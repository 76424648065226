import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import GoogleLocationAutoSuggest from '@common/components/FormElements/GoogleLocationAutoSuggest/GoogleLocationAutoSuggest';
import { Box } from '@components/Box';
import { CalloutText } from '@components/CalloutText';
import { Column } from '@components/Column';
import { Field } from '@components/FormElements';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';

type BusinessAddressProps = {
  value: string | undefined;
  onChange: (data: any) => void;
  onBlur: (data: any) => void;
  error?: string;
  initialValue: any;
  country: string;
};

export const BusinessAddress = (props: BusinessAddressProps) => {
  const { t } = useTranslation();
  const { error } = props;

  return (
    <Stack spacing="small" zIndex={1}>
      <Field
        label={t('createBusinessForm.fieldLabel.businessAddress')}
        message={
          <>
            {error && (
              <Box marginTop="xxsmall">
                <ErrorMessage>{error}</ErrorMessage>
              </Box>
            )}
            <Box marginTop="xxsmall">
              <Text size="small" tone="neutralLight">
                {t('createBusinessForm.fieldHelpText.location')}
              </Text>
            </Box>
          </>
        }
      >
        <GoogleLocationAutoSuggest {...props} />
      </Field>

      <Box tone="transparent" zIndex={-1}>
        <CalloutText tone="info">
          <Column tone="transparent" width="full">
            <Text>{t('createBusinessForm.calloutText.location.title')}</Text>
            <Text>{t('createBusinessForm.calloutText.location.example')}</Text>
          </Column>
        </CalloutText>
      </Box>
    </Stack>
  );
};
