import React from 'react';
import { Pressable, PressableProps } from 'react-native';
import { Banner } from '@components/Banner';
import { DevicesIllustration } from '@components/Illustration';
import { JobStreetLogo } from '@components/Logo';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { TextLink } from '@components/TextLink';
import { usePartnerPromo } from './hooks';

export type PartnerPromoProps = {
  testID?: PressableProps['testID'];
};

export const PartnerPromo = (props: PartnerPromoProps) => {
  const { testID } = props;
  const { partnerPromoBannerConfig, onBannerCardClicked } = usePartnerPromo();

  if (!partnerPromoBannerConfig) {
    return null;
  }

  const { tone, bannerType, text, brandName, linkText, linkTo } =
    partnerPromoBannerConfig;

  const bannerConfig = {
    jobStreet: {
      image: <DevicesIllustration size="standard" />,
      logo: <JobStreetLogo testID={`${brandName}-logo`} />
    }
  };

  return (
    <Stack testID={testID} marginTop="medium">
      <Pressable
        onPress={() => {
          onBannerCardClicked({
            type: bannerType,
            linkTo: linkTo
          });
        }}
      >
        <Banner
          testID="banner"
          tone={tone}
          image={
            <Stack
              tone="transparent"
              paddingRight="xlarge"
              paddingVertical="large"
              paddingLeft={{
                mobile: 'xlarge',
                desktop: 'none',
                tablet: 'none'
              }}
              alignItems={'center'}
              width="full"
            >
              {bannerConfig[brandName].image}
            </Stack>
          }
        >
          <Stack spacing="medium" flexShrink={1}>
            {bannerConfig[brandName].logo}
            <Text testID={`banner-text-${brandName}`}>{text}</Text>
            <TextLink
              weight="regular"
              onPress={() => {
                onBannerCardClicked({
                  type: bannerType,
                  linkTo: linkTo
                });
              }}
              testID={`banner-link-${brandName}`}
            >
              {linkText}
            </TextLink>
          </Stack>
        </Banner>
      </Pressable>
    </Stack>
  );
};
