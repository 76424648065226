import React, { ReactNode, forwardRef } from 'react';
import { View, ViewProps } from 'react-native';
import { Column } from '../Column';
import { Divider } from '../Divider';
import { Row } from '../Row';

type CardFooterProps = {
  children: ReactNode;
  testID?: ViewProps['testID'];
};

export const CardFooter = forwardRef<View, CardFooterProps>(
  ({ children, testID }: CardFooterProps, ref) => {
    return (
      <Column>
        <Divider direction="horizontal" />
        <Row
          alignItems="center"
          dividers
          justifyContent="space-evenly"
          ref={ref}
          testID={testID}
        >
          {children}
        </Row>
      </Column>
    );
  }
);
