import React from 'react';
import { G, Path, Polygon, Polyline } from 'svgs';
import { colors } from '@theme/tokens/colors';
import { createIllustration } from '../createIllustration';

const { grey900, blue500, blue50 } = colors;

const vectorComponent = () => (
  <>
    <G transform="translate(-660 -677)">
      <G transform="translate(138 649)">
        <G transform="translate(522 28)" clipRule="evenodd" fillRule="evenodd">
          <Path
            d="m102.2 93.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m104.4 92.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m106.6 91.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m108.7 89.9c0.2 0.1 0.2 0.3 0 0.5-0.2 0.1-0.6 0.1-0.8 0s-0.2-0.3 0-0.5c0.2-0.1 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m100 92.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m102.2 91.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m104.4 89.9c0.2 0.1 0.2 0.3 0 0.5-0.2 0.1-0.6 0.1-0.8 0s-0.2-0.3 0-0.5c0.2-0.1 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m106.6 88.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m97.8 91.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m100 89.9c0.2 0.1 0.2 0.3 0 0.5-0.2 0.1-0.6 0.1-0.8 0s-0.2-0.3 0-0.5c0.2-0.1 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m102.2 88.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m104.4 87.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m94.8 89.9c0.2-0.1 0.6-0.1 0.8 0s0.2 0.3 0 0.5c-0.2 0.1-0.6 0.1-0.8 0v-0.5"
            fill="#151411"
          />
          <Path
            d="m97.8 88.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m100 87.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m102.2 86.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m94.8 87.4c0.2-0.1 0.6-0.1 0.8 0s0.2 0.4 0 0.5-0.6 0.1-0.8 0v-0.5"
            fill="#151411"
          />
          <Path
            d="m97.8 86.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m94.8 84.8c0.2-0.1 0.6-0.1 0.8 0s0.2 0.4 0 0.5-0.6 0.1-0.8 0v-0.5"
            fill="#151411"
          />
          <Path
            d="m126.3 123.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m128.5 121.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m132.8 118.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m135 117.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m137.2 116.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m139.4 115c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m141.6 113.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m143.7 112.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m145.9 111.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m148.1 110c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.3 0-0.5 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m124.1 121.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m126.3 120.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m128.5 118.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m130.7 117.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m132.8 116.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m135 115c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m137.2 113.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m139.4 112.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m141.6 111.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m143.7 110c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m145.9 108.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m121.9 120.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m124.1 119.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m126.3 117.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m128.5 116.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m130.7 115c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m132.8 113.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m135 112.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m137.2 111.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m139.4 110c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m141.6 108.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m143.7 107.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5h0.8"
            fill="#151411"
          />
          <Path
            d="m119.8 119.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m121.9 118.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m124.1 116.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m126.3 115c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m128.5 113.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m130.7 112.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m132.8 111.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m135 110c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m137.2 108.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m139.4 107.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m141.6 106.7c-0.2 0.1-0.6 0.1-0.8 0s-0.2-0.4 0-0.5l0.8 0.5"
            fill="#151411"
          />
          <Path
            d="m117.6 118.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m119.8 116.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m121.9 115c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m124.1 113.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m126.3 112.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m128.5 111.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m130.7 110c0.2 0.1 0.2 0.3 0 0.5-0.2 0.1-0.6 0.1-0.8 0s-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m132.8 108.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m135 107.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m137.2 106.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m115.4 116.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m117.6 115.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m119.7 113.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m121.9 112.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m124.1 111.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m126.3 109.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m128.5 108.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m130.6 107.4c0.2 0.1 0.2 0.3 0 0.5-0.2 0.1-0.6 0.1-0.8 0s-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m132.8 106.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m135 104.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m113.2 115.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m115.4 114.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m117.6 112.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m119.7 111.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m121.9 109.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m124.1 108.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m126.3 107.4c0.2 0.1 0.2 0.3 0 0.5-0.2 0.1-0.6 0.1-0.8 0s-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m128.5 106.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m130.6 104.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m132.8 103.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m111 114.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m113.2 113.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m115.4 111.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m117.6 109.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m119.7 108.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m121.9 107.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m124.1 106.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m126.3 104.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m128.5 103.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m130.6 102.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m111 111.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m113.2 109.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5h0.8"
            fill="#151411"
          />
          <Path
            d="m118.9 106.2c0.2-0.1 0.6-0.1 0.8 0s0.2 0.4 0 0.5-0.6 0.1-0.8 0c-0.2-0.2-0.2-0.4 0-0.5"
            fill="#151411"
          />
          <Path
            d="m121.9 104.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m124.1 103.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m126.3 102.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m128.5 101.6c-0.2 0.1-0.6 0.1-0.8 0s-0.2-0.4 0-0.5l0.8 0.5"
            fill="#151411"
          />
          <Path
            d="m119.7 103.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m121.9 102.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m124.1 101.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m126.3 100.3c-0.2 0.1-0.6 0.1-0.8 0s-0.2-0.4 0-0.5 0.6-0.1 0.8 0v0.5"
            fill="#151411"
          />
          <Path
            d="m117.6 102.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m119.7 101.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m121.9 99.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m124.1 98.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m115.4 101.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m117.6 99.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m119.7 98.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m121.9 97.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m113.2 99.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m115.4 98.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m117.6 97.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m119.7 96c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m111 98.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m113.2 97.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m115.4 96c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m117.5 94.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m108.8 97.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.3 0-0.5c0.2-0.1 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m111 96c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m113.2 94.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m115.4 93.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m106.6 96c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m108.8 94.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m111 93.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m113.2 92.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m104.5 94.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m106.6 93.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m108.8 92.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m111 91c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m180.9 134c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m183.1 132.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m185.2 131.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m187.4 130.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m176.5 134c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m178.7 132.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m180.9 131.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m183.1 130.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m185.2 128.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m187.4 127.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m171.3 134.4c-0.2-0.1-0.2-0.4 0-0.5s0.6-0.1 0.8 0 0.2 0.4 0 0.5c-0.2 0.2-0.6 0.2-0.8 0"
            fill="#151411"
          />
          <Path
            d="m174.3 132.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m176.5 131.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m178.7 130.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m180.9 128.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m183.1 127.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m185.2 126.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m170 132.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m172.2 131.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.1-0.1 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m174.3 130.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m167.8 131.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m170 130.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m172.2 128.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.1-0.1 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m165.6 130.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m167.8 128.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m170 127.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m163.4 128.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m165.6 127.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m167.8 126.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m161.2 127.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m163.4 126.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m165.6 125.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m159.1 126.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.1-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m161.2 125.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m163.4 123.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m156.9 125.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m159.1 123.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.1-0.1 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m161.2 122.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m162.6 121.3c0.2-0.1 0.6-0.1 0.8 0s0.2 0.4 0 0.5l-0.8-0.5"
            fill="#151411"
          />
          <Path
            d="m154.7 123.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m156.9 122.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m159.1 121.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.1-0.1 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m152.5 122.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m154.7 121.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m156.9 120c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m150.3 121.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m152.5 120c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m154.7 118.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m156 117.5c0.2-0.1 0.6-0.1 0.8 0s0.2 0.4 0 0.5-0.6 0.1-0.8 0v-0.5"
            fill="#151411"
          />
          <Path
            d="m148.1 120c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m150.3 118.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m152.5 117.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m153.8 116.2c0.2-0.1 0.6-0.1 0.8 0s0.2 0.4 0 0.5l-0.8-0.5"
            fill="#151411"
          />
          <Path
            d="m146 118.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.1-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m148.1 117.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m150.3 116.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m152.5 115c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m132 124.3c-0.2-0.1-0.2-0.4 0-0.5s0.6-0.1 0.8 0 0.2 0.4 0 0.5h-0.8"
            fill="#151411"
          />
          <Path
            d="m135.1 122.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.1-0.1 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m137.2 121.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m139.4 120c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m141.6 118.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m143.8 117.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m146 116.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.1-0.1 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m148.1 115c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m150.3 113.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m152.5 112.9c-0.2 0.1-0.6 0.1-0.8 0s-0.2-0.4 0-0.5l0.8 0.5"
            fill="#151411"
          />
          <Path
            d="m128.5 124.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m130.7 123.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m132.9 121.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m135.1 120c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.1-0.1 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m137.2 118.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m139.4 117.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m141.6 116.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m143.8 115c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m146 113.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.3 0-0.5c0.1-0.1 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m148.1 112.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m150.3 111.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m178.5 48-82.2-47.1c-1-0.6-2-0.6-2.6 0l-4 2.4 3 4.3v64c0 2.7 1.6 5.8 3.7 7l76.7 44 3.9 5.6 3.8-2.3c0.8-0.4 1.4-1.5 1.4-3v-67.9c0-2.7-1.7-5.8-3.7-7"
            fill={blue500}
          />
          <Path
            d="m159 44.6-64.9-36.4-1.1-2.5 3-1.8c0.7-0.5 1.6-0.6 2.6 0l78.9 45.1c1.1 0.6 2.1 1.9 2.8 3.3l0.1 0.4-4.9 2.9-16.5-11"
            fill={blue50}
          />
          <Polyline
            points="180.9 55.2 176.5 57.9 176.9 59.5 180.9 57 180.9 55.2"
            fill={blue50}
          />
          <Path
            d="m93.1 7.5v64.1c0 2.6 1.6 5.5 3.5 6.6l76.7 43.9 3.9 5.5 0.8-0.5 0.2-1.2 3-1.9c0.4-0.3 0.7-0.7 0.7-1.2v-67.8c0-2.6-1.6-5.5-3.5-6.6l-82.3-47.1c-0.8-0.5-1.6-0.5-2.2 0l-3.7 2.2 2.9 4zm0.4-6.9c0.8-0.6 1.9-0.6 3.1 0l82.2 47.1c2.1 1.2 3.9 4.5 3.9 7.3v67.9c0 1.6-0.6 2.8-1.5 3.3l-4.1 2.5-4.1-5.9-76.9-43.9c-2.1-1.2-3.9-4.5-3.9-7.3v-63.9l-3.2-4.5 4.5-2.6z"
            fill="#151411"
          />
          <Path
            d="m116.3 105.8 16.7-9.5c1.6-0.9 4.3-0.8 6.2 0.2l10.1 5.8 4.8 2.2v2.2c-0.1 0.6-0.5 1.1-1.2 1.5l-20.5 11.7c-0.7 0.4-1.7 0.6-2.7 0.6-1.2 0-2.5-0.3-3.5-0.9l-13.3-7.6c-1-0.6-1.5-1.3-1.6-2.1v-0.2-1.8l5-2.1"
            fill="#151411"
          />
          <Path
            d="m152.7 106.2-20.5 11.7c-1.5 0.8-4.1 0.7-5.8-0.2l-13.4-7.7c-1.7-1-1.9-2.5-0.4-3.3l20.5-11.7c1.5-0.8 4.1-0.7 5.8 0.2l13.3 7.6c1.8 1 1.9 2.5 0.5 3.4"
            fill={blue500}
          />
          <Path
            d="m114.8 108.4 5.6-3.3c1.8-1.1 4.1-1.1 5.9 0l14 8-8.4 4.8c-1.5 0.8-4.1 0.7-5.8-0.2l-10.8-5.9c-1.8-1.1-1.9-2.5-0.5-3.4"
            fill={blue50}
          />
          <Path
            d="m152.5 105.8-20.5 11.7c-1.4 0.8-3.8 0.7-5.4-0.2l-13.3-7.6c-0.8-0.4-1.2-1-1.2-1.5s0.4-0.9 0.8-1.1l20.5-11.7c1.4-0.8 3.8-0.7 5.4 0.2l13.3 7.6c0.8 0.4 1.2 1 1.2 1.5 0 0.6-0.5 0.9-0.8 1.1zm-22.9 13.1c1 0 2-0.2 2.7-0.6l20.5-11.7c0.8-0.4 1.2-1.1 1.2-1.8 0-0.8-0.6-1.6-1.6-2.2l-13.3-7.6c-1.8-1.1-4.6-1.2-6.2-0.2l-20.5 11.7c-0.8 0.4-1.2 1.1-1.2 1.8 0 0.8 0.6 1.6 1.6 2.2l13.3 7.6c1.1 0.5 2.3 0.8 3.5 0.8z"
            fill="#151411"
          />
          <Path
            d="m89.7 2.9c0.8-0.5 1.8-0.4 2.8 0.2l82.2 47.1c2.1 1.2 3.9 4.5 3.9 7.3v67.9c0 1.5-0.6 2.2-1.1 2.5-0.3 0.2-0.7 0.3-1.2 0.3-0.7 0-1.5-0.2-2.3-0.7l-81.8-46c-2.1-1.2-3.9-4.5-3.9-7.3v-68.1c-0.1-1.5 0.5-2.7 1.4-3.2"
            fill="#151411"
          />
          <Path
            d="m88.6 66.1v8.2c0 2.5 1.8 5.5 3.9 6.8l81.6 46.8c2.2 1.2 3.9 0.2 3.9-2.3v-8.2l-89.4-51.3"
            fill={blue500}
          />
          <Path
            d="m177.7 117.6v8c0 1.1-0.3 1.8-0.9 2.2s-1.5 0.3-2.4-0.3l-81.6-46.7c-2.1-1.2-3.7-4.1-3.7-6.4v-7.6l88.6 50.8zm-1.8 11.2c0.4 0 0.9-0.1 1.2-0.3 0.9-0.5 1.3-1.5 1.3-2.9v-8.5l-0.2-0.1-90-51.5v8.9c0 2.6 1.9 5.8 4.1 7.1l81.6 46.8c0.8 0.3 1.4 0.5 2 0.5z"
            fill="#151411"
          />
          <Path
            d="m172.8 109.2-78.8-45.1c-0.7-0.4-1.3-1.5-1.3-2.5v-51.8c0-0.9 0.6-1.4 1.3-1l78.8 45.2c0.7 0.4 1.3 1.5 1.3 2.5v51.8c0 0.9-0.6 1.3-1.3 0.9"
            fill={blue500}
          />
          <Path
            d="m93.4 9c0.1 0 0.2 0.1 0.3 0.1l78.8 45.2c0.6 0.3 1.1 1.3 1.1 2.1v51.8c0 0.3-0.1 0.6-0.2 0.7-0.2 0.1-0.4 0-0.5-0.1l-78.8-45.1c-0.6-0.3-1.1-1.3-1.1-2.1v-51.8c0-0.3 0.1-0.6 0.2-0.7s0.2-0.1 0.2-0.1zm79.9 100.8c0.2 0 0.4 0 0.5-0.1 0.4-0.2 0.6-0.7 0.6-1.4v-51.9c0-1.1-0.7-2.3-1.5-2.8l-78.8-45.2c-0.4-0.3-0.9-0.3-1.2-0.1-0.4 0.2-0.6 0.7-0.6 1.4v51.8c0 1.1 0.7 2.3 1.5 2.8l78.8 45.2c0.2 0.2 0.5 0.3 0.7 0.3z"
            fill="#151411"
          />
          <Path
            d="m180.1 121.1-1.1 0.6c-0.2 0.1-0.3 0-0.3-0.2v-3.4c0-0.2 0.1-0.4 0.3-0.5l1.1-0.6c0.2-0.1 0.3 0 0.3 0.2v3.4c0 0.2-0.1 0.4-0.3 0.5"
            fill={blue50}
          />
          <Path
            d="m180.6 116.1-0.9 0.5c-0.1 0-0.1 0.2-0.1 0.3v3.2l0.9-0.5c0.1 0 0.1-0.2 0.1-0.3v-3.2zm-1.1 4.7c-0.1 0-0.2 0-0.2-0.1-0.2-0.1-0.2-0.3-0.2-0.5v-3.4c0-0.3 0.2-0.7 0.4-0.8l1.1-0.6c0.2-0.1 0.3-0.1 0.5 0s0.2 0.3 0.2 0.5v3.4c0 0.3-0.2 0.7-0.4 0.8l-1.1 0.6c-0.1 0.1-0.2 0.1-0.3 0.1z"
            fill="#151411"
          />
          <Polyline
            points="178.3 61 178 60.5 182 58 182.3 58.5 178.3 61"
            fill="#141311"
          />
          <Polyline
            points="93.2 4.2 92.9 3.7 96.9 1.2 97.3 1.8 93.2 4.2"
            fill="#141311"
          />
          <Polyline
            points="178.3 114.8 178 114.3 182 111.8 182.3 112.3 178.3 114.8"
            fill="#141311"
          />
          <Path
            d="m165.5 72.8-61.9-35.9c-0.9-0.5-1.6-1.9-1.6-3.1v-8.8c0-1.2 0.7-1.7 1.6-1.2l61.9 35.9c0.9 0.5 1.6 1.9 1.6 3.1v8.8c0 1.1-0.7 1.7-1.6 1.2"
            fill={blue50}
          />
          <Path
            d="m101.7 21.8c-0.1 0-0.2 0-0.3 0.1-0.3 0.2-0.4 0.6-0.4 1.1v8.8c0 1.1 0.7 2.4 1.5 2.9l61.9 35.9c0.2 0.1 0.5 0.2 0.8 0.1 0.3-0.2 0.4-0.6 0.4-1.1v-8.8c0-1.1-0.7-2.4-1.5-2.9l-61.9-35.9c-0.1-0.1-0.3-0.2-0.5-0.2zm63.1 49.4c-0.2 0-0.5-0.1-0.7-0.2l-61.9-35.9c-1-0.6-1.7-2-1.7-3.3v-8.8c0-0.7 0.2-1.2 0.7-1.5 0.4-0.2 0.8-0.2 1.3 0.1l61.9 35.9c1 0.6 1.7 2 1.7 3.3v8.8c0 0.7-0.2 1.2-0.7 1.5-0.2 0-0.4 0.1-0.6 0.1z"
            fill="#151411"
          />
          <Path
            d="m114.1 35.1c0 2.5-1.7 3.5-3.9 2.3s-3.9-4.3-3.9-6.8 1.7-3.5 3.9-2.3c2.2 1.3 3.9 4.3 3.9 6.8"
            fill="#151411"
          />
          <Path
            d="m107.3 27.4c-0.3 0-0.6 0.1-0.9 0.2-0.7 0.4-1 1.2-1 2.3 0 2.4 1.7 5.4 3.8 6.6 1 0.6 1.9 0.7 2.5 0.3 0.7-0.4 1-1.2 1-2.3 0-2.4-1.7-5.4-3.8-6.6-0.6-0.3-1.1-0.5-1.6-0.5zm3.5 10.1c-0.6 0-1.2-0.2-1.9-0.6-2.2-1.3-4-4.4-4-7 0-1.3 0.4-2.3 1.3-2.7 0.8-0.5 1.9-0.4 3 0.3 2.2 1.3 4 4.4 4 7 0 1.3-0.4 2.3-1.3 2.7-0.4 0.2-0.7 0.3-1.1 0.3z"
            fill="#151411"
          />
          <Path
            d="m110.2 28.3c-0.5-0.3-1-0.5-1.5-0.5 0.1 0 0.2 0.1 0.2 0.1 2.1 1.2 3.8 4.2 3.8 6.6 0 1.1-0.4 2-1 2.3-0.7 0.4-1.5 0.3-2.5-0.3 0.3 0.3 0.7 0.6 1 0.8 0.1 0.1 0.2 0.1 0.3 0.1h0.3c0.4 0 0.8-0.1 1.1-0.3 0.8-0.5 1.3-1.4 1.3-2.7-0.1-2-1.3-4.6-3-6.1"
            fill={blue500}
          />
          <Path
            d="m119.1 56-16.3-9.4c-0.9-0.5-1.6-1.8-1.6-3v-2.6c0-1.2 0.7-1.7 1.6-1.2l16.3 9.4c0.9 0.5 1.6 1.8 1.6 3v2.6c0 1.1-0.7 1.7-1.6 1.2"
            fill={blue50}
          />
          <Path
            d="m118.7 54c0.2 0.1 0.5 0.2 0.8 0.1 0.3-0.2 0.4-0.5 0.4-1.1v-2.6c0-1.1-0.6-2.3-1.4-2.8l-16.3-9.4c-0.2-0.1-0.5-0.2-0.8-0.1-0.3 0.2-0.4 0.5-0.4 1.1v2.6c0 1.1 0.6 2.3 1.4 2.8l16.3 9.4zm0.5 0.6c-0.2 0-0.5-0.1-0.7-0.2l-16.3-9.4c-0.9-0.5-1.7-1.9-1.7-3.2v-2.6c0-0.7 0.2-1.2 0.7-1.5 0.4-0.2 0.8-0.2 1.2 0.1l16.3 9.4c0.9 0.5 1.7 1.9 1.7 3.2v2.6c0 0.7-0.2 1.2-0.7 1.5-0.2 0-0.4 0.1-0.5 0.1z"
            fill="#151411"
          />
          <Path
            d="m142 69.2-16.3-9.4c-0.9-0.5-1.6-1.8-1.6-3v-2.6c0-1.2 0.7-1.7 1.6-1.2l16.3 9.4c0.9 0.5 1.6 1.8 1.6 3v2.6c-0.1 1.2-0.8 1.7-1.6 1.2"
            fill={blue50}
          />
          <Path
            d="m124.5 51.3c-0.1 0-0.2 0-0.3 0.1-0.3 0.2-0.4 0.5-0.4 1.1v2.5c0 1.1 0.6 2.3 1.4 2.8l16.3 9.4c0.2 0.1 0.5 0.2 0.8 0.1 0.3-0.2 0.4-0.5 0.4-1.1v-2.6c0-1.1-0.6-2.3-1.4-2.8l-16.3-9.4c-0.1-0.1-0.3-0.1-0.5-0.1zm17.5 16.5c-0.2 0-0.5-0.1-0.7-0.2l-16.3-9.4c-0.9-0.5-1.7-1.9-1.7-3.2v-2.6c0-0.7 0.2-1.2 0.7-1.5 0.4-0.2 0.8-0.2 1.2 0.1l16.3 9.4c0.9 0.5 1.7 1.9 1.7 3.2v2.6c0 0.7-0.2 1.2-0.7 1.5-0.2 0.1-0.3 0.1-0.5 0.1z"
            fill="#151411"
          />
          <Path
            d="m164.8 82.4-16.3-9.4c-0.9-0.5-1.6-1.8-1.6-3v-2.6c0-1.2 0.7-1.7 1.6-1.2l16.3 9.4c0.9 0.5 1.6 1.8 1.6 3v2.6c0 1.2-0.7 1.7-1.6 1.2"
            fill={blue50}
          />
          <Path
            d="m147.3 64.5c-0.1 0-0.2 0-0.3 0.1-0.3 0.2-0.4 0.5-0.4 1.1v2.6c0 1.1 0.6 2.3 1.4 2.8l16.3 9.4c0.2 0.1 0.5 0.2 0.8 0.1 0.3-0.2 0.4-0.5 0.4-1.1v-2.6c0-1.1-0.6-2.3-1.4-2.8l-16.3-9.4c-0.1-0.1-0.3-0.2-0.5-0.2zm17.5 16.6c-0.2 0-0.5-0.1-0.7-0.2l-16.3-9.4c-0.9-0.5-1.7-1.9-1.7-3.2v-2.6c0-0.7 0.2-1.2 0.7-1.5 0.4-0.2 0.8-0.2 1.2 0.1l16.3 9.4c0.9 0.5 1.7 1.9 1.7 3.2v2.6c0 0.7-0.2 1.2-0.7 1.5-0.1 0-0.3 0.1-0.5 0.1z"
            fill="#151411"
          />
          <Path
            d="m118.4 72.8-15.9-9.2c-0.9-0.6-1.7-2-1.7-3.3v-11.4c0-1.3 0.8-1.9 1.7-1.3l15.9 9.2c0.9 0.5 1.7 2 1.7 3.3v11.4c0 1.3-0.8 1.9-1.7 1.3"
            fill={blue500}
          />
          <Path
            d="m101.8 47.6c-0.1 0-0.2 0-0.3 0.1-0.3 0.2-0.5 0.6-0.5 1.2v11.4c0 1.2 0.7 2.6 1.6 3.1l15.9 9.2c0.3 0.2 0.7 0.2 0.9 0.1 0.3-0.2 0.5-0.6 0.5-1.2v-11.3c0-1.2-0.7-2.6-1.6-3.1l-15.9-9.2c-0.3-0.2-0.5-0.3-0.6-0.3zm17.3 25.7c-0.3 0-0.5-0.1-0.8-0.2l-15.9-9.2c-1-0.6-1.8-2.1-1.8-3.5v-11.5c0-0.8 0.3-1.3 0.7-1.6 0.4-0.2 0.9-0.2 1.3 0.1l15.9 9.2c1 0.6 1.8 2.1 1.8 3.5v11.4c0 0.8-0.3 1.3-0.7 1.6-0.1 0.1-0.3 0.2-0.5 0.2z"
            fill="#151411"
          />
          <Path
            d="m141.2 86.1-15.9-9.2c-0.9-0.5-1.7-2-1.7-3.3v-11.4c0-1.3 0.8-1.9 1.7-1.3l15.9 9.2c0.9 0.6 1.7 2 1.7 3.3v11.4c0 1.2-0.7 1.8-1.7 1.3"
            fill={blue500}
          />
          <Path
            d="m124.6 60.9c-0.1 0-0.2 0-0.3 0.1-0.3 0.2-0.5 0.6-0.5 1.2v11.4c0 1.2 0.7 2.6 1.6 3.1l15.9 9.2c0.3 0.2 0.7 0.2 0.9 0.1 0.3-0.2 0.5-0.6 0.5-1.2v-11.4c0-1.2-0.7-2.6-1.6-3.1l-15.9-9.3c-0.2-0.1-0.4-0.1-0.6-0.1zm17.3 25.6c-0.3 0-0.5-0.1-0.8-0.2l-15.9-9.3c-1-0.6-1.8-2.1-1.8-3.5v-11.3c0-0.8 0.3-1.3 0.7-1.6 0.4-0.2 0.9-0.2 1.3 0.1l15.9 9.2c1 0.6 1.8 2.1 1.8 3.5v11.4c0 0.8-0.3 1.3-0.7 1.6-0.1 0.1-0.3 0.1-0.5 0.1z"
            fill="#151411"
          />
          <Path
            d="m164.1 99.3-15.9-9.2c-0.9-0.6-1.7-2-1.7-3.3v-11.4c0-1.3 0.8-1.9 1.7-1.3l15.9 9.2c0.9 0.5 1.7 2 1.7 3.3v11.4c0 1.3-0.8 1.9-1.7 1.3"
            fill={blue500}
          />
          <Path
            d="m164.2 99.1c0.3 0.2 0.7 0.2 0.9 0.1 0.3-0.2 0.5-0.6 0.5-1.2v-11.4c0-1.2-0.7-2.6-1.6-3.1l-16-9.2c-0.3-0.2-0.7-0.2-0.9-0.1-0.3 0.2-0.5 0.6-0.5 1.2v11.4c0 1.2 0.7 2.6 1.6 3.1l16 9.2zm0.5 0.6c-0.3 0-0.5-0.1-0.8-0.2l-15.9-9.2c-1-0.6-1.8-2.1-1.8-3.5v-11.4c0-0.8 0.3-1.3 0.7-1.6 0.4-0.2 0.9-0.2 1.3 0.1l15.9 9.2c1 0.6 1.8 2.1 1.8 3.5v11.4c0 0.8-0.3 1.3-0.7 1.6-0.1 0.1-0.3 0.1-0.5 0.1z"
            fill="#151411"
          />
          <Path
            d="m83.8 158.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m85.9 157.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m88.1 156.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m90.3 155.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m92.5 153.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m94.7 152.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m96.8 151.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m99 150c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m101.2 148.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m103.4 147.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m105.6 146.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m107.7 145c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m79.4 158.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m81.6 157.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m83.8 156.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m85.9 155.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m88.1 153.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m90.3 152.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m92.5 151.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m94.7 150c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m96.8 148.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m99 147.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m101.2 146.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m103.4 145c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m105.6 143.7c0.2 0.1 0.2 0.3 0 0.5-0.2 0.1-0.6 0.1-0.8 0s-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m77.2 157.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m79.4 156.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m75 156.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m77.2 155.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m72.8 155.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m75 153.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m70.7 153.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m72.8 152.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m68.5 152.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m70.7 151.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m66.3 151.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m68.5 150c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m64.1 150c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m66.3 148.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m61.9 148.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m64.1 147.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m61.9 146.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m109.1 143.7c0.2-0.1 0.6-0.1 0.8 0s0.2 0.4 0 0.5-0.6 0.1-0.8 0-0.2-0.3 0-0.5"
            fill="#151411"
          />
          <Path
            d="m112.1 142.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m114.3 141.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m116.5 139.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m118.7 138.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.1-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m120.8 137.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m123 136.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m106.9 142.5c0.2-0.1 0.6-0.1 0.8 0s0.2 0.3 0 0.5c-0.2 0.1-0.6 0.1-0.8 0-0.2-0.2-0.2-0.4 0-0.5"
            fill="#151411"
          />
          <Path
            d="m109.9 141.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m112.1 139.9c0.2 0.1 0.2 0.3 0 0.5-0.2 0.1-0.6 0.1-0.8 0s-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m114.3 138.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m116.5 137.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m118.7 136.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.1-0.1 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m120.8 134.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m123 133.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m120.1 129.9s-1.2-0.1-1 1.3l-15.8-9.2c-1-0.5-2.3-0.5-3.3 0.1l-34.3 19.9c-1.4-0.6-2.7-0.8-3.2 0.1-1 1.7-0.3 2.6 0.2 3.2 0.1 0.2 0.3 0.3 0.5 0.4l16.5 9.6c1 0.5 2.2 0.5 3.2-0.1l36.9-21.4c0-0.1 2.5-1.5 0.3-3.9"
            fill={blue50}
          />
          <Path
            d="m63.7 141.6c-0.5 0-0.9 0.2-1.1 0.5-0.9 1.5-0.4 2.3 0.2 2.9 0.1 0.1 0.3 0.3 0.5 0.4l16.5 9.6c0.9 0.5 2.1 0.4 3-0.1l36.9-21.4s0.9-0.6 1-1.5c0.1-0.6-0.2-1.3-0.8-1.9-0.1 0-0.4 0-0.5 0.2-0.2 0.2-0.2 0.4-0.1 0.8l0.1 0.5-16.3-9.5c-1-0.5-2.1-0.4-3 0.1l-34.4 19.9h-0.1c-0.8-0.3-1.4-0.5-1.9-0.5zm17.5 14.2c-0.6 0-1.1-0.1-1.6-0.4l-16.5-9.6c-0.3-0.1-0.5-0.3-0.6-0.5-0.6-0.6-1.4-1.7-0.3-3.5 0.6-0.9 1.7-1 3.4-0.2l34.2-19.8c1.1-0.6 2.4-0.7 3.5-0.1l15.5 9c0-0.3 0.1-0.6 0.3-0.7 0.4-0.4 1-0.3 1-0.3h0.1l0.1 0.1c0.7 0.8 1 1.6 0.9 2.4-0.2 1.2-1.2 1.8-1.3 1.8l-36.9 21.3c-0.5 0.4-1.2 0.5-1.8 0.5z"
            fill="#151411"
          />
          <Path
            d="m62.9 141.5 37-21.4c1-0.6 2.2-0.6 3.3-0.1l16.5 9.6c0.9 0.6 1 1.9 0.1 2.4l-36.9 21.4c-1 0.6-2.2 0.6-3.2 0.1l-16.5-9.6c-1.2-0.8-1.1-1.9-0.3-2.4"
            fill={blue500}
          />
          <Path
            d="m119.7 130.4-16.5-9.6c-1-0.5-2.3-0.5-3.3 0.1l-37 21.4c-0.2 0.2-0.4 0.4-0.5 0.6 0.1 0.3 0.4 0.6 0.8 0.8l16.5 9.6c1 0.5 2.2 0.5 3.2-0.1l36.9-21.4c0.3-0.2 0.5-0.4 0.5-0.6-0.1-0.3-0.3-0.6-0.6-0.8"
            fill={blue50}
          />
          <Path
            d="m63.1 141.7c-0.3 0.2-0.5 0.6-0.5 0.9 0 0.4 0.3 0.8 0.8 1.1l16.5 9.6c0.9 0.5 2.1 0.4 3-0.1l36.9-21.4c0.3-0.2 0.5-0.5 0.5-0.8 0-0.4-0.2-0.9-0.6-1.2l-16.5-9.6c-1-0.5-2.1-0.4-3 0.1l-37.1 21.4zm18.1 12.3c-0.6 0-1.1-0.1-1.6-0.4l-16.5-9.6c-0.6-0.4-1-0.9-1-1.5 0-0.5 0.3-1 0.7-1.3l37-21.4c1.1-0.6 2.4-0.7 3.5-0.1l16.5 9.6c0.5 0.4 0.9 1 0.8 1.6 0 0.5-0.3 0.9-0.7 1.2l-36.9 21.4c-0.6 0.4-1.2 0.5-1.8 0.5z"
            fill="#151411"
          />
          <Path
            d="m107.6 124.1c-0.1-0.1-0.1-0.2 0-0.3s0.3-0.1 0.4 0 0.1 0.2 0 0.2c-0.1 0.2-0.3 0.2-0.4 0.1"
            fill="#151411"
          />
          <Path
            d="m111.8 126.6-3.7-2.1c-0.1-0.1-0.1-0.2 0-0.2 0.1-0.1 0.3-0.1 0.4 0l3.7 2.1c0.1 0.1 0.1 0.2 0 0.2-0.1 0.1-0.3 0.1-0.4 0"
            fill="#151411"
          />
          <Path
            d="m112.5 136.9-1.9 1.1s-0.1 0.1-0.1 0.2 0 0.2 0.1 0.3h0.1l1.8-1c0.1 0 0.1-0.1 0.1-0.2 0-0.3 0-0.3-0.1-0.4 0.1 0 0.1 0 0 0zm-1.9 1.8s-0.1 0 0 0c-0.3-0.1-0.4-0.4-0.4-0.6s0.2-0.3 0.2-0.3l1.8-1.1c0.1-0.1 0.2-0.1 0.3 0 0.1 0 0.2 0.1 0.2 0.2 0.1 0.1 0.1 0.3 0 0.4 0 0.2-0.2 0.3-0.3 0.4l-1.8 1c0.1-0.1 0.1 0 0 0z"
            fill={blue500}
          />
          <Path
            d="m115.3 135.3-1.9 1.1s-0.1 0.1-0.1 0.2 0 0.2 0.1 0.3h0.1l1.8-1c0.1 0 0.1-0.1 0.1-0.2 0-0.3 0-0.3-0.1-0.4 0.1 0 0.1 0 0 0zm-1.9 1.8c-0.3-0.1-0.4-0.4-0.4-0.6s0.2-0.3 0.2-0.3l1.8-1.1c0.1 0 0.2-0.1 0.3 0 0.1 0 0.2 0.1 0.2 0.2 0.1 0.1 0.1 0.3 0 0.4 0 0.2-0.2 0.3-0.3 0.4l-1.8 1c0.1-0.1 0.1 0 0 0z"
            fill={blue500}
          />
          <Path
            d="m109.7 138.5-0.5 0.3c-0.1 0-0.1 0.1-0.1 0.2v0.2s0 0.1 0.1 0.1h0.1l0.4-0.2s0.1-0.1 0.1-0.2c0-0.2 0-0.3-0.1-0.4 0.1 0.1 0.1 0 0 0zm-0.5 1c-0.1 0-0.1 0 0 0-0.2 0-0.3-0.1-0.3-0.3v-0.4c0-0.2 0.1-0.3 0.3-0.4l0.5-0.3c0.1 0 0.2-0.1 0.3 0 0.1 0 0.2 0.1 0.2 0.2 0.1 0.1 0.1 0.3 0 0.5 0 0.2-0.2 0.3-0.3 0.4l-0.5 0.3h-0.2z"
            fill={blue500}
          />
          <Path
            d="m117.1 130.8-16-9.2c-0.7-0.4-1.6-0.3-2.3 0.1l-33.2 19.2c-0.6 0.4-0.6 1.2 0.2 1.7l16 9.2c0.7 0.4 1.6 0.3 2.3-0.1l33.1-19.1c0.6-0.5 0.6-1.4-0.1-1.8"
            fill={blue50}
          />
          <Path
            d="m100.1 121.5c-0.4 0-0.8 0.1-1.1 0.3l-33.3 19.2c-0.2 0.2-0.4 0.4-0.4 0.6 0 0.3 0.2 0.6 0.6 0.8l15.9 9.2c0.7 0.3 1.5 0.3 2.1-0.1l33.1-19.1c0.3-0.2 0.4-0.4 0.4-0.7s-0.2-0.6-0.4-0.7l-16-9.2c-0.2-0.2-0.6-0.3-0.9-0.3zm-17.2 30.7c-0.4 0-0.8-0.1-1.1-0.3l-16-9.2c-0.4-0.3-0.7-0.6-0.7-1s0.2-0.7 0.5-0.9l33.2-19.2c0.7-0.4 1.7-0.5 2.4-0.1l16 9.2c0.4 0.2 0.6 0.5 0.6 1 0 0.4-0.2 0.8-0.6 1l-33.1 19.1c-0.4 0.2-0.8 0.4-1.2 0.4z"
            fill="#151411"
          />
          <Path
            d="m81.7 155.7-0.3-0.1c0.3-0.8 0-1.7 0-1.7l0.3-0.1s0.4 1 0 1.9"
            fill={blue500}
          />
          <Path
            d="m72.1 150.2s0.1 0 0 0c0.1 0 0.1 0 0.1-0.1 0-0.2-0.1-0.4-0.2-0.5l-1.4-0.8h-0.1v0.1c0 0.2 0.1 0.4 0.2 0.5l1.4 0.8zm0.1 0.3c-0.1 0-0.1 0-0.2-0.1l-1.4-0.8c-0.2-0.1-0.4-0.5-0.4-0.8 0-0.2 0.1-0.3 0.2-0.4s0.2-0.1 0.4 0l1.4 0.8c0.2 0.1 0.4 0.5 0.4 0.8 0 0.2-0.1 0.3-0.2 0.4s-0.2 0.1-0.2 0.1z"
            fill={blue500}
          />
          <Path
            d="m107.8 136.6-14.5-8.4c-0.3-0.2-0.2-0.4 0.1-0.6l4.2-2.4c0.3-0.2 0.8-0.2 1.1 0l14.5 8.4c0.3 0.2 0.2 0.4-0.1 0.6l-4.2 2.4c-0.3 0.1-0.8 0.1-1.1 0"
            fill={blue50}
          />
          <Path
            d="m108 135.3c0.2 0.1 0.6 0.1 0.9 0l4.2-2.4c0.1-0.1 0.2-0.1 0.2-0.2l-0.1-0.1-14.5-8.4c-0.2-0.1-0.6-0.1-0.9 0l-4.2 2.4c-0.1 0.1-0.2 0.1-0.2 0.2l0.1 0.1 14.5 8.4zm0.3 0.6c-0.2 0-0.4 0-0.6-0.1l-14.5-8.4c-0.2-0.1-0.3-0.3-0.3-0.5s0.1-0.4 0.4-0.6l4.2-2.4c0.4-0.2 1-0.2 1.3 0l14.5 8.4c0.2 0.1 0.3 0.3 0.3 0.5s-0.1 0.4-0.4 0.6l-4.2 2.4c-0.2 0-0.4 0.1-0.7 0.1z"
            fill="#151411"
          />
          <Path
            d="m100.6 128.5c-0.9 0.5-2.3 0.5-3.2 0s-0.9-1.4 0-1.9 2.3-0.5 3.2 0 0.9 1.4 0 1.9"
            fill="#151411"
          />
          <Path
            d="m98.4 125.8c-0.5 0-1.1 0.1-1.5 0.4-0.4 0.2-0.6 0.5-0.6 0.7 0 0.3 0.2 0.5 0.6 0.7 0.8 0.5 2.2 0.5 3 0 0.4-0.2 0.6-0.5 0.6-0.7 0-0.3-0.2-0.5-0.6-0.7s-1-0.4-1.5-0.4zm0 2.7c-0.6 0-1.2-0.1-1.7-0.4s-0.8-0.7-0.8-1.1 0.3-0.8 0.8-1.1c1-0.6 2.5-0.6 3.5 0 0.5 0.3 0.8 0.7 0.8 1.1s-0.3 0.8-0.8 1.1c-0.6 0.2-1.2 0.4-1.8 0.4z"
            fill="#151411"
          />
          <Path
            d="m100.8 126.8c-0.1-0.1-0.2-0.1-0.3-0.2s-0.3-0.1-0.4-0.2c0.2 0.2 0.3 0.3 0.3 0.5 0 0.3-0.2 0.5-0.6 0.7-0.8 0.5-2.2 0.5-3 0-0.1 0-0.1-0.1-0.2-0.1 0 0.2 0.1 0.5 0.4 0.7 0.4 0.2 0.9 0.2 1.3 0.2 0.6 0 1.2-0.1 1.7-0.4s0.8-0.7 0.8-1.1c0.1 0 0.1-0.1 0-0.1"
            fill={blue500}
          />
          <Path
            d="m93.3 143.8-6.6-3.8c-0.4-0.2-0.3-0.6 0.1-0.8l4.3-2.5c0.4-0.3 1.1-0.3 1.5-0.1l6.6 3.8c0.4 0.2 0.3 0.6-0.1 0.8l-4.3 2.5c-0.5 0.2-1.2 0.3-1.5 0.1"
            fill={blue500}
          />
          <Path
            d="m91.9 136.6c-0.2 0-0.5 0.1-0.7 0.2l-4.3 2.5c-0.2 0.1-0.2 0.2-0.2 0.3s0.1 0.1 0.1 0.2l6.6 3.8c0.3 0.2 0.9 0.1 1.2-0.1l4.3-2.5c0.2-0.1 0.2-0.2 0.2-0.3s-0.1-0.1-0.1-0.2l-6.6-3.8c-0.1-0.1-0.3-0.1-0.5-0.1zm2 7.5c-0.3 0-0.5-0.1-0.7-0.2l-6.6-3.8c-0.2-0.1-0.4-0.3-0.4-0.6s0.2-0.5 0.5-0.7l4.3-2.5c0.5-0.3 1.2-0.3 1.7-0.1l6.6 3.8c0.2 0.1 0.4 0.3 0.4 0.6s-0.2 0.5-0.5 0.7l-4.3 2.5c-0.4 0.3-0.7 0.3-1 0.3z"
            fill="#151411"
          />
          <Path
            d="m83.6 149.4-14.3-8.3c-0.3-0.2-0.3-0.5 0.1-0.8l7.1-4.1c0.4-0.2 1-0.2 1.3-0.1l14.3 8.3c0.3 0.2 0.3 0.5-0.1 0.8l-7.2 4.1c-0.3 0.3-0.9 0.3-1.2 0.1"
            fill={blue500}
          />
          <Path
            d="m77.3 136.3c-0.2 0-0.4 0.1-0.6 0.2l-7.2 4.1c-0.2 0.1-0.2 0.2-0.2 0.2 0 0.1 0.1 0.1 0.1 0.1l14.3 8.3c0.3 0.2 0.8 0.1 1.1-0.1l7.2-4.1c0.2-0.1 0.2-0.2 0.2-0.2 0-0.1-0.1-0.1-0.1-0.1l-14.3-8.3c-0.2-0.1-0.3-0.1-0.5-0.1zm6.9 13.4c-0.3 0-0.5-0.1-0.7-0.2l-14.3-8.3c-0.2-0.1-0.3-0.3-0.3-0.5s0.2-0.5 0.4-0.6l7.2-4.1c0.5-0.3 1.1-0.3 1.5-0.1l14.3 8.3c0.2 0.1 0.3 0.3 0.3 0.5s-0.2 0.5-0.4 0.6l-7.2 4.1c-0.2 0.3-0.5 0.3-0.8 0.3z"
            fill="#151411"
          />
          <Path
            d="m103.9 138-14.8-8.6c-0.2-0.1-0.2-0.3 0.1-0.4l1.6-0.9c0.2-0.1 0.6-0.1 0.8 0l14.8 8.6c0.2 0.1 0.2 0.3-0.1 0.4l-1.6 0.9c-0.2 0.1-0.6 0.1-0.8 0"
            fill={blue500}
          />
          <Path
            d="m104 137.8c0.1 0.1 0.4 0.1 0.5 0l1.6-0.9c0.1 0 0.1-0.1 0.1-0.1l-14.8-8.6c-0.1-0.1-0.4-0.1-0.5 0l-1.6 0.9c-0.1 0-0.1 0.1-0.1 0.1l14.8 8.6zm0.3 0.5c-0.2 0-0.3 0-0.4-0.1l-14.9-8.6c-0.2-0.1-0.2-0.2-0.2-0.4s0.1-0.3 0.3-0.4l1.6-0.9c0.3-0.2 0.7-0.2 1 0l14.8 8.6c0.2 0.1 0.2 0.2 0.2 0.4s-0.1 0.3-0.3 0.4l-1.6 0.9c-0.1 0-0.3 0.1-0.5 0.1z"
            fill="#151411"
          />
          <Path
            d="m100.6 140.7-14.8-8.6c-0.2-0.1-0.2-0.3 0.1-0.4l1.6-0.9c0.2-0.1 0.6-0.1 0.8 0l14.8 8.6c0.2 0.1 0.2 0.3-0.1 0.4l-1.6 0.9c-0.3 0.1-0.6 0.1-0.8 0"
            fill={blue50}
          />
          <Path
            d="m87.8 130.1c-0.1 0-0.2 0-0.3 0.1l-1.6 0.9c-0.1 0-0.1 0.1-0.1 0.1l14.8 8.6c0.1 0.1 0.4 0.1 0.5 0l1.6-0.9c0.1 0 0.1-0.1 0.1-0.1l-14.8-8.6c0-0.1-0.1-0.1-0.2-0.1zm13.1 10.1c-0.2 0-0.3 0-0.4-0.1l-14.8-8.6c-0.2-0.1-0.2-0.2-0.2-0.4s0.1-0.3 0.3-0.4l1.6-0.9c0.3-0.2 0.7-0.2 1 0l14.8 8.6c0.2 0.1 0.2 0.2 0.2 0.4s-0.1 0.3-0.3 0.4l-1.6 0.9c-0.2 0.1-0.4 0.1-0.6 0.1z"
            fill="#151411"
          />
          <Path
            d="m88 137.5-5.7-3.3c-0.2-0.1-0.2-0.4 0.1-0.5l1.4-0.8c0.3-0.2 0.7-0.2 0.9 0l5.7 3.3c0.2 0.1 0.2 0.4-0.1 0.5l-1.4 0.8c-0.2 0.1-0.6 0.1-0.9 0"
            fill={blue50}
          />
          <Path
            d="m82.4 133.1v0l5.7 3.3c0.2 0.1 0.5 0.1 0.7 0l1.4-0.8 0.1-0.1-5.7-3.3c-0.2-0.1-0.5-0.1-0.7 0l-1.4 0.8-0.1 0.1zm6 3.9c-0.2 0-0.4 0-0.5-0.1l-5.7-3.3c-0.2-0.1-0.3-0.3-0.3-0.4 0-0.2 0.1-0.4 0.3-0.5l1.4-0.8c0.3-0.2 0.8-0.2 1.1 0l5.7 3.3c0.2 0.1 0.3 0.3 0.3 0.4 0 0.2-0.1 0.4-0.3 0.5l-1.4 0.8c-0.1 0-0.3 0.1-0.6 0.1z"
            fill={blue50}
          />
          <Path
            d="m84.5 139.5-5.7-3.3c-0.2-0.1-0.2-0.4 0.1-0.5l1.4-0.8c0.3-0.2 0.7-0.2 0.9 0l5.7 3.3c0.2 0.1 0.2 0.4-0.1 0.5l-1.4 0.8c-0.2 0.2-0.6 0.2-0.9 0"
            fill={blue50}
          />
          <Path
            d="m84.6 138.5c0.2 0.1 0.5 0.1 0.7 0l1.4-0.8 0.1-0.1-5.7-3.3c-0.2-0.1-0.5-0.1-0.7 0l-1.4 0.8-0.1 0.1 5.7 3.3zm0.3 0.5c-0.2 0-0.4 0-0.5-0.1l-5.7-3.3c-0.2-0.1-0.3-0.3-0.3-0.4 0-0.2 0.1-0.4 0.3-0.5l1.4-0.8c0.3-0.2 0.8-0.2 1.1 0l5.7 3.3c0.2 0.1 0.3 0.3 0.3 0.4 0 0.2-0.1 0.4-0.3 0.5l-1.4 0.8c-0.1 0.1-0.3 0.1-0.6 0.1z"
            fill="#151411"
          />
          <Path
            d="m167.5 156.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m169.7 155.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m163.1 156.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m165.3 155.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m167.5 154.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m169.7 153.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m161 155.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m163.1 154.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m158.8 154.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m161 153.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m156.6 153.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m158.8 151.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m154.4 151.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m156.6 150.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m152.2 150.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m154.4 149.3c0.2 0.1 0.2 0.3 0 0.5-0.2 0.1-0.6 0.1-0.8 0s-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m150 149.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m152.2 148.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m147.9 148.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m150 146.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m145.7 146.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m147.9 145.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m143.5 145.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m145.7 144.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m141.3 144.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m143.5 143c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m139.1 143c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m141.3 141.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m136.9 141.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m139.1 140.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m134.8 140.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m136.9 139.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m134.8 137.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m163.9 153.4c-2.1-0.6-0.9-1.3 0.7-1.8l-27.4-16c-1.1-0.8-1.8-2-1.8-3.4l0.2-46.6c-1.5-1-2.4-2.2-1.8-3.4 1.1-2 2.5-1.9 3.4-1.6 0.2 0.1 0.5 0.2 0.7 0.3l28.1 16.4c1.1 0.7 1.8 2 1.8 3.3l-0.3 50.1c0.1 0.1 0.3 3.7-3.6 2.7"
            fill="#151411"
          />
          <Path
            d="m136.3 80.6c-0.7 0-1.5 0.4-2.2 1.6-0.5 0.9 0.1 1.9 1.7 3.1l0.1 0.1-0.2 46.7c0 1.3 0.6 2.5 1.7 3.2l27.8 16.2-0.5 0.2c-1.6 0.6-1.6 0.9-1.6 0.9s0 0.2 0.9 0.5c1.2 0.3 2 0.2 2.6-0.3 0.9-0.7 0.8-2.1 0.8-2.1l0.3-50.1c0-1.3-0.6-2.5-1.7-3.2l-28.1-16.4c-0.3-0.2-0.5-0.3-0.7-0.3-0.3 0-0.6-0.1-0.9-0.1zm28.9 73.2c-0.4 0-0.9-0.1-1.4-0.2s-1.3-0.4-1.3-0.9c0-0.4 0.4-0.8 1.5-1.2l-26.9-15.8c-1.2-0.8-1.9-2.2-1.9-3.6l0.2-46.5c-1.8-1.3-2.4-2.5-1.8-3.6 1.2-2.1 2.6-2 3.6-1.7 0.3 0.1 0.5 0.2 0.8 0.3l28.1 16.5c1.2 0.8 1.9 2.1 1.9 3.5l-0.3 50.1s0.1 1.6-0.9 2.5c-0.4 0.4-0.9 0.6-1.6 0.6z"
            fill="#151411"
          />
          <Path
            d="m133.6 83-0.2 50.3c0 1.4 0.7 2.6 1.8 3.4l28.1 16.4c1.1 0.7 2.5 0 2.5-1.3l0.3-50.1c0-1.3-0.6-2.6-1.8-3.3l-28.1-16.4c-1.4-0.9-2.5-0.2-2.6 1"
            fill={blue500}
          />
          <Path
            d="m165.2 99.8-28.1-16.4c-1.4-0.8-2.5-0.1-2.6 1.1l-0.2 50.3c0 0.3 0 0.7 0.1 1 0.2 0.3 0.5 0.6 0.9 0.8l28.1 16.4c1.1 0.7 2.5 0 2.5-1.3l0.3-50.1c0-0.3 0-0.6-0.1-0.9-0.3-0.4-0.6-0.7-0.9-0.9"
            fill={blue50}
          />
          <Path
            d="m133.8 83-0.2 50.3c0 1.3 0.6 2.5 1.7 3.2l28.1 16.4c0.4 0.3 1 0.3 1.4 0.1 0.5-0.2 0.7-0.7 0.7-1.2l0.3-50.1c0-1.3-0.6-2.5-1.7-3.2l-28.1-16.4c-0.6-0.3-1.2-0.4-1.6-0.2-0.3 0.2-0.5 0.6-0.6 1.1zm30.4 70.6c-0.4 0-0.7-0.1-1-0.3l-28.1-16.4c-1.2-0.8-1.9-2.2-1.9-3.6l0.2-50.3c0-0.7 0.4-1.2 0.9-1.5 0.6-0.3 1.3-0.2 2 0.2l28.1 16.4c1.2 0.8 1.9 2.1 1.9 3.5l-0.3 50.1c0 0.7-0.4 1.3-1 1.6-0.3 0.3-0.5 0.3-0.8 0.3z"
            fill="#151411"
          />
          <Path
            d="m135 84.5 29.5 17.6c0.2 0.1 0.3 0.4 0.3 0.6l-0.1 47.5c0 0.2-0.2 0.3-0.4 0.2l-29.6-17.5c-0.2-0.1-0.3-0.3-0.3-0.6l0.2-47.7c0.1-0.1 0.3-0.2 0.4-0.1"
            fill={blue50}
          />
          <Path
            d="m135 84.5-0.1 0.2-0.2 47.7c0 0.1 0.1 0.3 0.2 0.4l29.6 17.5 0.1-47.5c0-0.2-0.1-0.3-0.2-0.4l-29.5-17.6 0.1-0.3zm29.5 66.2c-0.1 0-0.2 0-0.3-0.1l-29.6-17.5c-0.3-0.2-0.4-0.5-0.4-0.8l0.2-47.7c0-0.2 0.1-0.3 0.2-0.4s0.3-0.1 0.4 0l29.5 17.6c0.3 0.2 0.4 0.5 0.4 0.8l-0.1 47.5c0 0.2-0.1 0.3-0.2 0.4 0 0.2 0 0.2-0.1 0.2z"
            fill="#151411"
          />
          <Path
            d="m134.9 84.5-0.2 47.7c0 0.2 0.1 0.4 0.3 0.6l29.6 17.5c0 0.1-0.1 0.3-0.3 0.2l-29.6-17.5c-0.2-0.1-0.3-0.3-0.3-0.6l0.2-47.7c0-0.2 0.2-0.3 0.3-0.2"
            fill="#F4B83B"
          />
          <Path
            d="m164.4 150.7c-0.1 0-0.2 0-0.2-0.1l-29.6-17.5c-0.3-0.2-0.4-0.5-0.4-0.8l0.2-47.7c0-0.1 0.1-0.3 0.2-0.4s0.3-0.1 0.4-0.1l0.2 0.1-0.1 0.2-0.2 47.7c0 0.1 0.1 0.3 0.2 0.4l29.6 17.5h0.2v0.1l-0.1 0.3v0.1c0 0.1-0.1 0.1-0.2 0.1-0.1 0.1-0.1 0.1-0.2 0.1"
            fill="#151411"
          />
          <Path
            d="m148.3 90.6c-0.1-0.1-0.2 0-0.2 0.1s0.1 0.3 0.2 0.3c0.1 0.1 0.2 0 0.2-0.1s-0.1-0.2-0.2-0.3"
            fill="#151411"
          />
          <Path
            d="m152.2 92.9-3.4-2c-0.1-0.1-0.2 0-0.2 0.1s0.1 0.3 0.2 0.3l3.4 2c0.1 0.1 0.2 0 0.2-0.1s-0.1-0.2-0.2-0.3"
            fill="#151411"
          />
          <Path
            d="m151.3 143.5-1.8-1.1c-0.4-0.2-0.7 0-0.7 0.5s0.3 1.1 0.7 1.3l1.8 1.1c0.4 0.2 0.7 0 0.7-0.5-0.1-0.5-0.4-1.1-0.7-1.3"
            fill={blue500}
          />
          <Path
            d="m148.3 141.9c-0.1 0-0.1 0 0 0-0.2 0.1-0.3 0.2-0.3 0.4 0 0.4 0.3 1 0.6 1.2l1.8 1.1c0.1 0 0.2 0.1 0.3 0s0.2-0.2 0.2-0.4c0-0.4-0.3-1-0.6-1.2l-1.8-1.1c-0.1 0.1-0.2 0-0.2 0zm2.3 3.1c-0.1 0-0.2 0-0.3-0.1l-1.8-1.1c-0.4-0.2-0.7-0.9-0.7-1.4 0-0.3 0.1-0.6 0.3-0.7s0.4-0.1 0.6 0l1.8 1.1c0.4 0.2 0.7 0.9 0.7 1.4 0 0.3-0.1 0.6-0.3 0.7-0.1 0.1-0.2 0.1-0.3 0.1z"
            fill="#151411"
          />
          <Path
            d="m148.1 142.2c0 0.5 0.3 1.1 0.7 1.3l1.8 1.1c0.4 0.3 0 0.4-0.2 0.2l-1.8-1.1c-0.4-0.2-0.7-0.8-0.7-1.3 0-0.3 0.2-0.8 0.2-0.2"
            fill="#151411"
          />
          <Path
            d="m150.6 145c-0.1 0-0.2 0-0.3-0.1l-1.8-1.1c-0.4-0.2-0.7-0.9-0.7-1.4 0-0.2 0.1-0.5 0.2-0.6h0.1 0.1c0.1 0.1 0.1 0.2 0.1 0.4 0 0.4 0.3 1 0.6 1.2l1.8 1.1c0.1 0.1 0.3 0.2 0.3 0.3s0 0.1-0.1 0.2h-0.3"
            fill="#151411"
          />
          <Path
            d="m167.2 114.9c-0.1 0-0.2 0-0.3 0.1s-0.1 0.1-0.1 0.2v2c0.1 0 0.2 0 0.3-0.1s0.1-0.1 0.1-0.2v-0.1-2c0.2 0.2 0.1 0.1 0 0.1 0.1 0 0.1 0 0 0zm-0.2 2.7c-0.1 0-0.3 0-0.4-0.1s-0.1-0.2-0.2-0.3v-2.1c0-0.1 0.1-0.3 0.2-0.4 0.2-0.1 0.4-0.2 0.5-0.2s0.3 0 0.4 0.1l0.2 0.2v2 0.1c0 0.1-0.1 0.3-0.2 0.4-0.1 0.2-0.3 0.3-0.5 0.3z"
            fill={blue500}
          />
          <Path
            d="m166.9 120.4s0.1 0 0 0c0.2 0 0.3 0 0.3-0.1 0.1-0.1 0.1-0.1 0.1-0.2v-2h-0.1c-0.1 0-0.2 0-0.3 0.1s-0.1 0.1-0.1 0.2l0.1 2zm0.1 0.3c-0.1 0-0.3 0-0.4-0.1s-0.1-0.2-0.2-0.2v-2.1c0-0.1 0.1-0.3 0.2-0.4 0.2-0.1 0.4-0.2 0.5-0.2s0.3 0 0.4 0.1 0.1 0.2 0.1 0.2v2.1c0 0.1-0.1 0.3-0.2 0.4 0 0.2-0.2 0.2-0.4 0.2z"
            fill={blue500}
          />
          <Path
            d="m166.9 113.6v0.4h0.1c0.1 0 0.2 0 0.3-0.1s0.1-0.1 0.1-0.2v-0.1-0.4-0.1h-0.1c-0.1 0-0.2 0-0.3 0.1-0.1 0.2-0.1 0.3-0.1 0.4zm0.1 0.8c-0.1 0-0.3 0-0.4-0.1s-0.1-0.2-0.2-0.3v-0.6c0-0.1 0.1-0.2 0.2-0.3 0.2-0.1 0.4-0.2 0.5-0.2s0.3 0 0.4 0.1l0.2 0.2v0.5c0 0.1-0.1 0.3-0.2 0.4-0.1 0.3-0.3 0.3-0.5 0.3z"
            fill={blue500}
          />
          <Path
            d="m162.2 96.7c0.2 0.2 0.6 0.2 0.9 0.1 0.1-0.1 0.2-0.2 0.2-0.3s-0.1-0.2-0.1-0.2c-0.2-0.2-0.6-0.2-0.9-0.1-0.1 0.1-0.2 0.2-0.2 0.3v0.1l0.1 0.1zm1.4-0.2c0 0.2-0.1 0.4-0.4 0.5-0.4 0.2-0.9 0.2-1.2-0.1-0.1-0.1-0.2-0.3-0.2-0.5s0.1-0.4 0.4-0.5c0.4-0.2 0.9-0.2 1.2 0.1l0.2 0.2v0.3z"
            fill={blue500}
          />
          <Path
            d="m165.8 100.4-0.1-0.3s1.1-0.3 1.8-0.9l0.2 0.2c-0.7 0.8-1.8 1-1.9 1"
            fill={blue500}
          />
          <Path
            d="m165.8 152-0.1-0.3s1-0.2 1.8-1l0.2 0.2c-0.8 0.8-1.9 1-1.9 1.1"
            fill={blue500}
          />
          <Polygon
            points="167.7 111.7 168 111.7 168 106.2 167.7 106.2"
            fill={blue500}
          />
          <Path
            d="m161.7 116.9-24.1-14c-0.5-0.3-0.9-1.1-0.9-1.8v-8.8c0-0.7 0.4-1 0.9-0.7l24.1 14c0.5 0.3 0.9 1.1 0.9 1.8v8.8c0.1 0.7-0.3 1-0.9 0.7"
            fill={blue500}
          />
          <Path
            d="m161.9 116.7c0.1 0.1 0.3 0.1 0.4 0s0.2-0.3 0.2-0.6v-8.8c0-0.6-0.4-1.3-0.8-1.6l-24.1-14c-0.1-0.1-0.3-0.1-0.4 0s-0.2 0.3-0.2 0.6v8.8c0 0.6 0.4 1.3 0.8 1.6l24.1 14zm0.2 0.6c-0.2 0-0.3 0-0.5-0.1l-24.1-14c-0.6-0.3-1.1-1.2-1.1-2v-8.8c0-0.4 0.2-0.8 0.4-1 0.2-0.1 0.5-0.1 0.8 0l24.1 14c0.6 0.3 1.1 1.2 1.1 2v8.8c0 0.4-0.2 0.8-0.4 1 0 0-0.2 0.1-0.3 0.1z"
            fill="#151411"
          />
          <Path
            d="m147.5 102.9c0 2.2-1.5 3-3.4 2-1.9-1.1-3.4-3.7-3.4-5.9s1.5-3 3.4-2c1.9 1.1 3.4 3.8 3.4 5.9"
            fill="#151411"
          />
          <Path
            d="m141.4 96.2c-0.3 0-0.5 0.1-0.8 0.2-0.6 0.3-0.9 1-0.9 2 0 2.1 1.5 4.6 3.3 5.7 0.8 0.5 1.6 0.6 2.2 0.2 0.6-0.3 0.9-1 0.9-2 0-2.1-1.5-4.6-3.3-5.7-0.5-0.2-1-0.4-1.4-0.4zm3 8.8c-0.5 0-1.1-0.2-1.6-0.5-1.9-1.1-3.5-3.8-3.5-6.1 0-1.1 0.4-2 1.1-2.4s1.6-0.3 2.6 0.2c1.9 1.1 3.5 3.8 3.5 6.1 0 1.1-0.4 2-1.1 2.4-0.3 0.2-0.6 0.3-1 0.3z"
            fill="#151411"
          />
          <Path
            d="m144 97c-0.5-0.3-0.9-0.4-1.3-0.4h0.1c1.8 1 3.3 3.6 3.3 5.7 0 1-0.3 1.7-0.9 2-0.5 0.3-1.3 0.2-2.1-0.2 0.3 0.3 0.6 0.5 1 0.7 0 0 0.1 0 0.1 0.1h0.2c0.4 0 0.7-0.1 1-0.3 0.7-0.4 1.1-1.3 1.1-2.4 0-1.7-1.1-3.9-2.5-5.2"
            fill={blue500}
          />
          <Path
            d="m147.3 117.8-9.5-5.5c-0.6-0.3-1-1.2-1-1.9v-4.6c0-0.7 0.5-1.1 1-0.8l9.5 5.5c0.6 0.3 1 1.2 1 1.9v4.6c0 0.8-0.5 1.2-1 0.8"
            fill={blue500}
          />
          <Path
            d="m137.3 105.1h-0.1c-0.1 0.1-0.2 0.3-0.2 0.6v4.6c0 0.7 0.4 1.5 0.9 1.7l9.5 5.5c0.1 0.1 0.3 0.1 0.4 0.1 0.1-0.1 0.2-0.3 0.2-0.6v-4.6c0-0.7-0.4-1.5-0.9-1.7l-9.5-5.5c-0.1 0-0.2-0.1-0.3-0.1zm10.4 13.1c-0.2 0-0.3-0.1-0.5-0.2l-9.5-5.5c-0.6-0.4-1.1-1.3-1.1-2.1v-4.6c0-0.5 0.2-0.8 0.5-1 0.3-0.1 0.6-0.1 0.9 0.1l9.5 5.5c0.6 0.4 1.1 1.3 1.1 2.1v4.6c0 0.5-0.2 0.8-0.5 1-0.2 0.1-0.3 0.1-0.4 0.1z"
            fill="#151411"
          />
          <Path
            d="m161.7 143.8-24.1-14c-0.5-0.3-0.9-1.1-0.9-1.8v-9.1c0-0.7 0.4-1 0.9-0.7l24.1 14c0.5 0.3 0.9 1.1 0.9 1.8v9.1c0.1 0.7-0.3 1-0.9 0.7"
            fill={blue500}
          />
          <Path
            d="m137.3 118.3h-0.1c-0.1 0.1-0.2 0.3-0.2 0.6v9.1c0 0.6 0.4 1.4 0.8 1.6l24.1 14c0.1 0.1 0.3 0.1 0.4 0s0.2-0.3 0.2-0.6v-9.1c0-0.6-0.4-1.4-0.8-1.6l-24.1-14h-0.3zm24.8 25.8c-0.2 0-0.3 0-0.5-0.1l-24.1-14c-0.6-0.3-1.1-1.2-1.1-2v-9.1c0-0.5 0.2-0.8 0.4-1 0.2-0.1 0.5-0.1 0.8 0.1l24.1 14c0.6 0.3 1.1 1.2 1.1 2v9.1c0 0.5-0.2 0.8-0.4 1h-0.3z"
            fill="#151411"
          />
          <Path
            d="m163.2 123.8-11.6-6.7c-0.4-0.2-0.7-0.8-0.7-1.3v-1.1c0-0.5 0.3-0.7 0.7-0.5l11.6 6.7c0.4 0.2 0.7 0.8 0.7 1.3v1.1c0 0.5-0.3 0.7-0.7 0.5"
            fill={blue50}
          />
          <Path
            d="m162.1 121.8c0.1 0 0.2 0.1 0.2 0 0.1 0 0.1-0.1 0.1-0.4v-1.1c0-0.4-0.3-0.9-0.6-1.1l-11.6-6.7c-0.1 0-0.1-0.1-0.2 0-0.1 0-0.1 0.1-0.1 0.4v1.1c0 0.4 0.3 0.9 0.6 1.1l11.6 6.7zm0.2 0.5c-0.1 0-0.3 0-0.4-0.1l-11.6-6.7c-0.4-0.3-0.8-0.9-0.8-1.5v-1.1c0-0.3 0.1-0.6 0.3-0.7s0.4-0.1 0.7 0l11.6 6.7c0.4 0.3 0.8 0.9 0.8 1.5v1.1c0 0.3-0.1 0.6-0.3 0.7-0.1 0.1-0.2 0.1-0.3 0.1z"
            fill="#151411"
          />
          <Path
            d="m163.2 128.2-11.6-6.7c-0.4-0.2-0.7-0.8-0.7-1.3v-1.1c0-0.5 0.3-0.7 0.7-0.5l11.6 6.7c0.4 0.2 0.7 0.8 0.7 1.3v1.1c0 0.5-0.3 0.7-0.7 0.5"
            fill={blue50}
          />
          <Path
            d="m150.1 116.9c-0.1 0-0.2 0.2-0.2 0.4v1.1c0 0.4 0.3 0.9 0.6 1.1l11.6 6.7c0.1 0 0.2 0.1 0.2 0 0.1 0 0.1-0.1 0.1-0.4v-1.1c0-0.4-0.3-0.9-0.6-1.1l-11.6-6.7h-0.1zm12.2 9.8c-0.1 0-0.3 0-0.4-0.1l-11.6-6.7c-0.4-0.3-0.8-0.9-0.8-1.5v-1.1c0-0.3 0.1-0.6 0.3-0.7s0.4-0.1 0.7 0l11.6 6.7c0.4 0.3 0.8 0.9 0.8 1.5v1.1c0 0.3-0.1 0.6-0.3 0.7-0.1 0.1-0.2 0.1-0.3 0.1z"
            fill={blue50}
          />
          <Path
            d="m163.3 132.6-24.8-14.4c-0.3-0.2-0.6-0.7-0.6-1.1v-1.3c0-0.4 0.3-0.6 0.6-0.5l24.8 14.4c0.3 0.2 0.6 0.7 0.6 1.1v1.3c0 0.5-0.3 0.7-0.6 0.5"
            fill={blue50}
          />
          <Path
            d="m162.2 130.6s0.1 0.1 0.2 0c0 0 0.1-0.1 0.1-0.3v-1.3c0-0.4-0.2-0.8-0.5-1l-24.8-14.4s-0.1-0.1-0.2 0c0 0-0.1 0.1-0.1 0.3v1.3c0 0.4 0.2 0.8 0.5 1l24.8 14.4zm0.1 0.5c-0.1 0-0.2 0-0.4-0.1l-24.8-14.4c-0.4-0.2-0.7-0.8-0.7-1.3v-1.3c0-0.3 0.1-0.6 0.3-0.7s0.4-0.1 0.6 0l24.8 14.4c0.4 0.2 0.7 0.8 0.7 1.3v1.3c0 0.3-0.1 0.6-0.3 0.7 0 0.1-0.1 0.1-0.2 0.1z"
            fill="#151411"
          />
          <Path
            d="m26.7 116.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m28.9 115.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m31.1 114.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m33.3 113c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m35.4 111.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m37.6 110.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m39.8 109.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m42 107.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m24.5 115.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m26.7 114.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m28.9 113c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m31.1 111.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m33.3 110.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m35.4 109.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m37.6 107.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m39.8 106.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m22.4 114.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m24.5 113c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m26.7 111.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m28.9 110.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m31.1 109.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m33.3 107.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m35.4 106.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m37.6 105.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m20.2 113c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.3 0-0.5c0.2-0.1 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m22.4 111.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m24.5 110.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m26.7 109.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m28.9 107.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m31.1 106.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m33.3 105.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m35.4 104.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m18 111.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m20.2 110.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m22.4 109.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m24.5 107.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m26.7 106.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m28.9 105.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m31.1 104.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m33.3 102.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m15.8 110.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m18 109.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m20.2 107.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m22.3 106.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m24.5 105.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m26.7 104.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m28.9 102.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m31.1 101.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m13.6 109.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m15.8 107.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m18 106.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m20.2 105.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m22.3 104.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m24.5 102.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m26.7 101.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m28.9 100.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m11.4 107.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m13.6 106.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m15.8 105.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m18 104.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m20.2 102.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m22.3 101.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m24.5 100.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m26.7 99.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m9.3 106.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m11.4 105.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m13.6 104.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m15.8 102.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m18 101.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m20.2 100.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m22.3 99.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m7.1 105.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m9.3 104.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m11.4 102.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m13.6 101.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m15.8 100.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m18 99.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m4.9 104.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m7.1 102.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m9.3 101.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m11.4 100.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.3 0-0.5c0.2-0.1 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m13.6 99.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m2.7 102.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m4.9 101.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m7.1 100.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.3 0-0.5c0.2-0.1 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m9.3 99.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m11.4 98.3c-0.2 0.1-0.6 0.1-0.8 0s-0.2-0.4 0-0.5 1.1 0.4 0.8 0.5"
            fill="#151411"
          />
          <Path
            d="m2.7 100.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m4.9 99.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m7.1 97.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m61.7 134.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m63.9 133.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m66.1 131.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m68.3 130.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.3 0-0.5c0.2-0.1 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m70.5 129.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.1-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m72.6 128c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m74.8 126.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m57.4 134.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m59.6 133.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.1-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m61.7 131.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m63.9 130.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m66.1 129.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m68.3 128c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m70.5 126.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.1-0.1 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m72.6 125.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m55.2 133.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m57.4 131.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m59.6 130.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.1-0.1 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m61.7 129.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m63.9 128c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m66.1 126.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m68.3 125.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m70.5 124.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.1-0.1 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m53 131.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m55.2 130.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m57.4 129.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.1-0.1 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m59.5 128c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m61.7 126.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m63.9 125.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m66.1 124.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m68.3 122.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.1-0.1 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m50.8 130.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m53 129.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m55.2 128c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m57.4 126.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.1-0.1 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m59.5 125.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m61.7 124.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m63.9 122.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m66.1 121.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m48.6 129.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m50.8 128c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m53 126.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m55.2 125.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m57.4 124.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.1-0.1 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m59.5 122.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m61.7 121.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m63.9 120.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m46.5 128c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.1-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m48.6 126.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m50.8 125.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m53 124.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m55.2 122.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m57.4 121.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.1-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m59.5 120.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m61.7 119.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m44.3 126.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m46.5 125.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.1-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m48.6 124.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m50.8 122.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m53 121.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m55.2 120.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m57.4 119.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.1-0.1 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m59.5 117.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.3 0-0.5 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m42.1 125.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m44.3 124.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m46.5 122.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.1-0.1 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m48.6 121.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m50.8 120.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m53 119.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m55.2 117.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.3 0-0.5 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m57.4 116.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.1-0.1 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m39.9 124.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m42.1 122.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m44.3 121.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m46.5 120.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.1-0.1 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m48.6 119.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m50.8 117.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m53 116.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m55.2 115.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m37.7 122.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m39.9 121.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m42.1 120.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m44.3 119.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m46.5 117.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.1-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m48.6 116.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m50.8 115.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m53 114.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m35.5 121.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m37.7 120.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m39.9 119.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m42.1 117.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m44.3 116.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.1-0.1 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m46.4 115.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m48.6 114.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m50.8 112.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m33.4 120.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.1-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m35.5 119.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m37.7 117.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m39.9 116.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m42.1 115.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m44.3 114.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.1-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m46.4 112.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m48.6 111.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m31.2 119.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m33.4 117.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.1-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m35.5 116.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m37.7 115.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m39.9 114.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m42.1 112.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m44.3 111.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.1-0.1 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m46.4 110.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m29 117.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m31.2 116.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m33.4 115.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.1-0.1 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m35.5 114.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m37.7 112.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m39.9 111.5c0.2 0.1 0.2 0.3 0 0.5-0.2 0.1-0.6 0.1-0.8 0s-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m42.1 110.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m44.3 109c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.1-0.1 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m44.2 106.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m46.4 105.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.1-0.1 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m48.5 104.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m50.7 102.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m52.9 101.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m55.1 100.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m57.3 99.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m41.2 105.4c0.2-0.1 0.6-0.1 0.8 0s0.2 0.4 0 0.5-0.6 0.1-0.8 0c-0.3-0.1-0.3-0.3 0-0.5"
            fill="#151411"
          />
          <Path
            d="m44.2 104.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m46.4 102.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.1-0.1 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m48.5 101.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m50.7 100.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m52.9 99.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m39 104.2c0.2-0.1 0.6-0.1 0.8 0s0.2 0.4 0 0.5-0.6 0.1-0.8 0c-0.3-0.2-0.3-0.4 0-0.5"
            fill="#151411"
          />
          <Path
            d="m42 102.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m44.2 101.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m46.4 100.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.1-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m48.5 99.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m36.8 102.9c0.2-0.1 0.6-0.1 0.8 0s0.2 0.3 0 0.5c-0.2 0.1-0.6 0.1-0.8 0-0.2-0.2-0.2-0.4 0-0.5"
            fill="#151411"
          />
          <Path
            d="m39.8 101.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m42 100.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m44.2 99.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m34.6 101.6c0.2-0.1 0.6-0.1 0.8 0s0.2 0.4 0 0.5-0.6 0.1-0.8 0-0.2-0.3 0-0.5"
            fill="#151411"
          />
          <Path
            d="m37.6 100.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m39.8 99.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m32.4 100.4c0.2-0.1 0.6-0.1 0.8 0s0.2 0.3 0 0.5c-0.2 0.1-0.6 0.1-0.8 0-0.2-0.2-0.2-0.4 0-0.5"
            fill="#151411"
          />
          <Path
            d="m35.4 99.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m30.2 99.1c0.2-0.1 0.6-0.1 0.8 0s0.2 0.4 0 0.5-0.6 0.1-0.8 0c-0.2-0.2-0.2-0.4 0-0.5"
            fill="#151411"
          />
          <Path
            d="m76.2 125.5c0.2-0.1 0.6-0.1 0.8 0s0.2 0.4 0 0.5-0.6 0.1-0.8 0c-0.3-0.2-0.3-0.4 0-0.5"
            fill="#151411"
          />
          <Path
            d="m79.2 124.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m81.4 123c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m83.5 121.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m85.7 120.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m87.9 119.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m90.1 117.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m92.3 116.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m94.4 115.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m96.6 114.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m98.8 112.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m101 111.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m74 124.2c0.2-0.1 0.6-0.1 0.8 0s0.2 0.4 0 0.5-0.6 0.1-0.8 0-0.2-0.3 0-0.5"
            fill="#151411"
          />
          <Path
            d="m77 123c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m79.2 121.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m81.4 120.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m83.5 119.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m85.7 117.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m87.9 116.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m90.1 115.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m92.3 114.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m98.8 110.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m101 109c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m94.4 112.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m96.6 111.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m71.8 123c0.2-0.1 0.6-0.1 0.8 0s0.2 0.3 0 0.5c-0.2 0.1-0.6 0.1-0.8 0-0.2-0.2-0.2-0.4 0-0.5"
            fill="#151411"
          />
          <Path
            d="m74.8 121.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m77 120.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m79.2 119.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m81.4 117.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m83.5 116.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m85.7 115.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m87.9 114.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m90.1 112.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m96.6 109c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m98.8 107.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m92.3 111.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m94.4 110.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.3 0-0.5c0.2-0.1 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m69.6 121.7c0.2-0.1 0.6-0.1 0.8 0s0.2 0.4 0 0.5-0.6 0.1-0.8 0c-0.2-0.2-0.2-0.4 0-0.5"
            fill="#151411"
          />
          <Path
            d="m72.6 120.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m74.8 119.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m77 117.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m79.2 116.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m81.3 115.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m83.5 114.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m85.7 112.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m87.9 111.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m94.4 107.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m96.6 106.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m90.1 110.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m92.2 109c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m67.4 120.4c0.2-0.1 0.6-0.1 0.8 0s0.2 0.3 0 0.5c-0.2 0.1-0.6 0.1-0.8 0s-0.2-0.4 0-0.5"
            fill="#151411"
          />
          <Path
            d="m70.4 119.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m72.6 117.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m74.8 116.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m77 115.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m79.2 114.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m81.3 112.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m83.5 111.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m90.1 107.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m92.2 106.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m94.4 105.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m96.6 104.5c-0.2 0.1-0.6 0.1-0.8 0s-0.2-0.4 0-0.5c0.2-0.2 1 0.3 0.8 0.5"
            fill="#151411"
          />
          <Path
            d="m85.7 110.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m87.9 109c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m65.2 119.1c0.2-0.1 0.6-0.1 0.8 0s0.2 0.4 0 0.5-0.6 0.1-0.8 0-0.2-0.3 0-0.5"
            fill="#151411"
          />
          <Path
            d="m68.3 117.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m70.4 116.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m72.6 115.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m74.8 114.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m77 112.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m79.2 111.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m81.3 110.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m87.9 106.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m90.1 105.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m92.2 104c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m83.5 109c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m85.7 107.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m63.1 117.9c0.2-0.1 0.6-0.1 0.8 0s0.2 0.3 0 0.5c-0.2 0.1-0.6 0.1-0.8 0-0.3-0.2-0.3-0.4 0-0.5"
            fill="#151411"
          />
          <Path
            d="m66.1 116.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m68.3 115.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m70.4 114.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m72.6 112.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m74.8 111.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m77 110.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m79.2 109c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m85.7 105.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m87.9 104c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m90.1 102.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m81.3 107.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m83.5 106.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m60.9 116.6c0.2-0.1 0.6-0.1 0.8 0s0.2 0.3 0 0.5c-0.2 0.1-0.6 0.1-0.8 0s-0.2-0.3 0-0.5"
            fill="#151411"
          />
          <Path
            d="m63.9 115.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m66.1 114.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m68.3 112.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m70.4 111.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m72.6 110.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m74.8 109c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m77 107.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m79.2 106.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m85.7 102.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m81.3 105.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m83.5 104c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m58.7 115.4c0.2-0.1 0.6-0.1 0.8 0s0.2 0.4 0 0.5-0.6 0.1-0.8 0c-0.2-0.2-0.2-0.4 0-0.5"
            fill="#151411"
          />
          <Path
            d="m61.7 114.1c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m63.9 112.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m66.1 111.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m68.3 110.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m70.4 109c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m72.6 107.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m74.8 106.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m77 105.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m79.2 104c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m81.3 102.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m83.5 101.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m56.5 114.1c0.2-0.1 0.6-0.1 0.8 0s0.2 0.3 0 0.5c-0.2 0.1-0.6 0.1-0.8 0-0.2-0.2-0.2-0.4 0-0.5"
            fill="#151411"
          />
          <Path
            d="m59.5 112.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m61.7 111.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m63.9 110.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m66.1 109c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m68.3 107.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m70.4 106.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m72.6 105.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m74.8 104c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m77 102.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m79.2 101.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m81.3 100.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m54.3 112.8c0.2-0.1 0.6-0.1 0.8 0s0.2 0.4 0 0.5-0.6 0.1-0.8 0-0.2-0.3 0-0.5"
            fill="#151411"
          />
          <Path
            d="m57.4 111.6c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m59.5 110.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m61.7 109c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m63.9 107.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m66.1 106.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m68.3 105.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m70.4 104c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m72.6 102.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m74.8 101.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m77 100.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m52.1 111.5c0.2-0.1 0.6-0.1 0.8 0s0.2 0.3 0 0.5c-0.2 0.1-0.6 0.1-0.8 0s-0.2-0.3 0-0.5"
            fill="#151411"
          />
          <Path
            d="m55.2 110.3c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m57.3 109c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m59.5 107.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m61.7 106.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m63.9 105.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m66.1 104c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m68.2 102.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m70.4 101.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m72.6 100.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m50 110.3c0.2-0.1 0.6-0.1 0.8 0s0.2 0.3 0 0.5c-0.2 0.1-0.6 0.1-0.8 0-0.3-0.2-0.3-0.4 0-0.5"
            fill="#151411"
          />
          <Path
            d="m53 109c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m55.2 107.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m57.3 106.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m59.5 105.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m61.7 104c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m63.9 102.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m66.1 101.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m68.2 100.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m70.4 98.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m47.8 109c0.2-0.1 0.6-0.1 0.8 0s0.2 0.4 0 0.5-0.6 0.1-0.8 0-0.2-0.3 0-0.5"
            fill="#151411"
          />
          <Path
            d="m50.8 107.8c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m53 106.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m55.2 105.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m57.3 104c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m59.5 102.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m61.7 101.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m63.9 100.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m66.1 98.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m45.6 107.8c0.2-0.1 0.6-0.1 0.8 0s0.2 0.3 0 0.5c-0.2 0.1-0.6 0.1-0.8 0-0.2-0.2-0.2-0.4 0-0.5"
            fill="#151411"
          />
          <Path
            d="m48.6 106.5c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m50.8 105.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m53 104c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.5-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m55.2 102.7c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.5-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m57.3 101.4c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m59.5 100.2c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5c0.2-0.2 0.6-0.2 0.8 0"
            fill="#151411"
          />
          <Path
            d="m61.7 98.9c0.2 0.1 0.2 0.4 0 0.5s-0.6 0.1-0.8 0-0.2-0.4 0-0.5 0.6-0.1 0.8 0"
            fill="#151411"
          />
          <Path
            d="m102.1 101.7 0.1-47.3c0-1.4-0.8-3-1.9-3.6l-53.6-31c-0.4-0.3-0.9-0.3-1.2-0.1l-1.5 0.7v0.1l1.4 1.9v45.9c0 1.4 0.8 3 1.9 3.6l52.3 32.2 0.5-0.2 1.1-0.6 0.3-0.1c0.3-0.3 0.6-0.8 0.6-1.5"
            fill="#151411"
          />
          <Path
            d="m44.4 20.5 1.3 1.8v0.1 45.9c0 1.3 0.8 2.8 1.7 3.3l52.2 32.1 0.4-0.2 1.3-0.7c0.3-0.2 0.5-0.6 0.5-1.2l0.1-47.3c0-1.3-0.8-2.8-1.7-3.3l-53.7-31c-0.2-0.1-0.6-0.3-0.9-0.1l-0.3 0.1-0.9 0.5zm55.2 84-0.1-0.1-52.4-32.2c-1.1-0.7-2-2.4-2-3.9v-45.8l-1.3-1.9c-0.1-0.1-0.1-0.2-0.1-0.3v-0.1l0.2-0.1 1.7-0.9c0.4-0.1 0.8 0 1.2 0.2l53.6 31c1.1 0.7 2 2.4 2 3.9l-0.1 47.3c0 0.8-0.3 1.4-0.7 1.7l-1.4 0.8-0.6 0.4z"
            fill="#151411"
          />
          <Path
            d="m98.9 104.1-53.6-31.1c-1-0.6-1.9-2.2-1.9-3.6v-47.5c0-1.4 0.8-2 1.9-1.4l53.6 31c1 0.6 1.9 2.2 1.9 3.6v46.1c0 1.5-0.8 3.5-1.9 2.9"
            fill={blue500}
          />
          <Path
            d="m99.9 53.1-53.6-31c-1-0.6-1.9 0-1.9 1.4v47.5c0 0.4 0.1 0.9 0.2 1.3 0.2 0.3 0.5 0.6 0.8 0.7l53.6 31c1 0.6 1.9-1.4 1.9-2.8v-46.1c0-0.4-0.1-0.9-0.2-1.3-0.3-0.3-0.5-0.5-0.8-0.7"
            fill={blue50}
          />
          <Path
            d="m44.5 20.6c-0.1 0-0.2 0-0.3 0.1-0.3 0.2-0.5 0.6-0.5 1.3v47.5c0 1.3 0.8 2.8 1.7 3.3l53.6 31c0.2 0.1 0.3 0.1 0.4 0 0.5-0.3 1-1.5 1-2.6v-46.1c0-1.3-0.8-2.8-1.7-3.3l-53.6-31c-0.2-0.1-0.4-0.2-0.6-0.2zm54.8 83.9c-0.2 0-0.4 0-0.5-0.1l-53.6-31c-1.1-0.7-2-2.4-2-3.9v-47.5c0-0.8 0.3-1.5 0.8-1.8 0.4-0.3 1-0.2 1.5 0.1l53.6 31c1.1 0.7 2 2.4 2 3.9v46.1c0 1.1-0.5 2.6-1.3 3.1-0.1 0.1-0.3 0.1-0.5 0.1z"
            fill="#151411"
          />
          <Path
            d="m97.6 99.2-51.1-29.6c-0.5-0.3-0.8-1-0.8-1.6v-42.8c0-0.6 0.4-0.9 0.8-0.6l51.1 29.6c0.5 0.3 0.8 1 0.8 1.6v42.8c0 0.6-0.3 0.8-0.8 0.6"
            fill={blue500}
          />
          <Path
            d="m97.8 98.9s0.2 0.1 0.2 0c0.1 0 0.1-0.2 0.1-0.4v-42.8c0-0.5-0.3-1.1-0.7-1.3l-51-29.5s-0.2-0.1-0.2 0c-0.1 0-0.1 0.2-0.1 0.4l-0.1 42.7c0 0.5 0.3 1.1 0.7 1.3l51.1 29.6zm0.1 0.7c-0.2 0-0.3 0-0.5-0.1l-51-29.6c-0.5-0.3-1-1.1-1-1.9v-42.8c0-0.4 0.2-0.8 0.4-0.9s0.5-0.1 0.8 0l51.1 29.6c0.5 0.3 1 1.1 1 1.9v42.8c0 0.4-0.2 0.8-0.4 0.9-0.1 0.1-0.2 0.1-0.4 0.1z"
            fill="#151411"
          />
          <Path
            d="m72.7 39.1-0.2-0.1c-0.2-0.2-0.5-0.6-0.5-1s0.2-0.5 0.5-0.4l0.2 0.1c0.3 0.2 0.5 0.6 0.5 0.9 0 0.5-0.2 0.6-0.5 0.5"
            fill={blue500}
          />
          <Path
            d="m72.3 38.2v-0.1c0-0.3-0.2-0.6-0.3-0.7l-0.2-0.1v0.1c0 0.3 0.2 0.6 0.3 0.7l0.2 0.1zm0 0.6c-0.1 0-0.2 0-0.3-0.1l-0.2-0.1c-0.4-0.2-0.6-0.7-0.6-1.2 0-0.3 0.1-0.5 0.3-0.7 0.2-0.1 0.4-0.1 0.6 0l0.2 0.1c0.4 0.2 0.6 0.7 0.6 1.2 0 0.3-0.1 0.5-0.3 0.7-0.1 0-0.2 0.1-0.3 0.1z"
            fill="#151411"
          />
          <Path
            d="m98.5 103.9-3-0.3-51.3-29.6c-1-0.6-2.9-0.5-4 0.2l-37.5 21.7-2-0.2v0.7c0 0.4 0.2 1.5 0.7 1.7l53.6 31c1 0.6 2.9 0.5 4-0.2l38.4-21c0.7-0.4 1-0.9 1-1.3v-0.7l0.1-2"
            fill="#151411"
          />
          <Path
            d="m1 96.1v0.4c0 0.4 0.2 1.3 0.5 1.5l53.6 31c0.9 0.5 2.7 0.5 3.8-0.2l38.4-21c0.5-0.3 0.8-0.7 0.8-1.1v-2.5l-2.9-0.3-51.2-29.6c-1-0.6-2.6-0.5-3.8 0.2l-37.4 21.7-1.8-0.1zm55.8 33.9c-0.7 0-1.4-0.1-1.9-0.4l-53.6-31c-0.6-0.4-0.9-1.6-0.8-2v-1l2.2 0.2 37.3-21.9c1.3-0.8 3.2-0.8 4.3-0.2l51.3 29.7 3.2 0.3v3.1c0 0.6-0.4 1.2-1.1 1.6l-38.4 21c-0.7 0.4-1.6 0.6-2.5 0.6z"
            fill="#151411"
          />
          <Path
            d="m55.1 127.8-53.6-31c-1-0.6-0.9-1.7 0.3-2.3l38.4-22.3c1.2-0.7 3-0.8 4-0.2l53.6 31c1 0.6 0.9 1.7-0.3 2.3l-38.4 22.3c-1.2 0.7-3 0.8-4 0.2"
            fill={blue500}
          />
          <Path
            d="m97.8 104.2-53.6-31c-1-0.6-2.9-0.5-4 0.2l-38.5 22.3c-0.3 0.2-0.6 0.4-0.7 0.6 0.1 0.2 0.3 0.3 0.5 0.5l53.6 31c1 0.6 2.9 0.5 4-0.2l38.4-22.3c0.3-0.2 0.6-0.4 0.7-0.6 0-0.2-0.2-0.3-0.4-0.5"
            fill={blue50}
          />
          <Path
            d="m55.2 127.5c0.9 0.5 2.7 0.5 3.8-0.2l38.4-22.3c0.5-0.3 0.8-0.7 0.8-1.1s-0.3-0.6-0.6-0.8l-53.6-30.9c-1-0.6-2.6-0.5-3.8 0.2l-38.3 22.3c-0.6 0.3-0.9 0.7-0.9 1 0 0.4 0.3 0.6 0.6 0.8l53.6 31zm1.6 1c-0.7 0-1.4-0.1-1.9-0.4l-53.6-31.1c-0.5-0.3-0.9-0.8-0.9-1.3 0-0.6 0.4-1.2 1.1-1.6l38.5-22.3c1.3-0.8 3.2-0.8 4.3-0.2l53.6 31c0.5 0.3 0.9 0.8 0.9 1.3 0 0.6-0.4 1.2-1.1 1.6l-38.4 22.3c-0.7 0.5-1.6 0.7-2.5 0.7z"
            fill="#151411"
          />
          <Path
            d="m70.5 117.3-51.1-29.5c-0.5-0.3-0.4-0.7 0.1-1l21.5-12.6c0.5-0.3 1.3-0.3 1.8-0.1l51.1 29.6c0.5 0.3 0.4 0.7-0.1 1l-21.5 12.5c-0.5 0.4-1.3 0.4-1.8 0.1"
            fill={blue500}
          />
          <Path
            d="m70.6 117.1c0.4 0.2 1.1 0.2 1.5-0.1l21.5-12.5c0.2-0.1 0.3-0.3 0.3-0.3 0-0.1-0.1-0.2-0.2-0.2l-51.1-29.6c-0.4-0.2-1.1-0.2-1.5 0.1l-21.4 12.5c-0.2 0.1-0.3 0.3-0.3 0.3 0 0.1 0.1 0.2 0.2 0.2l51 29.6zm0.7 0.7c-0.3 0-0.7-0.1-0.9-0.2l-51.1-29.6c-0.3-0.2-0.5-0.4-0.5-0.7s0.2-0.6 0.6-0.8l21.5-12.5c0.6-0.4 1.5-0.4 2.1-0.1l51 29.6c0.3 0.2 0.5 0.4 0.5 0.7s-0.2 0.6-0.6 0.8l-21.5 12.5c-0.3 0.2-0.7 0.3-1.1 0.3z"
            fill="#151411"
          />
          <Path
            d="m37.4 115.1-14.1-8.2c-0.2-0.1-0.2-0.4 0.1-0.6l11.4-6.7c0.3-0.2 0.7-0.2 1 0l14.1 8.2c0.2 0.1 0.2 0.4-0.1 0.6l-11.4 6.7c-0.3 0.1-0.7 0.2-1 0"
            fill={blue500}
          />
          <Path
            d="m37.6 114.8c0.1 0.1 0.4 0.1 0.7 0l11.4-6.7h0.1l-14.1-8.2c-0.1-0.1-0.4-0.1-0.7 0l-11.4 6.7h-0.1l14.1 8.2zm0.3 0.7c-0.2 0-0.4 0-0.6-0.1l-14.1-8.2c-0.2-0.1-0.3-0.3-0.3-0.5s0.1-0.4 0.4-0.6l11.4-6.7c0.4-0.2 0.9-0.2 1.3 0l14.1 8.2c0.2 0.1 0.3 0.3 0.3 0.5s-0.1 0.4-0.4 0.6l-11.4 6.7c-0.3 0-0.5 0.1-0.7 0.1z"
            fill="#151411"
          />
          <Path
            d="m19.4 87.8 0.3 0.1 21.3-12.4c0.5-0.3 1.3-0.3 1.8-0.1l50.8 29.4 0.1-0.1c0.5-0.3 0.6-0.8 0.1-1l-51-29.5c-0.5-0.3-1.3-0.2-1.8 0.1l-21.5 12.4c-0.5 0.4-0.5 0.8-0.1 1.1"
            fill="#151411"
          />
          <Path
            d="m42 74.9c0.3 0 0.7 0.1 0.9 0.2l50.7 29.3c0.2-0.1 0.3-0.2 0.3-0.3s-0.1-0.2-0.2-0.2l-51.1-29.5c-0.4-0.2-1.1-0.2-1.5 0.1l-21.4 12.5c-0.2 0.1-0.3 0.3-0.3 0.3 0 0.1 0.1 0.2 0.2 0.2l0.1 0.1 21.2-12.3c0.3-0.3 0.7-0.4 1.1-0.4zm51.6 30.3-0.2-0.1-50.8-29.4c-0.4-0.2-1.1-0.2-1.5 0.1l-21.4 12.5-0.4-0.3c-0.3-0.2-0.5-0.4-0.5-0.7s0.2-0.6 0.6-0.8l21.5-12.5c0.6-0.4 1.5-0.4 2.1-0.1l51 29.6c0.3 0.2 0.5 0.4 0.5 0.7s-0.2 0.6-0.6 0.8l-0.3 0.2z"
            fill="#151411"
          />
          <Path
            d="m23.3 106.9 0.4 0.2 11.1-6.5c0.3-0.2 0.7-0.2 1 0l13.7 7.9 0.3-0.2c0.3-0.2 0.3-0.4 0.1-0.6l-14.1-8.2c-0.2-0.1-0.7-0.1-1 0l-11.4 6.7c-0.3 0.3-0.3 0.6-0.1 0.7"
            fill="#151411"
          />
          <Path
            d="m35.4 100.3c0.2 0 0.4 0 0.6 0.1l13.6 7.9 0.2-0.1h0.1l-14.1-8.2c-0.1-0.1-0.4-0.1-0.7 0l-11.4 6.7h-0.1l0.2 0.1 11-6.4c0.1-0.1 0.3-0.1 0.6-0.1zm14.1 8.6-13.9-8c-0.1-0.1-0.4-0.1-0.7 0l-11.3 6.6-0.6-0.3c-0.2-0.1-0.3-0.3-0.3-0.5s0.1-0.4 0.4-0.6l11.4-6.7c0.4-0.2 0.9-0.2 1.3 0l14.1 8.2c0.2 0.1 0.3 0.3 0.3 0.5s-0.1 0.4-0.4 0.6l-0.3 0.2z"
            fill="#151411"
          />
          <Path
            d="m37.5 115.1-13.3-7.7c-0.2-0.1-0.5-0.2-0.2-0.3l2.8-1.6c0.1 0 0.3 0 0.6 0.2l13.2 7.7c0.2 0.1 0.4 0.3 0.3 0.3l-2.8 1.6c-0.1 0-0.4 0-0.6-0.2"
            fill={blue500}
          />
          <Path
            d="m24.4 107.2 13.2 7.6c0.1 0.1 0.3 0.1 0.3 0.1l2.4-1.4-13.2-7.6c-0.1-0.1-0.3-0.1-0.3-0.1l-2.4 1.4zm13.6 8.4c-0.2 0-0.5-0.1-0.6-0.2l-13.4-7.7c-0.2-0.1-0.4-0.2-0.4-0.5 0-0.1 0-0.2 0.2-0.4l2.8-1.6c0.2-0.1 0.6 0 0.9 0.2l13.2 7.7c0.4 0.2 0.5 0.4 0.5 0.5v0.2l-0.2 0.1-2.8 1.6c-0.1 0.1-0.1 0.1-0.2 0.1z"
            fill="#151411"
          />
          <Path
            d="m41 79.2-1.4-0.8c-0.4-0.2-0.3-0.6 0.1-0.8l0.6-0.3c0.4-0.2 1-0.3 1.4-0.1l1.4 0.8c0.4 0.2 0.3 0.6-0.1 0.8l-0.6 0.3c-0.4 0.3-1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m41.1 77.3c-0.2 0-0.5 0.1-0.7 0.2l-0.6 0.3-0.2 0.2s0 0.1 0.1 0.1l1.4 0.8c0.3 0.2 0.8 0.1 1.1-0.1l0.6-0.3 0.2-0.2s0-0.1-0.1-0.1l-1.4-0.8c-0.1-0.1-0.2-0.1-0.4-0.1zm0.5 2.3c-0.3 0-0.6-0.1-0.8-0.2l-1.4-0.8c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l0.6-0.3c0.5-0.3 1.3-0.3 1.7-0.1l1.4 0.8c0.3 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-0.6 0.3c-0.2 0.2-0.5 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m44.6 81.3-1.4-0.8c-0.4-0.2-0.3-0.6 0.1-0.8l0.6-0.3c0.4-0.2 1-0.3 1.4-0.1l1.4 0.8c0.4 0.2 0.3 0.6-0.1 0.8l-0.6 0.3c-0.4 0.2-1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m44.6 79.4c-0.2 0-0.5 0.1-0.7 0.2l-0.6 0.3-0.2 0.2s0 0.1 0.1 0.1l1.4 0.8c0.3 0.2 0.8 0.1 1.1-0.1l0.6-0.3 0.2-0.2s0-0.1-0.1-0.1l-1.4-0.8c0-0.1-0.2-0.1-0.4-0.1zm0.6 2.3c-0.3 0-0.6-0.1-0.8-0.2l-1.4-0.8c-0.3-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l0.6-0.3c0.5-0.3 1.3-0.3 1.7-0.1l1.4 0.8c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-0.6 0.3c-0.2 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m48.2 83.3-1.4-0.8c-0.4-0.2-0.3-0.6 0.1-0.8l0.6-0.3c0.4-0.2 1-0.3 1.4-0.1l1.4 0.8c0.4 0.2 0.3 0.6-0.1 0.8l-0.6 0.3c-0.4 0.3-1.1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m48.2 81.4c-0.2 0-0.5 0.1-0.7 0.2l-0.5 0.3-0.2 0.2s0 0.1 0.1 0.1l1.4 0.8c0.3 0.2 0.8 0.1 1.1-0.1l0.6-0.3 0.2-0.2s0-0.1-0.1-0.1l-1.4-0.8c-0.1 0-0.3-0.1-0.5-0.1zm0.6 2.4c-0.3 0-0.6-0.1-0.8-0.2l-1.4-0.8c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l0.6-0.3c0.5-0.3 1.3-0.3 1.7-0.1l1.4 0.8c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-0.6 0.3c-0.2 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m51.7 85.4-1.4-0.8c-0.4-0.2-0.3-0.6 0.1-0.8l0.6-0.3c0.4-0.2 1-0.3 1.4-0.1l1.4 0.8c0.4 0.2 0.3 0.6-0.1 0.8l-0.6 0.3c-0.4 0.3-1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m51.9 85.1c0.3 0.2 0.8 0.1 1.1-0.1l0.6-0.3 0.2-0.2s0-0.1-0.1-0.1l-1.4-0.8c-0.3-0.2-0.8-0.1-1.1 0.1l-0.6 0.3-0.2 0.2s0 0.1 0.1 0.1l1.4 0.8zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.4-0.8c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l0.6-0.3c0.5-0.3 1.3-0.3 1.7-0.1l1.4 0.8c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-0.6 0.3c-0.3 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m55.3 87.5-1.4-0.8c-0.4-0.2-0.3-0.6 0.1-0.8l0.6-0.3c0.4-0.2 1-0.3 1.4-0.1l1.4 0.8c0.4 0.2 0.3 0.6-0.1 0.8l-0.6 0.3c-0.4 0.2-1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m55.4 85.6c-0.2 0-0.5 0.1-0.7 0.2l-0.6 0.3-0.2 0.2s0 0.1 0.1 0.1l1.4 0.8c0.3 0.2 0.8 0.1 1.1-0.1l0.6-0.3 0.2-0.2s0-0.1-0.1-0.1l-1.4-0.8c-0.1-0.1-0.3-0.1-0.4-0.1zm0.5 2.3c-0.3 0-0.6-0.1-0.8-0.2l-1.4-0.8c-0.3-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l0.6-0.3c0.5-0.3 1.3-0.3 1.7-0.1l1.4 0.8c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-0.6 0.3c-0.2 0.2-0.5 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m58.9 89.5-1.4-0.8c-0.4-0.2-0.3-0.6 0.1-0.8l0.6-0.3c0.4-0.2 1-0.3 1.4-0.1l1.4 0.8c0.4 0.2 0.3 0.6-0.1 0.8l-0.6 0.3c-0.4 0.3-1.1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m59 89.3c0.3 0.2 0.8 0.1 1.1-0.1l0.6-0.3 0.2-0.2s0-0.1-0.1-0.1l-1.4-0.8c-0.3-0.2-0.8-0.1-1.1 0.1l-0.6 0.3-0.2 0.2s0 0.1 0.1 0.1l1.4 0.8zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.4-0.8c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l0.6-0.3c0.5-0.3 1.3-0.3 1.7-0.1l1.4 0.8c0.3 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-0.6 0.3c-0.2 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m62.5 91.6-1.5-0.8c-0.4-0.2-0.3-0.6 0.1-0.8l0.6-0.3c0.4-0.2 1-0.3 1.4-0.1l1.4 0.8c0.4 0.2 0.3 0.6-0.1 0.8l-0.6 0.3c-0.4 0.3-1 0.3-1.3 0.1"
            fill="#151411"
          />
          <Path
            d="m62.5 89.7c-0.2 0-0.5 0.1-0.7 0.2l-0.6 0.3-0.2 0.2s0 0.1 0.1 0.1l1.4 0.8c0.3 0.2 0.8 0.1 1.1-0.1l0.6-0.3 0.2-0.2s0-0.1-0.1-0.1l-1.3-0.8c-0.2-0.1-0.3-0.1-0.5-0.1zm0.6 2.3c-0.3 0-0.6-0.1-0.8-0.2l-1.4-0.8c-0.3-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l0.6-0.3c0.5-0.3 1.3-0.3 1.7-0.1l1.4 0.8c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-0.6 0.4c-0.3 0.1-0.6 0.2-0.9 0.2z"
            fill="#151411"
          />
          <Path
            d="m66 93.7-1.4-0.8c-0.4-0.2-0.3-0.6 0.1-0.8l0.6-0.3c0.4-0.2 1-0.3 1.4-0.1l1.4 0.8c0.4 0.2 0.3 0.6-0.1 0.8l-0.6 0.3c-0.4 0.2-1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m66.1 91.8c-0.2 0-0.5 0.1-0.7 0.2l-0.6 0.3-0.2 0.2s0 0.1 0.1 0.1l1.4 0.8c0.3 0.2 0.8 0.1 1.1-0.1l0.6-0.3 0.2-0.2s0-0.1-0.1-0.1l-1.4-0.8c-0.1-0.1-0.2-0.1-0.4-0.1zm0.5 2.3c-0.3 0-0.6-0.1-0.8-0.2l-1.4-0.8c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l0.6-0.3c0.5-0.3 1.3-0.3 1.7-0.1l1.4 0.8c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-0.6 0.3c-0.2 0.2-0.5 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m69.6 95.7-1.4-0.8c-0.4-0.2-0.3-0.6 0.1-0.8l0.6-0.3c0.4-0.2 1-0.3 1.4-0.1l1.4 0.8c0.4 0.2 0.3 0.6-0.1 0.8l-0.6 0.4c-0.4 0.2-1 0.2-1.4 0"
            fill="#151411"
          />
          <Path
            d="m69.8 95.5c0.3 0.2 0.8 0.1 1.1-0.1l0.6-0.3 0.2-0.2s0-0.1-0.1-0.1l-1.4-0.8c-0.3-0.2-0.8-0.1-1.1 0.1l-0.6 0.3-0.2 0.2s0 0.1 0.1 0.1l1.4 0.8zm0.4 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.4-0.8c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l0.6-0.3c0.5-0.3 1.3-0.3 1.7-0.1l1.4 0.8c0.3 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-0.6 0.3c-0.2 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m73.2 97.8-1.5-0.8c-0.4-0.2-0.3-0.6 0.1-0.8l0.6-0.3c0.4-0.2 1-0.3 1.4-0.1l1.4 0.8c0.4 0.2 0.3 0.6-0.1 0.8l-0.6 0.3c-0.3 0.3-1 0.3-1.3 0.1"
            fill="#151411"
          />
          <Path
            d="m73.3 97.5c0.3 0.2 0.8 0.1 1.1-0.1l0.6-0.3 0.2-0.2s0-0.1-0.1-0.1l-1.4-0.8c-0.3-0.2-0.8-0.1-1.1 0.1l-0.6 0.3-0.2 0.2s0 0.1 0.1 0.1l1.4 0.8zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.4-0.8c-0.3-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l0.6-0.3c0.5-0.3 1.3-0.3 1.7-0.1l1.4 0.8c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-0.6 0.4c-0.2 0.1-0.6 0.2-0.9 0.2z"
            fill="#151411"
          />
          <Path
            d="m76.9 99.9-1.4-0.8c-0.4-0.2-0.3-0.6 0.1-0.8l0.6-0.3c0.4-0.2 1-0.3 1.4-0.1l1.4 0.8c0.4 0.2 0.3 0.6-0.1 0.8l-0.6 0.3c-0.5 0.3-1.1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m77 99.7c0.3 0.2 0.8 0.1 1.1-0.1l0.6-0.3 0.2-0.2s0-0.1-0.1-0.1l-1.4-0.8c-0.3-0.2-0.8-0.1-1.1 0.1l-0.6 0.3-0.2 0.2s0 0.1 0.1 0.1l1.4 0.8zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.4-0.8c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l0.6-0.3c0.5-0.3 1.3-0.3 1.7-0.1l1.4 0.8c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-0.6 0.3c-0.3 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m80.4 102-1.4-0.8c-0.4-0.2-0.3-0.6 0.1-0.8l0.6-0.3c0.4-0.2 1-0.3 1.4-0.1l1.4 0.8c0.4 0.2 0.3 0.6-0.1 0.8l-0.6 0.3c-0.4 0.3-1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m80.5 100.1c-0.2 0-0.5 0.1-0.7 0.2l-0.6 0.3-0.2 0.2s0 0.1 0.1 0.1l1.4 0.8c0.3 0.2 0.8 0.1 1.1-0.1l0.6-0.3 0.2-0.2s0-0.1-0.1-0.1l-1.4-0.8c-0.1-0.1-0.3-0.1-0.4-0.1zm0.5 2.3c-0.3 0-0.6-0.1-0.8-0.2l-1.4-0.8c-0.3-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l0.6-0.3c0.5-0.3 1.3-0.3 1.7-0.1l1.4 0.8c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-0.6 0.3c-0.2 0.2-0.5 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m84 104.1-1.4-0.8c-0.4-0.2-0.3-0.6 0.1-0.8l0.6-0.3c0.4-0.2 1-0.3 1.4-0.1l1.4 0.8c0.4 0.2 0.3 0.6-0.1 0.8l-0.6 0.3c-0.4 0.2-1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m84.1 103.8c0.3 0.2 0.8 0.1 1.1-0.1l0.6-0.3 0.2-0.2s0-0.1-0.1-0.1l-1.4-0.8c-0.3-0.2-0.8-0.1-1.1 0.1l-0.6 0.3-0.2 0.2s0 0.1 0.1 0.1l1.4 0.8zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.4-0.8c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l0.6-0.3c0.5-0.3 1.3-0.3 1.7-0.1l1.4 0.8c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-0.6 0.3c-0.2 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m87.6 106.1-1.4-0.8c-0.4-0.2-0.3-0.6 0.1-0.8l0.6-0.3c0.4-0.2 1-0.3 1.4-0.1l1.4 0.8c0.4 0.2 0.3 0.6-0.1 0.8l-0.6 0.3c-0.4 0.3-1.1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m87.7 105.9c0.3 0.2 0.8 0.1 1.1-0.1l0.6-0.3 0.2-0.2s0-0.1-0.1-0.1l-1.4-0.8c-0.3-0.2-0.8-0.1-1.1 0.1l-0.6 0.3-0.2 0.2s0 0.1 0.1 0.1l1.4 0.8zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.4-0.8c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l0.6-0.3c0.5-0.3 1.3-0.3 1.7-0.1l1.4 0.8c0.3 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-0.6 0.3c-0.2 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m36.9 81.6-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m37.1 81.4c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.4 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.4-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.3 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.3 0.2-0.6 0.3-1 0.3z"
            fill="#151411"
          />
          <Path
            d="m40.5 83.7-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m40.6 83.4c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m44.1 85.8-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m45.2 83.2c-0.2 0-0.5 0.1-0.7 0.2l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c0-0.1-0.2-0.1-0.4-0.1zm-0.5 3c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m47.7 87.8-1.5-0.8c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.2-1.1 0.2-1.4 0"
            fill="#151411"
          />
          <Path
            d="m47.8 87.6c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m51.2 89.9-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m52.4 87.3c-0.2 0-0.5 0.1-0.7 0.2l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.1-0.1-0.2-0.1-0.4-0.1zm-0.5 3c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.3 0.3-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m54.9 92-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.5 0.3-1.1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m55 91.7c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.3 0.3-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m58.4 94.1-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.2-1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m58.6 93.8c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.8c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1c-0.2 0-0.2 0.1-0.2 0.1s0 0.1 0.1 0.1l1.5 0.9zm0.4 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.5 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m62 96.1-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.4-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m63.2 93.6c-0.2 0-0.5 0.1-0.6 0.2l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.2-0.1-0.4-0.1-0.5-0.1zm-0.6 3c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.5 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m65.6 98.2-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m66.7 95.6c-0.2 0-0.5 0.1-0.7 0.2l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c0-0.1-0.2-0.1-0.4-0.1zm-0.5 3.1c-0.3 0-0.6-0.1-0.8-0.2l-1.4-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.3 0.2-0.7 0.3-1 0.3z"
            fill="#151411"
          />
          <Path
            d="m69.2 100.3-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1.1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m70.3 97.7c-0.2 0-0.5 0.1-0.7 0.2l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.1-0.1-0.2-0.1-0.4-0.1zm-0.5 3c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m72.8 102.4-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1.1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m72.9 102.1c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m76.4 104.4-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.5 0.3-1.1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m77.5 101.8c-0.2 0-0.5 0.1-0.7 0.2l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.1 0-0.2-0.1-0.4-0.1zm-0.5 3.1c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.3 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m79.9 106.5-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m81.1 103.9c-0.2 0-0.5 0.1-0.6 0.2l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.2-0.1-0.4-0.1-0.5-0.1zm-0.6 3c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.3-0.5 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m83.5 108.6-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m83.7 108.3c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.4 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.5 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m32.8 84.1-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m33 83.8c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.4 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m36.4 86.1-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1.1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m36.5 85.9c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.4-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.4 0.9zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.3 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m40 88.2-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1.1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m41.1 85.6c-0.2 0-0.5 0.1-0.6 0.2l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.1 0-0.3-0.1-0.5-0.1zm-0.5 3.1c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m43.6 90.3-1.6-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.3-1.3 0.1"
            fill="#151411"
          />
          <Path
            d="m43.7 90c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.3 0.3-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m47.1 92.4-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.2-1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m48.3 89.8c-0.2 0-0.5 0.1-0.7 0.2l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.1-0.1-0.3-0.1-0.4-0.1zm-0.6 3c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.5 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m50.7 94.4-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.4-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m50.9 94.2c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.4 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.5 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m54.3 96.5-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m54.5 96.3c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.4 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m57.9 98.6-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m59 96c-0.2 0-0.5 0.1-0.7 0.2l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c0-0.1-0.2-0.1-0.4-0.1zm-0.5 3c-0.3 0-0.6-0.1-0.8-0.2l-1.4-0.8c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.3 0.2-0.6 0.2-1 0.2z"
            fill="#151411"
          />
          <Path
            d="m61.5 100.7-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.2-1.1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m62.6 98.1c-0.2 0-0.5 0.1-0.6 0.2l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.1-0.1-0.3-0.1-0.5-0.1zm-0.5 3c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m65.1 102.7-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.5 0.3-1.1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m65.2 102.5c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.3 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m68.7 104.8-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1.1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m69.8 102.2c-0.2 0-0.5 0.1-0.7 0.2l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.1 0-0.2-0.1-0.4-0.1zm-0.5 3.1c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.3 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.3 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m72.2 106.9-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m73.4 104.3c-0.2 0-0.5 0.1-0.7 0.2l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.1-0.1-0.3-0.1-0.4-0.1zm-0.6 3c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.5 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m79.4 111-5.1-3c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l5.1 3c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.4-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m79.6 110.8c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-5.1-3c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l5.1 3zm0.4 0.7c-0.3 0-0.6-0.1-0.8-0.2l-5.1-3c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l5.1 3c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.5 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m32 88.6-5-2.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l5 2.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.2-1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m32.2 88.3c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-5-2.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l5 2.9zm0.4 0.7c-0.3 0-0.6-0.1-0.8-0.2l-5-2.9c-0.3-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l5 2.9c0.3 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.5 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m35.7 90.7-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1.1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m35.8 90.4c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.2-0.1-0.7-0.1-1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.4 0.9zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.8c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.1-0.6 0.2-0.9 0.2z"
            fill="#151411"
          />
          <Path
            d="m39.2 92.7-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m39.4 92.5c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.3 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m42.8 94.8-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m43 94.5c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.4 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.3-0.5 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m46.4 96.9-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m46.6 96.6c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.8c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1c-0.2 0-0.2 0.1-0.2 0.2 0 0 0 0.1 0.1 0.1l1.5 0.8zm0.4 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.3-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.3-0.5 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m50 99-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.2-1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m50.1 98.7c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m53.6 101-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.4-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m54.7 98.5c-0.2 0-0.5 0.1-0.7 0.2l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c0-0.1-0.2-0.1-0.4-0.1zm-0.5 3c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m57.2 103.1-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1.1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m57.3 102.9c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m60.8 105.2-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.5 0.3-1.1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m60.9 104.9c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.3 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m64.4 107.3-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.5 0.3-1.1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m65.5 104.7c-0.2 0-0.5 0.1-0.7 0.2l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.1-0.1-0.2-0.1-0.4-0.1zm-0.5 3c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.3 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m71.5 111.4-5.1-2.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l5.1 2.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m69.1 106.7c-0.2 0-0.5 0.1-0.7 0.2l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l5.1 2.9c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-5.1-2.9c-0.1 0-0.3-0.1-0.4-0.1zm3 5.1c-0.3 0-0.6-0.1-0.8-0.2l-5.1-2.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l5.1 2.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.3-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m70.9 115.9-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l5.9-3.4c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-5.9 3.4c-0.4 0.3-1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m76.2 110.9c-0.2 0-0.5 0.1-0.7 0.2l-5.9 3.4-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9c0.3 0.2 0.8 0.1 1.1-0.1l5.9-3.4 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c0-0.1-0.2-0.1-0.4-0.1zm-4.7 5.5c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l5.9-3.4c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-5.9 3.4c-0.2 0.2-0.5 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m24.3 88.9-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.4-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m24.5 88.7c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.4 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.3-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.3 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.5 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m27.9 91-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m28 90.7c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.3-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m31.5 93.1-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m31.7 92.8c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.4 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.3 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.3-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m35.1 95.2-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.2-1.1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m35.2 94.9c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m49.4 103.5-12.3-7.1c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l12.3 7.1c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m39.8 94.6c-0.2 0-0.5 0.1-0.6 0.2l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l12.3 7.1c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-12.3-7.1c-0.2 0-0.3-0.1-0.5-0.1zm10.2 9.3c-0.3 0-0.6-0.1-0.8-0.2l-12.2-7.1c-0.3-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l12.3 7.1c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.3 0.2-0.6 0.3-1 0.3z"
            fill="#151411"
          />
          <Path
            d="m53 105.5-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m53.2 105.3c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.4 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m56.6 107.6-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1.1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m57.7 105c-0.2 0-0.5 0.1-0.7 0.2l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c0-0.1-0.2-0.1-0.4-0.1zm-0.5 3c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.3-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m60.2 109.7-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1.1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m61.3 107.1c-0.2 0-0.5 0.1-0.7 0.2l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c0-0.1-0.2-0.1-0.4-0.1zm-0.5 3c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.3-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m63.8 111.8-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.5 0.2-1.1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m63.9 111.5c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.3 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m67.3 113.8-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.3-1.4 0.1"
            fill="#151411"
          />
          <Path
            d="m67.5 113.6c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.4 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.5 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m41 78.6-1.4-0.8c-0.4-0.2-0.3-0.6 0.1-0.8l0.6-0.3c0.4-0.2 1-0.3 1.4-0.1l1.4 0.8c0.4 0.2 0.3 0.6-0.1 0.8l-0.6 0.3c-0.4 0.3-1.1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m41.1 78.4c0.3 0.2 0.8 0.1 1.1-0.1l0.6-0.3 0.2-0.2s0-0.1-0.1-0.1l-1.4-0.8c-0.3-0.2-0.8-0.1-1.1 0.1l-0.6 0.3-0.2 0.2s0 0.1 0.1 0.1l1.4 0.8zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.4-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l0.6-0.3c0.5-0.3 1.3-0.3 1.7-0.1l1.4 0.8c0.3 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-0.6 0.3c-0.3 0.3-0.6 0.4-0.9 0.4z"
            fill={grey900}
          />
          <Path
            d="m44.5 80.7-1.4-0.8c-0.4-0.2-0.3-0.6 0.1-0.8l0.6-0.3c0.4-0.2 1-0.3 1.4-0.1l1.4 0.8c0.4 0.2 0.3 0.6-0.1 0.8l-0.6 0.3c-0.4 0.3-1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m44.7 80.4c0.3 0.2 0.8 0.1 1.1-0.1l0.6-0.3 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.8c-0.3-0.2-0.8-0.1-1.1 0.1l-0.6 0.3-0.2 0.2s0 0.1 0.1 0.1l1.5 0.8zm0.4 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.3-0.8c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l0.6-0.3c0.5-0.3 1.3-0.3 1.7-0.1l1.4 0.8c0.3 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-0.6 0.3c-0.3 0.2-0.6 0.3-1 0.3z"
            fill="#151411"
          />
          <Path
            d="m48.1 82.8-1.4-0.8c-0.4-0.2-0.3-0.6 0.1-0.8l0.6-0.3c0.4-0.2 1-0.3 1.4-0.1l1.4 0.8c0.4 0.2 0.3 0.6-0.1 0.8l-0.6 0.3c-0.4 0.2-1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m48.3 82.5c0.3 0.2 0.8 0.1 1.1-0.1l0.6-0.3 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.8c-0.3-0.2-0.8-0.1-1.1 0.1l-0.6 0.3-0.2 0.2s0 0.1 0.1 0.1l1.5 0.8zm0.4 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.4-0.8c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l0.6-0.3c0.5-0.3 1.3-0.3 1.7-0.1l1.4 0.8c0.3 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-0.5 0.4c-0.3 0.1-0.7 0.2-1 0.2z"
            fill="#151411"
          />
          <Path
            d="m51.7 84.8-1.5-0.8c-0.4-0.2-0.3-0.6 0.1-0.8l0.6-0.3c0.4-0.2 1-0.3 1.4-0.1l1.4 0.8c0.4 0.2 0.3 0.6-0.1 0.8l-0.6 0.3c-0.3 0.3-1 0.3-1.3 0.1"
            fill={blue500}
          />
          <Path
            d="m51.8 84.6c0.3 0.2 0.8 0.1 1.1-0.1l0.6-0.3 0.2-0.2s0-0.1-0.1-0.1l-1.4-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-0.6 0.3-0.2 0.2s0 0.1 0.1 0.1l1.4 0.9zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.4-0.8c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l0.6-0.3c0.5-0.3 1.3-0.3 1.7-0.1l1.4 0.8c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-0.6 0.3c-0.2 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m55.3 86.9-1.4-0.8c-0.4-0.2-0.3-0.6 0.1-0.8l0.6-0.3c0.4-0.2 1-0.3 1.4-0.1l1.4 0.8c0.4 0.2 0.3 0.6-0.1 0.8l-0.6 0.3c-0.5 0.3-1.1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m55.4 86.6c0.3 0.2 0.8 0.1 1.1-0.1l0.6-0.3 0.2-0.2s0-0.1-0.1-0.1l-1.4-0.8c-0.3-0.2-0.8-0.1-1.1 0.1l-0.6 0.3-0.2 0.2s0 0.1 0.1 0.1l1.4 0.8zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.4-0.8c-0.3-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l0.6-0.3c0.5-0.3 1.3-0.3 1.7-0.1l1.4 0.8c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-0.6 0.3c-0.3 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m58.8 89-1.4-0.8c-0.4-0.2-0.3-0.6 0.1-0.8l0.6-0.3c0.4-0.2 1-0.3 1.4-0.1l1.4 0.8c0.4 0.2 0.3 0.6-0.1 0.8l-0.6 0.3c-0.4 0.2-1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m59 88.7c0.3 0.2 0.8 0.1 1.1-0.1l0.6-0.3 0.2-0.2s0-0.1-0.1-0.1l-1.4-0.8c-0.3-0.2-0.8-0.1-1.1 0.1l-0.6 0.3-0.2 0.2s0 0.1 0.1 0.1l1.4 0.8zm0.4 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.4-0.8c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l0.6-0.3c0.5-0.3 1.3-0.3 1.7-0.1l1.4 0.8c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-0.6 0.3c-0.2 0.2-0.5 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m62.4 91-1.4-0.8c-0.4-0.2-0.3-0.6 0.1-0.8l0.6-0.3c0.4-0.2 1-0.3 1.4-0.1l1.4 0.8c0.4 0.2 0.3 0.6-0.1 0.8l-0.6 0.4c-0.4 0.2-1 0.2-1.4 0"
            fill={blue500}
          />
          <Path
            d="m62.5 90.8c0.3 0.2 0.8 0.1 1.1-0.1l0.6-0.3 0.2-0.2s0-0.1-0.1-0.1l-1.4-0.8c-0.3-0.2-0.8-0.1-1.1 0.1l-0.6 0.3-0.2 0.2s0 0.1 0.1 0.1l1.4 0.8zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.4-0.8c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l0.6-0.3c0.5-0.3 1.3-0.3 1.7-0.1l1.4 0.8c0.3 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-0.5 0.3c-0.3 0.2-0.7 0.3-1 0.3z"
            fill="#151411"
          />
          <Path
            d="m66 93.1-1.4-0.8c-0.4-0.2-0.3-0.6 0.1-0.8l0.6-0.3c0.4-0.2 1-0.3 1.4-0.1l1.4 0.8c0.4 0.2 0.3 0.6-0.1 0.8l-0.6 0.3c-0.4 0.3-1.1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m66 91.2c-0.2 0-0.5 0.1-0.7 0.2l-0.6 0.3-0.2 0.2s0 0.1 0.1 0.1l1.4 0.8c0.3 0.2 0.8 0.1 1.1-0.1l0.6-0.3 0.2-0.2s0-0.1-0.1-0.1l-1.4-0.8c0-0.1-0.2-0.1-0.4-0.1zm0.6 2.3c-0.3 0-0.6-0.1-0.8-0.2l-1.4-0.8c-0.3-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l0.6-0.3c0.5-0.3 1.3-0.3 1.7-0.1l1.4 0.8c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-0.6 0.3c-0.2 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m69.5 95.2-1.4-0.8c-0.4-0.2-0.3-0.6 0.1-0.8l0.6-0.3c0.4-0.2 1-0.3 1.4-0.1l1.4 0.8c0.4 0.2 0.3 0.6-0.1 0.8l-0.6 0.3c-0.4 0.2-1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m69.7 94.9c0.3 0.2 0.8 0.1 1.1-0.1l0.6-0.3 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.8c-0.3-0.2-0.8-0.1-1.1 0.1l-0.6 0.3-0.2 0.2s0 0.1 0.1 0.1l1.5 0.8zm0.4 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.3-0.8c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l0.6-0.3c0.5-0.3 1.3-0.3 1.7-0.1l1.4 0.8c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-0.6 0.3c-0.3 0.2-0.6 0.3-1 0.3z"
            fill="#151411"
          />
          <Path
            d="m73.1 97.2-1.4-0.8c-0.4-0.2-0.3-0.6 0.1-0.8l0.6-0.3c0.4-0.2 1-0.3 1.4-0.1l1.4 0.8c0.4 0.2 0.3 0.6-0.1 0.8l-0.6 0.3c-0.4 0.3-1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m73.3 97c0.3 0.2 0.8 0.1 1.1-0.1l0.6-0.3 0.2-0.2s0-0.1-0.1-0.1l-1.4-0.8c-0.3-0.2-0.8-0.1-1.1 0.1l-0.6 0.3-0.2 0.2s0 0.1 0.1 0.1l1.4 0.8zm0.4 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.4-0.8c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l0.6-0.3c0.5-0.3 1.3-0.3 1.7-0.1l1.4 0.8c0.3 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-0.6 0.3c-0.2 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m76.8 99.3-1.4-0.8c-0.4-0.2-0.3-0.6 0.1-0.8l0.6-0.3c0.4-0.2 1-0.3 1.4-0.1l1.4 0.8c0.4 0.2 0.3 0.6-0.1 0.8l-0.6 0.3c-0.4 0.3-1 0.4-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m76.9 99.1c0.3 0.2 0.8 0.1 1.1-0.1l0.6-0.3 0.2-0.2s0-0.1-0.1-0.1l-1.4-0.8c-0.3-0.2-0.8-0.1-1.1 0.1l-0.6 0.3-0.2 0.2s0 0.1 0.1 0.1l1.4 0.8zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.4-0.8c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l0.6-0.3c0.5-0.3 1.3-0.3 1.7-0.1l1.4 0.8c0.3 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-0.6 0.3c-0.2 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m80.4 101.4-1.4-0.8c-0.4-0.2-0.3-0.6 0.1-0.8l0.6-0.3c0.4-0.2 1-0.3 1.4-0.1l1.4 0.8c0.4 0.2 0.3 0.6-0.1 0.8l-0.6 0.3c-0.4 0.3-1.1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m80.5 101.2c0.3 0.2 0.8 0.1 1.1-0.1l0.6-0.3 0.2-0.2s0-0.1-0.1-0.1l-1.4-0.8c-0.3-0.2-0.8-0.1-1.1 0.1l-0.6 0.3-0.2 0.2s0 0.1 0.1 0.1l1.4 0.8zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.4-0.8c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l0.6-0.3c0.5-0.3 1.3-0.3 1.7-0.1l1.4 0.8c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-0.6 0.3c-0.3 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m83.9 103.5-1.4-0.8c-0.4-0.2-0.3-0.6 0.1-0.8l0.6-0.3c0.4-0.2 1-0.3 1.4-0.1l1.4 0.8c0.4 0.2 0.3 0.6-0.1 0.8l-0.6 0.3c-0.4 0.3-1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m84.1 103.2c0.3 0.2 0.8 0.1 1.1-0.1l0.6-0.3 0.2-0.2s0-0.1-0.1-0.1l-1.4-0.8c-0.3-0.2-0.8-0.1-1.1 0.1l-0.6 0.3-0.2 0.2s0 0.1 0.1 0.1l1.4 0.8zm0.4 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.4-0.8c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l0.6-0.3c0.5-0.3 1.3-0.3 1.7-0.1l1.4 0.8c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-0.6 0.3c-0.2 0.2-0.5 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m87.5 105.5-1.4-0.8c-0.4-0.2-0.3-0.6 0.1-0.8l0.6-0.3c0.4-0.2 1-0.3 1.4-0.1l1.4 0.8c0.4 0.2 0.3 0.6-0.1 0.8l-0.6 0.3c-0.4 0.3-1 0.4-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m87.7 105.3c0.3 0.2 0.8 0.1 1.1-0.1l0.6-0.3 0.2-0.2s0-0.1-0.1-0.1l-1.4-0.8c-0.3-0.2-0.8-0.1-1.1 0.1l-0.6 0.3-0.2 0.2s0 0.1 0.1 0.1l1.4 0.8zm0.4 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.4-0.8c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l0.6-0.3c0.5-0.3 1.3-0.3 1.7-0.1l1.4 0.8c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-0.6 0.3c-0.2 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m36.9 81-1.5-0.9c-0.4-0.1-0.4-0.5 0.1-0.7l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.2-1.1 0.3-1.4 0"
            fill={blue500}
          />
          <Path
            d="m37 80.8c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.3 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.3 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m40.4 83.1-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m40.6 82.9c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1c-0.2 0.1-0.2 0.2-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.4 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.3-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.5 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m44 85.2-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m45.2 82.6c-0.2 0-0.5 0.1-0.7 0.2l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.1-0.1-0.3-0.1-0.4-0.1zm-0.5 3c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.3-0.1-0.4-0.3-0.4-0.5 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.3 0.2-0.6 0.2-0.9 0.2z"
            fill="#151411"
          />
          <Path
            d="m47.6 87.3-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.2-1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m47.8 87c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.4 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.4-0.8c-0.3-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.3 0.1-0.6 0.2-1 0.2z"
            fill="#151411"
          />
          <Path
            d="m51.2 89.3-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1.1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m51.3 89.1c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m54.8 91.4-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m55.9 88.8c-0.2 0-0.5 0.1-0.7 0.2l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9s-0.2-0.1-0.4-0.1zm-0.5 3.1c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m58.4 93.5-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1.1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m58.5 93.2c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.4-0.8c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.4 0.8zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.3-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.3 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m62 95.6-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1.1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m63.1 93c-0.2 0-0.5 0.1-0.7 0.2l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.1-0.1-0.2-0.1-0.4-0.1zm-0.5 3c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.8c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.1-0.6 0.2-0.9 0.2z"
            fill="#151411"
          />
          <Path
            d="m65.5 97.6-1.5-0.8c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.2-1 0.3-1.4 0"
            fill={blue500}
          />
          <Path
            d="m65.7 97.4c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.2-0.1-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.3 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.3 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m69.1 99.7-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m70.2 97.1c-0.2 0-0.5 0.1-0.7 0.2l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c0-0.1-0.2-0.1-0.4-0.1zm-0.5 3c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.3-0.5 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m72.7 101.8-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m73.9 99.2c-0.2 0-0.5 0.1-0.7 0.2l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.1-0.1-0.3-0.1-0.4-0.1zm-0.6 3c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.3-0.5 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m76.3 103.9-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.2-1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m76.4 103.6c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m79.9 105.9-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1.1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m81 103.3c-0.2 0-0.5 0.1-0.7 0.2l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.1 0-0.2-0.1-0.4-0.1zm-0.5 3.1c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.3 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m83.5 108-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1.1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m83.6 107.8c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m32.7 83.5-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m33.9 80.9c-0.2 0-0.5 0.1-0.6 0.2l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.6-0.9c-0.1-0.1-0.3-0.1-0.4-0.1zm-0.6 3c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.3-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.3 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.3-0.5 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m36.3 85.6-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.2-1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m37.4 83c-0.2 0-0.5 0.1-0.7 0.2l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c0-0.1-0.2-0.1-0.4-0.1zm-0.5 3c-0.3 0-0.6-0.1-0.8-0.2l-1.4-0.8c-0.3-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.3 0.1-0.6 0.2-1 0.2z"
            fill="#151411"
          />
          <Path
            d="m39.9 87.7-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.2-1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m40.1 87.4c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.4 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.4-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.3 0.2-0.6 0.3-1 0.3z"
            fill="#151411"
          />
          <Path
            d="m43.5 89.7-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m43.6 89.5c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.3 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m47.1 91.8-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1.1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m47.2 91.5c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.3-0.4-0.5 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.3 0.1-0.6 0.2-0.9 0.2z"
            fill="#151411"
          />
          <Path
            d="m50.7 93.9-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1.1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m50.8 93.6c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.8c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.1-0.6 0.2-0.9 0.2z"
            fill="#151411"
          />
          <Path
            d="m54.2 95.9-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.4-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m54.4 95.7c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.3 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m57.9 98-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.5 0.3-1.1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m58 97.8c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.3 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m61.4 100.1-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m62.6 97.5c-0.2 0-0.5 0.1-0.7 0.2l-1.7 1c-0.2 0.1-0.2 0.1-0.2 0.2 0 0 0 0.1 0.1 0.1l1.5 0.9c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.1-0.1-0.3-0.1-0.4-0.1zm-0.6 3c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.3-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.3-0.5 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m65 102.2-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.2-1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m65.1 101.9c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m68.6 104.3-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.2-1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m68.8 104c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.4 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m72.2 106.3-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1.1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m72.3 106.1c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m79.4 110.5-5.1-3c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l5.1 3c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.5 0.3-1.1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m76.9 105.8c-0.2 0-0.5 0.1-0.7 0.2l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l5.1 3c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-5.1-3c-0.1-0.1-0.2-0.1-0.4-0.1zm3.1 5.1c-0.3 0-0.6-0.1-0.8-0.2l-5.1-3c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l5.1 3c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.3 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m32 88-5-2.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l5 2.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1.1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m32.1 87.7c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-5-2.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1c-0.2 0.1-0.2 0.2-0.2 0.2s0 0.1 0.1 0.1l5 2.9zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-5-2.9c-0.3-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l5 2.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.3-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m35.6 90.1-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m35.8 89.8c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.4 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.4-0.8c-0.3-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.3 0.2-0.6 0.2-1 0.2z"
            fill="#151411"
          />
          <Path
            d="m39.2 92.2-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.2-1.1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m39.3 91.9c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.3 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m42.8 94.2-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.5 0.3-1.1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m42.9 94c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.3 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m46.4 96.3-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1.1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m46.5 96.1c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.4-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.4 0.9zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.3 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m49.9 98.4-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m50.1 98.1c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.4 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.5 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m53.5 100.5-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m53.7 100.2c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.8c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.8zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.3 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m57.1 102.5-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.4-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m58.2 99.9c-0.2 0-0.5 0.1-0.7 0.2l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9s-0.2-0.1-0.4-0.1zm-0.5 3.1c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.5 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m60.7 104.6-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1.1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m60.8 104.3c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.8c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.6 0.2-0.9 0.2z"
            fill="#151411"
          />
          <Path
            d="m64.3 106.7-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m65.4 104.1c-0.2 0-0.5 0.1-0.6 0.2l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.1-0.1-0.3-0.1-0.5-0.1zm-0.5 3c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.3-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m71.5 110.9-5.1-3c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l5.1 3c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m71.7 110.6c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-5.1-3c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l5.1 3zm0.4 0.7c-0.3 0-0.6-0.1-0.8-0.2l-5.1-3c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l5.1 3c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.3-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m70.9 115.3-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l5.9-3.4c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-5.9 3.4c-0.4 0.3-1.1 0.4-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m71 115.1c0.3 0.2 0.8 0.1 1.1-0.1l5.9-3.4 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-5.9 3.4-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l5.9-3.4c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-5.9 3.4c-0.2 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m24.3 88.4-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1.1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m24.4 88.1c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.3-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.3 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m27.8 90.4-1.5-0.9c-0.3-0.1-0.3-0.5 0.1-0.7l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.2-1 0.2-1.4 0"
            fill={blue500}
          />
          <Path
            d="m28 90.2c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1c0.2-0.1 0.2-0.2 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.4 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.3 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.5 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m31.4 92.5-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m31.6 92.3c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1c-0.2 0.1-0.2 0.2-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.3-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.3 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m35 94.6-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m35.2 94.3c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.4 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.4-0.8c-0.3-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.3 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.3 0.2-0.6 0.2-1 0.2z"
            fill="#151411"
          />
          <Path
            d="m49.4 102.9-12.3-7.1c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l12.3 7.1c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1.1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m49.5 102.6c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-12.3-7.1c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l12.3 7.1zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-12.3-7.1c-0.3-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l12.3 7.1c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.3-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m52.9 105-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.2-1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m53.1 104.7c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.5 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.3 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m56.5 107-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m56.7 106.8c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.3-0.2-0.8-0.1-1.1 0.1l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9zm0.4 0.7c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.5 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m60.1 109.1-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m61.3 106.5c-0.2 0-0.5 0.1-0.7 0.2l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.1 0-0.3-0.1-0.4-0.1zm-0.6 3.1c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.5 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m63.7 111.2-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.3-1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m64.8 108.6c-0.2 0-0.5 0.1-0.6 0.2l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.1-0.1-0.3-0.1-0.5-0.1zm-0.5 3c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m67.3 113.3-1.5-0.9c-0.4-0.2-0.3-0.6 0.1-0.8l1.7-1c0.4-0.2 1-0.3 1.4-0.1l1.5 0.9c0.4 0.2 0.3 0.6-0.1 0.8l-1.7 1c-0.4 0.2-1.1 0.3-1.4 0.1"
            fill={blue500}
          />
          <Path
            d="m68.4 110.7c-0.2 0-0.5 0.1-0.6 0.2l-1.7 1-0.2 0.2s0 0.1 0.1 0.1l1.5 0.9c0.3 0.2 0.8 0.1 1.1-0.1l1.7-1 0.2-0.2s0-0.1-0.1-0.1l-1.5-0.9c-0.2-0.1-0.3-0.1-0.5-0.1zm-0.5 3c-0.3 0-0.6-0.1-0.8-0.2l-1.5-0.9c-0.2-0.1-0.4-0.4-0.4-0.6 0-0.3 0.2-0.5 0.5-0.7l1.7-1c0.5-0.3 1.3-0.3 1.7-0.1l1.5 0.9c0.2 0.1 0.4 0.4 0.4 0.6 0 0.3-0.2 0.5-0.5 0.7l-1.7 1c-0.2 0.2-0.6 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m94.2 108.1-2.5 1.4s-0.1 0.1-0.1 0.2v0.8l2.5-1.4s0.1-0.1 0.1-0.2v-0.8zm-2.6 2.7h-0.1c-0.1 0-0.1-0.1-0.1-0.3v-0.9c0-0.2 0.1-0.4 0.2-0.4l2.5-1.5c0.1 0 0.2-0.1 0.3 0 0.1 0 0.1 0.1 0.1 0.3v0.9c0 0.2-0.1 0.4-0.2 0.4l-2.5 1.5h-0.2z"
            fill={blue500}
          />
          <Path
            d="m56.9 129.7c-0.1-0.8 0.1-1.5 0.1-1.5l0.3 0.1s-0.2 0.7-0.1 1.4h-0.3"
            fill="#F4B73D"
          />
          <Path
            d="m2.4 98.9c-0.1-0.8 0.1-1.5 0.1-1.5l0.3 0.1s-0.2 0.7-0.1 1.4h-0.3"
            fill="#F4B73D"
          />
          <Path
            d="m100.4 53.5-0.1-0.3s0.5-0.3 1.3-0.9l0.2 0.2c-0.8 0.7-1.3 1-1.4 1"
            fill={blue500}
          />
          <Polyline
            points="92.3 46.3 88.6 44.2 88.8 43.9 92.4 46 92.3 46.3"
            fill={blue500}
          />
          <Polygon
            points="102 74.1 102.3 74.1 102.3 68.9 102 68.9"
            fill={blue500}
          />
          <Path
            d="m49.7 40.8c-0.6-0.4-1.2-1.4-1.2-2.2v-7.7c0-0.9 0.5-1.2 1.2-0.9l44.6 25.9c0.6 0.4 1.2 1.4 1.2 2.2v7.7c0 0.9-0.5 1.2-1.2 0.9l-44.6-25.9z"
            fill={blue500}
          />
          <Path
            d="m49.3 30.1h-0.2c-0.2 0.1-0.3 0.4-0.3 0.7v7.7c0 0.8 0.5 1.7 1 2l44.6 25.9c0.1 0.1 0.4 0.2 0.5 0.1 0.2-0.1 0.3-0.4 0.3-0.7v-7.7c0-0.8-0.5-1.7-1-2l-44.6-25.9c-0.1-0.1-0.2-0.1-0.3-0.1zm45.5 36.9c-0.2 0-0.4-0.1-0.6-0.2l-44.6-25.8c-0.7-0.4-1.3-1.5-1.3-2.4v-7.7c0-0.5 0.2-0.9 0.5-1.1s0.6-0.1 1 0.1l44.6 25.9c0.7 0.4 1.3 1.5 1.3 2.4v7.7c0 0.5-0.2 0.9-0.5 1.1h-0.4z"
            fill="#151411"
          />
          <Path
            d="m58.9 40.6c0 2.1-1.4 2.9-3.2 1.9s-3.2-3.5-3.2-5.6 1.4-2.9 3.2-1.9 3.2 3.6 3.2 5.6"
            fill="#151411"
          />
          <Path
            d="m53.1 34.2c-0.3 0-0.5 0.1-0.7 0.2-0.5 0.3-0.8 1-0.8 1.9 0 2 1.4 4.4 3.1 5.4 0.8 0.5 1.5 0.5 2.1 0.2 0.5-0.3 0.8-1 0.8-1.9 0-2-1.4-4.4-3.1-5.4-0.6-0.3-1-0.4-1.4-0.4zm2.8 8.4c-0.5 0-1-0.2-1.6-0.5-1.8-1.1-3.3-3.7-3.3-5.8 0-1.1 0.4-1.9 1.1-2.3s1.6-0.3 2.5 0.2c1.8 1.1 3.3 3.7 3.3 5.8 0 1.1-0.4 1.9-1.1 2.3-0.2 0.2-0.5 0.3-0.9 0.3z"
            fill="#151411"
          />
          <Path
            d="m55.6 35c-0.5-0.2-0.9-0.4-1.3-0.4h0.1c1.7 1 3.1 3.4 3.1 5.4 0 0.9-0.3 1.6-0.8 1.9s-1.2 0.2-1.9-0.2c0.3 0.3 0.6 0.5 1 0.7 0 0 0.1 0 0.1 0.1h0.1c0.3 0 0.7-0.1 1-0.2 0.7-0.4 1.1-1.2 1.1-2.3-0.2-1.7-1.2-3.8-2.5-5"
            fill={blue500}
          />
          <Path
            d="m58.5 65.1-9-5.2c-0.5-0.3-1-1.1-1-1.9v-7.7c0-0.7 0.4-1 1-0.7l9 5.2c0.5 0.3 1 1.1 1 1.9v7.7c0 0.7-0.4 1.1-1 0.7"
            fill={blue50}
          />
          <Path
            d="m58.6 64.9c0.1 0.1 0.3 0.1 0.4 0.1 0.1-0.1 0.2-0.3 0.2-0.6v-7.7c0-0.6-0.4-1.4-0.9-1.7l-9-5.2c-0.1-0.1-0.3-0.1-0.4-0.1-0.1 0.1-0.2 0.3-0.2 0.6v7.7c0 0.6 0.4 1.4 0.9 1.7l9 5.2zm0.3 0.6c-0.2 0-0.3 0-0.5-0.1l-9-5.2c-0.6-0.4-1.1-1.3-1.1-2.1v-7.7c0-0.5 0.2-0.8 0.4-1 0.2-0.1 0.6-0.1 0.9 0.1l9 5.2c0.6 0.4 1.1 1.3 1.1 2.1v7.7c0 0.5-0.2 0.8-0.4 1h-0.4z"
            fill="#151411"
          />
          <Path
            d="m70.5 72.1-9-5.2c-0.5-0.3-1-1.1-1-1.9v-7.7c0-0.7 0.4-1 1-0.7l9 5.2c0.5 0.3 1 1.1 1 1.9v7.7c0 0.7-0.4 1-1 0.7"
            fill={blue50}
          />
          <Path
            d="m61.2 56.7h-0.1c-0.1 0.1-0.2 0.3-0.2 0.6v7.7c0 0.6 0.4 1.4 0.9 1.7l9 5.2c0.1 0.1 0.3 0.1 0.4 0.1 0.1-0.1 0.2-0.3 0.2-0.6v-7.7c0-0.6-0.4-1.4-0.9-1.7l-9-5.2c-0.1 0-0.2-0.1-0.3-0.1zm9.7 15.8c-0.2 0-0.3 0-0.5-0.1l-9-5.2c-0.6-0.4-1.1-1.3-1.1-2.1v-7.7c0-0.5 0.2-0.8 0.4-1 0.2-0.1 0.6-0.1 0.9 0.1l9 5.2c0.6 0.4 1.1 1.3 1.1 2.1v7.7c0 0.5-0.2 0.8-0.4 1-0.1-0.1-0.2 0-0.4 0z"
            fill="#151411"
          />
          <Path
            d="m82.5 79.1-9-5.2c-0.5-0.3-1-1.1-1-1.9v-7.7c0-0.7 0.4-1 1-0.7l9 5.2c0.5 0.3 1 1.1 1 1.9v7.7c0 0.6-0.4 1-1 0.7"
            fill={blue50}
          />
          <Path
            d="m73.2 63.7h-0.1c-0.1 0.1-0.2 0.3-0.2 0.6v7.7c0 0.6 0.4 1.4 0.9 1.7l9 5.2c0.1 0.1 0.3 0.1 0.4 0.1 0.1-0.1 0.2-0.3 0.2-0.6v-7.7c0-0.6-0.4-1.4-0.9-1.7l-9-5.2c-0.1-0.1-0.2-0.1-0.3-0.1zm9.7 15.7c-0.2 0-0.3 0-0.5-0.1l-9-5.2c-0.6-0.4-1.1-1.3-1.1-2.1v-7.7c0-0.5 0.2-0.8 0.4-1 0.2-0.1 0.6-0.1 0.9 0.1l9 5.2c0.6 0.4 1.1 1.3 1.1 2.1v7.7c0 0.5-0.2 0.8-0.4 1h-0.4z"
            fill="#151411"
          />
          <Path
            d="m94.5 86-9-5.2c-0.5-0.3-1-1.1-1-1.9v-7.7c0-0.7 0.4-1 1-0.7l9 5.2c0.5 0.3 1 1.1 1 1.9v7.7c0 0.7-0.4 1-1 0.7"
            fill={blue50}
          />
          <Path
            d="m85.2 70.6h-0.1c-0.1 0.1-0.2 0.3-0.2 0.6v7.8c0 0.6 0.4 1.4 0.9 1.7l9 5.2c0.1 0.1 0.3 0.1 0.4 0.1 0.1-0.1 0.2-0.3 0.2-0.6v-7.7c0-0.6-0.4-1.4-0.9-1.7l-9-5.2c-0.1-0.1-0.2-0.2-0.3-0.2zm9.7 15.8c-0.2 0-0.3 0-0.5-0.1l-9-5.2c-0.6-0.4-1.1-1.3-1.1-2.1v-7.7c0-0.5 0.2-0.8 0.4-1 0.2-0.1 0.6-0.1 0.9 0.1l9 5.2c0.6 0.4 1.1 1.3 1.1 2.1v7.7c0 0.5-0.2 0.8-0.4 1-0.1-0.1-0.2 0-0.4 0z"
            fill="#151411"
          />
          <Path
            d="m95.4 75.4-45.5-26.4c-0.4-0.2-0.7-0.9-0.7-1.4v-1.9c0-0.5 0.3-0.8 0.7-0.6l45.5 26.4c0.4 0.2 0.7 0.9 0.7 1.4v1.9c0 0.6-0.3 0.8-0.7 0.6"
            fill={blue50}
          />
          <Path
            d="m49 43.6c-0.1 0.1-0.2 0.2-0.2 0.4v1.9c0 0.5 0.3 1 0.6 1.2l45.5 26.4c0.1 0 0.2 0.1 0.3 0 0.1 0 0.1-0.2 0.1-0.4v-1.9c0-0.5-0.3-1-0.6-1.2l-45.5-26.4h-0.2zm46.1 30.3c-0.1 0-0.3 0-0.4-0.1l-45.5-26.4c-0.5-0.3-0.8-1-0.8-1.6v-1.8c0-0.4 0.1-0.7 0.4-0.8 0.2-0.1 0.5-0.1 0.7 0l45.5 26.4c0.5 0.3 0.8 1 0.8 1.6v1.9c0 0.4-0.1 0.7-0.4 0.8h-0.3z"
            fill="#151411"
          />
          <Path
            d="m94.7 94.5-45.3-26.2c-0.5-0.3-0.9-1-0.9-1.6v-4c0-0.6 0.4-0.9 0.9-0.6l45.2 26.2c0.5 0.3 0.9 1 0.9 1.6v4c0 0.6-0.4 0.9-0.8 0.6"
            fill={blue50}
          />
          <Path
            d="m94.8 94.3c0.1 0 0.2 0.1 0.3 0s0.2-0.3 0.2-0.5v-4c0-0.5-0.3-1.2-0.7-1.4l-45.3-26.2c-0.1 0-0.2-0.1-0.3 0s-0.2 0.3-0.2 0.5v4c0 0.5 0.3 1.2 0.7 1.4l45.3 26.2zm0.2 0.6c-0.1 0-0.3 0-0.5-0.1l-45.2-26.3c-0.5-0.3-1-1.1-1-1.8v-4c0-0.4 0.1-0.7 0.4-0.9 0.2-0.1 0.5-0.1 0.8 0l45.2 26.2c0.5 0.3 1 1.1 1 1.8v4c0 0.4-0.1 0.7-0.4 0.9-0.1 0.1-0.2 0.2-0.3 0.2z"
            fill="#151411"
          />
        </G>
      </G>
    </G>
  </>
);

export const DevicesIllustration = createIllustration({
  viewBox: '0 0 188 160',
  vectorComponent
});
