import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { EyeIcon, EyeSlashIcon } from '@components/Icon';
import { IconButton } from '@components/IconButton';

type PasswordRevealButtonProps = {
  isHidePassword: boolean;
  onPress: () => void;
};

const PasswordRevealButtonStyle: StyleProp<ViewStyle> = {
  position: 'absolute',
  right: 12,
  justifyContent: 'center',
  height: '100%',
  zIndex: 3000
};

export const PasswordRevealButton = (props: PasswordRevealButtonProps) => {
  const { isHidePassword, onPress } = props;
  const eyeIcon = isHidePassword ? (
    <EyeIcon testID="show-password-icon" size="xlarge" variant="outline" />
  ) : (
    <EyeSlashIcon testID="hide-password-icon" size="xlarge" variant="outline" />
  );

  return (
    <View style={PasswordRevealButtonStyle}>
      <IconButton
        testID="password-toggle-button"
        icon={eyeIcon}
        onPress={onPress}
      />
    </View>
  );
};
