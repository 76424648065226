import { SET_PLATFORM } from './constants';

export type PlatformType = 'mobile' | 'web';

type PlatformState = {
  userAgent?: string;
  device: {
    model?: string;
    type?: PlatformType;
    vendor?: string;
  };
};

const initialState: PlatformState = {
  userAgent: undefined,
  device: {
    model: undefined,
    type: undefined,
    vendor: undefined
  }
};

export default function (state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case SET_PLATFORM:
      return {
        ...state,
        userAgent: payload.userAgent,
        device: payload.device
      };
    default:
      return state;
  }
}
