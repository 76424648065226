import { REGEX } from '@common/constants/data';

export function isSameCaseInsensitive(str1, str2) {
  return str1.toLowerCase().trim() === str2.toLowerCase().trim();
}

export function normaliseWhitespace(str: string) {
  return str.replace(/\s{2,}/g, ' ');
}

export function extractTextFromHtml(str: string) {
  return str
    .replace(REGEX.HTML, '')
    .trim()
    .replace(REGEX.NON_BREAKING_SPACE, ' ');
}

export function isEmptyString(value) {
  return typeof value === 'string' && value.trim().length < 1;
}

export const normaliseExtractedText = (text: string): string =>
  normaliseWhitespace(extractTextFromHtml(text).trim());

export function normaliseLength(text: string): number {
  if (!text) return 0;
  return normaliseExtractedText(text).length;
}
