import { UseFormSetError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { VerifyEmailFormFields } from '../types';

export function useResponseErrorHandler() {
  const { t } = useTranslation();
  const handleResponseErrors = (
    response: {
      field: string;
      errorMessage: string;
    } | void,
    setError: UseFormSetError<VerifyEmailFormFields>
  ) => {
    if (!response) return;
    const { field, errorMessage } = response;
    setError(field as keyof VerifyEmailFormFields, {
      type: 'server',
      message: t(errorMessage)
    });
  };

  return {
    handleResponseErrors
  };
}
