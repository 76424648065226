import { useCallback } from 'react';
import { usePaymentContext } from '@common/components/FormElements/PaymentProvider/PaymentContext';
import { useAppDispatch } from '@store/hooks';
import { confirmPayment as confirmPaymentAction } from '@store/payment/stripeApi';

export type ConfirmPayment = ({
  data,
  successfulRedirectUrl
}: {
  data: PaymentData;
  successfulRedirectUrl: string;
}) => Promise<any>;

type PaymentData = {
  email: string;
  nameOnCard: string;
  country: string;
  cardDetails?: {
    brand: string;
    complete: boolean;
    expiryMonth: number;
    expiryYear: number;
    last4: string;
    validCVC: string;
    validExpiryDate: string;
    validNumber: string;
  };
};

export function useConfirmPayment() {
  const { clientId, elements, stripe } = usePaymentContext();
  const dispatch = useAppDispatch();

  const confirmPayment = useCallback<ConfirmPayment>(
    ({ data, successfulRedirectUrl }) => {
      return dispatch(
        confirmPaymentAction(stripe, {
          stripeConfig: {
            web: { elements },
            native: { clientId }
          },
          successfulRedirectUrl,
          data
        })
      );
    },
    [clientId, dispatch, elements, stripe]
  );

  return {
    confirmPayment
  };
}
