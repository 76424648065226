type Maybe<T> = T | null | undefined;

type DefaultErrorShape = Maybe<{
  data: Maybe<{
    httpError: Maybe<{
      [x: string]: any;
      guid: Maybe<string>;
    }>;
  }>;
}>;

export const extractTrpcErrorProperties = <T extends DefaultErrorShape>(
  error: T
) => {
  if (!error) return;

  return {
    guid: error?.data?.httpError?.guid,
    responseBody: error?.data
  };
};
