import React from 'react';
import { ViewProps } from 'react-native';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { Box } from '@components/Box';
import { useRequestError } from './hooks';

type RequestErrorMessageProps = {
  requestKey: string;
  testID?: ViewProps['testID'];
};

export const RequestErrorMessage = (props: RequestErrorMessageProps) => {
  const { requestKey, testID } = props;
  const { getRequestError } = useRequestError();
  const error = getRequestError(requestKey);
  if (!error) {
    return null;
  }

  return (
    <Box marginTop="medium" testID={testID}>
      <ErrorMessage>{error}</ErrorMessage>
    </Box>
  );
};
