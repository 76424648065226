import React, { useContext } from 'react';
import { Controller } from 'react-hook-form';
import { TFunction } from 'react-i18next';
import { FormContext } from './FormContext';
import { NotesFormFields } from './types';

interface FieldWrapperProps<V> {
  name: keyof NotesFormFields;
  render: (props: RenderField<V>) => React.ReactElement;
  rules?: (t: TFunction<'translation', undefined>) => Record<string, unknown>;
}

interface RenderField<Z> {
  onChange: () => void;
  onBlur: () => void;
  value: Z;
  error: string | undefined;
  t: TFunction<'translation', undefined>;
}
export function FieldWrapper<V>({ name, render, rules }: FieldWrapperProps<V>) {
  const { control, errors, t } = useContext(FormContext);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules?.(t)}
      render={({ field }) =>
        render({
          onChange: field.onChange,
          onBlur: field.onBlur,
          value: field.value as V,
          error: errors[name]?.message as string | undefined,
          t
        })
      }
    />
  );
}
