import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Application } from '@common/types/Application';
import { PhoneIcon } from '@components/Icon';
import { Link } from '@components/Link';
import { WhatsAppLogo } from '@components/Logo';
import { Modal } from '@components/Modal';
import { Row } from '@components/Row';
import { StackProps } from '@components/Stack';
import { useChatWithApplicantModal } from './useChatWithApplicantModal';

export type ChatWithApplicantModalProps = {
  testID?: StackProps['testID'];
  jobId: string;
  application: Application;
  modalOpen: boolean;
  onModalClose: () => void;
};

const ChatIconLink = ({
  text,
  icon,
  onPress,
  testID
}: {
  text: string;
  icon: ReactElement;
  onPress: () => void;
  testID: string;
}) => {
  return (
    <Link
      testID={testID}
      onPress={onPress}
      variant="secondary"
      align="vertical"
      icon={icon}
    >
      {text}
    </Link>
  );
};

export const ChatWithApplicantModal = (props: ChatWithApplicantModalProps) => {
  const { testID, jobId, onModalClose, application, modalOpen } = props;
  const { t } = useTranslation();
  const { onCallPress, onWhatsAppPress } = useChatWithApplicantModal({
    jobId,
    application
  });

  if (!application) {
    return null;
  }

  return (
    <Modal
      title={t('applications.chatModal.title')}
      visible={modalOpen}
      testID={testID}
      onRequestClose={onModalClose}
    >
      <Row justifyContent="space-around">
        <ChatIconLink
          text={t('applications.chatModal.action.call')}
          icon={<PhoneIcon size="xxlarge" variant="outline" />}
          onPress={onCallPress}
          testID={`chat-with-applicant-icons-${application.id}-call`}
        />
        <ChatIconLink
          text={t('applications.chatModal.action.whatsApp')}
          icon={<WhatsAppLogo width="xsmall" height="xsmall" />}
          onPress={onWhatsAppPress}
          testID={`chat-with-applicant-icons-${application.id}-whatsapp`}
        />
      </Row>
    </Modal>
  );
};
