import { RIGHT_TO_WORK, type RightToWork } from '@common/constants/data';
import { FILTERS } from '../../../types';

type Filter = {
  [FILTERS.RIGHT_TO_WORK]?: RightToWork[];
};

type Item = {
  candiRightToWork?: boolean;
};

export const filterRightToWork = <T extends Item>(
  filter: Filter,
  items: T[]
): T[] => {
  const filterValues = filter[FILTERS.RIGHT_TO_WORK];

  if (!filterValues) {
    return items;
  }

  const isEligibleToWork = filterValues.includes(
    RIGHT_TO_WORK.ELIGIBLE_TO_WORK
  );
  const isSponsorshipRequired = filterValues.includes(
    RIGHT_TO_WORK.SPONSORSHIP_REQUIRED
  );

  return items.filter((item) => {
    if (isEligibleToWork && isSponsorshipRequired) {
      return item.candiRightToWork === true || item.candiRightToWork === false;
    }

    if (isEligibleToWork) {
      return item.candiRightToWork === true;
    }

    if (isSponsorshipRequired) {
      return item.candiRightToWork === false;
    }

    return true;
  });
};
