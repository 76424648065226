import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Spinner.scss';
//reference: http://tobiasahlin.com/spinkit/

const DEFAULT_DELAY = 500;
let renderDelay;

export default class Spinner extends Component {
  static propTypes = {
    size: PropTypes.string.isRequired,
    fullPageOverlay: PropTypes.bool,
    centered: PropTypes.bool,
    extraTopMargin: PropTypes.bool,
    delay: PropTypes.number
  };

  constructor(props) {
    super(props);

    this.state = {
      delayedLoad: true
    };
  }

  componentDidMount() {
    const { delay } = this.props;
    const delayRender = (delay || delay === 0) ? delay : DEFAULT_DELAY;

    renderDelay = setTimeout(function () {
      this.setState({
        delayedLoad: false
      });
    }.bind(this), delayRender);
  }

  componentWillUnmount() {
    if (renderDelay) {
      clearTimeout(renderDelay);
    }
  }

  render() {
    const { fullPageOverlay, size, centered, extraTopMargin } = this.props;
    const { delayedLoad } = this.state;

    const sizeString = (size.indexOf('px') === -1) ? size + 'px' : size;

    const sizeStyle = {
      width: sizeString,
      height: sizeString
    };

    if (delayedLoad) {
      return false;
    }

    return (
      <div className={classNames({
        [styles.fullPageOverlay]: fullPageOverlay,
        [styles.centered]: centered,
        [styles.extraTopMargin]: extraTopMargin
      })} >
        <div className={styles.loader} style={sizeStyle}/>
      </div>
    );
  }
}