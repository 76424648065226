import { AppState } from '../../store';

export function getApplications(jobId: string) {
  return (state: AppState) =>
    state.entities.applications.items[jobId] || {
      totalApplication: 0,
      applicationsAvailability: {},
      statusCounts: {},
      data: []
    };
}

export function getApplicationById({
  applicationId,
  jobId
}: {
  applicationId: string;
  jobId: string;
}) {
  return (state: AppState) => {
    return (state.entities.applications.items[jobId]?.data || []).find(
      (application) => application.id === applicationId
    );
  };
}

export function getTemporaryResumeLink(applicationId: string) {
  return (state: AppState) =>
    state.entities.applications.temporaryResumeLinks[applicationId];
}

export function getApplicationAvailability(jobId: string) {
  return (state: AppState) =>
    state.entities.applications.items[jobId]?.applicationsAvailability;
}

export function getTotalApplicationCount(jobId: string) {
  return (state: AppState) =>
    state.entities.applications.items[jobId]?.totalApplication;
}
