import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { getFeatures } from '@store/config';
import { useAppSelector } from '@store/hooks';
import { useBrandName } from '../../localisation/useBrandName';

export const useCoinsFeature = (countryCode: SupportedCountryCode) => {
  const features = useAppSelector(getFeatures());
  const brandName = useBrandName();

  return Boolean(features?.coins[brandName][countryCode]);
};
