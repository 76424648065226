import React from 'react';
import { Defs, Ellipse, LinearGradient, Path, Stop } from 'svgs';
import { createIllustratedIcon } from '../createIllustratedIcon';

const vectorComponent = () => (
  <>
    <Ellipse cx={19.832} cy={20.363} fill="url(#a)" rx={16.943} ry={17.128} />
    <Path
      stroke="#FFCD6A"
      strokeWidth={2.958}
      d="M36.292 19.571c0 9.493-7.608 17.16-16.96 17.16-9.35 0-16.958-7.667-16.958-17.16s7.608-17.16 16.959-17.16c9.35 0 16.959 7.668 16.959 17.16Z"
    />
    <Path
      stroke="#D89317"
      strokeWidth={2.935}
      d="M37.158 20.148c0 9.42-7.549 17.028-16.828 17.028S3.502 29.567 3.502 20.148c0-9.42 7.549-17.028 16.828-17.028s16.828 7.609 16.828 17.028Z"
    />
    <Path
      fill="#D89317"
      d="M25.241 9.86H23.27a1.782 1.782 0 0 0-1.391.531c-.576.57-.765.89-.765 1.692v12.941c-.184 1.41-.983 2.114-2.397 2.114-1.415 0-2.175-.705-2.282-2.114V22.49h-3.866v2.942c0 1.24.047 5.004 6.148 4.86 6.1-.144 6.524-3.596 6.524-4.86V9.86Z"
    />
    <Path
      stroke="url(#b)"
      strokeWidth={2.935}
      d="M36.873 19.715c0 9.579-7.676 17.316-17.113 17.316-9.436 0-17.113-7.737-17.113-17.316C2.647 10.137 10.324 2.4 19.76 2.4s17.113 7.738 17.113 17.316Z"
    />
    <Path
      fill="#FFEAB6"
      d="M24.336 9.744h-1.88a1.679 1.679 0 0 0-1.326.518c-.549.555-.728.868-.728 1.65v12.614c-.176 1.374-.938 2.06-2.286 2.06-1.349 0-2.074-.686-2.176-2.06v-2.47h-3.685v2.867c0 1.209.044 4.878 5.86 4.738 5.818-.14 6.221-3.505 6.221-4.738V9.744Z"
    />
    <Defs>
      <LinearGradient
        id="a"
        x1={8.513}
        x2={33.194}
        y1={10.431}
        y2={28.813}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#E39D21" />
        <Stop offset={0.17} stopColor="#FB3" />
        <Stop offset={0.485} stopColor="#FFD877" />
        <Stop offset={0.745} stopColor="#FB3" />
        <Stop offset={1} stopColor="#D89317" />
      </LinearGradient>
      <LinearGradient
        id="b"
        x1={3.814}
        x2={33.366}
        y1={3.739}
        y2={30.838}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#E39D21" />
        <Stop offset={0.246} stopColor="#FB3" />
        <Stop offset={0.531} stopColor="#FFEEC2" />
        <Stop offset={0.795} stopColor="#FB3" />
        <Stop offset={1} stopColor="#E39D21" />
      </LinearGradient>
    </Defs>
  </>
);

export const JoraCoinIcon = createIllustratedIcon({
  viewBox: '0 0 39 39',
  vectorComponent
});
