import { useBrandName, useSiteCountryCode } from '@common/hooks/localisation';
import { useLimitJobRolesStatus } from '../useLimitJobRolesStatus';

/**
 * Determines whether job roles should be limited based on the current country and brand configuration.
 * @returns {boolean} Whether job roles should be limited.
 */
export const useLimitJobRolesEnabled = () => {
  const brand = useBrandName();
  const siteCountryCode = useSiteCountryCode();
  const limitJobRoles = useLimitJobRolesStatus();

  return Boolean(limitJobRoles?.[brand]?.[siteCountryCode]);
};
