import { useHistory, useParams } from 'react-router-native';
import { useGetUpgradeJobLink } from '@common/hooks/appPageLinks';
import { AD_TYPE } from '@seek/je-shared-data';
import { upgraded } from '@src/job';

/**
 * Custom hook that redirects to the upgrade job link if the ad type is invalid.
 * @param adType - The ad type to check.
 */
export const useRedirectInvalidAdType = (adType: AD_TYPE) => {
  const { push } = useHistory();
  const { jobId } = useParams<{ jobId: string }>();
  const upgradeJobLink = useGetUpgradeJobLink(jobId);

  if (!upgraded(adType)) push(upgradeJobLink);
};
