import { AppState } from '../store';

export function getLoadAndErrorState(state: AppState) {
  return state.app;
}

export function getRequestState(requestKey: string) {
  return ({ app: { error, loadStackWithSpinner } }: AppState) => ({
    isLoading: loadStackWithSpinner?.includes(requestKey),
    error: error[requestKey]
  });
}

export function getSubmissionError(requestKey: string) {
  return ({ app: { submissionError } }: AppState): Record<string, string> =>
    submissionError[requestKey];
}
