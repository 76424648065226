import { Job } from '@common/types';

export const mapJobToFormValues = (job: Job) => ({
  id: job.id,
  jobTitle: job.title,
  workExperience: job.workExperience,
  rightToWork: job.rightToWork,
  shiftAvailability: job.shiftAvailability,
  jobType: job.jobType,
  jobDescription: job.description,
  startDate: job.startDate,
  ...(job.salary ? { salary: job.salary } : {})
});
