import React from 'react';
import { BusinessImageAndDetails } from '@common/components/BusinessImageAndDetails';
import { Text } from '@components/Text';
import { PostJobFormBusiness } from '../../types';
import { BusinessName } from './components/BusinessName/BusinessName';
import { useBusinessInformation } from './hooks/useBusinessInformation';

type DisplayBusinessInformationProps = {
  selectedBusiness: PostJobFormBusiness;
};

export const DisplayBusinessInformation = ({
  selectedBusiness
}: DisplayBusinessInformationProps) => {
  const { locationText } = useBusinessInformation({
    business: selectedBusiness
  });

  return (
    <BusinessImageAndDetails businessSize={selectedBusiness.businessSize}>
      <BusinessName business={selectedBusiness} />
      <Text>{locationText}</Text>
    </BusinessImageAndDetails>
  );
};
