import { EventTypes } from 'redux-segment';

export const TRACK_SOCIAL_SHARE_AD_ICON_CLICKED =
  'jora-employer/entities/jobs/TRACK_SOCIAL_SHARE_AD_ICON_CLICKED';

export function trackSocialShareActionClicked({ id }) {
  return (dispatch) => {
    dispatch({
      type: TRACK_SOCIAL_SHARE_AD_ICON_CLICKED,
      meta: {
        analytics: {
          eventType: EventTypes.track,
          eventPayload: {
            event: 'Shared Icon Clicked',
            properties: {
              employerJobId: id
            }
          }
        }
      }
    });
  };
}
