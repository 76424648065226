import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRedirect } from '@common/hooks/useRedirect';
import { getRequestState } from '@store/app';
import { getUser } from '@store/auth';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';
import { Button } from '@components/Button';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { TextLink } from '@components/TextLink';
import { usePostJob } from '../../../../../joraEmployer/pages/job/post-job/usePostJob';

type postJobFormActionsProps = {
  submit: () => void;
  showCancelButton: boolean;
  submitButtonLabel: string;
  requestKey: string;
};

export const PostJobFormActions = ({
  submit,
  requestKey,
  submitButtonLabel,
  showCancelButton
}: postJobFormActionsProps) => {
  const { isLoading } = useAppSelector(getRequestState(requestKey));
  const { countryCode } = useAppSelector(getLocalisation);
  const { isAuthenticated } = useAppSelector(getUser);
  const { userHasBusiness } = usePostJob();
  const { redirect } = useRedirect();
  const { t } = useTranslation();

  const postJobButton = (text: string) => (
    <Button onPress={submit} disabled={isLoading} testID="post-job-action">
      {text}
    </Button>
  );

  const cancelButton = () => {
    if (!showCancelButton) {
      return null;
    }

    return (
      <Button
        variant="secondary"
        onPress={redirect(`/${countryCode}/`)}
        disabled={isLoading}
      >
        {t('common.action.cancel')}
      </Button>
    );
  };

  if (userHasBusiness) {
    return (
      <Row spacing="small">
        {postJobButton(t(submitButtonLabel))}
        {cancelButton()}
      </Row>
    );
  }

  return (
    <Stack spacing="medium">
      {postJobButton(t('common.action.next'))}

      {!isAuthenticated ? (
        <Row spacing="xxsmall">
          <Text>{t('registerForm.description.alreadyHaveAnAccount')}</Text>

          <TextLink onPress={redirect(`/${countryCode}/login`)}>
            {t('common.action.login')}
          </TextLink>
        </Row>
      ) : null}
    </Stack>
  );
};
