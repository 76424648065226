import { DEVICE_TYPE, DeviceType } from '@common/helpers/deviceInfo';
import {
  PAYMENT_CAMPAIGN,
  PAYMENT_METHOD,
  PaymentMethodType
} from '@store/payment/constants';

export function getRedirectBaseURL(
  paymentMethod: PaymentMethodType,
  deviceType: DeviceType,
  webBaseURL: string
): string {
  const nativeURL = 'joraemployermobile://deeplink';

  const config = {
    [PAYMENT_METHOD.indonesianBankTransfer]: {
      [DEVICE_TYPE.ios]: nativeURL,
      [DEVICE_TYPE.android]: nativeURL,
      [DEVICE_TYPE.web]: webBaseURL
    },
    [PAYMENT_METHOD.card]: {
      [DEVICE_TYPE.ios]: '', // Redirecting via dispatch push so not required base url
      [DEVICE_TYPE.android]: '', // Redirecting via dispatch push so not required base url
      [DEVICE_TYPE.web]: webBaseURL
    }
  };

  return config[paymentMethod]?.[deviceType] || '';
}

export function getSuccessUrlPathFromCampaign(props): string {
  const {
    jobId,
    countryCode,
    campaign,
    adType,
    jobCountry,
    paymentMethod,
    deviceType
  } = props;
  const baseUri = `jobs/${jobId}/payment-success?adType=${adType}&jobCountry=${jobCountry}`;
  const baseUriWithRedirect = `/${countryCode}/redirect?redirectPath=jobs/${jobId}/payment-success&adType=${adType}&jobCountry=${jobCountry}&paymentMethod=${paymentMethod}`;

  const getSuccessUrl = {
    [PAYMENT_METHOD.indonesianBankTransfer]:
      getSuccessUrlPathForIndonesianBankTransfer(
        baseUri,
        baseUriWithRedirect,
        deviceType
      ),
    [PAYMENT_METHOD.card]: getSuccessUrlPathForCard(baseUri, countryCode)
  };

  let successUrl = getSuccessUrl[paymentMethod]();
  const fromPostJobFlowCampaign = ![PAYMENT_CAMPAIGN.DEFAULT].includes(
    campaign
  );

  if (fromPostJobFlowCampaign) {
    successUrl = `${successUrl}&fromPostJob=true`;
  }

  return successUrl;
}

function getSuccessUrlPathForIndonesianBankTransfer(
  baseUri,
  baseUriWithRedirect,
  deviceType
) {
  return function () {
    if ([DEVICE_TYPE.ios, DEVICE_TYPE.android].includes(deviceType)) {
      return `/${baseUri}&paymentMethod=${PAYMENT_METHOD.indonesianBankTransfer}`;
    }

    return baseUriWithRedirect;
  };
}

function getSuccessUrlPathForCard(baseUri, countryCode) {
  return function () {
    return `/${countryCode}/${baseUri}`;
  };
}
