import { Step, StepMap } from './types';

/**
 * Converts a step to its corresponding title key.
 * @param step - The step to convert.
 * @param stepMap - The step map containing the mapping of steps to title keys.
 * @returns The title key corresponding to the given step.
 * @throws Error if the step is not found in the step map.
 */
export function stepToTitleKey(step: Step, stepMap: StepMap): string {
  const stepMapItem = stepMap.find((smi) => smi.step === step);
  if (!stepMapItem) throw new Error(`Step ${step} not found in step map`);
  return stepMapItem.titleKey;
}
