import { useHistory } from 'react-router-native';
import { pathToStep } from '@src/caja/steps';
import { getLastPathPart } from '@src/caja/steps/getLastPathPart';
import { stepToPath } from '@src/caja/steps/stepToPath';
import { Step, StepMap } from '@src/caja/steps/types';
import { modifyUrlPath } from '@src/lib/func';

export function useRedirectOnStepChange(currentPath: string, stepMap: StepMap) {
  const history = useHistory();
  return (step: Step) => {
    const lastPathPart = getLastPathPart(currentPath || '');
    const currentStep = pathToStep(lastPathPart, stepMap);

    if (step === currentStep) {
      return;
    }

    history.push(modifyUrlPath(stepToPath(step, stepMap), currentPath));
  };
}
