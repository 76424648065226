import React from 'react';
import { useHistory } from 'react-router-native';
import { BrandName } from '@seek/je-shared-data/lib/types/brand';
import { JobStreetExpressLogo, JoraLogo } from '@components/Logo/logos';
import * as theme from '../../../../common/theme/theme';
import { useSetLocalisation } from './useLocalisation';
import { useSetAppPreference } from './useSetAppPreference';

export function useCountrySelected(brandName) {
  const { setAppPreference } = useSetAppPreference();
  const { setLocalisation } = useSetLocalisation();
  const history = useHistory();

  return {
    handleCountrySelected: ({ country: countryCode }) => {
      setAppPreference(countryCode);
      setLocalisation(brandName, countryCode);
      history.push(`/${countryCode}/login`);
    }
  };
}

export function useRedirectToLogin(countryCode) {
  const history = useHistory();

  if (countryCode) {
    history.push(`/${countryCode}/login`);
  }
}

export function useLogo() {
  return {
    getLogo: (brandName) => {
      switch (brandName) {
        case BrandName.JOBSTREETEXPRESS:
          return (
            <JobStreetExpressLogo
              testID="jobStreetExpressLogo"
              height={theme.gridRowHeight * 16}
              width={theme.gridRowHeight * 40}
              title={'JobStreet Express'}
            />
          );
        case BrandName.JORA:
        default:
          return (
            <JoraLogo
              testID="joraLogo"
              height={theme.gridRowHeight * 16}
              width={theme.gridRowHeight * 16}
              title={'Jora'}
            />
          );
      }
    }
  };
}
