import React from 'react';
import { Trans as I18nextTrans } from 'react-i18next';
import { RequestErrorMessage } from '@common/components/RequestErrorMessage';
import REQUEST_KEY from '@common/constants/requestKeys';
import { getRequestState } from '@store/app';
import { loadBusinesses } from '@store/entities/businesses/actions';
import { useAppDispatch } from '@store/hooks';
import { useAppSelector } from '@store/hooks';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { TextLink } from '@components/TextLink';

export const LoadBusinessErrorMessage = () => {
  const dispatch = useAppDispatch();

  const { error: loadBusinessesError } = useAppSelector(
    getRequestState(REQUEST_KEY.BUSINESS.LIST)
  );

  if (!loadBusinessesError) {
    return null;
  }

  return (
    <Stack spacing="small">
      <RequestErrorMessage requestKey={REQUEST_KEY.BUSINESS.LIST} />

      <Text size="small" tone="neutralLight" testID="please-try-again">
        <I18nextTrans
          i18nKey="common.action.pleaseTryAgain"
          components={[
            <TextLink
              onPress={() => dispatch(loadBusinesses())}
              size="small"
              testID="try-again-link"
            />
          ]}
        />
      </Text>
    </Stack>
  );
};
