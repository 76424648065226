import React, { useCallback } from 'react';
import {
  AdCardContainer,
  AdUpgradeCard
} from '@common/components/AdUpgradeCard';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { useGetAdDuration } from '@common/hooks/job';
import { useBrandName } from '@common/hooks/localisation';
import { useRedirect } from '@common/hooks/useRedirect';
import { AD_TYPE } from '@seek/je-shared-data';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { UpgradeableAdType } from '@src/job/adType';
import { Stack } from '@components/Stack';
import { CoinAmount } from '../components';
import { BasicAdLink } from '../components/BasicAdLink';
import {
  CoinPaymentModal,
  useCoinPaymentModal
} from '../components/CoinPaymentModal';
import { DisclaimerText } from '../components/DisclaimerText';
import {
  useGetCoinPrice,
  useOnCoinPaymentSubmit,
  useSelectPaymentOption
} from '../hooks';

type Props = {
  job: {
    id: string;
    countryCode: SupportedCountryCode;
  };
  getPaymentSuccessLink: (adType: UpgradeableAdType) => string;
  basicAdLink?: string;
  campaign?: string;
};

/**
 * Component to display coin payment options for job ads
 */
export const CoinAdPanel = (props: Props) => {
  const { job, basicAdLink, campaign, getPaymentSuccessLink } = props;
  const { countryCode: jobCountryCode, id: jobId } = job;
  const brandName = useBrandName();

  const { redirect } = useRedirect();
  const { pricingText, fetchErrorMessage } = useGetCoinPrice({
    jobCountryCode
  });

  const { adDuration } = useGetAdDuration(jobCountryCode);

  const { isModalOpen, modalAdType, openModal, closeModal } =
    useCoinPaymentModal();

  const { onPlusAdPressed, onMaxAdPressed } = useSelectPaymentOption({
    jobId,
    campaign,
    pricingText,
    onAdTypeSelected: openModal
  });

  const onSuccessfulPayment = useCallback(() => {
    closeModal();
    redirect(getPaymentSuccessLink(modalAdType!))();
  }, [closeModal, getPaymentSuccessLink, modalAdType, redirect]);

  const { onCoinPaymentPress, errorMessage: upgradeErrorMessage } =
    useOnCoinPaymentSubmit({
      jobId,
      countryCode: jobCountryCode,
      onSuccessfulPayment: onSuccessfulPayment
    });

  return (
    <Stack spacing="large" testID="coin-ad-panel">
      <Stack spacing="medium">
        <AdCardContainer>
          <AdUpgradeCard
            adDuration={adDuration}
            brand={brandName}
            country={jobCountryCode}
            price={<CoinAmount price={pricingText[AD_TYPE.PLUS]} />}
            type={AD_TYPE.PLUS}
            onButtonPress={onPlusAdPressed}
          />
          <AdUpgradeCard
            adDuration={adDuration}
            brand={brandName}
            country={jobCountryCode}
            price={<CoinAmount price={pricingText[AD_TYPE.MAX]} />}
            type={AD_TYPE.MAX}
            onButtonPress={onMaxAdPressed}
          />
        </AdCardContainer>

        {basicAdLink ? (
          <BasicAdLink
            adDuration={adDuration[AD_TYPE.BASIC]}
            link={basicAdLink}
          />
        ) : null}

        <DisclaimerText jobCountryCode={jobCountryCode} />

        <CoinPaymentModal
          visible={isModalOpen}
          selectedAdType={modalAdType}
          onClose={closeModal}
          coinPrice={pricingText[modalAdType as UpgradeableAdType]}
          onPaymentPress={() => onCoinPaymentPress(modalAdType)}
          errorMessage={upgradeErrorMessage}
        />
      </Stack>

      <ErrorMessage>{fetchErrorMessage}</ErrorMessage>
    </Stack>
  );
};
