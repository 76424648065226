import { RightToWork } from '@common/constants/data';
import { ApplicationStatus } from '@common/types';
import { ProfileLocation } from './components/ApplicationsFilterSortBar/components/FiltersModal/components/FiltersContent/components/LocationFilter/useLocationOptions/type';

export enum FILTERS {
  APPLICANT_STATUS = 'applicant_status',
  VIDEO_SCREENING = 'video_screening',
  SORT_APPLICATIONS = 'sort_applications',
  DISTANCE = 'distance',
  RIGHT_TO_WORK = 'right_to_work',
  LOCATION = 'location'
}

export enum SORT {
  APPLICATION_DATE = 'applicationDate',
  DISTANCE = 'distance',
  RIGHT_TO_WORK = 'rightToWork'
}

export type ApplicationSortValue =
  | SORT.APPLICATION_DATE
  | SORT.DISTANCE
  | SORT.RIGHT_TO_WORK;

export type ApplicationFilterValues = {
  [FILTERS.SORT_APPLICATIONS]: ApplicationSortValue;
  [FILTERS.APPLICANT_STATUS]?: ApplicationStatus[];
  [FILTERS.DISTANCE]?: number;
  [FILTERS.VIDEO_SCREENING]?: boolean;
  [FILTERS.RIGHT_TO_WORK]?: RightToWork[];
  [FILTERS.LOCATION]?: ProfileLocation[];
};
