import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-native';
import { ProgressIndicator } from '@common/componentsV2/ProgressIndicator';
import { MilestoneStep } from '@common/componentsV2/ProgressIndicator/components/MilestoneStep';
import { useRegistrationProgressIndicator } from './hooks';

interface RegistrationProgressIndicatorProps {
  currentPathOverride?: string;
}

export const RegistrationProgressIndicator = ({
  currentPathOverride
}: RegistrationProgressIndicatorProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { paths } = useRegistrationProgressIndicator();

  return (
    <ProgressIndicator
      currentStepPathname={currentPathOverride || pathname}
      testID="registration-progress-indicator"
    >
      <MilestoneStep
        label={t('registerPage.progressIndicator.createJob')}
        pathname={paths.postJob}
        testID="1-createJob"
      />
      <MilestoneStep
        label={t('registerPage.progressIndicator.createBusiness')}
        pathname={paths.createBusiness}
        testID="2-createBusiness"
      />
      <MilestoneStep
        label={t('registerPage.progressIndicator.createAccount')}
        pathname={paths.register}
        testID="3-createAccount"
      />
    </ProgressIndicator>
  );
};
