import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { SingleSelect } from '@common/components/FormElements/SingleSelect';
import { Box } from '@components/Box';
import { Field } from '@components/FormElements';
import { Stack } from '@components/Stack';

type RightToWorkRadioProps = {
  value?: boolean;
  onChange: (key: boolean) => void;
  error?: string;
};

export const RightToWorkRadio = ({
  value,
  onChange,
  error
}: RightToWorkRadioProps) => {
  const { t } = useTranslation();

  return (
    <Stack spacing="small">
      <Field
        label={t('postJobForm.fieldLabel.rightToWorkVisa')}
        message={
          error && (
            <Box marginTop="xsmall">
              <ErrorMessage>{error}</ErrorMessage>
            </Box>
          )
        }
      >
        <Box marginTop="xsmall">
          <SingleSelect<boolean>
            testID="job-form-rightToWork"
            onChange={onChange}
            options={[
              {
                label: t('postJobForm.rightToWorkVisa.yes'),
                key: true
              },
              {
                label: t('postJobForm.rightToWorkVisa.no'),
                key: false
              }
            ]}
            value={value}
          />
        </Box>
      </Field>
    </Stack>
  );
};
