import { getUser } from '@store/auth';
import { useAppSelector } from '@store/hooks';

/**
 * Retrieves the isAuthenticated from the auth state.
 * @returns {boolean} The isAuthenticated.
 */
export function useHasAuthentication(): boolean {
  const { isAuthenticated } = useAppSelector(getUser);
  return isAuthenticated;
}
