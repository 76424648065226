import React from 'react';
import { useBrandName } from '@common/hooks/localisation';
import { BrandName } from '@seek/je-shared-data/lib/types/brand';
import { JoraAppLandingIllustration } from '@components/Illustration';

export const ShowAppLandingIllustration = () => {
  const brand = useBrandName();

  if (brand !== BrandName.JORA) {
    return null;
  }

  return (
    <JoraAppLandingIllustration
      size="xlarge"
      testID="jora-show-app-landing-illustration"
    />
  );
};
