import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <>
    <Path
      d="M7.772 2.917H3.464a.55.55 0 0 0-.547.56c.29 7.386 6.227 13.322 13.612 13.606a.539.539 0 0 0 .554-.541v-4.314a.533.533 0 0 0-.444-.528l-2.305-.406a.521.521 0 0 0-.464.148l-2.028 2.028a.536.536 0 0 1-.754 0L6.53 8.905a.536.536 0 0 1 0-.753l2.029-2.029a.52.52 0 0 0 .148-.457L8.3 3.361a.538.538 0 0 0-.528-.444Z"
      strokeMiterlimit={10}
    />
  </>
);

export const PhoneIcon = createIcon({
  viewBox: '0 0 20 20',
  vectorComponent
});
