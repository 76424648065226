import type {
  FormProps,
  IncompleteBusinessFormData,
  OnChangeCallback,
  OnSubmitCallback
} from '../types';

export interface HydratedBusinessFormProps {
  defaultValues: IncompleteBusinessFormData;
  onChange: OnChangeCallback;
  onSubmit: OnSubmitCallback;
}

export function hydrateBusinessFormProps(
  props: FormProps
): HydratedBusinessFormProps {
  return {
    defaultValues: props.defaultValues ?? {},
    onChange: props.onChange ?? (() => {}),
    onSubmit: props.onSubmit ?? (() => {})
  };
}
