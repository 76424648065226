import { Rules } from './types';

function isPresent(value: any, rules: Rules) {
  if (!rules.required) return true;
  return value !== '' && value !== undefined && value !== null;
}

function maxLength(value: string, rules: Rules) {
  if (!rules.maxLength) return true;
  return value?.length <= rules.maxLength.value;
}

function minLength(value: string, rules: Rules) {
  if (!rules.minLength) return true;
  return value?.length >= rules.minLength.value;
}

function max(value: number, rules: Rules) {
  if (!rules.max) return true;
  return value <= rules.max.value;
}

function min(value: number, rules: Rules) {
  if (!rules.min) return true;
  return value >= rules.min.value;
}

function pattern(value: string, rules: Rules) {
  if (!rules.pattern) return true;
  return rules.pattern.value.test(value);
}

function validate(value: any, rules: Rules) {
  if (!rules.validate) return true;
  return !rules.validate(value);
}

/**
 * Checks if all the rules for a given data key are valid.
 * @param data - The data object.
 * @param key - The key of the data object to validate.
 * @param rules - The rules to apply for validation.
 * @returns A boolean indicating whether all the rules are valid.
 */
export function rulesAreValid(data: any, key: string, rules: Rules) {
  return [
    isPresent(data[key], rules),
    maxLength(data[key], rules),
    minLength(data[key], rules),
    max(data[key], rules),
    min(data[key], rules),
    pattern(data[key], rules),
    validate(data[key], rules)
  ].every(Boolean);
}
