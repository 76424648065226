import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@components/Text';

type Props = {
  count: number;
};

export const ApplicationsBarTitle = ({ count }: Props) => {
  const { t } = useTranslation();

  return (
    <Text size="xlarge" weight="medium">
      {t('applications.title.applicantCount', { count })}
    </Text>
  );
};
