import { useTranslation } from 'react-i18next';

export const useJobTitleValidation = () => {
  const { t } = useTranslation();

  return {
    validate: (val: { jobTitle: string; role: string }) => {
      if (!val?.jobTitle && !val?.role) {
        return t('validations.job.title.required');
      }
    }
  };
};
