import { AppAction, AppError } from './actions';
import * as constants from './constants';

type LoadAndErrorState = {
  loadStack: string[];
  loadStackWithSpinner: string[];
  error: {
    [requestKey: string]: AppError | undefined;
  };
  submissionError: {
    [requestKey: string]: any;
  };
};

const initialState: LoadAndErrorState = {
  loadStack: [],
  loadStackWithSpinner: [],
  error: {},
  submissionError: {}
};

export default function reducer(
  state = initialState,
  { payload, type }: AppAction
): LoadAndErrorState {
  switch (type) {
    case constants.START_LOADING_WITH_SPINNER:
      return {
        ...state,
        loadStackWithSpinner: [
          ...state.loadStackWithSpinner,
          payload.requestKey
        ]
      };

    case constants.END_LOADING_WITH_SPINNER: {
      const updatedStack = state.loadStackWithSpinner.filter(
        (item) => item !== payload.requestKey
      );

      return {
        ...state,
        loadStackWithSpinner: updatedStack
      };
    }

    case constants.START_LOADING:
      return {
        ...state,
        loadStack: [...state.loadStack, payload.requestKey]
      };

    case constants.END_LOADING: {
      const updatedStack = state.loadStack.filter(
        (item) => item !== payload.requestKey
      );

      return {
        ...state,
        loadStack: updatedStack
      };
    }

    case constants.UPDATE_ERROR: {
      const { requestKey, error } = payload;

      return {
        ...state,
        error: {
          ...state.error,
          [requestKey]: error
        }
      };
    }

    case constants.UPDATE_SUCCESS:
    case constants.CLEAR_ERROR: {
      const { requestKey } = payload;

      return {
        ...state,
        error: {
          ...state.error,
          [requestKey]: undefined
        },
        submissionError: {
          ...state.submissionError,
          [requestKey]: undefined
        }
      };
    }

    case constants.UPDATE_SUBMISSION_ERROR: {
      const { requestKey, context } = payload;

      return {
        ...state,
        submissionError: {
          ...state.submissionError,
          [requestKey]: context
        }
      };
    }

    case constants.CLEAR_SUBMISSION_ERROR: {
      const { requestKey } = payload;

      return {
        ...state,
        submissionError: {
          ...state.submissionError,
          [requestKey]: undefined
        }
      };
    }

    default:
      return state;
  }
}
