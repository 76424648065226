import { UserDataAction } from './actions';
import * as constants from './constants';

const initialState: { [key: string]: any } = {};

export default function reducer(
  state = initialState,
  action: Partial<UserDataAction> = {}
) {
  switch (action.type) {
    case constants.USER_DATA_LOAD_SUCCESS:
    case constants.USER_DATA_UPDATE_SUCCESS:
      return { ...action.result };
    default:
      return state;
  }
}
