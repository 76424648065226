import { useTranslation } from 'react-i18next';
import { Transaction } from '@common/hooks/coins';

export const useFormatTransactionInfo = (transaction: Transaction) => {
  const { t } = useTranslation();
  const hasJobInfo = transaction.jobTitle && transaction.jobType;
  const isDebit = transaction.debitOrCredit === 'DEBIT';

  return {
    isDebit,
    jobInfo:
      hasJobInfo &&
      `${transaction.jobTitle} – ${t(
        `jobDetailsAndApplicationsListPage.jobType.${transaction.jobType}`
      )}`,
    amount: `${isDebit ? '-' : '+'}${transaction.amount} ${
      transaction.amount === 1
        ? `${t('myCoinsPage.coin')}`
        : `${t('myCoinsPage.coins')}`
    }`
  };
};
