import React from 'react';
import { RequestErrorMessage } from '@common/components/RequestErrorMessage';
import REQUEST_KEY from '@common/constants/requestKeys';
import { Application } from '@common/types/Application';
import { Warning } from './components/Warning';
import { useCoverLetterAndResumeStatusMessages } from './useCoverLetterAndResumeStatusMessages';

type CoverLetterMessageProps = {
  application: Application;
};

export const CoverLetterAndResumeStatusMessages = ({
  application
}: CoverLetterMessageProps) => {
  const { resumeStatusMessage, isResumeStatusCritical } =
    useCoverLetterAndResumeStatusMessages(application);

  return (
    <>
      {resumeStatusMessage ? (
        <Warning
          message={resumeStatusMessage}
          critical={isResumeStatusCritical}
        />
      ) : null}

      <RequestErrorMessage
        requestKey={`${REQUEST_KEY.APPLICATION.GET_RESUME_URL_BY_ID(
          application.id
        )}`}
        testID={`${application.id}-resume-error`}
      />
    </>
  );
};
