import { DimensionValue } from 'react-native';

const fractionToPercent = (
  numerator: number,
  denominator: number
): DimensionValue => {
  return `${(numerator * 100) / denominator}%`;
};

const exactSizes = {
  none: 0,
  dot: 4,
  xxxxsmall: 16,
  xxxsmall: 48,
  xsmall: 96,
  small: 184,
  medium: 320,
  large: 432,
  xlarge: 656,
  xxlarge: 752,
  xxxlarge: 1024
};

const fractionalSizes = {
  '1/2': fractionToPercent(1, 2),
  '1/3': fractionToPercent(1, 3),
  '2/3': fractionToPercent(2, 3),
  '1/4': fractionToPercent(1, 4),
  '2/4': fractionToPercent(2, 4),
  '3/4': fractionToPercent(3, 4),
  '1/5': fractionToPercent(1, 5),
  '2/5': fractionToPercent(2, 5),
  '3/5': fractionToPercent(3, 5),
  '4/5': fractionToPercent(4, 5),
  '1/7': fractionToPercent(1, 7),
  '3/7': fractionToPercent(3, 7),
  full: '100%'
} as const;

export const sizes = {
  ...exactSizes,
  ...fractionalSizes
} as const;

export type ExactSizes = keyof typeof exactSizes;
export type FractionalSizes = keyof typeof fractionalSizes;
export type Sizes = keyof typeof sizes;
