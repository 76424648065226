import React from 'react';
import { Link } from '@components/Link';

type CardActionLinkProps = {
  icon: React.ReactElement;
  text: string;
  onPress: () => void;
};

export const CardActionLink = ({
  icon,
  text,
  onPress
}: CardActionLinkProps) => {
  return (
    <Link
      variant="secondary"
      align={{
        mobile: 'vertical',
        tablet: 'horizontal',
        desktop: 'horizontal'
      }}
      icon={icon}
      onPress={onPress}
    >
      {text}
    </Link>
  );
};
