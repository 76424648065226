import {
  trackJobTitleContactUsLinkoutClick,
  trackJobTitleMainLinkoutClick
} from '@store/entities/jobs/actions';
import { useAppDispatch } from '@store/hooks';

export default function useTrackLink() {
  const dispatch = useAppDispatch();

  return {
    trackBrandLinkoutClick: () => dispatch(trackJobTitleMainLinkoutClick()),
    trackContactUsLinkoutClick: () =>
      dispatch(trackJobTitleContactUsLinkoutClick())
  };
}
