import { EventTypes } from 'redux-segment';

const TRACK_TIPS_LINK_CLICKED =
  'jora-employer/entities/jobs/TRACK_TIPS_LINK_CLICKED';

export function trackTipsClicked() {
  return (dispatch) => {
    dispatch({
      type: TRACK_TIPS_LINK_CLICKED,
      meta: {
        analytics: {
          eventType: EventTypes.track,
          eventPayload: {
            event: 'Job ad tips link clicked'
          }
        }
      }
    });
  };
}
