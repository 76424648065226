import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent } from '@components/Card';
import { Heading } from '@components/Heading';
import { CandidatesIcon, GraphLineIcon } from '@components/Icon';
import { useModal } from '@components/Modal';
import { TextLink } from '@components/TextLink';
import { LearnMoreModal, TileComponentWithIcon } from './components';

export const MonetisationTile = () => {
  const { t } = useTranslation();
  const { isModalOpen, openModal, closeModal } = useModal();

  return (
    <Card>
      <CardContent>
        <Heading level="h2" weight="medium">
          {t('employerLandingPage.monetisationTile.heading')}
        </Heading>

        <TileComponentWithIcon
          heading={t(
            'employerLandingPage.monetisationTile.candidatesReach.heading'
          )}
          icon={<CandidatesIcon variant="outline" size="medium" tone="brand" />}
        >
          {t(
            'employerLandingPage.monetisationTile.candidatesReach.description'
          )}
        </TileComponentWithIcon>

        <TileComponentWithIcon
          heading={t(
            'employerLandingPage.monetisationTile.sponsorJobs.heading'
          )}
          icon={<GraphLineIcon variant="outline" size="medium" tone="brand" />}
        >
          {t('employerLandingPage.monetisationTile.sponsorJobs.description')}
          <TextLink onPress={openModal}>
            {t('employerLandingPage.monetisationTile.sponsorJobs.learnMore')}
          </TextLink>
        </TileComponentWithIcon>

        <LearnMoreModal visible={isModalOpen} onClose={closeModal} />
      </CardContent>
    </Card>
  );
};
