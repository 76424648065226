import { useHistory } from 'react-router-native';
import { useCoinsFeature } from '@common/hooks/featureToggles';
import { useSiteCountryCode } from '@common/hooks/localisation';

export const useRedirectIfCoinsDisabled = () => {
  const history = useHistory();
  const countryCode = useSiteCountryCode();
  const isCoinsEnabled = useCoinsFeature(countryCode);

  if (!isCoinsEnabled) {
    history.push(`/${countryCode}/`);
  }
};
