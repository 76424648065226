import { AD_TYPE, JOB_STATUS } from '@seek/je-shared-data';
import { basicOrUndefined, isLive, liveOrInReview } from '@src/job';

/**
 * Checks if an ad is upgradeable.
 * @param adType - The type of the ad.
 * @param status - The status of the ad.
 * @returns A boolean indicating whether the ad is upgradeable.
 */
export const upgradeable = (
  adType: AD_TYPE | undefined,
  status: JOB_STATUS | undefined
) => basicOrUndefined(adType) && liveOrInReview(status);

export const upgradeableAndLive = ({
  adType,
  status
}: {
  adType: AD_TYPE | undefined;
  status: JOB_STATUS | undefined;
}) => upgradeable(adType, status) && isLive(status);
