import React from 'react';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { JobTitle } from './JobTitle';
import { useJobTitleValidation } from './useJobTitleValidation';

export const JobTitleController = <T extends FieldValues>({
  name,
  control
}: UseControllerProps<T>) => {
  const jobTitleRules = useJobTitleValidation();

  return (
    <Controller
      name={name}
      control={control}
      rules={jobTitleRules}
      render={({ field: { onChange, onBlur, value }, formState }) => {
        return (
          <JobTitle
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            error={formState?.errors[name]?.message as string}
          />
        );
      }}
    />
  );
};
