import requestKeys from '@common/constants/requestKeys';
import { getRequestState } from '@store/app';
import { useAppSelector } from '@store/hooks';

export const useGetRequestErrors = () => {
  const { error: paymentIntentError } = useAppSelector(
    getRequestState(requestKeys.PAYMENT.CREATE_PAYMENT_INTENT)
  );
  const { error: getAdPricesError } = useAppSelector(
    getRequestState(requestKeys.PAYMENT.GET_AD_PRICES)
  );
  const { error: loadJobError } = useAppSelector(
    getRequestState(requestKeys.JOB.LOAD)
  );

  return {
    hasError: Boolean(paymentIntentError || getAdPricesError || loadJobError),
    requestKeys: [
      requestKeys.PAYMENT.GET_AD_PRICES,
      requestKeys.PAYMENT.CREATE_PAYMENT_INTENT,
      requestKeys.JOB.LOAD
    ]
  };
};
