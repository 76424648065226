import React from 'react';
import { Box } from '@components/Box';
import { Heading } from '@components/Heading';

export const Header = ({ title }: { title: string }) => {
  return (
    <Box paddingTop="medium" paddingLeft="medium" tone="transparent">
      <Heading level="h1">{title}</Heading>
    </Box>
  );
};
