import { useParams } from 'react-router-native';
import { useRedirectUnauthenticatedUser } from '@common/hooks/useRedirectUnauthenticatedUser';
import { LOGIN_REDIRECT_QUERY_PARAMS } from '@store/auth/constants';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';

export const useRedirectToLoginPageIfUnauthenticated = () => {
  const { countryCode } = useAppSelector(getLocalisation);

  const { jobId } = useParams<{ jobId: string }>();

  useRedirectUnauthenticatedUser({
    to: `/${countryCode}/login?${LOGIN_REDIRECT_QUERY_PARAMS.EDIT_JOB}=${jobId}`
  });
};
