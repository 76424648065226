import { ReactNode, useCallback, useState } from 'react';

function isTheSameString(str1, str2) {
  if (typeof str1 === 'string' && typeof str2 === 'string') {
    return str1 === str2;
  }

  return false;
}

type UseRevealTextArgs = {
  truncatedText?: string | ReactNode;
  fullText?: string | ReactNode;
};

export function useRevealText({ truncatedText, fullText }: UseRevealTextArgs) {
  const [isExpanded, setIsExpanded] = useState(false);

  const setTextExpanded = useCallback(() => setIsExpanded(true), []);
  const truncatedTextNotExist = !truncatedText;

  return {
    shouldDisplayExpanded:
      truncatedTextNotExist ||
      isTheSameString(truncatedText, fullText) ||
      isExpanded,
    setTextExpanded
  };
}
