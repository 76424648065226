import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <Path
    d="M7.5 14.5a7 7 0 1 0 0-14 7 7 0 0 0 0 14zM12 13l5 4.5"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeMiterlimit={10}
  />
);

export const SearchIcon = createIcon({ viewBox: '0 0 18 18', vectorComponent });
