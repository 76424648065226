import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { NavMenuItem } from './NavMenuItem';
import { useIsNavMenuHidden, useNavMenuItems } from './hooks';

export const NavMenu = () => {
  const { t } = useTranslation();
  const isNavMenuHidden = useIsNavMenuHidden();
  const navMenuItems = useNavMenuItems();

  if (isNavMenuHidden) {
    return null;
  }

  return (
    <Stack width="full" minWidth="medium" borderWidth="xsmall">
      <Row
        alignSelf="center"
        width={{
          mobile: 'large',
          tablet: 'xxlarge',
          desktop: 'xxlarge'
        }}
        maxWidth="full"
        justifyContent="space-around"
        paddingHorizontal="xxsmall"
      >
        {navMenuItems.map((item) => (
          <NavMenuItem
            text={t(item.text)}
            icon={item.icon}
            link={item.link}
            selected={item.selected}
            key={item.key}
          />
        ))}
      </Row>
    </Stack>
  );
};
