import React from 'react';
import { Path } from 'svgs';
import { VectorComponentProps, createIcon } from '../createIcon';

const vectorComponent = ({
  foregroundColor,
  backgroundColor
}: VectorComponentProps) => (
  <>
    <Path
      d="M10.5 5h11M2.5 5H5"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M7.8 6.6a1.8 1.8 0 100-3.6 1.8 1.8 0 000 3.6z"
      stroke={backgroundColor}
      fill={foregroundColor}
    />
    <Path
      d="M10.5 20.5h11M2.5 20.5H5"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M7.8 22a1.8 1.8 0 100-3.6 1.8 1.8 0 000 3.6z"
      stroke={backgroundColor}
      fill={foregroundColor}
    />
    <Path
      d="M14.081 12H2.5M21.5 12h-2"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M16.8 13.6a1.8 1.8 0 100-3.6 1.8 1.8 0 000 3.6z"
      stroke={backgroundColor}
      fill={foregroundColor}
    />
  </>
);

export const FilterIcon = createIcon({ viewBox: '2 2 21 21', vectorComponent });
