import React from 'react';
import { useForm } from 'react-hook-form';
import { Trans as I18nextTrans, useTranslation } from 'react-i18next';
import { RequestErrorMessage } from '@common/components/RequestErrorMessage';
import { When } from '@common/components/When';
import REQUEST_KEYS from '@common/constants/requestKeys';
import { useRedirect } from '@common/hooks/useRedirect';
import { Button } from '@components/Button';
import { Link } from '@components/Link';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { TextLink } from '@components/TextLink';
import { EmailController } from '../Email';
import { PasswordController } from '../Password';
import { useLoginForm } from './useLoginForm';

export type LoginFormFields = {
  email: string;
  password: string;
};

export const LoginForm = () => {
  const { t } = useTranslation();
  const { redirect } = useRedirect();
  const formMethods = useForm<LoginFormFields>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      password: ''
    }
  });

  const {
    isRegistrationEnabled,
    postJobLinkWithQueryParams,
    showFormError,
    submitLogin,
    forgotPasswordLink
  } = useLoginForm({ setError: formMethods.setError });

  const {
    control,
    handleSubmit,
    formState: { isValid }
  } = formMethods;

  const onSubmit = async (data: LoginFormFields) => {
    if (isValid) {
      await submitLogin(data);
    }
  };

  return (
    <Stack spacing="large">
      <EmailController control={control} name="email" />

      <Stack spacing="xxsmall">
        <PasswordController
          control={control}
          name="password"
          onEnter={handleSubmit(onSubmit)}
        />
        <Link onPress={redirect(forgotPasswordLink)}>
          {t('loginForm.action.forgotPassword')}
        </Link>
      </Stack>

      <Stack spacing="small">
        <Button onPress={handleSubmit(onSubmit)} testID="login-action">
          {t('loginForm.action.login')}
        </Button>
        <When condition={isRegistrationEnabled}>
          <I18nextTrans
            i18nKey="loginForm.explanationText.signUpEmployer"
            parent={Text}
            components={[
              <TextLink onPress={redirect(postJobLinkWithQueryParams)} />
            ]}
          />
        </When>
      </Stack>

      <Stack>
        {showFormError ? (
          <RequestErrorMessage requestKey={REQUEST_KEYS.AUTH.LOGIN} />
        ) : null}
      </Stack>
    </Stack>
  );
};
