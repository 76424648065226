import React from 'react';
import { useTranslation } from 'react-i18next';
import { RequestErrorMessage } from '@common/components/RequestErrorMessage';
import { ApplicationStatus } from '@common/types';
import { Column } from '@components/Column';
import { CrossIcon, RightTickIcon } from '@components/Icon';
import { IconButton } from '@components/IconButton';
import { Row } from '@components/Row';
import { Text } from '@components/Text';
import { useApplicantStatusSelector } from './useApplicantStatusSelector';

type ApplicantStatusSelectorProps = {
  requestKey: string;
  currentStatus: ApplicationStatus;
  onChange: (status: ApplicationStatus) => void;
  testID: string;
};

export const ApplicantStatusSelector = (
  props: ApplicantStatusSelectorProps
) => {
  const { testID, requestKey, currentStatus, onChange } = props;

  const { t } = useTranslation();
  const { selectorOptions } = useApplicantStatusSelector({
    applicantStatus: currentStatus
  });

  return (
    <Column justifyContent="center" testID={testID}>
      <Row spacing="medium" alignItems="center" justifyContent="center">
        {selectorOptions.map((option, index) => {
          return (
            <Column
              alignItems="center"
              justifyContent="center"
              spacing="xsmall"
              testID={`${testID}-icon-buttons`}
              key={index}
            >
              <IconButton
                icon={
                  option.isSuccessIcon ? (
                    <RightTickIcon
                      size="xsmall"
                      variant="success"
                      testID={`${testID}-${option.value}-icon-button`}
                    />
                  ) : (
                    <CrossIcon
                      size="xsmall"
                      variant="danger"
                      testID={`${testID}-${option.value}-icon-button`}
                    />
                  )
                }
                onPress={() => onChange(option.value)}
                testID={`${testID}-${index}-icon-button`}
              />

              <Text align="center" size="xsmall" tone="neutral">
                {t(`${option.translationKey}`)}
              </Text>
            </Column>
          );
        })}
      </Row>

      <RequestErrorMessage requestKey={requestKey} testID={testID} />
    </Column>
  );
};
