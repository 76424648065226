import { useTranslation } from 'react-i18next';
import { WorkExperience as WorkExperienceEnum } from '@seek/je-shared-data/lib/types/jobs';

type WorkExperience = {
  options: { key: WorkExperienceEnum; label: string }[];
  calloutText: (selectedKey: WorkExperienceEnum) => string;
};

export const useWorkExperience = (): WorkExperience => {
  const { t } = useTranslation();
  const options = [
    {
      key: WorkExperienceEnum.NO_EXPERIENCE,
      label: t('postJobForm.workExperience.noExperience')
    },
    {
      key: WorkExperienceEnum.ONE_YEAR_EXPERIENCE,
      label: t('postJobForm.workExperience.oneYearExperience')
    },
    {
      key: WorkExperienceEnum.TWO_TO_THREE_YEARS_EXPERIENCE,
      label: t('postJobForm.workExperience.twoToThreeYearsExperience')
    },
    {
      key: WorkExperienceEnum.MORE_THAN_FOUR_YEARS_EXPERIENCE,
      label: t('postJobForm.workExperience.moreThanFourYearsExperience')
    }
  ];

  const calloutText = (selectedKey: WorkExperienceEnum) =>
    `postJobForm.explanationText.workExperience.${selectedKey}`;

  return {
    options,
    calloutText
  };
};
