import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <>
    <Path
      d="M8.917 3.667h4.416M8.917 6h4.416M8.917 8.334h4.416M15.083 1H.917v10h14.166V1Z"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M5 6.167c.833 0 1.5-.667 1.5-1.5 0-.834-.667-1.5-1.5-1.5s-1.5.666-1.5 1.5c0 .833.667 1.5 1.5 1.5ZM2.5 8.75c0-1.417 1.083-2.583 2.417-2.583 1.333 0 2.416 1.166 2.416 2.583"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const VisaIcon = createIcon({
  viewBox: '0 0 16 12',
  vectorComponent
});
