import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';

i18n
  .use(HttpApi)
  .init({
    lng: window.__i18nextInit.lng,
    fallbackLng: 'en',
    backend: {
      loadPath: '../locales/{{lng}}.json',
      queryStringParams: { v: window.__INITIAL_STATE__.config.translationHash }
    },
    interpolation: {
      escapeValue: false
    },
    debug: false,
    initImmediate: false,
    react: {
      useSuspense: false
    }
  });

export default i18n;