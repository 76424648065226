import React from 'react';
import { BusinessImageAndDetails } from '@common/components/BusinessImageAndDetails';
import { BUSINESS_SIZE } from '@seek/je-shared-data';
import { BusinessState } from '@store/entities/businesses/reducers';
import { ListJobItem } from '@store/entities/jobs';
import { Box } from '@components/Box';
import { Text } from '@components/Text';

type CardBusinessInformationProps = {
  name: ListJobItem['businessName'];
  size: BusinessState['list']['businesses'][0]['businessSize'];
  location: ListJobItem['location'];
};

export const CardBusinessInformation = ({
  name,
  size,
  location
}: CardBusinessInformationProps) => (
  <BusinessImageAndDetails
    businessSize={size || BUSINESS_SIZE.LESS_THAN_5_EMPLOYEES}
  >
    <Box flexShrink={1}>
      <Text weight="regular">{name}</Text>
      <Text size="small">{location}</Text>
    </Box>
  </BusinessImageAndDetails>
);
