/* eslint css-modules/no-unused-class: [2, { markAsUsed: [
  'flexDirection-row', 'flexDirection-column', 'flexDirection-row-tablet',
  'flexWrap-wrap',
  'justifyContent-spaceBetween', 'justifyContent-spaceBetween-tablet', 'justifyContent-spaceAround', 'justifyContent-flexEnd', 'justifyContent-flexStart', 'justifyContent-flexStart-tablet',
  'alignItems-center','alignItems-center-tablet','alignItems-stretch',
  'textAlign-left', 'textAlign-center', 'textAlign-right',
  'marginLeft-auto', 'marginLeft-medium',
  'marginRight-small', 'marginRight-medium',
  'marginBottom-small', 'marginBottom-medium', 'marginBottom-large',
  'marginTop-small', 'marginTop-medium', 'marginTop-large'
] }] */
import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash.omit';
import styles from './Div.scss';

const ResponsivePropTypes = (childPropType) => PropTypes.shape({
  mobile: childPropType,
  tablet: childPropType,
  desktop: childPropType
});

export default class Div extends Component {
  static propTypes = {
    children: PropTypes.node,
    isPageContainer: PropTypes.bool,
    className: PropTypes.any,
    forwardedRef: PropTypes.object,
    marginTop: PropTypes.oneOf(['small', 'medium', 'large']),
    marginLeft: PropTypes.oneOf(['medium', 'auto']),
    marginBottom: PropTypes.oneOf(['small', 'medium', 'large']),
    marginRight: PropTypes.oneOf(['small', 'medium']),
    textAlign: PropTypes.oneOf(['left', 'center', 'right']),
    flexDirection: PropTypes.oneOfType([
      PropTypes.oneOf(['column', 'row']),
      ResponsivePropTypes(PropTypes.oneOf(['column', 'row']))
    ]),
    flexWrap: PropTypes.oneOf(['wrap']),
    justifyContent: PropTypes.oneOfType([
      PropTypes.oneOf(['spaceBetween', 'spaceAround', 'flexEnd', 'flexStart']),
      ResponsivePropTypes(PropTypes.oneOf(['spaceBetween', 'spaceAround', 'flexEnd', 'flexStart']))
    ]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    alignItems: PropTypes.oneOfType([
      PropTypes.oneOf(['center', 'stretch']),
      ResponsivePropTypes(PropTypes.oneOf(['center', 'stretch']))
    ]),
    'data-test-key': PropTypes.string
  };

  static defaultProps = {
    textAlign: 'left'
  };

  render() {
    const {
      children,
      forwardedRef,
      textAlign,
      flexDirection,
      flexWrap,
      justifyContent,
      alignItems,
      marginTop,
      marginLeft,
      marginBottom,
      marginRight,
      className,
      isPageContainer,
      height,
      width,
      'data-test-key': dataTestKey,
      ...rest
    } = this.props;

    const flexStyle = classNames({
      [styles[`flexDirection-${flexDirection}`]]: typeof flexDirection === 'string',
      [styles[`flexDirection-${flexDirection?.mobile}`]]: flexDirection?.mobile,
      [styles[`flexDirection-${flexDirection?.tablet}-tablet`]]: flexDirection?.tablet
    });

    const alignItemsStyle = classNames({
      [styles[`alignItems-${alignItems}`]]: typeof alignItems === 'string',
      [styles[`alignItems-${alignItems?.mobile}`]]: alignItems?.mobile,
      [styles[`alignItems-${alignItems?.tablet}-tablet`]]: alignItems?.tablet
    });

    const justifyContentStyle = classNames({
      [styles[`justifyContent-${justifyContent}`]]: typeof justifyContent === 'string',
      [styles[`justifyContent-${justifyContent?.mobile}`]]: justifyContent?.mobile,
      [styles[`justifyContent-${justifyContent?.tablet}-tablet`]]: justifyContent?.tablet
    });

    return (
      <div
        data-test-key={dataTestKey}
        ref={forwardedRef}
        style={{
          height,
          width
        }}
        className={classNames(
          styles[`textAlign-${textAlign}`],
          flexStyle,
          alignItemsStyle,
          justifyContentStyle,
          {
            [styles.isPageContainer]: isPageContainer,
            [styles[`marginLeft-${marginLeft}`]]: marginLeft,
            [styles[`marginRight-${marginRight}`]]: marginRight,
            [styles[`marginBottom-${marginBottom}`]]: marginBottom,
            [styles[`marginTop-${marginTop}`]]: marginTop,
            [styles[`flexWrap-${flexWrap}`]]: flexWrap,
            [className]: className
          })}
        {...omit(rest, 'onLayout', 'tReady')}
      >
        {children}
      </div>
    );
  }
}
