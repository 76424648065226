import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { UpgradeAccountBanner } from '@common/components/Account/components';
import { PageHeadingWithUserPackage } from '@common/components/PageHeadingWithUserPackage';
import { RequestErrorMessage } from '@common/components/RequestErrorMessage';
import { useActiveBusinesses } from '@common/hooks/business';
import { useAccountUpgradeable } from '@common/hooks/featureToggles/useAccountUpgradeable';
import { useRedirectUnauthenticatedUser } from '@common/hooks/useRedirectUnauthenticatedUser';
import {
  useAtBusinessLimit,
  useGetUserPackage
} from '@common/hooks/userPackages';
import { getRequestState } from '@store/app';
import { loadBusinesses } from '@store/entities/businesses/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { Box } from '@components/Box';
import { Card, CardContent } from '@components/Card';
import { Stack } from '@components/Stack';
import ListWithLoadingState from '../../../components/ListWithLoadingState/ListWithLoadingState';
import REQUEST_KEY from '../../../constants/requestKeys';
import {
  BusinessList,
  PageHeadingLayout,
  ZeroBusinessesState
} from './components';
import { AddBusinessAction } from './components/AddBusinessAction';
import ListBusinessSkeleton from './components/ListBusinessSkeleton/ListBusinessSkeleton';

export const ListBusinesses = () => {
  useRedirectUnauthenticatedUser();

  const { t } = useTranslation();
  const accountUpgradeable = useAccountUpgradeable();
  const activeBusinesses = useActiveBusinesses();
  const { packageType } = useGetUserPackage();
  const atBusinessLimit = useAtBusinessLimit();
  const pageTitle = t('businessListingPage.title');
  const freeAndUpgradeableAccount =
    accountUpgradeable && packageType === 'free';

  const hasBusinesses = activeBusinesses.length > 0;
  const businessListError = useAppSelector(
    getRequestState(REQUEST_KEY.BUSINESS.LIST)
  ).error;
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(loadBusinesses());
  }, [dispatch]);

  return (
    <>
      <Helmet title={pageTitle} />

      <ListWithLoadingState
        requestKeys={[
          REQUEST_KEY.BUSINESS.LIST,
          REQUEST_KEY.ORGANISATION.USER_LIMITS,
          REQUEST_KEY.ORGANISATION.USER_PACKAGE
        ]}
        loadingState={<ListBusinessSkeleton />}
        zeroState={<ZeroBusinessesState />}
        isZeroState={!hasBusinesses}
      >
        <Stack marginBottom="large" tone="transparent" spacing="medium">
          <PageHeadingLayout>
            <Box marginTop="xsmall" tone="transparent">
              <PageHeadingWithUserPackage
                heading={t('businessListingPage.title')}
              />
            </Box>
            <AddBusinessAction atBusinessLimit={atBusinessLimit} />
          </PageHeadingLayout>

          <BusinessList activeBusinesses={activeBusinesses} />
        </Stack>

        {freeAndUpgradeableAccount && (
          <UpgradeAccountBanner pageTitle={pageTitle} />
        )}
      </ListWithLoadingState>

      {businessListError && (
        <Card>
          <CardContent>
            <RequestErrorMessage requestKey={REQUEST_KEY.BUSINESS.LIST} />
          </CardContent>
        </Card>
      )}
    </>
  );
};
