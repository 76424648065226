import { LOGOUT_SUCCESS } from '../auth/constants';
import { PaymentAction } from './actions';
import * as constants from './constants';

type PaymentState = {
  prices: {
    items: {
      [countryCode: string]: {
        pricing: number;
        currency: string;
      };
    };
  };
  intent: {
    paymentIntentId?: string;
    clientId?: string;
    pricingText?: string;
    adType?: string;
    jobCountry?: string;
  };
  stripe?: any;
};

const initialState: PaymentState = {
  prices: {
    items: {}
  },
  intent: {},
  stripe: null
};

export default function reducer(
  state = initialState,
  action: Partial<PaymentAction> = {}
) {
  switch (action.type) {
    case constants.GET_AD_PRICES_SUCCESS:
      return {
        ...state,
        prices: {
          ...state.prices,
          items: {
            ...state.prices.items,
            ...action.result
          }
        }
      };

    case constants.CREATE_PAYMENT_INTENT:
      return {
        ...state,
        intent: {
          error: null
        }
      };

    case constants.CREATE_PAYMENT_INTENT_SUCCESS:
      return {
        ...state,
        intent: {
          ...state.intent,
          ...action.result,
          error: null
        }
      };

    case constants.LOAD_STRIPE_INSTANCE:
      return {
        ...state,
        stripe: action.result
      };

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
}
