import React from 'react';
import { useTranslation } from 'react-i18next';
import { AD_TYPE } from '@seek/je-shared-data';
import { Badge, BadgeProps } from '@components/Badge';
import { FolderIcon, JobsIcon, SuitcaseIcon } from '@components/Icon';
import { Row } from '@components/Row';
import { useAdTypeStyle } from './useAdTypeStyle';

export type AdTypeBadgeProps = {
  adType?: AD_TYPE;
  showIcon?: boolean;
  testID?: BadgeProps['testID'];
};

export const AdTypeBadge = (props: AdTypeBadgeProps) => {
  const { showIcon = true, adType = AD_TYPE.BASIC } = props;
  const { t } = useTranslation();
  const resolvedPropStyles = useAdTypeStyle(adType);

  const text = t(`adBadge.${adType}`);
  if (!showIcon) {
    return <Badge {...resolvedPropStyles}>{text}</Badge>;
  }

  const badgeIcon = {
    [AD_TYPE.BASIC]: (
      <JobsIcon size="xlarge" tone="neutralLight" variant="outline" />
    ),
    [AD_TYPE.PLUS]: (
      <FolderIcon size="xlarge" tone="success" variant="outline" />
    ),
    [AD_TYPE.MAX]: (
      <SuitcaseIcon size="xlarge" tone="success" variant="outline" />
    )
  };

  return (
    <Row spacing="xsmall">
      {badgeIcon[adType]}
      <Badge {...resolvedPropStyles}>{text}</Badge>
    </Row>
  );
};
