import React from 'react';
import { Circle, G, Mask, Path, Rect } from 'svgs';
import { createIllustration } from '../createIllustration';

const vectorComponent = () => (
  <>
    <Path
      fill="#5CC78B"
      d="M20.58 89.543c3.226 1.296 5.405 3.413 7.953 5.487 2.73 2.222 4.883 4.922 5.515 8.3-7.22 1.278-17.042 2.382-23.515-1.821-2.412-1.566-4.227-3.79-5.9-6.035a99.707 99.707 0 0 1-4.175-6.063c6.463-2.161 13.821-2.403 20.123.132Z"
    />
    <Path
      fill="#2FAC66"
      d="M34.136 102.602a553.58 553.58 0 0 1-3.056-.84c-4.576-1.299-9.17-2.862-13.08-5.268-.088-.045-.17-.1-.251-.149-.242-.15-.483-.31-.718-.47-2.35-1.577-4.42-3.505-6.04-5.917-.04-.054-.07-.108-.104-.16-.196-.306-.398-.61-.588-.917a35.431 35.431 0 0 1-4.313-10.095 33.633 33.633 0 0 1-1.058-8.866c-.004-.215-.002-.43.006-.647.11.002.222.005.332.01 3.622.154 6.986 1.343 9.95 3.224.247.155.494.32.737.491 3.026 2.06 5.667 4.723 7.59 7.504.04.057.083.122.128.18.176.242.35.49.516.738a69.65 69.65 0 0 1 5.842 10.07 66.435 66.435 0 0 1 2.352 5.613c.235.628 1.313 5.38 1.755 5.499Z"
    />
    <Path
      fill="#5CC78B"
      d="M32.906 102.269c-.607-.167-1.219-.335-1.826-.507-1.93-1.923-3.538-4.137-5.093-6.381a.675.675 0 0 1-.203.016c-2.155-.163-4.22-.01-6.296.564-.518.144-1.009.33-1.488.533-.088-.045-.17-.1-.251-.149-.241-.15-.483-.31-.717-.47.81-.382 1.692-.667 2.538-.902a17.823 17.823 0 0 1 2.732-.543c.595-.068 2.247.145 2.723-.092.058-.025.115-.066.173-.106-1.141-1.652-2.278-3.303-3.526-4.85-3.868-1.057-7.192-.636-10.783.416a25.588 25.588 0 0 1-.588-.917c3.085-1.447 6.873-1.561 10.249-.865-2.047-2.466-4.164-4.881-6.293-7.29a.415.415 0 0 1-.279-.02c-2.608-1.17-5.122-1.234-7.786-1.153-.073-.255-.14-.512-.204-.768 2.432-.254 4.929-.098 7.189.729-2.801-3.16-5.602-6.326-8.248-9.594-.003-.215-.001-.43.007-.647.11.002.222.004.332.01 2.877 3.082 5.647 6.265 8.385 9.47.024-2.148.613-4.28 1.565-6.244.246.154.494.32.737.49-.853 2.2-1.642 4.341-1.44 6.768 2.251 2.655 6.92 8.204 7.02 8.33.237-2.714.812-5.187 2.012-7.592.038.059.082.122.127.18.177.242.35.49.517.738-1.11 2.348-1.736 4.881-1.587 7.461a.564.564 0 0 1-.137.375c1.135 1.458 2.185 2.963 3.236 4.467.802-1.077 1.236-3.048 1.924-3.973.3-.404.625-.777.976-1.107.186.343.362.688.537 1.038-1.305 1.435-2.033 3.147-2.673 5.143.484.695.969 1.39 1.469 2.072 1.46 2.019 3.162 3.735 4.97 5.37Z"
    />
    <Path
      fill="#DDF8EB"
      fillRule="evenodd"
      d="M75.316.549c26.504-.86 62.885 13.311 70.944 36.019 7.955 22.418 9.299 48.967-11.944 62.72-20.922 13.547-76.605 13.761-97.353 0-20.395-13.525 1.63-43.935 9.927-65.46C54.616 13.786 51.73 1.313 75.316.548Z"
      clipRule="evenodd"
    />
    <Rect
      width={67.84}
      height={91.271}
      x={10.02}
      y={22.89}
      fill="#DEF0FC"
      rx={6.306}
      transform="rotate(-9.876 10.02 22.89)"
    />
    <Path
      fill="#0F62C7"
      d="M11.102 29.103a6.306 6.306 0 0 1 5.13-7.295l54.41-9.472a6.306 6.306 0 0 1 7.294 5.13l1.158 6.653L12.26 35.755l-1.158-6.652Z"
    />
    <G fill="#197FE6" opacity={0.5}>
      <Rect
        width={51.398}
        height={5.676}
        x={24.33}
        y={57.47}
        rx={1.261}
        transform="rotate(-9.876 24.33 57.47)"
      />
      <Rect
        width={39.41}
        height={5.676}
        x={25.852}
        y={66.213}
        rx={1.261}
        transform="rotate(-9.876 25.852 66.213)"
      />
      <Rect
        width={22.655}
        height={5.676}
        x={35.801}
        y={74.654}
        rx={1.261}
        transform="rotate(-9.876 35.801 74.654)"
      />
      <Rect
        width={22.655}
        height={5.676}
        x={34.962}
        y={41.726}
        rx={1.261}
        transform="rotate(-9.876 34.962 41.726)"
      />
      <Rect
        width={22.655}
        height={5.676}
        x={37.538}
        y={84.627}
        rx={1.261}
        transform="rotate(-9.876 37.538 84.627)"
      />
      <Rect
        width={5.676}
        height={5.676}
        x={27.572}
        y={76.088}
        rx={2.838}
        transform="rotate(-9.876 27.572 76.088)"
      />
      <Rect
        width={5.676}
        height={5.676}
        x={29.308}
        y={86.061}
        rx={2.838}
        transform="rotate(-9.876 29.308 86.06)"
      />
      <Rect
        width={9.459}
        height={9.459}
        x={21.599}
        y={41.784}
        rx={1.261}
        transform="rotate(-9.876 21.6 41.784)"
      />
    </G>
    <Circle
      cx={56.109}
      cy={21.335}
      r={1.912}
      fill="#0E8136"
      transform="rotate(-9.876 56.109 21.335)"
    />
    <Circle
      cx={62.952}
      cy={20.144}
      r={1.912}
      fill="#0E8136"
      transform="rotate(-9.876 62.952 20.144)"
    />
    <Circle
      cx={69.795}
      cy={18.952}
      r={1.912}
      fill="#0E8136"
      transform="rotate(-9.876 69.795 18.952)"
    />
    <Path
      fill="#FB3"
      d="m69.068 81.558-3.485-6.102-7.024-.22 4.727-5.2-1.961-6.748 6.406 2.888 5.812-3.95-.768 6.985 5.554 4.306-6.881 1.43-2.38 6.611Z"
    />
    <Rect
      width={67.84}
      height={91.271}
      x={24.135}
      y={14.732}
      fill="#FFF7E1"
      rx={6.306}
      transform="rotate(-5 24.135 14.732)"
    />
    <Path
      fill="#E39D21"
      d="M24.685 21.014a6.306 6.306 0 0 1 5.733-6.832L85.435 9.37a6.306 6.306 0 0 1 6.832 5.732l.588 6.727-67.582 5.913-.588-6.727Z"
    />
    <G fill="#FB3" opacity={0.5}>
      <Rect
        width={51.398}
        height={5.676}
        x={35.454}
        y={50.403}
        rx={1.261}
        transform="rotate(-5 35.454 50.403)"
      />
      <Rect
        width={39.41}
        height={5.676}
        x={36.228}
        y={59.244}
        rx={1.261}
        transform="rotate(-5 36.228 59.244)"
      />
      <Rect
        width={22.655}
        height={5.676}
        x={45.423}
        y={68.499}
        rx={1.261}
        transform="rotate(-5 45.423 68.5)"
      />
      <Rect
        width={22.655}
        height={5.676}
        x={47.386}
        y={35.619}
        rx={1.261}
        transform="rotate(-5 47.386 35.62)"
      />
      <Rect
        width={22.655}
        height={5.676}
        x={46.306}
        y={78.586}
        rx={1.261}
        transform="rotate(-5 46.306 78.586)"
      />
      <Rect
        width={5.676}
        height={5.676}
        x={37.101}
        y={69.228}
        rx={2.838}
        transform="rotate(-5 37.101 69.228)"
      />
      <Rect
        width={5.676}
        height={5.676}
        x={37.984}
        y={79.314}
        rx={2.838}
        transform="rotate(-5 37.984 79.314)"
      />
      <Rect
        width={9.459}
        height={9.459}
        x={34.067}
        y={34.542}
        rx={1.261}
        transform="rotate(-5 34.067 34.542)"
      />
    </G>
    <Circle
      cx={70.189}
      cy={17.099}
      r={1.912}
      fill="#9C590C"
      transform="rotate(-5 70.19 17.1)"
    />
    <Circle
      cx={77.109}
      cy={16.495}
      r={1.912}
      fill="#9C590C"
      transform="rotate(-5 77.109 16.495)"
    />
    <Circle
      cx={84.029}
      cy={15.89}
      r={1.912}
      fill="#9C590C"
      transform="rotate(-5 84.029 15.89)"
    />
    <Rect
      width={67.84}
      height={91.271}
      x={42.02}
      y={9.89}
      fill="#F8F3FE"
      rx={6.306}
    />
    <Path
      fill="#6E30E8"
      d="M42.02 16.196a6.306 6.306 0 0 1 6.306-6.306h55.227a6.307 6.307 0 0 1 6.307 6.306v6.752H42.02v-6.752Z"
    />
    <G fill="#D8C3F9" opacity={0.5}>
      <Rect width={51.398} height={5.676} x={50.186} y={46.413} rx={1.261} />
      <Rect width={39.41} height={5.676} x={50.186} y={55.287} rx={1.261} />
      <Rect width={22.655} height={5.676} x={58.54} y={65.309} rx={1.261} />
      <Rect width={22.655} height={5.676} x={63.361} y={32.724} rx={1.261} />
      <Rect width={22.655} height={5.676} x={58.54} y={75.433} rx={1.261} />
      <Rect width={5.676} height={5.676} x={50.186} y={65.309} rx={2.838} />
      <Rect width={5.676} height={5.676} x={50.186} y={75.432} rx={2.838} />
      <Rect width={9.459} height={9.459} x={50.186} y={30.49} rx={1.261} />
    </G>
    <Circle cx={87.692} cy={16.261} r={1.912} fill="#521ECA" />
    <Circle cx={94.638} cy={16.261} r={1.912} fill="#521ECA" />
    <Circle cx={101.584} cy={16.263} r={1.912} fill="#521ECA" />
    <Path
      fill="#FB3"
      d="m90.13 77.817-2.387-6.61-6.882-1.42 5.549-4.313-.775-6.985 5.816 3.945 6.403-2.895-1.954 6.75 4.732 5.195-7.024.227-3.478 6.106Z"
    />
    <Path
      fill="#000"
      d="M155.263 101.06c0-.016.017-.032.017-.048.223-.428.651-.729 1.011-1.063.479-.428.719-.11 1.13.191.308.222.6.46.891.698.12.095 1.456 1.206 1.267 1.365l-3.648 3.237c-.325.285-.651.571-.959.873-.291.285-.565.65-.942.856-.583.318-.788-.222-.72-.682.086-.682.6-1.158.96-1.745.513-.826.942-1.746.924-2.714 0-.333-.085-.666.069-.968ZM123.901 103.234h.052c.514-.031 1.01.143 1.507.254.634.159.48.508.411 1-.051.365-.103.714-.188 1.079-.035.143-.36 1.777-.617 1.714-1.627-.413-3.254-.809-4.881-1.222a39.1 39.1 0 0 0-1.302-.317c-.411-.096-.891-.111-1.267-.302-.583-.301-.189-.73.256-.92.686-.286 1.388-.127 2.107-.143 1.028-.032 2.107-.159 2.998-.667.308-.19.582-.428.924-.476Z"
    />
    <Path
      fill="#1C1C1C"
      d="m144.489 70.418 2.073 14.234c.034.207.12.397.24.572l10.67 13.853c.411.54.309 1.301-.24 1.73l-1.233.952c-.582.444-1.438.38-1.935-.143l-13.326-14.409a1.182 1.182 0 0 1-.274-.46l-5.206-15.789c-.275-.825.393-1.65 1.318-1.65h6.543c.685 0 1.268.476 1.37 1.11Z"
    />
    <Path
      fill="#fff"
      d="M136.474 56.201c2.715 0 4.916-.398 4.916-.888 0-.491-2.201-.889-4.916-.889-2.715 0-4.916.398-4.916.889 0 .49 2.201.888 4.916.888Z"
    />
    <Path fill="#fff" d="M135.657 55.524h1.576v-1.936h-1.576v1.936Z" />
    <Path
      fill="#1C1C1C"
      d="m136.799 69.928-.377 4.395a1.584 1.584 0 0 1-.102.381l-6.064 14.504c-.017.063-.051.127-.068.19l-3.528 14.092c-.172.666-.822 1.11-1.559 1.047l-.822-.064c-.839-.063-1.456-.761-1.336-1.539l4.847-33.752c.103-.714.805-1.238 1.576-1.174l6.063.46c.822.048 1.439.698 1.37 1.46Z"
    />
    <Path
      fill="#fff"
      d="M148.001 77.29c-.103-.492-.326-.683-.326-.683.189-.396-.017-.872-.445-1.031l-.308-.111c-.412-.143-.874.032-1.045.397l-.017.016s-.805.888-1.251 2.237c-.565 1.73.052 2.777.651 2.904.565.11 1.336-1.904 1.627-2.634l.172.84c.017.112.119.191.239.207.12.016.24-.063.291-.175l.326-.888c.103-.35.154-.714.086-1.08Z"
    />
    <Path
      fill="#22C365"
      fillRule="evenodd"
      d="m120.961 57.331 2.908-3.48-6.877-2.524c-.421-2.432-.397-4.88-.379-5.667 0-.054.573-.47 1.141-.882.401-.29.8-.58.991-.738l.944.18c.171-.015.308.096.326.239.102 1.174.633 5.744 2.363 7.236.514.444 1.216.682 1.867.873.891.27 1.833.46 2.758.603.247 0 .514.05.776.099.156.029.31.057.457.075.428.064.856.111 1.284.159.72.08 1.439.11 2.159.143.65.016 1.336.079 1.884.412.182.117.345.258.509.4.191.164.383.329.604.457a3.49 3.49 0 0 0 1.644.46c.891.032 1.747-.238 2.501-.65.593-.324 1.348-.236 2.025-.156l.253.029c.805.08 1.695.174 2.466.46.24.095.48.206.702.349.72.428 1.388.92 2.039 1.428 2.072 1.65 3.836 3.666 5.018 5.95 2.501 4.872-2.209 12.823-2.775 13.759-.051.063-.102.11-.171.127-2.175.618-3.031-.572-3.237-.92a.25.25 0 0 1-.017-.191c.223-.794 1.747-6.554 1.627-8.331-.103-1.365-2.004-3.682-3.1-4.903-.189-.222-.565-.048-.497.222l1.867 7.585c.051.159-.103.317-.291.317l-17.248-.714a.271.271 0 0 1-.274-.301l1.319-9.03c.006-.045.026-.083.042-.114.028-.055.046-.088-.042-.108.237-.458-1.326-.903-3.315-1.47-1.365-.39-2.931-.836-4.251-1.383Zm-2.335-13.314c.294-.152.287-.115.119.023l-.119-.023Z"
      clipRule="evenodd"
    />
    <Path
      fill="#fff"
      d="M144.387 73.626c-1.679 0-3.049-1.27-3.049-2.825 0-.047.034-.08.085-.08.052 0 .086.032.086.08 0 1.476 1.285 2.666 2.878 2.666.051 0 .085.032.085.08 0 .047-.051.079-.085.079ZM127.961 73.626c-.051 0-.086-.032-.086-.08 0-.047.035-.079.086-.079 1.593 0 2.878-1.19 2.878-2.666 0-.047.034-.08.085-.08.052 0 .086.032.086.08 0 1.555-1.37 2.825-3.049 2.825Z"
    />
    <Path
      fill="#1C1C1C"
      d="M144.387 67.325c-.035 0-.069-.031-.086-.063l-2.295-8.934c-.017-.048.017-.08.068-.095.052-.016.086.016.103.063l2.278 8.934c.017.048-.017.08-.068.095ZM127.927 64.499c-.051 0-.085-.048-.085-.095l.976-6.522c0-.048.051-.08.103-.064a.07.07 0 0 1 .068.095l-.976 6.522c0 .032-.034.064-.086.064ZM136.474 56.438a4.665 4.665 0 0 1-3.152-1.206.073.073 0 0 1 0-.111.088.088 0 0 1 .12 0 4.429 4.429 0 0 0 3.015 1.158c1.147 0 2.209-.413 3.014-1.158a.088.088 0 0 1 .12 0 .073.073 0 0 1 0 .11c-.822.778-1.935 1.207-3.117 1.207Z"
    />
    <Path
      fill="#fff"
      d="M155.382 104.345c-.017 0-.051 0-.068-.016l-1.182-1.094a.073.073 0 0 1 0-.111.087.087 0 0 1 .12 0l1.182 1.094a.073.073 0 0 1 0 .111c-.018.016-.035.016-.052.016ZM154.92 104.772c-.018 0-.052 0-.069-.016l-1.181-1.094a.073.073 0 0 1 0-.111.087.087 0 0 1 .12 0l1.181 1.094a.073.073 0 0 1 0 .111c-.017.016-.034.016-.051.016ZM120.39 105.17c-.051 0-.085-.047-.085-.079l.137-1.538c0-.048.051-.08.085-.08.051 0 .086.048.086.08l-.137 1.538c0 .048-.034.079-.086.079ZM119.739 105.122c-.051 0-.085-.047-.085-.079l.137-1.538c0-.048.051-.08.085-.08.052 0 .086.048.086.08l-.137 1.538c0 .048-.034.079-.086.079Z"
    />
    <Path
      fill="#1C1C1C"
      d="M147.915 76.211h-.034c-.052-.016-.069-.063-.052-.095l.308-.698c.018-.048.069-.064.103-.048.052.016.069.064.052.096l-.309.698c0 .031-.034.047-.068.047ZM145.98 75.496h-.035c-.051-.015-.068-.063-.051-.095l.308-.698c.017-.047.069-.063.103-.047.051.015.068.063.051.095l-.308.698c0 .016-.034.047-.068.047ZM146.631 75.736h-.034c-.052-.016-.069-.064-.052-.096l.309-.698c.017-.047.068-.063.102-.047.052.016.069.063.052.095l-.308.698c0 .016-.035.048-.069.048ZM147.282 75.974h-.035c-.051-.016-.068-.063-.051-.095l.308-.698c.017-.048.069-.064.103-.048.051.016.068.064.051.095l-.308.698c0 .016-.034.048-.068.048ZM130.531 46.124v-.397c.102-1.079 1.027-1.682 2.175-1.444.771.159 1.524.429 2.329.492 1.096.08 2.056-.46 3.135-.46.839 0 1.627.301 2.278.778 1.267.952 2.038 2.554 1.952 4.062a4.99 4.99 0 0 1-1.233 2.951c-.531.603-1.199 1.095-2.055 1.254-1.764.333-3.614-.81-4.95-1.761a9.517 9.517 0 0 1-2.843-3.19c-.36-.682-.737-1.476-.788-2.285Z"
    />
    <Path
      fill="#fff"
      d="M136.285 53.805c2.308 0 4.179-1.734 4.179-3.872s-1.871-3.872-4.179-3.872c-2.308 0-4.179 1.734-4.179 3.872s1.871 3.872 4.179 3.872Z"
    />
    <Path
      fill="#fff"
      d="M140.431 51.82c.624 0 1.13-.47 1.13-1.048 0-.578-.506-1.047-1.13-1.047-.625 0-1.131.469-1.131 1.047s.506 1.047 1.131 1.047Z"
    />
    <Path
      fill="#1C1C1C"
      d="M132.877 48.98c.188.047.394.047.599-.016.702-.19 1.165-.825 1.867-1.016.771-.206 1.131.397 1.473.905a4.811 4.811 0 0 0 1.747 1.602c.891.46 1.833.159 1.953-.856.12-.937-.343-1.794-1.096-2.412-.874-.73-2.039-1.19-3.169-1.46-1.113-.27-2.158-.413-3.169.19-.77.46-1.096 1.142-1.079 1.984.017.523.377.952.874 1.079Z"
    />
    <Mask id="a" width={38} height={32} x={115} y={44}>
      <Path
        fill="#BDF0D5"
        d="M143.286 55.002c-.796-.272-1.716-.363-2.548-.439-.761-.075-1.663-.227-2.353.121-.778.394-1.408.24-2.334.042-.602-.015.639.016 0 0-.603-.145-1.54-.187-1.947-.429-.567-.318-2.424-.627-3.096-.642-.744-.03-1.487-.06-2.23-.136-.442-.045-.884-.09-1.327-.151-.406-.046-.866-.167-1.273-.167a22.42 22.42 0 0 1-2.849-.575c-.672-.181-1.397-.408-1.928-.832-1.787-1.423-2.336-5.782-2.442-6.902-.017-.136-.159-.242-.336-.227l.187-.075c-1.644-1.127-3.201 1.82-3.201 1.94-.035 1.453-.259 8.366 3.05 10.077 3.184 1.65 1.645 1.419 2.742 1.706 4.878 3.737 16.799 4.674 10.087 3.621l1.466-.778c-.018.151.973 7.966 1.15 7.966l10.385.562c.194 0 .354-.152.301-.303l-1.929-7.235c-.071-.257.319-.424.513-.212 1.132 1.166 3.096 3.376 3.202 4.677.124 1.695-1.45 7.19-1.68 7.946a.22.22 0 0 0 .017.182c.213.333 1.097 1.468 3.344.878.071-.015.124-.06.177-.121.584-.893 5.449-8.476 2.866-13.123-1.221-2.18-3.043-4.101-5.184-5.675-.672-.485-1.362-.954-2.105-1.363a4.389 4.389 0 0 0-.725-.333Z"
      />
    </Mask>
    <G mask="url(#a)">
      <Path
        fill="#22C365"
        d="m160.442 75.727-34.005-29.851v-2.42h-1.602s-15.474-1.52-21.168-1.924c.801 13.717 7.29 42.302 10.493 44.044 4.003 2.18 31.501 9.904 36.038 11.357 3.63 1.161 6.774-12.733 10.244-21.206Z"
      />
    </G>
    <Path
      fill="#FFBEBE"
      d="m98.36 56.447 44.001-36.795a3.154 3.154 0 0 1 4.442.396l3.905 4.67a3.153 3.153 0 0 1-.396 4.441L106.31 65.954l-7.95-9.507Z"
    />
    <Path
      fill="#E32121"
      d="M106.271 65.993 92.215 69.58l6.247-13.173c5.375 1.619 7.564 7.21 7.809 9.587Z"
    />
    <Path
      fill="#E32121"
      fillRule="evenodd"
      d="M137.709 23.55c5.313 1.654 7.663 7.121 7.907 9.485l-.004.001.031.037 4.837-4.045a3.152 3.152 0 0 0 .396-4.442l-3.905-4.67a3.152 3.152 0 0 0-4.441-.396l-4.821 4.03Z"
      clipRule="evenodd"
    />
    <Path
      fill="#fff"
      d="M110.535 44.734c-1.196-.087-2.001 1.255-2.254 1.938 1.406 2.029 3.651 2.01 4.015.876 0 0 .241-1.043-.246-1.441a48.166 48.166 0 0 1-.637-.541c.722.232 1.039.21 1.057-.182.017-.375-.74-.562-1.935-.65Z"
    />
  </>
);

export const UpgradeAccountIllustration = createIllustration({
  viewBox: '0 0 160 113',
  vectorComponent
});
