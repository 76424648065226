import React from 'react';
import { useTranslation } from 'react-i18next';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { RequestErrorMessage } from '@common/components/RequestErrorMessage';
import { UpdateAccountDetailsForm } from '@common/componentsV2/Account/forms/updateAccountDetails';
import REQUEST_KEY from '@common/constants/requestKeys';
import { useGetAppErrors } from '@common/hooks/appError';
import { useSiteCountryCode } from '@common/hooks/localisation';
import { useRedirectUnauthenticatedUser } from '@common/hooks/useRedirectUnauthenticatedUser';
import { getUser } from '@store/auth';
import { useAppSelector } from '@store/hooks';
import { Box } from '@components/Box';
import { CalloutText } from '@components/CalloutText';
import { Card, CardContent } from '@components/Card';
import { Heading } from '@components/Heading';
import { Stack } from '@components/Stack';
import { useAccountDetailsRedirect } from './hooks/useAccountDetailsRedirect';
import { useUpdateAccountDetails } from './hooks/useUpdateAccountDetails';

export const UpdateAccountDetails = () => {
  const requestKey = REQUEST_KEY.AUTH.UPDATE_ACCOUNT;
  const { t } = useTranslation();
  const countryCode = useSiteCountryCode();
  const { isAuthenticated, currentUser } = useAppSelector(getUser);
  const { onCancelRedirect } = useAccountDetailsRedirect();
  const { fetchDefaultValues, onSubmit } = useUpdateAccountDetails(requestKey);
  const appErrors = useGetAppErrors(requestKey);

  useRedirectUnauthenticatedUser({ to: `/${countryCode}/login` });

  if (!isAuthenticated) {
    return null;
  }

  return (
    <>
      <JoraEmployerHelmet title={t('updateAccountDetailsPage.title')} />

      <Stack tone="transparent" spacing="medium">
        <Box paddingTop="medium" paddingLeft="medium" tone="transparent">
          <Heading level="h1">{t('updateAccountDetailsPage.heading')}</Heading>
        </Box>

        <Card>
          <CardContent>
            <Stack spacing="medium">
              <CalloutText tone="info">
                {t('updateAccountDetailsPageForm.description')}
              </CalloutText>

              <UpdateAccountDetailsForm
                defaultValues={fetchDefaultValues(currentUser)}
                onSubmit={onSubmit}
                onCancel={onCancelRedirect}
              />

              {appErrors.stateError && !appErrors.submissionError && (
                <RequestErrorMessage requestKey={requestKey} />
              )}
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </>
  );
};
