export const fontFamily = 'Noto Sans';
export const fontFamilyWebFallback = 'sans-serif';
export const fontCssSrc =
  'https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300';

export const fontWeights = {
  light: '300',
  regular: '400',
  medium: '500',
  bold: '700'
} as const;

export const fontSizes = {
  xxsmall: 10,
  xsmall: 12,
  small: 14,
  medium: 16,
  large: 18,
  xlarge: 20,
  xxlarge: 24,
  xxxlarge: 32
} as const;

export const lineHeights = {
  xxsmall: 10,
  xsmall: 14,
  small: 16,
  medium: 24,
  large: 32,
  xlarge: 40,
  xxlarge: 48
} as const;

export const letterSpacing = {
  xxsmall: -0.02,
  xsmall: -0.15,
  small: -0.42,
  medium: -0.48,
  large: -0.72,
  xlarge: -0.96,
  xxlarge: -1.44
} as const;

export type FontWeight = keyof typeof fontWeights;
export type FontSize = keyof typeof fontSizes;
export type LineHeight = keyof typeof lineHeights;
export type LetterSpacing = keyof typeof letterSpacing;
