import { Linking } from 'react-native';

export async function openExternalURL(location) {
  const supported = await Linking.canOpenURL(location);

  if (supported) {
    await Linking.openURL(location);
  } else {
    throw Error(`Error: Unable to open external URL: ${location}`);
  }
}
