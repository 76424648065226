import React, { createElement } from 'react';
import { useRedirect } from '@common/hooks/useRedirect';
import { Box } from '@components/Box';
import { Link } from '@components/Link';

export const NavMenuItem = (props) => {
  const { redirect } = useRedirect();
  const { text, icon, link, selected, key } = props;

  const Icon =
    icon &&
    createElement(icon, {
      tone: selected ? 'success' : 'neutralLight',
      variant: 'outline',
      size: 'xlarge'
    });

  return (
    <Box
      alignItems="center"
      marginVertical={{
        mobile: 'xsmall',
        tablet: 'small',
        desktop: 'small'
      }}
      paddingHorizontal={{
        mobile: 'xxsmall',
        tablet: 'none',
        desktop: 'none'
      }}
      flexGrow={{
        mobile: 1,
        tablet: 0,
        desktop: 0
      }}
      flexBasis={{
        mobile: 1,
        tablet: 'auto',
        desktop: 'auto'
      }}
    >
      <Link
        testID={key}
        icon={Icon}
        size={{
          mobile: 'xxsmall',
          tablet: 'xsmall',
          desktop: 'xsmall'
        }}
        variant={selected ? 'success' : 'secondary'}
        weight="regular"
        align={{
          mobile: 'vertical',
          tablet: 'horizontal',
          desktop: 'horizontal'
        }}
        onPress={redirect(link)}
      >
        {text}
      </Link>
    </Box>
  );
};
