import React, { ReactNode, forwardRef } from 'react';
import { View, ViewProps } from 'react-native';
import { Column } from '@components/Column';
import { Breakpoint, Spacing } from '@theme/tokens';
import { Tone } from '@theme/types';

type CardContentProps = {
  children: ReactNode;
  paddingLeft?: Spacing | Record<Breakpoint, Spacing>;
  paddingBottom?: Spacing | Record<Breakpoint, Spacing>;
  tone?: Tone;
  testID?: ViewProps['testID'];
};

export const CardContent = forwardRef<View, CardContentProps>(
  (
    {
      children,
      paddingLeft,
      paddingBottom,
      testID,
      tone,
      ...restProps
    }: CardContentProps,
    ref
  ) => {
    return (
      <Column
        tone={tone}
        paddingTop="large"
        paddingBottom={paddingBottom || 'xlarge'}
        paddingLeft={
          paddingLeft || {
            mobile: 'medium',
            tablet: 'xlarge',
            desktop: 'xlarge'
          }
        }
        paddingRight={{
          mobile: 'medium',
          tablet: 'xlarge',
          desktop: 'xlarge'
        }}
        ref={ref}
        testID={testID}
        {...restProps}
      >
        <Column
          tone="transparent"
          paddingLeft={{
            mobile: 'xsmall',
            tablet: 'small',
            desktop: 'small'
          }}
          paddingRight={{
            mobile: 'xsmall',
            tablet: 'small',
            desktop: 'small'
          }}
        >
          {children}
        </Column>
      </Column>
    );
  }
);
