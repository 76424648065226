import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@components/Box';
import { Dropdown, Field } from '@components/FormElements';
import { Text } from '@components/Text';
import { useSalaryTypeSelector } from './hooks/useSalaryTypeSelector';

type SalaryByProps = {
  value: 'range' | 'exact';
  onChange: (string) => void;
};

export const SalaryTypeSelector = (props: SalaryByProps) => {
  const { t } = useTranslation();
  const { salaryByOptions } = useSalaryTypeSelector();
  const { onChange, value } = props;

  return (
    <Field
      label={
        <Box paddingBottom="xsmall">
          <Text size="small">
            {t('postJobForm.expectedSalary.label.showSalaryBy')}
          </Text>
        </Box>
      }
    >
      <Box>
        <Dropdown
          items={salaryByOptions}
          selectedItem={value}
          onChange={onChange}
          testID="job-form-salaryBy"
        />
      </Box>
    </Field>
  );
};
