export const LOAD_BUSINESS = 'jora-employer/entities/businesses/LOAD_BUSINESS';
export const LOAD_BUSINESS_SUCCESS =
  'jora-employer/entities/businesses/LOAD_BUSINESS_SUCCESS';
export const LOAD_BUSINESS_FAIL =
  'jora-employer/entities/businesses/LOAD_BUSINESS_FAIL';

export const LOAD_BUSINESSES =
  'jora-employer/entities/businesses/LOAD_BUSINESSES';
export const LOAD_BUSINESSES_SUCCESS =
  'jora-employer/entities/businesses/LOAD_BUSINESSES_SUCCESS';
export const LOAD_BUSINESSES_FAIL =
  'jora-employer/entities/businesses/LOAD_BUSINESSES_FAIL';

export const CREATE_BUSINESS =
  'jora-employer/entities/businesses/CREATE_BUSINESS';
export const CREATE_BUSINESS_SUCCESS =
  'jora-employer/entities/businesses/CREATE_BUSINESS_SUCCESS';
export const CREATE_BUSINESS_FAIL =
  'jora-employer/entities/businesses/CREATE_BUSINESS_FAIL';
export const CREATE_BUSINESS_FORM_NAME = 'jora-employer-create-business-form';

export const UPDATE_BUSINESS =
  'jora-employer/entities/businesses/UPDATE_BUSINESS';
export const UPDATE_BUSINESS_SUCCESS =
  'jora-employer/entities/businesses/UPDATE_BUSINESS_SUCCESS';
export const UPDATE_BUSINESS_FAIL =
  'jora-employer/entities/businesses/UPDATE_BUSINESS_FAIL';
