import React, { Fragment } from 'react';

type ElseProps = {
  children: React.ReactNode;
  condition?: boolean;
};

export const Else = ({ children, condition }: ElseProps) => {
  return <Fragment>{!condition && children}</Fragment>;
};
