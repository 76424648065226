import React from 'react';
import { Path } from 'svgs';
import { colors } from '@theme/tokens/colors';
import { createIcon } from '../createIcon';

const { green700 } = colors;

const vectorComponent = () => (
  <>
    <Path
      stroke={green700}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M9.993 11.813v4.5h-9.5v-8.5h3.5M5.493 6.313h5v2.5"
    />
    <Path
      stroke={green700}
      strokeLinecap="round"
      strokeMiterlimit={10}
      d="M8.993 9.313a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM11.993 8.813l3.5 3.5"
    />
    <Path
      stroke={green700}
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={0.75}
      d="M2.993 9.813h5M2.993 11.813h5M2.993 13.813h5"
    />
    <Path
      stroke={green700}
      strokeLinecap="round"
      strokeMiterlimit={10}
      d="M6.993 8.313h1.5"
    />
  </>
);

export const SearchDocumentIcon = createIcon({
  viewBox: '0 0 18 19',
  vectorComponent
});
