import { ReactNode, forwardRef } from 'react';
import React from 'react';
import { View, ViewStyle } from 'react-native';
import { Heading } from '@components/Heading';
import { Stack, StackProps } from '@components/Stack';
import { Text } from '@components/Text';
import {
  ProgressBarStyleProps,
  useProgressBarStyle
} from './useProgressBarStyle';

export type ProgressBarProps = {
  value: number;
  testID?: StackProps['testID'];
  label?: string;
  helperText?: string | ReactNode;
} & Omit<ProgressBarStyleProps, 'width'>;

export const ProgressBar = forwardRef<View, ProgressBarProps>((props, ref) => {
  const { label, helperText, testID, value, tone = 'brand' } = props;
  const { containerStyle, contentStyle } = useProgressBarStyle({
    width: `${value}%`,
    tone
  });

  return (
    <Stack testID={testID} ref={ref} spacing="small">
      {label ? <Heading level="h2">{label}</Heading> : null}

      <View style={containerStyle}>
        <View style={contentStyle as ViewStyle} />
      </View>

      {helperText ? (
        typeof helperText === 'string' ? (
          <Text size="small" weight="light">
            {helperText}
          </Text>
        ) : (
          helperText
        )
      ) : null}
    </Stack>
  );
});
