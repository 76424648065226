import React from 'react';
import { useTranslation } from 'react-i18next';
import { Application } from '@common/types/Application';
import { Modal } from '@components/Modal';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';

type TextScreeningAnswerModalProps = {
  application: Application;
  jobCompany: string;
  onClose: () => void;
  visible: boolean;
  testID?: string;
};

export const TextScreeningAnswerModal = ({
  application: { name, textScreeningAnswer },
  jobCompany,
  onClose,
  visible,
  testID
}: TextScreeningAnswerModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      testID={testID}
      title={t('applications.applicationCard.screeningAnswer.title', {
        name
      })}
      visible={visible}
      onRequestClose={onClose}
    >
      <Stack spacing="medium">
        <Text size="small" weight="medium">
          {t('applications.applicationCard.screeningAnswer.label', {
            businessName: jobCompany
          })}
        </Text>

        <Text size="small">{textScreeningAnswer}</Text>
      </Stack>
    </Modal>
  );
};
