import { useState } from 'react';
import { SingleSelectProps } from './SingleSelect';

type UseSingleSelectProps<T extends string | boolean | number> = Pick<
  SingleSelectProps<T>,
  'value' | 'onChange'
>;

export const useSingleSelect = <T extends string | boolean | number>({
  value,
  onChange
}: UseSingleSelectProps<T>) => {
  const [selectedKey, setSelectedKey] = useState<T | undefined>(value);

  const updateSelectedKey = (key: T) => {
    setSelectedKey(() => {
      onChange(key);
      return key;
    });
  };

  return {
    selectedKey,
    updateSelectedKey
  };
};
