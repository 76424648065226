import { useSiteCountryCode } from '@common/hooks/localisation';
import { getRegistrationEnabledStatus } from '@store/config';
import { useAppSelector } from '@store/hooks';

export const useRegistrationEnabled = () => {
  const countryCode = useSiteCountryCode();

  const isRegistrationEnabled = useAppSelector(
    getRegistrationEnabledStatus(countryCode)
  );

  return isRegistrationEnabled;
};
