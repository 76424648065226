import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

import * as colours from '../../theme/palette/colours';

const SkeletonLoader = ({
  baseColor = colours.grey200,
  highlightColor = colours.grey100,
  width = 150,
  height = 42,
  marginRight,
  margin,
  ...restProps
}) => {
  const skeletonStyle = {
    container: {
      ...(marginRight ? { marginRight: `${marginRight}px` } : {}),
      ...(margin ? { margin: `${margin}px` } : {})
    }
  };

  return (
    <div style={skeletonStyle.container}>
      <Skeleton
        baseColor={baseColor}
        highlightColor={highlightColor}
        width={width}
        height={height}
        {...restProps}
      />
    </div>
  );
};

SkeletonLoader.propTypes = {
  highlightColor: PropTypes.string,
  baseColor: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.number,
  marginRight: PropTypes.number,
  margin: PropTypes.number
};

export default SkeletonLoader;