export const SUGGESTION_FETCH = 'jora-employer/location/GOOGLE_SUGGESTION_FETCH';
export const SUGGESTION_FETCH_SUCCESS = 'jora-employer/location/GOOGLE_SUGGESTION_FETCH_SUCCESS';
export const SUGGESTION_FETCH_FAIL = 'jora-employer/location/GOOGLE_SUGGESTION_FETCH_FAIL';

export const LOCATION_DATA_FETCH = 'jora-employer/location/LOCATION_DATA_FETCH';
export const LOCATION_DATA_FETCH_SUCCESS = 'jora-employer/location/LOCATION_DATA_FETCH_SUCCESS';
export const LOCATION_DATA_FETCH_FAIL = 'jora-employer/location/LOCATION_DATA_FETCH_FAIL';
export const LOCATION_DATA_CLEAR = 'jora-employer/location/LOCATION_DATA_CLEAR';

export const LOCATION_DATA_VALIDATION_ERROR_TRACK = 'jora-employer/location/LOCATION_DATA_VALIDATION_ERROR_TRACK';

export const COUNTRIES_USE_LESS_GRANULAR_ADDRESS_AS_SUBURB = ['TH'];

export const GOOGLE_STATUS_OK = 'OK';
export const GOOGLE_ZERO_RESULTS = 'ZERO_RESULTS';
export const GOOGLE_API_NOT_FOUND = 'API_NOT_FOUND';

export const ADDRESS_TYPES_AUTO_SUGGEST_ALLOWED = ['route', 'street_address', 'establishment', 'premise', 'subpremise'];
export const ADDRESS_TYPES_STREET_NUMBER_REQUIRED = ['route'];
export const ADDRESS_TYPES_STREET_NAME_REQUIRED = ['street_address', 'route'];

export const ERRORS = {
  API_NOT_FOUND: 'validations.googleLocationAutoSuggest.apiNotFound',
  SUBURB_NOT_FOUND: 'validations.googleLocationAutoSuggest.suburbNotFound',
  STREET_NAME_NOT_FOUND: 'validations.googleLocationAutoSuggest.streetNameNotFound',
  REQUEST_FAILED: 'validations.googleLocationAutoSuggest.requestFailed',
  RESULT_NOT_FOUND: 'validations.googleLocationAutoSuggest.resultNotFound'
};