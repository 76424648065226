import React, { useEffect, useState } from 'react';
import { useFetchUiVersion } from '@common/hooks/system';

/**
  This component is used to reload the app when the version of the app changes.
  It uses the useFetchUiVersion hook to get the version of the app.
  If the version of the app changes, the window is reloaded.
  This stops stale client-side code from hanging around after a deployment
  for both web and native apps.
*/
export const CodeReloader = () => {
  const [mountedVersion, setMountedVersion] = useState<number | null>(null);
  const { uiVersion } = useFetchUiVersion();
  // Runs only once when the component is mounted
  useEffect(() => {
    if (!uiVersion) return;

    if (!mountedVersion) {
      setMountedVersion(uiVersion);
    } else if (uiVersion !== mountedVersion) {
      window.location.reload();
    }
  }, [uiVersion, mountedVersion]);
  return <></>;
};
