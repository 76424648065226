import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <>
    <Path
      d="M 10.264 1 C 9.703 0.9590000000000001 9.391 1.146 9.015 1.521 C 8.64 1.897 8.453 2.213 8.494 2.774 L 8.494 3.692 L 8.494 4.692 L 2.88 4.692 C 2.319 4.651 2.007 4.838 1.631 5.213 C 1.256 5.589 1.069 5.905 1.11 6.466 L 1.11 21.23 C 1.069 21.791 1.256 22.103 1.631 22.479 C 2.007 22.854 2.323 23.041 2.884 23 L 23.187 23 C 23.748 23.041 24.06 22.854 24.436 22.479 C 24.811 22.103 24.998 21.787 24.957 21.226 L 24.957 6.463 C 24.998 5.902 24.811 5.59 24.436 5.214 C 24.06 4.839 23.744 4.652 23.183 4.693 L 18.572 4.693 L 17.572 4.693 L 17.572 2.77 C 17.613 2.209 17.426 1.897 17.051 1.521 C 16.675 1.146 16.359 0.9590000000000001 15.798 1 L 10.264 1 Z"
      stroke-width="2"
    />
    <Path
      d="M 25.11 21.231 C 25.068 21.691 24.845 22.3 24.55 22.594 C 24.256 22.889 23.647 23.112 23.187 23.154 L 2.894 23.154 C 2.4290000000000003 23.115 1.812 22.89 1.516 22.595 C 1.221 22.3 0.995 21.696 0.9560000000000001 21.231 L 0.9560000000000001 15.615 L 25.11 15.615 L 25.11 21.231 Z"
      stroke-width="2"
    />
    <Path
      d="M 13.88 12.077 L 13.88 11.923 L 12.187 11.923 L 12.187 12.077 L 13.88 12.077 Z"
      stroke-width="2"
    />
    <Path
      d="M 23.187 4.538 C 23.647 4.58 24.256 4.803 24.551 5.098 C 24.846 5.393 25.068 6.002 25.11 6.462 L 25.11 15.769 L 0.9560000000000001 15.769 L 0.9560000000000001 6.462 C 0.998 6.002 1.221 5.393 1.516 5.098 C 1.811 4.803 2.42 4.58 2.88 4.538 L 23.187 4.538 Z"
      stroke-width="2"
    />
    <Path
      d="M 15.803 0.8460000000000001 C 16.263 0.888 16.872 1.111 17.166 1.4060000000000001 C 17.461 1.7 17.684 2.309 17.726 2.769 L 17.726 4.692 L 8.34 4.692 L 8.34 2.77 C 8.382 2.31 8.605 1.701 8.9 1.407 C 9.194 1.112 9.803 0.889 10.263 0.8470000000000001 L 9.807 0.889 L 9.85 0.8460000000000001 L 15.803 0.8460000000000001 Z"
      stroke-width="2"
    />
  </>
);

export const SuitcaseIcon = createIcon({
  viewBox: '0 0 27 24',
  vectorComponent
});
