import REQUEST_KEY from '@common/constants/requestKeys';
import { ClientError, ClientErrorProperties } from '@common/helpers/errors';
import { useHandleAppLoading } from '@common/hooks/appLoading';
import { extractTrpcErrorProperties } from '@src/trpc/lib';
import { trpcBff } from '@trpcBff';

const userPackageStaleTime = 10000;

type GetUserPackage = {
  packageType?: 'free' | 'paid';
  expiryDate?: Date | null;
  error?: {
    message: string;
    properties: ClientErrorProperties;
  };
};

export const useGetUserPackage = (): GetUserPackage => {
  const { data, isLoading, error } =
    trpcBff.userPackages.userPackage.get.useQuery(undefined, {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: userPackageStaleTime
    });

  // Show global loader while loading
  useHandleAppLoading(isLoading, REQUEST_KEY.ORGANISATION.USER_PACKAGE.GET);

  const clientError = getClientError(extractTrpcErrorProperties(error));

  return {
    packageType: data?.packageType,
    expiryDate: data?.expiryDate,
    error: clientError && {
      message: clientError.message,
      properties: clientError.properties
    }
  };
};

const getClientError = (errorProperties) => {
  if (!errorProperties) return;

  return new ClientError('errors.defaultWithGuid', {
    guid: errorProperties.guid,
    responseBody: errorProperties.responseBody
  });
};
