import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import configureStore from './configureStore';
import { getStoreConfig } from './storeConfig';

const { initialState, initialHistory, fetch, middleware } = getStoreConfig();

const { store, history } = configureStore(
  initialState,
  initialHistory,
  fetch,
  middleware
);

export { store, history };

type Fetch = typeof fetch;
type InterceptorConfig = {
  showGlobalSpinner?: boolean;
  shouldLoading?: boolean;
  requestKey?: string;
  defaultError?: string;
  mapErrorContext?: { [fieldName: string]: [appFieldName: string] };
  mapFormError?: { [errorKey: string]: [translationKey: string] };
  onFetchError?: (error: Error) => void;
  onResponseError?: (
    body: Response,
    dispatch: AppDispatch,
    getState: GetState
  ) => void;
};

type FetchInterceptor<Data> = (
  ...args: [...Parameters<Fetch>, InterceptorConfig | undefined]
) => Data;

export type GroupedActions<Data = any> = {
  payload?: unknown;
  types: string[];
  promise: (
    dispatch: AppDispatch,
    getState: GetState,
    fetch: FetchInterceptor<Data>
  ) => Promise<any>;
};

export type AppThunkAction = ThunkAction<void, AppState, unknown, AnyAction>;
export type AppState = ReturnType<GetState>;
export type AppDispatch = ThunkDispatch<AppState, unknown, AnyAction> &
  ((actions: GroupedActions) => Promise<any>);
export type GetState = typeof store.getState;
