import React, { useContext } from 'react';
import { ExpectedSalary as InnerField } from '@common/components/PostJobForm/components/ExpectedSalary';
import type { SalaryWithOptionalAmount } from '@common/components/PostJobForm/components/ExpectedSalary/hooks/useExpectedSalary';
import { SupportedCurrencies } from '@seek/je-shared-data/lib/types/brand';
import { SalaryRate } from '@seek/je-shared-data/lib/types/jobs';
import { FieldWrapper } from '../FieldWrapper';
import { FormContext } from '../FormContext';
import { expectedSalaryRules } from '../rules';

export default function ExpectedSalary() {
  const { t } = useContext(FormContext);
  return (
    <FieldWrapper<SalaryWithOptionalAmount>
      name="salary"
      rules={expectedSalaryRules(t)}
      render={(props) => (
        <InnerField {...{ ...props, value: ensureValue(props.value) }} />
      )}
    />
  );
}

function ensureValue(
  value: SalaryWithOptionalAmount | undefined
): SalaryWithOptionalAmount {
  return (
    value || {
      type: 'exact',
      amount: undefined,
      currency: SupportedCurrencies.AUD, // TODO get from country
      rate: SalaryRate.MONTHLY
    }
  );
}
