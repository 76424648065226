import { useActiveBusinesses } from '@common/hooks/business';
import { useBusinessLimit } from '../useBusinessLimit';

export const atBusinessLimit = (
  businessCount: number,
  businessLimit: number
): boolean => businessCount >= businessLimit;

export const useAtBusinessLimit = () => {
  const businessCount = useActiveBusinesses().length;
  const businessLimit = useBusinessLimit();
  if (!businessLimit) return false;

  return atBusinessLimit(businessCount, businessLimit);
};
