import * as validation from '@common/constants/validation';

export const validateFields = (
  fields: string[],
  data: Record<string, string | Record<string, string>>
) => {
  const errors: Record<string, string> = {};

  fields.forEach((key) => {
    const fieldValue = data[key];
    const isTextFieldEmpty =
      typeof fieldValue === 'string' ? !fieldValue.trim() : false;

    if (!fieldValue || isTextFieldEmpty) {
      errors[key] = validation.REQUIRED_VALIDATION_ERROR;
    }
  });

  return errors;
};
