import { EventTypes } from 'redux-segment';
import {
  SET_PLATFORM,
  TRACK_BROWSER_DETAILS,
  TRACK_CANCEL_PUSH_NOTIFICATIONS,
  TRACK_DEVICE_DETAILS,
  TRACK_ENABLE_PUSH_NOTIFICATIONS
} from './constants';

export function setPlatform({ userAgent, device }) {
  return (dispatch) => {
    dispatch({
      type: SET_PLATFORM,
      payload: {
        userAgent,
        device
      }
    });
  };
}

export function trackBrowserDetails() {
  return {
    type: TRACK_BROWSER_DETAILS,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'Browser details logged',
          properties: {
            viewportWidth: window.innerWidth,
            viewportHeight: window.innerHeight,
            nonInteraction: 1
          }
        }
      }
    }
  };
}

export function trackDeviceDetails() {
  return {
    type: TRACK_DEVICE_DETAILS,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'Device details logged',
          properties: {
            viewportWidth: window.innerWidth,
            viewportHeight: window.innerHeight,
            nonInteraction: 1
          }
        }
      }
    }
  };
}

export function trackEnablePushNotifications() {
  return {
    type: TRACK_ENABLE_PUSH_NOTIFICATIONS,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'User Enable Push Notifications'
        }
      }
    }
  };
}

export function trackCancelPushNotifications() {
  return {
    type: TRACK_CANCEL_PUSH_NOTIFICATIONS,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'User Cancel Push Notifications'
        }
      }
    }
  };
}
