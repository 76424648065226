import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextWithIcon } from '@common/components/TextWithIcon';
import { AD_TYPE } from '@seek/je-shared-data';
import { UpgradeableAdType } from '@src/job/adType';
import { Box } from '@components/Box';
import { JoraCoinIcon } from '@components/Icon';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { AdTypeTextWithIcon } from './AdTypeTextWithIcon';

type Props = {
  adType: UpgradeableAdType;
  coinPrice: number;
  currencyPrice?: string;
};

export const PricingPanel = (props: Props) => {
  const { t } = useTranslation();
  const { coinPrice, currencyPrice, adType } = props;

  if (![AD_TYPE.PLUS, AD_TYPE.MAX].includes(adType)) {
    return null;
  }

  return (
    <Box
      testID="pricing-panel"
      borderWidth="xsmall"
      width="full"
      paddingVertical="medium"
      paddingHorizontal="large"
    >
      <Row justifyContent="space-between" alignItems="center">
        <AdTypeTextWithIcon adType={adType} />

        <Stack alignItems={'center'}>
          <TextWithIcon
            icon={<JoraCoinIcon size="xlarge" testID="jora-coin-icon" />}
            spacing="xsmall"
          >
            <Text size="xlarge" weight="regular">
              {coinPrice}
            </Text>
          </TextWithIcon>
          {currencyPrice ? (
            <Text size="xsmall" tone="neutralLight">
              {t('paymentPage.worth', { price: currencyPrice })}
            </Text>
          ) : null}
        </Stack>
      </Row>
    </Box>
  );
};
