import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextWithIcon } from '@common/components/TextWithIcon';
import { EarthIcon } from '@components/Icon';
import { Text } from '@components/Text';

type CitizenshipInfoProps = {
  citizenship?: string;
};

export const CitizenshipInfo = ({ citizenship }: CitizenshipInfoProps) => {
  const { t } = useTranslation();

  const earthIcon = (
    <EarthIcon
      size="small"
      tone="success"
      variant="outline"
      testID="earth-icon"
    />
  );

  return (
    <>
      <TextWithIcon icon={earthIcon}>
        <Text>
          {t('applications.applicationCard.aboutMe.citizenship', {
            citizenship
          })}
        </Text>
      </TextWithIcon>
    </>
  );
};
