import { useWindowDimensions } from 'react-native';
import { useTheme } from '@theme/DesignSystemProvider/ThemeContext';

export type ModalStyleProps = {
  height?: 'standard' | 'fit-content';
  hasFooter?: boolean;
};

export function useModalStyle({
  height = 'standard',
  hasFooter
}: ModalStyleProps) {
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();
  const { modal } = useTheme();
  const headerFooterHeight = hasFooter ? 285 : 205;

  return {
    overlayStyle: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: modal.overlay.color,
      cursor: 'default'
    },
    contentStyle: {
      width: windowWidth - 36,
      maxWidth: 714,
      minWidth: 320,
      cursor: 'default'
    },
    scrollableContentStyle: {
      maxHeight:
        height === 'standard'
          ? windowHeight - 300
          : windowHeight - headerFooterHeight
    },
    headerStyle: {
      backgroundColor: modal.header.color
    }
  } as const;
}
