import { useEffect } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-native';
import { isServer } from '@common/helpers/detection';
import { pathUserHasLimitedAccessTo } from '@common/helpers/userAccessPathMapper';
import { useDraftData } from '@common/hooks/draft/useDraftData';
import { getUser } from '@store/auth';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';

export function useRedirectUserWithLimitedAccess() {
  const {
    currentUser: { emailVerified, appealStatus, emailLastVerifiedAt },
    isAuthenticated
  } = useAppSelector(getUser);
  const { countryCode } = useAppSelector(getLocalisation);
  const { pathname: currentPath } = useLocation();
  const history = useHistory();
  const { hasSignUpDraftData } = useDraftData();

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    const path = pathUserHasLimitedAccessTo({
      countryCode,
      userDetails: { emailVerified, appealStatus, emailLastVerifiedAt },
      hasDraftData: hasSignUpDraftData
    });

    if (path) {
      const match = matchPath(currentPath, path);

      // On SSR, the router.location.pathname is "/" regardless of actual path, so cannot be trusted for matching.
      // if page needs to redirect, it will still redirect correctly once user actually lands on frontend in browser
      const isUserNotOnAllowedPath = !(match && match.isExact) && !isServer();

      if (isUserNotOnAllowedPath) {
        history.push(path);
      }
    }
  }, [
    appealStatus,
    countryCode,
    currentPath,
    emailVerified,
    history,
    isAuthenticated,
    hasSignUpDraftData,
    emailLastVerifiedAt
  ]);
}
