import { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Text } from '@components/Text';
import { CalloutText } from '@components/CalloutText';
import Div from '../../../../../../../common/components/Div/Div';
import IconShowCase from '../../../../../../../common/components/IconShowCase/IconShowCase';
import IconWithActionText from '../../../../../../../common/components/IconWithActionText/IconWithActionText';
import CopyIcon from '../../../../../../../common/components/Icons/CopyIcon';
import FacebookIcon from '../../../../../../../common/components/Icons/FacebookIcon';
import LinkedinIcon from '../../../../../../../common/components/Icons/LinkedinIcon';
import ShareIcon from '../../../../../../../common/components/Icons/ShareIcon';
import WhatsAppIcon from '../../../../../../../common/components/Icons/WhatsAppIcon';
import TakeOverModal from '../../../../../../../common/components/TakeOverModal/TakeOverModal';
import {
  SOCIAL_MEDIA_PLATFORM,
  handleSocialLinkClick
} from './socialMedia';

@withTranslation()
export default class ShareAdActionLink extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    job: PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      liveJobLink: PropTypes.string.isRequired
    }),
    brandName: PropTypes.string.isRequired,
    handleSocialShareAdIconClicked: PropTypes.func.isRequired,
    handleSocialIconClicked: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpenSocialShareModal: false,
      showCalloutOnSocialShareModal: false,
      isSocialShareCopyError: false
    };
  }

  async openSocialShareModal() {
    const {
      job: { id },
      handleSocialShareAdIconClicked
    } = this.props;
    this.setState({
      isOpenSocialShareModal: true
    });

    await handleSocialShareAdIconClicked({ id });
  }

  closeSocialShareModal() {
    this.setState({
      isOpenSocialShareModal: false,
      showCalloutOnSocialShareModal: false,
      isSocialShareCopyError: false
    });
  }

  fallbackCopyTextToClipboard(textUrl) {
    let result;
    const input = document.createElement('textarea');
    input.innerHTML = textUrl;
    document.body.appendChild(input);
    input.select();

    try {
      result = document.execCommand('copy');
      if (result) {
        this.setState({
          showCalloutOnSocialShareModal: true,
          isSocialShareCopyError: false
        });
      } else {
        this.setState({
          showCalloutOnSocialShareModal: true,
          isSocialShareCopyError: true
        });
      }
    } catch (err) {
      this.setState({
        showCalloutOnSocialShareModal: true,
        isSocialShareCopyError: true
      });
      console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(input);
    return result;
  }

  async copyJobUrlToClipBoard(textUrl) {
    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(textUrl);
      return;
    }

    try {
      const toCopy = textUrl || location.href;
      await navigator.clipboard.writeText(toCopy);
      this.setState({
        showCalloutOnSocialShareModal: true,
        isSocialShareCopyError: false
      });
    } catch (err) {
      this.setState({
        showCalloutOnSocialShareModal: true,
        isSocialShareCopyError: true
      });
      console.error('Failed to copy: ', err);
    }

    return;
  }

  render() {
    const {
      isOpenSocialShareModal,
      showCalloutOnSocialShareModal,
      isSocialShareCopyError
    } = this.state;
    const {
      job: { id, title },
      t
    } = this.props;
    const themeType = isSocialShareCopyError ? 'error' : 'success';
    const message = isSocialShareCopyError
      ? `${t('dashboardPage.shareAd.copyLinkError')}`
      : `${t('dashboardPage.shareAd.copiedSuccessfully')}`;

    const titleComponent = (text) => <Text>{text}</Text>;

    const socialIconsList = [
      {
        icon: <FacebookIcon width={36} height={36} />,
        title: titleComponent(t('dashboardPage.shareAd.facebook')),
        onClick: handleSocialLinkClick.bind(this),
        key: SOCIAL_MEDIA_PLATFORM.FACEBOOK
      },
      {
        icon: <LinkedinIcon width={36} height={36} />,
        title: titleComponent(t('dashboardPage.shareAd.linkedin')),
        onClick: handleSocialLinkClick.bind(this),
        key: SOCIAL_MEDIA_PLATFORM.LINKEDIN
      },
      {
        icon: <CopyIcon width={26} height={36} />,
        title: titleComponent(t('dashboardPage.shareAd.copyLink')),
        onClick: handleSocialLinkClick.bind(this),
        key: 'copy'
      },
      {
        icon: <WhatsAppIcon width={36} height={36} />,
        title: titleComponent(t('applications.chatModal.action.whatsApp')),
        onClick: handleSocialLinkClick.bind(this),
        key: SOCIAL_MEDIA_PLATFORM.WHATSAPP
      }
    ];

    return (
      <Div marginBottom="medium">
        <TakeOverModal
          title={t('dashboardPage.shareAd.title', { jobTitle: title })}
          modalOpen={isOpenSocialShareModal}
          close={this.closeSocialShareModal.bind(this)}
          data-test-key={`social-share-modal-${id}`}
        >
          {showCalloutOnSocialShareModal ? (
            <CalloutText
              tone={themeType}
              testID={`copy-callout-text-${id}`}
            >
              {message}
            </CalloutText>
          ) : null}
          <IconShowCase
            marginTop="medium"
            dataSet={socialIconsList}
            data-test-key={`social-share-icons-${id}`}
            maxRowItems={3}
          />
        </TakeOverModal>
        <IconWithActionText
          stackForSmallScreen
          icon={ShareIcon}
          text={t('dashboardPage.action.shareAd')}
          data-test-key={`social-share-${id}-link-key`}
          onClick={this.openSocialShareModal.bind(this)}
          removeRightSpacing
        />
      </Div>
    );
  }
}
