import { isWebClient } from '../../common/helpers/detection';

export function tagSessionsWithId(id) {
  try {
    if (isWebClient() && window.hj) {
      window.hj('tagRecording', [id]);
    }
  } catch (_) { /* never mind */ }
}

export function tagSession(tag) {
  try {
    if (isWebClient()) {
      if (window.hj) {
        window.hj('tagRecording', [tag]);
      }
    }
  } catch (_) { /* never mind */ }
}

let isTagged = false;
export function tagHotjarRecording(brand) {
  if (isWebClient() && !isTagged) {
    window.analytics.ready(function () {
      window.hj('tagRecording', [brand]);
      isTagged = true;
    });
  }
}