import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <Path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M 17.069 16 H 9 A 0.8 0.8 0 0 1 8.366 14.634 L 12.434 10.566 A 0.8 0.8 0 0 1 13.566 10.566 L 17.634 14.634 A 0.8 0.8 0 0 1 17.07 16"
  />
);

export const DropdownUpIcon = createIcon({
  viewBox: '7 4 18 18',
  vectorComponent
});
