import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation/selectors';

export function useScoreCard() {
  const { t } = useTranslation();
  const brandNameText = useAppSelector(getLocalisation).brandNameText;

  return {
    heading: t('nps.howLikelyRecommend', {
      brandNameText
    }),
    minTextTip: t('nps.extremelyUnlikely'),
    maxTextTip: t('nps.extremelyLikely')
  };
}
