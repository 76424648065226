import { grey600 } from '../../theme/palette/colours';
import Svg, { Path } from 'svgs';

function FactoryIcon(props) {
  return (
    <Svg viewBox="3 3 18 18" fill="none" {...props}>
      <Path
        d="M13.496 19.5H3.5V7.462L8.49 4.5l5.006 2.962V19.5z"
        stroke={grey600}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M13.49 11.5h7l.01 8h-8.01M15.49 10.872V9.5h2v1.372"
        stroke={grey600}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M6.302 9.5H5.178a.193.193 0 01-.188-.193V8.192c0-.104.086-.192.188-.192h1.124c.102 0 .188.088.188.192v1.115a.188.188 0 01-.188.193zM8.8 9.5H7.68a.194.194 0 01-.19-.193V8.192c0-.104.087-.192.19-.192H8.8c.103 0 .19.088.19.192v1.115c.007.105-.08.193-.19.193zM11.302 9.5h-1.123a.193.193 0 01-.189-.193V8.192c0-.104.086-.192.188-.192h1.123c.103 0 .189.088.189.192v1.115a.193.193 0 01-.188.193zM6.302 12H5.178a.193.193 0 01-.188-.193v-1.114c0-.105.086-.193.188-.193h1.124c.102 0 .188.088.188.193v1.114a.188.188 0 01-.188.193zM8.8 12H7.68a.194.194 0 01-.19-.193v-1.114c0-.105.087-.193.19-.193H8.8c.103 0 .19.088.19.193v1.114c.007.105-.08.193-.19.193zM11.302 12h-1.123a.193.193 0 01-.189-.193v-1.114c0-.105.086-.193.188-.193h1.123c.103 0 .189.088.189.193v1.114a.193.193 0 01-.188.193zM6.302 14.5H5.178a.193.193 0 01-.188-.193v-1.114c0-.105.086-.193.188-.193h1.124c.102 0 .188.088.188.193v1.114a.188.188 0 01-.188.193zM8.8 14.5H7.68a.194.194 0 01-.19-.193v-1.114c0-.105.087-.193.19-.193H8.8c.103 0 .19.088.19.193v1.114c.007.105-.08.193-.19.193zM11.302 14.5h-1.123a.193.193 0 01-.189-.193v-1.114c0-.105.086-.193.188-.193h1.123c.103 0 .189.088.189.193v1.114a.193.193 0 01-.188.193zM16.302 14.5h-1.123a.193.193 0 01-.189-.193v-1.114c0-.105.086-.193.188-.193h1.123c.103 0 .189.088.189.193v1.114a.193.193 0 01-.188.193zM16.302 17h-1.123a.193.193 0 01-.189-.192v-1.115c0-.105.086-.193.188-.193h1.123c.103 0 .189.088.189.193v1.115a.193.193 0 01-.188.192zM18.802 14.5h-1.123a.193.193 0 01-.189-.193v-1.114c0-.105.086-.193.189-.193h1.123c.102 0 .188.088.188.193v1.114a.193.193 0 01-.188.193z"
        fill={grey600}
      />
      <Path
        d="M6.686 19.053V15.83h3.503v3.223"
        stroke={grey600}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M8.437 15.83v3.223"
        stroke={grey600}
        strokeWidth={0.75}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M17.944 7.54c-.126.408-.506.71-.956.71a.999.999 0 01-.295-1.953M16.7 6.29a1.666 1.666 0 111.251 1.25"
        stroke={grey600}
        strokeWidth={0.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default FactoryIcon;
