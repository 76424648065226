import {
  /*useMemo,*/
  useState
} from 'react';
import {
  getApplications,
  getTotalApplicationCount,
  trackApplicationListTabClicked
} from '@store/entities/applications';
import { useAppDispatch, useAppSelector } from '@store/hooks';

export function useJobApplicationDetails(jobId: string) {
  const dispatch = useAppDispatch();
  const [selectedApplicationsTab, setSelectedApplicationsTab] = useState<
    'matched' | 'other'
  >('matched');

  const { isApplicationDataAvailable } = useApplicationAvailability(jobId);
  const { hasApplications } = useHasApplications(jobId);

  return {
    shouldShowApplicationFilterBar: hasApplications,
    shouldShowApplicationStats: isApplicationDataAvailable,
    handleOnTabPress: (tabIndex) => {
      const tabSelected = tabIndex === 0 ? 'matched' : 'other';

      dispatch(
        trackApplicationListTabClicked({
          jobId,
          tab: tabSelected
        })
      );

      setSelectedApplicationsTab(tabSelected);
    },
    selectedApplicationsTab
  };
}

const useApplicationAvailability = (jobId: string) => {
  const { applicationsAvailability /*data: applications*/ } =
    useAppSelector(getApplications(jobId)) || {};

  const isApplicationDataAvailable = applicationsAvailability?.available;

  return { isApplicationDataAvailable };
};

const useHasApplications = (jobId: string) => {
  const { isApplicationDataAvailable } = useApplicationAvailability(jobId);
  const totalApplicationCount =
    useAppSelector(getTotalApplicationCount(jobId)) || 0;

  return {
    hasApplications: !!(
      isApplicationDataAvailable &&
      totalApplicationCount &&
      totalApplicationCount > 0
    )
  };
};
