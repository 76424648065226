import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <Path
    d="M18.5 12.889V19.5c0 .759-.073 1-.833 1H4.444c-.769 0-.944-.186-.944-.945V6.5c0-.759.175-1 .944-1h6.612M12 12l8.5-8.5M20.5 3.5h-5.667M20.5 9.167V3.5"
    strokeMiterlimit={10}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
);

export const ExternalLinkIcon = createIcon({
  viewBox: '3 3 18 18',
  vectorComponent
});
