import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <>
    <Path
      d="M15.417 8.03c0 2.608-5.417 9.053-5.417 9.053S4.583 10.638 4.583 8.03c0-2.608 2.594-5.113 5.417-5.113s5.417 2.505 5.417 5.113Z"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path d="M10 10.556c1.25 0 2.5-1.181 2.5-2.362 0-1.18-1.25-2.36-2.5-2.36s-2.5 1.18-2.5 2.36c0 1.181 1.25 2.362 2.5 2.362Z" />
  </>
);

export const LocationIcon = createIcon({
  viewBox: '0 0 20 20',
  vectorComponent
});
