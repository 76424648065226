import React from 'react';
import { useTranslation } from 'react-i18next';
import { UpgradeAccountModalWithButton } from '@common/components/Account/components';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { useGetHomeLink } from '@common/hooks/appPageLinks';
import { useRedirect } from '@common/hooks/useRedirect';
import { Box } from '@components/Box';
import { Card, CardContent } from '@components/Card';
import { Column } from '@components/Column';
import { Heading } from '@components/Heading';
import { JobLimitReachedIllustration } from '@components/Illustration';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { TextLink } from '@components/TextLink';

type JobLimitReachedProps = {
  pageTitle: string;
};

export const JobLimitReached = ({ pageTitle }: JobLimitReachedProps) => {
  const { t } = useTranslation();
  const { redirect } = useRedirect();
  const dashboardPath = useGetHomeLink();

  return (
    <Stack spacing="medium" tone="transparent">
      <JoraEmployerHelmet title={t('jobLimitReachedPage.title')} />

      <Box paddingLeft="medium" tone="transparent">
        <Heading level="h1">{t('jobLimitReachedPage.title')}</Heading>
      </Box>

      <Card>
        <CardContent>
          <Column alignItems="center" spacing="large">
            <JobLimitReachedIllustration size="medium" />

            <Box
              justifyContent="center"
              width={{ desktop: 'large', tablet: 'large', mobile: 'full' }}
            >
              <Text weight="regular" align="center">
                {t('jobLimitReachedPage.heading')}
              </Text>
            </Box>

            <Text align="center">{t('jobLimitReachedPage.description')}</Text>

            <UpgradeAccountModalWithButton
              pageTitle={pageTitle}
              buttonText={t('jobLimitReachedPage.cta')}
            />

            <TextLink onPress={redirect(dashboardPath)}>
              {t('jobLimitReachedPage.secondaryCta')}
            </TextLink>
          </Column>
        </CardContent>
      </Card>
    </Stack>
  );
};
