import { openExternalURL } from '@common/helpers/externalConnection';
import { LINK, useCandidateLink } from '@common/hooks/useCandidateLink';

export function useUpgradeJobCalloutText() {
  const { getCandidateLink } = useCandidateLink();

  return {
    redirectToCandiFaqPage: () =>
      openExternalURL(getCandidateLink(LINK.FAQ_EMPLOYER_PAGE))
  };
}
