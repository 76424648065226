import React, { useEffect, useRef } from 'react';
import ReactPaginate from 'react-paginate';
import { When } from '@common/components/When';
import { paginateItems, scrollToTopOfList } from '@src/joraEmployer/paging';

interface Identifiable {
  id: string;
}

type Scrollable = {
  getBoundingClientRect(): {
    top: number;
  };
  scrollTop: number;
  scrollTo: (options: { top: number; behavior: 'smooth' }) => void;
};

export interface PagingMenuProps<T extends Identifiable> {
  items: T[];
  perPage?: number;
  scrollToOnPageChange?: Scrollable | null;
  handlePaginatedItems?: (items: T[]) => void;
}
export const PagingMenu = <T extends Identifiable>({
  items,
  perPage = 50,
  scrollToOnPageChange,
  handlePaginatedItems
}: PagingMenuProps<T>) => {
  const [currentPage, setCurrentPage] = React.useState(0);

  const prevItemIdsRef = useRef<string[]>(items.map((item) => item.id));
  const handlePaginatedItemsRef = useRef(handlePaginatedItems);

  useEffect(() => {
    console.log('PagingMenu useEffect');
    handlePaginatedItemsRef.current?.(
      paginateItems({ items, perPage, currentPage: 0 })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newItemIds = items.map((item) => item.id);
    if (itemsHaveChanged(prevItemIdsRef.current, newItemIds)) {
      handlePaginatedItemsRef.current?.(
        paginateItems({ items, perPage, currentPage: 0 })
      );
      setCurrentPage(0);
      prevItemIdsRef.current = newItemIds;
    }
  }, [items, perPage]);

  function itemsHaveChanged(old: string[], current: string[]) {
    return (
      old.length !== current.length || old.some((id, i) => id !== current[i])
    );
  }

  function onPageChange({ selected }: { selected: number }) {
    if (scrollToOnPageChange) {
      const scroller = document.getElementById('main-scroller');
      scrollToTopOfList({ topOfList: scrollToOnPageChange, scroller });
    }
    handlePaginatedItems?.(
      paginateItems({ items, perPage, currentPage: selected })
    );
  }

  return (
    <When condition={items.length > perPage}>
      <div className="paging-menu">
        <ReactPaginate
          previousLabel={'<'}
          nextLabel={'>'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          forcePage={currentPage}
          pageCount={Math.ceil(items.length / perPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={onPageChange}
          containerClassName={'pagination'}
          activeClassName={'active'}
        />
      </div>
    </When>
  );
};
