import React from 'react';
import { Button } from '@components/Button';

interface SelectUpgradeButtonProps {
  children: string;
  onButtonPress: () => void;
}
export const SelectUpgradeButton = ({
  children,
  onButtonPress
}: SelectUpgradeButtonProps) => {
  return (
    <Button fullWidth={true} variant="primary" onPress={onButtonPress}>
      {children}
    </Button>
  );
};
