import { SpecificShiftAvailabilityType } from '@common/components/PostJobForm/components/ShiftAvailability/types';
import {
  AVAILABILITY,
  DAY,
  ShiftAvailability
} from '@seek/je-shared-data/lib/types/jobs';

const { MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY } = DAY;
const { MORNING, AFTERNOON, EVENING } = AVAILABILITY;

const allTimeAvailability = [MORNING, AFTERNOON, EVENING];

const mapShiftAvailabilityToShifts = (
  shiftAvailability: ShiftAvailability
): SpecificShiftAvailabilityType => {
  const { type, specificShiftAvailability } = shiftAvailability;
  if (type === 'SPECIFIC' && specificShiftAvailability) {
    return specificShiftAvailability;
  }

  return {
    [MONDAY]: allTimeAvailability,
    [TUESDAY]: allTimeAvailability,
    [WEDNESDAY]: allTimeAvailability,
    [THURSDAY]: allTimeAvailability,
    [FRIDAY]: allTimeAvailability,
    [SATURDAY]: allTimeAvailability,
    [SUNDAY]: allTimeAvailability
  };
};

export function useAvailableShiftDetails(value: ShiftAvailability) {
  return {
    availableShifts: mapShiftAvailabilityToShifts(value)
  };
}
