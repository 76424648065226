import { useTheme } from '@theme/DesignSystemProvider/ThemeContext';
import { IconSize } from '@theme/tokens';
import { useResolveToken } from '@theme/tokens/utils/useResolveToken';

export type IllustratedIconStyleProps = {
  size?: IconSize;
};

export function useIllustratedIconStyle({
  size = 'small'
}: IllustratedIconStyleProps) {
  const {
    icon: { sizes }
  } = useTheme();
  const { resolveToken } = useResolveToken();

  const dimension = {
    height: resolveToken(sizes, size),
    width: resolveToken(sizes, size)
  };

  return {
    ...dimension,
    fill: 'none'
  };
}
