import React, { useContext } from 'react';
import { JobTitle as InnerField } from '@common/components/PostJobForm/components/JobTitle/JobTitle';
import { FieldWrapper } from '../FieldWrapper';
import { FormContext } from '../FormContext';
import { jobTitleRules } from '../rules';

export default function JobTitle() {
  const { t } = useContext(FormContext);
  return (
    <FieldWrapper<string>
      name="jobTitle"
      rules={jobTitleRules(t)}
      render={(props) => <InnerField {...props} />}
    />
  );
}
