import React, { useState } from 'react';
import { Stack } from '@components/Stack';
import { NPSForm } from './components/NPSForm';
import { ScoreCard } from './components/ScoreCard';
import {
  useNpsUserData,
  useRefreshRecentNpsUserData,
  useTrackNps
} from './hooks';

type NpsProps = {
  trackingData: {
    page: string;
    applicantsReceived: boolean;
    userType: string;
    jobId: string;
  };
};

enum NpsStage {
  SCORE = 'SCORE',
  REASON = 'REASON'
}

export const NPS = (props: NpsProps) => {
  const { trackingData } = props;
  const { trackNpsReason, trackNpsScore } = useTrackNps();
  const { setLastInteractedNpsUserData, hasRecentlySubmittedNps } =
    useNpsUserData();

  useRefreshRecentNpsUserData();

  const [displayNps, setDisplayNps] = useState(!hasRecentlySubmittedNps());
  const [npsStage, setNpsStage] = useState(NpsStage.SCORE);

  if (!displayNps) {
    return null;
  }

  const scoreHandler = (score) => {
    trackNpsScore({ score, ...trackingData });
    setNpsStage(NpsStage.REASON);
  };

  const reasonHandler = ({ reason }) => {
    trackNpsReason({ reason, ...trackingData });
    setLastInteractedNpsUserData();
    setDisplayNps(false);
  };

  return (
    <Stack marginBottom="medium" testID="nps-component">
      {npsStage === NpsStage.SCORE ? (
        <ScoreCard handleScoreClick={scoreHandler} />
      ) : null}
      {npsStage === NpsStage.REASON ? (
        <NPSForm
          onSubmit={reasonHandler}
          handleClose={() => setDisplayNps(false)}
        />
      ) : null}
    </Stack>
  );
};
