import { forwardRef, useImperativeHandle } from 'react';
import {
  ConfirmIdBankTransferPayment,
  useConfirmIdBankTransferPayment
} from '@common/components/FormElements/PaymentProvider/useConfirmIdBankTransferPayment';
import {
  ConfirmPayment,
  useConfirmPayment
} from '@common/components/FormElements/PaymentProvider/useConfirmPayment';

type ConfirmPaymentProviderHandle = {
  confirmPayment: ConfirmPayment;
  confirmIdBankTransferPayment: ConfirmIdBankTransferPayment;
};

/**
 * @deprecated Remove this provider and use useConfirmPayment directly after the CheckoutForm is converted into a functional component.
 */
export const ConfirmPaymentProvider = forwardRef<
  ConfirmPaymentProviderHandle,
  void
>((props, ref) => {
  const { confirmPayment } = useConfirmPayment();
  const { confirmIdBankTransferPayment } = useConfirmIdBankTransferPayment();

  useImperativeHandle(
    ref,
    () => ({
      confirmPayment,
      confirmIdBankTransferPayment
    }),
    [confirmPayment, confirmIdBankTransferPayment]
  );

  return null;
});
