import isMatch from 'lodash.ismatch';
import { ProfileLocation } from '../../../components/ApplicationsFilterSortBar/components/FiltersModal/components/FiltersContent/components/LocationFilter/useLocationOptions/type';
import { FILTERS } from '../../../types';

type Filter = {
  [FILTERS.LOCATION]?: ProfileLocation[];
};

type Item = {
  currentLocationComponents?: ProfileLocation;
};

export const filterLocation = <T extends Item>(
  filter: Filter,
  items: T[]
): T[] => {
  const filterValue = filter[FILTERS.LOCATION];
  if (!filterValue) {
    return items;
  }

  return items.filter((item) => {
    const hasMatch = filterValue.some((filterObj) =>
      isMatch(item.currentLocationComponents!, filterObj)
    );
    return hasMatch;
  });
};
