import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { REDIRECT_TO } from '@common/search';

export type FormMode = 'POST' | 'EDIT' | 'REPOST';

export const useSubmitButtonLabel = (t: TFunction, mode: FormMode) => {
  if (mode === 'EDIT') {
    return t('postJobForm.action.updateJob');
  } else if (mode === 'REPOST') {
    return t('postJobForm.action.repostAd');
  } else {
    return t('postJobForm.action.postJob');
  }
};

export const useBusinessAndEmailEditable = (mode: FormMode) => {
  if (mode === 'EDIT') {
    return false;
  } else {
    return true;
  }
};

export const useShowCancelButton = (mode: FormMode) => {
  if (mode === 'EDIT' || mode === 'REPOST') {
    return true;
  } else {
    return false;
  }
};

export const useShouldRedirectBusinessCreatedTo = (mode: FormMode) => {
  if (mode === 'REPOST') {
    return REDIRECT_TO.repostJob;
  } else if (mode === 'POST') {
    return REDIRECT_TO.postJob;
  } else {
    return;
  }
};

export const useFormMode = (mode: FormMode) => {
  const { t } = useTranslation();

  const submitButtonLabel = useSubmitButtonLabel(t, mode);
  const showCancelButton = useShowCancelButton(mode);
  const shouldRedirectBusinessCreatedTo =
    useShouldRedirectBusinessCreatedTo(mode);

  const isBusinessAndEmailEditable = useBusinessAndEmailEditable(mode);

  return {
    submitButtonLabel,
    showCancelButton,
    shouldRedirectBusinessCreatedTo,
    isBusinessAndEmailEditable
  };
};
