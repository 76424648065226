import { getDifferenceInDaysForActiveJobs } from '@common/helpers/dateTime';

/**
 * Calculates the expiry text for a job based on the provided translation function and expiration date.
 * @param t - The translation function used to retrieve localized strings.
 * @param expiredAt - The expiration date of the job.
 * @returns The expiry text for the job.
 */
export const getExpiryText = ({
  t,
  expiredAt
}: {
  t: (key: string, options?) => string;
  expiredAt: string;
}) => {
  const daysTillExpiry = getDifferenceInDaysForActiveJobs({
    futureDate: expiredAt
  });
  return t(
    'jobDetailsAndApplicationsListPage.explanationText.jobExpireDate.expiring',
    { count: daysTillExpiry }
  );
};
