import { getLocalisedBrandDetails } from '@seek/je-shared-data';

export const CONTACT_PAGE = 'contact';
export const ABOUT_PAGE = 'about';
export const ADVERTISING_TERMS_OF_USE_PAGE = 'advertisingTermsOfUse';
export const TERMS_AND_CONDITION_PAGE = 'termsAndCondition';
export const PRIVACY_PAGE = 'privacy';
export const FAQ_EMPLOYER_PAGE = 'faqs.employers';
export const FAQ_WHY_ISNT_MY_JOB_AD_LIVE_PAGE = 'faqs.whyIsntMyJobAdLive';
export const POST_JOB_TIPS = 'postJobTips';

/**
 * @deprecated Use the useCandidateLink hook instead.
 */
export function buildCandidateLink({
  brandName,
  countryCode,
  page,
  queryString = ''
}) {
  const getPageLink = (obj, path) =>
    path.split('.').reduce((acc, part) => acc && acc[part], obj);

  const link = `https://${
    getLocalisedBrandDetails(brandName, countryCode).candidateDomain
  }${getPageLink(
    getLocalisedBrandDetails(brandName, countryCode).links,
    page
  )}${queryString}`;

  if (page === CONTACT_PAGE) {
    return link.includes('?')
      ? `${link}&who_are_you=employer`
      : `${link}?who_are_you=employer`;
  }

  return link;
}

/**
 * @deprecated Use the useCandidateLink hook instead.
 */
export const candidateLogin = ({ candidateDomain }) =>
  `https://${candidateDomain}/login`;

/**
 * @deprecated Use the useCandidateLink hook instead.
 */
export const candidateRegistration = ({ candidateDomain }) =>
  `https://${candidateDomain}/users/sign_up`;
