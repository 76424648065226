import { sendVerifyEmail as sendVerifyEmailAction } from '@store/auth';
import { useAppDispatch } from '@store/hooks';

type SendVerificationEmailProps = {
  isResend?: boolean;
};

export const useSendVerificationEmail = () => {
  const dispatch = useAppDispatch();

  return async ({ isResend }: SendVerificationEmailProps) => {
    const response = await dispatch(sendVerifyEmailAction(isResend));

    return { isSuccessful: !response?.error };
  };
};
