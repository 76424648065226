import React from 'react';
import { useTranslation } from 'react-i18next';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { useRedirectUnauthenticatedUser } from '@common/hooks/useRedirectUnauthenticatedUser';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';
import { CreateAccount } from './components/CreateAccount';
import { VerifyAccount } from './components/VerifyAccount';

export const VerifyEmailSuccess = () => {
  const { t } = useTranslation();
  const { countryCode } = useAppSelector(getLocalisation);
  useRedirectUnauthenticatedUser({ to: `/${countryCode}/login` });

  return (
    <>
      <JoraEmployerHelmet title={t('verifyEmailSuccessPage.title')} />
      <VerifyAccount />
      <CreateAccount />
    </>
  );
};
