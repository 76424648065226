import React from 'react';
import { Row, RowProps } from '@components/Row';

export type Props = {
  children: React.ReactElement[];
  paddingHorizontal?: RowProps['paddingHorizontal'];
};

export const TabList = (props: Props) => {
  const { children, paddingHorizontal } = props;

  return (
    <Row
      spacing="small"
      paddingVertical="medium"
      paddingHorizontal={
        paddingHorizontal || {
          mobile: 'small',
          desktop: 'none',
          tablet: 'none'
        }
      }
      tone="transparent"
    >
      {children}
    </Row>
  );
};
