import React from 'react';
import { useTranslation } from 'react-i18next';
import { Pill } from '@components/Pill';

type Props = {
  onPressClearFilters: () => void;
  filteredCount: number;
};

export const FilteredStatusPill = ({
  onPressClearFilters,
  filteredCount
}: Props) => {
  const { t } = useTranslation();
  return (
    <Pill
      label={t('applications.applicationsFilter.filterApplied', {
        count: filteredCount
      })}
      testID="applications-filter-selected-filters-pill"
      onPress={onPressClearFilters}
    />
  );
};
