import { REGEX } from '@common/constants/data';
import {
  BUSINESS_NAME_MAX_LENGTH,
  BUSINESS_NAME_MIN_LENGTH,
  MAX_LENGTH,
  MIN_LENGTH
} from '@common/constants/validation';
import type { T } from '@src/caja/Form/types';

/**
 * Generates validation rules for the business name field.
 * @param t - The translation function.
 * @returns The validation rules object.
 */
export function businessNameRules(t: T) {
  return {
    required: t('validations.business.name.required'),
    [MAX_LENGTH]: {
      value: BUSINESS_NAME_MAX_LENGTH,
      message: t('validations.business.name.maxLength')
    },
    [MIN_LENGTH]: {
      value: BUSINESS_NAME_MIN_LENGTH,
      message: t('validations.business.name.minLength')
    },
    pattern: {
      value: REGEX.CONTAINS_ANGLE_BRACKET,
      message: t('validations.business.name.noAngleBracket')
    }
  };
}
