import { useRedirectAuthenticatedUser } from '@common/hooks/useRedirectAuthenticatedUser';

type RedirectAuthenticatedUserProps = {
  to?: string;
};

/**
 * @deprecated Use the useRedirectAuthenticatedUser hook instead.
 */
export const RedirectAuthenticatedUser = ({
  to
}: RedirectAuthenticatedUserProps) => {
  useRedirectAuthenticatedUser({ to });

  return null;
};
