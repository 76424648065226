import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <>
    <Path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M4.493 13.656a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
    />
    <Path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M.493 18.156c0-2.486 1.795-4.5 4-4.5s4 2.014 4 4.5h-8ZM13.493 5.156a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM9.493 9.656c0-2.428 1.7-4.5 3.789-4.5 2.088 0 4.211 2.072 4.211 4.5h-8Z"
    />
    <Path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={0.75}
      d="M1.373 8.036c.53-2.84 3.111-5 6.214-5 .265 0 .53.016.786.049M17.613 12.276c-.667 2.855-3.548 5-7 5"
    />
  </>
);

export const CandidatesIcon = createIcon({
  viewBox: '0 0 18 19',
  vectorComponent
});
