import { TrpcRouter } from 'je-bff/src/handlers/bff';
import type { inferRouterInputs, inferRouterOutputs } from '@trpc/server';
import { trpcBff } from '@trpcBff';

export type ApplicationInformation = NonNullable<
  inferRouterOutputs<TrpcRouter>['getApplicationProfiles']
>;
export type GetApplicationProfilesParams =
  inferRouterInputs<TrpcRouter>['getApplicationProfiles'];

export const useGetApplications = (params: GetApplicationProfilesParams) =>
  trpcBff.getApplicationProfiles.useQuery(params, {
    staleTime: 5000,
    refetchOnWindowFocus: false
  });
