import { useSiteCountryCode } from '@common/hooks/localisation';

type UseGetEditBusinessLinkProps = {
  id: string;
};

export function useGetEditBusinessLink({
  id
}: UseGetEditBusinessLinkProps): string {
  const countryCode = useSiteCountryCode();
  return `/${countryCode}/businesses/${id}/edit-business`;
}
