import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { Box } from '@components/Box';
import { Field } from '@components/FormElements';
import { RichTextInput } from '@components/FormElements/RichTextInput';
import { Stack } from '@components/Stack';
import { JobDescriptionCalloutDetails } from './components/JobDescriptionCalloutDetails';

type JobDescriptionProps = {
  value: string;
  onChange: () => void;
  onBlur: () => void;
  error?: string;
};

export const JobDescription = ({
  error,
  onChange,
  onBlur,
  value
}: JobDescriptionProps) => {
  const { t } = useTranslation();

  function charCountProvider(count: number): string {
    return t('postJobForm.jobDescription.characters', { count });
  }

  return (
    <Stack spacing="small">
      <Field
        label={t('postJobForm.fieldLabel.jobDescription')}
        message={
          error && (
            <Box marginTop="xxsmall">
              <ErrorMessage>{error}</ErrorMessage>
            </Box>
          )
        }
      >
        <Box>
          <RichTextInput
            error={Boolean(error)}
            value={value}
            charCountProvider={charCountProvider}
            onChange={onChange}
            onBlur={onBlur}
          />
        </Box>
      </Field>

      <JobDescriptionCalloutDetails />
    </Stack>
  );
};
