import dayjs from 'dayjs';
import { REGEX } from '@common/constants/data';

type DateInput = Date | number | string;
type GetDateDiffInput = {
  currentDate?: DateInput;
  pastDate: DateInput;
};
type GetDateDiffInDaysInput = {
  currentDate?: DateInput;
  futureDate: DateInput;
};

export function localisedFormatDate(
  t: (key: string, options) => string,
  formatPattern: string,
  inputDate?: DateInput
) {
  if (!inputDate) {
    return '';
  }

  if (formatPattern === 'dd MMM yyyy') {
    const date = inputDate instanceof Date ? inputDate : new Date(inputDate);

    const dd = date.getDate();
    const MM = t('common.months.shortened', { returnObjects: true })[
      date.getMonth()
    ];
    const yyyy = date.getFullYear();

    return `${dd} ${MM} ${yyyy}`;
  }

  if (formatPattern === 'dd MMMM yyyy') {
    const date = inputDate instanceof Date ? inputDate : new Date(inputDate);

    const dd = date.getDate();
    const MM = t('common.months.long', { returnObjects: true })[
      date.getMonth()
    ];
    const yyyy = date.getFullYear();

    return `${dd} ${MM} ${yyyy}`;
  }

  return '';
}

export function getDateDifference({
  currentDate = new Date(),
  pastDate
}: GetDateDiffInput): {
  years: number;
  months: number;
} {
  const timeDifference = Math.floor(
    new Date(currentDate).getTime() - new Date(pastDate).getTime()
  );

  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  let months = Math.floor(days / 31);
  let years = 0;

  if (months > 12) {
    years = Math.floor(months / 12);
    months = months % 12;
  }

  if (months === 0) {
    months = 1;
  }

  return {
    years,
    months
  };
}

export function getPastDateTimeByDays(numberOfDays: number = 7): string {
  return dayjs().subtract(numberOfDays, 'day').toISOString();
}

export function convertDDMMYYYToIso(date: string): string {
  if (!REGEX.VALID_DATE.test(date)) {
    return '';
  }

  const [day, month, year] = date.split('/');

  return new Date(`${year}-${month}-${day} 12:00:00`).toISOString();
}

export function formatIsoToDate(isoDate: string, formatPattern: string) {
  return dayjs(new Date(isoDate)).format(formatPattern);
}

/**
 * Calculates the difference in days between a future date and the current date.
 *
 * @param currentDate - The current date. Defaults to the current system date if not provided.
 * @param futureDate - The future date to calculate the difference with.
 * @returns The difference in days between the future date and the current date.
 */
export function getDifferenceInDaysForActiveJobs({
  currentDate = new Date(),
  futureDate
}: GetDateDiffInDaysInput): number {
  const differenceInMilliseconds =
    new Date(futureDate).getTime() - new Date(currentDate).getTime();

  if (differenceInMilliseconds < 0) {
    return 0;
  }

  const differenceInDays = Math.floor(
    differenceInMilliseconds / (1000 * 60 * 60 * 24)
  );

  return differenceInDays;
}
