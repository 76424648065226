import * as constants from './constants';
import { getErrorMessage } from '../../../joraEmployer/helpers/responseData';

const initialState = {
  loading: false,
  locationSuggestError: null,
  locationDetailsError: null,
  locationsSuggested: [],
  selectedLocationData: {}
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case constants.SUGGESTION_FETCH:
      return {
        ...state,
        loading: true,
        locationSuggestError: null,
        locationsSuggested: [],
        selectedLocationData: {}
      };
    case constants.SUGGESTION_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        locationSuggestError: null,
        locationsSuggested: action.result
      };
    case constants.SUGGESTION_FETCH_FAIL:
      return {
        ...state,
        loading: false,
        locationSuggestError: getErrorMessage(action.error),
        locationsSuggested: []
      };
    case constants.LOCATION_DATA_FETCH:
      return {
        ...state,
        locationDetailsError: null,
        selectedLocationData: {}
      };
    case constants.LOCATION_DATA_FETCH_SUCCESS:
      return {
        ...state,
        locationDetailsError: null,
        selectedLocationData: action.result
      };
    case constants.LOCATION_DATA_FETCH_FAIL:
      return {
        ...state,
        locationDetailsError: getErrorMessage(action.error),
        selectedLocationData: {}
      };
    case constants.LOCATION_DATA_CLEAR:
      return {
        ...state,
        selectedLocationData: {}
      };
    default:
      return state;
  }
}