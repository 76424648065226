import { useTranslation } from 'react-i18next';
import { useGetSourceParams } from '@common/hooks/useSearchQueryParam';
import { getUser } from '@store/auth';
import { postJob } from '@store/entities/jobs';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { JobFields, PostJobFormFields } from '../../types';

type UseSubmitPostJobParams = {
  originalJobId?: string;
};

const toJobRequestData = (
  job: JobFields,
  sourceParams: Record<string, string>
) => {
  return {
    jobDescription: job.jobDescription,
    jobType: job.jobType,
    jobTitle: job.jobTitleAuto.jobTitle,
    shiftAvailability: job.shiftAvailability,
    rightToWork: job.rightToWork,
    startDate: job.startDate,
    workExperience: job.workExperience,
    jobRole: job.jobTitleAuto.role,
    salary: job.salary,
    sourceParams
  };
};

export const useSubmitPostJobWithAuto = ({
  originalJobId
}: UseSubmitPostJobParams = {}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const sourceParams = useGetSourceParams();
  const { currentUser } = useAppSelector(getUser);

  const submitPostJobWithAuto = (data: PostJobFormFields) => {
    const { business: businessData, ...jobData } = data;

    const jobRequestData = toJobRequestData(jobData, sourceParams);

    dispatch(
      postJob({
        ...jobRequestData,
        ...(originalJobId ? { originalJobId } : {}),
        userData: currentUser,
        businessData,
        t
      })
    );
  };

  return { submitPostJobWithAuto };
};
