import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import BusinessImage from '../BusinessImage/BusinessImage';
import Div from '../Div/Div';
import { BUSINESS_SIZE } from '@seek/je-shared-data';

export default class BusinessImageAndDetails extends PureComponent {
  static propTypes = {
    businessSize: PropTypes.oneOf(Object.values(BUSINESS_SIZE)),
    children: PropTypes.node.isRequired
  };

  render() {
    const { businessSize, children } = this.props;

    return (
      <Div flexDirection="row" alignItems="center">
        <BusinessImage withRightMargin businessSize={businessSize} />
        <Div isPageContainer>
          {children}
        </Div>
      </Div>
    );
  }
}
