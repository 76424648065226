import React from 'react';
import { AgeVerification as InnerField } from '@common/components/Account/components/AgeVerification';
import { FieldWrapper } from '../FieldWrapper';

export function AgeVerification() {
  return (
    <FieldWrapper<boolean>
      name="ageConsent"
      render={(props) => <InnerField {...props} />}
    />
  );
}
