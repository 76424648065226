import { EventTypes } from 'redux-segment';

export const BANNER_CLICKED = 'jora-employer/banner/DISPLAY_BANNER_CLICKED';

export function trackDisplayBannerClicked({ type }) {
  return {
    type: BANNER_CLICKED,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'Display banner clicked',
          properties: {
            type
          }
        }
      }
    }
  };
}
