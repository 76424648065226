import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@components/Box';
import { Card, CardContent } from '@components/Card';
import { Column } from '@components/Column';
import { Heading } from '@components/Heading';
import { JobPostingIllustration } from '@components/Illustration';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';

export const FunctionalityTile = () => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardContent>
        <Heading level="h2" weight="medium">
          {t('employerLandingPage.functionalityTile.heading')}
        </Heading>
        <Stack
          direction={{ mobile: 'column', tablet: 'row', desktop: 'row' }}
          spacing="large"
        >
          <Column
            flexShrink={1}
            maxWidth={{ mobile: 'xxxlarge', tablet: '2/3', desktop: '2/3' }}
          >
            <Box marginTop="medium">
              <Heading level="h2">
                {t('employerLandingPage.functionalityTile.firstJob.heading')}
              </Heading>
              <Box marginTop="xsmall">
                <Text>
                  {t(
                    'employerLandingPage.functionalityTile.firstJob.description'
                  )}
                </Text>
              </Box>
            </Box>

            <Box marginTop="medium">
              <Heading level="h2">
                {t(
                  'employerLandingPage.functionalityTile.manageCandidates.heading'
                )}
              </Heading>
              <Box marginTop="xsmall">
                <Text>
                  {t(
                    'employerLandingPage.functionalityTile.manageCandidates.description'
                  )}
                </Text>
              </Box>
            </Box>
          </Column>
          <Box
            marginLeft="medium"
            marginTop={{ mobile: 'large', tablet: 'none', desktop: 'none' }}
          >
            <JobPostingIllustration size="large" />
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};
