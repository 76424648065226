import { sendToNative } from '../sendToNative';

interface SendCountrySelectionResult {
  data: { redirectTo: string };
  success: boolean;
}

export async function sendCountrySelection(countryCode: string): Promise<void> {
  const result = (await sendToNative('country-selection', {
    countryCode
  })) as SendCountrySelectionResult;

  if (result.success) {
    window.location.href = result.data.redirectTo;
  }
}
