import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardAction } from '@components/Card';
import { DownArrowIcon } from '@components/Icon';
import { Link } from '@components/Link';

type Props = {
  onPress: () => void;
};

export const ViewLessAction = ({ onPress }: Props) => {
  const { t } = useTranslation();

  return (
    <CardAction>
      <Link
        onPress={onPress}
        variant="secondary"
        icon={<DownArrowIcon variant="outline" />}
      >
        {t('myCoinsPage.transactions.viewLess')}
      </Link>
    </CardAction>
  );
};
