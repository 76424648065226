import React, { useContext } from 'react';
import { MobileNumber as InnerField } from '@common/components/Account/components/MobileNumber';
import { mobileNumber } from '@common/components/Account/rules';
import { mapCountryCodeToDiallingCode } from '@common/constants/mapping';
import { useSiteCountryCode } from '@common/hooks/localisation';
import { FieldWrapper } from '../FieldWrapper';
import { FormContext } from '../FormContext';

export const MobileNumber = () => {
  const { t } = useContext(FormContext);
  const countryCode = useSiteCountryCode();
  const defaultDiallingCode = mapCountryCodeToDiallingCode(countryCode);
  return (
    <FieldWrapper<{ diallingCode: string; phoneNumber: string }>
      name="mobileNumber"
      rules={mobileNumber(t)}
      render={(props) => {
        const mergedProps = { ...props, t };
        return (
          <InnerField
            {...mergedProps}
            showExplanationText={true}
            value={
              props.value || {
                diallingCode: defaultDiallingCode,
                phoneNumber: ''
              }
            }
          />
        );
      }}
    />
  );
};
