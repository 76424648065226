import React, { LegacyRef } from 'react';
import RecaptchaBadge from '@common/components/RecaptchaBadge/RecaptchaBadge';
import { getGoogleRecaptcha } from '@store/config';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';

export interface RecaptchaHandle {
  execute: () => void;
}

interface RecaptchaProps {
  onVerify: (recaptchaResponse: string) => void;
  recaptchaRef: React.RefObject<RecaptchaHandle>;
}

export const Recaptcha = ({ onVerify, recaptchaRef }: RecaptchaProps) => {
  const { sitekey, baseUrl } = useAppSelector(getGoogleRecaptcha);
  const { language } = useAppSelector(getLocalisation);

  function handleVerify({ recaptchaResponse }: { recaptchaResponse: string }) {
    onVerify(recaptchaResponse);
  }

  function handleRecaptchaError(a: any) {
    console.error('Recaptcha error', a);
  }

  return (
    <RecaptchaBadge
      ref={recaptchaRef as LegacyRef<RecaptchaBadge>}
      sitekey={sitekey}
      baseUrl={baseUrl}
      insideCard={true}
      language={language}
      onVerify={handleVerify}
      onError={handleRecaptchaError}
    />
  );
};
