import { nextPageScrollPosition } from './nextPageScrollPosition';

type ElementWithRect = {
  getBoundingClientRect(): {
    top: number;
  };
  scrollTop: number;
  scrollTo: (options: { top: number; behavior: 'smooth' }) => void;
};

interface ScrollToTopOfListProps {
  topOfList: ElementWithRect | null;
  scroller: ElementWithRect | null;
}

/**
 * Scrolls the scroller element to the top of the list element smoothly.
 *
 * @param {Object} params - The parameters for the function.
 * @param {HTMLElement} params.topOfList - The top element of the list to scroll to.
 * @param {HTMLElement} params.scroller - The scroller element that will be scrolled.
 * @returns {void} This function does not return a value.
 */
export function scrollToTopOfList({
  topOfList,
  scroller
}: ScrollToTopOfListProps) {
  if (!topOfList || !scroller) return;

  const topOfListPosition = nextPageScrollPosition({
    topOfList,
    scroller
  });

  scroller.scrollTo({
    top: topOfListPosition,
    behavior: 'smooth'
  });
}
