import { useCallback } from 'react';
import { loadBusinesses } from '@store/entities/businesses/actions';
import { useAppDispatch } from '@store/hooks';

export const useOnDeleteBusinessSuccess = (onRequestClose: () => void) => {
  const dispatch = useAppDispatch();
  const onSuccessfullDeleteBusiness = useCallback(() => {
    dispatch(loadBusinesses());
    onRequestClose();
  }, [dispatch, onRequestClose]);

  return onSuccessfullDeleteBusiness;
};
