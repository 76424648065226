import { AppealStatus } from '@seek/je-shared-data/lib/types/authentication';

export const pathUserHasLimitedAccessTo = ({
  countryCode,
  userDetails: {
    appealStatus,
    emailVerified,
    emailLastVerifiedAt: _emailLastVerifiedAt
  },
  hasDraftData
}) => {
  // hasDraftData is only derived from the fact there is data in local storage for a new job/business
  // this could cause an edge for if a user starts the authenticated post job process but then leave to edit there email
  // which would result in them being redirected to verify-email page in the signup flow and not account verify email
  if (!emailVerified && hasDraftData) {
    return `/${countryCode}/verify-email`;
  }

  // TODO: uncomment this for new sign-up usecase
  // if (!emailVerified && !_emailLastVerifiedAt) {
  //   return `/${countryCode}/sign-up/account/verify-email`;
  // }

  // EXISTING UNVERIFIED USER
  // Applies for users who have never verified their email
  // add `&& !emailLastVerifiedAt` to the condition to make it work for existing users after sign-up usecase has been added
  if (!emailVerified) {
    return `/${countryCode}/account/verify-email`;
  }

  if (appealStatus === AppealStatus.REQUESTED) {
    return `/${countryCode}/account/verify-account-details`;
  }

  if (appealStatus === AppealStatus.SUBMITTED) {
    return `/${countryCode}/account/verify-account-details-success`;
  }
};
