import React from 'react';
import { useTranslation } from 'react-i18next';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { RequestErrorMessage } from '@common/components/RequestErrorMessage';
import REQUEST_KEY from '@common/constants/requestKeys';
import { useRedirectUnauthenticatedUser } from '@common/hooks/useRedirectUnauthenticatedUser';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';
import { Box } from '@components/Box';
import { Button } from '@components/Button';
import { Card, CardContent } from '@components/Card';
import { Heading } from '@components/Heading';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { useDeleteAccount } from './hooks/useDeleteAccount';

export const DeleteAccount = () => {
  const { t } = useTranslation();
  const { countryCode } = useAppSelector(getLocalisation);

  useRedirectUnauthenticatedUser({
    to: `/${countryCode}/account/delete-account-success`
  });

  return (
    <Stack tone="transparent">
      <JoraEmployerHelmet title={t('deleteAccountPage.title')} />

      <Box
        paddingTop="medium"
        paddingBottom="small"
        paddingHorizontal="medium"
        tone="transparent"
      >
        <Heading
          level="h1"
          weight="regular"
          testID="delete-account-page-heading"
        >
          {t('deleteAccountPage.title')}
        </Heading>
      </Box>

      <Card>
        <CardContent>
          <Stack spacing="medium" tone="transparent">
            <DeleteAccountCopy />
            <DeleteAccountActions />
            <RequestErrorMessage
              requestKey={REQUEST_KEY.AUTH.DELETE_ACCOUNT_REQUEST}
            />
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
};

const DeleteAccountCopy = () => {
  const { t } = useTranslation();

  return (
    <Stack spacing="medium" testID="delete-account-content">
      <Text>{t('deleteAccountPage.description.title')}</Text>

      <Text>{t('deleteAccountPage.description.warning')}</Text>
    </Stack>
  );
};

const DeleteAccountActions = () => {
  const { t } = useTranslation();
  const { handleDeleteAccount, redirectToAccountPage } = useDeleteAccount();

  return (
    <Stack
      marginTop="large"
      spacing="medium"
      direction={{ mobile: 'column', tablet: 'row', desktop: 'row' }}
    >
      <Button onPress={handleDeleteAccount} testID="delete-account-action">
        {t('deleteAccountPage.title')}
      </Button>

      <Button
        onPress={redirectToAccountPage}
        variant="secondary"
        testID="delete-account-cancel-button"
      >
        {t('common.action.cancel')}
      </Button>
    </Stack>
  );
};
