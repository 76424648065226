import { AppState } from '../../store';

export function getJobById(id: string) {
  return (state: AppState) => state.entities.jobs.items[id];
}

export const getJobs = () => {
  return (state: AppState) => state.entities.jobs.items;
};

export const getJobsList = () => {
  return (state: AppState) => state.entities.jobs.dashboard.list;
};
