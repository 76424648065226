import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useLocation } from 'react-router-native';
import { GridOverlay } from '@common/components/GridOverlay';
import { When } from '@common/components/When';
import { showFooter } from '@src/joraEmployer/appStructure';
import { inNativeApp } from '@src/joraEmployer/whereAmIRunning';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';
import { CodeReloader } from '../CodeReloader/CodeReloader';
import { UniversalLoader } from '../UniversalLoader';
import { UniversalRefresher } from '../UniversalRefresher/UniversalRefresher';
import { JoraEmployerFooter } from './components/JoraEmployerFooter';
import { JoraEmployerHeader } from './components/JoraEmployerHeader';
import { NavMenu } from './components/NavMenu';
import { UniversalOffline } from './components/UniversalOffline/UniversalOffline';
import { useRedirectUserWithLimitedAccess } from './hooks/useRedirectUserWithLimitedAccess';
import { useWebShell } from './hooks/useWebShell';

export const Shell = ({ children }: PropsWithChildren) => {
  useRedirectUserWithLimitedAccess();
  const localisation = useAppSelector(getLocalisation);
  const { pathname } = useLocation();
  const [shouldShowFooter, setShouldShowFooter] = useState(false);

  const { devToolsEnabled, setRaygunUserId, trackBrowserInformation } =
    useWebShell();

  useEffect(() => {
    trackBrowserInformation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShouldShowFooter(showFooter({ pathname, inNativeApp: inNativeApp() }));
  }, [pathname]);

  setRaygunUserId();

  return (
    <div className="h-screen h-dvh flex flex-col">
      <UniversalLoader />
      <UniversalOffline />
      <UniversalRefresher />
      <CodeReloader />
      <nav className="z-[100001]">
        <When condition={Boolean(devToolsEnabled)}>
          <GridOverlay />
        </When>
        <JoraEmployerHeader />
      </nav>
      <div
        id="main-scroller"
        className="flex-1 flex-grow flex-col flex overflow-y-scroll justify-between md:order-3"
      >
        <div
          id="child-wrapper"
          className="w-full max-w-full lg:max-w-7xl flex-grow mx-auto"
        >
          {children}
        </div>
        <When condition={shouldShowFooter}>
          <JoraEmployerFooter localisation={localisation} />
        </When>
      </div>
      <div className="z-[100001] md:order-1">
        <NavMenu />
      </div>
    </div>
  );
};
