import { useRedirect } from '@common/hooks/useRedirect';
import { deleteAccountRequest, logout } from '@store/auth';
import { useAppDispatch } from '@store/hooks';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';

export function useDeleteAccount() {
  const { countryCode } = useAppSelector(getLocalisation);
  const { redirect } = useRedirect();
  const dispatch = useAppDispatch();

  return {
    handleDeleteAccount: () => {
      dispatch(deleteAccountRequest());
      dispatch(
        logout({
          initiatedByUser: false,
          to: `/${countryCode}/account/delete-account-success`
        })
      );
    },
    redirectToAccountPage: redirect(`/${countryCode}/account`)
  };
}
