import REQUEST_KEY from '@common/constants/requestKeys';
import { ClientError, ClientErrorProperties } from '@common/helpers/errors';
import { useHandleAppLoading } from '@common/hooks/appLoading';
import { extractTrpcErrorProperties } from '@src/trpc/lib';
import { trpcBff } from '@trpcBff';

const userLimitsSaleTime = 10000;

type GetUserLimits = {
  businessLimit?: number;
  jobLimit?: number;
  isLoading?: boolean;
  premiumFilters?: boolean;
  error?: {
    message: string;
    properties: ClientErrorProperties;
  };
};

export const useGetUserLimits = (): GetUserLimits => {
  const { data, isLoading, error } =
    trpcBff.userPackages.userLimits.get.useQuery(undefined, {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: userLimitsSaleTime
    });

  // Show global loader while loading
  useHandleAppLoading(isLoading, REQUEST_KEY.ORGANISATION.USER_LIMITS.GET);

  const clientError = getClientError(extractTrpcErrorProperties(error));

  return {
    businessLimit: data?.businessLimit,
    jobLimit: data?.jobLimit,
    premiumFilters: data?.premiumFilters,
    error: clientError && {
      message: clientError.message,
      properties: clientError.properties
    }
  };
};

const getClientError = (errorProperties) => {
  if (!errorProperties) return;

  return new ClientError('errors.defaultWithGuid', {
    guid: errorProperties.guid,
    responseBody: errorProperties.responseBody
  });
};
