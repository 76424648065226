import { useMemo } from 'react';
import { ProfileApplication } from 'je-bff/src/applications/list/transformers';
import {
  getJobAge,
  isJobAdBasic,
  isJobClosedOrExpired
} from '@common/helpers/jobs';
import { useMonetisationEnabled } from '@common/hooks/featureToggles';
import { JOB_STATUS } from '@seek/je-shared-data';
import { getApplications } from '@store/entities/applications/selectors';
import { getJobById } from '@store/entities/jobs';
import { useAppSelector } from '@store/hooks';
import { EmptyApplicationsType } from './EmptyApplicationsType';

const JOB_AGE_FOR_MESSAGE_CHANGE = 3;

type EmptyApplicationsState = {
  condition: boolean;
  type: EmptyApplicationsType;
};

export type CurrentSelectedTab = 'matched' | 'other';

// TODO: this is quite complex; come back to refactor it and simplify the cases
export function useEmptyApplicationsInformationType(
  jobId: string,
  currentSelectedTab: CurrentSelectedTab,
  filteredApplications: ProfileApplication[]
): EmptyApplicationsType | undefined {
  const job = useAppSelector(getJobById(jobId)) || {};
  const { totalApplication = 0, applicationsAvailability } =
    useAppSelector(getApplications(jobId)) || {};
  const isMonetisationEnabled = useMonetisationEnabled(job.countryCode || '');

  const hasNoFilteredApplications = filteredApplications.length === 0;
  const isApplicationDataAvailable = Boolean(
    applicationsAvailability?.available
  );

  const emptyStateType = useMemo(() => {
    const matchedApplications = filteredApplications.filter(
      (application) => application.isMatch === true
    );
    const otherApplications = filteredApplications.filter(
      (application) => application.isMatch === false
    );

    const emptyApplicationsStates: EmptyApplicationsState[] = [
      {
        condition: !isApplicationDataAvailable && totalApplication > 0,
        type: EmptyApplicationsType.EXPIRED_APPLICATIONS
      },
      {
        condition:
          isApplicationDataAvailable &&
          isJobClosedOrExpired(job.status) &&
          totalApplication === 0,
        type: EmptyApplicationsType.INACTIVE_JOB_WITH_NO_APPLICATIONS
      },
      {
        condition:
          isApplicationDataAvailable &&
          job.status === JOB_STATUS.LIVE &&
          totalApplication === 0 &&
          isJobXDaysOld(job.createdAt, JOB_AGE_FOR_MESSAGE_CHANGE),
        type: EmptyApplicationsType.NEW_ACTIVE_JOB_WITH_NO_APPLICATIONS
      },
      {
        condition:
          isApplicationDataAvailable &&
          job.status === JOB_STATUS.LIVE &&
          totalApplication === 0 &&
          !isJobXDaysOld(job.createdAt, JOB_AGE_FOR_MESSAGE_CHANGE),
        type: EmptyApplicationsType.OLD_ACTIVE_JOB_WITH_NO_APPLICATIONS
      },
      {
        condition:
          isApplicationDataAvailable &&
          hasNoFilteredApplications &&
          totalApplication > 0,
        type: EmptyApplicationsType.NO_APPLICATIONS
      },
      {
        condition:
          currentSelectedTab === 'matched' &&
          job.status === JOB_STATUS.LIVE &&
          isApplicationDataAvailable &&
          matchedApplications.length === 0 &&
          otherApplications.length > 0,
        type: EmptyApplicationsType.ACTIVE_JOB_WITH_NO_MATCHED_APPLICATIONS
      },
      {
        condition:
          currentSelectedTab === 'other' &&
          job.status === JOB_STATUS.LIVE &&
          isApplicationDataAvailable &&
          otherApplications.length === 0 &&
          matchedApplications.length > 0 &&
          isMonetisationEnabled &&
          isJobAdBasic(job.adType),
        type: EmptyApplicationsType.ACTIVE_JOB_WITH_NO_OTHER_APPLICATIONS_FOR_UPGRADABLE_JOB
      },
      {
        condition:
          currentSelectedTab === 'other' &&
          job.status === JOB_STATUS.LIVE &&
          isApplicationDataAvailable &&
          otherApplications.length === 0 &&
          matchedApplications.length > 0,
        type: EmptyApplicationsType.ACTIVE_JOB_WITH_NO_OTHER_APPLICATIONS
      },
      {
        condition:
          currentSelectedTab === 'matched' &&
          isJobClosedOrExpired(job.status) &&
          isApplicationDataAvailable &&
          matchedApplications.length === 0 &&
          otherApplications.length > 0,
        type: EmptyApplicationsType.INACTIVE_JOB_WITH_NO_MATCHED_APPLICATIONS
      },
      {
        condition:
          currentSelectedTab === 'other' &&
          isJobClosedOrExpired(job.status) &&
          isApplicationDataAvailable &&
          otherApplications.length === 0 &&
          matchedApplications.length > 0,
        type: EmptyApplicationsType.INACTIVE_JOB_WITH_NO_OTHER_APPLICATIONS
      }
    ];

    return emptyApplicationsStates.find(
      (emptyApplicationsState) => emptyApplicationsState.condition
    )?.type;
  }, [
    currentSelectedTab,
    filteredApplications,
    hasNoFilteredApplications,
    isApplicationDataAvailable,
    isMonetisationEnabled,
    job.adType,
    job.createdAt,
    job.status,
    totalApplication
  ]);

  return emptyStateType;
}

function isJobXDaysOld(createdAt, days) {
  return getJobAge(createdAt) <= days;
}
