import React, { Fragment, ReactElement, forwardRef } from 'react';
import { View } from 'react-native';
import { Spacing } from '@theme/tokens/spacing';
import { Box, BoxProps } from '../Box';
import { Divider } from '../Divider';
import { Space } from './components';
import { DeviceSpacing } from './types';
import { useStackStyle } from './useStackStyle';

export type StackProps = BoxProps & {
  dividers?: boolean;
  spacing?: Spacing | DeviceSpacing;
};

export const Stack = forwardRef<View, StackProps>(
  (
    {
      spacing = 'none',
      direction = 'column',
      dividers,
      children,
      tone,
      ...restProps
    }: StackProps,
    ref
  ) => {
    const { dividerDirection } = useStackStyle({
      direction
    });
    const childrenArray = React.Children.toArray(children);

    return (
      <Box direction={direction} ref={ref} tone={tone} {...restProps}>
        {childrenArray.map((child, index) => {
          return (
            <Fragment key={(child as ReactElement).key || index}>
              {index > 0 ? (
                <Space direction={direction} size={spacing} />
              ) : null}

              {index > 0 && dividers ? (
                <>
                  <Divider direction={dividerDirection} />
                  <Space direction={direction} size={spacing} />
                </>
              ) : null}

              {child}
            </Fragment>
          );
        })}
      </Box>
    );
  }
);
