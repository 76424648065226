import { useDispatch } from 'react-redux';
import { EventTypes } from 'redux-segment';

export const useTrackUpgradeAccountButtonClicked = () => {
  const dispatch = useDispatch();
  return () => dispatch(trackUpgradeAccountButtonClicked());
};

const TRACK_UPGRADE_ACCOUNT_BUTTON_CLICKED =
  'jora-employer/account/TRACK_UPGRADE_ACCOUNT_BUTTON_CLICKED';

function trackUpgradeAccountButtonClicked(): { type: string } & Record<
  string,
  unknown
> {
  return {
    type: TRACK_UPGRADE_ACCOUNT_BUTTON_CLICKED,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'Upgrade account button clicked',
          properties: {}
        }
      }
    }
  };
}
