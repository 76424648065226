import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <>
    <Path d="M0.991821 9.08676H16.9918M0.991821 9.08676C0.991821 6.2084 2.48388 0.883391 8.87121 0.883423M0.991821 9.08676C0.991819 12.8834 2.99632 16.4171 8.94562 17.1079M16.9918 9.08676C17.2146 6.38342 15.1905 0.883454 8.87121 0.883423M16.9918 9.08676C16.451 15.6494 11.4245 17.2518 8.94562 17.1079M8.87121 0.883423C5.94163 3.9057 1.91463 11.5814 8.94562 17.1079M8.87121 0.883423C16.2628 8.48232 12.0254 14.9491 8.94562 17.1079M8.87121 0.883423L8.94562 17.1079" />
  </>
);

export const GlobeIcon = createIcon({ viewBox: '0 0 18 18', vectorComponent });
