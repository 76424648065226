import React from 'react';
import { PostJob } from '@src/joraEmployer/pages/job/post-job';
import { SignUp } from '@src/joraEmployer/pages/signUp';
import { getUser } from '@store/auth';
import { useAppSelector } from '@store/hooks';

export function PostJobRouter() {
  const {
    isAuthenticated,
    currentUser: { emailVerified }
  } = useAppSelector(getUser);

  const isAuthenticatedAndEmailVerified = isAuthenticated && emailVerified;

  if (isAuthenticatedAndEmailVerified) {
    return <PostJob />;
  }

  return <SignUp />;
}
