/* eslint css-modules/no-unused-class: [2, { markAsUsed: ['backgroundColor-white', 'backgroundColor-grey', 'backgroundColor-green'] }] */
import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CloseIcon from '../Icons/CloseIcon';

import styles from './CloseCross.scss';

export default class CloseCross extends Component {
  static propTypes = {
    close: PropTypes.func.isRequired,
    'data-test-key': PropTypes.string,
    className: PropTypes.string,
    iconStyles: PropTypes.string,
    preventDefault: PropTypes.bool,
    insideCard: PropTypes.bool,
    isSmall: PropTypes.bool,
    backgroundColor: PropTypes.oneOf(['white', 'grey', 'green'])
  };

  static defaultProps = {
    backgroundColor: 'grey'
  };

  clickEvent(e) {
    const { close } = this.props;

    if (e && e.preventDefault) {
      e.preventDefault();
    }

    close();
  }

  render() {
    const { insideCard, isSmall, backgroundColor, className, 'data-test-key': dataTestKey, close, preventDefault, iconStyles } = this.props;

    return (
      <button
        aria-label="close"
        onClick={preventDefault ? this.clickEvent.bind(this) : close}
        className={classNames(styles.root, styles[`backgroundColor-${backgroundColor}`], {
          [styles.insideCard]: insideCard,
          [styles.insideSmallCard]: isSmall
        }, className)}
        data-test-key={dataTestKey || 'close-cross'}>
        <CloseIcon className={classNames(styles.icon, iconStyles)} />
      </button>
    );
  }
}