import React from 'react';
import { LiveOrExpired, isExpired, isLive } from '@src/job';
import { ExpiredExpireInformation, LiveExpireInformation } from './components';

type Props = {
  expiredAt: string;
  status: LiveOrExpired;
};

export const ExpiryInformation = ({ expiredAt, status }: Props) => {
  if (isLive(status)) {
    return <LiveExpireInformation expiredAt={expiredAt} />;
  }

  if (isExpired(status)) {
    return <ExpiredExpireInformation expiredAt={expiredAt} />;
  }

  return null;
};
