import { isJobClosedOrExpired } from '@common/helpers/jobs';
import { useRedirect } from '@common/hooks/useRedirect';
import { getTotalApplicationCount } from '@store/entities/applications';
import { getJobById } from '@store/entities/jobs';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';

export function useRepostJobContainer(jobId: string) {
  const { redirect } = useRedirect();
  const { countryCode } = useAppSelector(getLocalisation);
  const { status } = useAppSelector(getJobById(jobId));
  const totalApplicationCount =
    useAppSelector(getTotalApplicationCount(jobId)) || 0;

  const shouldRenderRepostJobContainer =
    isJobClosedOrExpired(status) && totalApplicationCount < 1;

  return {
    shouldRenderRepostJobContainer,
    redirectToRepostPage: redirect(`/${countryCode}/jobs/${jobId}/repost-job`),
    redirectToHomePage: redirect(`/${countryCode}/`)
  };
}
