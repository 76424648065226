import React from 'react';
import { Pressable, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useAppDownloadReminderBanner } from '@common/components/AppDownloadReminderBanner/hooks';
import { Box } from '@components/Box';
import { Heading } from '@components/Heading';
import { AppStoreLogo, PlayStoreLogo } from '@components/Logo';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { useTheme } from '@theme/DesignSystemProvider/ThemeContext';
import { useResolveToken } from '@theme/tokens/utils/useResolveToken';
import { TileText } from '../TileText';
import { ShowAppLandingIllustration } from './components';

const TriangleCorner = ({ tone }) => {
  const {
    banner: { backgroundColor }
  } = useTheme();
  const { resolveToken } = useResolveToken();
  const gradientStyle = {
    backgroundColor: 'transparent',
    borderRightWidth: 100,
    borderTopWidth: 240,
    borderTopColor: 'transparent',
    borderRightColor: resolveToken(backgroundColor, tone)
  };

  return <View style={{ ...gradientStyle }} />;
};

export const AppInfoTile = () => {
  const { t } = useTranslation();
  const { onPressAppStore } = useAppDownloadReminderBanner();

  return (
    <Stack
      direction={{
        mobile: 'column-reverse',
        tablet: 'row',
        desktop: 'row'
      }}
    >
      <Row
        paddingLeft="xlarge"
        paddingVertical="large"
        paddingRight={{
          mobile: 'xlarge',
          desktop: 'xxsmall',
          tablet: 'xxsmall'
        }}
        width={{
          mobile: 'full',
          tablet: '1/2',
          desktop: '1/2'
        }}
      >
        <Stack spacing="medium" flexShrink={1}>
          <Box
            paddingLeft={{
              mobile: 'none',
              tablet: 'medium',
              desktop: 'medium'
            }}
          >
            <Heading level="h2" weight="medium">
              {t('employerLandingPage.appInfoTile.heading')}
            </Heading>
            <TileText>
              {t('employerLandingPage.appInfoTile.description')}
            </TileText>

            <Row spacing="medium" marginTop="medium">
              <Pressable onPress={() => onPressAppStore('ios')}>
                <AppStoreLogo width="medium" />
              </Pressable>

              <Pressable onPress={() => onPressAppStore('android')}>
                <PlayStoreLogo width="medium" />
              </Pressable>
            </Row>
          </Box>
        </Stack>
      </Row>

      <Row
        width={{
          mobile: 'full',
          tablet: '1/2',
          desktop: '1/2'
        }}
        justifyContent="flex-end"
      >
        <Row
          width="1/3"
          justifyContent="flex-end"
          visible={{
            desktop: true,
            tablet: true,
            mobile: false
          }}
        >
          <TriangleCorner tone="success" />
        </Row>

        <Row
          tone="success"
          alignItems="flex-end"
          justifyContent="center"
          width={{
            desktop: '2/3',
            tablet: '2/3',
            mobile: 'full'
          }}
        >
          <Stack tone="transparent" width="full">
            <ShowAppLandingIllustration />
          </Stack>
        </Row>
      </Row>
    </Stack>
  );
};
