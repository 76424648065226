import { createContext } from 'react';
import type { Control, FieldErrorsImpl } from 'react-hook-form';
import type { TFunction } from 'react-i18next';
import { VerifyAccountDetailsFormValues } from './types';

export type T = TFunction<'translation', undefined>;

interface FormContextProps {
  control: Control<VerifyAccountDetailsFormValues>;
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
  t: T;
}

export const FormContext = createContext<FormContextProps>({
  control: {} as Control<VerifyAccountDetailsFormValues>,
  errors: {},
  t: () => ''
});
