import { REGEX } from '@common/constants/data';
import { JOB_TITLE_MAX_LENGTH, MAX_LENGTH } from '@common/constants/validation';
import type { T } from '@src/caja/Form/types';

/**
 * Generates validation rules for the job title field.
 * @param t - The translation function.
 * @returns The validation rules object.
 */
export function jobTitleRules(t: T) {
  return {
    required: t('validations.job.title.required'),
    [MAX_LENGTH]: {
      value: JOB_TITLE_MAX_LENGTH,
      message: t('validations.job.title.maxLength', {
        max: JOB_TITLE_MAX_LENGTH
      })
    },
    pattern: {
      value: REGEX.CONTAINS_ANGLE_BRACKET,
      message: t('validations.job.title.noAngleBracket')
    }
  };
}
