import { DIALLING_CODE_OPTIONS } from './data';

function findKeyValueInArray(key, array) {
  const arrayItem = array.find((opt) => opt.key === key.toString());

  return arrayItem ? arrayItem.value : '';
}

export function mapCountryCodeToDiallingCode(countryCode = '') {
  return findKeyValueInArray(countryCode, DIALLING_CODE_OPTIONS);
}

export function mapBusinessToBusinessFormValues(business, currentUserId) {
  if (!business?.users) {
    return;
  }

  const businessUser = business.users.find(
    ({ userId }) => userId === currentUserId
  );

  return {
    id: business.id,
    name: business.name,
    businessSize: business.businessSize,
    industryId: business.industryId,
    industryOtherDescription: business.industryOtherDescription,
    businessAddress: business.locations[0],
    country: business.locations[0].countryCode.toLowerCase(),
    website: business.website,
    role: businessUser.role,
    recruitmentCompanyName: businessUser.recruitmentCompanyName,
    businessRegistrationNumber: business.businessRegistrationNumber
  };
}

export function mapBusinessToVerifyAccountDetails(business) {
  if (!business) {
    return;
  }

  return {
    website: business.website,
    businessRegistrationNumber: business.businessRegistrationNumber
  };
}
