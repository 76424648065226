import { EventTypes } from 'redux-segment';

export const TRACK_JOB_TITLE_CONTACT_US_CLICKED =
  'jora-employer/entities/jobs/TRACK_JOB_TITLE_CONTACT_US_CLICKED';

export function trackJobTitleContactUsLinkoutClick() {
  return {
    type: TRACK_JOB_TITLE_CONTACT_US_CLICKED,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'Job title contact us button clicked',
          parameters: {
            type: 'select-job-title'
          }
        }
      }
    }
  };
}
