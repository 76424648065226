import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { useBrandDomain, useBrandName } from '@common/hooks/localisation';
import { Button } from '@components/Button';
import { Stack } from '@components/Stack';
import { FormContext } from './FormContext';
import {
  BusinessAdditionalInformation,
  BusinessRegistrationNumber,
  BusinessWebsite
} from './components';
import {
  useGetBusinessRegistrationFieldLabel,
  useVerifyAccountDetailsForm
} from './hooks';
import { VerifyAccountDetailsFormProps } from './types';

export const VerifyAccountDetailsForm = ({
  submitAccountAppeal,
  defaultValues,
  isLoading
}: VerifyAccountDetailsFormProps) => {
  const { t } = useTranslation();
  const brandDomain = useBrandDomain();
  const brandName = useBrandName();
  const { errors, control, handleSubmit, isValid, isSubmitted } =
    useVerifyAccountDetailsForm(defaultValues);
  const { label: businessRegistrationLabel } =
    useGetBusinessRegistrationFieldLabel();

  return (
    <FormContext.Provider value={{ control, errors, t }}>
      <Stack spacing="large" marginTop="medium">
        <BusinessRegistrationNumber
          label={`1. ${businessRegistrationLabel} *`}
        />

        <BusinessWebsite
          label={`2. ${t('verifyAccountDetailsForm.fieldLabel.website')} *`}
          helperText={t('verifyAccountDetailsForm.fieldHelpText.website', {
            returnObjects: true,
            brandDomain,
            brand: brandName.toLowerCase()
          })}
        />

        <BusinessAdditionalInformation
          label={`3. ${t(
            'verifyAccountDetailsForm.fieldLabel.additionalInfo'
          )}`}
        />

        <Button
          onPress={handleSubmit(submitAccountAppeal)}
          disabled={isLoading}
          testID="verify-account-details-submit"
        >
          {t('common.action.submit')}
        </Button>

        {isSubmitted && !isValid ? (
          <ErrorMessage>{t('validations.general.summary')}</ErrorMessage>
        ) : null}
      </Stack>
    </FormContext.Provider>
  );
};
