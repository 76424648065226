import React, { ComponentType, forwardRef } from 'react';
import { View, ViewProps } from 'react-native';
import { AccessibilityProps } from 'react-native/Libraries/Components/View/ViewAccessibility';
import Svg from 'svgs';
import { IconStyleProps, useIconStyle } from './useIconStyle';

export type IconProps = {
  accessibilityLabel?: AccessibilityProps['accessibilityLabel'];
  testID?: ViewProps['testID'];
} & IconStyleProps;

export type VectorComponentProps = {
  foregroundColor?: string;
  backgroundColor?: string;
};

export function createIcon({
  viewBox,
  vectorComponent: Vector
}: {
  viewBox: string;
  vectorComponent: ComponentType<VectorComponentProps>;
}) {
  return forwardRef<View, IconProps>((props, ref) => {
    const { accessibilityLabel, testID, ...restProps } = props;
    const { containerStyle, imageStyle } = useIconStyle(restProps);

    return (
      <View
        accessibilityLabel={accessibilityLabel}
        style={containerStyle}
        testID={testID}
      >
        <Svg viewBox={viewBox} {...imageStyle} ref={ref}>
          <Vector
            foregroundColor={imageStyle.stroke}
            backgroundColor={imageStyle.fill}
          />
        </Svg>
      </View>
    );
  });
}
