import { useState } from 'react';
import { Suggestion } from '@components/FormElements/AutoSuggest';
import useTitleSuggestions from './useTitleSuggestions';

export default function useJobTitleAutoSuggest() {
  const [suggestions, setSuggestions] = useState<Suggestion[] | []>([]);
  const { getSuggestions } = useTitleSuggestions();

  function updateSuggestions(input: string) {
    const suggestionResults = getSuggestions(input);
    setSuggestions(suggestionResults);
  }

  function onFetchSuggestions(input: string) {
    if (input.length >= 2) {
      updateSuggestions(input);
    } else {
      setSuggestions([]);
    }
  }

  function toAutoSuggestValue(
    value: { jobTitle: string; role: string } | undefined
  ): Suggestion | undefined {
    if (!value) return;

    return {
      displayText: value.jobTitle,
      data: value
    };
  }

  return {
    suggestions,
    updateSuggestions,
    onFetchSuggestions,
    toAutoSuggestValue
  };
}
