import { useForm } from 'react-hook-form';
import { VerifyAccountDetailsFormProps } from '../types';

export const useVerifyAccountDetailsForm = (
  defaultValues: VerifyAccountDetailsFormProps['defaultValues']
) => {
  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitted, errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues
  });

  return {
    errors,
    control,
    handleSubmit,
    isValid,
    isSubmitted
  };
};
