import React, { ComponentType, forwardRef } from 'react';
import { View, ViewProps } from 'react-native';
import { AccessibilityProps } from 'react-native/Libraries/Components/View/ViewAccessibility';
import Svg from 'svgs';
import { LogoStyleProps, useLogoStyle } from './useLogoStyle';

export type LogoProps = {
  accessibilityLabel?: AccessibilityProps['accessibilityLabel'];
  testID?: ViewProps['testID'];
  title?: string;
} & LogoStyleProps;

export type VectorComponentProps = {
  foregroundColor?: string;
  backgroundColor?: string;
};

export function createLogo({
  viewBox,
  vectorComponent: Vector
}: {
  viewBox: string;
  vectorComponent: ComponentType<VectorComponentProps>;
}) {
  return forwardRef<View, LogoProps>((props, ref) => {
    const { accessibilityLabel, testID, title, ...restProps } = props;
    const resolvedStyle = useLogoStyle({
      ...restProps
    });

    return (
      <View
        accessibilityLabel={accessibilityLabel}
        style={{
          width: resolvedStyle.width,
          height: resolvedStyle.height
        }}
        testID={testID}
      >
        <Svg title={title} viewBox={viewBox} {...resolvedStyle} ref={ref}>
          <Vector backgroundColor={resolvedStyle.backgroundColor} />
        </Svg>
      </View>
    );
  });
}
