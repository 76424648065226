import React, { ReactElement } from 'react';
import {
  ProvidedStyle,
  StyleProvider
} from '@components/FormElements/StyleProvider';

type ElementStyleProviderProps = {
  children: ({
    defaultStyle,
    errorStyle,
    focusStyle
  }: ChildrenProps) => ReactElement;
};

type ChildrenProps = {
  defaultStyle: ProvidedStyle;
  errorStyle: ProvidedStyle;
  focusStyle: ProvidedStyle;
};

export const ElementStyleProvider = ({
  children
}: ElementStyleProviderProps) => {
  return (
    <StyleProvider fontSize="standard" height="xxxsmall" tone="neutral">
      {({ style: defaultStyle }) => (
        <StyleProvider tone="danger" fontSize="small">
          {({ style: errorStyle }) => (
            <StyleProvider focused>
              {({ style: focusStyle }) =>
                children({ defaultStyle, errorStyle, focusStyle })
              }
            </StyleProvider>
          )}
        </StyleProvider>
      )}
    </StyleProvider>
  );
};
