import { useEffect } from 'react';
import { useGetJob } from '@common/hooks/job';
import {
  useFetchAdPrices,
  useGetCoinPricing,
  useGetCurrencyPricing
} from '@common/hooks/payment';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';

/**
 * Custom hook that loads job data and ad prices based on the provided job ID.
 * @param id - The ID of the job.
 * @returns An object containing the job data and the country code of the job.
 */
export const useLoadJobAndAdPrices = (id: string) => {
  const { job, getJobData } = useGetJob(id);
  const jobCountryCode = job?.countryCode as SupportedCountryCode;
  const { getAdPrices } = useFetchAdPrices();
  const { currencyPrice } = useGetCurrencyPricing(jobCountryCode);
  const { coinPrice } = useGetCoinPricing(jobCountryCode);

  useEffect(() => {
    if (job) {
      getAdPrices(jobCountryCode);
    } else getJobData();
  }, [job, getJobData, getAdPrices, jobCountryCode]);

  return { job, jobCountryCode, currencyPrice, coinPrice };
};
