import React from 'react';
import { Trans as I18nextTrans, useTranslation } from 'react-i18next';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { useRedirectUnauthenticatedUser } from '@common/hooks/useRedirectUnauthenticatedUser';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';
import { Card, CardContent } from '@components/Card';
import { Heading } from '@components/Heading';
import { SuccessIcon } from '@components/Icon';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { TextLink } from '@components/TextLink';
import {
  useRedirectCandidateLinks,
  useRedirectUserWithoutSubmittedAppealStatus
} from './hooks';

export const VerifyAccountDetailsSuccess = () => {
  const { t } = useTranslation();
  const { countryCode } = useAppSelector(getLocalisation);
  const { redirectToEmployerFaq } = useRedirectCandidateLinks();

  useRedirectUnauthenticatedUser({ to: `/${countryCode}/login` });
  useRedirectUserWithoutSubmittedAppealStatus();

  return (
    <Stack tone="transparent">
      <JoraEmployerHelmet title={t('verifyAccountDetailsSuccessPage.title')} />

      <Stack spacing="medium" tone="transparent">
        <Card>
          <CardContent>
            <Stack alignItems="center" spacing="medium">
              <SuccessIcon
                size="xxxlarge"
                tone="success"
                variant="outline"
                testID="verify-account-details-success-icon"
              />

              <Heading level="h1" weight="regular" align="center">
                {t('verifyAccountDetailsSuccessPage.heading')}
              </Heading>
            </Stack>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Stack alignItems="center" spacing="medium">
              <Text
                tone="neutralLight"
                testID="verify-account-details-success-faq"
              >
                <I18nextTrans
                  i18nKey="verifyAccountDetailsSuccessPage.description.weAreVerifying"
                  components={{
                    TextLink: (
                      <TextLink
                        onPress={redirectToEmployerFaq}
                        testID="verify-account-details-success-faq-link"
                      />
                    )
                  }}
                />
              </Text>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </Stack>
  );
};
