import { createContext } from 'react';
import { DEFAULT_STEP_MAP } from '../steps';
import type { CajaContextProps } from '../types';

export const CajaContext = createContext<CajaContextProps>({
  loading: [false, () => {}],
  t: () => '',
  stepMap: DEFAULT_STEP_MAP,
  updateDebounce: 200,
  allowBusinessAndEmailChange: false
});
