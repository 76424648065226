import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeleteBusiness } from '@common/hooks/business';

type UseDeleteBusinessModalProps = {
  businessId: string;
  onDeleteBusinessSuccess: () => void;
};

export function useDeleteBusinessModal({
  businessId,
  onDeleteBusinessSuccess
}: UseDeleteBusinessModalProps) {
  const { t } = useTranslation();
  const { deleteBusiness, errorMessage, isSuccessful } = useDeleteBusiness();

  const errorText = errorMessage?.message
    ? t(errorMessage.message, errorMessage.properties)
    : '';

  useEffect(() => {
    if (isSuccessful) {
      onDeleteBusinessSuccess();
    }
  }, [isSuccessful, onDeleteBusinessSuccess]);

  const onDeleteBusinessPress = () => {
    deleteBusiness({
      businessId
    });
  };

  return {
    onDeleteBusinessPress,
    errorText
  };
}
