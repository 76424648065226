import React from 'react';
import {
  CustomBlockRenderer,
  TChildrenRenderer,
  TNode,
  TText
} from 'react-native-render-html';
import { Text } from '../Text';

type Context = {
  tag: string;
  index: number;
};

export const blockRenderer: CustomBlockRenderer = ({
  TDefaultRenderer,
  tnode,
  propsFromParent,
  ...props
}) => {
  const tag = tnode.tagName;
  const newContext = !!tag &&
    ['ul', 'ol'].includes(tag) && {
      tag,
      index: 0
    };

  const context = newContext || propsFromParent?.context;

  return (
    <TDefaultRenderer {...props} tnode={tnode}>
      {renderChildren(tnode.children, context)}
    </TDefaultRenderer>
  );
};

function renderChildren(children: readonly TNode[], context: Context) {
  let index = 0;

  return children.map((child, key) => {
    if (context && child.tagName === 'li') {
      context.index = index++;
    }
    return renderChild(key, child, context);
  });
}

function renderChild(key: number, child: TNode, context?: Context) {
  switch (child.type) {
    case 'text':
      return renderText(key, child, context);
    case 'phrasing':
      return <Text key={key}>{renderPhrasing(child.children, context)}</Text>;
    default:
      return (
        <TChildrenRenderer
          key={key}
          tchildren={[child]}
          disableMarginCollapsing={false}
          propsForChildren={{ context: { ...context } }}
        />
      );
  }
}

function renderPhrasing(children: readonly TNode[], context?: Context) {
  return children.map((child, index) => renderChild(index, child, context));
}

function renderText(index: number, text: TText, context?: Context) {
  const weight = text.tagName === 'strong' ? 'bold' : undefined;
  return (
    <Text weight={weight} key={index}>
      {`${getTextPrefix(context)}${text.data}`}
    </Text>
  );
}

function getTextPrefix(context?: Context) {
  switch (context?.tag) {
    case 'ul':
      return '• ';
    case 'ol':
      return `${context.index + 1}. `;
    default:
      return '';
  }
}
