import React, { forwardRef, useState } from 'react';
import {
  Pressable,
  PressableProps,
  Text as ReactNativeText,
  View
} from 'react-native';
import { Box } from '@components/Box';
import { useRadioButtonA11yProps } from './useRadioButtonA11yProps';
import {
  RadioButtonStyleProps,
  useRadioButtonStyle
} from './useRadioButtonStyle';

export type RadioButtonProps = RadioButtonStyleProps & {
  /**
   * The string or JSX element representing the text or content to display
   * alongside the RadioButton
   */
  label: string | React.ReactElement;
  /**
   * An optional function to be called when the RadioButton is pressed
   */
  onPress?: () => void;
  /**
   * An optional function to be called when the RadioButton receives focus
   */
  onFocus?: () => void;
  /**
   * An optional function to be called when the RadioButton loses focus
   */
  onBlur?: () => void;
  /**
   * An optional, unique identifier to be used for testing purposes
   */
  testID?: PressableProps['testID'];
};

export const RadioButton = forwardRef<View, RadioButtonProps>((props, ref) => {
  const {
    label,
    onPress,
    testID,
    checked,
    disabled,
    radioButtonAlign = 'left'
  } = props;
  const [focused, setFocus] = useState(false);

  const { innerStyle, outerStyle, pressableStyle, textStyle } =
    useRadioButtonStyle({
      disabled,
      checked,
      focused,
      radioButtonAlign
    });

  const a11yProps = useRadioButtonA11yProps({
    disabled,
    checked
  });

  const radioButtonIcon = (
    <View style={outerStyle}>
      <View style={innerStyle} />
    </View>
  );

  return (
    <Pressable
      {...a11yProps}
      disabled={disabled}
      onPress={onPress}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      testID={testID}
      ref={ref}
      style={pressableStyle}
    >
      {radioButtonAlign === 'left' ? radioButtonIcon : null}
      <Box flexShrink={1}>
        {typeof label === 'string' ? (
          <ReactNativeText style={textStyle}>{label}</ReactNativeText>
        ) : (
          label
        )}
      </Box>
      {radioButtonAlign === 'right' ? radioButtonIcon : null}
    </Pressable>
  );
});
