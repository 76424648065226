import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AlreadyHaveAccountLink } from '@caja/Form/forms/components';
import { CajaContext } from '@caja/context';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { RequestErrorMessage } from '@common/components/RequestErrorMessage';
import REQUEST_KEY from '@common/constants/requestKeys';
import { Stack } from '@components/Stack';
import { useListenForChanges } from '../hooks';
import { FormContext } from './FormContext';
import { errorMap } from './formErrorMap';
import { useRegistrationErrorHandler, useStartRecaptcha } from './hooks';
import { hydrateRegisterFormProps } from './props';
import {
  AgeVerification,
  AgreeToTerms,
  ConfirmEmail,
  ConfirmPassword,
  CookieConsent,
  Email,
  GivenName,
  MobileNumber,
  Password,
  Recaptcha,
  Submit,
  Surname
} from './topLevelFields';
import type { RecaptchaHandle } from './topLevelFields';
import {
  CompleteRegisterFormData,
  FormProps,
  IncompleteRegisterFormData
} from './types';

export default function RegisterForm(opts: FormProps) {
  // Can we move this into the formProps so that forms are more self-contained?
  const { updateDebounce, t } = useContext(CajaContext);
  const { isFieldError, setFieldLevelError, setGlobalFormError } =
    useRegistrationErrorHandler(errorMap);
  const props = hydrateRegisterFormProps(opts);
  const recaptchaRef = React.createRef<RecaptchaHandle>();
  const { onChange, onSubmit, defaultValues, brandNameText } = props;
  const { startRecaptcha } = useStartRecaptcha(recaptchaRef);

  const [hasFormError, setHasFormError] = useState(false);
  const [hasGlobalError, setHasGlobalError] = useState(false);

  const {
    handleSubmit,
    watch,
    control,
    getValues,
    setError,
    formState: { errors, isSubmitted, isValid }
  } = useForm<IncompleteRegisterFormData>({
    defaultValues,
    mode: 'onBlur'
  });

  function initiateRecaptcha() {
    startRecaptcha();
  }

  async function onRecaptchaVerifySuccess(recaptchaResponse: string) {
    const formData = getValues() as CompleteRegisterFormData;
    const response = await onSubmit({ ...formData, recaptchaResponse });

    if (response?.error) {
      if (isFieldError(response.error)) {
        setFieldLevelError({ error: response?.error, setError, t });
        setHasFormError(true);
        return;
      }

      setGlobalFormError(REQUEST_KEY.AUTH.REGISTER);
      setHasGlobalError(true);
      return;
    }

    setHasFormError(false);
    setHasGlobalError(false);
  }

  useListenForChanges({ watch, updateDebounce, onChange });

  const shouldShowFormError = hasFormError || (isSubmitted && !isValid);

  return (
    <FormContext.Provider value={{ control, errors, t }}>
      <Stack spacing="large">
        <GivenName />
        <Surname />
        <Email />
        <ConfirmEmail />
        <MobileNumber />
        <Password />
        <ConfirmPassword />
        {props.showCookieConsent && <CookieConsent />}
        {props.showAgeVerification && <AgeVerification />}
        <AgreeToTerms brandNameText={brandNameText} />
        <Submit onPress={handleSubmit(initiateRecaptcha)}>
          {t('common.action.next')}
        </Submit>
        {shouldShowFormError && (
          <ErrorMessage>{t('validations.general.summary')}</ErrorMessage>
        )}
        {hasGlobalError && (
          <RequestErrorMessage requestKey={REQUEST_KEY.AUTH.REGISTER} />
        )}
        <AlreadyHaveAccountLink />
      </Stack>

      <Recaptcha
        recaptchaRef={recaptchaRef}
        onVerify={onRecaptchaVerifySuccess}
      />
    </FormContext.Provider>
  );
}
