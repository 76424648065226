import { useHistory } from 'react-router-native';
import { AppealStatus } from '@seek/je-shared-data/lib/types/authentication';
import { getUser } from '@store/auth';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';

export const useRedirectUserWithoutRequestedAppealStatus = () => {
  const { countryCode } = useAppSelector(getLocalisation);
  const { currentUser } = useAppSelector(getUser);
  const history = useHistory();

  if (currentUser?.appealStatus !== AppealStatus.REQUESTED) {
    history.push(`/${countryCode}/home`);
  }
};
