import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <>
    <Path
      d="M9.5 17c-1.066-1.92-2-3.303-2-4.5 0-2.659 1.871-5 4.5-5s4.5 2.333 4.5 5c0 1.205-.926 2.58-2 4.5"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M14.63 17H9.37v1.645h5.26V17zM9.38 20.62h5.248M11 17v-4l.6.686a.515.515 0 00.8 0L13 13v4M12 5.627V3.4M8.518 7.113L6.98 5.578M15.11 7.24l1.564-1.55M4.38 9.153l1.833.863M19.63 9.153l-1.851.863"
      strokeWidth={0.75}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const LightbulbIcon = createIcon({
  viewBox: '3 3 18 18',
  vectorComponent
});
