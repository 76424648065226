import { trackApplicationFiltersModalOpened } from '@store/application_filters';
import { useAppDispatch } from '@store/hooks';

export function useTrackModalOpened({
  userIsViewingApplicantsType
}: {
  userIsViewingApplicantsType: 'matched' | 'other';
}) {
  const dispatch = useAppDispatch();
  const trackModalOpened = () =>
    dispatch(
      trackApplicationFiltersModalOpened({
        userIsViewingApplicantsType
      })
    );

  return trackModalOpened;
}
