import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';

export const useRegistrationProgressIndicator = () => {
  const { countryCode } = useAppSelector(getLocalisation);

  return {
    paths: {
      register: `/${countryCode}/register`,
      postJob: `/${countryCode}/post-job`,
      createBusiness: `/${countryCode}/create-business`
    }
  };
};
