import { ApplicationFilterValues } from '../../../../../types';
import { DEFAULT_FILTER_STATE, FilterForm } from './useFilterForm';

type UseApplyFilterProps = {
  setApplicationFilters: (filter: ApplicationFilterValues) => void;
  setFilterForm: (filter: FilterForm) => void;
  filterForm: ApplicationFilterValues;
};
export const useApplySelectedFilters = ({
  setApplicationFilters,
  setFilterForm,
  filterForm
}: UseApplyFilterProps) => {
  const applySelectedFilters = () => {
    setApplicationFilters(filterForm);
  };

  const clearAllFilters = () => {
    setFilterForm(DEFAULT_FILTER_STATE);
    setApplicationFilters(DEFAULT_FILTER_STATE);
  };

  return { applySelectedFilters, clearAllFilters };
};
