import React from 'react';
import superjson from 'superjson';
import { GENERAL_EXCEPTION } from '@seek/je-error-helper';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { TRPCClientError, httpLink } from '@trpc/client';
import { trpcBff } from '@trpcBff';
import { useCustomHeaders } from './hooks';

const queryClient = new QueryClient();

type Props = {
  children: React.ReactNode;
  endpoint: string;
};

export const BffProvider = ({ children, endpoint }: Props) => {
  const customHeaders = useCustomHeaders();

  const trpcClient = trpcBff.createClient({
    transformer: superjson,
    links: [
      httpLink({
        url: endpoint,
        fetch: async (url, options) => {
          try {
            const response = await fetch(url, {
              ...options,
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                ...options?.headers,
                ...customHeaders
              }
            });

            if (!response.ok) {
              const responseClone = response.clone();
              const result = await responseClone.json();

              if (result.message === GENERAL_EXCEPTION.UNVERIFIED_USER) {
                throw new TRPCClientError(result.message, { cause: result });
              }
            }

            return response;
          } catch (error) {
            console.warn('Failed to fetch bff:', error);

            throw new TRPCClientError('Failed to fetch bff', {
              cause: error as any
            });
          }
        }
      })
    ]
  });

  return (
    <trpcBff.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpcBff.Provider>
  );
};
