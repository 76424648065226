/* eslint css-modules/no-unused-class: [2, { markAsUsed: ['default', 'success', 'warning', 'danger', 'loading', 'smallBoxShadow', 'mediumBoxShadow', 'largeBoxShadow'] }] */
import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Card.scss';
import omit from 'lodash.omit';
import { Link } from 'react-router-dom';

/**
 * @deprecated Use the @components/Card instead.
 **/
export default class Card extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    href: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        to: PropTypes.string
      })
    ]),
    removeBottomSpacing: PropTypes.bool,
    extraTopMargin: PropTypes.bool,
    extraBottomMargin: PropTypes.bool,
    withRef: PropTypes.func,
    transparent: PropTypes.bool,
    regularSpacing: PropTypes.bool,
    onClick: PropTypes.func,
    withBorder: PropTypes.bool,
    theme: PropTypes.string,
    boxShadow: PropTypes.oneOf(['small', 'medium', 'large']),
    centered: PropTypes.bool
  };

  render() {
    const {
      children,
      className,
      href,
      extraTopMargin,
      extraBottomMargin,
      removeBottomSpacing,
      withRef,
      transparent,
      regularSpacing,
      onClick,
      withBorder,
      theme,
      boxShadow,
      centered
    } = this.props;
    const propsToSpread = omit(
      this.props,
      'href',
      'extraTopMargin',
      'extraBottomMargin',
      'removeBottomSpacing',
      'withRef',
      'transparent',
      'regularSpacing',
      'withBorder',
      'theme',
      'boxShadow',
      'centered'
    );
    const fullClassName = classNames(
      removeBottomSpacing ? styles.removeBottomSpacing : styles.root,
      {
        [styles.centered]: centered,
        [styles.extraTopMargin]: extraTopMargin,
        [styles.extraBottomMargin]: extraBottomMargin,
        [className]: className,
        [styles.transparent]: transparent,
        [styles.regularSpacing]: regularSpacing,
        [styles.withBorder]: withBorder
      },
      theme && styles[theme],
      boxShadow && styles[`${boxShadow}BoxShadow`]
    );

    if (href) {
      return (
        <Link to={href} {...propsToSpread}
          onClick={onClick}
          className={classNames(fullClassName, styles.clickable)}>
          {children}
        </Link>
      );
    }
    return (
      <div {...propsToSpread}
        onClick={onClick}
        className={classNames(fullClassName, { [styles.clickable]: onClick })}
        ref={withRef}>
        {children}
      </div>
    );
  }
}
