import { ReactElement } from 'react';

export type CardStyleProps = {
  leftAdornment?: ReactElement;
};

export function useCardStyle({ leftAdornment }: CardStyleProps) {
  const borderLeftWidth = leftAdornment ? ('none' as const) : undefined;

  return {
    borderLeftWidth
  };
}
