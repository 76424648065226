import URL from 'url';
import { getDeviceUserAgent } from '@common/helpers/deviceInfo';
import { GENERAL_EXCEPTION } from '@seek/je-error-helper';
import { userUnverifiedAllowAccess } from '@seek/je-shared-data';
import {
  endLoading,
  startLoading,
  updateError,
  updateSubmissionError,
  updateSuccess
} from '../../../joraEmployer/store/app/actions';
import { refresh } from '../../../joraEmployer/store/navigation/actions';
import { ClientError, ErrorPreventLogging } from '../../helpers/errors';
import renameKeys from '../../helpers/renameKeys';

function validateImpersonatorAccess(disallowAccess, requestPath) {
  const { services: disallowedServices = [] } = disallowAccess;
  return disallowedServices.some((value) => {
    return requestPath.startsWith(value);
  });
}

function isUserNotAllowedToSendRequest(getState, requestPath) {
  const { isVerified, appealStatus } = getState().auth.currentUser || {};
  const isLocalDev = getState().config.isLocalDev;

  if (isLocalDev) {
    return false;
  }

  const unverifiedUserAllowedService =
    userUnverifiedAllowAccess[appealStatus] || [];

  if (isVerified === false) {
    return !unverifiedUserAllowedService.some((value) => {
      const regexp = new RegExp(`${value}`);
      return regexp.test(requestPath);
    });
  }

  return false;
}

function refreshApp(dispatch) {
  dispatch(refresh());
}

export default function fetchInterceptor(fetch, dispatch, getState) {
  return async (url, fetchConfig, interceptorConfig = {}) => {
    const { impersonatorToken } = getState().auth.currentUser || {};
    const { localisation } = getState();
    const disallowAccess =
      impersonatorToken && impersonatorToken.disallowAccess;
    const resourcePath = URL.parse(url).pathname;

    const { headers = {} } = fetchConfig;

    const {
      showGlobalSpinner = false,
      shouldLoading = true, // remove if unit test for component did mount issue resolve https://github.com/SEEK-Jobs/je-ui/pull/410
      requestKey,
      defaultError = 'errors.defaultWithGuid',
      mapErrorContext,
      mapFormError = {},
      onFetchError,
      onResponseError
    } = interceptorConfig;

    try {
      if (shouldLoading) {
        startLoading(dispatch, requestKey, showGlobalSpinner);
      }

      if (
        disallowAccess &&
        validateImpersonatorAccess(disallowAccess, resourcePath)
      ) {
        throw new ErrorPreventLogging('errors.default');
      }

      if (isUserNotAllowedToSendRequest(getState, resourcePath)) {
        refreshApp(dispatch);
        throw new ErrorPreventLogging('errors.default');
      }

      let response;
      try {
        const deviceUserAgent = await getDeviceUserAgent(headers['User-Agent']);
        const userAgentHeader = deviceUserAgent
          ? { 'User-Agent': deviceUserAgent }
          : {};
        const localisationHeaders = {
          'x-country-code': localisation.countryCode,
          'x-brand': localisation.brandConfig.name
        };

        response = await fetch(url, {
          ...fetchConfig,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...headers,
            ...userAgentHeader,
            ...localisationHeaders
          }
        });
      } catch (error) {
        if (typeof onFetchError === 'function') {
          onFetchError(error, dispatch, getState);
        }

        throw error;
      }

      let body = {};
      try {
        body = await response.json();
      } catch (err) {
        // ignore if response body is empty
      }

      if (!response.ok) {
        if (
          body.code === GENERAL_EXCEPTION.ACCESS_DENIED &&
          body.message === GENERAL_EXCEPTION.UNVERIFIED_USER
        ) {
          refreshApp(dispatch);
        }

        if (body.context) {
          const context = mapErrorContext
            ? renameKeys(body.context, mapErrorContext)
            : body.context;
          body.context = context;
          updateSubmissionError(dispatch, requestKey, context);
        }

        if (typeof onResponseError === 'function') {
          onResponseError(body, dispatch, getState);
        }

        const errorMsgExcludedFromLogging =
          body.context || mapFormError[body.code];

        if (errorMsgExcludedFromLogging) {
          throw new ErrorPreventLogging(
            errorMsgExcludedFromLogging,
            body.guid,
            body.context
          );
        }

        throw new ClientError(defaultError, {
          guid: body.guid,
          responseBody: body
        });
      }

      updateSuccess(dispatch, requestKey);
      return Promise.resolve(body);
    } catch (error) {
      updateError(dispatch, requestKey, error);
      return Promise.reject(error);
    } finally {
      if (shouldLoading) {
        endLoading(dispatch, requestKey, showGlobalSpinner);
      }
    }
  };
}
