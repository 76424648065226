import { ProfileApplication } from 'je-bff/src/applications/list/transformers';

export const sortByDistance = (
  applicationA: ProfileApplication,
  applicationB: ProfileApplication
) => {
  const currentApplication = applicationA.distanceFromJobKm;
  const nextApplication = applicationB.distanceFromJobKm;

  if (currentApplication === undefined || nextApplication === undefined) {
    if (currentApplication === undefined && nextApplication !== undefined) {
      return 1;
    }
    if (currentApplication !== undefined && nextApplication === undefined) {
      return -1;
    }

    return 0;
  }

  return currentApplication - nextApplication;
};
