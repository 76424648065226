import React, { ComponentType, forwardRef } from 'react';
import { View, ViewProps } from 'react-native';
import { AccessibilityProps } from 'react-native/Libraries/Components/View/ViewAccessibility';
import Svg from 'svgs';
import {
  IllustratedIconStyleProps,
  useIllustratedIconStyle
} from './useIllustratedIconStyle';

export type SimpleIconProps = {
  accessibilityLabel?: AccessibilityProps['accessibilityLabel'];
  testID?: ViewProps['testID'];
  title?: string;
} & IllustratedIconStyleProps;

export type VectorComponentProps = {
  foregroundColor?: string;
  backgroundColor?: string;
};

export function createIllustratedIcon({
  viewBox,
  vectorComponent: Vector
}: {
  viewBox: string;
  vectorComponent: ComponentType<VectorComponentProps>;
}) {
  return forwardRef<View, SimpleIconProps>((props, ref) => {
    const { accessibilityLabel, testID, title, ...restProps } = props;
    const resolvedStyle = useIllustratedIconStyle(restProps);

    return (
      <View
        accessibilityLabel={accessibilityLabel}
        style={{
          width: resolvedStyle.width,
          height: resolvedStyle.height
        }}
        testID={testID}
      >
        <Svg title={title} viewBox={viewBox} {...resolvedStyle} ref={ref}>
          <Vector />
        </Svg>
      </View>
    );
  });
}
