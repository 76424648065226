import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <Path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M 7.931 10 H 16.069 A 0.8 0.8 180 0 1 16.634 11.366 L 12.566 15.434 A 0.8 0.8 180 0 1 11.434 15.434 L 7.366 11.366 A 0.8 0.8 180 0 1 7.93 10"
  />
);

export const DropdownDownIcon = createIcon({
  viewBox: '7 4 18 18',
  vectorComponent
});
