import * as validation from '../../../constants/validation';
import { isValidWebsiteUrl } from '../../../helpers/validation';
import { BUSINESS_ROLE, INDUSTRY } from '@seek/je-shared-data';
import { hasAngleBracket } from '../../../helpers/validation';

function isRequired(fieldValue) {
  const isTextFieldEmpty = typeof fieldValue === 'string' ? !fieldValue.trim() : false;

  return !fieldValue || isTextFieldEmpty;
}

export function validateCreateBusinessForm(data) {
  const errors = {};
  const requiredFields = [
    'name',
    'country',
    'businessAddress',
    'industryId',
    'businessSize',
    'role'
  ];
  const mustProvideRecruitmentCompanyName = (data.role && data.role === BUSINESS_ROLE.RECRUITER);
  const mustProvideIndustryOtherDescription = (data.industryId && data.industryId === INDUSTRY.OTHER);

  if (data.website) {
    if (!isValidWebsiteUrl(data.website.trim())) {
      errors.website = validation.FORMAT_VALIDATION_ERROR;
    }

    if (data.website.length > validation.WEBSITE_URL_MAX_LENGTH) {
      errors.website = validation.MAX_LENGTH;
    }

    if (hasAngleBracket(data.website)) {
      errors.website = validation.NO_ANGLE_BRACKET;
    }
  }

  if (data.businessRegistrationNumber) {
    if (data.businessRegistrationNumber.length > validation.BUSINESS_REGISTRATION_NUMBER_MAX_LENGTH) {
      errors.businessRegistrationNumber = validation.MAX_LENGTH;
    }
  }

  if (data.name) {
    if (data.name.length > validation.BUSINESS_NAME_MAX_LENGTH) {
      errors.name = validation.MAX_LENGTH;
    }

    if (data.name.length < validation.BUSINESS_NAME_MIN_LENGTH) {
      errors.name = validation.MIN_LENGTH;
    }

    if (hasAngleBracket(data.name)) {
      errors.name = validation.NO_ANGLE_BRACKET;
    }
  }

  if (mustProvideRecruitmentCompanyName) {
    if (data.recruitmentCompanyName) {
      if (data.recruitmentCompanyName.length > validation.RECRUITMENT_COMPANY_NAME_MAX_LENGTH) {
        errors.recruitmentCompanyName = validation.MAX_LENGTH;
      }

      if (data.recruitmentCompanyName.length < validation.RECRUITMENT_COMPANY_NAME_MIN_LENGTH) {
        errors.recruitmentCompanyName = validation.MIN_LENGTH;
      }

      if (hasAngleBracket(data.recruitmentCompanyName)) {
        errors.recruitmentCompanyName = validation.NO_ANGLE_BRACKET;
      }
    }
  }

  if (mustProvideIndustryOtherDescription) {
    if (data.industryOtherDescription) {
      if (data.industryOtherDescription.length < validation.INDUSTRY_OTHER_DESCRIPTION_MIN_LENGTH) {
        errors.industryOtherDescription = validation.MIN_LENGTH;
      }
    }
  }

  if (!data.businessAddress || !data.businessAddress.streetAddress) {
    errors.businessAddress = validation.SELECT_SUGGESTION;
  }

  requiredFields.forEach(key => {
    const fieldValue = data[key];
    if (isRequired(fieldValue)) {
      errors[key] = validation.REQUIRED_VALIDATION_ERROR;
    }

    if (key === 'role' && mustProvideRecruitmentCompanyName) {
      if (isRequired(data.recruitmentCompanyName)) {
        errors.recruitmentCompanyName = validation.REQUIRED_VALIDATION_ERROR;
      }
    }

    if (key === 'industryId' && mustProvideIndustryOtherDescription) {
      if (isRequired(data.industryOtherDescription)) {
        errors.industryOtherDescription = validation.REQUIRED_VALIDATION_ERROR;
      }
    }
  });

  return errors;
}