import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetPostJobLink } from '@common/hooks/appPageLinks/useGetPostJobLink';
import { useRedirect } from '@common/hooks/useRedirect';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';
import { Box } from '@components/Box';
import { Button } from '@components/Button';
import { Card, CardContent } from '@components/Card';
import { Column } from '@components/Column';
import { Heading } from '@components/Heading';
import { Stack } from '@components/Stack';
import { TileText } from '../TileText';
import { ShowPostJobIllustration } from './components/ShowPostJobIllustration';

export const PostJobTile = () => {
  const { t } = useTranslation();
  const { brandNameText } = useAppSelector(getLocalisation);
  const { redirect } = useRedirect();
  const { postJobLink } = useGetPostJobLink();

  return (
    <Card>
      <CardContent>
        <Stack
          direction={{ mobile: 'column', tablet: 'row', desktop: 'row' }}
          spacing="large"
          paddingTop="medium"
        >
          <Column
            flexShrink={1}
            maxWidth={{ mobile: 'xxxlarge', tablet: '2/3', desktop: '2/3' }}
          >
            <Heading level="h2" weight="medium">
              {t('employerLandingPage.postJobTile.heading', { brandNameText })}
            </Heading>
            <TileText>
              {t('employerLandingPage.postJobTile.description')}
            </TileText>
            <Box marginTop="medium">
              <Button onPress={redirect(postJobLink)}>
                {t('employerLandingPage.postJobTile.postJob')}
              </Button>
            </Box>
          </Column>
          <Box
            marginLeft="medium"
            marginTop={{ mobile: 'large', tablet: 'none', desktop: 'none' }}
          >
            <ShowPostJobIllustration />
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};
