import { GroupedActions } from '../store';
import * as constants from './constants';
import { refreshClient } from './helpers';

export function refresh(): GroupedActions {
  return {
    types: [
      constants.REFRESH,
      constants.REFRESH_SUCCESS,
      constants.REFRESH_FAIL
    ],
    promise: async (dispatch, getState) => {
      const {
        router: { location }
      } = getState();

      if (location?.pathname) {
        await refreshClient({ url: location.pathname, dispatch, getState });
      }
    }
  };
}
