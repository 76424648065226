import React, { ReactNode, forwardRef } from 'react';
import { View, ViewProps } from 'react-native';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';

type CardActionProps = {
  children: ReactNode;
  testID?: ViewProps['testID'];
};

export const CardAction = forwardRef<View, CardActionProps>(
  ({ children, testID }: CardActionProps, ref) => {
    return (
      <Row
        flex={1}
        justifyContent="center"
        paddingVertical="medium"
        ref={ref}
        testID={testID}
      >
        <Stack width="full" alignItems="center">
          {children}
        </Stack>
      </Row>
    );
  }
);
