import { ApplicationStatus } from '@common/types';
import { FILTERS } from '../../../types';

type Filter = {
  [FILTERS.APPLICANT_STATUS]?: ApplicationStatus[];
};

type Item = {
  status?: ApplicationStatus;
};

export const filterApplicantStatus = <T extends Item>(
  filter: Filter,
  items: T[]
): T[] => {
  const filterValues = filter[FILTERS.APPLICANT_STATUS];

  if (!filterValues) {
    return items;
  }

  return items.filter(
    (item) => item.status && filterValues.includes(item.status)
  );
};
