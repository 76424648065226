import { trpcBff } from '@trpcBff';

type Response = ReturnType<typeof trpcBff.system.uiVersion.get.useQuery>;

export type ErrorResponse = Response['error'];

export const useFetchUiVersion = () => {
  const { data, isError, error } = trpcBff.system.uiVersion.get.useQuery(
    undefined,
    {
      staleTime: 300_000, // 5 minutes
      refetchOnWindowFocus: true,
      retry: false
    }
  );

  return {
    uiVersion: data,
    isError,
    error
  };
};
