import React, { useState } from 'react';
import { Offline } from 'react-detect-offline';
import { useTranslation } from 'react-i18next';
import { When } from '@common/components/When';
import { inNativeApp } from '@src/joraEmployer/whereAmIRunning';
import { Button } from '@components/Button';

export const UniversalOffline = () => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);

  function onRefresh() {
    setDisabled(true);
    setTimeout(() => {
      setDisabled(false);
    }, 2000);
  }
  return (
    <When condition={inNativeApp()}>
      <Offline polling={{ enabled: false }}>
        <div className="h-screen flex flex-col items-center justify-center absolute top-0 left-0 w-full bg-white z-[100002]">
          <div className="text-center">{t('errors.noNetwork.message')}</div>
          <div className="mt-4">
            <Button onPress={onRefresh} disabled={disabled}>
              {t('errors.noNetwork.actionText')}
            </Button>
          </div>
        </div>
      </Offline>
    </When>
  );
};
