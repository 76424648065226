import { GOOGLE_API_NOT_FOUND } from '../constants';

export function getPlacePredictions({ args: { searchTerm, countryCode } }, callback) {
  const googleMaps = (window.google && window.google.maps);
  if (!googleMaps) {
    return callback([], GOOGLE_API_NOT_FOUND);
  }

  const autoCompleteService = new googleMaps.places.AutocompleteService();
  return autoCompleteService.getPlacePredictions({
    input: searchTerm,
    componentRestrictions: {
      country: countryCode
    }
  }, callback);
}

export function getLocationDetails({ args: { placeId } }, callback) {
  const googleMaps = (window.google && window.google.maps);
  if (!googleMaps) {
    return callback([], GOOGLE_API_NOT_FOUND);
  }

  const geoCodeService = new googleMaps.Geocoder();
  return geoCodeService.geocode({
    placeId: placeId
  }, callback);
}