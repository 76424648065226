import { useEffect } from 'react';
import { t } from 'i18next';
import { UseFormSetError } from 'react-hook-form';
import REQUEST_KEYS from '@common/constants/requestKeys';
import {
  useGetForgotPasswordLink,
  useGetPostJobLink
} from '@common/hooks/appPageLinks';
import { useRegistrationEnabled } from '@common/hooks/featureToggles';
import { useSearchQueryParam } from '@common/hooks/useSearchQueryParam';
import { AUTH_EXCEPTION } from '@seek/je-error-helper';
import { getRequestState } from '@store/app';
import { login } from '@store/auth/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';

export const useShowFormError = () => {
  const { error } = useAppSelector(getRequestState(REQUEST_KEYS.AUTH.LOGIN));

  if (typeof error?.message === 'string') {
    return true;
  }

  // don't display a "form-level" error if these errors exist, as they are meant to be displayed as "field-level" errors
  return !(error?.message?.password || error?.message?.email);
};

// sets field-level errors for email and password based on form-level error result
export const useAuthenticationFieldErrors = (
  setError: UseLoginFormProps['setError']
) => {
  const { error: formLevelError } = useAppSelector(
    getRequestState(REQUEST_KEYS.AUTH.LOGIN)
  );

  useEffect(() => {
    const errorMessages = formLevelError?.message as {
      password: string;
      email: string;
    };
    const passwordError = errorMessages?.password;
    const emailError = errorMessages?.email;

    if (passwordError === AUTH_EXCEPTION.INVALID_LOGIN_CREDENTIALS) {
      setError('password', {
        message: t('errors.authentication.login.notAuthorizedException')
      });
    } else if (emailError === AUTH_EXCEPTION.ACCOUNT_SUSPENDED) {
      setError('email', {
        message: t('errors.authentication.login.accountSuspended')
      });
    } else if (emailError === AUTH_EXCEPTION.USER_NOT_FOUND) {
      setError('email', {
        message: t('errors.authentication.login.userNotFoundException')
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formLevelError]);
};

type SubmitLoginProps = {
  email: string;
  password: string;
};
export const useSubmitLogin = () => {
  const dispatch = useAppDispatch();
  const { getRawQueryParams } = useSearchQueryParam();

  const queryParams = getRawQueryParams();

  return async (props: SubmitLoginProps) => {
    const { email, password } = props;
    await dispatch(login({ email: email.trim(), password }, queryParams));
  };
};

export const usePostJobLinkWithQueryParams = () => {
  const { postJobLink } = useGetPostJobLink();
  const { getRawQueryParams } = useSearchQueryParam();
  const rawQueryParams = getRawQueryParams();

  return `${postJobLink}${rawQueryParams}`;
};

type UseLoginFormProps = {
  setError: UseFormSetError<{ email: string; password: string }>;
};
export const useLoginForm = ({ setError }: UseLoginFormProps) => {
  const isRegistrationEnabled = useRegistrationEnabled();
  const postJobLinkWithQueryParams = usePostJobLinkWithQueryParams();

  const forgotPasswordLink = useGetForgotPasswordLink();

  const submitLogin = useSubmitLogin();

  const showFormError = useShowFormError();

  useAuthenticationFieldErrors(setError);

  return {
    isRegistrationEnabled,
    postJobLinkWithQueryParams,
    forgotPasswordLink,
    showFormError,
    submitLogin
  };
};
