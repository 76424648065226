import { EventTypes } from 'redux-segment';
import { MODAL_OPENED, MODAL_CLOSED } from '../constants/modal';

export function modalOpened({ openTrackEventPayload }) {
  const meta = openTrackEventPayload && openTrackEventPayload.event ? {
    analytics: {
      eventType: EventTypes.track,
      eventPayload: openTrackEventPayload
    }
  } : {};

  return dispatch => {
    dispatch({
      type: MODAL_OPENED,
      meta
    });
  };
}

export function modalClosed() {
  return dispatch => {
    dispatch({
      type: MODAL_CLOSED
    });
  };
}