import { useGetPostJobLink } from '@common/hooks/appPageLinks/useGetPostJobLink';
import { useRedirect } from '@common/hooks/useRedirect';
import { BrandName } from '@seek/je-shared-data/lib/types/brand';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';

export function usePostJobSuccess() {
  const { countryCode, brandNameText, brandConfig } =
    useAppSelector(getLocalisation);
  const { redirect } = useRedirect();
  const { postJobLink } = useGetPostJobLink();

  const isRealTimeJobPostingBrand =
    brandConfig?.name &&
    [BrandName.JOBSTREETEXPRESS].includes(brandConfig.name);

  return {
    isRealTimeJobPostingBrand,
    brandNameText,
    redirectToPostJobPage: redirect(postJobLink),
    redirectToHomePage: redirect(`/${countryCode}/`)
  };
}
