import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <>
    <Path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="m.5 9.516 3.5-4 3 2 4-4 2 2 4-4.5"
    />
    <Path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M14.5.516h2.652c.203-.01.357.16.348.361v2.639"
    />
  </>
);

export const GraphLineIcon = createIcon({
  viewBox: '0 0 18 11',
  vectorComponent
});
