import { validateCountryCodeForBrand } from '@seek/je-shared-data';
import {
  BrandName,
  SupportLevel,
  SupportedCountryCode
} from '@seek/je-shared-data/lib/types/brand';
import { AppState } from '../store';

export function getSiteDomain(brand?: BrandName) {
  return (state: AppState) => brand && state.config.domain?.[brand];
}

export function getRegistrationEnabledStatus(countryCode?: string) {
  return (state: AppState) => {
    const disabled =
      countryCode &&
      state.config.featureToggles?.disableRegistration[countryCode];

    const isTier3Country =
      state.localisation?.supportTier === SupportLevel.TIER_3;

    return !isTier3Country && (disabled === undefined || !disabled);
  };
}

export function getMonetisationEnabledStatus(
  brand?: BrandName,
  jobCountryCode?: string
) {
  return (state: AppState) => {
    const enabled =
      jobCountryCode &&
      brand &&
      state.config.featureToggles?.monetisation[brand]?.[jobCountryCode];
    return enabled;
  };
}

export function getRegionSelectionEnabledStatus() {
  return (state: AppState) => {
    return state.config.featureToggles?.regionSelector;
  };
}

export function getFeatures() {
  return (state: AppState) => {
    return state.config.featureToggles;
  };
}

export function getLimitRolesEnabledStatus() {
  return (state: AppState) => {
    return state.config.featureToggles?.limitJobRoles;
  };
}

export function getDevTools() {
  return (state: AppState) => state.config.devTools;
}

export function getLimitedBusinessCountryCodes(brand: BrandName) {
  return (state: AppState) => {
    const supportedCountryCodes = Object.values(SupportedCountryCode);

    const isLimited =
      state.config.featureToggles?.limitBusinessCountryCodeForBrand?.[brand];

    if (!isLimited) {
      return supportedCountryCodes;
    }

    return supportedCountryCodes.filter((country) =>
      validateCountryCodeForBrand(brand, country)
    );
  };
}

export function getEnvironment() {
  return (state: AppState) => state.config.environment;
}

export function getSiteId() {
  return (state: AppState) => state.config.siteID;
}

export function getGoogleRecaptcha(state: AppState) {
  return state.config.googleRecaptcha;
}
