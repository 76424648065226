import React, { useContext } from 'react';
import { BusinessRegistration as InnerField } from '@common/components/BusinessForm/components/BusinessRegistration';
import { useBusinessRegistrationFieldName } from '@common/components/BusinessForm/components/BusinessRegistration/hooks/useBusinessRegistration';
import { FieldWrapper } from '../FieldWrapper';
import { FormContext } from '../FormContext';
import { businessRegistrationRules } from '../rules';

export default function BusinessRegistration() {
  const { t } = useContext(FormContext);
  const { businessRegistrationFieldName } = useBusinessRegistrationFieldName();
  return (
    <FieldWrapper<string>
      name="businessRegistrationNumber"
      rules={businessRegistrationRules(t, businessRegistrationFieldName)}
      render={(props) => <InnerField {...props} />}
    />
  );
}
