import { TFunction } from 'react-i18next';
import { useCreateJob } from './useCreateJob';

export const useJob = (t: TFunction) => {
  const createJob = useCreateJob(t);

  return {
    createJob
  };
};
