import { inNativeApp } from '@src/joraEmployer/whereAmIRunning';
import { ping } from '@src/nativeBridge/actions';

export async function getNativeVersionNumber(): Promise<string | null> {
  if (!inNativeApp()) return null;
  try {
    const response = await ping();
    if (!response || !response.success) return null;
    return response.system?.applicationVersion;
  } catch (error) {
    console.error('Failed to get native version number', error);
    return null;
  }
}
