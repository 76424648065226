import { isSameCaseInsensitive } from '@common/helpers/textHelper';
import { EditableBusinessInformationProps } from '../EditableBusinessInformation';

type UseBusinessInformationProps = {
  business: EditableBusinessInformationProps['selectedBusiness'];
};

export const useBusinessInformation = ({
  business
}: UseBusinessInformationProps) => {
  if (business === undefined) {
    return {
      shouldRenderRecruitmentInformation: false,
      locationText: ''
    };
  }

  const shouldRenderRecruitmentInformation = Boolean(
    business.recruitmentCompanyName &&
      !isSameCaseInsensitive(business.name, business.recruitmentCompanyName)
  );

  const locationText = `${business.geolocation.suburb}, ${business.geolocation.state}`;

  return {
    shouldRenderRecruitmentInformation,
    locationText
  };
};
