import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRedirect } from '@common/hooks/useRedirect';
import { TextLink } from '@components/TextLink';

type BasicAdLinkProps = {
  link: string;
  adDuration?: number;
};

/**
 * Renders a basic ad link component.
 *
 * @param link - The link to redirect to when the link is pressed.
 */
export const BasicAdLink = ({ adDuration, link }: BasicAdLinkProps) => {
  const { t } = useTranslation();
  const { redirect } = useRedirect();

  if (!adDuration) return null;

  return (
    <TextLink size="small" weight="regular" onPress={redirect(link)}>
      {t('upgradeJobForm.basic.continue', { duration: adDuration })}
    </TextLink>
  );
};
