import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAvailabilityDisplay } from '@common/hooks/useAvailibilityDisplay';
import { Availability, Day } from '@seek/je-shared-data/lib/types/jobs';
import { Column } from '@components/Column';
import { Checkbox } from '@components/FormElements';
import { Row } from '@components/Row';
import { Text } from '@components/Text';
import { OnCheckboxPressedType } from '../types';

type Props = {
  day: Day;
  onCheckboxPressed: (value: OnCheckboxPressedType) => void;
  availabilityOptions: Availability[];
  key?: string;
};

export const DailyShiftAvailabilityCheckboxes = ({
  key,
  onCheckboxPressed,
  availabilityOptions,
  day
}: Props) => {
  const { t } = useTranslation();
  const { availabilityLabel } = useAvailabilityDisplay();

  return (
    <Row
      width="full"
      marginTop="small"
      key={key}
      testID={`${day}-shift-availability-checkboxes`}
    >
      <Column width="1/4">
        <Text size="small">
          {t(`postJobForm.shiftAvailability.shiftDay.${day}`)}
        </Text>
      </Column>

      {availabilityLabel.map(({ value: availability }, index) => (
        <Column width="1/4" alignItems="center" key={`${key}-${index}`}>
          <Checkbox
            testID={`${day}-${availability}-checkbox`}
            onChange={(isSelected) =>
              onCheckboxPressed({
                day,
                availability,
                isSelected
              })
            }
            value={availabilityOptions.includes(availability)}
          />
        </Column>
      ))}
    </Row>
  );
};
