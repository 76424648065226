import React from 'react';
import { GivenName as InnerField } from '@common/components/Account/components/GivenName/GivenName';
import { givenName } from '@common/components/Account/rules';
import { FieldWrapper } from '../FieldWrapper';

export const GivenName = () => {
  return (
    <FieldWrapper<string>
      name="givenName"
      rules={givenName}
      render={(props) => {
        return <InnerField {...props} />;
      }}
    />
  );
};
