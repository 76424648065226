import React from 'react';
import { useTranslation } from 'react-i18next';
import { BusinessImageAndDetails } from '@common/components/BusinessImageAndDetails';
import { useGetEditBusinessLink } from '@common/hooks/appPageLinks';
import { useSiteCountryCode } from '@common/hooks/localisation';
import { useRedirect } from '@common/hooks/useRedirect';
import { Business } from '@common/types';
import { extractBusinessLocation } from '@src/business';
import { Card, CardAction, CardActionLink, CardFooter } from '@components/Card';
import { Heading } from '@components/Heading';
import { EditIcon, TrashIcon } from '@components/Icon';
import { useModal } from '@components/Modal';
import { Pressable } from '@components/Pressable';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { DeleteBusinessModal } from './components';

type BusinessCardProps = {
  business: Business;
  testID?: string;
};

export const BusinessCard = ({
  business: { id, name, businessSize, locations },
  testID
}: BusinessCardProps) => {
  const { t } = useTranslation();
  const { redirect } = useRedirect();
  const countryCode = useSiteCountryCode();
  const locationText = extractBusinessLocation(locations);
  const editBusinessLink = useGetEditBusinessLink({ id });
  const { isModalOpen, openModal, closeModal } = useModal();

  return (
    <Card testID={testID}>
      <Stack margin="xlarge">
        <Pressable
          onPress={redirect(`/${countryCode}/businesses/${id}/details`)}
        >
          <BusinessImageAndDetails businessSize={businessSize}>
            <Heading level="h2" weight="regular">
              {name}
            </Heading>
            <Text>{locationText}</Text>
          </BusinessImageAndDetails>
        </Pressable>
      </Stack>

      <CardFooter>
        <CardAction>
          <CardActionLink
            icon={<EditIcon variant="outline" testID="edit-icon" />}
            text={t('common.action.edit')}
            onPress={redirect(editBusinessLink)}
          />
        </CardAction>
        <CardAction>
          <DeleteBusinessModal
            businessName={name}
            businessId={id}
            visible={isModalOpen}
            onRequestClose={closeModal}
          />
          <CardActionLink
            icon={<TrashIcon variant="outline" testID="trash-icon" />}
            text={t('common.action.delete')}
            onPress={openModal}
          />
        </CardAction>
      </CardFooter>
    </Card>
  );
};
