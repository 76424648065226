import React from 'react';
import { ClipPath, Defs, G, LinearGradient, Path, Stop } from 'svgs';
import { createLogo } from '../createLogo';

const vectorComponent = () => (
  <>
    <G clipPath="url(#a)">
      <Path
        d="M34.498 25.405c0 .187-.005.59-.017.902-.027.763-.087 1.748-.179 2.197-.138.674-.345 1.31-.616 1.84a6.595 6.595 0 0 1-1.214 1.672 6.598 6.598 0 0 1-1.67 1.21c-.533.27-1.173.478-1.851.615-.444.09-1.422.15-2.18.176-.312.011-.716.017-.901.017L9.128 34.03c-.186 0-.59-.005-.902-.017-.763-.027-1.748-.088-2.196-.179-.674-.138-1.31-.345-1.84-.616a6.595 6.595 0 0 1-1.673-1.214 6.594 6.594 0 0 1-1.209-1.67c-.271-.533-.479-1.173-.616-1.851-.09-.444-.149-1.422-.176-2.18a30.658 30.658 0 0 1-.016-.901L.502 8.66c0-.186.006-.59.017-.902.027-.763.088-1.748.18-2.196.137-.674.344-1.31.615-1.84A6.596 6.596 0 0 1 2.528 2.05 6.598 6.598 0 0 1 4.198.841C4.732.57 5.372.362 6.05.225 6.494.135 7.471.076 8.23.05c.312-.01.716-.017.902-.017l16.741.003c.187 0 .59.006.902.017.763.027 1.748.088 2.197.18.674.137 1.31.344 1.84.615.627.32 1.188.729 1.672 1.214.484.484.89 1.045 1.21 1.67.27.533.478 1.173.615 1.851.09.445.15 1.422.176 2.18.011.312.017.716.017.902l-.003 16.742Z"
        fill="url(#b)"
      />
      <Path
        d="M26.508 8.077a12.115 12.115 0 0 0-8.625-3.575c-6.72 0-12.19 5.467-12.192 12.188-.001 2.148.56 4.245 1.627 6.093L5.588 29.1l6.464-1.695a12.187 12.187 0 0 0 5.826 1.483h.005c6.72 0 12.19-5.468 12.193-12.188a12.114 12.114 0 0 0-3.568-8.623ZM17.883 26.83h-.004c-1.818-.001-3.602-.49-5.158-1.412l-.37-.22-3.835 1.006 1.024-3.739-.241-.383a10.105 10.105 0 0 1-1.55-5.391c.002-5.586 4.548-10.13 10.138-10.13a10.067 10.067 0 0 1 7.165 2.97 10.068 10.068 0 0 1 2.965 7.168c-.002 5.586-4.548 10.13-10.134 10.13Zm5.56-7.588c-.306-.152-1.803-.889-2.083-.99-.279-.102-.482-.153-.685.152-.203.305-.787.99-.965 1.194-.177.203-.355.229-.66.076-.305-.152-1.286-.474-2.45-1.511-.905-.808-1.517-1.805-1.695-2.11-.177-.305-.018-.47.134-.622.137-.136.305-.355.457-.533.152-.178.203-.305.305-.508.101-.204.05-.382-.026-.534-.076-.152-.685-1.652-.939-2.261-.247-.594-.498-.514-.686-.523-.177-.009-.38-.01-.583-.01a1.12 1.12 0 0 0-.813.38c-.279.305-1.066 1.042-1.066 2.541 0 1.499 1.092 2.947 1.244 3.15.152.204 2.148 3.28 5.204 4.6.727.313 1.295.5 1.737.64.73.232 1.394.2 1.919.121.585-.087 1.802-.736 2.056-1.448.254-.711.254-1.32.178-1.448-.076-.127-.28-.203-.584-.356Z"
        fill={'#FFFFFF'}
      />
    </G>
    <Defs>
      <LinearGradient
        id="b"
        x1={17.5}
        y1={0.032}
        x2={17.5}
        y2={34.034}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#61FD7D" />
        <Stop offset={1} stopColor="#2BB826" />
      </LinearGradient>
      <ClipPath id="a">
        <Path fill={'#FFFFFF'} transform="translate(.5)" d="M0 0h34v34H0z" />
      </ClipPath>
    </Defs>
  </>
);

export const WhatsAppLogo = createLogo({
  viewBox: '1 0 34 34',
  vectorComponent
});
