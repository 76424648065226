import { colors } from '../colors';

const smallBoxShadow = {
  shadowOffset: {
    width: 2,
    height: 2
  },
  shadowOpacity: 0.1,
  shadowRadius: 8,
  shadowColor: colors.grey500,
  elevation: 4
};

type BoxShadow = typeof smallBoxShadow;

const mediumBoxShadow: BoxShadow = {
  shadowOffset: {
    width: 4,
    height: 4
  },
  shadowOpacity: 0.11,
  shadowRadius: 12,
  shadowColor: colors.grey900,
  elevation: 6
};

const largeBoxShadow: BoxShadow = {
  shadowOffset: {
    width: 8,
    height: 8
  },
  shadowOpacity: 0.16,
  shadowRadius: 16,
  shadowColor: colors.black,
  elevation: 8
};

export const shadow = {
  small: smallBoxShadow,
  medium: mediumBoxShadow,
  large: largeBoxShadow
} as const;

export type Shadow = keyof typeof shadow;
