import { AppState } from '../store';

export function getUserData(state: AppState) {
  return state.userData;
}

export function getRecentlyUsedBusinessId(state: AppState): string {
  return state.userData?.recentlyUsedBusiness?.id;
}

export function getNpsData(state: AppState) {
  return state.userData?.nps;
}
