import { ClientError, ErrorPreventLogging } from '@common/helpers/errors';

export function buildError(error) {
  if (error instanceof ClientError || error instanceof ErrorPreventLogging) {
    return {
      message: error.message,
      properties: error instanceof ClientError ? error.properties : undefined
    };
  }

  return {
    message: 'errors.defaultWithErrorMessage',
    properties: {
      errorMessage: error.message,
      ...error.properties
    }
  };
}

export function getErrorMessage(error) {
  if (typeof error === 'string') {
    return error;
  }

  return buildError(error).properties.errorMessage || buildError(error).message;
}
