import React from 'react';
import { useTranslation } from 'react-i18next';
import { useBrandNameText } from '@common/hooks/localisation';
import { useRedirect } from '@common/hooks/useRedirect';
import { Box } from '@components/Box';
import { Button } from '@components/Button';
import {
  GraphLineIcon,
  ResumeWithTickIcon,
  SearchDocumentIcon
} from '@components/Icon';
import { Modal } from '@components/Modal';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { TileComponentWithIcon } from '.';

export const LearnMoreModal = (props) => {
  const { t } = useTranslation();
  const { redirect } = useRedirect();
  const brandNameText = useBrandNameText();
  const { onClose, visible } = props;

  return (
    <Modal
      title={t('employerLandingPage.monetisationTile.learnMoreModal.heading', {
        brandNameText
      })}
      height="fit-content"
      visible={visible}
      onRequestClose={onClose}
      testID="coin-payment-modal"
    >
      <Stack spacing="medium">
        <Text>
          {t('employerLandingPage.monetisationTile.learnMoreModal.description')}
        </Text>

        <TileComponentWithIcon
          heading={t(
            'employerLandingPage.monetisationTile.learnMoreModal.easyToPost.heading'
          )}
          icon={
            <ResumeWithTickIcon variant="outline" size="medium" tone="brand" />
          }
        >
          {t(
            'employerLandingPage.monetisationTile.learnMoreModal.easyToPost.description'
          )}
        </TileComponentWithIcon>

        <TileComponentWithIcon
          heading={t(
            'employerLandingPage.monetisationTile.learnMoreModal.trackResults.heading'
          )}
          icon={<GraphLineIcon variant="outline" size="medium" tone="brand" />}
        >
          {t(
            'employerLandingPage.monetisationTile.learnMoreModal.trackResults.description'
          )}
        </TileComponentWithIcon>

        <TileComponentWithIcon
          heading={t(
            'employerLandingPage.monetisationTile.learnMoreModal.accountManagement.heading'
          )}
          icon={
            <SearchDocumentIcon variant="outline" size="medium" tone="brand" />
          }
        >
          {t(
            'employerLandingPage.monetisationTile.learnMoreModal.accountManagement.description'
          )}
        </TileComponentWithIcon>

        <Box marginTop="medium">
          <Button
            onPress={redirect(
              'https://au.jora.com/support/enquiries/new?who_are_you=employer'
            )}
          >
            {t(
              'employerLandingPage.monetisationTile.learnMoreModal.getInTouch'
            )}
          </Button>
        </Box>
      </Stack>
    </Modal>
  );
};
