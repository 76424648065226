import { userHasBadAccountStatus } from '@common/helpers/auth';
import { NO_SHOW_PATHS } from '../constants';

interface Verified {
  isVerified?: boolean;
  emailVerified?: boolean;
}
interface ShouldHideNavMenuProps {
  isAuthenticated: boolean;
  currentUser: Verified;
  currentPath: string;
  countryCode: string;
}
export const shouldHideNavMenu = ({
  isAuthenticated,
  currentUser,
  currentPath,
  countryCode
}: ShouldHideNavMenuProps) => {
  if (!isAuthenticated) return true;
  if (userHasBadAccountStatus(currentUser)) return true;

  const onNoShowPath = NO_SHOW_PATHS.find(
    (path) => `/${countryCode}/${path}` === currentPath
  );

  if (onNoShowPath) return true;

  return false;
};
