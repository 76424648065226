import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <>
    <Path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M9 14.818a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM1.5 22.818c0-4.417 3.36-8 7.5-8 4.14 0 7.5 3.583 7.5 8M26.8 14.818a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM19.3 22.818c0-4.417 3.36-8 7.5-8 4.14 0 7.5 3.583 7.5 8M17.916 9.066a3.375 3.375 0 1 0 0-6.75 3.375 3.375 0 0 0 0 6.75Z"
    />
    <Path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M12.291 15.066c0-3.313 2.52-6 5.625-6s5.625 2.687 5.625 6"
    />
  </>
);

export const MaxAdIcon = createIcon({
  viewBox: '0 0 36 24',
  vectorComponent
});
