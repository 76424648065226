import { useState } from 'react';
import { getApplications } from '@store/entities/applications';
import { useAppSelector } from '@store/hooks';
import { ApplicationFilterValues, FILTERS } from '../../types';
import { getFilteredApplications } from './filters';

export const useFilteredApplications = (jobId: string) => {
  const [applicationFilters, setApplicationFilters] =
    useState<ApplicationFilterValues>({} as ApplicationFilterValues);

  const { data: unfilteredApplications } =
    useAppSelector(getApplications(jobId)) || {};

  const filteredApplications = getFilteredApplications(
    applicationFilters,
    unfilteredApplications
  );

  const appliedFiltersCount = Object.keys(applicationFilters).filter(
    (key) => key !== FILTERS.SORT_APPLICATIONS
  ).length;

  return {
    setApplicationFilters,
    allApplications: unfilteredApplications,
    filteredApplications,
    appliedFiltersCount
  };
};
