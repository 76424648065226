import React, { Fragment, ReactNode } from 'react';

export type WhenProps = {
  condition: boolean;
  children: ReactNode;
};

export const When = ({ condition, children }: WhenProps) => {
  if (condition) {
    return <Fragment>{children}</Fragment>;
  }

  return null;
};
