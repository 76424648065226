import React from 'react';
import { ViewProps } from 'react-native';
import { Column } from '@components/Column';
import { Checkbox } from '@components/FormElements/Checkbox';
import { composeTestIDs } from '@components/utils';
import { useMultiSelect } from './useMultiSelect';

type Option<T> = {
  key: T;
  label: string;
};

export type MultiSelectProps<T = string> = {
  /**
   * The value of the checkbox which determines if the checkbox is checked if
   * the value is a string or unchecked if it is undefined
   */
  value: T[] | undefined;
  /**
   * An array of options to be displayed
   */
  options: Option<T>[];
  /**
   * A callback function invoked when the selected values change and takes the
   * newly selected values as a parameter
   */
  onChange: (selectedValues: T[]) => void;
  /**
   * An optional, unique identifier to be used for testing purposes
   */
  testID?: ViewProps['testID'];
};

export const MultiSelect = <T extends string>({
  options,
  testID,
  value = [],
  onChange
}: MultiSelectProps<T>) => {
  const { updateSelectedKeys } = useMultiSelect<T>({ value, onChange });

  return (
    <Column spacing="medium" testID={testID}>
      {options.map(({ key, label }) => (
        <Checkbox
          key={key}
          value={value.includes(key)}
          label={label}
          testID={composeTestIDs(testID, `option-${key}`)}
          onChange={updateSelectedKeys(key)}
        />
      ))}
    </Column>
  );
};
