import React from 'react';
import { BusinessInformation } from '@common/components/PostJobForm/components/BusinessInformation';
import { mapBusinessToFormValues } from '@common/components/PostJobForm/helpers/mapBusinessToFormValues';
import { PostJobFormBusiness } from '@common/components/PostJobForm/types';
import { FieldWrapper } from '../FieldWrapper';

export default function Business({
  isBusinessEditable,
  shouldRedirectBusinessCreatedTo
}) {
  const interceptOnChange = (data, onChange): void => {
    onChange(mapBusinessToFormValues(data));
  };

  return (
    <FieldWrapper<PostJobFormBusiness | undefined>
      name="business"
      render={(props) => (
        <BusinessInformation
          business={props.value}
          isBusinessEditable={isBusinessEditable}
          shouldRedirectBusinessCreatedTo={shouldRedirectBusinessCreatedTo}
          handleBusinessSelected={(data) =>
            interceptOnChange(data, props.onChange)
          }
        />
      )}
    />
  );
}
