import { useEffect, useState } from 'react';
import { Transactions, useQueryTransactions } from '@common/hooks/coins';

const DEFAULT_PER_PAGE = 5;
const DEFAULT_PAGE = 1;

export const useCoinTransactions = () => {
  const [transactions, setTransactions] = useState<Transactions>([]);
  const [totalTransactions, setTotalTransactions] = useState<number>(0);

  const [pageNumber, setPage] = useState(DEFAULT_PAGE);
  const [dataPerPage, setPerPage] = useState(DEFAULT_PER_PAGE);

  const {
    transactions: result,
    errorMessage,
    isLoading
  } = useQueryTransactions({
    page: pageNumber,
    perPage: dataPerPage
  });

  useEffect(() => {
    if (!result) return;
    if (result.transactions.length === 0) return;

    const someTransactionId = result.transactions[0].id;
    if (transactions.some((trx) => trx.id === someTransactionId)) return;

    setTransactions([...transactions, ...result.transactions]);
  }, [transactions, result]);

  useEffect(() => {
    if (!result) return;
    if (result.total === totalTransactions) return;

    setTotalTransactions(result.total);
  }, [result, totalTransactions]);

  const viewMoreOnPress = () => {
    setPage(pageNumber + 1);
    setPerPage(DEFAULT_PER_PAGE);
  };

  const viewLessOnPress = () => {
    setPage(1);
    setPerPage(DEFAULT_PER_PAGE);
    setTransactions([]);
  };

  return {
    viewMoreOnPress,
    viewLessOnPress,
    hasMoreTransactions: totalTransactions > transactions.length,
    hasLessTransactions: pageNumber > 1,
    transactions,
    fetchTransactionsError: errorMessage,
    isFetchTransactionsLoading: isLoading
  };
};
