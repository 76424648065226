import { EventTypes } from 'redux-segment';

const APP_STORE_BUTTON_CLICKED =
  'jora-employer/banner/APP_STORE_BUTTON_CLICKED';

export function trackAppStoreButtonClicked({ type }) {
  return {
    type: APP_STORE_BUTTON_CLICKED,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'App store button clicked',
          properties: {
            type
          }
        }
      }
    }
  };
}
