import React from 'react';
import { useTranslation } from 'react-i18next';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { useRedirect } from '@common/hooks/useRedirect';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';
import { Button } from '@components/Button';
import { Card, CardContent } from '@components/Card';
import { Heading } from '@components/Heading';
import { SuccessIcon } from '@components/Icon';
import { Stack } from '@components/Stack';

export const ResetPasswordSuccess = () => {
  const { t } = useTranslation();

  const { redirect } = useRedirect();
  const { countryCode } = useAppSelector(getLocalisation);

  return (
    <Stack tone="transparent">
      <JoraEmployerHelmet title={t('resetPasswordSuccessPage.title')} />
      <Stack spacing="medium" tone="transparent">
        <Card>
          <CardContent>
            <Stack alignItems="center" spacing="medium">
              <SuccessIcon size="xxxlarge" tone="success" variant="outline" />
              <Heading level="h1" weight="regular">
                {t('resetPasswordSuccessPage.heading')}
              </Heading>
            </Stack>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Stack spacing="medium">
              <Button
                onPress={redirect(`/${countryCode}/login`)}
                testID="login-button"
              >
                {t('common.action.login')}
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </Stack>
  );
};
