import * as React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <>
    <Path
      strokeLinejoin="round"
      strokeWidth={0.9}
      d="M12.417 6.757 3.44 11.353a.18.18 0 0 0 0 .321l8.977 4.593a.18.18 0 0 0 .164 0l9.099-4.592a.18.18 0 0 0 0-.323l-9.1-4.595a.18.18 0 0 0-.163 0Z"
    />
    <Path
      strokeLinejoin="round"
      strokeWidth={0.9}
      d="M7.283 13.69v2.352c0 .015.002.028.005.042.224.871 1.587 2.574 5.316 2.574 3.73 0 5.093-1.703 5.316-2.574a.166.166 0 0 0 .005-.042V13.69"
    />
    <Path
      strokeLinejoin="round"
      strokeWidth={0.7}
      d="M12.355 11.129 5.31 12.473a.18.18 0 0 0-.147.177v1.888m0 0 .813 3.415c.012.049.003.101-.036.133-.107.085-.348.193-.777.193-.419 0-.693-.103-.832-.188-.054-.033-.072-.1-.056-.162l.888-3.391Z"
    />
  </>
);

export const MortarboardIcon = createIcon({
  viewBox: '2 2 20 20',
  vectorComponent
});
