import type { T } from '../../../Form/types';
import type { CajaContextProps, HydratedCajaProps } from '../../../types';

interface CajaRequiredContext extends HydratedCajaProps {
  t: T;
  updateDebounce: number;
  loading?: [boolean, (loading: boolean) => void];
}
export function toCajaContext(
  cajaProps: CajaRequiredContext
): CajaContextProps {
  return {
    loading: cajaProps.loading || [false, () => {}],
    stepMap: cajaProps.stepMap,
    userEmail: cajaProps.userEmail,
    allowBusinessAndEmailChange: cajaProps.allowBusinessAndEmailChange,
    t: cajaProps.t,
    updateDebounce: cajaProps.updateDebounce
  };
}
