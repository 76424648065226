import React from 'react';
import { TextInputProps } from 'react-native';
import { Masks } from 'react-native-mask-input';
import { Input } from '@components/FormElements/Input';

export type DateInputProps = {
  value: TextInputProps['value'];
  onChange: TextInputProps['onChangeText'];
  onBlur?: TextInputProps['onBlur'];
  testID?: TextInputProps['testID'];
  disabled?: boolean | undefined;
  tone?: 'neutral' | 'danger' | undefined;
};

export const DateInput = (props: DateInputProps) => {
  const placeholder = 'DD/MM/YYYY';

  return (
    <Input
      id="date"
      placeholder={placeholder}
      keyboardType="numeric"
      mask={Masks.DATE_DDMMYYYY}
      maskAutoComplete={true}
      {...props}
    />
  );
};
