import React, { useState } from 'react';
import { Box } from '@components/Box';
import { Heading } from '@components/Heading';
import { useUpgradeJob } from './hooks/useUpgradeJob';

const defaultValue = JSON.stringify({
  jobId: 'abcd',
  adType: 'max',
  countryCode: 'au'
});

export const UpgradeJobTestWidget = () => {
  const [inputValue, setInput] = useState(defaultValue);
  const { onClickMutate } = useUpgradeJob();

  const onButtonPress = () => {
    onClickMutate(inputValue);
  };

  return (
    <Box>
      <Heading level="h2">Upgrade Job (use input to put in some JSON)</Heading>
      <input
        type="text"
        placeholder=""
        onChange={(e) => setInput(e.target.value)}
      />
      <button onClick={onButtonPress}>Upgrade Job Test</button>
    </Box>
  );
};
