import React from 'react';
import { Trans as I18nextTrans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { SuccessIcon } from '@components/Icon';
import { Row } from '@components/Row';
import { Text } from '@components/Text';

const ProgressText = ({ progressNumber }: { progressNumber: number }) => {
  return (
    <I18nextTrans
      i18nKey={
        'jobDetailsAndApplicationsListPage.applicationStatusProgressText.reviewed'
      }
      parent={Row}
      values={{ progressNumber }}
      components={[
        <Text size="small" weight="medium" />,
        <Text size="small" weight="light" />
      ]}
    />
  );
};

export const ApplicationsStatusProgressHelperText = ({
  applicationStatusProgress
}: {
  applicationStatusProgress: number;
}) => {
  const { t } = useTranslation();

  if (applicationStatusProgress === 0) {
    return (
      <Text size="small" weight="light" tone="danger">
        {t(
          'jobDetailsAndApplicationsListPage.applicationStatusProgressText.noneReviewed'
        )}
      </Text>
    );
  }

  if (applicationStatusProgress === 100) {
    return (
      <Row spacing="xsmall" alignItems="center">
        <SuccessIcon
          size="xsmall"
          tone="success"
          variant="outline"
          testID="tick-icon"
        />
        <ProgressText progressNumber={applicationStatusProgress} />
      </Row>
    );
  }

  return <ProgressText progressNumber={applicationStatusProgress} />;
};
