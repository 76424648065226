import { useHistory } from 'react-router-native';
import { stepToPath } from '@src/caja/steps/stepToPath';
import { Step, StepMap } from '@src/caja/steps/types';
import { modifyUrlPath } from '@src/lib/func';

export function useNextStep(currentPath: string, stepMap: StepMap) {
  const history = useHistory();
  return (step: Step) => {
    const newStepPath = modifyUrlPath(stepToPath(step, stepMap), currentPath);
    history.push(newStepPath);
  };
}
