interface PaginateArgs<T> {
  items: T[];
  perPage: number;
  currentPage: number;
}

/**
 * Paginates the given items based on the specified perPage and currentPage values.
 *
 * @template T - The type of the items being paginated.
 * @param {PaginateArgs<T>} args - The pagination arguments.
 * @param {T[]} args.items - The array of items to paginate.
 * @param {number} args.perPage - The number of items per page.
 * @param {number} args.currentPage - The current page number (0-based).
 * @returns {T[]} - The sliced array of items for the current page.
 */
export function paginateItems<T>({
  items,
  perPage,
  currentPage
}: PaginateArgs<T>) {
  return items.slice(currentPage * perPage, (currentPage + 1) * perPage);
}
