import queryString from 'query-string';
import { push } from 'redux-first-history';
import type { PaymentDetails } from '@store/payment/stripeApi/stripeApi';
import { ClientError } from '../../../../common/helpers/errors';
import { getUuid } from '../../../../common/helpers/uuid/uuid';
import REQUEST_KEY from '../../../constants/requestKeys';
import { endLoading, startLoading, updateCustomError } from '../../app/actions';
import * as constants from '../constants';
import { getConfirmPaymentError } from './utils';

export function confirmIdBankTransferPayment({
  stripeConfig: {
    native: { paymentIntentId, clientId }
  },
  successfulRedirectUrl,
  data: { bankName, country, email, fullName, paymentMethod, brand }
}) {
  return {
    types: [
      constants.CONFIRM_PAYMENT_INTENT,
      constants.CONFIRM_PAYMENT_INTENT_SUCCESS,
      constants.CONFIRM_PAYMENT_INTENT_FAIL
    ],
    promise: async (dispatch, getState, fetch) => {
      try {
        const {
          config: { stripePublicKeys }
        } = getState();
        const response = await fetch(
          `https://api.stripe.com/v1/payment_intents/${paymentIntentId}/confirm`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: queryString.stringify({
              'payment_method_data[type]': paymentMethod,
              'payment_method_data[billing_details][email]': email,
              'payment_method_data[billing_details][name]': fullName,
              'payment_method_data[id_bank_transfer][bank]': bankName,
              key: stripePublicKeys[brand][country],
              client_secret: clientId,
              return_url: successfulRedirectUrl
            })
          },
          {
            showGlobalSpinner: true
          }
        );

        const stripePaymentUrl =
          response?.next_action?.display_bank_transfer_instructions
            ?.hosted_instructions_url;

        if (!stripePaymentUrl) {
          updateCustomError(
            dispatch,
            REQUEST_KEY.PAYMENT.CONFIRM_PAYMENT,
            getConfirmPaymentError(new ClientError('errors.defaultWithGuid'))
          );
        }

        return response;
      } catch (err) {
        const error = new ClientError('errors.defaultWithGuid', {
          guid: `ui-${getUuid()}`,
          responseBody: err instanceof Error ? err?.message : ''
        });
        updateCustomError(dispatch, REQUEST_KEY.PAYMENT.CONFIRM_PAYMENT, error);
        throw error;
      } finally {
        endLoading(dispatch, REQUEST_KEY.PAYMENT.CONFIRM_PAYMENT, true);
      }
    }
  };
}

export function confirmPayment(
  stripe,
  {
    stripeConfig: { native: { clientId } = {} },
    successfulRedirectUrl,
    data: { email, nameOnCard: name }
  }: PaymentDetails
) {
  return {
    types: [
      constants.CONFIRM_PAYMENT,
      constants.CONFIRM_PAYMENT_SUCCESS,
      constants.CONFIRM_PAYMENT_FAIL
    ],
    promise: async (dispatch) => {
      if (!stripe && !clientId) {
        return;
      }

      try {
        startLoading(dispatch, REQUEST_KEY.PAYMENT.CONFIRM_PAYMENT, true);

        const { paymentIntent, error } = await stripe.confirmPayment(clientId, {
          paymentMethodType: 'Card',
          paymentMethodData: {
            billingDetails: {
              email,
              name
            }
          }
        });

        if (error) {
          updateCustomError(
            dispatch,
            REQUEST_KEY.PAYMENT.CONFIRM_PAYMENT,
            getConfirmPaymentError(error)
          );
        }

        if (paymentIntent) {
          dispatch(push(successfulRedirectUrl));
        }

        return;
      } catch (err) {
        const error = new ClientError('errors.defaultWithGuid', {
          guid: `ui-${getUuid()}`,
          responseBody: err instanceof Error ? err?.message : ''
        });
        updateCustomError(dispatch, REQUEST_KEY.PAYMENT.CONFIRM_PAYMENT, error);
        throw error;
      } finally {
        endLoading(dispatch, REQUEST_KEY.PAYMENT.CONFIRM_PAYMENT, true);
      }
    }
  };
}

export function loadStripeInstance() {
  return null;
}
