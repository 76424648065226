import React from 'react';
import { forwardRef } from 'react';
import {
  Pressable,
  PressableProps,
  Text as ReactNativeText,
  View
} from 'react-native';
import { Box } from '@components/Box';
import '@components/Button/useButtonStyle';
import { useTabStyle, useTextStyle } from './useTabStyle';

export type TabProps = {
  handleOnPress: () => void;
  isSelected: boolean;
  children?: string;
  testID?: PressableProps['testID'];
};

export const Tab = forwardRef<View, TabProps>((props, ref) => {
  const { handleOnPress, isSelected, children, testID } = props;

  const resolvedPressableStyle = useTabStyle({ isSelected });
  const resolvedTextStyle = useTextStyle({ isSelected });

  return (
    <Pressable
      role="button"
      style={resolvedPressableStyle}
      onPress={handleOnPress}
      testID={testID}
      ref={ref}
    >
      <Box
        tone="transparent"
        paddingVertical="xsmall"
        paddingHorizontal={{
          mobile: 'small',
          tablet: 'large',
          desktop: 'large'
        }}
      >
        <ReactNativeText style={resolvedTextStyle}>{children}</ReactNativeText>
      </Box>
    </Pressable>
  );
});
