import REQUEST_KEY from '@common/constants/requestKeys';
import { ClientError } from '@common/helpers/errors';
import { useHandleAppLoading } from '@common/hooks/appLoading';
import { extractTrpcErrorProperties } from '@src/trpc/lib';
import {
  ErrorResponse,
  useFetchNextExpiringCredit
} from './useFetchNextExpiringCredit';

export const useQueryNextExpiringCredit = () => {
  const { data, error, isLoading } = useFetchNextExpiringCredit();

  useHandleAppLoading(isLoading, REQUEST_KEY.COINS.NEXT_EXPIRING_CREDIT.GET);

  const clientError = getClientError(
    extractTrpcErrorProperties<ErrorResponse>(error)
  );

  return {
    nextExpiringCredit: data,
    errorMessage: {
      message: clientError?.message,
      properties: clientError?.properties
    }
  };
};

const getClientError = (errorProperties) => {
  if (!errorProperties) return;

  return new ClientError('errors.defaultWithGuid', {
    guid: errorProperties.guid,
    responseBody: errorProperties.responseBody
  });
};
