import { OnPressed, useCoinPaymentPressed } from './useCoinPaymentPressed';
import { useCurrencyPaymentPressed } from './useCurrencyPaymentPressed';
import { CoinPriceMap, useHasSufficientCoins } from './useHasSufficientCoins';

type Params = {
  pricingText: CoinPriceMap;
  onAdTypeSelected: OnPressed;
  jobId: string;
  campaign?: string;
};

/**
 * Selects payment option based on user's coin balance (i.e: if user has sufficient coins, use coin payment, otherwise use currency payment)
 */
export const useSelectPaymentOption = (params: Params) => {
  const { pricingText, onAdTypeSelected, jobId, campaign } = params;

  const onCoinPaymentPressed = useCoinPaymentPressed(onAdTypeSelected);

  const onCurrencyPaymentPressed = useCurrencyPaymentPressed({
    jobId,
    campaign
  });

  const { max: hasSufficientMaxCoins, plus: hasSufficientPlusCoins } =
    useHasSufficientCoins(pricingText);

  return {
    onMaxAdPressed: hasSufficientMaxCoins
      ? onCoinPaymentPressed.onMaxAdPressed
      : onCurrencyPaymentPressed.onMaxAdPressed,
    onPlusAdPressed: hasSufficientPlusCoins
      ? onCoinPaymentPressed.onPlusAdPressed
      : onCurrencyPaymentPressed.onPlusAdPressed
  };
};
