import React from 'react';
import { Trans as I18nextTrans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-native';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { RegistrationProgressIndicator } from '@common/components/RegistrationProgressIndicator';
import { RequestErrorMessage } from '@common/components/RequestErrorMessage';
import { When } from '@common/components/When';
import { VerifyEmailForm } from '@common/componentsV2/Account/forms/verifyEmail/VerifyEmailForm';
import REQUEST_KEY from '@common/constants/requestKeys';
import { useGetAppErrors } from '@common/hooks/appError';
import { useSiteCountryCode } from '@common/hooks/localisation';
import { useRedirectEmailVerifiedUser } from '@common/hooks/useRedirectEmailVerifiedUser';
import { useRedirectUnauthenticatedUser } from '@common/hooks/useRedirectUnauthenticatedUser';
import { Box } from '@components/Box';
import { Card, CardContent } from '@components/Card';
import { Heading } from '@components/Heading';
import { VerifyEmailIllustration } from '@components/Illustration';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { useVerifyEmail } from './hooks/useVerifyEmail';

export const VerifyEmail = () => {
  const { t } = useTranslation();
  const countryCode = useSiteCountryCode();
  const history = useHistory();
  const showVerifyEmailError = useGetAppErrors(REQUEST_KEY.AUTH.VERIFY_EMAIL);
  const showResendVerifyEmailError = useGetAppErrors(
    REQUEST_KEY.AUTH.RESEND_VERIFY_EMAIL
  );
  const {
    submitVerificationCode,
    resendVerificationEmail,
    userEmail,
    isFromUpdateAccount
  } = useVerifyEmail();

  useRedirectUnauthenticatedUser({ to: `/${countryCode}/login` });
  useRedirectEmailVerifiedUser();

  const heading = isFromUpdateAccount
    ? t('verifyEmailPage.heading.fromUpdateAccount')
    : t('verifyEmailPage.heading.fromPostJob');

  const onSubmit = async ({ emailVerificationCode }, { isValid }) => {
    let verifyEmailSuccessPage = `/${countryCode}/account/verify-email-success`;

    if (isFromUpdateAccount) {
      verifyEmailSuccessPage += '?fromUpdateAccount=true';
    }

    if (isValid) {
      await submitVerificationCode({ emailVerificationCode });
      history.push(verifyEmailSuccessPage);
    }
  };

  const onResend = async () => {
    await resendVerificationEmail();
  };

  return (
    <>
      <JoraEmployerHelmet title={t('verifyEmailPage.title')} />

      <Stack spacing="medium" tone="transparent">
        <Box paddingTop="medium" paddingLeft="medium" tone="transparent">
          <Heading level="h1">{heading}</Heading>
        </Box>

        <Card>
          <CardContent>
            <Stack spacing="large">
              <When condition={!isFromUpdateAccount}>
                <Box alignSelf="center" testID="progress-indicator">
                  <RegistrationProgressIndicator
                    currentPathOverride={`/${countryCode}/register`}
                  />
                </Box>
              </When>
              <Stack
                alignItems="center"
                spacing="medium"
                marginBottom={'small'}
              >
                <VerifyEmailIllustration size="large" />
                <Heading level="h1" weight="regular" align="center">
                  {t('verifyEmailPage.description.confirmEmail')}
                </Heading>
                <Text align="center">
                  <I18nextTrans
                    extraTopMargin="small"
                    i18nKey="verifyEmailPage.description.emailSent"
                    values={{ email: userEmail }}
                  >
                    <Text weight="regular">0</Text>
                  </I18nextTrans>
                </Text>
              </Stack>
            </Stack>
            <VerifyEmailForm onSubmit={onSubmit} onResend={onResend} />
            <>
              {showVerifyEmailError || showResendVerifyEmailError ? (
                <>
                  <RequestErrorMessage
                    requestKey={REQUEST_KEY.AUTH.VERIFY_EMAIL}
                  />
                  <RequestErrorMessage
                    requestKey={REQUEST_KEY.AUTH.RESEND_VERIFY_EMAIL}
                  />
                </>
              ) : null}
            </>
          </CardContent>
        </Card>
      </Stack>
    </>
  );
};
