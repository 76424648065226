import type { T } from '@src/caja/Form/types';

/**
 * Generates validation rules for the business address field.
 * @param t - The translation function.
 * @returns The validation rules object.
 */
export function businessAddressRules(t: T) {
  return {
    validate: (value: any) => {
      if (!value?.streetAddress) {
        return t('validations.business.businessAddress.selectSuggestion');
      }

      return;
    },
    required: t('validations.business.businessAddress.required')
  };
}
