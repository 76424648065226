import { sendToNative } from '../sendToNative';

interface BrazeResponse {
  success: boolean;
}

interface PermissionOptions {
  alert: boolean;
  sound: boolean;
  badge: boolean;
  provisional: boolean;
}

async function requestPushPermission(
  permissionOptions: PermissionOptions
): Promise<BrazeResponse> {
  return (await sendToNative('braze', {
    type: 'requestPushPermission',
    args: permissionOptions
  })) as BrazeResponse;
}

async function enableSDK(): Promise<BrazeResponse> {
  return (await sendToNative('braze', {
    type: 'enableSDK'
  })) as BrazeResponse;
}

async function disableSDK(): Promise<BrazeResponse> {
  return (await sendToNative('braze', {
    type: 'disableSDK'
  })) as BrazeResponse;
}

async function changeUser(userId?: string): Promise<BrazeResponse | void> {
  if (!userId) return;
  return (await sendToNative('braze', {
    type: 'changeUser',
    args: userId
  })) as BrazeResponse;
}

export const Braze = {
  requestPushPermission,
  enableSDK,
  disableSDK,
  changeUser
};
