import { isBankTransfer } from './isBankTransfer';
import { validateFields } from './validateFields';

const validateCardFields = (data) => {
  const requiredCardFields = ['email', 'nameOnCard', 'paymentMethod'];
  return validateFields(requiredCardFields, data);
};

const validateBankTransferFields = (data) => {
  const requiredBankTransferFields = ['email', 'paymentMethod'];
  return validateFields(requiredBankTransferFields, data);
};

export const validateCheckoutForm = (data) =>
  isBankTransfer(data)
    ? validateBankTransferFields(data)
    : validateCardFields(data);
