import { green700 } from '../../theme/palette/colours';
import Svg, { G, Path } from 'svgs';

function SadManIllustration(props) {
  return (
    <Svg viewBox="0 0 143 132" {...props}>
      <G
        stroke={green700}
        strokeWidth="4"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <Path d="M47.472 81.583c-10.223 6.275-17.637 3.99-23.181 1.34-3.696-1.767-6.827-4.899-9.393-9.395l-3.454-5.76-5.392.819c-2.047-1.758-3.192-3.626-3.435-5.605-.243-1.98.416-4.07 1.976-6.27l5.412-.665-.53-4.316-4.027-32.794 16.235-1.994M29.8 15.947l14.25-1.75c3.207-.394 7.111-3.887 11.714-10.48 3.646 2.479 7.228 5.555 10.746 9.23 5.275 5.51 4.389 13.377 3.357 16.162-1.031 2.784-3.165 5.126-6.035 6.768-1.913 1.095-4.107 1.866-6.582 2.315l3.469 28.25c.122 2.659-.496 4.987-1.853 6.983" />
        <Path d="M10.005 56.047l8.117-.996-1.458-11.876 40.586-4.983M23.866 61.825c1.175-2.045 2.465-3.154 3.87-3.327 1.405-.172 2.925.592 4.56 2.292M43.362 58.006c1.175-2.046 2.465-3.155 3.87-3.328 1.405-.172 2.925.592 4.56 2.293M34.02 74.84c1.644-2.104 3.402-3.27 5.275-3.5 1.873-.23 3.861.476 5.965 2.12M95.038 121.038c10.112 6.452 17.565 4.296 23.154 1.743 3.726-1.702 6.911-4.778 9.556-9.228l3.553-5.7 5.378.914c2.077-1.722 3.254-3.57 3.532-5.545.277-1.975-.345-4.076-1.867-6.303l-5.4-.76.606-4.305 4.598-32.72-16.197-2.276M113.853 55.72l-14.218-1.998c-3.199-.45-7.042-4.01-11.529-10.682-3.69 2.414-7.324 5.427-10.905 9.039-5.371 5.419-4.622 13.3-3.64 16.101.983 2.803 3.076 5.181 5.917 6.873 1.894 1.128 4.074 1.938 6.54 2.43l-3.96 28.184c-.169 2.657.408 4.996 1.73 7.015" />
        <Path d="M132.945 96.16l-8.099-1.138 1.665-11.849-40.492-5.69M118.985 101.695c-1.139-2.066-2.41-3.197-3.811-3.394-1.402-.197-2.935.54-4.599 2.212M99.559 97.536c-1.14-2.066-2.41-3.198-3.811-3.395-1.402-.197-2.935.54-4.6 2.213M108.605 114.53c-1.606-2.132-3.344-3.329-5.213-3.591-1.869-.263-3.869.409-6 2.015M16.363 101.081v7.324a4 4 0 01-4 4H5.04h0M22.025 101.081v7.324a4 4 0 004 4h7.324M16.363 129.39v-7.323a4 4 0 00-4-4H5.04h0M22.025 129.39v-7.323a4 4 0 014-4h7.324M121.106 2v7.324a4 4 0 01-4 4h-7.323 0M126.768 2v7.324a4 4 0 004 4h7.324M121.106 30.309v-7.324a4 4 0 00-4-4h-7.323 0M126.768 30.309v-7.324a4 4 0 014-4h7.324" />
      </G>
    </Svg>
  );
}

export default SadManIllustration;
