import React from 'react';
import { Trans } from 'react-i18next';
import { openExternalURL } from '@common/helpers/externalConnection';
import { useSupportSalesEmail } from '@common/hooks/localisation';
import { useSupportSalesWhatsApp } from '@common/hooks/localisation/useSupportSalesWhatsApp';
import { TextLink } from '@components/TextLink';

export const ContactUs = () => {
  const supportSalesEmail = useSupportSalesEmail();
  const supportSalesWhatsApp = useSupportSalesWhatsApp();

  const openEmail = () => {
    openExternalURL(`mailto:${supportSalesEmail}`);
  };

  const openWhatsapp = () => {
    const parsedWhatsappNumber = supportSalesWhatsApp
      ?.replace(/[+\s]/g, '')
      .trim();
    openExternalURL(`https://wa.me/${parsedWhatsappNumber}`);
  };

  return (
    <Trans
      i18nKey="account.upgradeAccountModal.footer"
      values={{
        supportSalesEmail,
        supportSalesWhatsApp
      }}
      components={[
        <TextLink size="small" onPress={openEmail}>
          0
        </TextLink>,
        <TextLink size="small" onPress={openWhatsapp}>
          1
        </TextLink>
      ]}
    />
  );
};
