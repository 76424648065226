import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <>
    <Path
      d="M3.225 4.583h13.55c.167 0 .308.14.308.319v10.196a.314.314 0 0 1-.308.319H3.225a.314.314 0 0 1-.308-.319V4.902a.31.31 0 0 1 .308-.319Z"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M2.917 4.813 10 11.185l7.083-6.372M7.875 9.274l-4.958 4.46M17.083 13.734l-4.958-4.46"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const EmailIcon = createIcon({
  viewBox: '0 0 20 20',
  vectorComponent
});
