import { EventTypes } from 'redux-segment';
import REQUEST_KEY from '@common/constants/requestKeys';
import { ClientError, ErrorPreventLogging } from '@common/helpers/errors';
import { FetchActionResultSuccess } from '@common/store/middleware/clientMiddlewareTypes';
import { GENERAL_EXCEPTION } from '@seek/je-error-helper';
import { JOB_STATUS } from '@seek/je-shared-data';
import * as constants from '../../constants';
import { toJobServiceEndpoint } from '../stateUtils/toJobServiceEndpoint';

type DeleteResult = {
  id: string;
  status: JOB_STATUS.CLOSED;
};

export type DeleteActionSuccess = FetchActionResultSuccess<
  typeof constants.DELETE_JOB_SUCCESS,
  DeleteResult
>;

export function deleteJob(data) {
  return {
    types: [
      constants.DELETE_JOB,
      constants.DELETE_JOB_SUCCESS,
      constants.DELETE_JOB_FAIL
    ],
    promise: async (dispatch, getState, fetch): Promise<DeleteResult> => {
      const jobServiceEndpoint = toJobServiceEndpoint(getState);
      const { localisation } = getState();
      const { jobId, reason, additionalComments } = data;

      await fetch(
        `${jobServiceEndpoint}/${jobId}`,
        {
          headers: {
            'x-jora-site': localisation.countryCode
          },
          method: 'DELETE',
          credentials: 'include'
        },
        {
          requestKey: REQUEST_KEY.JOB.DELETE,
          showGlobalSpinner: true,
          onResponseError: handleDeleteError
        }
      );

      dispatch(trackCloseJobReason({ id: jobId, reason, additionalComments }));

      return {
        id: jobId,
        status: JOB_STATUS.CLOSED
      };
    }
  };
}

function handleDeleteError(body) {
  const { code, guid } = body;

  switch (code) {
    case GENERAL_EXCEPTION.NOT_FOUND:
      throw new ErrorPreventLogging(code);
    default:
      throw new ClientError('errors.defaultWithGuid', {
        guid,
        responseBody: body
      });
  }
}

const TRACK_CLOSE_JOB_REASON =
  'jora-employer/entities/jobs/TRACK_CLOSE_JOB_REASON';

function trackCloseJobReason({ id, reason, additionalComments }) {
  return {
    type: TRACK_CLOSE_JOB_REASON,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'Close Job Reason Submitted',
          properties: {
            employerJobId: id,
            reason,
            additionalComments
          }
        }
      }
    }
  };
}
