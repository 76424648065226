import React from 'react';
import { View } from 'react-native';
import { DividerStyleProps, useDividerStyle } from './useDividerStyle';

export type DividerProps = {
  testID?: string;
} & DividerStyleProps;

export const Divider = (props: DividerProps) => {
  const { testID, ...restProps } = props;
  const resolvedStyle = useDividerStyle(restProps);

  return <View style={resolvedStyle} testID={testID} />;
};
