import React from 'react';
import { CountrySelector as InnerField } from '@common/components/BusinessForm/components/CountrySelector';
import { FieldWrapper } from '../FieldWrapper';

export default function CountrySelector() {
  return (
    <FieldWrapper<string>
      name="country"
      render={(props) => <InnerField {...props} />}
    />
  );
}
