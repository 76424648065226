import _unescapeHtmlEntities from 'lodash.unescape';
import { ListJobItem } from './types';

export function toFormattedJob(job): ListJobItem {
  return {
    ...job,
    countryCode: job.countryCode.toLowerCase(),
    title: _unescapeHtmlEntities(job.title),
    company: _unescapeHtmlEntities(job.company),
    businessName: _unescapeHtmlEntities(job.businessName)
  };
}
