import { useMonetisationEnabled } from '@common/hooks/featureToggles';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';

export const useDetailedLanding = () => {
  const { countryCode } = useAppSelector(getLocalisation);

  const isMonetisationEnabled = useMonetisationEnabled(countryCode || '');

  return {
    isMonetisationEnabled
  };
};
