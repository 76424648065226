import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Button';
import { Stack } from '@components/Stack';
import { TextLink } from '@components/TextLink';
import { FormContext } from './context/FormContext';
import { useResponseErrorHandler } from './hooks';
import { EmailVerificationCode } from './topLevelFields';
import type { VerifyEmailFormFields } from './types';

type FormProps = {
  onResend: () => Promise<void | {
    field: string;
    errorMessage: string;
  }>;
  onSubmit: (
    data: VerifyEmailFormFields,
    formState: { isValid: boolean }
  ) => Promise<void | { field: string; errorMessage: string }>;
};

export const VerifyEmailForm = ({ onResend, onSubmit }: FormProps) => {
  const { t } = useTranslation();
  const { handleResponseErrors } = useResponseErrorHandler();

  const {
    control,
    handleSubmit,
    trigger: triggerValidation,
    setError,
    formState: { isValid, errors }
  } = useForm<VerifyEmailFormFields>({
    mode: 'onBlur'
  });

  const submit = async (data: VerifyEmailFormFields) => {
    await triggerValidation();
    const errorResponse = await onSubmit(data, { isValid });

    handleResponseErrors(errorResponse, setError);
  };

  return (
    <FormContext.Provider value={{ control, errors, t }}>
      <Stack spacing="large">
        <EmailVerificationCode />
      </Stack>
      <Stack
        paddingTop="large"
        spacing="medium"
        direction={{ mobile: 'column', tablet: 'row', desktop: 'row' }}
        alignItems={{ mobile: 'stretch', tablet: 'center', desktop: 'center' }}
      >
        <Button onPress={handleSubmit(submit)} testID="verify-email-action">
          {t('verifyEmailForm.action.submit')}
        </Button>
        <Stack alignItems={'center'}>
          <TextLink
            variant="primary"
            onPress={onResend}
            testID="resend-email-action"
          >
            {t('verifyEmailForm.action.emailResend')}
          </TextLink>
        </Stack>
      </Stack>
    </FormContext.Provider>
  );
};
