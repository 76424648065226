import React, { useContext } from 'react';
import { JobStartDate as InnerField } from '@common/components/PostJobForm/components/JobStartDate/JobStartDate';
import { FieldWrapper } from '../FieldWrapper';
import { FormContext } from '../FormContext';
import { jobStartDateRules } from '../rules';

export default function JobStartDate() {
  const { t } = useContext(FormContext);
  return (
    <FieldWrapper<string>
      name="startDate"
      rules={jobStartDateRules(t)}
      render={(props) => <InnerField {...props} />}
    />
  );
}
