import { ViewStyle } from 'react-native';
import { useTheme } from '@theme/DesignSystemProvider/ThemeContext';
import { useResolveToken } from '@theme/tokens/utils/useResolveToken';

export type FieldStyleProps = {
  hasMaxWidth?: boolean;
  zIndex?: ViewStyle['zIndex'];
};

export function useFieldStyle({ zIndex, hasMaxWidth }: FieldStyleProps) {
  const {
    formElement: { container }
  } = useTheme();
  const { resolveToken } = useResolveToken();

  return {
    maxWidth: hasMaxWidth ? container.maxWidth : undefined,
    zIndex: resolveToken(null, zIndex)
  };
}
