import React, { cloneElement } from 'react';
import { Row } from '@components/Row';
import { MilestoneStepProps } from './components/MilestoneStep';
import { useProgressIndicator } from './hooks/useProgressIndicator';
import { useProgressIndicatorStyles } from './hooks/useProgressIndicatorStyle';

export type ProgressIndicatorProps = {
  children: React.ReactElement<MilestoneStepProps>[];
  currentStepPathname: string;
  testID: string;
};

export const ProgressIndicator = ({
  currentStepPathname,
  testID,
  children
}: ProgressIndicatorProps) => {
  const { width } = useProgressIndicatorStyles();
  const { getStepProps } = useProgressIndicator({
    children,
    currentStepPathname
  });

  return (
    <Row testID={testID} width={width}>
      {children.map((child, index) => {
        const stepProps = getStepProps(child, index);
        return cloneElement(child, { ...stepProps });
      })}
    </Row>
  );
};
