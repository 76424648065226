type ApplicationInformationAvailability = {
  applicationsAvailability: {
    available: boolean;
  };
};
/**
 * Checks if the application information is available.
 * @param applicationInformation - The application information to check.
 * @returns A boolean indicating whether the application information is available.
 */
export const available = (
  applicationInformation: ApplicationInformationAvailability | undefined
) => Boolean(applicationInformation?.applicationsAvailability?.available);
