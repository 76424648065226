type ElementWithRect = {
  getBoundingClientRect(): {
    top: number;
  };
  scrollTop: number;
};

interface NextPagePositionProps {
  topOfList: ElementWithRect;
  scroller: ElementWithRect;
}

/**
 * Calculates the scroll position to return to once the user navigates between paginated pages.
 *
 * @param {Object} params - The parameters for the function.
 * @param {HTMLElement} params.topOfList - The top element of the list.
 * @param {HTMLElement} params.scroller - The element that is used to scroll the list.
 * @returns {number} The scroll position for the next page.
 */
export function nextPageScrollPosition({
  topOfList,
  scroller
}: NextPagePositionProps) {
  const offset = 80;
  const topOfListPosition =
    topOfList.getBoundingClientRect().top +
    scroller.scrollTop -
    scroller.getBoundingClientRect().top -
    offset;
  return Math.max(0, topOfListPosition);
}
