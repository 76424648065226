import React from 'react';
import { BusinessSize } from '@common/types';
import { BUSINESS_SIZE } from '@seek/je-shared-data';
import { Box } from '@components/Box';
import {
  BuildingIcon,
  FactoryIcon,
  FarmhouseIcon,
  ShopIcon
} from '@components/Icon';

const mapBusinessSizeToIconAndKey = {
  [BUSINESS_SIZE.LESS_THAN_5_EMPLOYEES]: {
    icon: ShopIcon,
    testID: 'tiny-business-icon'
  },
  [BUSINESS_SIZE.LESS_THAN_20_EMPLOYEES]: {
    icon: FarmhouseIcon,
    testID: 'small-business-icon'
  },
  [BUSINESS_SIZE.LESS_THAN_50_EMPLOYEES]: {
    icon: FarmhouseIcon,
    testID: 'small-business-icon'
  },
  [BUSINESS_SIZE.LESS_THAN_100_EMPLOYEES]: {
    icon: BuildingIcon,
    testID: 'medium-business-icon'
  },
  [BUSINESS_SIZE.LESS_THAN_200_EMPLOYEES]: {
    icon: BuildingIcon,
    testID: 'medium-business-icon'
  },
  [BUSINESS_SIZE.MORE_THAN_200_EMPLOYEES]: {
    icon: FactoryIcon,
    testID: 'large-business-icon'
  },
  default: {
    icon: ShopIcon,
    testID: 'tiny-business-icon'
  }
};

type BusinessImageProps = {
  /**
   * The business size that will determine the icon to be rendered
   */
  businessSize: BusinessSize;
};

const BusinessImage = ({ businessSize }: BusinessImageProps) => {
  const { icon: Icon, testID } =
    mapBusinessSizeToIconAndKey[businessSize ?? 'default'];

  return (
    <Box
      tone="neutralDark"
      borderRadius="xsmall"
      borderWidth="xsmall"
      padding="small"
      width="xxxsmall"
      height="xxxsmall"
      testID={`business-image-${testID}`}
    >
      <Icon testID={testID} variant="outline" tone="neutral" size="xlarge" />
    </Box>
  );
};

export { BusinessImage, BusinessImageProps };
