import { TrpcRouter } from 'je-bff/src/handlers/bff';
import REQUEST_KEY from '@common/constants/requestKeys';
import { ClientError } from '@common/helpers/errors';
import { useHandleAppLoading } from '@common/hooks/appLoading';
import { useUpdateApplicantNotesInStore } from '@src/joraEmployer/pages/job/details/components/JobApplicationDetails/components/ApplicationCard/components/Notes/hooks';
import { inferRouterInputs } from '@trpc/server';
import { trpcBff } from '@trpcBff';

export type UpdateNoteParams =
  inferRouterInputs<TrpcRouter>['applications']['notes']['update'];

export const useUpdateNote = ({ jobId }: { jobId: string }) => {
  const { mutateAsync, isLoading } =
    trpcBff.applications.notes.update.useMutation();
  const updateApplicantNotesInStore = useUpdateApplicantNotesInStore();

  const requestKey = REQUEST_KEY.APPLICATION.UPDATE_NOTE;

  useHandleAppLoading(isLoading, requestKey);

  const updateNote = async (params: UpdateNoteParams) => {
    try {
      await mutateAsync(params);

      updateApplicantNotesInStore({
        notes: params.notes,
        applicationId: params.applicationId,
        notesUpdatedAt: formattedNowDate(),
        jobId
      });
    } catch (e) {
      throw getClientError(e);
    }
  };

  return updateNote;
};

const getClientError = (error) => {
  if (!error) return;

  const guid = error?.data?.httpError.guid;
  const responseBody = error?.data;

  return new ClientError('errors.defaultWithGuid', {
    guid,
    responseBody
  });
};

const formattedNowDate = () =>
  new Date().toLocaleString('default', {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  });
