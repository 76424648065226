import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <>
    <Path
      d="M18.5 17.5l2-2M16.5 15.5l2 2M18.5 5v12M7.5 3.5h10c.715 0 1 .634 1 1.5M5.5 6.5l-2 2M7.5 8.5l-2-2M5.5 19V6.5M16.5 20.5h-10c-.715 0-1-.634-1-1.5"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const RepostIcon = createIcon({ viewBox: '3 3 18 18', vectorComponent });
