import React, { useContext } from 'react';
import { JobType as InnerField } from '@common/components/PostJobForm/components/JobType/JobType';
import { FieldWrapper } from '../FieldWrapper';
import { FormContext } from '../FormContext';
import { jobTypeRules } from '../rules';

export default function JobType() {
  const { t } = useContext(FormContext);
  return (
    <FieldWrapper<string>
      name="jobType"
      rules={jobTypeRules(t)}
      render={(props) => <InnerField {...props} />}
    />
  );
}
