import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <>
    <Path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="m17.938 13.746-7.438-7-7.438 7"
    />
  </>
);

export const DownArrowIcon = createIcon({
  viewBox: '0 0 21 22',
  vectorComponent
});
