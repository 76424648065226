import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-native';
import { useSiteCountryCode } from '@common/hooks/localisation';
import { Box } from '@components/Box';
import { Button } from '@components/Button';
import { PrivacyIcon } from '@components/Icon';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';

type AddBusinessActionProps = {
  atBusinessLimit: boolean;
};

export const AddBusinessAction = ({
  atBusinessLimit
}: AddBusinessActionProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const countryCode = useSiteCountryCode();

  return (
    <Stack
      spacing="xsmall"
      tone="transparent"
      alignItems={{
        mobile: 'stretch',
        tablet: 'flex-end',
        desktop: 'flex-end'
      }}
    >
      <Box
        marginHorizontal={{ mobile: 'none', tablet: 'none', desktop: 'none' }}
      >
        <Button
          disabled={atBusinessLimit}
          data-test-key="add-business-button"
          onPress={() => history.push(`/${countryCode}/businesses/create`)}
        >
          {t('businessListingPage.action.addBusiness')}
        </Button>
      </Box>

      {atBusinessLimit && (
        <Row
          tone="transparent"
          spacing="xxsmall"
          alignItems="center"
          justifyContent={{
            mobile: 'center',
            tablet: 'flex-start',
            desktop: 'flex-start'
          }}
        >
          <PrivacyIcon />
          <Text size="small">{t('businessListingPage.state.full')}</Text>
        </Row>
      )}
    </Stack>
  );
};
