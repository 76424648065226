import { useCallback } from 'react';
import REQUEST_KEY from '@common/constants/requestKeys';
import { getRequestState } from '@store/app';
import { get } from '@store/entities/jobs/actions/get';
import { getJobById } from '@store/entities/jobs/selectors';
import { useAppDispatch, useAppSelector } from '@store/hooks';

export const useGetJob = (jobId: string) => {
  const dispatch = useAppDispatch();
  const job = useAppSelector(getJobById(jobId));

  const loadJobError = useAppSelector(
    getRequestState(REQUEST_KEY.JOB.LOAD)
  ).error;

  const getJobData = useCallback(() => {
    dispatch(get(jobId));
  }, [dispatch, jobId]);

  return {
    getJobData,
    hasLoadJobError: Boolean(loadJobError),
    job
  };
};
