import React, { ComponentType, forwardRef } from 'react';
import { View, ViewProps } from 'react-native';
import { AccessibilityProps } from 'react-native/Libraries/Components/View/ViewAccessibility';
import Svg from 'svgs';
import {
  IllustrationStyleProps,
  useIllustrationStyle
} from './useIllustrationStyle';

export type IllustrationProps = {
  accessibilityLabel?: AccessibilityProps['accessibilityLabel'];
  testID?: ViewProps['testID'];
} & IllustrationStyleProps;

export function createIllustration({
  viewBox,
  vectorComponent: Vector
}: {
  viewBox: string;
  vectorComponent: ComponentType;
} & IllustrationStyleProps) {
  return forwardRef<View, IllustrationProps>((props, ref) => {
    const { accessibilityLabel, testID, size } = props;
    const resolvedStyle = useIllustrationStyle({ size });

    return (
      <View
        accessibilityLabel={accessibilityLabel}
        style={{
          width: resolvedStyle.width,
          height: resolvedStyle.height
        }}
        testID={testID}
      >
        <Svg viewBox={viewBox} {...resolvedStyle} ref={ref}>
          <Vector />
        </Svg>
      </View>
    );
  });
}
