import { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Container.scss';
import classNames from 'classnames';
export default class Container extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    blockChildren: PropTypes.bool,
    extraTopMargin: PropTypes.bool,
    removeLineHeight: PropTypes.bool
  };

  render() {
    const { removeLineHeight, extraTopMargin, blockChildren, children, ...rest } = this.props; //eslint-disable-line no-unused-vars

    return (
      <div {...rest} className={classNames(
        styles.root,
        { [styles.extraTopMargin]: extraTopMargin },
        { [styles.lineHeight]: !removeLineHeight }
      )}>
        {children}
      </div>
    );
  }
}