import { useTranslation } from 'react-i18next';
import { REGEX } from '@common/constants/data';

export const useEmailValidation = () => {
  const { t } = useTranslation();

  const errors = {
    required: t('validations.general.email.required'),
    pattern: t('validations.general.email.format')
  };

  return {
    required: errors.required,
    validate: (value: string) => {
      if (!REGEX.EMAIL_FORMAT.test(value.trim())) {
        return errors.pattern;
      }
    }
  };
};
