import React from 'react';
import { PostJobFormWithAuto } from '@common/components/PostJobFormWithAuto';
import { useLoadJobIntoDraft } from '@common/componentsV2/PostJobFormWithAuto/hooks/useLoadJobIntoDraft';
import { useSubmitPostJobWithAuto } from '@common/componentsV2/PostJobFormWithAuto/hooks/useSubmitPostJob';
import REQUEST_KEY from '@common/constants/requestKeys';
import { useRedirectToJobNotFoundIfError } from '@common/hooks/useRedirectToJobNotFoundIfError';

/**
 * Renders a repost form, fetching the original job from the server
 * and loading it into the draft store.
 * @param originalJobId The id of the original job to repost
 * @returns A repost form
 */
export const RepostFormFetchFromOriginalJobWithAuto = ({
  originalJobId
}: {
  originalJobId: string;
}) => {
  const { submitPostJobWithAuto } = useSubmitPostJobWithAuto({ originalJobId });
  useLoadJobIntoDraft({ jobId: originalJobId });

  useRedirectToJobNotFoundIfError();

  return (
    <PostJobFormWithAuto
      mode="REPOST"
      submitPostJob={submitPostJobWithAuto}
      requestKey={REQUEST_KEY.JOB.CREATE}
    />
  );
};
