import React from 'react';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { ShiftAvailabilityField } from './ShiftAvailability';
import { useShiftAvailabilityValidation } from './hooks/useShiftAvailabilityValidation';

export const ShiftAvailabilityController = <T extends FieldValues>({
  name,
  control
}: UseControllerProps<T>) => {
  const shiftAvailabilityRules = useShiftAvailabilityValidation();

  return (
    <Controller
      name={name}
      control={control}
      rules={shiftAvailabilityRules}
      render={({ field: { onChange, value }, formState }) => {
        return (
          <ShiftAvailabilityField
            onChange={onChange}
            value={value}
            error={formState?.errors[name]?.message as string}
          />
        );
      }}
    />
  );
};
