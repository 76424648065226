/* eslint-disable react-native/no-inline-styles */
import React, { ReactElement, forwardRef, useState } from 'react';
import { Linking, Platform } from 'react-native';
import {
  ActivityIndicator,
  Pressable,
  PressableProps,
  Text as ReactNativeText,
  View
} from 'react-native';
import {
  LinkState,
  LinkStyleProps,
  useLinkStyle
} from '@components/Link/useLinkStyle';

export type ExternalLinkProps = {
  children?: string;
  icon?: ReactElement;
  loading?: boolean;
  testID?: PressableProps['testID'];
  href: string;
} & Omit<LinkStyleProps, 'state'>;

/**
 * Link component is an accessible element for navigation.
 */
export const ExternalLink = forwardRef<View, ExternalLinkProps>(
  (props, ref) => {
    const { children, loading, testID, href, icon, ...restProps } = props;
    const [linkState, setLinkState] = useState<LinkState>('default');
    const linkStyleProps = { state: linkState, ...restProps };
    const {
      containerStyle: { flexDirection, marginBottom, marginRight },
      pressableStyle,
      activityIndicatorStyle: {
        color: activityIndicatorColor,
        size: activityIndicatorSize
      },
      textStyle
    } = useLinkStyle(linkStyleProps);

    function handlePress() {
      if (Platform.OS === 'web') {
        window.open(href, '_blank');
      } else {
        Linking.openURL(href);
      }
    }

    return (
      <View>
        <View style={{ alignSelf: 'flex-start' }}>
          <Pressable
            style={pressableStyle}
            onPress={handlePress}
            testID={testID}
            ref={ref}
            onHoverIn={() => setLinkState('hover')}
            onHoverOut={() => setLinkState('default')}
            onPressIn={() => setLinkState('hover')}
            onPressOut={() => setLinkState('default')}
          >
            <View
              style={{
                alignItems: 'center',
                flexDirection
              }}
            >
              {icon || loading ? (
                <View style={{ marginBottom, marginRight }}>
                  {loading ? (
                    <ActivityIndicator
                      color={activityIndicatorColor}
                      size={activityIndicatorSize}
                    />
                  ) : (
                    icon
                  )}
                </View>
              ) : null}

              <ReactNativeText style={textStyle}>{children}</ReactNativeText>
            </View>
          </Pressable>
        </View>
      </View>
    );
  }
);
