import { RangeSalary } from '@seek/je-shared-data/lib/types/jobs';

export type useRangeAmountProps = {
  value?: Partial<RangeSalary['amount']>;
  onChange: (param?: Partial<RangeSalary['amount']>) => void;
};

export const useRangeAmount = ({ value, onChange }: useRangeAmountProps) => {
  const onMinAmountChange = (from: string) => {
    const hasNoAmount = !from && value?.to === undefined;

    if (hasNoAmount) {
      onChange(undefined);
    } else {
      onChange({
        ...value,
        from: from ? Number(from) : undefined
      });
    }
  };

  const onMaxAmountChange = (to: string) => {
    const hasNoAmount = !to && value?.from === undefined;

    if (hasNoAmount) {
      onChange(undefined);
    } else {
      onChange({
        ...value,
        to: to ? Number(to) : undefined
      });
    }
  };

  return {
    onMinAmountChange,
    onMaxAmountChange
  };
};
