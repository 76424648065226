import { trpcBff } from '@trpcBff';

type Response = ReturnType<typeof trpcBff.coins.pricing.get.useQuery>;

export type ErrorResponse = Response['error'];

export const useFetchNextExpiringCredit = () => {
  const { data, isError, error, isInitialLoading } =
    trpcBff.coins.nextExpiringCredit.get.useQuery(undefined, {
      staleTime: 5000,
      refetchOnWindowFocus: false,
      retry: false
    });

  return {
    data,
    isError,
    error,
    isLoading: isInitialLoading
  };
};
