import { useWindowDimensions } from 'react-native';
import { Breakpoint, breakpoints } from '@theme/tokens';

const isMobile = (width: number) => width > breakpoints.mobile;
const isTablet = (width: number) => width > breakpoints.tablet;
const isDesktop = (width: number) => width > breakpoints.desktop;

export function useResponsiveValue(defaultBreakpoint: Breakpoint = 'mobile') {
  const { width } = useWindowDimensions();

  if (isDesktop(width)) {
    return 'desktop';
  }

  if (isTablet(width)) {
    return 'tablet';
  }

  if (isMobile(width)) {
    return 'mobile';
  }

  return defaultBreakpoint;
}
