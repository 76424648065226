import React from 'react';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { Password } from './Password';
import { usePasswordValidation } from './usePasswordValidation';

interface PasswordControllerProps<T extends FieldValues>
  extends UseControllerProps<T> {
  onEnter?: () => void;
}

export const PasswordController = <T extends FieldValues>({
  name,
  control,
  onEnter
}: PasswordControllerProps<T>) => {
  const passwordRules = usePasswordValidation();

  return (
    <Controller
      name={name}
      control={control}
      rules={passwordRules}
      render={({ field: { onChange, onBlur, value }, formState }) => {
        return (
          <Password
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            error={formState?.errors[name]?.message as string}
            onEnter={onEnter}
          />
        );
      }}
    />
  );
};
