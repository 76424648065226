import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <>
    <Path
      d="M18.5 6.5v14h-13v-17H16"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M18.5 6.5h-3V3.816M16 3.5l2.5 3"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M8 6.5h5.5M8 9.1h7.5M8 11.7h7.5M8 14.3h7.5M8 16.9h7.5"
      strokeWidth={0.75}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const DocumentIcon = createIcon({
  viewBox: '3 3 18 18',
  vectorComponent
});
