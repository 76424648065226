import { grey900 } from '../../theme/palette/colours';
import Svg, { Path } from 'svgs';

function CloseIcon(props) {
  return (
    <Svg viewBox="5 5 14 14" fill="none" {...props}>
      <Path
        d="M5.5 5.5l13 13M18.5 5.5l-13 13"
        stroke={grey900}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default CloseIcon;
