import { useAppDispatch } from '@store/hooks';
import { updateLocalisation } from '@store/localisation';
import { GroupedActions } from '@store/store';

export function useSetLocalisation() {
  const dispatch = useAppDispatch();

  return {
    setLocalisation: (brandName, countryCode) => {
      dispatch(
        updateLocalisation(
          brandName,
          countryCode
        ) as unknown as GroupedActions<any>
      );
    }
  };
}
