import { useTranslation } from 'react-i18next';
import {
  AVAILABILITY,
  Availability
} from '@seek/je-shared-data/lib/types/jobs';

type AvailabilityOption = {
  label: string;
  value: Availability;
};
const { MORNING, AFTERNOON, EVENING } = AVAILABILITY;

export const useAvailabilityDisplay = () => {
  const { t } = useTranslation();

  const availabilityLabel: AvailabilityOption[] = [
    {
      value: MORNING,
      label: t('postJobForm.shiftAvailability.shiftTime.morning')
    },
    {
      value: AFTERNOON,
      label: t('postJobForm.shiftAvailability.shiftTime.afternoon')
    },
    {
      value: EVENING,
      label: t('postJobForm.shiftAvailability.shiftTime.evening')
    }
  ];

  return {
    availabilityLabel
  };
};
