import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { Box } from '@components/Box';
import { Dropdown, Field } from '@components/FormElements';
import { useJobType } from './hooks/useJobType';

type JobTypeProps = {
  value: string;
  onChange: () => void;
  error?: string;
};

export const JobType = (props: JobTypeProps) => {
  const { t } = useTranslation();
  const { jobTypeOptions } = useJobType();
  const { error, onChange, value } = props;

  return (
    <Field
      zIndex={5}
      label={t('postJobForm.fieldLabel.jobType')}
      message={
        error && (
          <Box marginTop="xxsmall">
            <ErrorMessage>{error}</ErrorMessage>
          </Box>
        )
      }
    >
      <Box zIndex={11}>
        <Dropdown
          items={jobTypeOptions}
          selectedItem={value}
          onChange={onChange}
          placeholder={t('postJobForm.jobType.selectJobType')}
          testID="job-form-jobType"
        />
      </Box>
    </Field>
  );
};
