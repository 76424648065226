/* eslint css-modules/no-unused-class: [2, { markAsUsed: [
  'lineHeight-small', 'lineHeight-medium', 'lineHeight-large',
  'fontSize-tiny', 'fontSize-small', 'fontSize-medium'
] }] */
import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './CardCopy.scss';

const MARGIN_SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium'
};

const DEVICE_TYPE = {
  MOBILE_ONLY: 'mobileOnly'
};

/**
 * @deprecated Use the @components/Text instead.
 **/
export default class CardCopy extends Component {
  static propTypes = {
    children: PropTypes.any.isRequired,
    lineHeight: PropTypes.oneOf(['small', 'medium', 'large']),
    fontSize: PropTypes.oneOf(['tiny', 'small', 'medium']),
    extraTopMargin: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]),
    extraBottomMargin: PropTypes.bool,
    maintainLineBreaks: PropTypes.bool,
    className: PropTypes.string,
    centered: PropTypes.oneOfType([
      PropTypes.oneOf([DEVICE_TYPE.MOBILE_ONLY]),
      PropTypes.bool
    ])
  };

  static defaultProps = {
    lineHeight: 'medium',
    fontSize: 'medium'
  };

  getTopMarginStyling() {
    const { extraTopMargin } = this.props;

    if (extraTopMargin === MARGIN_SIZES.SMALL) {
      return styles.extraSmallTopMargin;
    }

    return styles.extraMediumTopMargin;
  }

  getCenteredStyling() {
    const { centered } = this.props;

    if (centered === DEVICE_TYPE.MOBILE_ONLY) {
      return styles.centeredMobileOnly;
    }

    return styles.centered;
  }

  render() {
    const { children, extraTopMargin, extraBottomMargin, className = '', maintainLineBreaks, centered, lineHeight, fontSize, ...propsToSpread } = this.props;

    const classes = classNames(
      styles[`lineHeight-${lineHeight}`],
      styles[`fontSize-${fontSize}`],
      {
        [this.getTopMarginStyling()]: extraTopMargin,
        [styles.extraBottomMargin]: extraBottomMargin,
        [styles.maintainLineBreaks]: maintainLineBreaks,
        [this.getCenteredStyling()]: centered
      },
      className
    );

    return (
      <div
        {...propsToSpread}
        className={classes}>
        {children}
      </div>
    );
  }
}