import { type RightToWork } from '@common/constants/data';
import {
  useGetUserPackage,
  useUserHasPremiumFilterAccess
} from '@common/hooks/userPackages';
import { ApplicationStatus } from '@common/types';
import { trackApplicationFiltersApplied } from '@store/application_filters';
import { useAppDispatch } from '@store/hooks';
import { ApplicationSortValue, FILTERS } from '../../../../../../types';

export function useTrackFiltersApplied({
  filters
}: {
  filters: {
    [FILTERS.SORT_APPLICATIONS]: ApplicationSortValue;
    [FILTERS.APPLICANT_STATUS]?: ApplicationStatus[];
    [FILTERS.DISTANCE]?: number;
    [FILTERS.RIGHT_TO_WORK]?: RightToWork[];
    [FILTERS.VIDEO_SCREENING]?: boolean;
  };
}) {
  const dispatch = useAppDispatch();
  const userPackage = useGetUserPackage();
  const userHasPremiumFilters = useUserHasPremiumFilterAccess();

  return () =>
    dispatch(
      trackApplicationFiltersApplied({
        userPackageType: userPackage.packageType,
        userHasPremiumFilters,
        applicantStatusFilter: filters.applicant_status,
        distanceFilter: filters.distance,
        videoScreeningFilter: filters.video_screening,
        rightToWorkFilter: filters.right_to_work,
        sortBy: filters.sort_applications
      })
    );
}
