import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import Spinner from '../../../common/components/Spinner/Spinner';
import Div from '../../../common/components/Div/Div';

import styles from './UniversalLoader.scss';

@connect(
  ({ app }) => ({
    loadStack: app.loadStackWithSpinner
  })
)
export default class UniversalLoader extends Component {
  static propTypes = {
    loadStack: PropTypes.array.isRequired
  };

  render() {
    const { loadStack } = this.props;
    const isLoading = loadStack && loadStack.length > 0;

    return (
      <Div className={styles.root}>
        <Transition in={isLoading}
          timeout={{ enter: 200, exit: 400 }}
          appear
          unmountOnExit>
          {() => (
            <Div className={styles.loaderWrapper}>
              <Spinner size="80" fullPageOverlay delay={0} />
            </Div>
          )}
        </Transition>
      </Div>
    );
  }
}
