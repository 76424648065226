import { BrandName } from '@seek/je-shared-data/lib/types/brand';

const jobTitlesImports = {
  jse_id: async () => await import('@seek/jora-matchbox/job-title-jse-id.json'),
  jse_sg: async () => await import('@seek/jora-matchbox/job-title-jse-sg.json')
};

type LoadJobTitles = {
  brandName: BrandName;
  countryCode: string;
};

export default async function loadJobTitles({
  brandName,
  countryCode
}: LoadJobTitles) {
  const brand = brandName === BrandName.JOBSTREETEXPRESS ? 'jse' : brandName;
  try {
    const jobTitles = await jobTitlesImports[`${brand}_${countryCode}`]();

    return jobTitles.default;
  } catch (error) {
    console.error(
      `Failed to load job titles for: ${brand}-${countryCode}`,
      error
    );
  }
}
