import React from 'react';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { Box } from '@components/Box';
import { Field, Input } from '@components/FormElements';
import { Stack } from '@components/Stack';

type BusinessRegistrationNumberProps = {
  value: string;
  onChange: () => void;
  onBlur: () => void;
  label: string;
  error?: string;
};

export const BusinessRegistrationNumber = (
  props: BusinessRegistrationNumberProps
) => {
  const { error, onChange, onBlur, value, label } = props;

  return (
    <Stack spacing="small">
      <Field
        label={label}
        message={
          error && (
            <Box marginTop="xxsmall">
              <ErrorMessage>{error}</ErrorMessage>
            </Box>
          )
        }
      >
        <Input
          id="verify-account-form-businessRegistrationNumber"
          testID="business-registration-number-input-field"
          tone={error ? 'danger' : 'neutral'}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          autoCapitalize="none"
        />
      </Field>
    </Stack>
  );
};
