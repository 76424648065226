import { EventTypes } from 'redux-segment';

export const trackMinLengthValidationError = () => ({
  type: EventTypes.track,
  meta: {
    analytics: {
      eventType: EventTypes.track,
      eventPayload: {
        event: 'Job description min length validation error displayed'
      }
    }
  }
});

export const trackMaxLengthValidationError = () => ({
  type: EventTypes.track,
  meta: {
    analytics: {
      eventType: EventTypes.track,
      eventPayload: {
        event: 'Job description max length validation error displayed'
      }
    }
  }
});
