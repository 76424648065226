import { Component } from 'react';
import styles from './GridOverlay.scss';
import { isWebClient } from '../../helpers/detection';

export default class GridOverlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showGrid: false
    };
  }

  handleToggleClick() {
    this.setState({
      showGrid: !this.state.showGrid
    });
  }

  row() {
    return (
      <div className={styles.row}>
        <div className={styles.margin}></div>
        <div className={styles.column}></div>
        <div className={styles.gutter}></div>
        <div className={styles.column}></div>
        <div className={styles.gutter}></div>
        <div className={styles.column}></div>
        <div className={styles.gutter}></div>
        <div className={styles.column}></div>
        <div className={styles.gutter}></div>
        <div className={styles.column}></div>
        <div className={styles.gutter}></div>
        <div className={styles.column}></div>
        <div className={styles.gutter}></div>
        <div className={styles.column}></div>
        <div className={styles.gutter}></div>
        <div className={styles.column}></div>
        <div className={styles.gutter}></div>
        <div className={styles.column}></div>
        <div className={styles.gutter}></div>
        <div className={styles.column}></div>
        <div className={styles.gutter}></div>
        <div className={styles.column}></div>
        <div className={styles.gutter}></div>
        <div className={styles.column}></div>
        <div className={styles.margin}></div>
      </div>
    );
  }

  rows() {
    const gridRowHeight = 8;
    let rowCount = 0;

    if (isWebClient()) {
      rowCount = parseInt(window.document.body.scrollHeight / gridRowHeight);
    }

    const dummyArray = Array.apply(null, new Array(rowCount)).map(function () {
      return 1;
    });

    return dummyArray.map(function (element, index) {
      return (
        <div key={`gridRow${index}`}> { this.row() } </div>
      );
    }.bind(this));
  }

  render() {
    const {showGrid} = this.state;

    return (
      <div {...this.props}>
        <a className={styles.toggle} onClick={this.handleToggleClick.bind(this)}>grid</a>
        {
          (showGrid) ? [
            <div key="show" className={styles.grid}>
              { this.rows() }
            </div>
          ] : <div key="hide"></div>
        }
      </div>
    );
  }
}