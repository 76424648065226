import React from 'react';
import { useTranslation } from 'react-i18next';
import REQUEST_KEY from '@common/constants/requestKeys';
import { useAtBusinessLimit } from '@common/hooks/userPackages';
import { RedirectTo } from '@common/search';
import { getRequestState } from '@store/app';
import { getUser } from '@store/auth';
import { BusinessWithUsers } from '@store/entities/businesses/types';
import { useAppSelector } from '@store/hooks';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { PostJobFormBusiness } from '../../types';
import { DisplayBusinessInformation } from './DisplayBusinessInformation';
import { EditableBusinessInformation } from './EditableBusinessInformation';
import { LoadBusinessErrorMessage } from './components/LoadBusinessErrorMessage';

type BusinessInformationProps = {
  isBusinessEditable: boolean;
  shouldRedirectBusinessCreatedTo: RedirectTo | undefined;
  handleBusinessSelected: (business: BusinessWithUsers) => void;
  business?: PostJobFormBusiness;
};

export const BusinessInformation = ({
  business,
  isBusinessEditable,
  shouldRedirectBusinessCreatedTo,
  handleBusinessSelected
}: BusinessInformationProps) => {
  const { isAuthenticated } = useAppSelector(getUser);
  const { t } = useTranslation();
  const { error: loadBusinessesError } = useAppSelector(
    getRequestState(REQUEST_KEY.BUSINESS.LIST)
  );
  const atBusinessLimit = useAtBusinessLimit();

  const renderBusinessInformation = (selectedBusiness: PostJobFormBusiness) =>
    isBusinessEditable && shouldRedirectBusinessCreatedTo ? (
      <EditableBusinessInformation
        selectedBusiness={selectedBusiness}
        shouldRedirectBusinessCreatedTo={shouldRedirectBusinessCreatedTo}
        onBusinessSelected={handleBusinessSelected}
        disableAddBusiness={atBusinessLimit}
      />
    ) : (
      <DisplayBusinessInformation selectedBusiness={selectedBusiness} />
    );

  return isAuthenticated ? (
    <Stack spacing={loadBusinessesError ? 'none' : 'medium'}>
      <Text>
        {isBusinessEditable
          ? t('postJobForm.businessInformation.businessWillBeLinkedToJob')
          : t('postJobForm.businessInformation.businessAlreadyLinkedToJob')}
      </Text>
      {loadBusinessesError ? <LoadBusinessErrorMessage /> : null}
      {business ? renderBusinessInformation(business) : null}
    </Stack>
  ) : null;
};
