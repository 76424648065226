import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

function vectorComponent() {
  return (
    <>
      <Path
        d="M11.993 19.5H3.5V7.866L12 4.5l-.007 15zM20.5 19.5h-8.493v-13L20.5 8.065V19.5z"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M6.751 11H5.25A.252.252 0 015 10.751V9.25C5 9.114 5.114 9 5.249 9H6.75c.135 0 .249.114.249.249v1.492c0 .145-.104.259-.249.259zM9.751 11H8.25A.252.252 0 018 10.751V9.25C8 9.114 8.114 9 8.249 9H9.75c.135 0 .249.114.249.249v1.492c0 .145-.114.259-.249.259zM6.751 14H5.25A.252.252 0 015 13.751V12.25c0-.135.114-.249.249-.249H6.75c.135 0 .249.114.249.249v1.492c0 .145-.104.259-.249.259zM9.751 14H8.25A.252.252 0 018 13.751V12.25c0-.135.114-.249.249-.249H9.75c.135 0 .249.114.249.249v1.492c0 .145-.114.259-.249.259zM15.751 11H14.25a.252.252 0 01-.249-.249V9.25c0-.135.114-.249.249-.249h1.502c.135 0 .249.114.249.249v1.492c0 .145-.104.259-.249.259zM18.751 11H17.25a.252.252 0 01-.249-.249V9.25c0-.135.114-.249.249-.249h1.502c.135 0 .249.114.249.249v1.492c0 .145-.104.259-.249.259zM15.751 14H14.25a.253.253 0 01-.249-.25v-1.5c0-.135.114-.25.249-.25h1.502c.135 0 .249.115.249.25v1.5a.24.24 0 01-.249.25zM18.751 14H17.25a.253.253 0 01-.249-.25v-1.5c0-.135.114-.25.249-.25h1.502c.135 0 .249.115.249.25v1.5a.24.24 0 01-.249.25zM15.751 17H14.25a.253.253 0 01-.249-.25v-1.5c0-.135.114-.25.249-.25h1.502c.135 0 .249.115.249.25v1.5a.24.24 0 01-.249.25zM18.751 17H17.25a.253.253 0 01-.249-.25v-1.5c0-.135.114-.25.249-.25h1.502c.135 0 .249.115.249.25v1.5a.24.24 0 01-.249.25z"
        strokeWidth={0.5}
      />
      <Path
        d="M5.5 19v-3.5h4V19"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path d="M7.5 16v3.5" strokeWidth={0.5} strokeMiterlimit={10} />
    </>
  );
}

export const BuildingIcon = createIcon({
  viewBox: '3 3 18 18',
  vectorComponent
});
