import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatStringToTitleCase } from '@common/helpers/textFormatting';
import { useSiteCountryCode } from '@common/hooks/localisation';
import { useRedirect } from '@common/hooks/useRedirect';
import { Box } from '@components/Box';
import { EditIcon } from '@components/Icon';
import { Link } from '@components/Link';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';

type AccountDetailProps = {
  givenName?: string;
  surname?: string;
  email?: string;
  mobile?: string;
};

export const AccountDetails = ({
  givenName,
  surname,
  email,
  mobile
}: AccountDetailProps) => {
  const { t } = useTranslation();
  const countryCode = useSiteCountryCode();
  const { redirect } = useRedirect();

  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack>
        <Box>
          <Text>{formatStringToTitleCase(`${givenName} ${surname}`)}</Text>
          <Text>{email}</Text>
          <Text>{mobile}</Text>
        </Box>
      </Stack>

      <Box justifyContent="center">
        <Link
          onPress={redirect(`/${countryCode}/account/update-details`)}
          variant="secondary"
          align="vertical"
          icon={<EditIcon variant="outline" />}
        >
          {t('common.action.edit')}
        </Link>
      </Box>
    </Stack>
  );
};
