import React, { useContext } from 'react';
import { JobTypeRadio as InnerField } from '@common/components/PostJobForm/components/JobTypeRadio';
import { JOB_TYPES } from '@seek/je-shared-data';
import { FieldWrapper } from '../FieldWrapper';
import { FormContext } from '../FormContext';
import { jobTypeRules } from '../rules';

export default function JobTypeRadio() {
  const { t } = useContext(FormContext);
  return (
    <FieldWrapper<JOB_TYPES>
      name="jobType"
      rules={jobTypeRules(t)}
      render={(props) => <InnerField {...props} />}
    />
  );
}
