import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { SingleSelect } from '@common/components/FormElements';
import { ShiftAvailability } from '@seek/je-shared-data/lib/types/jobs';
import { Box } from '@components/Box';
import { Field } from '@components/FormElements';
import { Stack } from '@components/Stack';
import { SpecificShiftAvailability } from './components';
import {
  useShiftAvailabilityTypeRadio,
  useSpecificShiftAvailability
} from './hooks';

export type Props = {
  value: ShiftAvailability;
  onChange: (value: ShiftAvailability) => void;
  error?: string;
};

export const ShiftAvailabilityRadioField = ({
  value,
  onChange,
  error
}: Props) => {
  const { t } = useTranslation();

  const {
    shiftAvailabilityType,
    shiftAvailabilityTypeOptions,
    onShiftAvailabilityTypeChange
  } = useShiftAvailabilityTypeRadio(value, onChange);

  const {
    onCheckboxPressed,
    selectedAvailability,
    onAllCheckboxPressed,
    selectedAllAvailability
  } = useSpecificShiftAvailability(value, onChange);

  return (
    <Stack spacing="small" testID="shift-availability">
      <Field
        label={t('postJobForm.shiftAvailability.title')}
        message={
          error && (
            <Box marginTop="small">
              <ErrorMessage>{error}</ErrorMessage>
            </Box>
          )
        }
      >
        <Box>
          <div className="my-xs">
            <SingleSelect
              onChange={onShiftAvailabilityTypeChange}
              options={shiftAvailabilityTypeOptions}
              value={shiftAvailabilityType}
            />
          </div>

          {shiftAvailabilityType === 'SPECIFIC' && (
            <SpecificShiftAvailability
              value={selectedAvailability}
              selectedAllAvailability={selectedAllAvailability}
              onAvailabilityCheckboxPress={onCheckboxPressed}
              onAllAvailabilityCheckboxPress={onAllCheckboxPressed}
            />
          )}
          <Box />
        </Box>
      </Field>
    </Stack>
  );
};
