import { useLocation } from 'react-router-native';
import { useSubmitPostJob } from '@common/componentsV2/PostJobForm/hooks/useSubmitPostJob';
import { useSubmitPostJobWithAuto } from '@common/componentsV2/PostJobFormWithAuto/hooks/useSubmitPostJob';
import { useRedirect } from '@common/hooks/useRedirect';
import { useGetSourceParams } from '@common/hooks/useSearchQueryParam';
import { updateSlug } from '@src/lib/window';
import { getBusinesses } from '@store/entities/businesses/selectors';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';

const useGetUserHasBusiness = () => {
  const businesses = useAppSelector(getBusinesses());

  return Boolean(businesses.length);
};

const useRedirectToCreateBusinessPage = () => {
  const { search } = useLocation();
  const { redirect } = useRedirect();
  const { countryCode } = useAppSelector(getLocalisation);

  return redirect(`/${countryCode}/businesses/create${search}`);
};

const useUpdateDepricatedUrls = () => {
  const { pathname } = useLocation();
  return () => {
    if (pathname.includes('select-job-role')) {
      updateSlug('post-job', window, false);
    }
  };
};

export const usePostJob = () => {
  const { submitPostJob } = useSubmitPostJob();
  const { submitPostJobWithAuto } = useSubmitPostJobWithAuto();
  const sourceParams = useGetSourceParams();
  const userHasBusiness = useGetUserHasBusiness();
  const redirectToCreateBusinessPage = useRedirectToCreateBusinessPage();
  const updateDepricatedUrls = useUpdateDepricatedUrls();

  return {
    submitPostJobWithAuto,
    submitPostJob,
    sourceParams,
    userHasBusiness,
    redirectToCreateBusinessPage,
    updateDepricatedUrls
  };
};
