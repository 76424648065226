import React, { useContext } from 'react';
import { Email as InnerField } from '@common/components/Account/components/Email/Email';
import { email } from '@common/components/Account/rules';
import { FieldWrapper } from '../FieldWrapper';
import { FormContext } from '../FormContext';

export const Email = () => {
  const { t } = useContext(FormContext);
  return (
    <FieldWrapper<string>
      name="email"
      rules={email(t)}
      render={(props) => {
        const mergedProps = { ...props, t };
        return <InnerField {...mergedProps} showExplanationText={false} />;
      }}
    />
  );
};
