import { REGEX } from '@common/constants/data';
import type { T } from '../types';

interface EmailVerificationCodeErrors {
  required: string;
  format: string;
}

export const emailVerificationCode = (t: T) => {
  const errors = {
    required: t('validations.user.verifyEmail.minLength'),
    format: t('validations.user.verifyEmail.format')
  } as EmailVerificationCodeErrors;

  return {
    required: errors.required,
    validate: (value: string) => {
      if (!REGEX.EMAIL_VERIFICATION_CODE.test(value.trim())) {
        return errors.format;
      }
    }
  };
};
