import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-native';
import REQUEST_KEY from '@common/constants/requestKeys';
import {
  useGetCreateBusinessLink,
  useGetPostJobLink
} from '@common/hooks/appPageLinks';
import { getRequestState } from '@store/app';
import { getDraftBusiness, getDraftJob } from '@store/draft';
import {
  createBusiness,
  loadBusinesses
} from '@store/entities/businesses/actions';
import { postJob } from '@store/entities/jobs';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getUserData } from '@store/userData';

export const useCreateAccount = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { postJobLink } = useGetPostJobLink();
  const userData = useAppSelector(getUserData);
  const draftJob = useAppSelector(getDraftJob);
  const createBusinessLink = useGetCreateBusinessLink();
  const draftBusiness = useAppSelector(getDraftBusiness);
  const { error: createBusinessError } = useAppSelector(
    getRequestState(REQUEST_KEY.BUSINESS.CREATE)
  );
  const { error: createJobError } = useAppSelector(
    getRequestState(REQUEST_KEY.JOB.CREATE)
  );

  const handleSubmit = async () => {
    if (Object.keys(draftJob).length === 0) {
      return history.push(postJobLink);
    }

    // Check for existing business
    const businesses = (await dispatch(loadBusinesses())).result;
    if (businesses?.length > 0) {
      // Use recently created business
      const { id, name, recruitmentCompanyName, locations } = businesses[0];

      const businessData = {
        id,
        name,
        geolocation: locations[0],
        recruitmentCompanyName
      };
      const response = await dispatch(
        postJob({ ...draftJob, userData, businessData, t })
      );

      if (response.error || createJobError) return history.push(postJobLink);

      return response;
    }

    if (Object.keys(draftBusiness).length === 0) {
      return history.push(createBusinessLink);
    }

    const response = await submitBusiness();

    if (typeof response === 'object') {
      const { businessId, token } = response;
      return await submitJob({ ...draftBusiness, businessId, token });
    }
  };

  const submitJob = async (business) => {
    const { businessId, businessAddress, name, recruitmentCompanyName, token } =
      business;
    const businessData = {
      id: businessId,
      name,
      geolocation: businessAddress,
      recruitmentCompanyName,
      token
    };

    const response = await dispatch(
      postJob({ ...draftJob, userData, businessData, t })
    );

    if (response.error || createJobError) return history.push(postJobLink);
  };

  const submitBusiness = async (): Promise<{
    businessId: string;
    token: string;
  } | void> => {
    const response = (
      await dispatch(createBusiness(draftBusiness, userData['source.query']))
    ).result;

    if (!response || createBusinessError) {
      return history.push(createBusinessLink);
    }

    return response;
  };

  return {
    handleSubmit,
    submitJob,
    submitBusiness
  };
};
