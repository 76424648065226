import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorIcon } from '@components/Icon';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';

export const VideoError = () => {
  const { t } = useTranslation();

  return (
    <Stack
      tone="black"
      spacing="xxsmall"
      justifyContent="center"
      padding="xlarge"
    >
      <Row justifyContent="center" tone="transparent">
        <ErrorIcon tone="white" size="xlarge" variant="outline" />
      </Row>
      <Text tone="white" size="large" weight="regular" align="center">
        {t('errors.application.screeningVideo.title')}
      </Text>
      <Text tone="white" align="center">
        {t('errors.application.screeningVideo.description')}
      </Text>
    </Stack>
  );
};
