import { useCallback } from 'react';
import { useGetSourceParams } from '@common/hooks/useSearchQueryParam';
import { saveDraftForm } from '@store/draft';
import { JOB_DRAFT_FORM } from '@store/draft/constants';
import { useAppDispatch } from '@store/hooks';

export const useSaveJobDraft = () => {
  const dispatch = useAppDispatch();
  const sourceParams = useGetSourceParams();

  const saveJobDraft = useCallback(
    (data: any) => {
      dispatch(
        saveDraftForm(JOB_DRAFT_FORM, {
          ...data,
          sourceParams
        })
      );
    },
    [sourceParams, dispatch]
  );

  return {
    saveJobDraft
  };
};
