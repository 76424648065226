import _unescapeHtmlEntities from 'lodash.unescape';
import { formatIsoToDate } from '@common/helpers/dateTime';
import { GetJobItem } from './types';

export function toFormattedJob(job): GetJobItem {
  return {
    ...job,
    countryCode: job.countryCode.toLowerCase(),
    title: _unescapeHtmlEntities(job.title),
    company: _unescapeHtmlEntities(job.company),
    businessName: _unescapeHtmlEntities(job.businessName),
    ...(job.startDate
      ? { startDate: formatIsoToDate(job.startDate, 'DD/MM/YYYY') }
      : {})
  };
}
