import React, { useContext } from 'react';
import { FormContext } from '../../FormContext';
import { FieldWrapper } from '../FieldWrapper';
import { BusinessWebsite as InnerField } from './BusinessWebsite';
import { useBusinessWebsiteRules } from './useBusinessWebsiteRules';

type Props = { label: string; helperText: string };

export const BusinessWebsite = (props: Props) => {
  const { t } = useContext(FormContext);

  return (
    <FieldWrapper<string>
      name="website"
      rules={useBusinessWebsiteRules(t)}
      render={(renderProps) => {
        const propsToPass = {
          ...renderProps,
          ...props
        };

        return <InnerField {...propsToPass} />;
      }}
    />
  );
};
