import { Component } from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash.omit';

import InputField from '../InputField/InputField';
import Div from '../../Div/Div';

import * as validationConstants from '../../../constants/validation';

export default class EmailAddressField extends Component {
  static propTypes = {
    marginTop: PropTypes.string,
    validationMessages: PropTypes.object
  };

  static defaultProps = {
    validationMessages: validationConstants.GENERAL_VALIDATION_MESSAGES.EMAIL
  };

  render() {
    const { validationMessages, marginTop } = this.props;

    return (
      <Div marginTop={marginTop}>
        <InputField
          fieldId="email"
          label="Email"
          type="email"
          blacklisthotjar
          validationMessages={validationMessages}
          {...omit(this.props, 'extraTopMargin')} />
      </Div>
    );
  }
}