import { trackAppStoreButtonClicked } from '@common/actions';
import { openExternalURL } from '@common/helpers/externalConnection';
import { useAppStoreLink } from '@common/hooks/useAppStoreLink';
import { useAppDispatch } from '@store/hooks';

type DeviceType = 'ios' | 'android';

export const useOpenAppStoreLink = () => {
  const { appStoreLinks } = useAppStoreLink();
  const dispatch = useAppDispatch();

  const track = (type) => {
    dispatch(trackAppStoreButtonClicked({ type }));
  };

  return {
    onPressAppStore: (deviceType: DeviceType) => {
      track(deviceType);
      openExternalURL(appStoreLinks[deviceType]);
    }
  };
};
