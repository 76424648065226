import { SupportLevel } from '@seek/je-shared-data/lib/types/brand';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';

/**
 * Retrieves the support tier from the localisation state.
 * @returns {SupportTier} The support tier.
 */
export function useSupportTier(): SupportLevel {
  const { supportTier } = useAppSelector(getLocalisation);
  return supportTier as SupportLevel;
}
