import React, { forwardRef } from 'react';
import {
  Pressable as ReactNativePressable,
  PressableProps as ReactNativePressableProps,
  View
} from 'react-native';
import { Box, BoxProps } from '@components/Box';

export type PressableProps = {
  children?: ReactNativePressableProps['children'];
  testID?: ReactNativePressableProps['testID'];
  onPress?: () => void;
} & BoxProps;

/**
 * Pressable component is a wrapper for the native Pressable component.
 */
export const Pressable = forwardRef<View, PressableProps>((props, ref) => {
  const { testID, onPress, direction = 'column', ...restProps } = props;

  return (
    <ReactNativePressable onPress={() => onPress?.()} testID={testID} ref={ref}>
      <Box direction={direction} {...restProps} />
    </ReactNativePressable>
  );
});
