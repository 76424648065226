import { loadStripe } from '@stripe/stripe-js';
import type { StripeElements } from '@stripe/stripe-js';
import { ClientError } from '../../../../common/helpers/errors';
import { getUuid } from '../../../../common/helpers/uuid/uuid';
import REQUEST_KEY from '../../../constants/requestKeys';
import { endLoading, startLoading, updateCustomError } from '../../app/actions';
import * as constants from '../constants';
import { getConfirmPaymentError } from './utils';

export type PaymentDetails = {
  stripeConfig: {
    web: { elements?: StripeElements | null };
    native?: { clientId?: string };
  };
  successfulRedirectUrl: string;
  data: { email: string; nameOnCard: string };
};

export function confirmPayment(
  stripe,
  {
    stripeConfig: {
      web: { elements }
    },
    successfulRedirectUrl,
    data: { email, nameOnCard }
  }: PaymentDetails
) {
  return {
    types: [
      constants.CONFIRM_PAYMENT,
      constants.CONFIRM_PAYMENT_SUCCESS,
      constants.CONFIRM_PAYMENT_FAIL
    ],
    promise: async (dispatch) => {
      if (!stripe && !elements) {
        return;
      }

      try {
        startLoading(dispatch, REQUEST_KEY.PAYMENT.CONFIRM_PAYMENT, true);

        const response = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: successfulRedirectUrl,
            payment_method_data: {
              billing_details: {
                email,
                ...(nameOnCard && { name: nameOnCard })
              }
            }
          }
        });

        if (!response.ok) {
          updateCustomError(
            dispatch,
            REQUEST_KEY.PAYMENT.CONFIRM_PAYMENT,
            getConfirmPaymentError(response.error)
          );
        }

        return;
      } catch (err) {
        const error = new ClientError('errors.defaultWithGuid', {
          guid: `ui-${getUuid()}`,
          responseBody: err instanceof Error ? err?.message : ''
        });
        updateCustomError(dispatch, REQUEST_KEY.PAYMENT.CONFIRM_PAYMENT, error);
        throw error;
      } finally {
        endLoading(dispatch, REQUEST_KEY.PAYMENT.CONFIRM_PAYMENT, true);
      }
    }
  };
}

export function loadStripeInstance() {
  return async (dispatch, getState) => {
    const {
      config: { stripePublicKeys },
      localisation
    } = getState();
    const {
      brandConfig: { name: brand },
      countryCode
    } = localisation;

    const stripePublicKey = stripePublicKeys[brand]?.[countryCode];
    if (!stripePublicKey) {
      return;
    }

    const result = await loadStripe(stripePublicKey, {
      locale: countryCode
    });

    dispatch({
      type: constants.LOAD_STRIPE_INSTANCE,
      result
    });
  };
}
