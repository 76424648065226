import { useActiveJobs } from '@common/hooks/job';
import { useJobLimit } from '../useJobLimit';

export const atJobLimit = (jobCount: number, jobLimit: number): boolean =>
  jobCount >= jobLimit;

export const useAtJobLimit = () => {
  const jobCount = useActiveJobs().length;
  const jobLimit = useJobLimit();
  if (!jobLimit) return false;

  return atJobLimit(jobCount, jobLimit);
};
