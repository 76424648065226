import React from 'react';
import { Surname as InnerField } from '@common/components/Account/components/Surname';
import { surname as surnameRules } from '@common/components/Account/rules';
import { FieldWrapper } from '../FieldWrapper';

export const Surname = () => {
  return (
    <FieldWrapper<string>
      name="surname"
      rules={surnameRules}
      render={(props) => {
        return <InnerField {...props} />;
      }}
    />
  );
};
