import React from 'react';
import { useTranslation } from 'react-i18next';
import { SingleSelect } from '@common/components/FormElements';
import { SupportedCurrencies } from '@seek/je-shared-data/lib/types/brand';
import { Salary } from '@seek/je-shared-data/lib/types/jobs';
import { Column } from '@components/Column';
import { Modal } from '@components/Modal';
import { Row } from '@components/Row';
import { useChangeCurrencyModal } from './hooks/useChangeCurrencyModal';

type ChangeCurrencyModalProps = {
  visible: boolean;
  value: string;
  onClose: () => void;
  onCurrencyChange: (currency: Salary['currency']) => void;
};

export const ChangeCurrencyModal = ({
  visible,
  value,
  onClose,
  onCurrencyChange
}: ChangeCurrencyModalProps) => {
  const { t } = useTranslation();
  const { currencyOptions } = useChangeCurrencyModal();

  return (
    <Modal
      title={t('postJobForm.expectedSalary.changeCurrencyModal.title')}
      visible={visible}
      testID="change-currency-modal"
      onRequestClose={onClose}
    >
      <Row width="full" alignItems="center">
        <Column width="1/2">
          <SingleSelect
            options={currencyOptions.leftColumn}
            onChange={(selectedCurrency) =>
              onCurrencyChange(selectedCurrency as SupportedCurrencies)
            }
            value={value}
            testID="change-currency-modal-left-column"
          />
        </Column>

        <Column width="1/2">
          <SingleSelect
            options={currencyOptions.rightColumn}
            onChange={(selectedCurrency) =>
              onCurrencyChange(selectedCurrency as SupportedCurrencies)
            }
            value={value}
            testID="change-currency-modal-right-column"
          />
        </Column>
      </Row>
    </Modal>
  );
};
