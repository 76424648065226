import { avatarSizes } from './avatar';
import { borderWidth } from './borders';
import { colors } from './colors';
import { iconSizes } from './icon';
import { illustrationSizes } from './illustration';
import { logoSizes } from './logo';
import { radius } from './radius';
import { shadow } from './shadow';
import { sizes } from './size';
import { spacing } from './spacing';
import {
  fontCssSrc,
  fontFamily,
  fontFamilyWebFallback,
  fontSizes,
  fontWeights,
  letterSpacing,
  lineHeights
} from './typography';

export const tokens = {
  avatarSizes,
  border: {
    radius,
    width: borderWidth
  },
  colors,
  icon: {
    // Components define what size
    iconSizes
  },
  illustration: {
    // Components define what size
    illustrationSizes
  },
  logo: {
    logoSizes
  },
  shadow,
  sizes,
  spacing,
  typography: {
    fontCssSrc,
    fontFamily,
    fontFamilyWebFallback,
    fontWeights,
    fontSizes,
    lineHeights,
    letterSpacing
  }
} as const;

export type Tokens = typeof tokens;
