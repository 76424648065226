import { useEffect, useState } from 'react';
import { CURRENCY_SYMBOLS } from '@common/constants/data';
import { AD_TYPE } from '@seek/je-shared-data';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { useAppSelector } from '@store/hooks';
import { getPricingItems } from '@store/payment/selectors';

/**
 * Custom hook that formats the currency pricing based on the job country code and ad type.
 * @param jobCountryCode The country code of the job.
 * @param adType The type of the ad.
 * @returns The formatted price as a string.
 */
export const useFormatCurrencyPricing = (
  jobCountryCode: SupportedCountryCode,
  adType: AD_TYPE
) => {
  const pricingItems = useAppSelector(getPricingItems(jobCountryCode));
  const [price, setPrice] = useState<string>('-');

  useEffect(() => {
    if (pricingItems) {
      const { currency, pricing } = pricingItems;

      setPrice(formatPrice(currency, pricing[adType]));
    }
  }, [jobCountryCode, pricingItems, adType]);

  return price;
};

const formatPrice = (currency: string, price: number) => {
  const convertCentsToDollars = (centsAmount: number) => centsAmount / 100;

  return price
    ? `${CURRENCY_SYMBOLS[currency]}${convertCentsToDollars(price)}`
    : '-';
};
