import React from 'react';
import { CardAction } from '@components/Card';
import { DocumentIcon } from '@components/Icon';
import { Link } from '@components/Link';
import { useViewProfileDisplay } from './useViewProfileAction';

type Props = {
  responsive: boolean;
  onViewProfilePressed: () => void;
  isProfileExpanded: boolean;
  testID?: string;
};

export const ViewProfileAction = ({
  testID,
  responsive,
  isProfileExpanded,
  onViewProfilePressed
}: Props) => {
  const viewProfileActionText = useViewProfileDisplay(isProfileExpanded);

  return (
    <CardAction>
      <Link
        onPress={onViewProfilePressed}
        align={{
          mobile: responsive ? 'vertical' : 'horizontal',
          tablet: 'horizontal',
          desktop: 'horizontal'
        }}
        variant="secondary"
        icon={<DocumentIcon variant="outline" />}
        testID={testID}
      >
        {viewProfileActionText}
      </Link>
    </CardAction>
  );
};
