import React, { forwardRef, useEffect, useState } from 'react';
import { Else, If, Then } from '@common/components/If';
import { ApplicationFilterValues } from '../../../../types';
import { FiltersContent, UpgradeAccountContent } from './components';
import { useTrackModalOpened } from './hooks';

type FiltersModalProps = {
  onRequestClose: () => void;
  pageTitle: string;
  selectedApplicationsTab: 'matched' | 'other';
  setApplicationFilters: (filter: ApplicationFilterValues) => void;
  visible: boolean;
};

export const FiltersModal = forwardRef(
  (
    {
      onRequestClose,
      pageTitle,
      selectedApplicationsTab,
      setApplicationFilters,
      visible
    }: FiltersModalProps,
    ref
  ) => {
    const [modalState, setModalState] = useState<'filters' | 'upgrade'>(
      'filters'
    );

    const toggleModalState = () => {
      setModalState((prevState) =>
        prevState === 'filters' ? 'upgrade' : 'filters'
      );
    };

    const showFilters = modalState === 'filters';
    const trackModalOpened = useTrackModalOpened({
      userIsViewingApplicantsType: selectedApplicationsTab
    });

    useEffect(
      () => {
        visible && trackModalOpened();
      },
      [visible] /* eslint-disable-line react-hooks/exhaustive-deps */
    );

    return (
      <If condition={showFilters}>
        <Then>
          <FiltersContent
            ref={ref}
            visible={visible}
            onRequestClose={onRequestClose}
            setApplicationFilters={setApplicationFilters}
            toggleModalState={toggleModalState}
          />
        </Then>
        <Else>
          <UpgradeAccountContent
            onRequestClose={onRequestClose}
            pageTitle={pageTitle}
            toggleModalState={toggleModalState}
            visible={visible}
          />
        </Else>
      </If>
    );
  }
);
