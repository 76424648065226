import React from 'react';
import { SelectRegionForm } from '@common/components/SelectRegionForm';
import { inNativeApp } from '@src/joraEmployer/whereAmIRunning';
import { sendCountrySelection } from '@src/nativeBridge/actions';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';
import { getUserData } from '@store/userData';
import { Stack } from '@components/Stack';
import { useLogo, useRedirectToLogin } from './hooks/useRegionSelection';

export const RegionSelection = () => {
  const localisation = useAppSelector(getLocalisation);
  const { appPreferences } = useAppSelector(getUserData);
  const { countryCode, brandConfig } = localisation;
  const { getLogo } = useLogo();

  useRedirectToLogin(appPreferences?.countryCode);

  function handleCountrySelected(e) {
    sendCountrySelection(e.country);
  }

  if (!inNativeApp()) {
    return null;
  }

  return (
    <Stack padding={'medium'} height={'full'} justifyContent={'center'}>
      <Stack alignItems={'center'}>{getLogo(brandConfig?.name)}</Stack>
      <SelectRegionForm
        submitCountrySelected={handleCountrySelected}
        brandName={brandConfig!.name}
        initialCountryCode={countryCode}
      />
    </Stack>
  );
};
