import { Tone } from '@theme';
import { useTheme } from '@theme/DesignSystemProvider/ThemeContext';
import { IconSize } from '@theme/tokens';
import { useResolveToken } from '@theme/tokens/utils/useResolveToken';

export type IconStyleProps = {
  height?: IconSize;
  width?: IconSize;
  size?: IconSize;
  tone?: Tone | 'neutralLight' | 'successMuted' | 'white';
  variant?: 'outline' | 'fill' | 'circle' | 'success' | 'danger';
};

export function useIconStyle({
  height,
  width,
  size = 'medium',
  tone = 'neutral',
  variant = 'fill'
}: IconStyleProps) {
  const {
    colors: { background },
    icon: { color: iconColor, sizes, variant: iconVariant }
  } = useTheme();
  const { resolveToken } = useResolveToken();

  const toneColor = resolveToken(iconColor, tone);
  const colors = {
    outline: {
      stroke: toneColor,
      fill: resolveToken(background, 'transparent')
    },
    fill: { stroke: resolveToken(background, 'neutral'), fill: toneColor }
  }[['circle', 'success', 'danger'].includes(variant) ? 'outline' : variant];

  const dimension =
    height || width
      ? {
          height: resolveToken(sizes, height),
          width: resolveToken(sizes, width)
        }
      : {
          height: resolveToken(sizes, size),
          width: resolveToken(sizes, size)
        };

  const maxSize = Math.max(dimension.height || 0, dimension.width || 0);
  const containerPadding = variant === 'circle' ? maxSize / 2 : maxSize;
  const containerStyle = ['circle', 'success', 'danger'].includes(variant)
    ? {
        backgroundColor: iconVariant[variant].containerBackgroundColor,
        borderRadius: (maxSize + 2 * containerPadding) / 2,
        padding: containerPadding
      }
    : undefined;

  return {
    containerStyle,
    imageStyle: {
      stroke: colors.stroke,
      fill: colors.fill,
      ...dimension
    }
  };
}
