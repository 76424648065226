import { useTranslation } from 'react-i18next';
import { openExternalURL } from '@common/helpers/externalConnection';
import { Application } from '@common/types/Application';
import { trackInteraction } from '@store/entities/applications/actions';
import { INTERACTION_TYPE } from '@store/entities/applications/constants';
import { getJobById } from '@store/entities/jobs';
import { useAppDispatch, useAppSelector } from '@store/hooks';

function buildWhatsAppUrl({
  t,
  applicant: { name, phoneNumber },
  job: { title, liveJobLink }
}) {
  const jobPostUrl = `${liveJobLink}?utm_campaign=connect-with-applicant&utm_medium=referral&utm_source=whatsapp`;

  const prefilledMessage = encodeURIComponent(
    `${t('applications.chatModal.chatMessage', {
      applicantName: name,
      jobTitle: title
    })} ${jobPostUrl}`
  );

  return `https://wa.me/${phoneNumber}?text=${prefilledMessage}`;
}

export function useChatWithApplicantModal({
  jobId,
  application
}: {
  jobId: string;
  application: Application;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const job = useAppSelector(getJobById(jobId)) || {};

  const phoneNumber = application?.phoneNumberE164 || application?.phoneNumber;
  const whatsAppUrl = buildWhatsAppUrl({
    t,
    job: { liveJobLink: job.liveJobLink, title: job.title },
    applicant: {
      name: application?.name,
      phoneNumber
    }
  });

  const track = (interactionType) => {
    dispatch(trackInteraction(application.id, interactionType));
  };

  return {
    onCallPress: () => {
      track(INTERACTION_TYPE.CLICKED_CALL_ICON);
      openExternalURL(`tel:${phoneNumber}`);
    },
    onWhatsAppPress: () => {
      track(INTERACTION_TYPE.CLICKED_WHATS_APP_ICON);
      openExternalURL(whatsAppUrl);
    }
  };
}
