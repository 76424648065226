import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { JOB_TYPES } from '@seek/je-shared-data';

type JobTypeOptions = {
  key: JOB_TYPES;
  label: string;
};

const jobTypeOptions = (t: TFunction): JobTypeOptions[] => {
  return [
    {
      label: t(`postJobForm.jobType.${JOB_TYPES.FULL_TIME}`),
      key: JOB_TYPES.FULL_TIME
    },
    {
      label: t(`postJobForm.jobType.${JOB_TYPES.PART_TIME}`),
      key: JOB_TYPES.PART_TIME
    },
    {
      label: t(`postJobForm.jobType.${JOB_TYPES.CASUAL}`),
      key: JOB_TYPES.CASUAL
    }
  ];
};

export const useJobTypeRadio = () => {
  const { t } = useTranslation();
  const options = jobTypeOptions(t);

  return { options };
};
