import { useTranslation } from 'react-i18next';
import { REGEX } from '@common/constants/data';
import { isFutureDate } from '../../../../../joraEmployer/helpers/validation';

type JobStartDateErrors = {
  valid: string;
  future: string;
};

export const useJobStartDateValidation = () => {
  const { t } = useTranslation();

  const errors = t('validations.job.startDate', {
    returnObjects: true
  }) as JobStartDateErrors;

  return {
    pattern: {
      value: REGEX.VALID_DATE,
      message: errors.valid
    },
    validate: (value: string) => {
      if (value && value !== '' && !isFutureDate(value)) {
        return errors.future;
      }
    }
  };
};
