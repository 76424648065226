import { useMemo } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-native';
import { useAppSelector } from '@store/hooks';
import { getUserData } from '@store/userData';

export const useGetSourceParams = (): Record<string, string> => {
  const { 'source.query': sourceQuery } = useAppSelector(getUserData);
  const { search } = useLocation();

  const sourceParams: Record<string, string> = useMemo(() => {
    return sourceQuery || (queryString.parse(search) as unknown as string);
  }, [sourceQuery, search]);

  return sourceParams;
};
