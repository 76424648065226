import React from 'react';
import { TextWithIcon } from '@common/components/TextWithIcon';
import { JoraCoinIcon } from '@components/Icon/illustratedIcons';
import { Text } from '@components/Text';
import {
  Props as StyleProps,
  useTotalCoinsIconStyle,
  useTotalCoinsTextStyle
} from './useTotalCoinsTextStyle';

type Props = {
  balance: number;
} & StyleProps;

export const TotalCoinsText = ({ balance, size, textTone }: Props) => {
  const textStyling = useTotalCoinsTextStyle({ size, textTone });
  const iconStyling = useTotalCoinsIconStyle({ size });

  return (
    <TextWithIcon
      icon={<JoraCoinIcon {...iconStyling} testID="jora-coin-icon" />}
      spacing="xsmall"
    >
      <Text {...textStyling}>{balance}</Text>
    </TextWithIcon>
  );
};
