import React, { ReactElement } from 'react';
import { TextWithIcon } from '@common/components/TextWithIcon';
import { Box } from '@components/Box';
import { Heading } from '@components/Heading';
import { Text } from '@components/Text';

export type TileComponentWithIconProps = {
  children: React.ReactNode;
  heading: string;
  icon: ReactElement;
};

export const TileComponentWithIcon = (props: TileComponentWithIconProps) => {
  const { children, heading, icon } = props;

  return (
    <Box marginTop="medium" flexShrink={1}>
      <TextWithIcon icon={icon} alignItems="flex-start" marginTop="xxsmall">
        <Heading level="h2">{heading}</Heading>
        <Box marginTop="xxsmall">
          <Text>{children}</Text>
        </Box>
      </TextWithIcon>
    </Box>
  );
};
