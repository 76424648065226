import {
  AccountIcon,
  CoinMenuIcon,
  DocumentIcon,
  PostIcon,
  ShopMenuIcon
} from '@components/Icon';

export const getNavMenuItems = (args: {
  countryCode: string;
  postJobLink: string;
  currentPath: string;
  isPostJobEnabled: boolean;
  isCoinsEnabled: boolean;
}) => {
  const {
    countryCode,
    postJobLink,
    isPostJobEnabled,
    currentPath,
    isCoinsEnabled
  } = args;

  const navMenuItems = [
    {
      icon: DocumentIcon,
      text: 'navMenu.jobs',
      link: `/${countryCode}/home`,
      key: 'myJobMenuItem'
    },
    ...(isPostJobEnabled
      ? [
          {
            icon: PostIcon,
            text: 'navMenu.postJob',
            link: postJobLink,
            key: 'postJobMenuItem'
          }
        ]
      : []),
    {
      icon: ShopMenuIcon,
      text: 'navMenu.businesses',
      link: `/${countryCode}/businesses`,
      key: 'businessesMenuItem'
    },
    ...(isCoinsEnabled
      ? [
          {
            icon: CoinMenuIcon,
            text: 'navMenu.myCoins',
            link: `/${countryCode}/my-coins`,
            key: 'myCoinsMenuItem'
          }
        ]
      : []),
    {
      icon: AccountIcon,
      text: 'navMenu.account',
      link: `/${countryCode}/account`,
      key: 'accountMenuItem'
    }
  ];

  return navMenuItems.map((item) => ({
    ...item,
    selected: currentPath === item.link
  }));
};
