import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './BusinessImage.scss';
import classNames from 'classnames';
import { BUSINESS_SIZE } from '@seek/je-shared-data';

import ShopIcon from '../Icons/ShopIcon';
import FarmhouseIcon from '../Icons/FarmhouseIcon';
import BuildingIcon from '../Icons/BuildingIcon';
import FactoryIcon from '../Icons/FactoryIcon';

export default class BusinessImage extends PureComponent {
  static propTypes = {
    businessSize: PropTypes.oneOf(Object.values(BUSINESS_SIZE)),
    withRightMargin: PropTypes.bool,
    logoUrl: PropTypes.string
  };

  getIcon() {
    const { businessSize } = this.props;

    switch (businessSize) {
      case BUSINESS_SIZE.LESS_THAN_5_EMPLOYEES:
        return ShopIcon;
      case BUSINESS_SIZE.LESS_THAN_20_EMPLOYEES:
        return FarmhouseIcon;
      case BUSINESS_SIZE.LESS_THAN_50_EMPLOYEES:
        return FarmhouseIcon;
      case BUSINESS_SIZE.LESS_THAN_100_EMPLOYEES:
        return BuildingIcon;
      case BUSINESS_SIZE.LESS_THAN_200_EMPLOYEES:
        return BuildingIcon;
      case BUSINESS_SIZE.MORE_THAN_200_EMPLOYEES:
        return FactoryIcon;
      default:
        return ShopIcon;
    }
  }

  getDataTestKey() {
    const { businessSize } = this.props;

    switch (businessSize) {
      case BUSINESS_SIZE.LESS_THAN_5_EMPLOYEES:
        return 'tiny-business-icon';
      case BUSINESS_SIZE.LESS_THAN_20_EMPLOYEES:
        return 'small-business-icon';
      case BUSINESS_SIZE.LESS_THAN_50_EMPLOYEES:
        return 'small-business-icon';
      case BUSINESS_SIZE.LESS_THAN_100_EMPLOYEES:
        return 'medium-business-icon';
      case BUSINESS_SIZE.LESS_THAN_200_EMPLOYEES:
        return 'medium-business-icon';
      case BUSINESS_SIZE.MORE_THAN_200_EMPLOYEES:
        return 'large-business-icon';
      default:
        return 'tiny-business-icon';
    }
  }

  render() {
    const { logoUrl, withRightMargin } = this.props;
    const Icon = this.getIcon();
    const dataTestKey = this.getDataTestKey();

    return (
      <div
        data-test-key={dataTestKey}
        className={classNames(styles.container, { [styles.withRightMargin]: withRightMargin })}
        style={logoUrl ? { backgroundImage: `url(${logoUrl})` } : {}}>
        {
          !logoUrl && <Icon className={styles.icon} theme="unselected" />
        }
      </div>
    );
  }
}
