import React from 'react';
import { useTranslation } from 'react-i18next';
import { BusinessImageAndDetails } from '@common/components/BusinessImageAndDetails';
import { SingleSelect } from '@common/components/FormElements/SingleSelect';
import { useRedirect } from '@common/hooks/useRedirect';
import { RedirectTo } from '@common/search';
import { extractBusinessLocation } from '@src/business';
import { BusinessWithUsers } from '@store/entities/businesses/types';
import { Modal } from '@components/Modal';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { TextLink } from '@components/TextLink';
import { useChangeBusinessModal } from '../../hooks/useChangeBusinessModal';
import { MoreBusinessesCallout } from '../MoreBusinessesCallout';

type ChangeBusinessModalProps = {
  onRequestClose: () => void;
  selectedBusinessId: string;
  isBusinessModalOpen: boolean;
  shouldRedirectBusinessCreatedTo: RedirectTo;
  onBusinessSelected: (business: BusinessWithUsers) => void;
  disableAddBusiness: boolean;
};

export const ChangeBusinessModal = ({
  selectedBusinessId,
  isBusinessModalOpen,
  onRequestClose,
  onBusinessSelected,
  shouldRedirectBusinessCreatedTo,
  disableAddBusiness
}: ChangeBusinessModalProps) => {
  const { t } = useTranslation();
  const { redirect } = useRedirect();

  const {
    handleBusinessSelected,
    addNewBusinessUrl,
    businesses,
    accountUpgradeable,
    brandNameText,
    supportSalesEmail,
    supportSalesWhatsApp
  } = useChangeBusinessModal({
    onBusinessSelected,
    shouldRedirectBusinessCreatedTo,
    onRequestClose
  });

  const businessOptions = businesses.map(
    ({ id, name, locations, businessSize }) => {
      const location = extractBusinessLocation(locations);

      return {
        key: id,
        label: (
          <BusinessImageAndDetails businessSize={businessSize}>
            <Text weight="regular">{name}</Text>
            <Text>{location}</Text>
          </BusinessImageAndDetails>
        )
      };
    }
  );

  return (
    <Modal
      testID="change-business-modal"
      title={t('business.title.selectBusiness')}
      visible={isBusinessModalOpen}
      onRequestClose={onRequestClose}
    >
      <Stack spacing="large">
        <SingleSelect
          options={businessOptions}
          value={selectedBusinessId}
          onChange={handleBusinessSelected}
          radioButtonAlign="right"
          testID="business-single-select"
        />

        {disableAddBusiness ? (
          <Text tone="neutralLight" testID="add-new-business-disabled">
            {`+  ${t('business.actions.addBusiness')}`}
          </Text>
        ) : (
          <TextLink
            onPress={redirect(addNewBusinessUrl)}
            testID="add-new-business"
          >
            {`+  ${t('business.actions.addBusiness')}`}
          </TextLink>
        )}

        {accountUpgradeable && (
          <MoreBusinessesCallout
            brandNameText={brandNameText}
            supportSalesEmail={supportSalesEmail}
            supportSalesWhatsApp={supportSalesWhatsApp}
          />
        )}
      </Stack>
    </Modal>
  );
};
