import { TFunction } from 'react-i18next';
import {
  APPLICATION_NOTES_MAX_LENGTH,
  MAX_LENGTH
} from '@common/constants/validation';

interface NotesErrors {
  maxLength: string;
}

export const notes = (t: TFunction<'translation', undefined>) => {
  const errors = t('validations.application.notes', {
    returnObjects: true,
    max: APPLICATION_NOTES_MAX_LENGTH
  }) as NotesErrors;

  return {
    [MAX_LENGTH]: {
      value: APPLICATION_NOTES_MAX_LENGTH,
      message: errors.maxLength
    }
  };
};
