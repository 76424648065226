import React, { ReactNode, forwardRef } from 'react';
import { View, ViewProps } from 'react-native';
import { Row } from '@components/Row';
import { Box } from '../Box';

type CardActionsProps = {
  children: ReactNode;
  testID?: ViewProps['testID'];
};

export const CardActions = forwardRef<View, CardActionsProps>(
  ({ children, testID }: CardActionsProps, ref) => {
    return (
      <Box flex={1}>
        <Row
          justifyContent="space-evenly"
          paddingVertical="medium"
          paddingHorizontal="small"
          spacing="large"
          ref={ref}
          testID={testID}
        >
          {children}
        </Row>
      </Box>
    );
  }
);
