import * as constants from './constants';
import { EventTypes } from 'redux-segment';

export function unsubscribeFromEmail({ userId }) {
  return {
    type: constants.UNSUBSCRIBE_FROM_EMAILS,
    meta: {
      analytics: {
        eventType: EventTypes.identify,
        eventPayload: {
          userId,
          traits: {
            unsubscribedFromEmail: true
          }
        }
      }
    }
  };
}