import { useSearchQueryParam } from '@common/hooks/useSearchQueryParam';
import {
  PAYMENT_CAMPAIGN,
  PaymentCampaignType
} from '@store/payment/constants';

/**
 * Custom hook to verify the payment campaign.
 * Retrieves the campaign value from the query parameter and checks if it is a valid payment campaign.
 * If the campaign is not valid, it returns the default payment campaign.
 * @returns The payment campaign value.
 */
export const useVerifyPaymentCampaign = () => {
  const { getQueryParam } = useSearchQueryParam();
  const campaign = getQueryParam('campaign') as PaymentCampaignType;

  if (!Object.values(PAYMENT_CAMPAIGN).includes(campaign)) {
    return PAYMENT_CAMPAIGN.EMAIL_LINK;
  }

  return campaign;
};
