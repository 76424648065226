import { NO_STATUS } from '@common/constants/data';
import { ApplicationStatus } from '@common/types/Application';
import { APPLICANT_STATUS } from '@seek/je-shared-data';

type UseApplicantStatusSelectorProps = {
  applicantStatus: ApplicationStatus;
};

export type ApplicantStatusOption = {
  value: ApplicationStatus;
  translationKey: string;
  isSuccessIcon: boolean;
};

const {
  NOT_SUITABLE,
  SHORTLISTED,
  SCHEDULED_INTERVIEW,
  OFFERED_JOB,
  ACCEPTED_OFFER
} = APPLICANT_STATUS;

function mapCurrentStatusWithIconAction(currentStatus) {
  const mapCurrentStatus = {
    [NO_STATUS]: SHORTLISTED,
    [SHORTLISTED]: SCHEDULED_INTERVIEW,
    [SCHEDULED_INTERVIEW]: OFFERED_JOB,
    [OFFERED_JOB]: ACCEPTED_OFFER,
    [NOT_SUITABLE]: SHORTLISTED
  };

  const mappedApplicantStatus = mapCurrentStatus[currentStatus] || SHORTLISTED;

  return {
    status: mappedApplicantStatus,
    translationKey: `applications.statusAction.${mappedApplicantStatus}`
  };
}

export function useApplicantStatusSelector(
  props: UseApplicantStatusSelectorProps
) {
  const { applicantStatus } = props;
  const { status, translationKey } =
    mapCurrentStatusWithIconAction(applicantStatus);
  const selectorOptions: ApplicantStatusOption[] = [];

  if (applicantStatus !== ACCEPTED_OFFER) {
    selectorOptions.push({
      value: status,
      translationKey,
      isSuccessIcon: true
    });
  }

  if (applicantStatus !== NOT_SUITABLE && applicantStatus !== ACCEPTED_OFFER) {
    const unsuccessfulTranslationKey =
      applicantStatus === OFFERED_JOB
        ? 'applications.statusAction.offerDeclined'
        : 'applications.statusAction.notSuitable';

    selectorOptions.push({
      value: NOT_SUITABLE,
      translationKey: unsuccessfulTranslationKey,
      isSuccessIcon: false
    });
  }

  return {
    selectorOptions
  };
}
