import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => {
  return (
    <>
      <Path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M17.966 14h-5.503l-2.997 4-3-4h-5.5V1h17v13Z"
      />
      <Path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={0.75}
        d="M4.836 4.9h9.24M4.836 7.9h9.24M4.836 10.9h9.24"
      />
    </>
  );
};

export const CommentIcon = createIcon({
  viewBox: '0 0 19 19',
  vectorComponent
});
