import React, { forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { Else, If, Then } from '@common/components/If';
import { When } from '@common/components/When';
import {
  useAccountUpgradeable,
  usePremiumApplicantFiltersFeature
} from '@common/hooks/featureToggles';
import { useUserHasPremiumFilterAccess } from '@common/hooks/userPackages';
import { Column } from '@components/Column';
import { Divider } from '@components/Divider';
import { Modal } from '@components/Modal';
import { ApplicationFilterValues, FILTERS } from '../../../../../../types';
import { useApplySelectedFilters, useFilterForm } from '../../hooks';
import { useTrackFiltersCleared } from '../../hooks';
import { FooterActions } from '../FooterActions';
import {
  ApplicantStatusFilter,
  DisabledDistanceFilter,
  DisabledRightToWorkFilter,
  DistanceFilter,
  RightToWorkFilter,
  SortApplications,
  UpgradeAccountLink,
  VideoScreeningFilter
} from './components';
import { useHandleApplyButton, useHandleClearButton } from './hooks';

type FiltersContentProps = {
  toggleModalState: () => void;
  setApplicationFilters: (filter: ApplicationFilterValues) => void;
  onRequestClose: () => void;
  visible: boolean;
};
export const FiltersContent = forwardRef(
  (
    {
      toggleModalState,
      setApplicationFilters,
      onRequestClose,
      visible
    }: FiltersContentProps,
    ref
  ) => {
    const { t } = useTranslation();
    const accountUpgradeable = useAccountUpgradeable();
    const showPremiumFilters =
      usePremiumApplicantFiltersFeature() && accountUpgradeable; // only show the filters in markets where package upgrades are possible
    const userIsFreeUser = !useUserHasPremiumFilterAccess();

    const { filterForm, setFilterForm, handleFieldChange, handleFieldClear } =
      useFilterForm();

    const { applySelectedFilters, clearAllFilters } = useApplySelectedFilters({
      filterForm,
      setApplicationFilters,
      setFilterForm
    });

    const onApplyButtonClicked = useHandleApplyButton({
      filterForm,
      applySelectedFilters,
      closeModal: onRequestClose
    });
    const onClearAllButtonClicked = useHandleClearButton({
      clearAllFilters,
      closeModal: onRequestClose
    });
    const sendClearAllFiltersTrackingEvent = useTrackFiltersCleared();

    useImperativeHandle(ref, () => ({
      clearAllFilters,
      sendClearAllFiltersTrackingEvent
    }));

    return (
      <Modal
        visible={visible}
        title={t('applications.applicationsFilter.action.filter')}
        height="fit-content"
        testID="applications-filter-modal"
        onRequestClose={onRequestClose}
        footerChildren={
          <FooterActions
            clearAllFilters={onClearAllButtonClicked}
            applyFilters={onApplyButtonClicked}
          />
        }
      >
        <Column spacing="large">
          {accountUpgradeable ? (
            <UpgradeAccountLink toggleModalState={toggleModalState} />
          ) : null}

          <SortApplications
            value={filterForm[FILTERS.SORT_APPLICATIONS]}
            onChange={handleFieldChange(FILTERS.SORT_APPLICATIONS)}
          />

          <Divider />

          <ApplicantStatusFilter
            value={filterForm[FILTERS.APPLICANT_STATUS]}
            onChange={handleFieldChange(FILTERS.APPLICANT_STATUS)}
            onClear={handleFieldClear(FILTERS.APPLICANT_STATUS)}
          />

          <Divider />

          <VideoScreeningFilter
            value={filterForm[FILTERS.VIDEO_SCREENING]}
            onChange={handleFieldChange(FILTERS.VIDEO_SCREENING)}
            onClear={handleFieldClear(FILTERS.VIDEO_SCREENING)}
          />

          <When condition={showPremiumFilters}>
            <Column spacing="large">
              <Divider />

              <If condition={userIsFreeUser}>
                <Then>
                  <DisabledDistanceFilter />
                </Then>
                <Else>
                  <DistanceFilter
                    value={filterForm[FILTERS.DISTANCE]}
                    onChange={handleFieldChange(FILTERS.DISTANCE)}
                    onClear={handleFieldClear(FILTERS.DISTANCE)}
                  />
                </Else>
              </If>

              <Divider />

              <If condition={userIsFreeUser}>
                <Then>
                  <DisabledRightToWorkFilter />
                </Then>
                <Else>
                  <RightToWorkFilter
                    value={filterForm[FILTERS.RIGHT_TO_WORK]}
                    onChange={handleFieldChange(FILTERS.RIGHT_TO_WORK)}
                    onClear={handleFieldClear(FILTERS.RIGHT_TO_WORK)}
                  />
                </Else>
              </If>
            </Column>
          </When>
        </Column>
      </Modal>
    );
  }
);
