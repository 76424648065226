import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetUpgradeJobLink } from '@common/hooks/appPageLinks';
import { useRedirect } from '@common/hooks/useRedirect';
import { PAYMENT_CAMPAIGN } from '@store/payment/constants';
import { Button } from '@components/Button';

type UpgradeAdButtonProps = {
  jobId: string;
};

export const UpgradeAdButton = ({ jobId }: UpgradeAdButtonProps) => {
  const { t } = useTranslation();
  const { redirect } = useRedirect();
  const upgradeJobPage = useGetUpgradeJobLink(
    jobId,
    PAYMENT_CAMPAIGN.JOBS_DASHBOARD
  );

  return (
    <Button onPress={redirect(upgradeJobPage)} variant="secondary">
      {t('dashboardPage.action.upgradeAd')}
    </Button>
  );
};
