import { getRequestState, getSubmissionError } from '@store/app';
import { useAppSelector } from '@store/hooks';

export function useGetAppErrors(requestKey: string) {
  const { error: stateError } = useAppSelector(getRequestState(requestKey));
  const submissionError = useAppSelector(getSubmissionError(requestKey));
  return {
    stateError: !!stateError,
    submissionError: !!submissionError
  };
}
