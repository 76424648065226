/**
 * Resolves the number of items per page from a given input string.
 *
 * @param inputString - The input string to parse as the number of items per page.
 * @returns The parsed number of items per page. Defaults to 50 if the input string is empty or invalid.
 */
type QueryParam =
  | string
  | boolean
  | number
  | (string | boolean | null)[]
  | null
  | undefined;
export function resolveItemsPerPage(inputString: QueryParam): number {
  const defaultValue = 50;
  if (typeof inputString === 'string') {
    return resolveString(inputString, defaultValue);
  }
  if (typeof inputString === 'number') {
    return inputString;
  }

  return 50;
}

function resolveString(inputString: string, defaultValue = 50): number {
  const res = parseInt(inputString, 10);
  if (!isNaN(res)) {
    return res;
  }
  return defaultValue;
}
