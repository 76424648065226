import { useSearchQueryParam } from '@common/hooks/useSearchQueryParam';
import { getUser } from '@store/auth';
import { useAppSelector } from '@store/hooks';

export const useVerifyEmailSuccess = () => {
  const { checkQueryParam } = useSearchQueryParam();
  const {
    currentUser: { emailLastVerifiedAt }
  } = useAppSelector(getUser);

  const userHasVerifiedEmail = Boolean(emailLastVerifiedAt);
  const fromUpdateAccountFlow =
    userHasVerifiedEmail || checkQueryParam('fromUpdateAccount');

  return {
    fromUpdateAccountFlow,
    fromPostJobFlow: !fromUpdateAccountFlow
  };
};
