import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  BrandName,
  SupportedCountryCode
} from '@seek/je-shared-data/lib/types/brand';
import { Button } from '@components/Button';
import { Dropdown, Field } from '@components/FormElements';
import { GlobeIcon } from '@components/Icon';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { useSelectRegionForm } from './hooks';

type SelectRegionFormProps = {
  submitCountrySelected: ({ country }) => void;
  initialCountryCode?: SupportedCountryCode;
  brandName: BrandName;
};

export const SelectRegionForm = (props: SelectRegionFormProps) => {
  const { submitCountrySelected, initialCountryCode, brandName } = props;
  const { control, handleSubmit } = useForm({
    defaultValues: {
      country: initialCountryCode
    }
  });

  const [loading, setLoading] = useState(false);

  function didSubmit(e) {
    setLoading(true);
    submitCountrySelected(e);
  }

  const { i18n } = useTranslation();
  const t = i18n.getFixedT('en');
  const { dropdownOptions } = useSelectRegionForm(
    brandName,
    initialCountryCode
  );

  const dropdownLabel = (
    <Stack
      direction={'row'}
      spacing={'small'}
      alignItems={'center'}
      justifyContent={'flex-start'}
      marginBottom={'small'}
    >
      <GlobeIcon size={'large'} tone={'neutral'} variant={'outline'} />
      <Text>{t<string>('selectRegionPage.title')}</Text>
    </Stack>
  );

  return (
    <Stack spacing={'medium'}>
      <Controller
        control={control}
        name="country"
        render={({ field: { onChange, value } }) => (
          <Field label={dropdownLabel} zIndex={10}>
            <Dropdown
              items={dropdownOptions}
              selectedItem={value}
              testID="dropdown-country"
              onChange={(data) => {
                onChange(data);
              }}
            />
          </Field>
        )}
      />
      <Button
        disabled={loading}
        onPress={handleSubmit(didSubmit)}
        testID="submitRegionSelection"
      >
        {t('common.action.continue') as string}
      </Button>
    </Stack>
  );
};
