import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { BUSINESS_SIZE_OPTIONS } from '@common/constants/data';
import { Dropdown } from '@src/common/components/FormElements/Dropdown';
import { Box } from '@components/Box';
import { Field } from '@components/FormElements';

type BusinessSizeProps = {
  value: string | undefined;
  onChange: () => void;
  error?: string;
};

export const BusinessSize = (props: BusinessSizeProps) => {
  const { t } = useTranslation();
  const businessSizeOptions = BUSINESS_SIZE_OPTIONS(t);
  const { error, onChange, value } = props;

  return (
    <Field
      label={t('createBusinessForm.fieldLabel.businessSize')}
      message={
        error && (
          <Box marginTop="xxsmall">
            <ErrorMessage>{error}</ErrorMessage>
          </Box>
        )
      }
    >
      <Dropdown
        hideLabel
        options={businessSizeOptions}
        value={value}
        placeholder={t('createBusinessForm.businessSize.select')}
        onChange={onChange}
      />
    </Field>
  );
};
