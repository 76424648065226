import { TrpcRouter } from 'je-bff/src/handlers/bff';
import { ClientError } from '@common/helpers/errors';
import { inferRouterInputs } from '@trpc/server';
import { trpcBff } from '@trpcBff';

export type UpdateInteractionParams =
  inferRouterInputs<TrpcRouter>['applications']['interaction']['update'];

export const useUpdateInteraction = () => {
  const updateApplicantInteractionMutation =
    trpcBff.applications.interaction.update.useMutation();

  return {
    updateApplicantInteraction: updateApplicantInteractionMutation.mutate,
    errorMessage: getClientError(updateApplicantInteractionMutation.error),
    isSuccessful: updateApplicantInteractionMutation.isSuccess
  };
};

const getClientError = (error) => {
  if (!error) return;

  const guid = error?.data?.httpError.guid;
  const responseBody = error?.data;

  return new ClientError('errors.defaultWithGuid', {
    guid,
    responseBody
  });
};
