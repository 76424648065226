import React, { useEffect, useState } from 'react';
import { normaliseLength } from '@common/helpers/textHelper';
import { Box } from '@components/Box';
import { Text } from '@components/Text';

interface FooterParams {
  value: string;
  charCountProvider?: (count: number) => string;
}
export function Footer({
  value,
  charCountProvider = (c) => `${c}`
}: FooterParams) {
  const [normalisedLength, setNormalisedLength] = useState(
    normaliseLength(value)
  );

  useEffect(() => {
    setNormalisedLength(normaliseLength(value));
  }, [value]);

  return (
    <Box
      alignItems="flex-end"
      margin="small"
      testID="job-form-jobDescription-footer"
    >
      <Text size="small" tone="neutralLight">
        {charCountProvider(normalisedLength)}
      </Text>
    </Box>
  );
}
