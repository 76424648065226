import { Component } from 'react';
import PropTypes from 'prop-types';
import { Text } from '@components/Text';
import ActionLink from '../../../../../common/components/ActionLink/ActionLink';
import Card from '../../../../../common/components/Card/Card';
import CardActions from '../../../../../common/components/CardActions/CardActions';
import Div from '../../../../../common/components/Div/Div';
import Skeleton from '../../../../../common/components/Skeleton/Skeleton';
import * as colours from '../../../../../common/theme/palette/colours';

export default class ListJobSkeleton extends Component {
  static propTypes = {
    count: PropTypes.number,
    showButtonSkeleton: PropTypes.bool
  };

  render() {
    const { count = 1, showButtonSkeleton } = this.props;
    const gridRowHeight = 8;

    return (
      <Div>
        {showButtonSkeleton && (
          <Div
            marginTop="medium"
            marginBottom="small"
            flexDirection="row"
            justifyContent="flexEnd"
          >
            <Skeleton
              highlightColor={colours.grey200}
              baseColor={colours.grey300}
              width={200}
              height={gridRowHeight * 6}
              marginRight={gridRowHeight * 2}
            />
          </Div>
        )}

        {new Array(count).fill('').map((_, index) => (
          <Card
            withBorder
            extraTopMargin={!showButtonSkeleton || index !== 0}
            theme="loading"
            key={index}
          >
            <Div flex={1} flexDirection="row" alignItems="center">
              <Div marginBottom="medium">
                <Skeleton width={300} height={gridRowHeight * 2} />
              </Div>
            </Div>

            <Div>
              <Skeleton width={100} height={gridRowHeight * 2} />
            </Div>
            <Div
              marginTop="medium"
              flex={1}
              flexDirection="row"
              alignItems="center"
            >
              <Skeleton
                width={50}
                height={gridRowHeight * 6}
                marginRight={gridRowHeight}
              />
              <Div flexDirection="column">
                <Text weight="medium">
                  <Skeleton width={200} height={gridRowHeight * 2} />
                </Text>
                <Div marginTop="small">
                  <Skeleton width={100} height={gridRowHeight * 2} />
                </Div>
              </Div>
            </Div>
            <CardActions onCardWithBorder>
              <Div>
                <Skeleton width={125} height={gridRowHeight * 2} />
              </Div>
              <Div flexDirection="row" justifyContent="spaceBetween">
                <ActionLink onClick={() => { }}>
                  {' '}
                  <Skeleton width={65} height={gridRowHeight * 2} />
                </ActionLink>
                <ActionLink onClick={() => { }}>
                  {' '}
                  <Skeleton width={65} height={gridRowHeight * 2} />
                </ActionLink>
              </Div>
            </CardActions>
          </Card>
        ))}
      </Div>
    );
  }
}
