import { Application, ApplicationStatus } from '@common/types';
import { APPLICANT_STATUS } from '@seek/je-shared-data';

const statusToScore = {
  [APPLICANT_STATUS.NO_STATUS]: 0,
  [APPLICANT_STATUS.NOT_SUITABLE]: 100,
  [APPLICANT_STATUS.SHORTLISTED]: 25,
  [APPLICANT_STATUS.SCHEDULED_INTERVIEW]: 50,
  [APPLICANT_STATUS.OFFERED_JOB]: 75,
  [APPLICANT_STATUS.ACCEPTED_OFFER]: 100
} as const;

export function useProgressBarScore(applications: Application[]) {
  if (applications.length === 0) {
    return {
      applicationStatusProgress: statusToScore[APPLICANT_STATUS.NO_STATUS]
    };
  }

  if (hasAnAcceptedOffer(applications)) {
    return {
      applicationStatusProgress: statusToScore[APPLICANT_STATUS.ACCEPTED_OFFER]
    };
  }

  return {
    applicationStatusProgress: getStatusProgressScore(applications)
  };
}

function hasAnAcceptedOffer(applications: Application[]) {
  return (
    applications.filter((application) => {
      const applicantStatus = getApplicationStatus(application.status);
      return applicantStatus === APPLICANT_STATUS.ACCEPTED_OFFER;
    }).length !== 0
  );
}

function getStatusProgressScore(applications: Application[]) {
  let accumulatedScore = 0;

  applications.forEach((application) => {
    const applicantStatus = getApplicationStatus(application.status);

    accumulatedScore += statusToScore[applicantStatus];
  });

  return Math.ceil(accumulatedScore / applications.length);
}

function getApplicationStatus(status?: ApplicationStatus) {
  return status ? status : APPLICANT_STATUS.NO_STATUS;
}
