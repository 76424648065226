import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { Box } from '@components/Box';
import { CalloutText } from '@components/CalloutText';
import { Field, Input } from '@components/FormElements';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';

type JobTitleProps = {
  value: string;
  onChange: () => void;
  onBlur: () => void;
  error?: string;
};

export const JobTitle = (props: JobTitleProps) => {
  const { t } = useTranslation();
  const { error, onChange, onBlur, value } = props;

  return (
    <Stack spacing="small">
      <Field
        label={t('postJobForm.fieldLabel.jobTitle')}
        message={
          error && (
            <Box marginTop="xxsmall">
              <ErrorMessage>{error}</ErrorMessage>
            </Box>
          )
        }
      >
        <Input
          id="job-form-jobTitle"
          testID="job-form-jobTitle"
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          autoCapitalize="words"
          autoFocus
        />
      </Field>

      <CalloutText tone="info">
        <Stack tone="transparent" flexShrink={1}>
          <Text>
            {t(
              'postJobForm.explanationText.jobTitle.chooseBestTitleForJob.title'
            )}
          </Text>
          <Text>
            {t(
              'postJobForm.explanationText.jobTitle.chooseBestTitleForJob.example'
            )}
          </Text>
        </Stack>
      </CalloutText>
    </Stack>
  );
};
