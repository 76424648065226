import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextWithIcon } from '@common/components/TextWithIcon';
import { LOCALISED_COUNTRIES } from '@common/constants/data';
import { SuccessIcon, VisaIcon } from '@components/Icon';
import { Text } from '@components/Text';

type RightToWorkInfoProps = {
  candiRightToWork?: boolean;
  jobCountryCode?: string;
};

export const RightToWorkInfo = ({
  candiRightToWork,
  jobCountryCode
}: RightToWorkInfoProps) => {
  const { t } = useTranslation();

  if (candiRightToWork === undefined) return null;

  const icon = candiRightToWork ? (
    <SuccessIcon
      size="small"
      tone="success"
      variant="outline"
      testID="success-icon"
    />
  ) : (
    <VisaIcon
      size="small"
      tone="success"
      variant="outline"
      testID="visa-icon"
    />
  );
  const location = jobCountryCode ? LOCALISED_COUNTRIES(t)[jobCountryCode] : '';

  return (
    <TextWithIcon icon={icon} alignItems="flex-start" marginTop="xxsmall">
      <Text>
        {t(
          `applications.applicationCard.workRight.${
            candiRightToWork ? 'eligible' : 'sponsorship'
          }`,
          {
            location
          }
        )}
      </Text>
    </TextWithIcon>
  );
};
