import React, { createContext } from 'react';

type TabsContextType = {
  selectedTab: number;
  setSelectedTab: (tab: number) => void;
};

const throwContextError = () => {
  throw new Error('Called outside TabsContext not allowed');
};

export const TabsContext = createContext<TabsContextType>({
  selectedTab: 0,
  setSelectedTab: throwContextError
});

export const useTabs = () => React.useContext(TabsContext);
