import React, { useContext } from 'react';
import { Surname as InnerField } from '@common/components/Account/components/Surname/Surname';
import { surname } from '@common/components/Account/rules';
import { FieldWrapper } from '../FieldWrapper';
import { FormContext } from '../FormContext';

export const Surname = () => {
  const { t } = useContext(FormContext);
  return (
    <FieldWrapper<string>
      name="surname"
      rules={surname(t)}
      render={(props) => {
        const mergedProps = { ...props, t };
        return <InnerField {...mergedProps} />;
      }}
    />
  );
};
