import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

function vectorComponent() {
  return (
    <>
      <Path
        d="M20.5 19.5h-17v-12l8.5-3 8.5 3v12z"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M10.5 19v-4.5h3V19"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        strokeLinejoin="round"
      />
      <Path
        d="M7.83 11H6.17a.175.175 0 01-.17-.175v-1.65C6 9.08 6.078 9 6.17 9h1.66c.092 0 .17.08.17.175v1.657a.17.17 0 01-.17.168zM7.83 16H6.17a.175.175 0 01-.17-.175v-1.65c0-.095.078-.175.17-.175h1.66c.092 0 .17.08.17.175v1.65a.175.175 0 01-.17.175zM17.83 16h-1.66a.175.175 0 01-.17-.175v-1.65c0-.095.078-.175.17-.175h1.66c.092 0 .17.08.17.175v1.65a.175.175 0 01-.17.175zM12.83 11h-1.66a.175.175 0 01-.17-.175v-1.65c0-.095.078-.175.17-.175h1.66c.092 0 .17.08.17.175v1.657a.165.165 0 01-.17.168zM17.83 11h-1.66a.175.175 0 01-.17-.175v-1.65c0-.095.078-.175.17-.175h1.66c.092 0 .17.08.17.175v1.657a.17.17 0 01-.17.168z"
        strokeWidth={0.5}
      />
    </>
  );
}

export const FarmhouseIcon = createIcon({
  viewBox: '3 3 18 18',
  vectorComponent
});
