import { createContext } from 'react';
import type { Control, FieldErrorsImpl } from 'react-hook-form';
import { TFunction } from 'react-i18next';
import { NotesFormFields } from './types';

interface FormContextProps {
  t: TFunction<'translation', undefined>;
  control: Control<NotesFormFields>;
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
}

export const FormContext = createContext<FormContextProps>({
  control: {} as FormContextProps['control'],
  errors: {},
  t: () => ''
});
