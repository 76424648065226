import REQUEST_KEY from '@common/constants/requestKeys';
import { ClientError } from '@common/helpers/errors';
import { useHandleAppLoading } from '@common/hooks/appLoading';
import { extractTrpcErrorProperties } from '@src/trpc/lib';
import {
  ErrorResponse,
  QueryOptions,
  useFetchMyBalance
} from './useFetchMyBalance';

export const useQueryMyBalance = (queryOptions?: QueryOptions) => {
  const { data, error, isLoading } = useFetchMyBalance(queryOptions);

  useHandleAppLoading(isLoading, REQUEST_KEY.COINS.BALANCE.GET);

  const clientError = getClientError(
    extractTrpcErrorProperties<ErrorResponse>(error)
  );

  return {
    myBalance: data,
    errorMessage: {
      message: clientError?.message,
      properties: clientError?.properties
    }
  };
};

const getClientError = (errorProperties) => {
  if (!errorProperties) return;

  return new ClientError('errors.defaultWithGuid', {
    guid: errorProperties.guid,
    responseBody: errorProperties.responseBody
  });
};
