import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Button';
import { Card, CardContent } from '@components/Card';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { useRepostJobContainer } from './useRepostJobContainer';

type Props = {
  jobId: string;
};

export const RepostJobContainer = ({ jobId }: Props) => {
  const { t } = useTranslation();
  const { redirectToHomePage, redirectToRepostPage } =
    useRepostJobContainer(jobId);

  return (
    <Card testID="repost-job-container">
      <CardContent>
        <Text weight="regular">
          {t('applications.description.repostThisJob')}
        </Text>

        <Stack
          marginTop="xlarge"
          spacing="medium"
          direction={{
            mobile: 'column',
            tablet: 'row',
            desktop: 'row'
          }}
        >
          <Button onPress={redirectToRepostPage}>
            {t('common.action.repostAd')}
          </Button>
          <Button variant="secondary" onPress={redirectToHomePage}>
            {t('common.action.backToHome')}
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};
