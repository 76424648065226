import React from 'react';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { JobDescription } from './JobDescription';
import { useJobDescriptionValidation } from './hooks/useJobDescriptionValidation';

export const JobDescriptionController = <T extends FieldValues>({
  name,
  control
}: UseControllerProps<T>) => {
  const jobDescriptionRules = useJobDescriptionValidation();

  return (
    <Controller
      name={name}
      control={control}
      rules={jobDescriptionRules}
      render={({ field: { onChange, onBlur, value }, formState }) => (
        <JobDescription
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          error={formState?.errors[name]?.message as string}
        />
      )}
    />
  );
};
