import PropTypes from 'prop-types';
import { green700, grey900 } from '../../theme/palette/colours';
import Svg, { Path } from 'svgs';

function getOutline(theme) {
  switch (theme) {
    case 'green':
      return green700;
    default:
      return grey900;
  }
}

function CopyIcon(props) {
  return (
    <Svg viewBox="0 0 14 18" fill="none" {...props}>
      <Path
        d="M13.5 5.5H4.5V17.5H13.5V5.5Z"
        stroke={getOutline(props.theme)}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M2.5 12.5H0.5V0.5H9.5V3.5"
        stroke={getOutline(props.theme)}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

CopyIcon.propTypes = {
  theme: PropTypes.string
};

export default CopyIcon;
