export const START_LOADING_WITH_SPINNER =
  'joraemployer/app/START_LOADING_WITH_SPINNER';
export const END_LOADING_WITH_SPINNER =
  'joraemployer/app/END_LOADING_WITH_SPINNER';
export const START_LOADING = 'joraemployer/app/START_LOADING';
export const END_LOADING = 'joraemployer/app/END_LOADING';
export const UPDATE_SUCCESS = 'joraemployer/app/UPDATE_SUCCESS';
export const UPDATE_ERROR = 'joraemployer/app/UPDATE_ERROR';
export const CLEAR_ERROR = 'joraemployer/app/CLEAR_ERROR';
export const UPDATE_SUBMISSION_ERROR =
  'joraemployer/app/UPDATE_SUBMISSION_ERROR';
export const CLEAR_SUBMISSION_ERROR = 'joraemployer/app/CLEAR_SUBMISSION_ERROR';
export const SELECT_REGION_FORM = 'jora-employer-select-region-form';
