export function useCheckboxA11yProps({
  disabled,
  value = false
}: {
  disabled?: boolean;
  value?: boolean;
}) {
  return {
    accessibilityChecked: value,
    accessibilityDisabled: disabled,
    accessibilityRole: 'checkbox',
    accessibilityState: { checked: value, disabled }
  } as const;
}
