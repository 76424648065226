import { useTheme } from '@theme/DesignSystemProvider/ThemeContext';
import { useResolveToken } from '@theme/tokens/utils/useResolveToken';
import { Tone } from '@theme/types';

type Variant = 'primary' | 'secondary';
export type BadgeTone = Exclude<Tone, 'brand'>;

export type BadgeStyleProps = {
  tone?: BadgeTone;
  variant?: Variant;
  borderRadius?: 'none' | 'normal';
};

export function useBadgeStyle({
  tone = 'neutral',
  variant = 'primary',
  borderRadius = 'normal'
}: BadgeStyleProps) {
  const { badge, border, typography, spacing } = useTheme();
  const { resolveToken } = useResolveToken();
  const { fontColor } = badge.variant[variant][tone];
  const {
    size: textSize,
    lineHeight: textLineHeight,
    letterSpacing: textLetterSpacing
  } = resolveToken(typography.text, 'small') || {};

  return {
    containerStyle: {
      alignSelf: 'flex-start' as const,
      backgroundColor: resolveToken(badge.variant[variant], tone)
        ?.backgroundColor,
      paddingHorizontal: resolveToken(spacing, 'xsmall'),
      paddingVertical: resolveToken(spacing, 'xxsmall'),
      borderRadius:
        borderRadius === 'none' ? 0 : resolveToken(border.radius, 'xsmall')
    },
    textStyle: {
      fontFamily: typography.fontFamily,
      color: fontColor,
      fontSize: resolveToken(typography.fontSizes, textSize),
      fontWeight: resolveToken(typography.fontWeights, 'light'),
      lineHeight: resolveToken(typography.lineHeights, textLineHeight),
      letterSpacing: resolveToken(typography.letterSpacing, textLetterSpacing)
    }
  };
}
