import REQUEST_KEY from '@common/constants/requestKeys';
import { ClientError } from '@common/helpers/errors';
import { useHandleAppLoading } from '@common/hooks/appLoading';
import { trpcBff } from '@trpcBff';

export const useUpgradeJobWithCoinsMutation = () => {
  const upgradeJobMutation =
    trpcBff.jobs.actions.upgradeWithCoins.useMutation();

  useHandleAppLoading(
    upgradeJobMutation.isLoading,
    REQUEST_KEY.JOB.ACTION.UPGRADE
  );

  return {
    upgradeJobWithCoins: upgradeJobMutation.mutate,
    errorMessage: getClientError(upgradeJobMutation.error),
    isSuccessful: upgradeJobMutation.isSuccess
  };
};

const getClientError = (error) => {
  if (!error) return;

  const guid = error?.data?.httpError.guid;
  const responseBody = error?.data;

  return new ClientError('errors.defaultWithGuid', {
    guid,
    responseBody
  });
};
