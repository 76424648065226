import React, { ReactElement, useState } from 'react';
import { PaymentContext } from './PaymentContext';
import type { StripeElements, WebStripe } from './components';
import { StripePaymentProvider } from './components/StripePaymentProvider';

export type PaymentProviderProps = {
  children: ReactElement | null;
};

export const PaymentProvider = ({ children }: PaymentProviderProps) => {
  const [stripe, setStripe] = useState<WebStripe>();
  const [paymentIntentId, setPaymentIntentId] = useState<string>();
  const [clientId, setClientId] = useState<string>();
  const [elements, setElements] = useState<StripeElements | null>();

  const paymentContextData = {
    paymentIntentId,
    setPaymentIntentId,
    clientId,
    setClientId,
    stripe,
    setStripe,
    elements,
    setElements
  };

  return (
    <PaymentContext.Provider value={paymentContextData}>
      <StripePaymentProvider>
        {clientId ? children : null}
      </StripePaymentProvider>
    </PaymentContext.Provider>
  );
};
