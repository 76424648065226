import { useGetCurrencyPricing } from '@common/hooks/payment';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';

type Params = {
  jobCountryCode: SupportedCountryCode;
};

export const useGetCurrencyPrice = ({ jobCountryCode }: Params) => {
  const { currencyPrice } = useGetCurrencyPricing(jobCountryCode);

  return currencyPrice;
};
