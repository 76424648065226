import React from 'react';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <>
    <path
      fill="currentColor"
      d="M8 4h13v2H8zM4.5 6.5a1.5 1.5 0 1 1 0-3a1.5 1.5 0 0 1 0 3m0 7a1.5 1.5 0 1 1 0-3a1.5 1.5 0 0 1 0 3m0 6.9a1.5 1.5 0 1 1 0-3a1.5 1.5 0 0 1 0 3M8 11h13v2H8zm0 7h13v2H8z"
    />
  </>
);

export const UnorderedListIcon = createIcon({
  viewBox: '0 0 24 24',
  vectorComponent
});
