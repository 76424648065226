import REQUEST_KEY from '@common/constants/requestKeys';
import { ClientError } from '@common/helpers/errors';
import { useHandleAppLoading } from '@common/hooks/appLoading';
import {
  BrandName,
  SupportedCountryCode
} from '@seek/je-shared-data/lib/types/brand';
import { extractTrpcErrorProperties } from '@src/trpc/lib';
import {
  ErrorResponse,
  QueryOptions,
  useFetchCoinPricing
} from './useFetchCoinPricing';

export const useQueryCoinPricing = (
  jobCountryCode: SupportedCountryCode,
  brand: BrandName,
  queryOptions: QueryOptions
) => {
  const params = { jobCountryCode, brand };

  const { data, error, isLoading } = useFetchCoinPricing(params, queryOptions);

  useHandleAppLoading(isLoading, REQUEST_KEY.COINS.PRICING.GET);

  return {
    data,
    error: getClientError(extractTrpcErrorProperties<ErrorResponse>(error))
  };
};

const getClientError = (errorProperties) => {
  if (!errorProperties) return;

  return new ClientError('errors.defaultWithGuid', {
    guid: errorProperties.guid,
    responseBody: errorProperties.responseBody
  });
};
