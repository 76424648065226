export function useDropdownA11yProps({
  disabled = false,
  value = ''
}: {
  disabled?: boolean;
  value?: string;
}) {
  return {
    accessibilityDisabled: disabled,
    accessibilityValueText: value,
    accessibilityState: { disabled, valueText: value }
  } as const;
}
