import React, { ReactNode, forwardRef, useState } from 'react';
import {
  Pressable,
  PressableProps,
  Text as ReactNativeText,
  View
} from 'react-native';
import { TickIcon } from '../../Icon';
import { Row } from '../../Row';
import { composeTestIDs } from '../../utils';
import { useCheckboxA11yProps } from './useCheckboxA11yProps';
import {
  CheckboxState,
  CheckboxStyleProps,
  useCheckboxStyle
} from './useCheckboxStyle';

export type CheckboxProps = Omit<CheckboxStyleProps, 'checkboxState'> & {
  disabled?: boolean;
  label?: string | ReactNode;
  testID?: PressableProps['testID'];
  onChange?: (value: boolean) => void;
};

export const Checkbox = forwardRef<View, CheckboxProps>((props, ref) => {
  const { disabled, label, value, testID, onChange } = props;

  const [checkboxState, setCheckboxState] = useState<CheckboxState>('default');
  const { containerStyle, pressableStyle, textStyle } = useCheckboxStyle({
    checkboxState: disabled ? 'disabled' : checkboxState,
    value
  });
  const a11yProps = useCheckboxA11yProps({ disabled, value });

  return (
    <Pressable
      {...a11yProps}
      disabled={disabled}
      onPress={() => {
        onChange?.(!value);
      }}
      testID={testID}
      ref={ref}
      style={pressableStyle}
      onPressIn={() => setCheckboxState('active')}
      onPressOut={() => setCheckboxState('default')}
    >
      <Row alignItems="center" spacing={label ? 'small' : 'none'}>
        <View style={containerStyle} testID="check-box-container">
          {value ? (
            <TickIcon
              size="large"
              tone="brand"
              testID={composeTestIDs(testID, 'tick-icon')}
            />
          ) : null}
        </View>
        <ReactNativeText style={textStyle}>{label}</ReactNativeText>
      </Row>
    </Pressable>
  );
});
