import { ThunkAction } from 'redux-thunk';
import {
  LocalisedBrandDetails,
  getLocalisedBrandDetails
} from '@seek/je-shared-data';
import { BrandName } from '@seek/je-shared-data/lib/types/brand';
import i18next from '../../translations/i18n/i18next-server';
import { AppState } from '../store';
import * as constants from './constants';

export type LocalisationAction = {
  type: string;
  result: LocalisedBrandDetails;
};

type LocalisationThunkAction = ThunkAction<
  void,
  AppState,
  void,
  LocalisationAction
>;

export function updateLocalisation(
  brandName: BrandName,
  countryCode: string
): LocalisationThunkAction {
  return (dispatch) => {
    const localisation = getLocalisedBrandDetails(brandName, countryCode);

    i18next.changeLanguage(localisation.language);

    dispatch({
      type: constants.LOCALISATION_UPDATE_SUCCESS,
      result: localisation
    });
  };
}
