import { createBrowserHistory } from 'history';
import { inNativeApp } from '@src/joraEmployer/whereAmIRunning';
import { nativeBrazeMiddleware } from '@store/middleware';
import { errorTrackingMiddleware } from '../../../common/helpers/raygun';
import {
  createNativeClient,
  createTracker,
  injectImpersonatorDetails
} from '../../helpers/analytics';
import { createGoogleGTagMiddleware } from './createGoogleGTagMiddleware';

const createMiddlewares = (state, initialHistory) => {
  const tracker = inNativeApp()
    ? createTracker(createNativeClient(initialHistory))
    : createTracker();

  const middlewares = [injectImpersonatorDetails(), tracker];

  if (inNativeApp()) {
    middlewares.push(nativeBrazeMiddleware);
  }

  const gtagMiddleWare = createGoogleGTagMiddleware(state);
  if (gtagMiddleWare) {
    middlewares.push(gtagMiddleWare);
  }

  middlewares.push(errorTrackingMiddleware);
  return middlewares;
};

export function getStoreConfig() {
  const initialState = (window as any).__INITIAL_STATE__;
  const initialHistory = createBrowserHistory();

  return {
    initialState,
    initialHistory,
    fetch,
    middleware: createMiddlewares(initialState, initialHistory)
  };
}
