let history;

export function setHistory(currentHistory) {
  history = currentHistory;
}

export function isBackOrForwardNavigation() {
  return history && history.action === 'POP' && history.length > 1;
}

