import { EventTypes } from 'redux-segment';
import { AUTHENTICATED } from '@store/auth/constants';
import { setUser, trackEvent, trackPageView } from './trackers';

export default (store) => (next) => (action) => {
  const state = store.getState();
  const brandName = state.localisation?.brandConfig?.name;
  const gtagId = state.config.analytics.gtagId?.[brandName];

  switch (action.type) {
    case AUTHENTICATED:
      if (action.result) {
        setUser(gtagId, action.result.id, state);
      }
      break;
    case '@@router/CALL_HISTORY_LOCATION':
    case '@@router/UPDATE_LOCATION':
    case '@@router/LOCATION_CHANGE':
      trackPageView(action, state);
      break;
    case EventTypes.track:
      trackEvent(action, state);
      break;
    default:
      if (action.meta?.analytics?.eventType === EventTypes.track) {
        trackEvent(action, state);
      }
      break;
  }

  return next(action);
};
