import pick from 'lodash.pick';
import * as reduxSegment from 'redux-segment';
import { objectIsEmpty } from '@src/lib/func';
import { sendToNative } from '@src/nativeBridge';
import { AUTHENTICATED } from '../store/auth/constants';

function buildNameFromPath(path) {
  if (!path) {
    return '';
  }

  const pathSections = path.split('/').filter(Boolean);

  if (pathSections.length > 2) {
    return `${pathSections[1]} - ${pathSections[pathSections.length - 1]}`;
  }

  return pathSections[1];
}

export const trackPageView = (getState, action) => {
  const { payload } = action;
  const { language, countryCode, brandConfig } = getState().localisation;
  const { currentUser: { id, registeredCountry } } = getState().auth;
  const name = buildNameFromPath(payload.pathname);

  return {
    eventType: reduxSegment.EventTypes.page,
    eventPayload: {
      name,
      ...payload,
      properties: {
        siteBrand: brandConfig.name,
        language,
        siteCountryCode: countryCode,
        user_id: id, // eslint-disable-line camelcase
        siteId: registeredCountry
      }
    }
  };
};

function injectSegmentEvent(segmentEvent, impersonatorToken) {
  segmentEvent.eventPayload = {
    ...segmentEvent.eventPayload,
    properties: {
      ...segmentEvent.eventPayload.properties,
      ...pick(impersonatorToken, ['impersonatorId', 'sessionId'])
    }
  };

  return segmentEvent;
}

export function injectImpersonatorDetails() {
  return ({ getState }) => next => action => {
    const {
      impersonatorToken
    } = getState().auth.currentUser || {};

    if (!impersonatorToken) {
      return next(action);
    }

    const metaAnalytics = action.meta && action.meta.analytics;
    const metaAnalyticsArray = Array.isArray(metaAnalytics) ? metaAnalytics : [metaAnalytics];

    metaAnalyticsArray
      .filter(event => event && event.eventType === reduxSegment.EventTypes.track)
      .forEach(event => injectSegmentEvent(event, impersonatorToken));

    return next(action);
  };
}

export function createTracker(client) {
  return reduxSegment.createTracker({
    mapper: {
      '@@router/CALL_HISTORY_LOCATION': (getState, action) => trackPageView(getState, action),
      '@@router/UPDATE_LOCATION': (getState, action) => trackPageView(getState, action),
      '@@router/LOCATION_CHANGE': (getState, action) => trackPageView(getState, action),
      [AUTHENTICATED]: (getState, action) => {
        return {
          eventType: reduxSegment.EventTypes.identify,
          eventPayload: (action.result && action.result.id) ? {
            userId: action.result.id
          } : {}
        };
      }
    },
    client
  });
}

async function nativeSegment(type, payload) {
  return await sendToNative('segment', {
    segmentType: type,
    segmentPayload: JSON.stringify(payload)
  });
}

// Note: currently only track/screen/identify events
// are coming through segment
export function createNativeClient(history) {
  return {
    track: async (eventName, properties) => {
      return await nativeSegment('track', {
        eventName,
        properties
      });
    },
    identify: async (userId, traits, options) => {
      if (!userId || objectIsEmpty(userId)) return;

      return await nativeSegment('identify', {
        userId,
        traits,
        options
      });
    },
    page: async (properties) => {
      const { pathname, search } = history.location;
      const mappedProperties = {
        path: pathname,
        search,
        url: `${pathname}${search}`,
        ...properties
      };

      return await nativeSegment('screen', {
        name: pathname,
        properties: mappedProperties
      });
    }
  };
}
