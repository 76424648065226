import { createContext, useContext } from 'react';
import { UpdateNoteParams } from '@common/hooks/application/notes/useUpdateNote';

interface ApplicationContextProps {
  updateNote: (params: UpdateNoteParams) => Promise<void>;
}

export const ApplicationContext = createContext<ApplicationContextProps>({
  updateNote: {} as ApplicationContextProps['updateNote']
});

export const useApplicationContext = () => useContext(ApplicationContext);
