import React, { useEffect } from 'react';
import PullToRefresh from 'pulltorefreshjs';
import { useTranslation } from 'react-i18next';
import { useAppRefresh } from '@common/hooks/useAppRefresh';
import { inNativeApp } from '@src/joraEmployer/whereAmIRunning';

export const UniversalRefresher = () => {
  const { refreshApp } = useAppRefresh();
  const { t } = useTranslation();
  useEffect(() => {
    if (!inNativeApp()) return;
    setTimeout(() => {
      PullToRefresh.init({
        mainElement: '#main-scroller',
        triggerElement: '#main-scroller',
        distThreshold: 100,
        distMax: 120,
        instructionsPullToRefresh: t('common.refresh.pullToRefresh'),
        instructionsReleaseToRefresh: t('common.refresh.releaseToRefresh'),
        instructionsRefreshing: t('common.refresh.refreshing'),
        shouldPullToRefresh() {
          const scrollPosition =
            document.getElementById('main-scroller')?.scrollTop;
          return scrollPosition === 0;
        },
        onRefresh() {
          refreshApp();
        }
      });
    }, 200);
  }, [refreshApp, t]);
  return <></>;
};
