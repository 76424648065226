import {
  SHIFT_AVAILABILITY_TYPE,
  ShiftAvailability
} from '@seek/je-shared-data/lib/types/jobs';

const { SPECIFIC, ANY_TIME } = SHIFT_AVAILABILITY_TYPE;

const tabIndexOrder = [SPECIFIC, ANY_TIME];

export function useShiftAvailabilityTab(
  value: ShiftAvailability | undefined,
  onChange: (value: ShiftAvailability) => void
) {
  const selectedTabValue = value?.type ? tabIndexOrder.indexOf(value.type) : 0;

  const handleOnTabPress = (tabIndexSelected: number) => {
    onChange({
      ...value,
      type: tabIndexOrder[tabIndexSelected]
    });
  };

  return {
    selectedTabValue,
    handleOnTabPress
  };
}
