import { User } from '@common/types';
import { AuthAction } from './actions';
import * as constants from './constants';

type AuthState = {
  currentUser: Partial<User>;
  isAuthenticated: boolean;
  forgotPassword: {
    email?: string;
  };
  experiments: {
    isInAdTypeUpsellExperiment?: boolean;
  };
};

const initialState: AuthState = {
  currentUser: {},
  isAuthenticated: false,
  forgotPassword: {
    email: undefined
  },
  experiments: {}
};

function isCohortEvenNumber(cohort) {
  return cohort && cohort % 2 === 0;
}

export default function reducer(
  state = initialState,
  action: Partial<AuthAction> = {}
) {
  switch (action.type) {
    case constants.AUTHENTICATED:
      return {
        ...state,
        currentUser: action.result || ({} as Partial<User>),
        isAuthenticated: !!action.result,
        experiments: {
          isInAdTypeUpsellExperiment: action.result
            ? isCohortEvenNumber(action.result.cohort)
            : false
        }
      };
    case constants.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          email: action.result?.email
        }
      };
    case constants.DELETE_ACCOUNT_REQUEST_SUCCESS:
    case constants.LOGOUT_SUCCESS:
      return {
        ...state,
        currentUser: {} as Partial<User>,
        isAuthenticated: false,
        experiments: {}
      };
    case constants.SUBMIT_ACCOUNT_APPEAL_SUCCESS:
    case constants.UPDATE_ACCOUNT_DETAILS_SUCCESS:
    case constants.GET_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.result
        },
        isAuthenticated: true
      };
    case constants.VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          emailVerified: true
        }
      };

    default:
      return state;
  }
}
