import { getUuid } from '@common/helpers/uuid';
import { AppDispatch } from '@store/store';
import { buildError } from '../../helpers/responseData';
import * as constants from './constants';

export type AppAction = {
  type: string;
  payload: {
    requestKey: string;
    error?: AppError;
    context?: any;
  };
};

export type AppError = {
  message: string | Record<string, any>;
  properties?: {
    errorMessage?: string;
    guid: string;
    type?: string;
  };
};

export function clearError(requestKey: string) {
  return {
    type: constants.CLEAR_ERROR,
    payload: { requestKey }
  };
}

export function startLoading(
  dispatch: AppDispatch,
  requestKey: string,
  showGlobalSpinner: boolean
) {
  dispatch({
    type: showGlobalSpinner
      ? constants.START_LOADING_WITH_SPINNER
      : constants.START_LOADING,
    payload: { requestKey }
  });
}

export function endLoading(
  dispatch: AppDispatch,
  requestKey: string,
  showGlobalSpinner: boolean
) {
  dispatch({
    type: showGlobalSpinner
      ? constants.END_LOADING_WITH_SPINNER
      : constants.END_LOADING,
    payload: {
      requestKey
    }
  });
}

export function updateSuccess(dispatch: AppDispatch, requestKey: string) {
  dispatch({
    type: constants.UPDATE_SUCCESS,
    payload: {
      requestKey
    }
  });
}

export function updateError(
  dispatch: AppDispatch,
  requestKey: string,
  error: any
) {
  let appError = error;
  if (!appError.preventLogging) {
    if (typeof appError === 'string') {
      appError = { message: appError, properties: { guid: `ui-${getUuid()}` } };
    }

    if (typeof appError === 'object' && !appError.properties) {
      appError.properties = { guid: `ui-${getUuid()}` };
    }
  }

  dispatch({
    type: constants.UPDATE_ERROR,
    payload: {
      requestKey,
      error: buildError(appError)
    }
  });
}

export function updateSubmissionError(dispatch, requestKey, context) {
  dispatch({
    type: constants.UPDATE_SUBMISSION_ERROR,
    payload: {
      requestKey,
      context
    }
  });
}

export function updateCustomError(
  dispatch: AppDispatch,
  requestKey: string,
  error: any
) {
  dispatch({
    type: constants.UPDATE_ERROR,
    payload: {
      requestKey,
      error
    }
  });
}
