import React from 'react';
import { Circle, G, Mask, Path, Rect } from 'svgs';
import { createIllustration } from '../createIllustration';

const vectorComponent = () => (
  <>
    <Path
      fill="#DEF0FC"
      fillRule="evenodd"
      d="M59.084 105.298c-29.496.031-42.263-4.266-51.763-26.404-9.782-22.795-8.555-51.352 16.055-65.46C47.774-.552 82.934 10.516 107.77 24.01c27.569 14.978 46.52 30.594 35.405 65.999-27.2 45.037-54.765 15.258-84.091 15.29Z"
      clipRule="evenodd"
    />
    <Rect
      width={47.218}
      height={4.771}
      x={20.715}
      y={24.42}
      fill="#7FC1F4"
      rx={0.681}
      transform="rotate(-6.853 20.715 24.42)"
    />
    <Rect
      width={47.218}
      height={4.771}
      x={29.385}
      y={96.555}
      fill="#7FC1F4"
      rx={0.681}
      transform="rotate(-6.853 29.385 96.555)"
    />
    <Mask id="b" width={54} height={74} x={21} y={23}>
      <Path
        fill="#E6D9FF"
        d="m21.284 29.158 45.45-5.462 8.1 67.397-45.45 5.463z"
      />
    </Mask>
    <G fill="#B9DEF8" mask="url(#b)">
      <Path d="M46.805 60.04c-.034-3.78-5.793-6.66-8.668-7.629l8.936-1.074 2.023 16.836-8.936 1.074c2.23-1.494 6.679-5.427 6.645-9.207ZM51.041 59.532c-.862-3.68 4.05-7.844 6.614-9.466l-8.936 1.074 2.023 16.836 8.936-1.074c-2.52-.923-7.774-3.69-8.637-7.37Z" />
      <Path d="M66.172 30.44C67.543 41.85 59.407 52.209 48 53.58c-11.408 1.37-21.768-6.766-23.139-18.174-1.37-11.408-1.252-20.804 18.174-23.139C62.459 9.933 64.8 19.033 66.172 30.441ZM72.553 83.532c-1.37-11.408-11.73-19.544-23.138-18.173-11.408 1.37-19.545 11.73-18.174 23.138 1.371 11.408 3.713 20.508 23.139 18.174 19.426-2.335 19.544-11.73 18.173-23.139Z" />
      <Path d="m46.166 51.446 2.553-.307 1.93 16.059-2.553.306z" />
    </G>
    <Mask id="d" width={50} height={74} x={24} y={23}>
      <Mask id="c" width={54} height={74} x={21} y={23}>
        <Path
          fill="#6E30E8"
          d="m21.284 29.158 45.45-5.462 8.1 67.397-45.45 5.463z"
        />
      </Mask>
      <G fill="#6E30E8" mask="url(#c)">
        <Path d="M46.805 60.041c-.034-3.78-5.793-6.66-8.668-7.629l8.936-1.074 2.023 16.836-8.936 1.074c2.23-1.494 6.679-5.427 6.645-9.207ZM51.041 59.532c-.862-3.68 4.05-7.844 6.614-9.466l-8.936 1.074 2.023 16.836 8.936-1.074c-2.52-.923-7.774-3.69-8.637-7.37Z" />
        <Path d="M66.172 30.44C67.543 41.85 59.407 52.209 48 53.58c-11.408 1.37-21.768-6.766-23.139-18.174-1.37-11.408-1.252-20.804 18.174-23.139C62.459 9.933 64.8 19.033 66.172 30.441ZM72.553 83.532c-1.37-11.408-11.73-19.544-23.138-18.173-11.408 1.37-19.545 11.73-18.174 23.138 1.371 11.408 3.713 20.508 23.139 18.174 19.426-2.335 19.544-11.73 18.173-23.139Z" />
        <Path d="m46.166 51.447 2.553-.307 1.93 16.059-2.553.306z" />
      </G>
    </Mask>
    <G mask="url(#d)">
      <Mask id="e" width={54} height={74} x={23} y={23}>
        <Path
          fill="#6E30E8"
          d="m23.404 28.903 45.45-5.463 8.1 67.398-45.45 5.463z"
        />
      </Mask>
      <G fill="#F2FAFE" mask="url(#e)">
        <Path d="M48.924 59.787c-.034-3.78-5.793-6.66-8.668-7.629l8.935-1.074 2.024 16.836-8.936 1.074c2.23-1.494 6.679-5.427 6.645-9.207ZM53.16 59.278c-.863-3.68 4.05-7.844 6.613-9.465l-8.935 1.073 2.023 16.836 8.936-1.074c-2.52-.923-7.774-3.69-8.637-7.37Z" />
        <Path d="M68.291 30.187c1.371 11.408-6.765 21.768-18.173 23.139-11.408 1.37-21.768-6.766-23.139-18.174-1.37-11.408-1.252-20.804 18.174-23.139C64.578 9.68 66.92 18.78 68.29 30.188ZM74.672 83.278c-1.371-11.408-11.73-19.544-23.139-18.173-11.408 1.37-19.544 11.73-18.173 23.138 1.371 11.408 3.713 20.509 23.139 18.174 19.425-2.335 19.544-11.73 18.173-23.139Z" />
        <Path d="m48.285 51.192 2.553-.307 1.93 16.059-2.553.306zM50.9 77.597c-.352 1.468-.07 1.767-2.06 3.433 3.81-.307 7.033.4 5.626-.675-1.32-1.01-1.828-.162-2.71-2.263-.366-.536-.613-1.51-.856-.495Z" />
      </G>
    </G>
    <Path
      fill="#4BA2EE"
      d="M48.337 55.05c.081-1.04-2.923-1.991-4.564-2.417 3.773-.604 10.713-2.618 9.368-1.775-1.41.883-3.558 2.58-4.222 3.67-.227.608-.663 1.563-.582.522Z"
    />
    <Path
      fill="#4BA2EE"
      d="M44.795 40.105c-7.159-1.498-12.23 1.622-13.975 3.798 3.317 4.844 11.745 11.133 19.46 8.298 9.186-3.376 12.452-11.426 13.53-14.381-1.865 2.374-10.066 4.158-19.015 2.285ZM68 91.862l-29.066 3.493c.275-1.132 2.005-2.566 5.353-8.621 7.539-11.434 8.6-6.51 16.897-2.736 3.616 1.644 6.485 5.118 6.815 7.864Z"
    />
    <Path fill="#4BA2EE" d="m47.905 52.728.79-.095 3.394 28.247-.79.095z" />
    <Path fill="#C1E2CF" d="m116.47 81.437-.103-8.623-.156-.13.259 8.753Z" />
    <Path
      fill="#1B1C1C"
      d="m96.023 94.67-2.889 10.03 19.022 2.604.539-10.351-16.672-2.282Zm9.134 3.416a6.582 6.582 0 1 1 0 0ZM92.02 94.123l-17.167-2.35c-.963 3.62-1.298 7.593-1.35 9.252l-.028.984 18.1 2.477c-.333-3.198-.467-7.43.445-10.363Zm-15.134 7.092Z"
    />
    <Path
      fill="#1B1C1C"
      d="m91.577 104.483 1.555.213 2.889-10.03-4-.547c-.913 2.936-.779 7.168-.444 10.364Z"
    />
    <Path
      fill="#fff"
      d="M118.591 112.068c1.847-.32 2.849-1.696 3.354-3.428l-5.485-.751c.496 4.146 2.131 4.179 2.131 4.179ZM116.149 97.475c-.278.25-.538.517-.78.784l.78-.784Z"
    />
    <Path
      fill="#fff"
      d="m122.082 101.05-1.93 1.604c-.108-.905-.188-1.457-.188-1.457-1.387-3.65-3.146-3.72-4.696-2.828-.392.442-.733.872-.998 1.197-1.394 1.704-.847 6.189.188 6.522 1.03.373 1.907-2.639 1.907-2.639-.095 1.85-.043 3.3.091 4.44l5.485.751c.724-2.477.427-5.688.141-7.59ZM138.274 77.328c-.033-.043-.07-.048-.102-.09-.103-.09-.24-.227-.382-.323l-1.961-1.62-.435 3.175c.416.03.793.088 1.079.163l-1.081-.148-1.779 12.993 1.373-1.14c0-.003 9.2-7.33 3.288-13.01Z"
    />
    <Path
      fill="#fff"
      d="m115.246 98.383.025-.014c.032-.037.066-.073.097-.11l-.122.124ZM135.182 78.453c.073.004.143.008.213.014l.434-3.175-9.57-7.91-5.744 6.483 8.288 6.847c.428.287.774.57 1.005.834.334.392.478.761.314 1.124-.646 1.304-9.827 10.64-13.97 14.806 1.649-1.485 3.83-2.288 5.64 1.959 0 0 .146.631.293 1.615l11.529-9.575 1.779-12.993-.211-.029Z"
    />
    <Path
      fill="#fff"
      d="M115.269 98.368c1.55-.892 3.309-.825 4.695 2.829 0 0 .078.551.189 1.457l1.929-1.605c-.148-.981-.293-1.615-.293-1.615-1.813-4.247-3.994-3.443-5.64-1.959l-.78.784-.1.11Z"
    />
    <Path
      fill="#1B1C1C"
      d="M99.197 49.364c-7.474-2.88-4.79-12.317-4.78-12.384 8.188 1.198 15.571.081 15.571.081.4 1.333.659 2.526.787 3.59.158-.017.308.004.501.03a2.832 2.832 0 0 1 2.424 3.197 2.831 2.831 0 0 1-3.193 2.424 212.81 212.81 0 0 1-.303-.041c-1.713 3.773-6.112 3.908-7.511 3.852l-.29 2.117 10.728 1.468c1.67.229 3.192.98 4.424 2.154l6.273 6.13c3.667-.688 6.954-2.25 9.751-5.405 3.559-3.961 5.49-9.885 2.111-14.563-2.444-3.35-6.687-5.403-10.639-6.173-4.483-.884-9.529-.725-13.358-3.568-2.768-2.08-4.579-4.108-8.168-4.717-2.646-.439-5.324-.109-7.727 1.07-4.889 2.386-7.508 7.674-7.266 12.967.006.233.013.466.087.784.32 3.217 1.31 5.985 2.796 8.348l7.458 1.021.326-2.382h-.002Z"
    />
    <Path
      fill="#fff"
      d="M68.907 56.655c-.43-1.493.136-16.203.351-21.386-.575 1.605-1.975 2.603-5.318.906 0 0-.465-.355-1.147-.956l-2.612 19.08s-1.839 11.737 6.428 11.787c1.409.008 2.598-.008 3.604-.041l1.214-8.869c-1.243.215-2.318.176-2.52-.521ZM102.251 50.081c-1.172-.13-2.184-.38-3.057-.718l-.326 2.38 3.53.483.29-2.117c-.275-.008-.437-.028-.437-.028Z"
    />
    <Path
      fill="#22C365"
      d="m126.893 64.98-3.071-3c-2.026.38-4.166.496-6.403.463-9.731-.192-20.852-3.512-26.01-11.722l-.567-.078a9.218 9.218 0 0 0-3.867.284L72.46 55.013c-.482.128-.81.548-.872 1.003l-.158 1.157c1.998-.346 4.438-1.346 4.438-1.346l-.254 9.478s-.323.365-2.943.594c-.66.06-1.47.107-2.455.142l-.14 1.019a1.19 1.19 0 0 0 1.361 1.346l9.914-1.507-10.388 22.788a1.191 1.191 0 0 0 .931 1.673l2.962.406c.513-1.928 1.205-3.758 2.124-5.084 1.11-1.625 2.58-2.473 4.503-1.819l12.85 4.424a3.283 3.283 0 0 1 1.756 1.445c.057.008.113.01.17.02.005-.037 13.539 1.39 13.539 1.39 1.75.199 3.053 1.693 2.967 3.459l-.07 1.345 2.847.39c.761.104 1.38-.47 1.366-1.206l-.434-14.7-.259-8.753.155.132 1.99 1.71c.484.413 1.226.358 1.676-.118l.479-.54-1.647-1.362-.556-.46 5.517-6.673.332.275 2.101 1.736.685-.773c.442-.436.387-1.178-.054-1.624Z"
    />
    <Path
      fill="#22C365"
      d="M109.795 92.148s-13.534-1.426-13.539-1.389c-.058-.008-.113-.012-.17-.02.445.76.588 1.69.32 2.591l-.386 1.34 16.672 2.282.07-1.345c.089-1.765-1.214-3.26-2.967-3.459Z"
    />
    <Path
      fill="#22C365"
      d="M96.088 90.739a3.282 3.282 0 0 0-1.756-1.445l-12.85-4.424c-1.923-.651-3.39.194-4.503 1.82-.922 1.324-1.613 3.154-2.124 5.082l17.168 2.35c.072-.228.147-.452.232-.664.73-1.777 1.927-2.939 3.833-2.719Z"
    />
    <Path
      fill="#22C365"
      d="M96.088 90.74c-1.906-.22-3.102.94-3.836 2.718-.085.212-.16.436-.232.665l4 .547.386-1.34c.27-.9.127-1.83-.318-2.59ZM123.802 66.104l.353-.457-.332-.274-5.517 6.672.556.461 1.647 1.363 5.744-6.483-2.101-1.736-.35.454ZM117.419 62.444c2.237.03 4.377-.085 6.403-.463l-6.273-6.13c-1.232-1.173-2.754-1.925-4.424-2.154l-10.728-1.468-.177 1.295-3.53-.483.177-1.295-7.457-1.021c5.157 8.207 16.278 11.527 26.009 11.72ZM72.67 65.903c2.62-.231 2.944-.593 2.944-.593l.254-9.479s-2.44 1-4.438 1.347l-1.214 8.868a43.55 43.55 0 0 0 2.455-.143Z"
    />
    <Path
      fill="#22C365"
      d="m102.4 52.23-3.53-.484-.177 1.295 3.53.483.177-1.295Z"
    />
    <Path
      fill="#fff"
      d="m102.857 48.889-.167 1.222c1.399.057 5.801-.078 7.511-3.852l.303.042a2.831 2.831 0 0 0 3.193-2.425 2.832 2.832 0 0 0-2.424-3.196 2.084 2.084 0 0 0-.501-.03c-.125-1.06-.387-2.257-.787-3.59 0 0-7.383 1.116-15.572-.081-.008.066-2.693 9.504 4.78 12.383l.132-.957 3.532.484Z"
    />
    <Path
      fill="#fff"
      d="M102.25 50.081s.162.02.437.03l.167-1.222-3.53-.484-.13.958c.876.337 1.884.587 3.056.718ZM61.692 29.78a11.929 11.929 0 0 1-4.017-1.303c.673 2.126 2.528 4.263 4.044 5.743l.599-4.372c-.207-.017-.415-.04-.626-.068ZM62.917 34.333l6.42-.86s-.032.677-.076 1.8c.38-1.056.401-2.376.386-3.362-1.044 1.406-1.294.892-3.473-1.198 0 0 .047-.496.15-1.208-1.276.334-2.624.46-4.004.345l-.598 4.373c.388.38.757.715 1.074.996l.12-.886Z"
    />
    <Path
      fill="#fff"
      d="m69.261 35.27.076-1.8-6.42.86-.12.886c.68.602 1.147.956 1.147.956 3.342 1.7 4.74.702 5.317-.903ZM136.474 78.63a6.31 6.31 0 0 0-1.08-.163l-.002.015 1.082.148Z"
    />
    <Path
      fill="#fff"
      d="m135.392 78.483.002-.015c-.069-.006-.139-.01-.212-.014l.21.029Z"
    />
    <Path
      fill="#62BE81"
      d="M62.77 13.965c-.85.873-.466 1.543.267 2.087.08-.818.16-1.635.248-2.458a1.707 1.707 0 0 0-.515.37Z"
    />
    <Path
      fill="#62BE81"
      d="m62.79 26.404.07-.511c-.432.382-.763-.295-.779-.715a12.2 12.2 0 0 1 .003-.966c-1.417-.4-2.742-1.345-3.409-2.611-.708-1.346 1.479-2.234 2.178-.904.294.554.834 1.01 1.449 1.303l.05-.407c.137-1.11.318-2.213.449-3.324a5.457 5.457 0 0 1-.894-.445c-1.212-.8-2.24-2.199-1.826-3.723.13-.47.373-.9.7-1.265.7-.782 1.735-1.24 2.783-1.34.15-.644.534-1.564 1.098-1.501.206.022.343.223.428.546.075.287.106.667.11 1.11.312.097.61.235.882.421.494.338 1.909 1.36 1.221 2.218-.531.662-1.571-.238-2.228-.616a52.625 52.625 0 0 1-.473 3.267c.335.17.636.328.84.476 1.243.914 2.03 2.45 1.745 3.991l4.971.681c.407-.867.689-1.81.825-2.805.73-5.335-3.016-10.27-8.35-11-5.336-.73-10.27 3.016-11 8.351-.505 3.68 1.121 7.167 3.929 9.217a3.78 3.78 0 0 1 .792-1.428c.002.003 1.32-1.065 4.435 1.98Z"
    />
    <Path
      fill="#62BE81"
      d="M57.5 26.109c-2.927-2.093-4.628-5.7-4.107-9.506.748-5.469 5.807-9.31 11.275-8.561 5.468.748 9.309 5.808 8.56 11.276a9.982 9.982 0 0 1-.816 2.806l1.973.27c.353-.886.609-1.83.744-2.816.746-5.454-2.317-10.548-7.154-12.6a7.248 7.248 0 0 0-.54-.212 11.865 11.865 0 0 0-3.016-.683C58.346 5.51 52.826 9.63 51.62 15.578c-.052.252-.092.505-.127.762-.11.807-.138 1.606-.086 2.385.027.407.071.81.138 1.206a11.953 11.953 0 0 0 6.134 8.544c-.258-.8-.342-1.601-.179-2.366Z"
    />
    <Path
      fill="#62BE81"
      d="m63.997 20.972 1.226.168c.021-.074.04-.15.054-.225.035-.189.052-.383.025-.572-.093-.668-.506-1.09-1.035-1.405-.096.677-.18 1.356-.27 2.034ZM65.313 26.422c.329-.128.885.101 1.492.543.491-1.958 1.286-3.62 2.459-1.888.079.131.159.274.235.426a9.789 9.789 0 0 0 2.659-3.412l-4.972-.68c-.014.08-.031.163-.05.242-.52 2.013-2.063 2.812-3.723 2.767-.069.287-.143.574-.223.86-.05.175-.105.356-.218.499a1.006 1.006 0 0 1-.11.117l-.07.51c.378.37.78.798 1.212 1.297.32-.023.64-.062.953-.116-.064-.543-.002-1.017.356-1.165Z"
    />
    <Path
      fill="#62BE81"
      d="M69.613 25.727c.272.564.514 1.22.68 1.903a11.896 11.896 0 0 0 4.092-5.234l-1.973-.27a10.024 10.024 0 0 1-2.8 3.601ZM63.802 22.35c.06-.001.115-.003.172-.007.615-.05 1.066-.6 1.248-1.2l-1.225-.169c-.06.46-.123.918-.195 1.375ZM65.566 29.665s-.416-.956-.574-1.838a9.747 9.747 0 0 1-.795.1c.432.511.888 1.085 1.369 1.738ZM62.318 29.847l.26-1.906c-.209-.017-.418-.034-.628-.063a9.939 9.939 0 0 1-4.453-1.77c-.163.765-.08 1.567.175 2.369a11.933 11.933 0 0 0 4.646 1.37Z"
    />
    <Path
      fill="#62BE81"
      d="M58.356 24.428a3.78 3.78 0 0 0-.792 1.428 9.713 9.713 0 0 0 5.05 1.843l.178-1.295c-3.117-3.044-4.436-1.976-4.436-1.976ZM64.995 27.828c.155.882.574 1.839.574 1.839a33.838 33.838 0 0 0-1.37-1.736c-.532.047-1.07.053-1.617.013l-.261 1.907c1.38.115 2.728-.012 4.004-.345a27.33 27.33 0 0 1 .386-2.133c-.553.2-1.13.353-1.716.455Z"
    />
    <Path
      fill="#62BE81"
      d="M62.612 27.698c.468.035.932.034 1.39.002a21.43 21.43 0 0 0-1.213-1.297l-.177 1.295ZM65.313 26.424c-.358.148-.422.621-.358 1.162a9.554 9.554 0 0 0 1.819-.5c.008-.04.02-.08.028-.12-.603-.441-1.16-.67-1.489-.542Z"
    />
    <Path
      fill="#fff"
      d="M63.038 16.05c-.731-.545-1.118-1.215-.267-2.088.16-.163.333-.287.515-.37.064-.6.137-1.198.22-1.8.012-.087.031-.188.058-.296-1.048.1-2.083.559-2.784 1.341a3.15 3.15 0 0 0-.699 1.265c-.417 1.523.614 2.92 1.826 3.723.259.17.569.31.894.445a116.641 116.641 0 0 0 .236-2.221ZM60.852 20.7c-.702-1.33-2.889-.443-2.177.903.667 1.267 1.995 2.212 3.409 2.612.031-.742.122-1.473.217-2.21-.614-.295-1.154-.751-1.449-1.305ZM67.303 14.29c.688-.857-.727-1.88-1.221-2.217a3.38 3.38 0 0 0-.882-.42c.003.593-.046 1.298-.125 2.021.657.378 1.697 1.279 2.228.617ZM65.442 17.418c-.203-.148-.505-.307-.84-.476-.105.617-.194 1.095-.23 1.317-.037.227-.071.451-.102.679.53.316.942.737 1.035 1.405.027.192.01.384-.025.573a2.11 2.11 0 0 1-.054.224l1.964.27c.281-1.543-.504-3.079-1.748-3.992ZM63.977 22.344c-.056.004-.115.005-.171.006a28.149 28.149 0 0 1-.39 2.07c1.66.046 3.203-.754 3.722-2.766.02-.08.037-.162.051-.242l-1.964-.27c-.184.598-.636 1.15-1.248 1.202Z"
    />
    <Path
      fill="#fff"
      d="M63.285 13.595c-.09.822-.169 1.64-.248 2.457.458.342 1.053.632 1.564.893.156-.904.35-2.111.474-3.267a1.316 1.316 0 0 0-.296-.137c-.526-.152-1.039-.16-1.494.054ZM62.087 24.212c-.014.318-.017.641-.003.966.016.42.347 1.096.778.715l.205-1.497a5.444 5.444 0 0 1-.98-.184ZM65.2 11.651c-.005-.441-.037-.825-.11-1.11-.085-.324-.222-.522-.427-.547-.568-.063-.95.857-1.099 1.5a4.145 4.145 0 0 1 1.636.156ZM63.348 22.32l.193-1.41.453.061c.09-.678.174-1.36.272-2.034-.445-.266-.974-.46-1.466-.665-.131 1.11-.312 2.214-.449 3.324-.015.136-.034.271-.05.407.337.158.695.269 1.047.317Z"
    />
    <Path
      fill="#fff"
      d="M62.86 25.893a.798.798 0 0 0 .11-.117c.113-.143.167-.324.217-.5.084-.285.158-.571.224-.859a6.746 6.746 0 0 1-.347-.02l-.205 1.496ZM63.997 20.972l-.453-.062-.193 1.41c.153.021.305.03.454.03.069-.457.132-.916.192-1.378ZM64.371 18.258c.036-.221.125-.7.23-1.317-.513-.262-1.105-.551-1.564-.893-.065.667-.132 1.33-.206 1.999-.007.075-.017.15-.028.225.492.206 1.021.399 1.466.665.032-.228.065-.452.102-.68ZM63.064 24.396l.284-2.076a3.551 3.551 0 0 1-1.046-.32c-.092.74-.184 1.47-.217 2.21.325.094.654.156.979.186ZM63.285 13.594c.453-.214.966-.206 1.495-.054.084.023.183.072.295.137.078-.724.128-1.428.124-2.022a3.822 3.822 0 0 0-.897-.164 4.813 4.813 0 0 0-.739.01 2.92 2.92 0 0 0-.058.295c-.082.6-.152 1.199-.22 1.798Z"
    />
    <Path
      fill="#fff"
      d="m63.348 22.32-.284 2.076c.116.01.232.02.346.021.162-.683.286-1.374.39-2.07a2.263 2.263 0 0 1-.452-.027ZM64.004 27.7c.064.074.13.15.195.23.268-.025.534-.056.795-.1a3.603 3.603 0 0 1-.037-.243c-.313.051-.632.09-.953.113ZM61.986 27.637a9.713 9.713 0 0 1-4.422-1.78c-.023.084-.047.17-.064.255a9.939 9.939 0 0 0 5.081 1.833l.033-.242a11.39 11.39 0 0 1-.628-.066Z"
    />
    <Path
      fill="#fff"
      d="M64.957 27.584c.01.08.022.162.037.243a10.02 10.02 0 0 0 1.717-.455c.021-.094.043-.191.068-.285a9.656 9.656 0 0 1-1.822.497ZM64.004 27.701a9.719 9.719 0 0 1-1.39-.002l-.032.242c.547.04 1.088.035 1.617-.013l-.195-.227ZM70.293 27.628c-.558.402-1.15.759-1.77 1.062.628.84 1.094 1.79 1.11 2.617.005.18.01.383.012.6.076-.103.156-.216.243-.339.743-1.068.736-2.561.405-3.94Z"
    />
    <Path
      fill="#fff"
      d="M68.522 28.69c-.699.342-1.435.614-2.197.812-.104.715-.15 1.208-.15 1.208 2.178 2.088 2.428 2.604 3.472 1.198-.002-.218-.01-.421-.011-.6-.02-.826-.486-1.774-1.114-2.618ZM66.808 26.967c.032.022.061.046.093.068a9.86 9.86 0 0 0 2.602-1.533 9.004 9.004 0 0 0-.236-.426c-1.173-1.73-1.97-.067-2.46 1.89ZM67.12 27.21c.484.401.98.915 1.4 1.478.62-.303 1.212-.66 1.77-1.062a9.621 9.621 0 0 0-.68-1.903 9.885 9.885 0 0 1-2.49 1.487Z"
    />
    <Path
      fill="#fff"
      d="M67.12 27.21c-.136.055-.273.11-.412.162-.17.755-.3 1.522-.386 2.133a11.72 11.72 0 0 0 2.197-.813 8.713 8.713 0 0 0-1.398-1.481ZM66.776 27.088c.04-.015.08-.032.124-.05-.032-.025-.064-.047-.093-.068l-.03.119Z"
    />
    <Path
      fill="#fff"
      d="M66.901 27.038c.075.057.146.114.22.174.9-.373 1.739-.875 2.492-1.483-.037-.076-.073-.151-.113-.224a9.791 9.791 0 0 1-2.599 1.533Z"
    />
    <Path
      fill="#fff"
      d="m66.777 27.087-.069.284c.14-.051.276-.103.413-.16a7.538 7.538 0 0 0-.22-.175c-.04.015-.08.033-.124.05Z"
    />
    <Mask id="f" width={25} height={26} x={51} y={5}>
      <Circle
        cx={63.563}
        cy={18.131}
        r={12.211}
        fill="#D9D9D9"
        transform="rotate(15 63.563 18.131)"
      />
    </Mask>
    <G mask="url(#f)">
      <Circle
        cx={63.438}
        cy={18.599}
        r={11.352}
        fill="url(#g)"
        transform="rotate(15 63.438 18.6)"
      />
      <Path
        stroke="#FFCD6A"
        strokeWidth={1.468}
        d="M74.473 21.01c-1.66 6.199-8.032 9.878-14.231 8.217-6.199-1.661-9.878-8.033-8.217-14.232 1.661-6.199 8.033-9.877 14.232-8.216 6.199 1.66 9.877 8.032 8.216 14.231Z"
      />
      <Circle
        cx={63.794}
        cy={18.545}
        r={11.53}
        stroke="#D89317"
        strokeWidth={1.457}
        transform="rotate(15 63.794 18.545)"
      />
      <Path
        fill="#D89317"
        d="m68.74 12.805-1.275-.341a1.2 1.2 0 0 0-.992.099c-.47.264-.647.437-.785.95l-2.22 8.285c-.36.87-.999 1.183-1.914.938-.915-.246-1.287-.829-1.114-1.75l.434-1.622-2.501-.67-.505 1.883c-.212.794-.828 3.212 3.145 4.178 3.973.966 4.84-1.17 5.056-1.98l2.672-9.97Z"
      />
      <Circle
        cx={63.499}
        cy={18.169}
        r={11.721}
        stroke="url(#h)"
        strokeWidth={1.457}
        transform="rotate(15 63.5 18.17)"
      />
      <Path
        fill="#FFEAB6"
        d="m68.172 12.589-1.217-.326a1.131 1.131 0 0 0-.947.101c-.45.26-.62.43-.754.93L63.09 21.37c-.35.849-.96 1.156-1.833.922-.873-.233-1.224-.799-1.055-1.696l.424-1.581-2.385-.64-.492 1.836c-.208.774-.808 3.13 2.98 4.05 3.789.919 4.627-1.166 4.839-1.955l2.604-9.717Z"
      />
      <Path
        fill="#fff"
        d="M70.208 26.436c-.557.403-1.15.76-1.77 1.062.628.841 1.095 1.79 1.11 2.617.006.18.01.384.013.602l.243-.34c.743-1.068.736-2.562.404-3.94Z"
      />
      <Path
        fill="#fff"
        d="M68.438 27.5c-.699.341-1.435.614-2.197.812-.104.714-.15 1.207-.15 1.207 2.179 2.088 2.428 2.604 3.473 1.199-.003-.218-.01-.422-.012-.601-.02-.826-.486-1.774-1.114-2.618ZM66.724 25.774c.032.022.061.047.093.069a9.859 9.859 0 0 0 2.602-1.533 9.005 9.005 0 0 0-.236-.426c-1.173-1.73-1.97-.067-2.459 1.89ZM67.037 26.02c.482.4.979.915 1.398 1.478a12.08 12.08 0 0 0 1.77-1.062 9.616 9.616 0 0 0-.68-1.903 9.884 9.884 0 0 1-2.488 1.486Z"
      />
      <Path
        fill="#fff"
        d="M67.037 26.02c-.137.055-.274.11-.413.161-.17.755-.3 1.522-.386 2.133.762-.198 1.498-.47 2.197-.813a8.71 8.71 0 0 0-1.398-1.48ZM66.692 25.897c.04-.015.08-.033.124-.05-.032-.025-.064-.047-.093-.069l-.03.12Z"
      />
      <Path
        fill="#fff"
        d="M66.817 25.847c.074.057.146.114.22.174.9-.374 1.738-.876 2.491-1.484a5.103 5.103 0 0 0-.113-.224 9.794 9.794 0 0 1-2.598 1.534Z"
      />
      <Path
        fill="#fff"
        d="M66.692 25.896c-.021.094-.046.19-.068.285.14-.052.276-.103.413-.161a7.372 7.372 0 0 0-.22-.174l-.125.05Z"
      />
    </G>
    <Path
      fill="#fff"
      d="m65.336 31.052.785-1.583 3.538-2.328-1.493 3.68-2.83.23ZM30.583 51.638 9.14 58.05l4.308 16.434 22.345-6.113-5.21-16.733Z"
    />
    <Path
      fill="#E32121"
      d="m26.198 50.252.004-.001.239-.064.005-.001c.542-.146.862-.703.717-1.243l-.723-2.701a1.015 1.015 0 0 0-1.243-.718l-.005.002-.239.063-.004.002a1.015 1.015 0 0 0-.718 1.243l.724 2.7c.144.541.702.863 1.243.718ZM18.949 52.194l.005-.002.238-.064.005-.001c.542-.145.863-.703.718-1.243l-.724-2.7a1.015 1.015 0 0 0-1.243-.718l-.005.001-.238.064-.005.001a1.015 1.015 0 0 0-.718 1.243l.724 2.7c.145.541.7.864 1.243.718Z"
    />
    <Path
      fill="#FFBEBE"
      d="M15.31 59.436a1.084 1.084 0 0 0-1.327-.766l-1.094.293c-.577.154-.92.75-.766 1.327l.293 1.094c.155.578.75.921 1.327.766l1.094-.293c.578-.155.921-.75.767-1.327l-.293-1.094ZM16.548 64.053a1.084 1.084 0 0 0-1.328-.766l-1.094.293c-.577.155-.92.75-.766 1.327l.293 1.094c.155.578.75.921 1.327.766l1.094-.293c.578-.154.922-.75.767-1.327l-.293-1.094ZM16.457 67.903l-1.094.293c-.577.155-.92.75-.766 1.327l.293 1.094c.155.578.75.921 1.328.767l1.093-.293c.578-.155.922-.75.767-1.328l-.293-1.094a1.084 1.084 0 0 0-1.328-.766ZM19.928 58.2a1.084 1.084 0 0 0-1.327-.767l-1.094.293c-.578.155-.921.75-.766 1.328l.293 1.094c.155.577.75.92 1.327.766l1.094-.293c.578-.155.921-.75.766-1.328l-.293-1.093ZM21.166 62.814a1.084 1.084 0 0 0-1.328-.767l-1.094.294c-.577.154-.92.75-.766 1.327l.293 1.094c.155.578.75.921 1.328.766l1.093-.293c.578-.155.921-.75.767-1.327l-.293-1.094ZM21.075 66.666l-1.094.293c-.578.155-.921.75-.766 1.327l.293 1.094c.155.578.75.921 1.327.766l1.094-.293c.578-.155.921-.75.766-1.327l-.293-1.094a1.084 1.084 0 0 0-1.327-.766ZM24.544 56.963a1.084 1.084 0 0 0-1.327-.766l-1.094.293c-.578.155-.921.75-.766 1.327l.293 1.094c.155.578.75.921 1.327.767l1.094-.293c.578-.155.921-.75.766-1.328l-.293-1.094ZM24.456 60.813l-1.094.293c-.578.155-.921.75-.766 1.328l.293 1.094c.155.577.75.92 1.327.766L25.31 64c.578-.155.921-.75.766-1.328l-.293-1.094a1.084 1.084 0 0 0-1.327-.766ZM29.162 55.724a1.084 1.084 0 0 0-1.328-.766l-1.094.293c-.577.155-.92.75-.766 1.327l.293 1.094c.155.578.75.921 1.328.766l1.094-.293c.577-.155.92-.75.766-1.327l-.293-1.094ZM26.192 65.294l-2.093.561a.566.566 0 0 0-.4.694l.56 2.094c.081.302.392.48.694.4l.758-.203a9.2 9.2 0 0 1 .542-3.555c-.02.004-.041.004-.06.01ZM27.478 60.004a.566.566 0 0 0-.4.693l.556 2.076a9.208 9.208 0 0 1 2.773-2.4l-.142-.532a.567.567 0 0 0-.694-.4l-2.094.56v.003Z"
    />
    <Path
      fill="#F88"
      d="m25.915 70.399-12.18 3.263L9.6 58.229l20.715-5.55 1.858 6.935a9.32 9.32 0 0 1 .361-.108 9.208 9.208 0 0 1 1.617-.28l-2.97-11.083a1.237 1.237 0 0 0-1.514-.875l-2.214.593.218.814a1.702 1.702 0 0 1-1.202 2.082 1.702 1.702 0 0 1-2.083-1.202l-.218-.813-3.965 1.062.218.813A1.702 1.702 0 0 1 19.22 52.7a1.702 1.702 0 0 1-2.083-1.203l-.218-.813-3.965 1.063.218.813a1.703 1.703 0 0 1-1.202 2.082 1.702 1.702 0 0 1-2.083-1.202l-.218-.813-2.213.593a1.237 1.237 0 0 0-.875 1.515l5.411 20.195c.177.66.856 1.05 1.515.874l13.05-3.497a9.226 9.226 0 0 1-.549-1.504l-.092-.404Z"
    />
    <Path
      fill="#15A449"
      d="M32.911 60.909c-4.139 1.108-6.604 5.379-5.495 9.517 1.11 4.139 5.38 6.604 9.518 5.495 4.139-1.109 6.604-5.379 5.495-9.517-1.109-4.139-5.38-6.604-9.518-5.495Zm3.503 13.073a5.768 5.768 0 0 1-7.056-4.074 5.768 5.768 0 0 1 4.074-7.057 5.768 5.768 0 0 1 7.056 4.074 5.77 5.77 0 0 1-4.074 7.057Z"
    />
    <Path
      fill="#15A449"
      d="m34.196 68.929-1.542-.89-1.005 1.74 3.282 1.896 3.138-5.435-1.74-1.005-2.133 3.694Z"
    />
    <Circle cx={34.923} cy={68.404} r={8.155} fill="#DFE5FF" />
    <Circle cx={34.922} cy={68.405} r={7.076} fill="#C2CCFF" />
    <Circle cx={34.922} cy={68.404} r={1.29} fill="#6E30E8" />
    <Path
      stroke="#6E30E8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.021}
      d="m37.578 63.188-2.788 5.137 3.544 1.593"
    />
    <Path
      fill="#fff"
      d="M99.658 56.116c-3.089-.29-3.404-3.29-3.045-4.678l7.721 1.024s-1.047 3.995-4.676 3.654Z"
    />
  </>
);

export const UpdateYourBusinessesIllustration = createIllustration({
  viewBox: '0 0 148 118',
  vectorComponent
});
