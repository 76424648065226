import { useTranslation } from 'react-i18next';
import { RIGHT_TO_WORK_FILTER_OPTIONS } from '@common/constants/data';

export const useRightToWorkOptions = () => {
  const { t } = useTranslation();

  const options = RIGHT_TO_WORK_FILTER_OPTIONS(t);

  return [...options];
};
