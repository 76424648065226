import React from 'react';
import { useTranslation } from 'react-i18next';
import { Application } from '@common/types/Application';
import { CardAction } from '@components/Card';
import { HtmlText } from '@components/HtmlText';
import { ViewIcon } from '@components/Icon';
import { Link } from '@components/Link';
import { Modal, useModal } from '@components/Modal';
import { useViewCoverLetterCardAction } from './useViewCoverLetterCardAction';

type ViewCoverLetterActionProps = {
  application: Application;
  responsive: boolean;
  coverLetterMessage: string;
};

export const ViewCoverLetterCardAction = ({
  application,
  responsive,
  coverLetterMessage
}: ViewCoverLetterActionProps) => {
  const { t } = useTranslation();
  const { isModalOpen, openModal, closeModal } = useModal();
  const { trackViewCoverLetter } = useViewCoverLetterCardAction(application);

  const viewCoverLetter = () => {
    openModal();
    trackViewCoverLetter();
  };

  return (
    <>
      <CardAction>
        <Link
          onPress={viewCoverLetter}
          align={{
            mobile: responsive ? 'vertical' : 'horizontal',
            tablet: 'horizontal',
            desktop: 'horizontal'
          }}
          variant="secondary"
          icon={<ViewIcon variant="outline" />}
          testID={`${application.id}-cover-letter`}
        >
          {t('applications.applicationCard.viewCoverLetter')}
        </Link>
      </CardAction>

      <Modal
        testID="cover-letter-modal"
        title={t('applications.applicationCard.coverLetter')}
        visible={isModalOpen}
        onRequestClose={closeModal}
      >
        <HtmlText>{coverLetterMessage}</HtmlText>
      </Modal>
    </>
  );
};
