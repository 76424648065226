import React, { ReactElement } from 'react';
import { Row } from '@components/Row';
import { Text } from '@components/Text';

interface UpgradeInfoProps {
  price: ReactElement;
  name: string;
}
export const UpgradeInfo = ({ price, name }: UpgradeInfoProps) => {
  return (
    <Row justifyContent="space-between">
      <Text size="xlarge" weight="regular">
        {name}
      </Text>
      {price}
    </Row>
  );
};
