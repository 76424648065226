import React from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from '@components/Column';
import { Checkbox } from '@components/FormElements';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { PremiumFeaturesBadge } from '../../../../../PremiumFeaturesBadge';
import { useRightToWorkOptions } from './useRightToWorkOptions';

export const DisabledRightToWorkFilter = () => {
  const { t } = useTranslation();
  const options = useRightToWorkOptions();

  return (
    <Column spacing="large">
      <Row justifyContent="space-between" alignItems="center">
        <Row alignItems="center" spacing="medium">
          <Text weight="medium">
            {t('applications.applicationsFilter.rightToWork.title')}
          </Text>

          <PremiumFeaturesBadge />
        </Row>

        <Text size="small" tone="neutralLight">
          {t('applications.applicationsFilter.clear')}
        </Text>
      </Row>

      <OptionsColumn options={options} />
    </Column>
  );
};

type OptionsColumnProps = {
  options: ReturnType<typeof useRightToWorkOptions>;
};

const OptionsColumn = ({ options }: OptionsColumnProps) => {
  return (
    <Stack
      spacing="medium"
      direction={{ desktop: 'row', tablet: 'column', mobile: 'column' }}
      width="full"
    >
      {options.map(({ key, label }) => (
        <Row
          width={{
            desktop: '1/2',
            tablet: 'full',
            mobile: 'full'
          }}
        >
          <Checkbox
            key={key}
            label={label}
            testID={`disable-right-to-work-filter-option-${key}`}
            disabled={true}
          />
        </Row>
      ))}
    </Stack>
  );
};
