import { useTranslation } from 'react-i18next';
import { REGEX } from '@common/constants/data';
import { JOB_TITLE_MAX_LENGTH, MAX_LENGTH } from '@common/constants/validation';

type JobTitleErrors = {
  required: string;
  maxLength: string;
  noAngleBracket: string;
};

export const useJobTitleValidation = () => {
  const { t } = useTranslation();

  const errors = t('validations.job.title', {
    returnObjects: true,
    max: JOB_TITLE_MAX_LENGTH
  }) as JobTitleErrors;

  return {
    required: errors.required,
    [MAX_LENGTH]: { value: JOB_TITLE_MAX_LENGTH, message: errors.maxLength },
    pattern: {
      value: REGEX.CONTAINS_ANGLE_BRACKET,
      message: errors.noAngleBracket
    }
  };
};
