import React from 'react';
import { useTranslation } from 'react-i18next';
import { JOB_STATUS } from '@seek/je-shared-data';
import { Badge, BadgeProps } from '@components/Badge';
import { getJobStatusBadgeTone } from './getJobStatusBadgeTone';

export type JobStatusBadgeProps = {
  status: JOB_STATUS;
  testID?: BadgeProps['testID'];
};

export const JobStatusBadge = ({ status, testID }: JobStatusBadgeProps) => {
  const { t } = useTranslation();

  return (
    <Badge testID={testID} tone={getJobStatusBadgeTone(status)}>
      {t(`badge.${status}`)}
    </Badge>
  );
};
