export const LOAD_COUNT = 'jora-employer/entities/applications/LOAD_COUNT';
export const LOAD_COUNT_SUCCESS =
  'jora-employer/entities/applications/LOAD_COUNT_SUCCESS';
export const LOAD_COUNT_FAIL =
  'jora-employer/entities/applications/LOAD_COUNT_FAIL';

export const LOAD_APPLICATIONS =
  'jora-employer/entities/applications/LOAD_APPLICATIONS';
export const LOAD_APPLICATIONS_SUCCESS =
  'jora-employer/entities/applications/LOAD_APPLICATIONS_SUCCESS';
export const LOAD_APPLICATIONS_FAIL =
  'jora-employer/entities/applications/LOAD_APPLICATIONS_FAIL';

export const UPDATE_APPLICANT_STATUS =
  'jora-employer/entities/applications/UPDATE_APPLICANT_STATUS';
export const UPDATE_APPLICANT_STATUS_SUCCESS =
  'jora-employer/entities/applications/UPDATE_APPLICANT_STATUS_SUCCESS';
export const UPDATE_APPLICANT_STATUS_FAIL =
  'jora-employer/entities/applications/UPDATE_APPLICANT_STATUS_FAIL';

export const UPDATE_APPLICANT_NOTES =
  'jora-employer/entities/applications/UPDATE_APPLICANT_NOTES';

export const APPLICANT_STATUS_TRACK =
  'jora-employer/entities/applications/APPLICANT_STATUS_TRACK';

export const LOAD_TEMPORARY_RESUME_LINK =
  'jora-employer/entities/applications/LOAD_TEMPORARY_RESUME_LINK';
export const LOAD_TEMPORARY_RESUME_LINK_SUCCESS =
  'jora-employer/entities/applications/LOAD_TEMPORARY_RESUME_LINK_SUCCESS';
export const LOAD_TEMPORARY_RESUME_LINK_FAIL =
  'jora-employer/entities/applications/LOAD_TEMPORARY_RESUME_LINK_FAIL';

export const EXPANDED_COVER_LETTER_TRACK =
  'jora-employer/entities/applications/EXPANDED_COVER_LETTER_TRACK';

export const INTERACTION_TRACK =
  'jora-employer/entities/applications/INTERACTION_TRACK';
export const INTERACTION_TRACK_SUCCESS =
  'jora-employer/entities/applications/INTERACTION_TRACK_SUCCESS';
export const INTERACTION_TRACK_FAIL =
  'jora-employer/entities/applications/INTERACTION_TRACK_FAIL';

export const LIST_FILTER = 'jora-employer/entities/applications/LIST_FILTER';
export const LIST_FILTER_TRACK =
  'jora-employer/entities/applications/LIST_FILTER_TRACK';
export const CLEAR_FILTER = 'jora-employer/entities/applications/CLEAR_FILTER';

export const LIST_TAB_CLICKED_TRACK =
  'jora-employer/entities/applications/LIST_TAB_CLICKED_TRACK';

export const UPDATE_FIXED_PANEL_HEIGHT =
  'jora-employer/entities/applications/UPDATE_FIXED_PANEL_HEIGHT';

export const INTERACTION_TYPE = {
  CLICKED_PHONE_NUMBER: 'clickedPhoneNumber',
  REVEALED_PHONE_NUMBER: 'revealedPhoneNumber',
  REVEALED_EMAIL: 'revealedEmail',
  CLICKED_EMAIL: 'clickedEmail',
  CLICKED_CALL_ICON: 'clickedCallIcon',
  CLICKED_WHATS_APP_ICON: 'clickedWhatsAppIcon'
};
