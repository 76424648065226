import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';

/**
 * Retrieves the country code from the localisation state.
 * @returns {SupportedCountryCode} The country code.
 */
export function useSiteCountryCode(): SupportedCountryCode {
  const { countryCode } = useAppSelector(getLocalisation);
  return countryCode as SupportedCountryCode;
}
