import React from 'react';
import { useTranslation } from 'react-i18next';
import { Application } from '@common/types/Application';
import { CardAction } from '@components/Card';
import { DownloadIcon } from '@components/Icon';
import { Link } from '@components/Link';
import { useDownloadResumeCardAction } from './useDownloadResumeCardAction';
import { useResumeUrlStatus } from './useResumeUrlStatus';

type DownloadResumeActionProps = {
  application: Application;
  responsive: boolean;
};

export const DownloadResumeCardAction = ({
  application: { id },
  responsive
}: DownloadResumeActionProps) => {
  const { t } = useTranslation();
  const { downloadResume } = useDownloadResumeCardAction(id);
  const { isGettingtResumeUrl } = useResumeUrlStatus(id);

  return (
    <CardAction>
      <Link
        onPress={downloadResume}
        align={{
          mobile: responsive ? 'vertical' : 'horizontal',
          tablet: 'horizontal',
          desktop: 'horizontal'
        }}
        variant="secondary"
        icon={<DownloadIcon variant="outline" />}
        loading={isGettingtResumeUrl}
        testID={`${id}-resume`}
      >
        {t('applications.applicationCard.resumeDownload')}
      </Link>
    </CardAction>
  );
};
