import {
  BUSINESS_REGISTRATION_NUMBER_MAX_LENGTH,
  MAX_LENGTH
} from '@common/constants/validation';
import type { T } from '@src/caja/Form/types';

export const businessRegistrationRules = (
  t: T,
  businessRegistrationNumberFieldName: string
) => {
  return {
    [MAX_LENGTH]: {
      value: BUSINESS_REGISTRATION_NUMBER_MAX_LENGTH,
      message: t('validations.business.businessRegistrationNumber.maxLength', {
        max: BUSINESS_REGISTRATION_NUMBER_MAX_LENGTH,
        businessRegistrationNumberFieldName
      })
    }
  };
};
