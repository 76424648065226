import {
  JOB_DESCRIPTION_MAX_LENGTH,
  JOB_DESCRIPTION_MIN_LENGTH
} from '@common/constants/validation';
import { T } from '@src/caja/Form/types';
import type {
  FormProps,
  IncompleteInfoFormData,
  OnChangeCallback,
  OnInvalidDescriptionCallback,
  OnSubmitCallback
} from '../types';
import { OnCancelCallback } from '../types/onCancelCallback';

export interface HydratedInfoFormProps {
  defaultValues: IncompleteInfoFormData;
  onChange: OnChangeCallback;
  onSubmit: OnSubmitCallback;
  onCancel?: OnCancelCallback;
  onInvalidDescription: OnInvalidDescriptionCallback;
  jobDescriptionLength: { min: number; max: number };
  submitButtonLabel: string;
}

export function hydrateInfoFormProps(
  props: FormProps,
  t: T
): HydratedInfoFormProps {
  return {
    defaultValues: props.defaultValues ?? {},
    onChange: props.onChange ?? (() => {}),
    onSubmit: props.onSubmit ?? (() => {}),
    onCancel: props.onCancel,
    onInvalidDescription: props.onInvalidDescription ?? (() => {}),
    jobDescriptionLength: props.jobDescriptionLength ?? {
      min: JOB_DESCRIPTION_MIN_LENGTH,
      max: JOB_DESCRIPTION_MAX_LENGTH
    },
    submitButtonLabel:
      props.submitButtonLabel ?? t('postJobForm.action.postJob')
  };
}
