import * as deviceInfo from '@common/helpers/deviceInfo';

export function isWebClient() {
  return typeof window !== 'undefined' && window.document;
}

export function isNative() {
  return typeof navigator !== 'undefined' && navigator.product === 'ReactNative';
}

export function isIOS() {
  return deviceInfo.isIOS();
}

export function isServer() {
  return !isWebClient() && !isNative();
}

export function cookiesEnabled() {
  return navigator.cookieEnabled;
}