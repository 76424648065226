import { useEffect } from 'react';
import { useGetCurrencyPricing } from '@common/hooks/payment';
import { useSearchQueryParam } from '@common/hooks/useSearchQueryParam';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { UpgradeableAdType } from '@src/job/adType';
import { PaymentCampaignType } from '@store/payment/constants';
import { useCreatePaymentIntent } from './useCreatePaymentIntent';

type Props = {
  campaign: PaymentCampaignType;
  jobCountryCode: SupportedCountryCode;
  jobId: string;
};

export const useSetupPayment = ({ campaign, jobCountryCode, jobId }: Props) => {
  const { getQueryParam } = useSearchQueryParam();
  const adType = getQueryParam('adType') as UpgradeableAdType;

  const { currencyPrice } = useGetCurrencyPricing(jobCountryCode);
  const pricingText = currencyPrice[adType];

  // Check if the price contains a number instead of a dash
  const isPriceAvailable = /\d/.test(currencyPrice[adType]);

  const setupPayment = useCreatePaymentIntent({
    jobCountryCode,
    jobId,
    campaign
  });

  useEffect(() => {
    if (isPriceAvailable) {
      setupPayment(adType, pricingText);
    }
    // Only run this effect again when the price is available
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPriceAvailable]);
};
