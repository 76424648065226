import React from 'react';
import { ErrorIcon } from '@components/Icon';
import { Text } from '@components/Text';
import { TextWithIcon } from '../TextWithIcon';

export type ErrorMessageProps = {
  children?: string;
};

export const ErrorMessage = ({ children }: ErrorMessageProps) => {
  if (!children) return null;

  return (
    <TextWithIcon icon={<ErrorIcon tone="danger" size="small" />}>
      <Text tone="danger" size="small">
        {children}
      </Text>
    </TextWithIcon>
  );
};
