import { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';

@connect(
  ({ auth, localisation }) => ({
    isAuthenticated: auth.isAuthenticated,
    countryCode: localisation.countryCode
  }), {
    routePush: push
  }
)
export default class ImpersonatorAuthenticate extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    countryCode: PropTypes.string.isRequired,
    routePush: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { isAuthenticated, countryCode, routePush } = this.props;

    if (isAuthenticated) {
      routePush(`/${countryCode}/home`);
      return;
    }

    return routePush(`/${countryCode}/`);
  }

  render() {
    return <Fragment />;
  }
}