import React from 'react';
import { useTranslation } from 'react-i18next';
import { RevealText } from '@common/components/RevealText';
import { TextWithIcon } from '@common/components/TextWithIcon';
import { openExternalURL } from '@common/helpers/externalConnection';
import { formatEmailWithEllipsis } from '@common/helpers/textFormatting';
import { EmailIcon } from '@components/Icon';
import { Link } from '@components/Link';

type RevealEmailAddressProps = {
  emailAddress: string;
  onReveal: () => void;
  onLaunchMailClient: () => void;
};

export const RevealEmailAddress = ({
  emailAddress,
  onReveal,
  onLaunchMailClient
}: RevealEmailAddressProps) => {
  const { t } = useTranslation();

  const launchMailClient = async () => {
    openExternalURL(`mailto:${emailAddress}`);

    await onLaunchMailClient();
  };

  return (
    <TextWithIcon
      icon={
        <EmailIcon
          size="small"
          tone="success"
          variant="outline"
          testID="email-icon"
        />
      }
    >
      <RevealText
        truncatedText={formatEmailWithEllipsis(emailAddress)}
        revealActionText={t('applications.applicationCard.showEmail')}
        onRevealPressed={async () => await onReveal()}
      >
        <Link onPress={launchMailClient}>{emailAddress}</Link>
      </RevealText>
    </TextWithIcon>
  );
};
