import React from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationStatus } from '@common/types';
import { Column } from '@components/Column';
import { Checkbox } from '@components/FormElements';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { TextLink } from '@components/TextLink';
import { useApplicantStatusOptions } from './useApplicantStatusOptions';

type ApplicantStatusFilterProps = {
  value?: ApplicationStatus[];
  onClear: () => void;
  onChange: (selectedValues: ApplicationStatus[]) => void;
};

export const ApplicantStatusFilter = ({
  onChange,
  onClear,
  value = []
}: ApplicantStatusFilterProps) => {
  const { t } = useTranslation();
  const options = useApplicantStatusOptions();

  const firstColumnOptions = options.slice(0, options.length / 2);
  const secondColumnOptions = options.slice(options.length / 2);

  return (
    <Column spacing="large">
      <Row justifyContent="space-between" alignItems="center">
        <Text weight="medium">
          {t('applications.applicationsFilter.applicantStatus.title')}
        </Text>

        <TextLink
          variant={value.length ? 'primary' : 'secondary'}
          onPress={onClear}
          testID="clear-filter-button"
        >
          {t('applications.applicationsFilter.clear')}
        </TextLink>
      </Row>

      <Stack
        spacing="medium"
        direction={{ desktop: 'row', tablet: 'column', mobile: 'column' }}
      >
        <OptionsColumn
          options={firstColumnOptions}
          value={value}
          onChange={onChange}
          onClear={onClear}
        />

        <OptionsColumn
          options={secondColumnOptions}
          value={value}
          onChange={onChange}
          onClear={onClear}
        />
      </Stack>
    </Column>
  );
};

type OptionsColumnProps = ApplicantStatusFilterProps & {
  options: ReturnType<typeof useApplicantStatusOptions>;
};

const OptionsColumn = ({
  options,
  value = [],
  onChange,
  onClear
}: OptionsColumnProps) => {
  const handleCheckbox = (checkboxValue: ApplicationStatus) => {
    const isCurrentlySelected = value.includes(checkboxValue);
    const newValue = isCurrentlySelected
      ? value.filter((item) => item !== checkboxValue)
      : value.concat(checkboxValue);

    if (newValue.length === 0) {
      return onClear();
    }

    return onChange(newValue);
  };

  return (
    <Column
      spacing="medium"
      width={{
        desktop: '1/2',
        tablet: 'full',
        mobile: 'full'
      }}
    >
      {options.map(({ key, label, value: checkboxValue }) => (
        <Checkbox
          key={key}
          value={value.includes(checkboxValue)}
          label={label}
          testID={`status-filter-option-${key}`}
          onChange={() => handleCheckbox(checkboxValue)}
        />
      ))}
    </Column>
  );
};
