import { useEffect } from 'react';
import dayjs from 'dayjs';
import { getUser } from '@store/auth';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { clear, set } from '@store/userData/actions';
import { getNpsData } from '@store/userData/selectors';

export const NPS_INTERACTION_DURATION = 30;

type NpsUserData = {
  [userId: string]: {
    lastInteractionDate: string;
  };
};

const hasRecentInteraction = (lastInteractionDate: string) => {
  if (!lastInteractionDate) {
    return false;
  }

  const daysSinceLastInteraction = dayjs().diff(lastInteractionDate, 'day');

  return daysSinceLastInteraction <= NPS_INTERACTION_DURATION;
};

const filterRecentNpsCookies = (npsUserData: NpsUserData) => {
  return Object.fromEntries(
    Object.entries(npsUserData).filter(([, value]) =>
      hasRecentInteraction(value?.lastInteractionDate)
    )
  );
};

export function useNpsUserData() {
  const dispatch = useAppDispatch();
  const npsUserData = useAppSelector(getNpsData) || {};
  const { currentUser } = useAppSelector(getUser);
  const userId = currentUser.id as string;

  return {
    npsUserData,
    setLastInteractedNpsUserData: () =>
      dispatch(
        set('nps', {
          [userId]: {
            lastInteractionDate: new Date().toISOString()
          }
        })
      ),
    hasRecentlySubmittedNps: () =>
      hasRecentInteraction(npsUserData[userId]?.lastInteractionDate)
  };
}

export function useRefreshRecentNpsUserData() {
  const dispatch = useAppDispatch();
  const { npsUserData } = useNpsUserData();

  useEffect(() => {
    const recentNpsCookies = filterRecentNpsCookies(npsUserData);

    if (
      Object.keys(recentNpsCookies).length !== Object.keys(npsUserData).length
    ) {
      dispatch(clear('nps'));
      dispatch(set('nps', recentNpsCookies));
    }
  }, [dispatch, npsUserData]);
}
