import React, { ReactNode, forwardRef } from 'react';
import { View } from 'react-native';
import { Box, BoxProps } from '@components/Box';
import {
  ErrorIcon,
  InfoIcon,
  LightbulbIcon,
  SuccessIcon
} from '@components/Icon';
import { Text } from '@components/Text';
import { Row } from '../Row';
import { CalloutTextTone, useCalloutTextStyle } from './useCalloutTextStyle';

export type CalloutTextProps = {
  children?: string | ReactNode;
  testID?: BoxProps['testID'];
  tone?: CalloutTextTone;
};

const getCalloutTextIcon = (tone: CalloutTextTone) =>
  ({
    info: <LightbulbIcon tone="info" size="large" variant="outline" />,
    success: <SuccessIcon tone="success" size="medium" variant="outline" />,
    warning: <InfoIcon tone="warning" size="medium" variant="outline" />,
    danger: <ErrorIcon tone="danger" size="medium" variant="outline" />,
    neutral: <LightbulbIcon tone="neutral" size="large" variant="outline" />
  }[tone]);

export const CalloutText = forwardRef<View, CalloutTextProps>((props, ref) => {
  const { children, testID, tone = 'neutral' } = props;
  const resolvedStyles = useCalloutTextStyle({ tone });

  return (
    <View style={resolvedStyles} testID={testID} ref={ref}>
      <Box marginRight="small" marginTop="xxsmall" tone="transparent">
        {getCalloutTextIcon(tone)}
      </Box>
      <Row flexShrink={1} tone="transparent">
        {typeof children === 'string' ? <Text>{children}</Text> : children}
      </Row>
    </View>
  );
});
