import { useTheme } from '@theme/DesignSystemProvider/ThemeContext';
import { Breakpoint } from '@theme/tokens';
import { IllustrationSize } from '@theme/tokens/illustration';
import { useResolveToken } from '@theme/tokens/utils/useResolveToken';

export type IllustrationStyleProps = {
  size?: Record<Breakpoint, IllustrationSize> | IllustrationSize;
};

export function useIllustrationStyle({
  size = 'standard'
}: IllustrationStyleProps) {
  const {
    illustration: { sizes }
  } = useTheme();
  const { resolveToken } = useResolveToken();

  return {
    fill: 'none',
    height: resolveToken(sizes, size),
    width: '100%'
  } as const;
}
