type MatchableApplication = {
  isMatch: boolean;
};

export const useMatchedAndUnmatchedApplications = <
  T extends MatchableApplication
>(
  applications: T[]
) => {
  const matchedApplications = applications.filter(
    (application) => application.isMatch
  );

  const unmatchedApplications = applications.filter(
    (application) => !application.isMatch
  );

  return {
    matchedApplications,
    unmatchedApplications
  };
};
