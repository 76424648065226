import { EventTypes } from 'redux-segment';
import { isIOS } from '@common/helpers/deviceInfo';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { getAdministrativeAreaLevels } from './address';
import * as constants from './constants';
import {
  getLocationDetails,
  getPlacePredictions
} from './googleMapsApi/googleMapsApi';

function trackError({ type, errorType, attemptedAddress }) {
  return {
    type,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'Google Location Auto Suggest Error Displayed',
          properties: {
            errorType,
            attemptedAddress
          }
        }
      }
    }
  };
}

export function fetchSuggestions(searchTerm, isSuburbSearch, countryCode) {
  return {
    types: [
      constants.SUGGESTION_FETCH,
      constants.SUGGESTION_FETCH_SUCCESS,
      constants.SUGGESTION_FETCH_FAIL
    ],
    promise: async (dispatch, getState, fetch) => {
      const state = getState();
      const googleApiKey =
        state.config.googleMaps.apiKey[isIOS() ? 'ios' : 'android'];

      return await new Promise((resolve, reject) => {
        const { ERRORS } = constants;

        getPlacePredictions(
          {
            native: { fetch, googleApiKey },
            args: { searchTerm, countryCode }
          },
          function (results, status) {
            switch (status) {
              case constants.GOOGLE_API_NOT_FOUND: {
                return reject(ERRORS.API_NOT_FOUND);
              }
              case constants.GOOGLE_STATUS_OK: {
                const filteredResults = results.filter((result) => {
                  return constants.ADDRESS_TYPES_AUTO_SUGGEST_ALLOWED.some(
                    (type) => {
                      if (result.types.includes(type)) {
                        if (
                          constants.ADDRESS_TYPES_STREET_NUMBER_REQUIRED.includes(
                            type
                          )
                        ) {
                          const STREET_NUMBER_REGEX = /^\d{1,}/;

                          return STREET_NUMBER_REGEX.test(result.description);
                        }
                        return true;
                      }

                      return false;
                    }
                  );
                });

                if (!filteredResults.length) {
                  dispatch(
                    trackError({
                      type: constants.LOCATION_DATA_VALIDATION_ERROR_TRACK,
                      errorType: 'NOT_FOUND_DUE_TO_PLACE_TYPE_RESTRICTIONS',
                      attemptedAddress: searchTerm
                    })
                  );

                  return reject(ERRORS.RESULT_NOT_FOUND);
                }

                return resolve(filteredResults);
              }
              case constants.GOOGLE_ZERO_RESULTS:
                dispatch(
                  trackError({
                    type: constants.LOCATION_DATA_VALIDATION_ERROR_TRACK,
                    errorType: 'RESULT_NOT_FOUND',
                    attemptedAddress: searchTerm
                  })
                );
                return reject(ERRORS.RESULT_NOT_FOUND);
              default:
                dispatch(
                  trackError({
                    type: constants.LOCATION_DATA_VALIDATION_ERROR_TRACK,
                    errorType: 'REQUEST_FAILED',
                    attemptedAddress: searchTerm
                  })
                );
                return reject(ERRORS.REQUEST_FAILED);
            }
          }
        );
      });
    }
  };
}

function getAddressPart(addressParts, partWanted) {
  for (let i = 0; i < addressParts.length; i++) {
    if (addressParts[i].types.includes(partWanted)) {
      return addressParts[i].short_name;
    }
  }

  return null;
}

export function getSuburbAndState({ addressParts, countryCode }) {
  const addressComponentPriorites = [
    'neighborhood',
    'postal_town',
    'sublocality_level_2',
    'sublocality_level_1',
    'locality',
    'administrative_area_level_4',
    'administrative_area_level_3',
    'administrative_area_level_2',
    'administrative_area_level_1'
  ];

  const granularAddresses = addressComponentPriorites.reduce(
    (accumulator, addressComponent) => {
      const term = getAddressPart(addressParts, addressComponent);

      if (term && !accumulator.includes(term)) {
        return [...accumulator, term];
      }

      return accumulator;
    },
    []
  );

  const suburb =
    constants.COUNTRIES_USE_LESS_GRANULAR_ADDRESS_AS_SUBURB.includes(
      countryCode
    )
      ? granularAddresses[granularAddresses.length - 2] || granularAddresses[0]
      : granularAddresses[0];

  const state = granularAddresses[granularAddresses.length - 1];

  return {
    suburb,
    state
  };
}

export function fetchLocationData(suggestion, isSuburbSearch) {
  return {
    types: [
      constants.LOCATION_DATA_FETCH,
      constants.LOCATION_DATA_FETCH_SUCCESS,
      constants.LOCATION_DATA_FETCH_FAIL
    ],
    promise: async (dispatch, getState, fetch) => {
      const { ERRORS } = constants;
      const placeId = suggestion.place_id;
      const state = getState();
      const googleApiKey =
        state.config.googleMaps.apiKey[isIOS() ? 'ios' : 'android'];

      return await new Promise((resolve, reject) => {
        getLocationDetails(
          { native: { fetch, googleApiKey }, args: { placeId } },
          function (results, status) {
            if (status === constants.GOOGLE_API_NOT_FOUND) {
              return reject(ERRORS.API_NOT_FOUND);
            }
            if (status === constants.GOOGLE_ZERO_RESULTS) {
              return reject(ERRORS.RESULT_NOT_FOUND);
            }
            if (status !== constants.GOOGLE_STATUS_OK) {
              return reject(ERRORS.REQUEST_FAILED);
            }

            const addressParts = results[0].address_components;
            const coordinates = results[0].geometry.location;
            const formattedAddress = results[0].formatted_address;
            const locality = getAddressPart(addressParts, 'locality');
            const countryCode =
              getAddressPart(addressParts, 'country') ||
              SupportedCountryCode[locality];
            const postcode = getAddressPart(addressParts, 'postal_code');
            const { suburb, state } = getSuburbAndState({
              addressParts,
              countryCode
            });

            if (!suburb) {
              dispatch(
                trackError({
                  type: constants.LOCATION_DATA_VALIDATION_ERROR_TRACK,
                  errorType: 'SUBURB_NOT_FOUND',
                  attemptedAddress: suggestion.description
                })
              );

              return reject(ERRORS.SUBURB_NOT_FOUND);
            }

            if (isSuburbSearch) {
              const locationData = {
                lat:
                  typeof coordinates.lat === 'function'
                    ? coordinates.lat()
                    : coordinates.lat,
                lon:
                  typeof coordinates.lng === 'function'
                    ? coordinates.lng()
                    : coordinates.lng,
                suburb
              };

              if (postcode) {
                locationData.postcode = postcode;
              }

              return resolve(locationData);
            }

            const streetNumber = getAddressPart(addressParts, 'street_number');
            const subPremise = getAddressPart(addressParts, 'subpremise');
            const streetName = getAddressPart(addressParts, 'route');
            const administrativeAreaLevels =
              getAdministrativeAreaLevels(addressParts);
            const addressType = results[0].types;
            const isRequiredStreetName =
              constants.ADDRESS_TYPES_STREET_NAME_REQUIRED.some((type) =>
                addressType.includes(type)
              );

            if (isRequiredStreetName && !streetName) {
              dispatch(
                trackError({
                  type: constants.LOCATION_DATA_VALIDATION_ERROR_TRACK,
                  errorType: 'STREET_NAME_NOT_FOUND',
                  attemptedAddress: suggestion.description
                })
              );

              return reject(ERRORS.STREET_NAME_NOT_FOUND);
            }

            return resolve({
              countryCode,
              lat:
                typeof coordinates.lat === 'function'
                  ? coordinates.lat()
                  : coordinates.lat,
              lon:
                typeof coordinates.lng === 'function'
                  ? coordinates.lng()
                  : coordinates.lng,
              street:
                `${subPremise ? subPremise + '/' : ''}${
                  streetNumber ? `${streetNumber} ` : ''
                }${streetName || ''}` || null,
              suburb,
              state,
              postcode,
              formattedAddress,
              administrativeAreaLevels
            });
          }
        );
      });
    }
  };
}

export function clearLocationData() {
  return {
    type: constants.LOCATION_DATA_CLEAR
  };
}
