import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextLink } from '@components/TextLink';

type UpgradeAccountLinkProps = {
  toggleModalState: () => void;
};
export const UpgradeAccountLink = ({
  toggleModalState
}: UpgradeAccountLinkProps) => {
  const { t } = useTranslation();

  return (
    <TextLink variant="primary" onPress={toggleModalState}>
      {t('applications.applicationsFilter.upgradeAccountForPremiumFilters')}
    </TextLink>
  );
};
