import React, { useEffect } from 'react';
import { usePaymentContext } from '@common/components/FormElements/PaymentProvider/PaymentContext';
import { PaymentProviderProps } from '@common/components/FormElements/PaymentProvider/PaymentProvider';
import { ElementStyleProvider } from '@common/components/FormElements/PaymentProvider/components/ElementStyleProvider';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { loadStripeInstance } from '@store/payment/actions';
import { getPaymentClientId, getStripe } from '@store/payment/selectors';
import { Elements, ElementsConsumer, useStripe } from '@stripe/react-stripe-js';
import { ProvidedStyle } from '@components/FormElements/StyleProvider';

export type { StripeElements } from '@stripe/stripe-js';
export type WebStripe = ReturnType<typeof useStripe>;

export const StripePaymentProvider = ({ children }: PaymentProviderProps) => {
  const clientId = useAppSelector(getPaymentClientId);
  const stripe = useAppSelector(getStripe);
  const dispatch = useAppDispatch();
  const { setClientId, setElements, setStripe } = usePaymentContext();

  useEffect(() => {
    if (!stripe) {
      dispatch(loadStripeInstance());
    }
  }, [dispatch, stripe]);

  useEffect(() => {
    setClientId(clientId);
    setStripe(stripe);
  }, [clientId, setClientId, stripe, setStripe]);

  return clientId && stripe ? (
    <ElementStyleProvider>
      {({ defaultStyle, errorStyle, focusStyle }) => (
        <Elements
          options={getOptions(defaultStyle, errorStyle, focusStyle, clientId)}
          stripe={stripe}
        >
          <ElementsConsumer>
            {({ elements }) => {
              setElements(elements);
              return children;
            }}
          </ElementsConsumer>
        </Elements>
      )}
    </ElementStyleProvider>
  ) : null;
};

function getOptions(
  defaultStyle: ProvidedStyle,
  errorStyle: ProvidedStyle,
  focusStyle: ProvidedStyle,
  clientId?: string
) {
  return {
    clientSecret: clientId,
    appearance: getAppearance(defaultStyle, errorStyle, focusStyle),
    fonts: [{ cssSrc: defaultStyle.fontCssSource }]
  };
}

function getAppearance(
  defaultStyle: ProvidedStyle,
  errorStyle: ProvidedStyle,
  focusStyle: ProvidedStyle
) {
  const {
    borderColor,
    fontFamily,
    color: colorText,
    fontSize,
    fontWeight
  } = defaultStyle;
  const { color: colorDanger, fontSize: errorFontSize } = errorStyle;
  const { borderColor: focusBorderColor } = focusStyle;

  if (
    !borderColor ||
    !focusBorderColor ||
    !colorText ||
    !fontSize ||
    !fontWeight
  ) {
    return;
  }

  return {
    theme: 'stripe',
    variables: {
      fontFamily,
      colorText,
      colorDanger,
      fontSizeSm: `${fontSize}px`,
      fontWeightNormal: fontWeight
    },
    rules: {
      '.Label': {
        letterSpacing: '-0.04em', //todo: use letterSpacing from design system once CheckoutForm is migrated
        marginTop: '4px'
      },
      '.Input': {
        fontWeight,
        letterSpacing: '-0.04em', //todo: use letterSpacing from design system once CheckoutForm is migrated
        borderColor,
        paddingLeft: '16px', //todo: adjust once CheckoutForm is migrated
        paddingTop: '14px', //todo: adjust once CheckoutForm is migrated
        paddingBottom: '13px', //todo: adjust once CheckoutForm is migrated
        boxShadow: 'none' //todo: add to useStyleProviderStyle once CheckoutForm is migrated
      },
      '.Input:focus': {
        borderColor: focusBorderColor,
        boxShadow: 'none'
      },
      '.Input--invalid': {
        color: 'var(--colorText)',
        boxShadow: 'none'
      },
      '.Error': {
        fontSize: `${errorFontSize}px`,
        letterSpacing: '-0.02em' //todo: use letterSpacing from design system once CheckoutForm is migrated
      }
    }
  } as const;
}
