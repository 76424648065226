import { CompleteData } from '@src/caja';
import { createBusiness as createBusinessAction } from '@store/entities/businesses/actions';
import { useAppDispatch } from '@store/hooks';
import { CreateBusinessRequest } from './types';

type CreateBusinessProps = {
  business: CompleteData['business'];
  sourceParams: Record<string, string>;
};

const toBusinessRequestData = (
  business: CompleteData['business']
): CreateBusinessRequest => {
  return {
    businessSize: business.businessSize,
    industryId: business.industryId,
    industryOtherDescription: business.industryOtherDescription,
    name: business.name,
    role: business.role,
    recruitmentCompanyName: business.recruitmentCompanyName,
    website: business.website,
    businessAddress: business.businessAddress,
    businessRegistrationNumber: business.businessRegistrationNumber
  };
};

export const useCreateBusiness = () => {
  const dispatch = useAppDispatch();

  return async ({ business, sourceParams }: CreateBusinessProps) => {
    const response = await dispatch(
      createBusinessAction(toBusinessRequestData(business), sourceParams)
    );

    return {
      data: response?.result,
      isSuccessful: !response?.error
    };
  };
};
