import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <>
    <Path
      d="M12 12.5a4.5 4.5 0 100-9 4.5 4.5 0 000 9zM4.5 20.5c0-4.417 3.36-8 7.5-8 4.14 0 7.5 3.583 7.5 8"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const UserIcon = createIcon({ viewBox: '3 3 18 18', vectorComponent });
