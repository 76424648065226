import React from 'react';
import { Path } from 'svgs';
import { VectorComponentProps, createIcon } from '../createIcon';

const vectorComponent = ({
  foregroundColor,
  backgroundColor
}: VectorComponentProps) => (
  <>
    <Path fill={backgroundColor} d="M0 0h21v20H0z" />
    <Path fill="#fff" d="M-36-59h750v261H-36z" />
    <Path
      fill={foregroundColor}
      stroke={foregroundColor}
      strokeMiterlimit={10}
      d="m14.293 15.076-2.988-4.514a.88.88 0 0 0-1.468 0L6.85 15.076c-.07.11.007.257.135.257h7.168a.167.167 0 0 0 .141-.257Z"
    />
    <Path
      stroke={foregroundColor}
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M15.156 2.917H5.989v1.666h9.167V2.917ZM15.156 15.417H5.989v1.666h9.167v-1.666Z"
    />
    <Path
      stroke={foregroundColor}
      strokeMiterlimit={10}
      d="m6.85 4.924 2.994 4.519a.873.873 0 0 0 1.455 0l2.994-4.52c.071-.108-.006-.256-.135-.256H6.984c-.128 0-.206.148-.135.257Z"
    />
  </>
);

export const ExpiredIcon = createIcon({
  viewBox: '0 0 21 20',
  vectorComponent
});
