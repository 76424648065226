import { NativeSystem } from '../NativeSystem';
import { sendToNative } from '../sendToNative';

interface PingResponse {
  success: boolean;
  data: { message: 'pong' };
  system: NativeSystem;
}

export async function ping(): Promise<PingResponse> {
  return (await sendToNative('ping', {})) as PingResponse;
}
