import React from 'react';
import {
  Recaptcha as InnerField,
  RecaptchaHandle
} from '@common/components/Recaptcha';
import { FieldWrapper } from '../FieldWrapper';

type RecaptchaProps = {
  onVerify: (recaptchaResponse: string) => void;
  recaptchaRef: React.RefObject<RecaptchaHandle>;
};

export const Recaptcha = ({ onVerify, recaptchaRef }: RecaptchaProps) => (
  <FieldWrapper<string>
    name="recaptchaResponse"
    render={(props) => {
      return (
        <InnerField
          {...props}
          recaptchaRef={recaptchaRef}
          onVerify={onVerify}
        />
      );
    }}
  />
);
