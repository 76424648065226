import { useTranslation } from 'react-i18next';
import {
  SalaryWithOptionalAmount,
  isExactSalaryInput,
  isRangeSalaryInput
} from './useExpectedSalary';

export const useExpectedSalaryValidation = () => {
  const { t } = useTranslation();

  return {
    validate: (salary: SalaryWithOptionalAmount) => {
      if (isRangeSalaryInput(salary)) {
        const { amount } = salary;

        if (amount && amount.from > amount.to) {
          return t(
            'postJobForm.expectedSalary.validationText.maxAmountGreaterThanMinAmount'
          );
        }

        if (amount && (amount.from === 0 || amount.to === 0)) {
          return t('postJobForm.expectedSalary.validationText.noAmount');
        }

        if (
          amount &&
          (amount.from || amount.to) &&
          !(amount.from && amount.to)
        ) {
          return t(
            'postJobForm.expectedSalary.validationText.maxAmountAndMinAmountFilled'
          );
        }
      }

      if (isExactSalaryInput(salary) && salary.amount === 0) {
        return t('postJobForm.expectedSalary.validationText.noAmount');
      }

      return true;
    }
  };
};
