import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateInteraction } from '@common/hooks/application';
import { UpdateInteractionParams } from '@common/hooks/application/interaction';

type Params = {
  applicationId: string;
  onSuccess: () => void;
};

export const useViewTextScreeningAnswer = ({
  applicationId,
  onSuccess
}: Params) => {
  const { t } = useTranslation();
  const { updateApplicantInteraction, errorMessage, isSuccessful } =
    useUpdateInteraction();
  const [isAnswerViewed, setAnswerViewed] = useState(false);

  const errorText = errorMessage?.message
    ? t(errorMessage.message, errorMessage.properties)
    : '';

  useEffect(() => {
    if (isSuccessful) {
      setAnswerViewed(true);
      onSuccess();
    }
  }, [isSuccessful, onSuccess, isAnswerViewed]);

  const onViewTextScreeningAnswerPress = (
    interaction: UpdateInteractionParams['interaction']
  ) => {
    updateApplicantInteraction({
      applicationId,
      interaction
    });
  };

  return {
    onViewTextScreeningAnswerPress,
    errorMessage: errorText,
    isAnswerViewed
  };
};
