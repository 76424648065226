import { openExternalURL } from '@common/helpers/externalConnection';
import { LINK, useCandidateLink } from '@common/hooks/useCandidateLink';
import { trackTipsClicked } from '@store/entities/jobs/actions';
import { useAppDispatch } from '@store/hooks';

export function useJobDescriptionCalloutDetails() {
  const dispatch = useAppDispatch();
  const { getCandidateLink } = useCandidateLink();
  const candidateLink = getCandidateLink(LINK.POST_JOB_TIPS);

  return {
    shouldRenderFindOutMoreLink: !!candidateLink,
    onPressFindOutMore: () => {
      dispatch(trackTipsClicked());
      openExternalURL(candidateLink);
    }
  };
}
