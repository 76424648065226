import React from 'react';
import { Box } from '@components/Box';
import { Card, CardContent } from '@components/Card';
import { Heading } from '@components/Heading';
import { Text } from '@components/Text';

type InformationCardProps = {
  heading: string;
  text: string;
};

export const InformationCard = ({ heading, text }: InformationCardProps) => (
  <Card>
    <CardContent>
      <Box marginTop="medium">
        <Heading level="h2">{heading}</Heading>
      </Box>
      <Box marginTop="xsmall">
        <Text>{text}</Text>
      </Box>
    </CardContent>
  </Card>
);
