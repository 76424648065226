import React, { forwardRef, useEffect, useState } from 'react';
import { View } from 'react-native';
import { SearchIcon } from '@components/Icon';
import { Input } from '../Input';
import { NoSuggestionsList, SuggestionList } from './SuggestionList';
import { AutoSuggestTone, useAutoSuggest, useAutoSuggestStyle } from './hooks';

export type Suggestion = {
  key?: string;
  displayText: string;
  data: unknown;
};

export type AutoSuggestProps = {
  value: Suggestion | undefined;
  placeholder?: string;
  onChange: (suggestion: Suggestion | undefined) => void;
  onBlur: () => void;
  disabled?: boolean;
  onFetchSuggestions: (value: string) => void;
  suggestions: Suggestion[];
  tone?: AutoSuggestTone;
  noResultsText: string;
  testID?: string;
};

const getTextInputTone = ({
  defaultTone,
  disabled
}: {
  defaultTone: AutoSuggestTone;
  disabled?: boolean;
}) => (disabled ? 'successMuted' : defaultTone);

export const AutoSuggest = forwardRef<View, AutoSuggestProps>((props, ref) => {
  const {
    value,
    onChange,
    onBlur,
    disabled,
    noResultsText,
    onFetchSuggestions,
    suggestions,
    tone = 'neutral',
    placeholder,
    testID
  } = props;

  const hasSuggestions = Boolean(suggestions.length);

  const defaultTone = getTextInputTone({
    defaultTone: tone || 'neutral',
    disabled: disabled
  });

  const [textAreaTone, setTone] = useState<AutoSuggestTone>(defaultTone);
  useEffect(() => setTone(tone), [tone]);

  const resolvedStyles = useAutoSuggestStyle({ tone: textAreaTone });
  const {
    searchTermUpdated,
    searchTerm,
    onSearchTermChange,
    isOpen,
    onSuggestionSelected
  } = useAutoSuggest({ onChange, onBlur, onFetchSuggestions });

  return (
    <View ref={ref} style={resolvedStyles.container}>
      <Input
        prefixIcon={
          <SearchIcon variant="outline" tone="neutral" size="xlarge" />
        }
        onBlur={onBlur}
        expanded={isOpen}
        id="autoSuggest"
        onChange={onSearchTermChange}
        value={searchTermUpdated ? searchTerm : value?.displayText}
        placeholder={placeholder}
        testID={`${testID}-search`}
      />
      {hasSuggestions && isOpen && (
        <SuggestionList
          suggestions={suggestions}
          onSuggestionSelected={onSuggestionSelected}
          searchTerm={searchTerm}
          tone={textAreaTone}
          testID={`${testID}-options`}
        />
      )}

      {!hasSuggestions && isOpen && (
        <NoSuggestionsList
          testID={`${testID}-noSuggestions`}
          noResultsText={noResultsText}
        />
      )}
    </View>
  );
});
