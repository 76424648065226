import { BUSINESS_REGISTRATION_NUMBER_FIELD_NAME } from '@common/constants/data';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';

export const useBusinessRegistrationFieldName = () => {
  const { countryCode } = useAppSelector(getLocalisation);
  const businessRegistrationFieldName =
    BUSINESS_REGISTRATION_NUMBER_FIELD_NAME[
      countryCode as SupportedCountryCode
    ];

  return { businessRegistrationFieldName };
};
