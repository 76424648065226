import React from 'react';
import { Box } from '@components/Box';
import { Button } from '@components/Button';
import { Card, CardContent } from '@components/Card';
import { Heading } from '@components/Heading';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { useScoreCard } from './useScoreCard';

type ScoreCardProps = {
  handleScoreClick: (value: string) => void;
};

export const ScoreCard = ({ handleScoreClick }: ScoreCardProps) => {
  const { heading, minTextTip, maxTextTip } = useScoreCard();

  return (
    <Card testID="nps-score-component">
      <CardContent>
        <Stack spacing="small">
          <Heading align="center" level="h2">
            {heading}
          </Heading>
          <Row
            flex={1}
            justifyContent="center"
            alignItems="center"
            spacing="xsmall"
            flexWrap="wrap"
          >
            {['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'].map(
              (n) => (
                <Box marginTop="xsmall" key={`${n}-box`}>
                  <Button
                    testID={`score-button-${n}`}
                    variant="secondary"
                    key={n}
                    onPress={() => handleScoreClick(n)}
                  >
                    {n}
                  </Button>
                </Box>
              )
            )}
          </Row>
          <Row justifyContent="space-between">
            <Text>{minTextTip}</Text>
            <Text>{maxTextTip}</Text>
          </Row>
        </Stack>
      </CardContent>
    </Card>
  );
};
