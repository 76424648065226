import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => {
  return (
    <>
      <Path
        d="M20.478 9.08H3.5L5.621 3.5H18.35l2.129 5.58zM20.478 9.767V20.5H3.5V9.767"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M7.743 9.2v.98c0 .733-.385 1.32-.85 1.32H4.35c-.473 0-.85-.594-.85-1.32V9.2M11.993 9.2v.98c0 .733-.385 1.32-.85 1.32H8.6c-.473 0-.85-.594-.85-1.32V9.2M16.25 9.2v.98c0 .733-.384 1.32-.85 1.32h-2.543c-.473 0-.85-.594-.85-1.32V9.2M20.5 9.2v.98c0 .733-.384 1.32-.85 1.32h-2.543c-.473 0-.85-.594-.85-1.32V9.2"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M9.864 20.5v-6.7h4.242v6.7M12.251 17.664h1.796"
        strokeWidth={0.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
};

export const ShopIcon = createIcon({
  viewBox: '3 3 18 18',
  vectorComponent
});
