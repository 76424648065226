import { StepMap } from '@src/caja/steps/types';
import { useNextStep } from './useNextStep';
import { useRedirectOnStepChange } from './useRedirectOnStepChange';

export default function useFlowNavigation(
  stepMap: StepMap,
  currentPath: string
) {
  const nextStep = useNextStep(currentPath, stepMap);
  const redirectOnStepChange = useRedirectOnStepChange(currentPath, stepMap);

  return { nextStep, redirectOnStepChange };
}
