import React, { forwardRef } from 'react';
import {
  Pressable,
  Modal as RNModal,
  ModalProps as RNModalProps,
  View
} from 'react-native';
import { Box } from '@components/Box';
import { Column } from '@components/Column';
import { CrossIcon } from '@components/Icon';
import { IconButton } from '@components/IconButton';
import { Row } from '@components/Row';
import { Text } from '@components/Text';
import { composeTestIDs } from '../utils';
import { ScrollableModalContent } from './ScrollableModalContent';
import { ModalStyleProps, useModalStyle } from './useModalStyle';

export type ModalProps = ModalStyleProps & {
  children?: React.ReactNode;
  testID?: RNModalProps['testID'];
  title: string | React.ReactNode;
  visible: boolean;
  onRequestClose: () => void;
  hideCloseButton?: boolean;
  footerChildren?: string | React.ReactNode;
};

type ModalContentProps = Pick<
  ModalProps,
  | 'title'
  | 'children'
  | 'height'
  | 'testID'
  | 'onRequestClose'
  | 'hideCloseButton'
> & {
  hasFooter?: boolean;
};

const ModalContent = ({
  title,
  children,
  onRequestClose,
  height,
  testID,
  hideCloseButton = false,
  hasFooter
}: ModalContentProps) => {
  const { headerStyle } = useModalStyle({ height, hasFooter });

  return (
    <div className="md:mt-[124px] lg:mt-[124px]">
      <Column testID={testID} tone="transparent">
        <View style={headerStyle}>
          <Row
            spacing="small"
            paddingHorizontal={{
              mobile: 'large',
              tablet: 'xlarge',
              desktop: 'xlarge'
            }}
            paddingVertical="medium"
            alignItems="center"
            justifyContent="space-between"
            tone="transparent"
          >
            <Box flexShrink={1} tone="transparent">
              {typeof title === 'string' ? (
                <Text weight="regular">{title}</Text>
              ) : (
                title
              )}
            </Box>
            {!hideCloseButton && (
              <IconButton
                testID={composeTestIDs(testID, 'close-button')}
                icon={
                  <CrossIcon size="small" variant="outline" tone="neutral" />
                }
                onPress={onRequestClose}
              />
            )}
          </Row>
        </View>

        <ScrollableModalContent height={height} hasFooter={hasFooter}>
          <Column
            tone="neutral"
            paddingTop="large"
            paddingBottom="xlarge"
            paddingLeft={{
              mobile: 'large',
              tablet: 'xlarge',
              desktop: 'xlarge'
            }}
            paddingRight={{
              mobile: 'large',
              tablet: 'xlarge',
              desktop: 'xlarge'
            }}
          >
            {children}
          </Column>
        </ScrollableModalContent>
      </Column>
    </div>
  );
};

const ModalContainer = forwardRef<RNModal, Omit<ModalProps, 'title'>>(
  (props, ref) => {
    const {
      children,
      footerChildren,
      testID,
      visible,
      onRequestClose,
      ...restProps
    } = props;
    const { overlayStyle, contentStyle } = useModalStyle(restProps);

    return (
      <RNModal
        onRequestClose={onRequestClose}
        visible={visible}
        transparent
        testID={testID}
        animationType="fade"
        ref={ref}
      >
        <Pressable style={overlayStyle} onPress={onRequestClose}>
          <Column
            flex={1}
            justifyContent="center"
            alignItems="center"
            marginHorizontal="xlarge"
            paddingHorizontal="xlarge"
            tone="transparent"
          >
            <Pressable style={contentStyle}>{visible && children}</Pressable>
            <Pressable style={contentStyle}>
              {visible && footerChildren}
            </Pressable>
          </Column>
        </Pressable>
      </RNModal>
    );
  }
);

export const Modal = forwardRef<RNModal, ModalProps>((props, ref) => {
  const {
    children,
    height,
    testID,
    title,
    visible,
    onRequestClose,
    hideCloseButton,
    footerChildren
  } = props;

  const hasFooter = Boolean(footerChildren);

  return (
    <ModalContainer
      ref={ref}
      onRequestClose={onRequestClose}
      visible={visible}
      testID={testID}
      footerChildren={footerChildren}
      hasFooter={hasFooter}
    >
      <ModalContent
        title={title}
        height={height}
        testID={composeTestIDs(testID, 'content')}
        onRequestClose={onRequestClose}
        hideCloseButton={hideCloseButton}
        hasFooter={hasFooter}
      >
        {children}
      </ModalContent>
    </ModalContainer>
  );
});
