import { LOGOUT_SUCCESS } from '../../../joraEmployer/store/auth/constants';

// On the web, Raygun is a global variable and is setup in src/joraEmployer/render.js
const isDevBuildEnvironment = (process.env.NODE_ENV || 'localdev') !== 'production';

const options = {
  debugMode: isDevBuildEnvironment,
  disableErrorTracking: isDevBuildEnvironment,
  ignore3rdPartyErrors: true,
  disablePulse: true
};

export function sendError(raygunError) {
  if (Raygun) {
    if (raygunError.properties) {
      Raygun.withCustomData(raygunError.properties);
    }

    if (raygunError instanceof TypeError) {
      Raygun.groupingKey((payload) => {
        try {
          return payload.Details.Error.Message;
        } catch (e) {
          return null;
        }
      });
    }

    Raygun.send(raygunError);
  }
}

function resetAnonymousUser() {
  const rg4js = require('raygun4js'); //cannot import at top of file, because will break SSR

  rg4js('getRaygunInstance').resetAnonymousUser();
  rg4js('options', { ...options, disableAnonymousUserTracking: false });
}

export function errorTrackingMiddleware() {
  return next => action => {
    try {
      switch (action.type) {
        case LOGOUT_SUCCESS:
          resetAnonymousUser();
          break;
      }
    } catch (e) {
      console.error(`Error: errorTrackingMiddleware ${e}`); //eslint-disable-line no-console
    }
    return next(action);
  };
}
