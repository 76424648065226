import { useTheme } from '@theme/DesignSystemProvider/ThemeContext';
import { useResolveToken } from '@theme/tokens/utils/useResolveToken';
import { Tone } from '@theme/types';

export type ProgressBarStyleProps = {
  width: string;
  tone?: Tone;
};

export function useProgressBarStyle({ width, tone }: ProgressBarStyleProps) {
  const { spacing, progressBar, border } = useTheme();
  const { resolveToken } = useResolveToken();

  const bar = {
    height: 'xsmall',
    radius: 'small'
  } as const;

  return {
    contentStyle: {
      width: resolveToken(null, width),
      height: resolveToken(spacing, bar.height),
      borderRadius: resolveToken(border.radius, bar.radius),
      backgroundColor: resolveToken(progressBar.color, tone)
    },
    containerStyle: {
      width: '100%',
      borderRadius: resolveToken(border.radius, bar.radius),
      alignItems: 'flex-start',
      justifyContent: 'center',
      height: resolveToken(spacing, bar.height),
      backgroundColor: resolveToken(progressBar.color, 'neutral')
    }
  } as const;
}
