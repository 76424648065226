import React, { useContext } from 'react';
import { CajaContext } from '@src/caja/context';
import { Button } from '@components/Button';

interface SubmitProps {
  children: string;
  testID?: string;
  onPress: () => void;
}
export default function Submit({ children, testID, onPress }: SubmitProps) {
  const {
    loading: [isLoading]
  } = useContext(CajaContext);

  return (
    <Button onPress={onPress} disabled={isLoading} testID={testID}>
      {children}
    </Button>
  );
}
