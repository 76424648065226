import { parsePhoneNumber } from '@lib/mobileNumberValidation';
import { REGEX } from '../constants/data';

export function detectedPhoneNumber(inputText = '') {
  const trimmedText = inputText && inputText.trim();
  const reformattedText = trimmedText.replace(
    REGEX.NON_ALPHANUMERIC_REMOVAL_FORMAT,
    ''
  );

  return REGEX.MOBILE_FORMAT.test(reformattedText);
}

export function detectedEmail(inputText = '') {
  const trimmedText = inputText && inputText.trim();

  return REGEX.CONTAINS_EMAIL.test(trimmedText);
}

export function isValidEmailFormat(email) {
  return REGEX.EMAIL_FORMAT.test(email.trim().toLowerCase());
}

export function isValidMobileNumber(diallingCode, mobileNumber) {
  const parsedMobileNumber = parsePhoneNumber(`${diallingCode}${mobileNumber}`);

  if (!parsedMobileNumber) return false;

  return parsedMobileNumber.isValid();
}

export function isValidPasswordFormat(password) {
  return REGEX.PASSWORD.test(password);
}

export function isValidJobId(jobId) {
  return REGEX.JOB_ID.test(jobId);
}

export function isValidWebsiteUrl(website) {
  return REGEX.WEBSITE_FORMAT.test(website);
}

export function hasAngleBracket(text) {
  return REGEX.ANGLE_BRACKET.test(text);
}

// This date validation assumes the date string format is always dd/MM/yyyy
export function isValidDate(date: string) {
  const [day, month, year] = date.split('/');
  const formattedDate = new Date(`${year}-${month}-${day} 00:00:00`);

  if (date.length < 10) {
    return false;
  }

  // Taking advantage of the JS Date quirk with months and rejecting if month index is the same as the month number given
  // see: https://fjolt.com/article/javascript-date-is-weird#incorrect-dates-skip-to-next-month
  return (
    formattedDate instanceof Date &&
    formattedDate.getMonth() !== parseInt(month, 10)
  );
}

// This date validation assumes the date string format is always dd/MM/yyyy
export function isFutureDate(date: string) {
  const currentDate = new Date();
  const [day, month, year] = date.split('/');
  const formattedDate = new Date(`${year}-${month}-${day} 00:00:00`);

  return formattedDate.getTime() > currentDate.getTime();
}
