import React from 'react';
import { useTranslation } from 'react-i18next';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { Box } from '@components/Box';
import { Card, CardContent } from '@components/Card';
import { Heading } from '@components/Heading';
import { DeleteSuccessIllustration } from '@components/Illustration';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';

export const DeleteAccountSuccess = () => {
  const { t } = useTranslation();

  return (
    <Stack tone="transparent">
      <JoraEmployerHelmet title={t('deleteAccountSuccessPage.title')} />

      <Box
        paddingTop="medium"
        paddingBottom="small"
        paddingHorizontal="medium"
        tone="transparent"
      >
        <Heading level="h1" weight="regular">
          {t('deleteAccountSuccessPage.title')}
        </Heading>
      </Box>

      <Stack spacing="medium" tone="transparent">
        <Card>
          <CardContent>
            <Stack alignItems="center" spacing="medium">
              <DeleteSuccessIllustration
                size="standard"
                testID="delete-success-illustration"
              />

              <Stack alignItems="center" spacing="xxsmall">
                <Text>
                  {t(
                    'deleteAccountSuccessPage.description.accountIsBeingDeleted'
                  )}
                </Text>

                <Text>
                  {t('deleteAccountSuccessPage.description.weAreSorry')}
                </Text>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </Stack>
  );
};
