import { getLocalisedBrandDetails } from '@seek/je-shared-data';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';

export const LINK = {
  CONTACT_PAGE: 'contact',
  ABOUT_PAGE: 'about',
  ADVERTISING_TERMS_OF_USE_PAGE: 'advertisingTermsOfUse',
  TERMS_AND_CONDITION_PAGE: 'termsAndCondition',
  PRIVACY_PAGE: 'privacy',
  FAQ_EMPLOYER_PAGE: 'faqs.employers',
  FAQ_WHAT_ARE_COINS: 'faqs.whatAreCoins',
  FAQ_WHY_ISNT_MY_JOB_AD_LIVE_PAGE: 'faqs.whyIsntMyJobAdLive',
  POST_JOB_TIPS: 'postJobTips',
  LOGIN: 'login',
  REGISTRATION: 'registration'
} as const;

type LinkPage = (typeof LINK)[keyof typeof LINK];

function buildCandidateLink({
  brandName,
  countryCode,
  candidateDomain,
  page,
  queryString = ''
}) {
  const getPageLink = (obj, path) =>
    path.split('.').reduce((acc, part) => acc && acc[part], obj);

  const pagePath = getPageLink(
    getLocalisedBrandDetails(brandName, countryCode).links,
    page
  );

  if (!pagePath) {
    return null;
  }

  const link = `https://${candidateDomain}${pagePath}${queryString}`;

  if (page === LINK.CONTACT_PAGE) {
    return link.includes('?')
      ? `${link}&who_are_you=employer`
      : `${link}?who_are_you=employer`;
  }

  return link;
}

export function useCandidateLink() {
  const { countryCode, brandConfig, candidateDomain } =
    useAppSelector(getLocalisation);
  const brandName = brandConfig?.name;

  const getCandidateLink = (page: LinkPage) => {
    const candidateLink = buildCandidateLink({
      brandName,
      countryCode,
      candidateDomain,
      page
    });

    return candidateLink;
  };

  return { getCandidateLink };
}
