import { BRAND_CONFIG } from '@seek/je-shared-data';
import { BrandName } from '@seek/je-shared-data/lib/types/brand';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';

type AppStoreLink = {
  appStoreLinks: {
    android?: string;
    ios?: string;
  };
};

export function useAppStoreLink(): AppStoreLink {
  const { brandConfig } = useAppSelector(getLocalisation);

  const storeLinksConfig = {
    [BrandName.JORA]: {
      android: BRAND_CONFIG.jora.defaultSettings.androidStoreLink,
      ios: BRAND_CONFIG.jora.defaultSettings.iosStoreLink
    },
    [BrandName.JOBSTREETEXPRESS]: {
      android: BRAND_CONFIG.jobstreetExpress.defaultSettings.androidStoreLink,
      ios: BRAND_CONFIG.jobstreetExpress.defaultSettings.iosStoreLink
    }
  };

  return {
    appStoreLinks: brandConfig?.name
      ? { ...storeLinksConfig[brandConfig.name] }
      : {}
  };
}
