import { useTheme } from '@theme/DesignSystemProvider/ThemeContext';
import { BaseTheme } from '@theme/DesignSystemProvider/ThemeContext/makeBaseTheme';
import { useResolveToken } from '@theme/tokens/utils/useResolveToken';

export type CheckboxState = keyof BaseTheme['checkbox']['color'];
export type CheckboxStyleProps = {
  checkboxState: CheckboxState;
  value?: boolean;
};

export function useCheckboxStyle({ checkboxState, value }: CheckboxStyleProps) {
  const {
    border,
    checkbox,
    icon,
    typography: { fontWeights, fontFamily }
  } = useTheme();
  const { resolveToken } = useResolveToken();
  const { backgroundColor, borderColor, fontColor } =
    resolveToken(checkbox.color, checkboxState) || {};

  const size = resolveToken(icon.sizes, 'large');

  return {
    containerStyle: {
      backgroundColor,
      borderColor,
      borderRadius: resolveToken(border.radius, 'xsmall'),
      borderWidth: resolveToken(border.width, value ? 'none' : 'xsmall'),
      width: size,
      height: size,
      justifyContent: 'center',
      alignItems: 'center'
    },
    pressableStyle: {
      borderWidth: 0,
      outlineWidth: 0
    },
    textStyle: {
      color: fontColor,
      fontWeight: resolveToken(fontWeights, 'light'),
      fontFamily
    }
  } as const;
}
