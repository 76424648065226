import { REGEX } from '@common/constants/data';
import {
  MAX_LENGTH,
  WEBSITE_URL_MAX_LENGTH
} from '@common/constants/validation';
import { T } from '../../FormContext';

type BusinessWebsiteErrors = {
  required: string;
  maxLength: string;
  format: string;
  noAngleBracket: string;
};

export const useBusinessWebsiteRules = (t: T) => {
  const errors = t('validations.business.website', {
    returnObjects: true,
    max: WEBSITE_URL_MAX_LENGTH
  }) as BusinessWebsiteErrors;

  return {
    required: errors.required,
    [MAX_LENGTH]: {
      value: WEBSITE_URL_MAX_LENGTH,
      message: errors.maxLength
    },
    validate: (value) => {
      if (!REGEX.WEBSITE_FORMAT.test(value)) {
        return errors.format;
      }
      if (REGEX.ANGLE_BRACKET.test(value)) {
        return errors.noAngleBracket;
      }

      return true;
    }
  };
};
