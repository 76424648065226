export const REQUIRED_VALIDATION_ERROR = 'required';
export const FORMAT_VALIDATION_ERROR = 'format';
export const MISMATCH_VALIDATION_ERROR = 'mismatch';

export const SELECT_SUGGESTION = 'selectSuggestion';
export const MOBILE_DETECTED = 'mobileDetected';
export const EMAIL_DETECTED = 'emailDetected';
export const MAX_LENGTH = 'maxLength';
export const MIN_LENGTH = 'minLength';
export const NO_ANGLE_BRACKET = 'noAngleBracket';
export const VALID_DATE = 'valid';
export const FUTURE_DATE = 'future';

export const JOB_TITLE_MAX_LENGTH = 255;
export const JOB_DESCRIPTION_MIN_LENGTH = 200;
export const JOB_DESCRIPTION_MIN_LENGTH_FOR_EXPRESS_VERIFICATION = 30;
export const JOB_DESCRIPTION_MAX_LENGTH = 50000;
export const NPS_REASON_MAX_LENGTH = 1000;
export const CLOSE_JOB_REASON_MAX_LENGTH = 200;
export const COMPANY_NAME_MAX_LENGTH = 255;
export const BUSINESS_NAME_MAX_LENGTH = 100;
export const BUSINESS_NAME_MIN_LENGTH = 2;
export const APPLICATION_NOTES_MAX_LENGTH = 300;
export const RECRUITMENT_COMPANY_NAME_MAX_LENGTH = 100;
export const RECRUITMENT_COMPANY_NAME_MIN_LENGTH = 2;
export const INDUSTRY_OTHER_DESCRIPTION_MIN_LENGTH = 2;
export const WEBSITE_URL_MAX_LENGTH = 150;
export const BUSINESS_REGISTRATION_NUMBER_MAX_LENGTH = 100;
export const ADDITIONAL_APPEAL_INFORMATION_MAX_LENGTH = 1500;
export const EMAIL_VERIFICATION_CODE_LENGTH = 6;

export const LENGTH_BEFORE_ELLIPSIS = 65;

export const GENERAL_VALIDATION_MESSAGES = {
  SUMMARY:
    'There are errors on this page. Please correct the highlighted fields.',
  EMAIL: {
    required: 'Please enter an email address.',
    format: 'Please enter a valid email address.'
  },
  PIN: {
    required: 'Please enter 4 numbers.',
    format: 'Please enter 4 numbers.',
    mismatch: 'New PINs do not match.'
  },
  FILE: {
    required: 'Please upload a file.',
    format: 'Please upload .pdf, .doc, .docx file only.'
  }
};
