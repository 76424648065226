import React, { useContext } from 'react';
import { ShiftAvailabilityField as InnerField } from '@common/components/PostJobForm/components/ShiftAvailability/ShiftAvailability';
import type { ShiftAvailability as ShiftType } from '@seek/je-shared-data/lib/types/jobs';
import { FieldWrapper } from '../FieldWrapper';
import { FormContext } from '../FormContext';
import { shiftAvailabilityRules } from '../rules';

export default function RightToWork() {
  const { t } = useContext(FormContext);
  return (
    <FieldWrapper<ShiftType>
      rules={shiftAvailabilityRules(t)}
      name="shiftAvailability"
      render={(props) => <InnerField {...props} />}
    />
  );
}
