import { Tokens } from '../../tokens';

export function makeBaseTheme(tokens: Tokens) {
  const decoratedTokens = decorateTokens(tokens);

  return {
    shadow: decoratedTokens.shadow,
    sizes: decoratedTokens.sizes,
    spacing: decoratedTokens.spacing,
    badge: getBadgeTokens(decoratedTokens),
    avatar: getAvatarTokens(decoratedTokens),
    button: getButtonTokens(decoratedTokens),
    border: getBorderTokens(decoratedTokens),
    checkbox: getCheckboxTokens(decoratedTokens),
    radioButton: getRadioButtonTokens(decoratedTokens),
    calloutText: getCalloutTextTokens(decoratedTokens),
    colors: getColorsTokens(decoratedTokens),
    banner: getBannerTokens(decoratedTokens),
    divider: getDividerTokens(decoratedTokens),
    icon: getIconTokens(decoratedTokens),
    illustration: getIllustrationTokens(decoratedTokens),
    link: getLinkTokens(decoratedTokens),
    logo: getLogoTokens(decoratedTokens),
    modal: getModalTokens(decoratedTokens),
    pill: getPillTokens(decoratedTokens),
    progressBar: getProgressBarTokens(tokens),
    formElement: getFormElementTokens(decoratedTokens),
    richTextInput: getRichTextInputTokens(decoratedTokens),
    typography: getTypographyTokens(decoratedTokens),
    tab: getTabTokens(decoratedTokens)
  } as const;
}

export type BaseTheme = ReturnType<typeof makeBaseTheme>;
type DecoratedToken = ReturnType<typeof decorateTokens>;

function decorateTokens(tokens: Tokens) {
  return {
    ...tokens,
    colors: {
      ...tokens.colors,
      brand: tokens.colors.green700,
      unused: '' // unused is a placeholder to indicate that the semantic colour for a particular case is not currently being used. In the future when it's required, we can define a proper colour for it without worrying about its impact on the app.
    }
  };
}

function getAvatarTokens(tokens: DecoratedToken) {
  const { avatarSizes } = tokens;

  return {
    sizes: avatarSizes
  };
}

function getBadgeTokens(tokens: DecoratedToken) {
  const { colors } = tokens;

  return {
    variant: {
      primary: {
        neutral: {
          backgroundColor: colors.grey600,
          fontColor: colors.white
        },
        info: {
          backgroundColor: colors.blue600,
          fontColor: colors.white
        },
        promo: {
          backgroundColor: colors.purple500,
          fontColor: colors.white
        },
        success: {
          backgroundColor: colors.brand,
          fontColor: colors.white
        },
        warning: {
          backgroundColor: colors.yellow500,
          fontColor: colors.grey900
        },
        danger: {
          backgroundColor: colors.red600,
          fontColor: colors.white
        }
      },
      secondary: {
        neutral: {
          backgroundColor: colors.grey200,
          fontColor: colors.grey900
        },
        info: {
          backgroundColor: colors.blue50,
          fontColor: colors.grey900
        },
        promo: {
          backgroundColor: colors.purple200,
          fontColor: colors.grey900
        },
        success: {
          backgroundColor: colors.green100,
          fontColor: colors.grey900
        },
        warning: {
          backgroundColor: colors.yellow200,
          fontColor: colors.grey900
        },
        danger: {
          backgroundColor: colors.red200,
          fontColor: colors.grey900
        }
      }
    }
  } as const;
}

function getButtonTokens(tokens: DecoratedToken) {
  const { colors } = tokens;

  return {
    variant: {
      primary: {
        default: {
          backgroundColor: colors.brand,
          borderColor: colors.brand,
          fontColor: colors.white
        },
        active: {
          backgroundColor: colors.green800,
          borderColor: colors.green800,
          fontColor: colors.white
        },
        hover: {
          backgroundColor: colors.green600,
          borderColor: colors.green600,
          fontColor: colors.white
        },
        disabled: {
          backgroundColor: colors.grey200,
          borderColor: colors.grey200,
          fontColor: colors.grey400
        }
      },
      secondary: {
        default: {
          backgroundColor: colors.white,
          borderColor: colors.brand,
          fontColor: colors.brand
        },
        active: {
          backgroundColor: colors.green50,
          borderColor: colors.green800,
          fontColor: colors.green800
        },
        hover: {
          backgroundColor: colors.white,
          borderColor: colors.green600,
          fontColor: colors.green600
        },
        disabled: {
          backgroundColor: colors.green200,
          borderColor: colors.green200,
          fontColor: colors.green600
        }
      }
    }
  } as const;
}

function getBorderTokens(tokens: DecoratedToken) {
  const { colors, border } = tokens;

  return {
    ...border,
    color: {
      transparent: colors.transparent,
      brand: colors.grey200,
      danger: colors.grey200,
      success: colors.grey200,
      info: colors.grey200,
      warning: colors.grey200,
      promo: colors.grey200,
      neutral: colors.grey200,
      neutralDark: colors.grey200,
      dark: colors.grey200,
      black: colors.grey200
    }
  } as const;
}

function getDividerTokens(tokens: DecoratedToken) {
  const { colors, border } = tokens;

  return {
    ...border,
    color: {
      transparent: colors.transparent,
      brand: colors.brand,
      danger: colors.red600,
      success: colors.brand,
      info: colors.blue100,
      warning: colors.yellow500,
      promo: colors.purple600,
      neutral: colors.grey200,
      neutralDark: colors.grey600
    }
  } as const;
}

function getCalloutTextTokens(tokens: DecoratedToken) {
  const { colors } = tokens;

  return {
    color: {
      danger: colors.red100,
      success: colors.green100,
      info: colors.blue100,
      warning: colors.yellow100,
      neutral: colors.grey100
    }
  };
}

function getCheckboxTokens(tokens: DecoratedToken) {
  const { colors } = tokens;

  return {
    color: {
      default: {
        backgroundColor: 'transparent',
        borderColor: colors.grey500,
        fontColor: colors.black
      },
      active: {
        backgroundColor: colors.green50,
        borderColor: colors.brand,
        fontColor: colors.black
      },
      disabled: {
        backgroundColor: colors.grey50,
        borderColor: colors.grey400,
        fontColor: colors.grey400
      }
    }
  } as const;
}

function getRadioButtonTokens(tokens: DecoratedToken) {
  const { colors } = tokens;

  return {
    color: {
      default: colors.grey500,
      checked: colors.brand,
      focused: colors.brand,
      disabled: colors.grey400
    },
    state: {
      default: {
        backgroundColor: colors.white,
        borderColor: colors.grey500,
        fontColor: colors.black
      }
    }
  } as const;
}

function getColorsTokens(tokens: DecoratedToken) {
  const { colors } = tokens;

  return {
    background: {
      transparent: colors.transparent,
      brand: colors.brand,
      danger: colors.red100,
      success: colors.green100,
      info: colors.blue100,
      warning: colors.yellow100,
      promo: colors.purple100,
      neutral: colors.white,
      neutralDark: colors.grey100,
      dark: colors.grey700,
      black: colors.grey900
    },
    foreground: {
      neutral: colors.black,
      neutralLight: colors.grey600,
      brand: colors.brand,
      danger: colors.red600,
      success: colors.brand,
      info: colors.blue600,
      warning: colors.yellow600,
      promo: colors.purple600,
      white: colors.white
    }
  } as const;
}

function getBannerTokens(tokens: DecoratedToken) {
  const { colors } = tokens;

  return {
    backgroundColor: {
      danger: colors.red100,
      success: colors.green100,
      info: colors.blue100,
      warning: colors.yellow100
    }
  } as const;
}

function getLogoTokens(tokens: DecoratedToken) {
  const {
    colors,
    logo: { logoSizes: sizes }
  } = tokens;

  return {
    color: {
      dark: {
        background: colors.white
      },
      light: {
        background: colors.grey900
      }
    },
    sizes
  } as const;
}

function getIconTokens(tokens: DecoratedToken) {
  const {
    colors,
    icon: { iconSizes: sizes }
  } = tokens;

  return {
    color: {
      transparent: colors.transparent,
      brand: colors.brand,
      danger: colors.red600,
      success: colors.brand,
      successMuted: colors.grey400,
      info: colors.blue600,
      warning: colors.yellow600,
      promo: colors.purple600,
      neutral: colors.grey900,
      neutralLight: colors.grey500,
      white: colors.white
    },
    variant: {
      circle: {
        containerBackgroundColor: colors.grey100
      },
      success: {
        containerBackgroundColor: colors.green100
      },
      danger: {
        containerBackgroundColor: colors.red100
      }
    },
    sizes
  } as const;
}

function getIllustrationTokens(tokens: DecoratedToken) {
  const {
    illustration: { illustrationSizes: sizes }
  } = tokens;

  return {
    sizes
  } as const;
}

function getLinkTokens(tokens: DecoratedToken) {
  const { colors } = tokens;

  return {
    variant: {
      primary: {
        default: {
          fontColor: colors.blue600,
          activityIndicatorColor: colors.blue500
        },
        hover: {
          fontColor: colors.blue600,
          activityIndicatorColor: colors.blue500
        }
      },
      secondary: {
        default: {
          fontColor: colors.grey900,
          activityIndicatorColor: colors.grey500
        },
        hover: {
          fontColor: colors.grey900,
          activityIndicatorColor: colors.grey500
        }
      },
      neutral: {
        default: {
          fontColor: colors.white,
          activityIndicatorColor: colors.white
        },
        hover: {
          fontColor: colors.white,
          activityIndicatorColor: colors.white
        }
      },
      success: {
        default: {
          fontColor: colors.green700,
          activityIndicatorColor: colors.green700
        },
        hover: {
          fontColor: colors.green700,
          activityIndicatorColor: colors.green700
        }
      }
    }
  } as const;
}

function getModalTokens(tokens: DecoratedToken) {
  const { colors } = tokens;

  return {
    overlay: {
      color: 'rgba(23,26,23,.5)'
    },
    header: {
      color: colors.grey100
    }
  };
}

function getPillTokens(tokens: DecoratedToken) {
  const { colors } = tokens;

  return {
    color: {
      backgroundColor: colors.green50,
      borderColor: colors.brand
    }
  } as const;
}

function getTypographyTokens(tokens: DecoratedToken) {
  const { typography } = tokens;

  return {
    ...typography,
    heading: {
      h1: {
        letterSpacing: 'large',
        size: 'xlarge',
        lineHeight: 'medium'
      },
      h2: {
        letterSpacing: 'medium',
        size: 'medium',
        lineHeight: 'medium'
      }
    },
    text: {
      xxsmall: {
        letterSpacing: 'xxsmall',
        size: 'xxsmall',
        lineHeight: 'xxsmall'
      },
      xsmall: {
        letterSpacing: 'xsmall',
        size: 'xsmall',
        lineHeight: 'xsmall'
      },
      small: {
        letterSpacing: 'small',
        size: 'small',
        lineHeight: 'small'
      },
      standard: {
        letterSpacing: 'medium',
        size: 'medium',
        lineHeight: 'medium'
      },
      large: {
        letterSpacing: 'large',
        size: 'large',
        lineHeight: 'large'
      },
      xlarge: {
        letterSpacing: 'xlarge',
        size: 'xlarge',
        lineHeight: 'xlarge'
      },
      xxlarge: {
        letterSpacing: 'xxlarge',
        size: 'xxlarge',
        lineHeight: 'xxlarge'
      }
    }
  } as const;
}

function getFormElementTokens(tokens: DecoratedToken) {
  const { colors } = tokens;

  return {
    container: {
      maxWidth: 430,
      paddingTop: 'small',
      paddingBottom: 'small',
      paddingHorizontal: 'medium',
      borderWidth: 'xsmall',
      borderStyle: 'solid',
      borderRadius: 'xsmall',
      cursor: {
        successMuted: 'default'
      }
    },
    color: {
      neutral: {
        borderColor: colors.grey500,
        focusedBorderColor: colors.green700,
        fontColor: colors.black
      },
      neutralMuted: {
        borderColor: colors.grey400,
        focusedBorderColor: colors.unused,
        fontColor: colors.grey400
      },
      danger: {
        borderColor: colors.red600,
        focusedBorderColor: colors.green700,
        fontColor: colors.red600
      },
      success: {
        borderColor: colors.brand,
        focusedBorderColor: colors.unused,
        fontColor: colors.black
      },
      successMuted: {
        borderColor: colors.green200,
        focusedBorderColor: colors.unused,
        fontColor: colors.grey400
      }
    }
  } as const;
}

function getProgressBarTokens(tokens: Tokens) {
  const { colors } = tokens;

  return {
    color: {
      brand: colors.green700,
      danger: colors.red600,
      success: colors.green700,
      info: colors.blue600,
      warning: colors.yellow600,
      promo: colors.purple600,
      neutral: colors.grey100
    }
  };
}

function getRichTextInputTokens(tokens: DecoratedToken) {
  const {
    border: { radius, width: borderWidth },
    colors,
    sizes,
    spacing
  } = tokens;

  return {
    backgroundColor: colors.white,
    border: {
      width: borderWidth.xsmall,
      color: {
        focused: colors.green700
      }
    },
    container: {
      borderWidth: borderWidth.xsmall,
      borderRadius: radius.small
    },
    innerContainer: {
      paddingBottom: spacing.xsmall
    },
    editor: {
      backgroundColor: colors.white,
      color: colors.black,
      height: sizes.small
    },
    toolbar: {
      alignItems: 'flex-start',
      backgroundColor: colors.white,
      borderBottomWidth: borderWidth.xsmall,
      borderBottomColor: colors.grey500,
      borderTopLeftRadius: radius.medium,
      borderTopRightRadius: radius.medium,
      paddingLeft: spacing.xsmall
    },
    toolbarIcon: {
      iconTint: colors.black,
      disabledIconTint: colors.grey500,
      selectedIconTint: colors.green500
    }
  };
}

function getTabTokens(tokens: DecoratedToken) {
  const { colors } = tokens;

  return {
    default: {
      backgroundColor: colors.white,
      borderColor: colors.grey200,
      fontColor: colors.black
    },
    active: {
      backgroundColor: colors.green100,
      borderColor: colors.green700,
      fontColor: colors.green700
    }
  } as const;
}
