import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@components/Box';
import { Button } from '@components/Button';
import { Column } from '@components/Column';
import { UpgradeAccountIllustration } from '@components/Illustration';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { ContactUs } from '../ContactUs';
import { PhoneAndEmail } from '../PhoneAndEmail';
import { Benefits } from './components';

type RequestUpgradeProps = {
  mobile?: string;
  email?: string;
  onSubmit: () => void;
};

export const RequestUpgrade = ({
  mobile,
  email,
  onSubmit
}: RequestUpgradeProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Stack
        direction={{ desktop: 'row', tablet: 'row', mobile: 'column-reverse' }}
      >
        {/* LHS */}
        <Column spacing="xsmall" flex={1}>
          <Text weight="regular">
            {t('account.upgradeAccountModal.requestUpgrade.heading1')}
          </Text>

          <Benefits />

          <Box marginTop="xsmall">
            <Text weight="regular">
              {t('account.upgradeAccountModal.requestUpgrade.heading2')}
            </Text>
          </Box>

          <Text>
            {t('account.upgradeAccountModal.requestUpgrade.description2')}
          </Text>

          <Box marginTop="xsmall">
            <PhoneAndEmail mobile={mobile} email={email} />
          </Box>
        </Column>

        {/* RHS */}
        <Box
          visible={{ desktop: true, tablet: true, mobile: false }}
          marginTop="xlarge"
        >
          <UpgradeAccountIllustration size="standard" />
        </Box>
      </Stack>

      <Box marginVertical="large">
        <Button onPress={onSubmit}>
          {t('account.upgradeAccountModal.requestUpgrade.cta')}
        </Button>
      </Box>

      <Text size="small">
        <ContactUs />
      </Text>
    </>
  );
};
