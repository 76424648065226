import { useState } from 'react';
import { Suggestion } from '../AutoSuggest';

type useAutoSuggestProps = {
  onChange: (value: Suggestion | undefined) => void;
  onBlur: () => void;
  onFetchSuggestions: (value: string) => void;
};

export default function useAutoSuggest(props: useAutoSuggestProps) {
  const [searchTerm, setSearchTerm] = useState<string>();
  const [searchTermUpdated, setSearchTermUpdated] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  function onSearchTermChange(searchBy: string) {
    setSearchTerm(searchBy);
    setIsOpen(false);
    setSearchTermUpdated(true);
    props.onChange(undefined);

    if (searchBy.length > 1) {
      setIsOpen(true);
      return props.onFetchSuggestions(searchBy);
    }
  }

  function onSuggestionSelected(selectedSuggestion: Suggestion) {
    setIsOpen(false);
    setSearchTerm(undefined);
    setSearchTermUpdated(false);
    props.onChange(selectedSuggestion);
    props.onBlur();
  }

  return {
    searchTerm,
    searchTermUpdated,
    isOpen,
    setIsOpen,
    onSearchTermChange,
    onSuggestionSelected
  };
}
