import React, { Fragment, ReactElement, ReactNode } from 'react';

export type IfProps = {
  condition: boolean;
  children: ReactNode;
};

export const If = ({ condition, children }: IfProps) => {
  const conditionalChildren =
    children &&
    React.Children.map(children, (child) =>
      React.cloneElement(child as ReactElement, {
        condition
      })
    );

  return <Fragment>{conditionalChildren}</Fragment>;
};
