import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <Path
    d="M3.5 14.5l4.474 5L20.5 5.5"
    strokeMiterlimit={10}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
);

export const RightTickIcon = createIcon({
  viewBox: '3 3 18 18',
  vectorComponent
});
