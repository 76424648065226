import _omit from 'lodash.omit';
import { get as getData, save as saveData } from '@common/helpers/storage';
import {
  USER_DATA_KEY,
  USER_DATA_LOAD_SUCCESS,
  USER_DATA_UPDATE_SUCCESS
} from './constants';

export type UserDataAction = {
  type: string;
  result: Record<string, any>;
};

function serialize(userdata) {
  return JSON.stringify(userdata);
}

function deserialize(userdata) {
  return JSON.parse(userdata);
}

function save(data, secure) {
  saveData({
    key: USER_DATA_KEY,
    serializedData: serialize(data),
    allowSecureCookies: secure
  });
}

export function set(key, value) {
  return (dispatch, getState) => {
    const currentEntity = getState().userData || {};
    const currentEntityValue = currentEntity[key] || {};

    currentEntity[key] = { ...currentEntityValue, ...value };

    save(currentEntity, getState().config.allowSecureCookies);

    dispatch({
      type: USER_DATA_UPDATE_SUCCESS,
      result: currentEntity
    });
  };
}

export function clear(key) {
  return (dispatch, getState) => {
    const currentEntity = getState().userData || {};
    const newEntity = _omit(currentEntity, key);

    save(newEntity, getState().config.allowSecureCookies);

    dispatch({
      type: USER_DATA_UPDATE_SUCCESS,
      result: newEntity
    });
  };
}

export function loadFromCookie(store, cookies) {
  try {
    const userData = getData({ cookies, key: USER_DATA_KEY });
    let deserializedData = null;

    if (userData) {
      deserializedData = deserialize(userData);
    }

    store.dispatch({
      type: USER_DATA_LOAD_SUCCESS,
      result: deserializedData || {}
    });
  } catch (err) {
    /* nevermind */
  }
}

export async function loadFromNativeStorage(store) {
  try {
    const userData = await getData({ key: USER_DATA_KEY });
    let deserializedData = null;

    if (userData) {
      deserializedData = deserialize(userData);
    }

    store.dispatch({
      type: USER_DATA_LOAD_SUCCESS,
      result: deserializedData || {}
    });
  } catch (err) {
    /* nevermind */
  }
}
