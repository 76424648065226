import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <>
    <Path
      d="M12 20.5a8.5 8.5 0 100-17 8.5 8.5 0 000 17z"
      strokeMiterlimit={10}
    />
    <Path
      d="M7.5 13.5l3 2 6-7"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const SuccessIcon = createIcon({
  viewBox: '3 3 18 18',
  vectorComponent
});
