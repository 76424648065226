import { Dimensions, Platform } from 'react-native';
import { requiresSafeAreaInset } from '@common/helpers/deviceInfo';
import * as colours from './palette/colours';
export * from './palette/colours';

//***Padding
export const smallDevicePadding = 20;
export const mediumDevicePadding = 40;
export const defaultHorizontalPadding = 16;

//***Grid
export const gridRowHeight = 8;

//***BreakPoints
export const layoutGrowMaxWidth = 750;
export const formGrowMaxWidth = 430;
export const smallScreenMinWidth = 320;
export const mediumScreenMinWidth = 601;

//***Type
export const fontFamily = 'Noto Sans';

// Type styles
export const heading1 = 24;
export const heading2 = 20;
export const body1 = 16;
export const body2 = 14;
export const paragraph = 10;

// Font size
export const fontTiny = 10;
export const fontSmall = 14;
export const fontStandard = 16;
export const fontLarge = 21;

// Letter spacing (unit: pt)
export const letterSpacingTiny = -0.15;
export const letterSpacingSmall = -0.42;
export const letterSpacingStandard = -0.48;
export const letterSpacingLarge = -0.94;
export const letterSpacingHeading1 = -1.44;
export const letterSpacingHeading2 = -0.9;
export const letterSpacingBody1 = -0.48;
export const letterSpacingBody2 = -0.42;
export const letterSpacingParagraph = -0.15;

// Font weight
export const fontWeightLight = '300';
export const fontWeightRegular = Platform.OS === 'ios' ? '500' : 'bold';

// Global styles
export const defaultTypography = {
  fontWeight: fontWeightLight,
  fontSize: fontStandard,
  letterSpacing: letterSpacingStandard,
  fontFamily: fontFamily,
  color: colours.grey900
};

export const defaultLink = {
  fontWeight: fontWeightLight,
  fontSize: fontStandard,
  letterSpacing: letterSpacingStandard,
  fontFamily: fontFamily,
  color: colours.blue600
};

export const globalHeaderHeight = Platform.OS === 'ios' ? 74 : 54;
export const safeAreaInset = requiresSafeAreaInset() ? 15 : 0;

export function getDevicePadding() {
  const { width } = Dimensions.get('window');

  return width > formGrowMaxWidth ? mediumDevicePadding : smallDevicePadding;
}

export function getDeviceHeight() {
  const { height } = Dimensions.get('window');

  return height;
}

export function getDeviceWidth() {
  const { width } = Dimensions.get('window');

  return width;
}

// formElements
export const formElementBorder = {
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: colours.grey500,
  borderRadius: 6
};

export const formElementInvalidState = {
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: colours.red600
};

export const formElementFocusState = {
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: colours.green700
};