import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { SingleSelect } from '@common/components/FormElements';
import { WorkExperience as WorkExperienceEnum } from '@seek/je-shared-data/lib/types/jobs/jobs';
import { Box } from '@components/Box';
import { CalloutText } from '@components/CalloutText';
import { Field } from '@components/FormElements';
import { Stack } from '@components/Stack';
import { useWorkExperience } from './hooks/useWorkExperience';

type WorkExperienceProps = {
  value: WorkExperienceEnum | undefined;
  onChange: (key: string) => void;
  error?: string;
};

export const WorkExperience = ({
  value,
  onChange,
  error
}: WorkExperienceProps) => {
  const { t } = useTranslation();
  const { options, calloutText } = useWorkExperience();

  return (
    <Stack spacing="small">
      <Field
        label={t('postJobForm.fieldLabel.workExperience')}
        message={
          error && (
            <Box marginTop="xsmall">
              <ErrorMessage>{error}</ErrorMessage>
            </Box>
          )
        }
      >
        <Box marginTop="xsmall">
          <SingleSelect
            testID="job-form-workExperience"
            onChange={onChange}
            options={options}
            value={value}
          />
        </Box>
      </Field>

      {value ? (
        <CalloutText tone="info">{t(calloutText(value))}</CalloutText>
      ) : null}
    </Stack>
  );
};
