import { BUSINESS_REGISTRATION_NUMBER_FIELD_NAME } from '@common/constants/data';
import { useGetBusinessListData } from '@common/hooks/business';
import { useSiteCountryCode } from '@common/hooks/localisation';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { extractBusinessCountryCode } from '@src/business';
import { BusinessWithUsers } from '@store/entities/businesses/types';

export const useGetBusinessRegistrationFieldLabel = () => {
  const countryCode = useSiteCountryCode();
  const { businesses } = useGetBusinessListData();

  return {
    label: getRegistrationFieldName(businesses[0], countryCode)
  };
};

const getRegistrationFieldName = (
  business: BusinessWithUsers,
  countryCode: SupportedCountryCode = SupportedCountryCode.Australia
) => {
  let businessRegistrationFieldLabel =
    BUSINESS_REGISTRATION_NUMBER_FIELD_NAME[countryCode];

  if (business) {
    const businessCountryCode = extractBusinessCountryCode(business);

    if (businessCountryCode) {
      businessRegistrationFieldLabel =
        BUSINESS_REGISTRATION_NUMBER_FIELD_NAME[businessCountryCode];
    }
  }

  return businessRegistrationFieldLabel;
};
