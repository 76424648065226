import { useBrandName } from '@common/hooks/localisation';
import { AD_TYPE } from '@seek/je-shared-data';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { useQueryAdDuration } from '../useQueryAdDuration';

/**
 * Custom hook to get ad duration based on the job country code.
 * @param countryCode - The country code of the job.
 * @returns An object containing ad duration and error message.
 */
export const useGetAdDuration = (countryCode: SupportedCountryCode) => {
  const brandName = useBrandName();

  const { data, error } = useQueryAdDuration(countryCode, brandName, {
    enabled: true
  });

  return {
    adDuration: {
      [AD_TYPE.BASIC]: data?.basic,
      [AD_TYPE.PLUS]: data?.plus,
      [AD_TYPE.MAX]: data?.max
    },
    errorMessage: { message: error?.message, properties: error?.properties }
  };
};
