import React, { forwardRef } from 'react';
import { View, ViewProps } from 'react-native';
import { BoxProps } from '@components/Box';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { BannerStyleProps, useBannerStyle } from './useBannerStyle';

export type BannerProps = {
  /**
   * The image that will be displayed in the banner
   */
  image: React.ReactNode;
  /**
   * The alignment for the image in the banner
   */
  alignImage?: BoxProps['alignItems'];
  /**
   * The content that will be displayed alongside the image in the banner
   */
  children: React.ReactNode;
  /**
   * The unique identifier to added the banner, which can be used to locate it during automated testing
   */
  testID?: ViewProps['testID'];
} & BannerStyleProps;

const TriangleCorner = ({ tone }) => {
  const { gradientStyle } = useBannerStyle({ tone });

  return <View style={{ ...gradientStyle }} />;
};

export const Banner = forwardRef<View, BannerProps>((props, ref) => {
  const {
    image,
    alignImage = 'center',
    children,
    testID,
    tone = 'success'
  } = props;

  return (
    <Stack
      ref={ref}
      testID={testID}
      direction={{
        mobile: 'column-reverse',
        tablet: 'row',
        desktop: 'row'
      }}
      borderWidth="xsmall"
    >
      <Row
        paddingLeft="xlarge"
        paddingVertical="large"
        paddingRight={{
          mobile: 'xlarge',
          desktop: 'xxsmall',
          tablet: 'xxsmall'
        }}
        width={{
          mobile: 'full',
          tablet: '1/2',
          desktop: '1/2'
        }}
      >
        {children}
      </Row>

      <Row
        width={{
          mobile: 'full',
          tablet: '1/2',
          desktop: '1/2'
        }}
        justifyContent="flex-end"
      >
        <Row
          width="1/3"
          justifyContent="flex-end"
          visible={{
            desktop: true,
            tablet: true,
            mobile: false
          }}
        >
          <TriangleCorner tone={tone} />
        </Row>

        <Row
          tone={tone}
          alignItems={alignImage}
          justifyContent="center"
          width={{
            desktop: '2/3',
            tablet: '2/3',
            mobile: 'full'
          }}
        >
          {image}
        </Row>
      </Row>
    </Stack>
  );
});
