import React from 'react';
import { useTranslation } from 'react-i18next';
import { RevealText } from '@common/components/RevealText';
import { truncatePhraseAndAppendEllipsis } from '@common/helpers/textFormatting';
import { Row } from '@components/Row';
import { Text } from '@components/Text';

type Props = {
  currentPosition?: string | null;
  currentPositionDuration?: string;
  testID?: string;
};

export const CurrentPositionInfo = (props: Props) => {
  const { currentPosition, currentPositionDuration, testID } = props;
  const { t } = useTranslation();

  if (!currentPosition) {
    return null;
  }

  const currentPositionDurationComponent = currentPositionDuration && (
    <Text tone="neutralLight">{` (${currentPositionDuration})`}</Text>
  );

  const truncatedText = truncatePhraseAndAppendEllipsis(currentPosition, 2);
  const truncatedTextComponent = (
    <Text>
      {truncatedText}
      {currentPositionDurationComponent}
    </Text>
  );

  const fullTextComponent = (
    <Text>
      {currentPosition}
      {currentPositionDurationComponent}
    </Text>
  );

  return (
    <Row flexShrink={1} testID={testID}>
      <RevealText
        testID={`${testID}-text`}
        revealActionText={t('applications.applicationCard.showRole')}
        truncatedText={
          truncatedText !== currentPosition ? truncatedTextComponent : undefined
        }
      >
        {fullTextComponent}
      </RevealText>
    </Row>
  );
};
