import { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './JoraEmployerFooter.scss';
import { withTranslation } from 'react-i18next';
import { SupportLevel } from '@seek/je-shared-data/lib/types/brand';
import { If, Then, Else } from '@common/components/If';

import ExternalLink from '../../../../../common/components/ExternalLink/ExternalLink';
import * as link from '../../../../helpers/links';
import { AppDetails } from '../../../../components/AppDetails/AppDetails.tsx';

@withTranslation()
export default class JoraEmployerFooter extends Component {
  static propTypes = {
    localisation: PropTypes.shape({
      brandConfig: PropTypes.shape({
        name: PropTypes.string.isRequired
      }),
      candidateDomain: PropTypes.string.isRequired,
      copyrightText: PropTypes.string.isRequired,
      countryCode: PropTypes.string.isRequired,
      supportTier: PropTypes.number.isRequired
    }),
    t: PropTypes.func.isRequired
  };

  render() {
    const { localisation, t } = this.props;
    const { copyrightText, countryCode, brandConfig: { name: brandName }, supportTier } = localisation;
    const copyrightYear = new Date().getFullYear();

    return (
      <footer className={styles.footer}>
        <div className={styles.wrapper}>
          <div>
            <ExternalLink href={link.buildCandidateLink({ brandName, countryCode, page: link.ABOUT_PAGE })} target="_blank" data-test-key="footer-about-link">
              {t('common.linkText.about')}
            </ExternalLink>
            <If condition={supportTier === SupportLevel.TIER_1}>
              <Then>
                <ExternalLink href={link.buildCandidateLink({ brandName, countryCode, page: link.CONTACT_PAGE })} target="_blank" data-test-key="footer-contact-link">
                  {t('common.linkText.contact')}
                </ExternalLink>
              </Then>
              <Else>
                <ExternalLink href={link.buildCandidateLink({ brandName, countryCode, page: link.FAQ_EMPLOYER_PAGE })} target="_blank" data-test-key="footer-faqs-link">
                  {t('common.linkText.faqs')}
                </ExternalLink>
              </Else>
            </If>
            <ExternalLink href={link.buildCandidateLink({ brandName, countryCode, page: link.TERMS_AND_CONDITION_PAGE })} target="_blank" data-test-key="footer-terms-link">
              {t('common.linkText.termsAndConditions')}
            </ExternalLink>
            <ExternalLink href={link.buildCandidateLink({ brandName, countryCode, page: link.PRIVACY_PAGE })} target="_blank" data-test-key="footer-privacy-link">
              {t('common.linkText.privacyPolicy')}
            </ExternalLink>
          </div>
        </div>
        <div className={styles.copyright}>&copy; {copyrightYear} {copyrightText}</div>
        <AppDetails />
      </footer>
    );
  }
}
