import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from '@components/Badge';
import { Box } from '@components/Box';

export const RecommendedUpgrade = () => {
  const { t } = useTranslation();
  if (!t('upgradeJobForm.recommended')) return null;

  return (
    <Box>
      <Badge tone="promo">{t('upgradeJobForm.recommended')}</Badge>
    </Box>
  );
};
