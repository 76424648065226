import { useTranslation } from 'react-i18next';
import {
  CLOSE_JOB_REASON_MAX_LENGTH,
  MAX_LENGTH,
  REQUIRED_VALIDATION_ERROR
} from '@common/constants/validation';

export function useFormValidation() {
  const { t } = useTranslation();

  const validationMessages = t('validations.job.closeJob.additionalComments', {
    returnObjects: true,
    max: CLOSE_JOB_REASON_MAX_LENGTH
  });

  return {
    additionalCommentsValidation: {
      validate: (value: string = '') => {
        return {
          required: {
            value: !value.length,
            message: validationMessages[REQUIRED_VALIDATION_ERROR]
          },
          [MAX_LENGTH]: {
            value: CLOSE_JOB_REASON_MAX_LENGTH,
            message: validationMessages[MAX_LENGTH]
          }
        };
      }
    },
    reasonFieldValidation: {
      validate: (value: string = '') => {
        return {
          required: {
            value: !value.length,
            message: t('validations.job.closeJob.reason.required')
          }
        };
      }
    }
  };
}
