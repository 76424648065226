import React from 'react';
import { Card, CardContent } from '@components/Card';
import { Stack } from '@components/Stack';

export function FormCard({ children }: { children: React.ReactNode }) {
  return (
    <Card>
      <CardContent>
        <Stack spacing="large">{children}</Stack>
      </CardContent>
    </Card>
  );
}
