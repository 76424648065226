import { Component } from 'react';
import PropTypes from 'prop-types';
import ValidationMessage from '../ValidationMessage/ValidationMessage';
import Div from '../../Div/Div';

export default function (WrappedComponent) {
  return class ReduxFormField extends Component {
    static propTypes = {
      input: PropTypes.shape({
        name: PropTypes.string,
        onChange: PropTypes.func
      }),
      meta: PropTypes.shape({
        error: PropTypes.string,
        touched: PropTypes.bool
      }),
      validationMessages: PropTypes.object,
      extraValidationTopMargin: PropTypes.bool
    };

    render() {
      const { input, meta, validationMessages, extraValidationTopMargin, ...restProps } = this.props;

      return (
        <Div>
          <WrappedComponent
            {...input}
            {...meta}
            {...restProps} />
          {
            validationMessages && meta &&
            <ValidationMessage
              extraTopMargin={extraValidationTopMargin}
              field={meta}
              name={input.name}
              testKeyPrefix={input.name}
              messages={validationMessages} />
          }
        </Div>
      );
    }
  };
}