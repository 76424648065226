import type { T } from '@src/caja/Form/types';

/**
 * Generates validation rules for the business role field.
 * @param t - The translation function.
 * @returns The validation rules object.
 */
export function roleRules(t: T): Record<string, string> {
  return {
    required: t('validations.business.role.required')
  };
}
