import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@components/Box';
import { Button } from '@components/Button';
import { Column } from '@components/Column';
import { UpdateYourBusinessesIllustration } from '@components/Illustration';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { ContactUs } from '../ContactUs';
import { PhoneAndEmail } from '../PhoneAndEmail';

type RequestUpgradeConfirmationProps = {
  mobile?: string;
  email?: string;
  onComplete: () => void;
};

export const RequestUpgradeConfirmation = ({
  mobile,
  email,
  onComplete
}: RequestUpgradeConfirmationProps) => {
  const { t } = useTranslation();

  return (
    <Box alignItems="center">
      <Column
        alignItems="center"
        spacing="small"
        width={{ desktop: '3/4', tablet: '3/4', mobile: 'full' }}
      >
        <UpdateYourBusinessesIllustration
          size={{ desktop: 'standard', tablet: 'standard', mobile: 'small' }}
        />

        <Text weight="regular" align="center">
          {t('account.upgradeAccountModal.confirmation.heading')}
        </Text>

        <Text align="center">
          {t('account.upgradeAccountModal.confirmation.description')}
        </Text>

        <Stack alignItems="center">
          <PhoneAndEmail mobile={mobile} email={email} />
        </Stack>

        <Box marginVertical="small">
          <Text size="small" align="center">
            <ContactUs />
          </Text>
        </Box>

        <Button onPress={onComplete} variant="secondary">
          {t('account.upgradeAccountModal.confirmation.cta')}
        </Button>
      </Column>
    </Box>
  );
};
