import React, { ReactElement, forwardRef } from 'react';
import { Pressable, PressableProps, View } from 'react-native';

export type IconButtonProps = {
  icon?: ReactElement;
  testID?: PressableProps['testID'];
  onPress?: PressableProps['onPress'];
};

export const IconButton = forwardRef<View, IconButtonProps>((props, ref) => {
  const { icon, testID, onPress } = props;

  return (
    <Pressable onPress={onPress} testID={testID} ref={ref}>
      {icon}
    </Pressable>
  );
});
