import { useLocation } from 'react-router-native';
import { useGetPostJobLink } from '@common/hooks/appPageLinks';
import {
  useCoinsFeature,
  useIsTier3Support
} from '@common/hooks/featureToggles';
import { useSiteCountryCode } from '@common/hooks/localisation';
import { getNavMenuItems } from '../helpers/getNavMenuItems';

/**
 * Custom hook that returns the navigation menu items based on the current user's country code, support tier, and location.
 * @returns An array of navigation menu items.
 */
export const useNavMenuItems = () => {
  const { pathname } = useLocation();

  const countryCode = useSiteCountryCode();
  const { postJobLink } = useGetPostJobLink();

  const isTier3Support = useIsTier3Support();
  const isPostJobEnabled = !isTier3Support;
  const isCoinsEnabled = useCoinsFeature(countryCode);

  return getNavMenuItems({
    countryCode,
    postJobLink,
    isPostJobEnabled,
    isCoinsEnabled,
    currentPath: pathname
  });
};
