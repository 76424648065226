import { createContext } from 'react';
import type { Control, FieldErrorsImpl } from 'react-hook-form';
import type { T } from '../../../types';
import type { UpdateAccountDetailsFormFields } from '../types';

interface FormContextProps {
  t: T;
  control: Control<UpdateAccountDetailsFormFields>;
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
}

export const FormContext = createContext<FormContextProps>({
  control: {} as FormContextProps['control'],
  errors: {},
  t: () => ''
});
