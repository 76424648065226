import {
  CHARCODE_ELLIPSIS,
  NUMBER_OF_LINES_BEFORE_ELLIPSIS
} from '../constants/general';
import { LENGTH_BEFORE_ELLIPSIS } from '../constants/validation';

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function formatEmailWithEllipsis(email) {
  if (!email) {
    return '';
  }

  const parts = email.split('@');

  return `${parts[0].charAt(0)}${String.fromCharCode(CHARCODE_ELLIPSIS)}@${
    parts[parts.length - 1]
  }`;
}

export function truncateStringWithEllipsis(stringToTruncate, maxLength = 12) {
  if (stringToTruncate && stringToTruncate.length > maxLength) {
    return `${stringToTruncate.substring(
      0,
      maxLength - 1
    )}${String.fromCharCode(CHARCODE_ELLIPSIS)}`;
  }

  return stringToTruncate;
}

export function truncatePhraseAndAppendEllipsis(textToTruncate, max = 12) {
  const numberOfWords = textToTruncate.split(' ').length;

  return numberOfWords > max
    ? `${textToTruncate.split(' ').slice(0, max).join(' ')}... `
    : textToTruncate;
}

export function formatStringToTitleCase(inputString = '') {
  const stringParts = inputString.trim().split(' ');
  const stringPartsFormatted = stringParts.map((part) =>
    capitalizeFirstLetter(part)
  );

  return stringPartsFormatted.join(' ');
}

export function getFirstNumOfLine({ inputString = '', numOfLine = 2 } = {}) {
  if (typeof inputString !== 'string') {
    return '';
  }

  if (inputString && inputString.split('\n').length > 1) {
    return inputString.split('\n').splice(0, numOfLine).join('\n');
  }

  return inputString;
}

export function countNumberOfLines(inputString) {
  return inputString.split('\n').length;
}

function trimUnwantedEOLChars(string) {
  const EOL_SPACES_OR_FULL_STOPS_REGEX = /(\s|\.)*$/;
  const unwantedCharsIndex = string.search(EOL_SPACES_OR_FULL_STOPS_REGEX);

  return unwantedCharsIndex !== -1
    ? string.substr(0, unwantedCharsIndex)
    : string;
}

export function ellipsisTextSection(textToShorten, fullText) {
  const MAX_LENGTH = LENGTH_BEFORE_ELLIPSIS;

  if (
    fullText.length > MAX_LENGTH ||
    countNumberOfLines(fullText) > NUMBER_OF_LINES_BEFORE_ELLIPSIS
  ) {
    const shortenedText = textToShorten.substr(
      0,
      MAX_LENGTH - (fullText.length - textToShorten.length)
    );
    const maxNumberOfLineString =
      getFirstNumOfLine({
        inputString: shortenedText,
        numOfLine: NUMBER_OF_LINES_BEFORE_ELLIPSIS
      }) || shortenedText;

    return (
      trimUnwantedEOLChars(maxNumberOfLineString) +
      String.fromCharCode(CHARCODE_ELLIPSIS)
    );
  }

  return textToShorten;
}
