import React from 'react';
import { useTranslation } from 'react-i18next';
import { TotalCoinsText } from '@common/components/TotalCoinsText';
import {
  useQueryMyBalance,
  useQueryNextExpiringCredit
} from '@common/hooks/coins';
import { Card, CardContent } from '@components/Card';
import { Heading } from '@components/Heading';
import { CoinExpiryText } from './CoinExpiryText';

export const CoinSummary = () => {
  const { t } = useTranslation();
  const { myBalance } = useQueryMyBalance();
  const { nextExpiringCredit } = useQueryNextExpiringCredit();

  if (myBalance === undefined) return null;
  if (nextExpiringCredit === undefined) return null;

  const { remainingAmount, expiryDate } = nextExpiringCredit.credit || {};

  return (
    <Card>
      <CardContent>
        <Heading level="h2">{t('myCoinsPage.balance.heading')}</Heading>

        <TotalCoinsText size="large" balance={myBalance} />
        <CoinExpiryText
          amountExpiring={remainingAmount}
          expiryDate={expiryDate}
        />
      </CardContent>
    </Card>
  );
};
