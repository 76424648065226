import { isValidEmailFormat } from '../../../helpers/validation';
import * as validation from '../../../constants/validation';

export function validateForgotPasswordForm(data) {
  const errors = {};
  const requiredFields = [
    'email'
  ];
  const isValidEmail = data.email && !isValidEmailFormat(data.email);

  if (isValidEmail) {
    errors.email = validation.FORMAT_VALIDATION_ERROR;
  }

  requiredFields.forEach(key => {
    const fieldValue = data[key];
    const isTextFieldEmpty = typeof fieldValue === 'string' ? !data[key].trim() : false;

    if (!fieldValue || isTextFieldEmpty) {
      errors[key] = validation.REQUIRED_VALIDATION_ERROR;
    }
  });

  return errors;
}