import { Component } from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash.omit';

export default class ExternalLink extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    target: PropTypes.string,
    'data-test-key': PropTypes.string
  };

  render() {
    const { children, className, target, 'data-test-key': dataTestKey } = this.props;
    const rel = (target === '_blank') ? 'noopener' : null;

    return (
      <a data-test-key={dataTestKey} className={className} rel={rel} {...omit(this.props, 'textSize')}>
        {children}
      </a>
    );
  }
}