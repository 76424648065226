import React from 'react';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { RightToWork } from './RightToWork';

export const RightToWorkController = <T extends FieldValues>({
  name,
  control
}: UseControllerProps<T>) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value } }) => {
      return <RightToWork onChange={onChange} value={value} />;
    }}
  />
);
