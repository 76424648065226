import {
  JOB_DESCRIPTION_MAX_LENGTH,
  JOB_DESCRIPTION_MIN_LENGTH,
  JOB_DESCRIPTION_MIN_LENGTH_FOR_EXPRESS_VERIFICATION
} from '@common/constants/validation';

/**
 * Calculates the minimum and maximum length for a job description based on the provided express verification token.
 * If no token is provided, the default minimum and maximum lengths are used.
 *
 * @param {string} expressVerificationToken The express verification token.
 * @returns An object containing the minimum and maximum lengths for the job description.
 */
export function minMax(expressVerificationToken): { min: number; max: number } {
  if (!expressVerificationToken || !expressVerificationToken.length) {
    return { min: JOB_DESCRIPTION_MIN_LENGTH, max: JOB_DESCRIPTION_MAX_LENGTH };
  }

  return {
    min: JOB_DESCRIPTION_MIN_LENGTH_FOR_EXPRESS_VERIFICATION,
    max: JOB_DESCRIPTION_MAX_LENGTH
  };
}
