import React from 'react';
import { useTranslation } from 'react-i18next';
import { applicantCount } from '@common/applicationInformation';
import { useGetApplicationDetailsLink } from '@common/hooks/appPageLinks/useGetApplicationDetailsLink';
import { ApplicationInformation } from '@common/hooks/useAppQuery/queries/useGetApplications';
import { useRedirect } from '@common/hooks/useRedirect';
import { ListJobItem } from '@store/entities/jobs';
import { CardAction, CardActionLink } from '@components/Card';
import { UserIcon } from '@components/Icon';

export type ApplicationsActionProps = {
  applicationInformation: ApplicationInformation | undefined;
  job: ListJobItem;
};

export const ApplicationsAction = ({
  job,
  applicationInformation
}: ApplicationsActionProps) => {
  const { t } = useTranslation();
  const { redirect } = useRedirect();
  const applicationDetailsLink = useGetApplicationDetailsLink(job.id);

  return (
    <CardAction>
      <CardActionLink
        icon={<UserIcon variant="outline" testID="user-icon" />}
        text={t('dashboardPage.jobCard.applicantCount', {
          count: applicantCount(applicationInformation)
        })}
        onPress={redirect(applicationDetailsLink)}
      />
    </CardAction>
  );
};
