import React from 'react';
import { TextWithIcon } from '@common/components/TextWithIcon';
import { RightTickIcon } from '@components/Icon';
import { Text } from '@components/Text';

interface BenefitProps {
  children: React.ReactNode;
}

export const Benefit = ({ children }: BenefitProps) => (
  <TextWithIcon
    icon={
      <RightTickIcon
        size="small"
        variant="outline"
        tone="success"
        testID="right-tick-icon"
      />
    }
  >
    <Text size="small">{children}</Text>
  </TextWithIcon>
);
