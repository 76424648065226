import { ReactElement, useState } from 'react';
import {
  UseStyleProviderStyleProps,
  useStyleProviderStyle
} from './useStyleProviderStyle';

export type StyleProviderProps = Omit<
  UseStyleProviderStyleProps,
  'isFocused'
> & {
  children: ({ style }: ChildrenProps) => ReactElement;
  focused?: boolean;
};

export type ProvidedStyle = ReturnType<typeof useStyleProviderStyle>;

type ChildrenProps = {
  onBlur: () => void;
  onFocus: () => void;
  style: ReturnType<typeof useStyleProviderStyle>;
};

/**
 * Provides design system-based styles to components that cannot be included
 * in the design system component module.
 * For instance, third-party components that provide not just UI functionality
 * but also additional services like payment.
 * */
export const StyleProvider = ({
  children,
  focused = false,
  ...restProps
}: StyleProviderProps) => {
  const [isFocused, setFocused] = useState(focused);
  const resolvedStyle = useStyleProviderStyle({ isFocused, ...restProps });

  return children({
    onBlur: () => setFocused(false),
    onFocus: () => setFocused(true),
    style: resolvedStyle
  });
};
