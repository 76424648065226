import React from 'react';
import { AdTypeBadge } from '@common/components/AdTypeBadge';
import { JobStatusBadge } from '@common/components/JobStatusBadge';
import { upgraded } from '@src/job';
import { ListJobItem } from '@store/entities/jobs/types';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { JobPostedOnText } from '../JobPostedOnText';

type JobInformationPanelProps = {
  job: ListJobItem;
};

export const JobInformationPanel = ({ job }: JobInformationPanelProps) => {
  const { adType, createdAt, status } = job;

  return (
    <Stack
      direction={{ mobile: 'column', tablet: 'row', desktop: 'row' }}
      spacing={{ mobile: 'medium', tablet: 'xsmall', desktop: 'xsmall' }}
      alignItems={{ mobile: 'flex-start', tablet: 'center', desktop: 'center' }}
    >
      <Row spacing="xsmall" alignItems="center">
        <JobStatusBadge status={status} />
        {upgraded(adType) ? (
          <AdTypeBadge adType={adType} showIcon={false} />
        ) : null}
      </Row>
      <JobPostedOnText createdAt={createdAt} />
    </Stack>
  );
};
