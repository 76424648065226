import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { Box } from '@components/Box';
import { Field, Input } from '@components/FormElements';
import { Stack } from '@components/Stack';

type RecruitmentCompanyNameProps = {
  value: string | undefined;
  onChange: () => void;
  onBlur: () => void;
  error?: string;
};

export const RecruitmentCompanyName = (props: RecruitmentCompanyNameProps) => {
  const { t } = useTranslation();
  const { error, onChange, onBlur, value } = props;

  return (
    <Stack spacing="small">
      <Field
        label={t('createBusinessForm.fieldLabel.recruitmentName')}
        message={
          error && (
            <Box marginTop="xxsmall">
              <ErrorMessage>{error}</ErrorMessage>
            </Box>
          )
        }
      >
        <Input
          id="business-form-recruitmentCompanyName"
          testID="business-form-recruitmentCompanyName"
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          autoCapitalize="words"
        />
      </Field>
    </Stack>
  );
};
