import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { useRedirect } from '@common/hooks/useRedirect';
import { useRedirectUnauthenticatedUser } from '@common/hooks/useRedirectUnauthenticatedUser';
import { useSearchQueryParam } from '@common/hooks/useSearchQueryParam';
import { BRAND_CONFIG } from '@seek/je-shared-data';
import { useAppDispatch } from '@store/hooks';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';
import { trackConfirmPaymentSuccess } from '@store/payment/actions';
import { PAYMENT_METHOD } from '@store/payment/constants';
import { getUserData } from '@store/userData';
import { Button } from '@components/Button';
import { Card, CardContent } from '@components/Card';
import { Heading } from '@components/Heading';
import { BudgetFriendlyIllustration } from '@components/Illustration';
import { List } from '@components/List';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';

export const PaymentSuccess = () => {
  useRedirectUnauthenticatedUser();

  const { t } = useTranslation();
  const { redirect } = useRedirect();
  const { countryCode, brandNameText } = useAppSelector(getLocalisation);

  const { checkQueryParam, getQueryParam } = useSearchQueryParam();
  const isFromPostJob = checkQueryParam('fromPostJob');
  const adType = getQueryParam('adType'); //Mandatory field
  const jobCountry = getQueryParam('jobCountry'); //Mandatory field
  const paymentMethod = getQueryParam('paymentMethod');

  const dispatch = useAppDispatch();
  const userData = useAppSelector(getUserData);
  const queryParams: {
    utm_campaign: string;
    utm_medium: string;
    utm_source: string;
    utm_content: string;
  } = userData['source.query'] || {};
  const {
    utm_campaign: utmCampaign,
    utm_medium: utmMedium,
    utm_source: utmSource,
    utm_content: utmContent
  } = queryParams;
  const sourceParams =
    utmCampaign || utmMedium || utmSource || utmContent
      ? `utm_campaign=${utmCampaign}&utm_medium=${utmMedium}&utm_source=${utmSource}&utm_content=${utmContent}`
      : '';
  const isBankTransfer =
    paymentMethod === PAYMENT_METHOD.indonesianBankTransfer;

  useEffect(() => {
    // Track payment success event required for analytics
    if (adType && jobCountry) {
      dispatch(
        trackConfirmPaymentSuccess({ jobCountry, adType, sourceParams })
      );
    }
  }, [dispatch, adType, jobCountry, sourceParams]);

  const postJobSuccessCopy = () => {
    return brandNameText ===
      BRAND_CONFIG.jobstreetExpress.defaultSettings.brandNameText ? (
      <Card>
        <CardContent>
          <Stack spacing="medium">
            {isBankTransfer ? (
              <Text>
                {t('paymentSuccessPage.description.bankTransferDelayText')}
              </Text>
            ) : null}

            <Heading level="h2">
              {t('paymentSuccessPage.temporary.firstTimePoster.heading', {
                brandNameText: brandNameText
              })}
            </Heading>

            {isFromPostJob ? (
              <Stack spacing="medium">
                <Text>
                  {t(
                    'paymentSuccessPage.temporary.firstTimePoster.description'
                  )}
                </Text>

                <Heading level="h2">
                  {t('paymentSuccessPage.temporary.verifiedPoster.heading', {
                    brandNameText: brandNameText
                  })}
                </Heading>

                <Text>
                  {t('paymentSuccessPage.temporary.verifiedPoster.description')}
                </Text>
              </Stack>
            ) : (
              <Text>
                {t(
                  'paymentSuccessPage.description.whatHappensNow.yourJobWillBeUpgraded'
                )}
              </Text>
            )}

            <Stack
              spacing="medium"
              direction={{ mobile: 'column', tablet: 'row', desktop: 'row' }}
            >
              <Button onPress={redirect(`/${countryCode}/post-job`)}>
                {t('postJobSuccessPage.action.postAnotherJob')}
              </Button>

              <Button
                onPress={redirect(`/${countryCode}/`)}
                variant="secondary"
              >
                {t('common.action.backToHome')}
              </Button>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    ) : (
      <Card>
        <CardContent>
          <Stack spacing="medium">
            <Heading level="h2">
              {t('paymentSuccessPage.description.whatHappensNow.heading')}
            </Heading>

            {isFromPostJob ? (
              <Stack spacing="medium">
                <List>
                  <Text>
                    {t(
                      'paymentSuccessPage.description.whatHappensNow.weAreReviewing'
                    )}
                  </Text>
                  <Text>
                    {t(
                      'paymentSuccessPage.description.whatHappensNow.ifYouAlready'
                    )}
                  </Text>
                </List>

                <Heading level="h2">
                  {t('paymentSuccessPage.description.whatHappensNext.heading')}
                </Heading>

                <Text>
                  {t(
                    'paymentSuccessPage.description.whatHappensNext.yourJobWillBeUpgraded'
                  )}
                </Text>

                <List variant="number">
                  <Text>
                    {t(
                      'paymentSuccessPage.description.whatHappensNext.editOrCloseJob'
                    )}
                  </Text>
                  <Text>
                    {t(
                      'paymentSuccessPage.description.whatHappensNext.reviewAndShortlistApplicants'
                    )}
                  </Text>
                </List>
              </Stack>
            ) : (
              <Text>
                {t(
                  'paymentSuccessPage.description.whatHappensNow.yourJobWillBeUpgraded'
                )}
              </Text>
            )}

            <Stack
              spacing="medium"
              direction={{ mobile: 'column', tablet: 'row', desktop: 'row' }}
            >
              <Button onPress={redirect(`/${countryCode}/post-job`)}>
                {t('postJobSuccessPage.action.postAnotherJob')}
              </Button>

              <Button
                onPress={redirect(`/${countryCode}/`)}
                variant="secondary"
              >
                {t('common.action.backToHome')}
              </Button>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    );
  };

  return (
    <Stack tone="transparent">
      <JoraEmployerHelmet title={t('paymentSuccessPage.title')} />

      <Stack spacing="medium" tone="transparent">
        <Card>
          <CardContent>
            <Stack alignItems="center" spacing="medium">
              <BudgetFriendlyIllustration size="small" />
              <Heading level="h1" weight="regular">
                {t('paymentSuccessPage.heading', { context: paymentMethod })}
              </Heading>
            </Stack>
          </CardContent>
        </Card>

        {postJobSuccessCopy()}
      </Stack>
    </Stack>
  );
};
