import { useSiteCountryCode } from '@common/hooks/localisation';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { getFeatures } from '@store/config';
import { useAppSelector } from '@store/hooks';
import { useBrandName } from '../../localisation/useBrandName';

export const useAccountUpgradeable = () => {
  const features = useAppSelector(getFeatures());
  const countryCode = useSiteCountryCode();
  const brandName = useBrandName();

  return Boolean(
    features?.accountUpgradeable?.[brandName]?.[
      countryCode as SupportedCountryCode
    ]
  );
};
