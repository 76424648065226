import {
  ResponseErrors,
  UpdateAccountDetailsFormFields
} from '@common/components/Account/forms/updateAccountDetails/types';

export function mapResponseError({
  context
}: {
  context?: Partial<{ [K in keyof UpdateAccountDetailsFormFields]: string }>;
}): ResponseErrors | undefined {
  if (!context) return;

  // Only one server error is returned at a time
  const field = Object.keys(context)[0] as keyof UpdateAccountDetailsFormFields;

  const mapErrorToMessage = {
    email: 'emailAlreadyExists',
    mobileNumber: 'mobileAlreadyExists'
  };

  const errorMessage = `common.errors.${mapErrorToMessage[field]}`;

  return {
    field,
    errorMessage
  };
}
