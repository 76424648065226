import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';

import Div from '../../../../common/components/Div/Div';
import ScreenHeading from '../../../../common/components/ScreenHeading/ScreenHeading';
import UpdatePasswordForm from './components/UpdatePasswordForm/UpdatePasswordForm';
import { RedirectUnauthenticatedUser } from '../../../components/RedirectUnauthenticatedUser';

@connect(
  ({ localisation }) => ({
    countryCode: localisation.countryCode
  })
)
@withTranslation()
export default class UpdatePassword extends Component {
  static propTypes = {
    countryCode: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired
  };

  render() {
    const { t, countryCode } = this.props;
    return (
      <Div>
        <RedirectUnauthenticatedUser to={`/${countryCode}/login`} />
        <Helmet title={t('updatePasswordPage.title')} />
        <ScreenHeading>{t('updatePasswordPage.heading')}</ScreenHeading>
        <UpdatePasswordForm />
      </Div>
    );
  }
}