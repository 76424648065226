import { JOB_STATUS } from '@seek/je-shared-data';

type LinkWithStatus = {
  liveJobLink: string | undefined;
  status: JOB_STATUS | undefined;
};

/**
 * Determines if a job is shareable based on its status and live job link.
 * @param {LinkWithStatus} linkWithStatus - The link and status of the job.
 * @returns {boolean} True if the job is shareable, false otherwise.
 */
export const shareable = ({ status, liveJobLink }: LinkWithStatus): Boolean => {
  if (!liveJobLink) {
    return false;
  }
  return status === JOB_STATUS.LIVE;
};
