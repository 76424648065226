import { ProfileApplication } from 'je-bff/src/applications/list/transformers';
import { ApplicationFilterValues } from '../../../types';
import { sortFilteredApplications } from '../sort';
import {
  applyFilterReducer,
  filterApplicantStatus,
  filterDistance,
  filterRightToWork,
  filterVideoScreening
} from './';
import { filterLocation } from './filterLocation';

export const getFilteredApplications = (
  filterValues: ApplicationFilterValues,
  applications: ProfileApplication[]
): ProfileApplication[] => {
  const filters = [
    filterApplicantStatus,
    filterDistance,
    filterRightToWork,
    filterVideoScreening,
    filterLocation
  ];
  const filteredApplications = applyFilterReducer(
    filters,
    filterValues,
    applications
  );
  return sortFilteredApplications(filterValues, filteredApplications);
};
