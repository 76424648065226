import { IncompleteData } from '@src/caja/types';
import { IncompleteInfoFormData } from './types';

export function subsetOfData(
  cajaData: IncompleteData | undefined
): IncompleteInfoFormData {
  if (!cajaData) return {};
  return {
    jobTitle: cajaData.jobTitle,
    jobTitleAuto: cajaData.jobTitleAuto,
    jobType: cajaData.jobType,
    rightToWork: cajaData.rightToWork,
    workExperience: cajaData.workExperience,
    startDate: cajaData.startDate,
    salary: cajaData.salary,
    jobDescription: cajaData.jobDescription,
    shiftAvailability: cajaData.shiftAvailability,
    business: cajaData.business
  };
}
