import React from 'react';
import { useTranslation } from 'react-i18next';
import { expiryIsRelevant } from '@common/applicationInformation';
import { expiryWarning } from '@common/applicationInformation/expiryWarning';
import { NeutralWarningText } from '@common/components/ErrorMessage';
import { useMonetisationEnabled } from '@common/hooks/featureToggles';
import { useMismatchedStripeAccount } from '@common/hooks/featureToggles/useMonetisationEnabled/useMonetisationEnabled';
import { useBrandName, useSiteCountryCode } from '@common/hooks/localisation';
import { ApplicationInformation } from '@common/hooks/useAppQuery/queries/useGetApplications';
import { useRedirect } from '@common/hooks/useRedirect';
import { Business } from '@common/types';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import {
  hasExpiredAt,
  isLiveOrExpired,
  shareable,
  upgradeableAndLive
} from '@src/job';
import { ListJobItem } from '@store/entities/jobs';
import { Box } from '@components/Box';
import { CardContent } from '@components/Card';
import { Pressable } from '@components/Pressable';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { ShareAdActionLink } from '../ShareAdAction';
import { CardBusinessInformation } from './components/CardBusinessInformation';
import { ExpiryInformation } from './components/ExpiryInformation';
import { JobInformationPanel } from './components/JobInformationPanel';
import { UpgradeAdButton } from './components/UpgradeAdButton';
import { useOnShareOnPlatformPressed } from './hooks/useOnShareOnPlatformPressed';
import { useOnSharePressed } from './hooks/useOnSharePressed';

type JobCardContentProps = {
  applicationInformation: ApplicationInformation | undefined;
  businessSize: Business['businessSize'];
  job: ListJobItem;
};

export const JobCardContent = ({
  applicationInformation,
  businessSize,
  job
}: JobCardContentProps) => {
  const { t } = useTranslation();
  const brand = useBrandName();
  const siteCountryCode = useSiteCountryCode();
  const { redirect } = useRedirect();

  const onSharePressed = useOnSharePressed();
  const onShareOnPlatformPressed = useOnShareOnPlatformPressed();

  const {
    adType,
    businessName,
    countryCode,
    id: jobId,
    liveJobLink,
    location,
    status,
    title,
    expiredAt
  } = job;

  const showJobExpiryDetails =
    hasExpiredAt(expiredAt) && isLiveOrExpired(status);

  const isMismatchedStripeAccount = useMismatchedStripeAccount(
    countryCode as SupportedCountryCode
  );
  const shouldDisplayUpgradeAd =
    useMonetisationEnabled(countryCode) &&
    !isMismatchedStripeAccount &&
    upgradeableAndLive({ adType, status });

  return (
    <CardContent
      paddingBottom="large"
      paddingLeft={{
        mobile: 'xxsmall',
        tablet: 'medium',
        desktop: 'medium'
      }}
    >
      <Stack
        spacing={
          shareable({ liveJobLink, status })
            ? {
                mobile: {
                  web: 'none',
                  android: 'medium',
                  ios: 'medium'
                },
                tablet: 'none',
                desktop: 'none'
              }
            : 'small'
        }
      >
        <Row justifyContent="space-between">
          <Pressable
            flex={1}
            onPress={redirect(`/${siteCountryCode}/jobs/${jobId}/details`)}
          >
            <JobInformationPanel job={job} />
          </Pressable>

          {shareable({ liveJobLink, status }) ? (
            <ShareAdActionLink
              brandName={brand}
              job={{ id: jobId, title, liveJobLink }}
              handleSocialShareAdIconClicked={onSharePressed}
              handleSocialIconClicked={onShareOnPlatformPressed}
            />
          ) : null}
        </Row>

        <Box flex={1}>
          <Stack spacing="medium">
            <Pressable
              onPress={redirect(`/${siteCountryCode}/jobs/${jobId}/details`)}
            >
              <Stack spacing="medium">
                {showJobExpiryDetails && (
                  <ExpiryInformation expiredAt={expiredAt} status={status} />
                )}

                <Text weight="regular">{title}</Text>

                <Stack
                  spacing={
                    expiryIsRelevant(applicationInformation, status)
                      ? 'medium'
                      : 'large'
                  }
                >
                  <CardBusinessInformation
                    name={businessName}
                    size={businessSize}
                    location={location}
                  />

                  {expiryIsRelevant(applicationInformation, status) ? (
                    <NeutralWarningText>
                      {expiryWarning({ t, applicationInformation, status })}
                    </NeutralWarningText>
                  ) : null}
                </Stack>
              </Stack>
            </Pressable>

            {shouldDisplayUpgradeAd ? <UpgradeAdButton jobId={jobId} /> : null}
          </Stack>
        </Box>
      </Stack>
    </CardContent>
  );
};
