import React from 'react';
import { UpgradeAccountBanner } from '@common/components/Account/components';
import { useAccountUpgradeable } from '@common/hooks/featureToggles/useAccountUpgradeable';
import { useGetUserPackage } from '@common/hooks/userPackages';
import { Box } from '@components/Box';

type UpgradeBannerProps = {
  pageTitle: string;
};

export const UpgradeBanner = ({ pageTitle }: UpgradeBannerProps) => {
  const accountUpgradeable = useAccountUpgradeable();
  const { packageType } = useGetUserPackage();
  const freeAndUpgradeableAccount =
    accountUpgradeable && packageType === 'free';

  if (!freeAndUpgradeableAccount) return null;

  return (
    <Box
      marginBottom="medium"
      marginHorizontal={{
        desktop: 'none',
        tablet: 'none',
        mobile: 'medium'
      }}
    >
      <UpgradeAccountBanner pageTitle={pageTitle} />
    </Box>
  );
};
