import { isAppAvailableForBrand } from '@common/helpers/isAppAvailableForBrand';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';

export const useAppAvailable = () => {
  const { brandConfig } = useAppSelector(getLocalisation);

  const isAppAvailable =
    brandConfig && isAppAvailableForBrand(brandConfig.name);

  return { isAppAvailable };
};
