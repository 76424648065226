import { swapRichTags } from './swapRichTags';

/**
 * Processes rich text by swapping rich tags and trimming the result.
 *
 * @param {string} value - The rich text to process.
 * @returns {string} The processed rich text.
 */
export function processRichText(value: string): string {
  return swapRichTags(value).trim();
}
