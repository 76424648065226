import { MilestoneStepProps } from '../components/MilestoneStep';

export const useProgressIndicator = ({
  children,
  currentStepPathname
}: {
  children: React.ReactElement<MilestoneStepProps>[];
  currentStepPathname: string;
}) => {
  let activeStep = children.length - 1;

  const getStepProps = (
    child: React.ReactElement<MilestoneStepProps>,
    index: number
  ) => {
    const isActiveStep = currentStepPathname === child.props.pathname;

    if (isActiveStep) {
      activeStep = index;
    }

    return {
      key: index,
      number: index + 1,
      isActive: isActiveStep,
      isDone: index < activeStep,
      isLastStep: index === children.length - 1
    };
  };

  return { getStepProps };
};
