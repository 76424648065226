import React from 'react';
import { Pressable } from 'react-native';
import { Banner, BannerProps } from '@components/Banner';
import { Heading } from '@components/Heading';
import { EmployerAppIllustration } from '@components/Illustration';
import { AppStoreLogo, PlayStoreLogo } from '@components/Logo';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { useAppDownloadReminderBanner } from './hooks';

export type Props = {
  testID?: BannerProps['testID'];
};

export const AppDownloadReminderBanner = (props: Props) => {
  const { testID } = props;
  const { shouldRenderBanner, bannerCopy, onPressAppStore } =
    useAppDownloadReminderBanner();

  if (!shouldRenderBanner) {
    return null;
  }

  return (
    <Banner
      testID={testID}
      tone="success"
      image={
        <Stack tone="transparent" paddingTop="large" width="full">
          <EmployerAppIllustration size="large" />
        </Stack>
      }
      alignImage="flex-end"
    >
      <Stack spacing="medium" flexShrink={1}>
        <Heading level="h1" weight="medium">
          {bannerCopy.title}
        </Heading>
        <Text>{bannerCopy.description}</Text>

        <Row spacing="medium">
          <Pressable onPress={() => onPressAppStore('ios')}>
            <AppStoreLogo width="medium" />
          </Pressable>

          <Pressable onPress={() => onPressAppStore('android')}>
            <PlayStoreLogo width="medium" />
          </Pressable>
        </Row>
      </Stack>
    </Banner>
  );
};
