import { SuccessResponse } from './useFetchTransactions';

export {
  useQueryTransactions,
  type QueryTransactionsResult
} from './useQueryTransactions';

export type Transactions = SuccessResponse['transactions'];
export type Transaction = SuccessResponse['transactions'][0];
export type TransactionsResult = SuccessResponse;
