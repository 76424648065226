import React, { useRef } from 'react';
import {
  useAccountUpgradeable,
  usePremiumApplicantFiltersFeature
} from '@common/hooks/featureToggles';
import { useUserHasPremiumFilterAccess } from '@common/hooks/userPackages';
import { Column } from '@components/Column';
import { useModal } from '@components/Modal';
import { Row } from '@components/Row';
import { ApplicationFilterValues } from '../../types';
import {
  FilteredStatusPill,
  FiltersModal,
  PremiumFeaturesBadge
} from './components';
import { FiltersLink } from './components/FiltersLink';

type Props = {
  appliedFiltersCount: number;
  pageTitle: string;
  setApplicationFilters: (filter: ApplicationFilterValues) => void;
  shouldShowActions: boolean;
  selectedApplicationsTab: 'matched' | 'other';
};

export const ApplicationsFilterSortBar = ({
  appliedFiltersCount,
  pageTitle,
  selectedApplicationsTab,
  setApplicationFilters,
  shouldShowActions
}: Props) => {
  const {
    openModal: openFilterModal,
    closeModal: closeFilterModal,
    isModalOpen: isFilterModalOpen
  } = useModal();
  const userIsFreeUser = !useUserHasPremiumFilterAccess();
  const accountUpgradeable = useAccountUpgradeable();
  const showPremiumFilters =
    usePremiumApplicantFiltersFeature() && accountUpgradeable; // only show the filters in markets where package upgrades are possible

  const ref = useRef<{
    clearAllFilters: () => void;
    sendClearAllFiltersTrackingEvent: (eventType: string) => void;
  }>();

  return (
    <Column
      justifyContent="space-between"
      alignItems="flex-start"
      padding="medium"
      tone="transparent"
      zIndex={1000}
      spacing="medium"
    >
      <Row
        paddingTop="xsmall"
        paddingLeft="xxsmall"
        tone="transparent"
        alignItems="center"
        spacing="medium"
      >
        {shouldShowActions ? (
          <Row tone="transparent" alignItems="center" spacing="medium">
            <FiltersLink onPressLink={openFilterModal} />

            {userIsFreeUser && showPremiumFilters ? (
              <PremiumFeaturesBadge />
            ) : null}

            <FiltersModal
              ref={ref}
              pageTitle={pageTitle}
              setApplicationFilters={setApplicationFilters}
              visible={isFilterModalOpen}
              onRequestClose={closeFilterModal}
              selectedApplicationsTab={selectedApplicationsTab}
            />
          </Row>
        ) : null}
      </Row>

      {appliedFiltersCount > 0 ? (
        <Row paddingLeft="xxsmall" tone="transparent" alignItems="center">
          <FilteredStatusPill
            onPressClearFilters={() => {
              ref.current?.clearAllFilters();
              ref.current?.sendClearAllFiltersTrackingEvent(
                'Application filters pill cleared'
              );
            }}
            filteredCount={appliedFiltersCount}
          />
        </Row>
      ) : null}
    </Column>
  );
};
