import React from 'react';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { Box } from '@components/Box';
import { Field, Input } from '@components/FormElements';
import { Stack } from '@components/Stack';
import { T } from '../../types';

type GivenNameProps = {
  value: string;
  onChange: () => void;
  onBlur: () => void;
  error?: string;
  t: T;
};

export const GivenName = ({
  error,
  onChange,
  onBlur,
  value,
  t
}: GivenNameProps) => {
  return (
    <Stack spacing="small">
      <Field
        label={t('registerForm.fieldLabel.givenName')}
        message={
          error && (
            <Box marginTop="xxsmall">
              <ErrorMessage>{error}</ErrorMessage>
            </Box>
          )
        }
      >
        <Input
          tone={error ? 'danger' : 'neutral'}
          id="account-details-form-givenName"
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          autoCapitalize="words"
          autoFocus
        />
      </Field>
    </Stack>
  );
};
