import React from 'react';
import { TextInputProps } from 'react-native';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { Box } from '@components/Box';
import { Field, Input } from '@components/FormElements';
import { Stack } from '@components/Stack';

type PasswordInputProps = {
  value: string;
  onChange: () => void;
  onBlur: () => void;
  error?: string;
  onEnter?: TextInputProps['onSubmitEditing'];
};

export const PasswordConfirmation = (props: PasswordInputProps) => {
  const { t } = useTranslation();
  const { error, onChange, onBlur, value, onEnter } = props;

  return (
    <Stack spacing="small">
      <Field
        label={t('registerForm.fieldLabel.confirmPassword')}
        message={
          error && (
            <Box marginTop="xxsmall">
              <ErrorMessage>{error}</ErrorMessage>
            </Box>
          )
        }
      >
        <Input
          tone={error ? 'danger' : 'neutral'}
          id="login-form-password-confirmation"
          testID="login-form-password-confirmation"
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          onSubmitEditing={onEnter}
          secureTextEntry={true}
        />
      </Field>
    </Stack>
  );
};
