import { LINK, useCandidateLink } from '@common/hooks/useCandidateLink';
import { useRedirect } from '@common/hooks/useRedirect';
import { Job } from '@common/types';
import { JOB_STATUS } from '@seek/je-shared-data';

export function useCalloutDetails(job: Job) {
  const { redirect } = useRedirect();
  const { getCandidateLink } = useCandidateLink();

  const { status } = job;
  const whyIsntMyJobAdLiveLink = getCandidateLink(
    LINK.FAQ_WHY_ISNT_MY_JOB_AD_LIVE_PAGE
  );

  switch (status) {
    case JOB_STATUS.INVALID_AD:
    case JOB_STATUS.ACTION_REQUIRED:
    case JOB_STATUS.USER_BANNED:
    case JOB_STATUS.CRAWLER_REJECTED: {
      return {
        i18nKey:
          'jobDetailsAndApplicationsListPage.explanationText.actionRequired',
        redirectToFaq: redirect(whyIsntMyJobAdLiveLink),
        tone: 'danger'
      } as const;
    }
    case JOB_STATUS.IN_REVIEW: {
      return {
        i18nKey: 'jobDetailsAndApplicationsListPage.explanationText.inReview',
        tone: 'warning'
      } as const;
    }
    case JOB_STATUS.EXPIRED_BUT_NEVER_LIVE: {
      return {
        i18nKey:
          'jobDetailsAndApplicationsListPage.explanationText.expiredButNeverLive',
        redirectToFaq: redirect(whyIsntMyJobAdLiveLink),
        tone: 'warning'
      } as const;
    }
    default:
      return;
  }
}
