import React from 'react';
import { InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';
import { Box } from '@components/Box';
import { Column } from '@components/Column';
import { Row } from '@components/Row';
import { Slider } from '@components/Slider';
import { Text } from '@components/Text';

type DistanceFilterProps = {
  value: number | undefined;
  onChange: (value: number) => void;
  onClear: () => void;
};

const min = 1;
const max = 101;

export const DistanceFilter = ({
  value = max,
  onChange,
  onClear
}: DistanceFilterProps) => {
  const { t } = useTranslation();

  return (
    <Column spacing="medium">
      <Text weight="medium">
        {t('applications.applicationsFilter.distance.title')}
      </Text>

      <Box paddingLeft="small" paddingRight="large">
        <Row spacing="xxsmall" alignItems="center">
          <span className="text-sm font-light">{t('Within')}</span>
          <div className="inputNumber">
            <InputNumber
              className="border-grey400 rounded-none text-sm focus:border-green700 focus:outline-none focus:ring-0 hover:border-grey400"
              style={{ width: '68px' }}
              min={min}
              max={max}
              size="large"
              controls={false}
              value={value}
              formatter={(val) => {
                if (`${val}` === `${max}`) return '100+';
                return `${val}`;
              }}
              parser={(val) => {
                if (val === '100+') return max;
                return parseInt(`${val}`, 10);
              }}
              onChange={(val) => {
                if (typeof val !== 'number') return;

                if (val === max) {
                  onClear();
                } else {
                  onChange(val);
                }
              }}
            />
          </div>
          <span className="text-sm font-light">{t('km')}</span>
        </Row>

        <Slider
          value={value}
          onChange={onChange}
          min={min}
          max={max}
          marks={{
            1: {
              label: <Text size="small">1km</Text>
            },
            101: {
              label: <Text size="small">100km+</Text>
            }
          }}
        />
      </Box>
    </Column>
  );
};
