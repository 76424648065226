import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateInteraction } from '@common/hooks/application';
import { UpdateInteractionParams } from '@common/hooks/application/interaction';

type Params = {
  applicationId: string;
  openVideoModal: () => void;
};

export const useViewVideoScreening = ({
  applicationId,
  openVideoModal
}: Params) => {
  const { t } = useTranslation();
  const { updateApplicantInteraction, errorMessage, isSuccessful } =
    useUpdateInteraction();
  const [isVideoViewed, setVideoViewed] = useState(false);

  const errorText = errorMessage?.message
    ? t(errorMessage.message, errorMessage.properties)
    : '';

  useEffect(() => {
    if (isSuccessful) {
      setVideoViewed(true);
    }
  }, [isSuccessful, isVideoViewed]);

  const onViewVideoScreeningPress = (
    interaction: UpdateInteractionParams['interaction']
  ) => {
    updateApplicantInteraction({
      applicationId,
      interaction
    });
    openVideoModal();
  };

  return {
    onViewVideoScreeningPress,
    errorMessage: errorText,
    isVideoViewed
  };
};
