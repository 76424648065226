import React, { useContext } from 'react';
import { PasswordConfirmation } from '@common/components/Account/components/PasswordConfirmation';
import { passwordConfirmationRules } from '@common/components/Account/rules/PasswordConfirmation';
import { FieldWrapper } from '../FieldWrapper';
import { FormContext } from '../FormContext';

export const ConfirmPassword = () => {
  const { t } = useContext(FormContext);
  return (
    <FieldWrapper<string>
      name="confirmPassword"
      rules={passwordConfirmationRules(t)}
      render={(props) => {
        return <PasswordConfirmation {...props} />;
      }}
    />
  );
};
