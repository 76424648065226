import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { UpdateInteractionParams } from '@common/hooks/application/interaction';
import { getJobById } from '@store/entities/jobs/selectors';
import { useAppSelector } from '@store/hooks';
import { Badge } from '@components/Badge';
import { Box } from '@components/Box';
import { CardAction } from '@components/Card';
import { VideoIcon } from '@components/Icon';
import { Link } from '@components/Link';
import { useModal } from '@components/Modal';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { VideoScreeningModal } from './components/VideoScreeningModal';
import { useViewVideoScreening } from './useViewVideoScreening';

type ViewVideoScreeningAnswerProps = {
  application: {
    id: string;
    isScreeningVideoViewed?: boolean;
    videoUid: string;
    name: string;
  };
  jobId: string;
};

export const ViewVideoScreeningAction = ({
  application,
  jobId
}: ViewVideoScreeningAnswerProps) => {
  const { id, isScreeningVideoViewed, videoUid, name } = application;
  const { company } = useAppSelector(getJobById(jobId));

  const { t } = useTranslation();
  const { isModalOpen, openModal, closeModal } = useModal();
  const { onViewVideoScreeningPress, errorMessage, isVideoViewed } =
    useViewVideoScreening({
      applicationId: id,
      openVideoModal: openModal
    });

  return (
    <>
      <CardAction>
        <Stack spacing="small" width="full" alignItems="center">
          <Box
            alignItems="center"
            direction={{
              mobile: 'column',
              tablet: 'row',
              desktop: 'row'
            }}
          >
            <Link
              onPress={() => {
                onViewVideoScreeningPress(
                  'viewedScreeningVideo' as UpdateInteractionParams['interaction']
                );
              }}
              align={{
                mobile: 'vertical',
                tablet: 'horizontal',
                desktop: 'horizontal'
              }}
              variant="secondary"
              icon={<VideoIcon variant="outline" />}
            >
              {t('applications.applicationCard.viewVideoScreening')}
            </Link>

            {!isScreeningVideoViewed && !isVideoViewed && (
              <Row
                marginTop={{
                  mobile: 'xsmall',
                  tablet: 'none',
                  desktop: 'none'
                }}
                marginLeft={{
                  mobile: 'none',
                  tablet: 'xsmall',
                  desktop: 'xsmall'
                }}
              >
                <Badge tone="promo">{t('badge.newBadge')}</Badge>
              </Row>
            )}
          </Box>

          {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
        </Stack>
      </CardAction>

      {isModalOpen && (
        <VideoScreeningModal
          testID={`view-video-screening-modal-${id}`}
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          company={company}
          videoUid={videoUid}
          applicationId={id}
          applicantName={name}
        />
      )}
    </>
  );
};
