import { useTheme } from '@theme/DesignSystemProvider/ThemeContext';
import { useResolveToken } from '@theme/tokens/utils/useResolveToken';

export type CalloutTextTone =
  | 'neutral'
  | 'success'
  | 'warning'
  | 'danger'
  | 'info';

export type CalloutTextStyleProps = {
  /** The tone of the call out text
   *  @default neutral
   */
  tone?: CalloutTextTone;
};

export function useCalloutTextStyle({ tone }: CalloutTextStyleProps) {
  const { spacing, calloutText } = useTheme();
  const { resolveToken } = useResolveToken();

  return {
    backgroundColor: resolveToken(calloutText.color, tone),
    padding: resolveToken(spacing, 'medium'),
    flexDirection: 'row'
  } as const;
}
