import React from 'react';
import { useTranslation } from 'react-i18next';
import { WorkExperience } from '@common/types';
import { Box } from '@components/Box';
import { Column } from '@components/Column';
import { Heading } from '@components/Heading';
import { DownArrowIcon, JobsIcon, UpArrowIcon } from '@components/Icon';
import { Link } from '@components/Link';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { WORK_EXPERIENCE_LIMIT, useWorkExperienceDetails } from './hooks';

type Props = {
  testID: string;
};

type WorkExperienceDetailsProps = Props & {
  value: WorkExperience;
};

export const WorkExperienceDetails = ({
  value,
  testID
}: WorkExperienceDetailsProps) => {
  const {
    textLink,
    workExperience,
    showLimitedWorkExperience,
    toggleShowLimitedWorkExperience
  } = useWorkExperienceDetails(value);
  const { t } = useTranslation();

  return (
    <Stack spacing="large" testID={testID}>
      <Row>
        <Heading level="h2">
          {t('applications.applicationCard.workExperience.title')}
        </Heading>
      </Row>
      {workExperience.map((eachWorkExperience, index) => {
        return (
          <Row spacing="small" key={index}>
            <Box marginTop="xxsmall">
              <JobsIcon
                size="small"
                tone="success"
                variant="outline"
                testID={`${testID}-job-icon`}
              />
            </Box>

            <Column spacing="small" width="full" flexShrink={1}>
              <Heading level="h2" testID={`${testID}-job-title`}>
                {eachWorkExperience.jobTitle}
              </Heading>

              <Text testID={`${testID}-business-name`}>
                {eachWorkExperience.businessName}
              </Text>

              <Row spacing="xsmall" alignItems="center">
                <Text size="small" testID={`${testID}-job-duration`}>
                  {eachWorkExperience.duration}
                </Text>

                <Row width="dot" height="dot" borderRadius="full" tone="dark" />

                <Text size="small" testID={`${testID}-job-duration-text`}>
                  {eachWorkExperience.durationText}
                </Text>
              </Row>

              <Text
                tone="neutralLight"
                size="small"
                testID={`${testID}-job-notes`}
              >
                {eachWorkExperience.notes}
              </Text>
            </Column>
          </Row>
        );
      })}

      {value.length > WORK_EXPERIENCE_LIMIT ? (
        <Link
          testID={`${testID}-show-more-and-less-link`}
          onPress={toggleShowLimitedWorkExperience}
          icon={
            showLimitedWorkExperience ? (
              <UpArrowIcon
                variant="outline"
                tone="info"
                size="xlarge"
                testID={`${testID}-arrow-up-icon`}
              />
            ) : (
              <DownArrowIcon
                variant="outline"
                tone="info"
                size="xlarge"
                testID={`${testID}-arrow-up-icon`}
              />
            )
          }
        >
          {textLink}
        </Link>
      ) : null}
    </Stack>
  );
};
