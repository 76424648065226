import { BrandName } from '@seek/je-shared-data/lib/types/brand';

/**
 * Determines whether to show the benefit for priority placement over basic.
 *
 * @param {BrandName} params.brand - The brand name.
 * @returns {boolean} Returns true if the benefit should be shown, otherwise
 * false.
 */
export const showPriorityPlacementOverBasic = ({
  brand
}: {
  brand: BrandName;
}): boolean => {
  if (brand === BrandName.JOBSTREETEXPRESS) return true;
  if (brand === BrandName.JOBSDB) return true;
  if (brand === BrandName.JORA) return true;

  return false;
};
