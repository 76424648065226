import { Braze } from '@src/nativeBridge/actions';

export function requestPushNotificationPermission() {
  Braze.requestPushPermission({
    alert: true,
    sound: true,
    badge: true,
    provisional: false
  });
}
