import React, { useContext } from 'react';
import { JobTitleAutoSuggest as InnerField } from '@common/components/PostJobFormWithAuto/components/jobTitleAutoSuggest/JobTitleAutoSuggest';
import { FieldWrapper } from '../FieldWrapper';
import { FormContext } from '../FormContext';
import { jobTitleAutoSuggestRules } from '../rules';

export default function JobTitleAutoSuggest() {
  const { t } = useContext(FormContext);

  return (
    <FieldWrapper<{ jobTitle: string; role: string }>
      name="jobTitleAuto"
      rules={jobTitleAutoSuggestRules(t)}
      render={(props) => <InnerField {...props} />}
    />
  );
}
