import React from 'react';
import { ViewProps } from 'react-native';
import { Column } from '@components/Column';
import { RadioButton } from '@components/FormElements/RadioButton/RadioButton';
import { composeTestIDs } from '@components/utils';
import { useSingleSelect } from './useSingleSelect';

type Option<T extends string | boolean | number = string> = {
  key: T;
  label: string | React.ReactElement;
};

export type SingleSelectProps<T extends string | boolean | number = string> = {
  /**
   * The value of the radio button which determines if the radio button is
   * checked if the value is set or unchecked if it is undefined
   */
  value: T | undefined;
  /**
   * An array of options to be displayed
   */
  options: Option<T>[];
  /**
   * A callback function invoked when the selected value changes and takes the
   * newly selected value as a parameter
   */
  onChange: (selectedValue: T) => void;
  /**
   * An optional flag to disable any options from being selected
   */
  disabled?: boolean;
  /**
   * An optional, unique identifier to be used for testing purposes
   */
  testID?: ViewProps['testID'];
  /**
   * An optional prop to control the alignment of the radio buttons
   */
  radioButtonAlign?: 'left' | 'right';
};

export const SingleSelect = <T extends string | boolean | number>({
  value,
  testID,
  options,
  onChange,
  disabled = false,
  radioButtonAlign = 'left'
}: SingleSelectProps<T>) => {
  const { updateSelectedKey } = useSingleSelect({
    value,
    onChange
  });

  return (
    <Column spacing="medium" testID={testID}>
      {options.map(({ key, label }) => (
        <RadioButton
          key={`${key}`}
          label={label}
          disabled={disabled}
          checked={value === key}
          onPress={() => updateSelectedKey(key)}
          testID={composeTestIDs(testID, `option-${key}`)}
          radioButtonAlign={radioButtonAlign}
        />
      ))}
    </Column>
  );
};
