import { getUser } from '@store/auth';
import { useAppSelector } from '@store/hooks';

/**
 * Retrieves the current user from the auth state.
 * @returns The current user.
 */
export function useCurrentUser() {
  const { currentUser } = useAppSelector(getUser);
  return currentUser;
}
