import React from 'react';
import { Application } from '@common/types/Application';
import { CardFooter } from '@components/Card';
import { DownloadResumeCardAction } from './components/DownloadResumeCardAction';
import { RefreshCardAction } from './components/RefreshCardAction';
import { ViewCoverLetterCardAction } from './components/ViewCoverLetterCardAction';
import { ViewProfileAction } from './components/ViewProfileAction';
import { ViewTextScreeningAnswerAction } from './components/ViewScreeningAnswerAction';
import { ViewVideoScreeningAction } from './components/ViewVideoScreeningAction';
import { useApplicationCardActions } from './useApplicationCardActions';

type Props = {
  application: Application;
  jobId: string;
  viewProfileActionProps: {
    isProfileExpanded: boolean;
    onViewProfilePressed: () => void;
  };
};

export const ApplicationCardActions = (props: Props) => {
  const {
    application,
    jobId,
    viewProfileActionProps: { isProfileExpanded, onViewProfilePressed }
  } = props;

  const {
    shouldShowCoverLetterAction,
    shouldShowViewProfileAction,
    shouldShowRefreshAction,
    shouldShowDownloadResumeAction,
    shouldShowViewScreeningAnswersAction,
    shouldShowViewVideoScreeningAction,
    shouldBeResponsive
  } = useApplicationCardActions(application);

  return (
    <CardFooter>
      {shouldShowViewScreeningAnswersAction &&
      !shouldShowViewVideoScreeningAction ? (
        <ViewTextScreeningAnswerAction
          application={application}
          jobId={jobId}
        />
      ) : null}

      {shouldShowViewVideoScreeningAction ? (
        <ViewVideoScreeningAction
          application={{
            id: application.id,
            isScreeningVideoViewed: application.isScreeningVideoViewed,
            videoUid: application.videoUid!,
            name: application.name!
          }}
          jobId={jobId}
        />
      ) : null}

      {shouldShowViewProfileAction ? (
        <ViewProfileAction
          testID={`view-profile-action-${application.id}`}
          responsive={shouldBeResponsive}
          isProfileExpanded={isProfileExpanded}
          onViewProfilePressed={onViewProfilePressed}
        />
      ) : null}

      {shouldShowCoverLetterAction ? (
        <ViewCoverLetterCardAction
          application={application}
          responsive={shouldBeResponsive}
          coverLetterMessage={application?.message || ''}
        />
      ) : null}

      {shouldShowRefreshAction ? (
        <RefreshCardAction
          application={application}
          responsive={shouldBeResponsive}
        />
      ) : null}

      {shouldShowDownloadResumeAction ? (
        <DownloadResumeCardAction
          application={application}
          responsive={shouldBeResponsive}
        />
      ) : null}
    </CardFooter>
  );
};
