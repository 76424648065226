import { isComplete as jobInfoFormComplete } from '@caja/Form/forms/infoForm';
import { StepMapping } from '../mapToStep';
import { Step } from '../types';

export default function resolveBusinessForm({
  data,
  options
}: Pick<StepMapping, 'data' | 'options'>): Step {
  const jobInfoIncomplete = !jobInfoFormComplete(data, options?.infoForm);

  if (jobInfoIncomplete) {
    return 'info-form';
  }

  return 'business-form';
}
