import { useRedirectUnauthenticatedUser } from '@common/hooks/useRedirectUnauthenticatedUser';

type RedirectUnauthenticatedUserProps = {
  to?: string;
};

/**
 * @deprecated Use the useRedirectUnauthenticatedUser hook instead.
 */
export const RedirectUnauthenticatedUser = ({
  to
}: RedirectUnauthenticatedUserProps) => {
  useRedirectUnauthenticatedUser({ to });

  return null;
};
