export const colors = {
  green50: '#F2FDF7', // Used as shadows to add depth to brand coloured icons/text
  green100: '#DFF8EB',
  green200: '#BDF0D5',
  green500: '#22C365',
  green600: '#15A449',
  green700: '#0E8136', // Brand
  green800: '#085C22',

  red50: '#FFF1F1',
  red100: '#FFDFDF',
  red200: '#FFBEBE',
  red500: '#FF3333',
  red600: '#E32121', // Used for errors

  yellow50: '#FFFDF2',
  yellow100: '#FFF7E1',
  yellow200: '#FFEEC3',
  yellow500: '#FFBB33',
  yellow600: '#E28D21',

  blue50: '#F2FAFE',
  blue100: '#DEF0FC',
  blue200: '#B9DEF8',
  blue500: '#197FE6',
  blue600: '#0F62C7', // Used for links
  blue700: '#0D3880',

  purple50: '#F8F3FE',
  purple100: '#ECE2FC',
  purple200: '#D8C3F9',
  purple500: '#6E30E8',
  purple600: '#521ECA',

  grey50: '#FAFAFA',
  grey100: '#F0F0F0',
  grey200: '#DEDEDE',
  grey300: '#C1C1C1',
  grey400: '#A4A4A4',
  grey500: '#808080',
  grey600: '#626262',
  grey700: '#494949',
  grey900: '#1C1C1C',

  white: '#FFFFFF',
  black: '#000000',

  transparent: 'rgba(0,0,0,0)',

  blueSeek: '#0D3880' // Seek Brand
};

export type Colors = keyof typeof colors;
