import React from 'react';
import { useTranslation } from 'react-i18next';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { useJobTitleAutoSuggest } from '@common/hooks/featureToggles';
import { useRedirectToJobNotFoundIfError } from '@common/hooks/useRedirectToJobNotFoundIfError';
import { Box } from '@components/Box';
import { Card, CardContent } from '@components/Card';
import { Heading } from '@components/Heading';
import { Stack } from '@components/Stack';
import { EditFormWithAutoTitle } from './EditJobWithAutoTitle';
import { EditFormWithTextTitle } from './EditJobWithTextTitle';
import { useRedirectToJobNotFoundIfJobNotEditable } from './hooks/useRedirectToJobNotFoundIfJobNotEditable';
import { useRedirectToLoginPageIfUnauthenticated } from './hooks/useRedirectToLoginPageIfUnauthenticated';

export const EditJob = () => {
  useRedirectToLoginPageIfUnauthenticated();
  useRedirectToJobNotFoundIfError();
  useRedirectToJobNotFoundIfJobNotEditable();

  const jobTitleAutoSuggestEnabled = useJobTitleAutoSuggest();

  const { t } = useTranslation();

  return (
    <Stack tone="transparent">
      <JoraEmployerHelmet title={t('editJobPage.title')} />
      <Stack spacing="medium" tone="transparent">
        <Box paddingTop="medium" paddingLeft="medium" tone="transparent">
          <Heading level="h1">{t('editJobPage.heading')}</Heading>
        </Box>

        <Card>
          <CardContent>
            <Stack spacing="large">
              {jobTitleAutoSuggestEnabled ? (
                <EditFormWithAutoTitle />
              ) : (
                <EditFormWithTextTitle />
              )}
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </Stack>
  );
};
