import React from 'react';
import { Submit as InnerField } from '@caja/Form/forms/components/buttons';

interface SubmitProps {
  children: string;
  onPress: () => void;
}
export default function Submit({ children, onPress }: SubmitProps) {
  return (
    <InnerField testID="register-action" onPress={onPress}>
      {children}
    </InnerField>
  );
}
