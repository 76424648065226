import React from 'react';
import { useBrandName } from '@common/hooks/localisation';
import { BrandName } from '@seek/je-shared-data/lib/types/brand';
import {
  JobsDBLoginIllustration,
  JobstreetLoginIllustration,
  JoraLoginIllustration
} from '@components/Illustration';

export const ShowLoginIllustration = () => {
  const brand = useBrandName();
  if (brand === BrandName.JOBSDB) {
    return (
      <JobsDBLoginIllustration
        size="large"
        testID="jobsdb-login-illustration"
      />
    );
  }

  if (brand === BrandName.JOBSTREETVN) {
    return (
      <JobstreetLoginIllustration
        size="large"
        testID="jobstreet-login-illustration"
      />
    );
  }

  if (brand === BrandName.JOBSTREETEXPRESS) {
    return null;
  }

  return (
    <JoraLoginIllustration size="large" testID="jora-login-illustration" />
  );
};
