export type UseWarningStyleProps = {
  critical?: boolean;
};

export function useWarningStyle({ critical }: UseWarningStyleProps) {
  return {
    iconStyle: {
      size: 'small',
      tone: critical ? 'danger' : 'neutral',
      variant: critical ? 'fill' : 'outline'
    },
    textStyle: {
      size: 'small',
      tone: critical ? 'danger' : 'neutralLight',
      weight: critical ? 'regular' : 'light'
    }
  } as const;
}
