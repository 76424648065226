import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Button';
import { Heading } from '@components/Heading';
import { TwoPeopleHighFiving } from '@components/Illustration';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';

type FormProps = {
  onSubmit: () => void;
};

export default function VerifySuccessForm({ onSubmit }: FormProps) {
  const { t } = useTranslation();
  return (
    <>
      <Stack alignItems="center" spacing="medium">
        <TwoPeopleHighFiving size="large" testID="two-people-high-fiving" />
        <Heading level="h1" weight="regular">
          {t('verifyEmailSuccessPage.description.emailHasBeenVerified')}
        </Heading>

        <Text>{t('verifyEmailSuccessPage.description.mayNowProceed')}</Text>
      </Stack>

      <Button onPress={onSubmit}>{t('common.action.continue')}</Button>
    </>
  );
}
