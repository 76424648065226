import React from 'react';
import { Trans as I18nextTrans, useTranslation } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { openExternalURL } from '@common/helpers/externalConnection';
import { useBrandName } from '@common/hooks/localisation';
import { LINK, useCandidateLink } from '@common/hooks/useCandidateLink';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';
import { Box } from '@components/Box';
import { CalloutText } from '@components/CalloutText';
import { AutoSuggest, Field } from '@components/FormElements';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { TextLink } from '@components/TextLink';
import { useJobTitleAutoSuggest, useTrackLink } from './hooks';
import { JobTitle } from './types';

type JobTitleProps = {
  value: JobTitle;
  onChange: (value: JobTitle) => void;
  onBlur: () => void;
  error?: string;
};

export const JobTitleAutoSuggest = (props: JobTitleProps) => {
  const { t } = useTranslation();
  const { suggestions, onFetchSuggestions, toAutoSuggestValue } =
    useJobTitleAutoSuggest();
  const { error, onChange, onBlur, value } = props;
  const brandname = useBrandName();
  const { getCandidateLink } = useCandidateLink();
  const { trackBrandLinkoutClick, trackContactUsLinkoutClick } = useTrackLink();
  const { mainEmployerBrandLink } = useAppSelector(getLocalisation);

  return (
    <Stack spacing="small" zIndex={10}>
      <Field
        zIndex={1}
        label={t('postJobForm.fieldLabel.jobTitle')}
        message={
          error && (
            <Box marginTop="xxsmall">
              <ErrorMessage>{error}</ErrorMessage>
            </Box>
          )
        }
      >
        <AutoSuggest
          suggestions={suggestions}
          onFetchSuggestions={onFetchSuggestions}
          onChange={(suggestedValue) => {
            onChange(suggestedValue?.data as JobTitle);
          }}
          noResultsText={t(
            'postJobForm.explanationText.jobTitleAutoSuggest.noResults'
          )}
          onBlur={onBlur}
          value={toAutoSuggestValue(value)}
          testID="job-title-auto-suggest"
        />
      </Field>

      <CalloutText tone="info">
        <Stack tone="transparent" flexShrink={1}>
          <Text>
            <I18nextTrans
              i18nKey={`postJobForm.explanationText.jobTitleAutoSuggest.chooseBestTitleForJob.title.${brandname}`}
              parent={Text}
              components={[
                <TextLink
                  onPress={() => {
                    trackContactUsLinkoutClick();
                    openExternalURL(getCandidateLink(LINK.CONTACT_PAGE));
                  }}
                >
                  0
                </TextLink>,
                <TextLink
                  onPress={() => {
                    trackBrandLinkoutClick();
                    openExternalURL(mainEmployerBrandLink);
                  }}
                >
                  1
                </TextLink>
              ]}
            />
          </Text>
        </Stack>
      </CalloutText>
    </Stack>
  );
};
