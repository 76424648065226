import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash.omit';

import styles from './Dropdown.scss';
import * as constants from '../../../constants/general';
import Div from '../../Div/Div';
import Label from '../../Label/Label';
import ReduxFormField from '../ReduxFormField/ReduxFormField';
import DropdownIcon from '../../Icons/DropdownIcon';

@ReduxFormField
export default class Dropdown extends Component {
  static propTypes = {
    'data-test-key': PropTypes.string,
    error: PropTypes.any,
    touched: PropTypes.bool,
    fieldId: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    hideLabel: PropTypes.bool,
    extraTopMargin: PropTypes.bool,
    shortField: PropTypes.bool,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired
    })),
    initialValue: PropTypes.string
  };

  onChange(event) {
    const { onChange } = this.props;

    if (onChange) {
      onChange(event.target.value);
    }
  }

  render() {
    const { fieldId, initialValue, extraTopMargin, shortField, touched, error, options, label, className, 'data-test-key': dataTestKey, hideLabel } = this.props;

    return (
      <Div>
        {hideLabel ? null : <Label labelFor={fieldId}>{label}</Label>}
        <span className={styles.container}>
          <div className={styles.icon}>
            <DropdownIcon />
          </div>
          <select
            data-test-key={`dropdown-${dataTestKey || fieldId}`}
            defaultValue={initialValue}
            aria-label={label}
            id={fieldId}
            className={classNames(
              touched && error ? styles.selectInvalid : styles.select,
              !shortField && styles.fullWidth,
              className,
              {
                [styles.extraTopMargin]: extraTopMargin
              }
            )}
            onChange={this.onChange.bind(this)}
            {...omit(this.props, 'options', 'className', 'onChange', 'extraTopMargin', 'shortField', 'label', 'hideLabel', 'fieldId', 'data-test-key', 'tReady', constants.REDUX_FORM_PROPS)}>
            {
              options.map(option =>
                <option key={option.key}
                  value={option.value}>{option.label}</option>
              )
            }
          </select>
        </span>
      </Div>
    );
  }
}
