import {
  BrandName,
  SupportedCountryCode
} from '@seek/je-shared-data/lib/types/brand';

interface TwentyEightDays {
  brand: BrandName;
  country: SupportedCountryCode;
}
/**
 * Determines whether to show the benefit for job featured for x days promo.
 *
 * @param {BrandName} params.brand - The brand name.
 * @param {SupportedCountryCode} params.country - The country code.
 * @returns {boolean} Returns true if the benefit should be shown, otherwise
 * false.
 */
export const showJobFeaturedForXDaysPromo = ({
  brand,
  country
}: TwentyEightDays): boolean => {
  if (brand !== BrandName.JOBSTREETEXPRESS) return false;
  if (
    country === SupportedCountryCode.Singapore ||
    country === SupportedCountryCode.Indonesia
  ) {
    return false;
  }

  return false;
};
