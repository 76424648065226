import { objectIsNotEmpty } from '@src/lib/func/objectIsNotEmpty';
import { getDraftBusiness, getDraftJob } from '@store/draft';
import { useAppSelector } from '@store/hooks';

export function useDraftData() {
  const draftJob = useAppSelector(getDraftJob);
  const draftBusiness = useAppSelector(getDraftBusiness);

  const hasSignUpDraftData = () =>
    objectIsNotEmpty(draftJob) && objectIsNotEmpty(draftBusiness);

  return {
    hasSignUpDraftData: hasSignUpDraftData()
  };
}
