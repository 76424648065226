import { isPermittedToShowApplicationInformation } from '@common/helpers/jobs';
import { ApplicationInformation } from '@common/hooks/useAppQuery/queries/useGetApplications';
import { JOB_STATUS } from '@seek/je-shared-data';
import { noApplications } from './anyApplications';
import { available } from './available';

/**
 * Checks if the expiry is relevant for the given application information and status.
 * @param applicationInformation - The application information.
 * @param status - The job status.
 * @returns A boolean indicating whether the expiry is relevant.
 */
export const expiryIsRelevant = (
  applicationInformation: ApplicationInformation | undefined,
  status: JOB_STATUS | undefined
) => {
  if (noApplications(applicationInformation)) return false;
  if (!isPermittedToShowApplicationInformation(status)) return false;
  if (!available(applicationInformation)) return true;

  return status === JOB_STATUS.EXPIRED;
};
