import { useCallback } from 'react';
import { useAppDispatch } from '@store/hooks';
import { refresh as refreshAction } from '@store/navigation/actions';

export function useRefreshCardAction() {
  const dispatch = useAppDispatch();
  const refresh = useCallback(() => {
    dispatch(refreshAction());
  }, [dispatch]);

  return {
    refresh
  };
}
