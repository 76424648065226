export const radius = {
  none: 0,
  xsmall: 4,
  small: 8,
  medium: 16,
  large: 24,
  xlarge: 32,
  full: 9999
} as const;

export type Radius = keyof typeof radius;
