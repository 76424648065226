import React, { useContext } from 'react';
import { EmailVerificationCode as InnerField } from '@common/componentsV2/Account/components/EmailVerificationCode';
import { FieldWrapper } from '../FieldWrapper';
import { FormContext } from '../FormContext';
import { emailVerificationCode } from '../rules';

export const EmailVerificationCode = () => {
  const { t } = useContext(FormContext);
  return (
    <FieldWrapper<string>
      name="emailVerificationCode"
      rules={emailVerificationCode(t)}
      render={(props) => {
        const mergedProps = { ...props, t };
        return <InnerField {...mergedProps} />;
      }}
    />
  );
};
