import { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './ValidationMessage.scss';
import Link from '../../Link/Link';
import ErrorIcon from '../../Icons/ErrorIcon';

export default class ValidationMessage extends Component {
  static propTypes = {
    field: PropTypes.shape({
      touched: PropTypes.bool.isRequired,
      error: PropTypes.any
    }),
    messages: PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          helpMessage: PropTypes.shape({
            preText: PropTypes.string,
            postText: PropTypes.string,
            link: PropTypes.shape({
              text: PropTypes.string.isRequired,
              to: PropTypes.string.isRequired,
              target: PropTypes.string
            })
          })
        })
      ])
    ),
    extraTopMargin: PropTypes.bool,
    testKeyPrefix: PropTypes.string
  };

  renderHelpMessage(helpMessage, testKeyPrefix) {
    const helpMessagePreText = helpMessage.preText || '';
    const helpMessagePostText = helpMessage.postText || '';
    const hasHelpMessageLink = typeof helpMessage.link === 'object';

    return (
      <div>
        <span className={styles.errorHelpMessage}
          data-test-key={testKeyPrefix ? `${testKeyPrefix}-help-message` : 'help-message'}>
          <span>{helpMessagePreText}</span>
          {
            hasHelpMessageLink &&
            <Link
              to={helpMessage.link.to}
              target={helpMessage.link.target}>
              {helpMessage.link.text}
            </Link>
          }
          <span>{helpMessagePostText}</span>
        </span>
      </div>
    );
  }

  render() {
    const { field: { touched, error }, extraTopMargin, messages = {}, testKeyPrefix } = this.props;

    const errorMessage = messages[error] || {};
    const { text, helpMessage } = errorMessage;
    const errorText = text || messages[error];

    if (!touched || !error) {
      return false;
    }

    return (
      <div>
        <div className={extraTopMargin ? styles.extraTopMargin : styles.root}>
          <ErrorIcon theme="dangerFilled" className={styles.icon} />
          <span className={styles.errorText}
            data-test-key={testKeyPrefix ? `${testKeyPrefix}-validation-message` : 'validation-message'}>
            {errorText || error}
          </span>
        </div>
        {helpMessage && this.renderHelpMessage(helpMessage, testKeyPrefix)}
      </div>
    );
  }
}