import React, { useContext } from 'react';
import { FormContext } from '../../FormContext';
import { FieldWrapper } from '../FieldWrapper';
import { BusinessAdditionalInformation as InnerField } from './BusinessAdditionalInformation';
import { useBusinessAdditionalInformationRules } from './useBusinessAdditionalInformationRules';

type Props = { label: string };

export const BusinessAdditionalInformation = (props: Props) => {
  const { t } = useContext(FormContext);

  return (
    <FieldWrapper<string>
      name="additionalInformation"
      rules={useBusinessAdditionalInformationRules(t)}
      render={(renderProps) => {
        const propsToPass = {
          ...renderProps,
          ...props
        };

        return <InnerField {...propsToPass} />;
      }}
    />
  );
};
