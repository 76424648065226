import { useCallback } from 'react';
import REQUEST_KEY from '@common/constants/requestKeys';
import { getRequestState } from '@store/app';
import { loadBusinesses } from '@store/entities/businesses/actions';
import { getBusinessById } from '@store/entities/businesses/selectors';
import { useAppDispatch, useAppSelector } from '@store/hooks';

export const useGetBusinessData = (businessId: string) => {
  const dispatch = useAppDispatch();

  const business = useAppSelector(getBusinessById(businessId));

  const loadBusinessError = useAppSelector(
    getRequestState(REQUEST_KEY.BUSINESS.LIST)
  ).error;

  const getBusinessData = useCallback(() => {
    if (businessId) {
      dispatch(loadBusinesses());
    }
  }, [businessId, dispatch]);

  return {
    getBusinessData,
    hasLoadBusinessError: !!loadBusinessError,
    business
  };
};
