import { getPastDateTimeByDays } from '@common/helpers/dateTime';
import { requestPushNotificationPermission } from '@common/helpers/pushNotifications';
import { inNativeApp } from '@src/joraEmployer/whereAmIRunning';
import { useAppDispatch } from '@store/hooks';
import {
  trackCancelPushNotifications,
  trackEnablePushNotifications
} from '@store/platform/actions';
import { useModal } from '@components/Modal';
import { PushPermissionModalStore } from './helpers/pushPermissionModalStore';

const isPushPermissionRequired = () => inNativeApp();

async function openPushPermissionModal(openModal) {
  if (isPushPermissionRequired()) {
    const pushPermissionData = await PushPermissionModalStore().read();

    if (
      pushPermissionData &&
      !pushPermissionData.isEnabled &&
      pushPermissionData.timestamp < getPastDateTimeByDays()
    ) {
      await PushPermissionModalStore().clear();
    }

    if (!pushPermissionData) {
      openModal();
    }
  }
}

export function usePushPermissionModal() {
  const { closeModal, openModal, isModalOpen } = useModal();
  const dispatch = useAppDispatch();

  const onRequestClose = async (isEnabled) => {
    closeModal();
    await PushPermissionModalStore().save({
      timestamp: new Date().toISOString(),
      isEnabled
    });
  };

  openPushPermissionModal(openModal);

  return {
    enablePushNotification: () => {
      onRequestClose(true);
      dispatch(trackEnablePushNotifications());
      requestPushNotificationPermission();
    },
    cancelPushNotification: () => {
      onRequestClose(false);
      dispatch(trackCancelPushNotifications());
    },
    isModalOpen: isModalOpen
  };
}
