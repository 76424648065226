import React from 'react';
import { Circle, Defs, G, LinearGradient, Mask, Path, Rect, Stop } from 'svgs';
import { createIllustration } from '../createIllustration';

const vectorComponent = () => (
  <>
    <Path
      fill="#DEF0FC"
      d="M105.98 107.12c-12.456.121-21.582.194-41.203.194-21.028 0-39.836-.085-52.362-.22-.235-.256-.408-.635-.702-.78-2.389-1.175-7.204-6.332-7.606-9.702-2.32-19.436 6.796-14.933.819-48.974 0 0-5.438-33.648 31.265-32.124 5.865.243 11.792 2.51 16.81 6.215 5.018 3.707 10.165 8.405 16.013 8.983 5.01.494 9.495-4.325 13.944-7.173 6.656-4.262 18.503-5.626 23.154 4.446 21.694 42.022-.131 79.134-.131 79.134z"
    />
    <Path
      fill="#5CC78B"
      d="M86.451 82.656c-.15.842 5.582 1.825 8.466 2.21.797-1.033-.836-4.403-1.696-5.924-.86-1.522-1.32-.83-1.32.135 0 1.364-1.02 0-1.28-.516-.26-.515-.92-2.752-.74-4.206.18-1.455-1.02-2.775-1.799-.358-.78 2.416-1.44-1.97-1.631-2.82-.191-.85-1.368-4.788-3.528-2.237-2.16 2.55 1.22 6.131 1.458 6.265.24.135.082.82-.598.314-.68-.506-1.565-.232-1.941.505-.377.737-.189 2.105 2.54 3.053s4.233 2.316 3.856 2.632-1.599-.106-1.787.947z"
    />
    <Path
      fill="#2FAC66"
      d="M93.067 73.528c-.88 1.342 3.088 5.545 5.027 7.967 1.208-.188 1.694-4.122 3.677-6.216 2.822-2.981 1.017-5.69-.817-2.892-.721 1.1-1.154-.161-1.045-.74.109-.58 1.634-5.654 2.234-6.96.704-1.532.72-2.53-1.291-1.292-2.012 1.238-.79-4.297-.431-5.077.358-.78 1.895-4.3-1.233-3.98-3.127.32-2.39 7.62-2.405 7.92-.156 3.13-1.88 3.463-2.093 2.581-.212-.882-.154-1.503-.872-1.228-.717.275-1.49 1.895 0 4.622 1.49 2.728 2.124 5.599 1.655 5.558-.47-.041-1.839-1.129-2.406-.263z"
    />
    <Path
      fill="#5CC78B"
      d="M105.47 85.346c-2.366-.585-4.241.962-4.883 1.809-.653-1.54.756-5.656 2.166-8.465s2.717-1.501 2.476-.77c-.241.731-.138 1.424 1.066 1 1.203-.423 2.029-3.655 5.673-3.501 3.645.154-3.163 6.503-3.851 6.657s-2.647 1.577-.963 1.346c1.685-.23 1.273 2.655-1.684 1.924z"
    />
    <Path
      stroke="#5CC78B"
      strokeWidth={0.231}
      d="M99.265 58.12c-3.425 9.263-.427 27.654-.785 30.024"
    />
    <Path
      fill="#FFBEBE"
      d="M102.99 88.082h-9.947a.463.463 0 0 0-.463.463v1.519c0 .16.13.292.292.292.147 0 .27.109.29.255l.63 4.993c.03.231.224.405.457.405h7.526a.461.461 0 0 0 .459-.414l.527-4.93a.346.346 0 0 1 .345-.31.347.347 0 0 0 .347-.346v-1.464a.463.463 0 0 0-.463-.463z"
    />
    <Path
      stroke="#2FAC66"
      strokeWidth={0.231}
      d="M110.28 77.382c-3.13 2.13-9.498 7.234-9.938 10.62M84.392 71.11c.344 1.885 1.877 6.28 5.261 8.773 4.23 3.117 6.327 4.964 6.636 8.042"
    />
    <Path
      fill="#FDFEFC"
      d="M93.135 96.454h9.448a1.81 1.81 0 0 1 0 3.619h-9.448v-3.619zM90.415 101.03h14.353a1.81 1.81 0 0 1 0 3.618H90.415v-3.618z"
    />
    <Path
      fill="#fb3"
      fillRule="evenodd"
      d="M103.06 96.009H93.044a.463.463 0 0 0-.463.463v3.577c0 .256.207.463.463.463h10.016a2.252 2.252 0 1 0 0-4.503zm-1.031.446h-8.985a.463.463 0 0 0-.463.462v2.693c0 .256.207.463.463.463h8.985a1.81 1.81 0 0 0 0-3.618z"
      clipRule="evenodd"
    />
    <Path
      fill="#955EEF"
      fillRule="evenodd"
      d="M105.65 100.58H90.086a.463.463 0 0 0-.463.463v3.577c0 .256.207.463.463.463h15.564a2.251 2.251 0 0 0 0-4.503zm-1.674.445h-13.89a.463.463 0 0 0-.463.463v2.693c0 .255.207.463.463.463h13.89a1.81 1.81 0 0 0 0-3.619z"
      clipRule="evenodd"
    />
    <Path
      stroke="#7FC1F4"
      strokeDasharray="3.26 3.26"
      strokeWidth={0.326}
      d="M40.817 21.987c2.324 4.636 1.426 19.004-12.374 13.727-19.141-7.32-24.714 10.318-13.34 17.788 17.09 11.223 15.832 47.932 2.706 29.389M109.42 34.223c-4.968-1.485-18.963 1.89-11.373 14.566 10.527 17.582-5.877 26.128-15.205 16.222-14.017-14.885-49.951-7.281-29.413 2.43"
    />
    <Path
      fill="#fff"
      stroke="#fff"
      strokeWidth={0.254}
      d="M79.184 100.96c-4.662-.602-10.589-6.767-12.97-9.775l8.459-5.827 4.511 4.136 9.21-11.091h7.258c-3.007 7.143-11.807 23.158-16.468 22.557z"
    />
    <Path
      fill="#1B1C1C"
      d="M34.541 58.449c-1.322.11-2.57-.849-3.599-1.62-2.276-1.62-3.634-4.084-3.671-6.883-.037-4.12 1.946-8.314 5.471-10.522 3.379-2.097 7.454-2.063 11.126-.736 1.983.736 4.149 1.397 6.279 1.362.697 0 1.505-.075 2.13-.444.55-.33.624-.698.844-1.251.183-.478.514-.182.697.11.257.368.44.81.588 1.251a8.975 8.975 0 0 1 .477 4.82c-.404 2.098-1.689 4.123-3.452 5.337-1.688 1.177-3.524 2.025-5.36 2.871-1.91.846-3.71 1.913-5.582 2.869-.33 0-.991.515-1.322.663-.477.259-.991.516-1.468.736-.845.444-1.653 1.07-2.57 1.362-.22.037-.404.075-.588.075z"
    />
    <Path fill="#fff" d="M41.789 63.948h-3.671v-4.856h3.671v4.856z" />
    <Path
      fill="#fff"
      d="M40.269 59.625c-4.806 0-8.702-3.905-8.702-8.72 0-4.818 3.896-8.724 8.702-8.724s8.702 3.905 8.702 8.724c0 4.815-3.896 8.72-8.702 8.72z"
    />
    <Path
      fill="#fff"
      d="M31.567 55.134a2.353 2.353 0 0 1-2.35-2.356 2.35 2.35 0 1 1 4.7 0 2.353 2.353 0 0 1-2.35 2.356z"
    />
    <Path
      fill="#0E8136"
      d="M10.792 95.539c.076-1.519 1.898-2.203 1.898-2.203s-.228-7.747 2.807-14.583c3.037-6.836 9.942-13.292 16.545-16.255l.292-.128c1.42.793 3.715 1.307 6.307 1.307 4.311 0 7.806-1.423 7.806-3.18 0-.089-.01-.178-.028-.265 4.191.754 13.719 4.056 20.536 12.064 6.717 7.893 5.989 8.05 5.924 8.052.047 0 1.167.02 1.539.987.38.988.127 1.443.127 1.443s1.19 1.24.632 2.558c-.41.971-1.366 1.772-1.366 1.772l-7.69 4.152c-1.352.677-3.902-.06-4.708-.32-.655 1.205-1.44 1.712-1.44 1.712l-35.796-.836-1.517 2.43s.278.38.05 1.14l-.227.76-9.487 3.798s-.835-.456-.683-1.747c-.003.001-1.596-1.138-1.52-2.658z"
    />
    <Path
      fill="#fff"
      d="M38.64 63.679c-2.592 0-4.888-.516-6.307-1.307 6.529-2.815 13.787-2.189 13.787-2.189l.297.051c.017.087.028.176.028.265.001 1.757-3.494 3.18-7.805 3.18z"
    />
    <Path
      fill="#fff"
      stroke="#000"
      strokeWidth={0.254}
      d="M33.92 65.791c-.01-.008-.018-.01-.026-.01s-.017.004-.025.012c-.007.008-.007.014-.007.016s0 .008.01.016l-.084.095.083-.096c1.665 1.436 3.827 2.068 5.92 1.953 2.093-.115 4.174-.98 5.705-2.593l.092.087-.092-.087a.032.032 0 0 0 .007-.01v-.005s0-.006-.01-.014a.036.036 0 0 0-.025-.01.038.038 0 0 0-.025.013l-.092-.088.092.088c-3.003 3.165-8.191 3.45-11.523.633z"
    />
    <Path
      fill="#0E8136"
      d="M59.971 92.653s-.152 1.063-.19 1.48c-.038.418-.34 1.292-.34 1.292s.492 4.71.53 6.266c.038 1.557 0 3.57 0 3.57H22.302s1.62-11.064 1.822-11.722c0 0 .152-.912.05-1.722-.02-.165-.03-.356-.03-.356s9.859.103 16.74.356c6.88.254 19.086.836 19.086.836z"
    />
    <Path
      fill="#FFBEBE"
      d="M43.938 100.64c.314 0 .944-.025 1.624-.191.875-.213 1.604-.816 2.056-1.695.489-.953.574-2.09.22-2.968-.299-.74-1.151-1.922-3.494-1.505l.2 1.138c1.774-.316 2.112.524 2.223.8.227.567.158 1.355-.176 2.008-.171.334-.568.92-1.303 1.1-.774.188-1.489.154-1.496.154l-.06 1.154c.008.001.082.005.206.005z"
    />
    <Path
      fill="#FFBEBE"
      d="M35.211 105.26h9.288a.24.24 0 0 0 .241-.229l.715-13.909a.242.242 0 0 0-.241-.254H34.497a.242.242 0 0 0-.241.254l.714 13.909a.242.242 0 0 0 .241.229z"
    />
    <Path
      fill="#fff"
      d="M12.733 101.47a8.326 8.326 0 0 1-.006-1.144c.075-1.405.568-2.507 3.036-3.912 2.467-1.405 4.781-1.14 6.792-.608 0 0 1.638.548 2.96.912 1.517.417 3.605.873 4.592.873.722 0 2.036-.53 2.795-.911.72-.36 2.302-.874 3.82-.532 1.518.342.52 1.735-.126 1.849-.645.114-1.48.392-1.48.392s2.429.76 3.378 1.367c.949.607 2.569 1.824 2.618 2.469.076.987-1.201 3.038-2.074 3.038-.873 0-19.593.608-22.971-.113a5.25 5.25 0 0 1-1.403-.5v-3.179h-1.931v-.001z"
    />
    <Path
      fill="#fff"
      d="M12.733 101.47h1.93v3.178c-1.432-.774-1.838-2.073-1.93-3.178z"
    />
    <Path
      fill="#FFF1F1"
      fillRule="evenodd"
      d="M38.34 77.167c-1.99.58-3.809 1.941-4.297 3.664-.516 1.825-.355 3.055.254 3.986.62.95 1.748 1.656 3.33 2.333 2.451 1.05 3.229 2.503 3.284 3.345.067-.086.148-.199.242-.346.223-.347.505-.856.856-1.578.414-.85.46-1.543.33-2.13-.133-.595-.453-1.11-.825-1.594a18.013 18.013 0 0 0-.568-.694 7.299 7.299 0 0 1-.512-.67c-.29-.447-.484-.968-.194-1.527.257-.495.736-.822 1.2-1.076.208-.113.427-.22.635-.32l.083-.04c.236-.115.455-.224.652-.34.401-.238.637-.467.713-.74.075-.268.023-.684-.427-1.362-.407-.612-1.083-.985-1.928-1.133-.847-.149-1.842-.065-2.828.222z"
      clipRule="evenodd"
    />
    <Path
      stroke="#000"
      strokeWidth={0.507}
      d="M54.818 76.635c1.565 3.837 5.21 12.418 6.663 14.235M28.164 76.786c1.565 3.836-4.634 13.027-3.18 14.844"
    />
    <Path
      fill="#87C7FF"
      d="M72.721 55.133h21.314v22.056c0 1.436-.34 5.257-3.41 5.319H69.472a3.884 3.884 0 0 1-.16 0c-2.643.062-3.41-3.553-3.41-3.553h6.747c.08-.696.073-1.34.073-1.766V55.133z"
    />
    <Mask
      id="a"
      width={30}
      height={28}
      x={65}
      y={55}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha'
      }}
    >
      <Path
        fill="#D9ECFC"
        d="M72.721 55.134h21.314V77.19c0 1.436-.34 5.257-3.41 5.32H69.472a4.272 4.272 0 0 1-.16 0c-2.643.06-3.41-3.554-3.41-3.554h6.747c.08-.696.073-1.34.073-1.766V55.134z"
      />
    </Mask>
    <G mask="url(#a)">
      <Path
        fill="#D9ECFC"
        d="M51.072 55.133h21.537v22.056c0 1.435-.344 5.257-3.446 5.319H47.787a3.962 3.962 0 0 1-.162 0c-2.67.061-3.446-3.553-3.446-3.553h6.819c.08-.696.073-1.341.073-1.766V55.133z"
      />
    </G>
    <Path
      fill="#fff"
      stroke="#fff"
      strokeWidth={0.219}
      d="M85.861 70.834c-.423-3.881 5.353-2.348 8.088-.731 1.058.586 1.88 3.554 2.057 6.112.176 2.558-1.412 3.352-2.47 2.911-.847-.353-1.412-2.235-1.059-2.911-2.029-.177-6.193-1.5-6.616-5.381z"
    />
    <Path fill="#197FE6" d="M5.238 105.26h110.37v3.275H5.238z" />
    <Rect
      width={26.905}
      height={36.198}
      x={57.791}
      y={1.861}
      fill="#FFDFDF"
      rx={1.958}
      transform="rotate(-3.854 57.791 1.861)"
    />
    <Path
      fill="#f88"
      d="M57.926 3.857a2 2 0 0 1 1.861-2.13L82.64.187a2 2 0 0 1 2.13 1.861l.214 3.172L58.14 7.028l-.214-3.171z"
    />
    <G fill="#f88" opacity={0.5}>
      <Rect
        width={20.384}
        height={2.251}
        x={61.996}
        y={16.095}
        rx={0.618}
        transform="rotate(-3.854 61.996 16.095)"
      />
      <Rect
        width={15.63}
        height={2.251}
        x={62.233}
        y={19.607}
        rx={0.618}
        transform="rotate(-3.854 62.233 19.607)"
      />
      <Rect
        width={8.985}
        height={2.251}
        x={65.806}
        y={23.35}
        rx={0.618}
        transform="rotate(-3.854 65.806 23.35)"
      />
      <Rect
        width={8.985}
        height={2.251}
        x={66.844}
        y={10.328}
        rx={0.618}
        transform="rotate(-3.854 66.844 10.328)"
      />
      <Rect
        width={8.985}
        height={2.251}
        x={66.075}
        y={27.356}
        rx={0.618}
        transform="rotate(-3.854 66.075 27.356)"
      />
      <Rect
        width={2.251}
        height={2.251}
        x={62.5}
        y={23.573}
        rx={1.125}
        transform="rotate(-3.854 62.5 23.573)"
      />
      <Rect
        width={2.251}
        height={2.251}
        x={62.77}
        y={27.578}
        rx={1.125}
        transform="rotate(-3.854 62.77 27.578)"
      />
      <Rect
        width={3.752}
        height={3.752}
        x={61.572}
        y={9.794}
        rx={0.618}
        transform="rotate(-3.854 61.572 9.794)"
      />
    </G>
    <Circle
      cx={76.033}
      cy={3.165}
      r={0.758}
      fill="#f33"
      transform="rotate(-3.854 76.033 3.165)"
    />
    <Circle
      cx={78.782}
      cy={2.98}
      r={0.758}
      fill="#f33"
      transform="rotate(-3.854 78.782 2.98)"
    />
    <Circle
      cx={81.53}
      cy={2.795}
      r={0.758}
      fill="#f33"
      transform="rotate(-3.854 81.53 2.795)"
    />
    <Path
      fill="#fb3"
      d="m78.639 27.457-1.12-2.552-2.762-.379 2.08-1.854-.492-2.743 2.407 1.406 2.456-1.317-.593 2.723 2.01 1.93-2.772.277-1.214 2.509z"
    />
    <Rect
      width={26.905}
      height={36.198}
      x={76.433}
      y={0.236}
      fill="#FFEEC3"
      rx={1.958}
      transform="rotate(14.593 76.433 .236)"
    />
    <Path
      fill="#fb3"
      d="M75.929 2.171A2 2 0 0 1 78.369.74l22.165 5.77a2 2 0 0 1 1.432 2.44l-.8 3.076-26.038-6.779.801-3.076z"
    />
    <G fill="#fb3" opacity={0.5}>
      <Rect
        width={20.384}
        height={2.251}
        x={75.918}
        y={15.068}
        rx={0.618}
        transform="rotate(14.593 75.918 15.068)"
      />
      <Rect
        width={15.63}
        height={2.251}
        x={75.031}
        y={18.475}
        rx={0.618}
        transform="rotate(14.593 75.031 18.475)"
      />
      <Rect
        width={8.985}
        height={2.251}
        x={77.236}
        y={23.156}
        rx={0.618}
        transform="rotate(14.593 77.236 23.156)"
      />
      <Rect
        width={8.985}
        height={2.251}
        x={82.342}
        y={11.132}
        rx={0.618}
        transform="rotate(14.593 82.342 11.132)"
      />
      <Rect
        width={8.985}
        height={2.251}
        x={76.224}
        y={27.042}
        rx={0.618}
        transform="rotate(14.593 76.224 27.042)"
      />
      <Rect
        width={2.251}
        height={2.251}
        x={74.03}
        y={22.321}
        rx={1.125}
        transform="rotate(14.593 74.03 22.321)"
      />
      <Rect
        width={2.251}
        height={2.251}
        x={73.018}
        y={26.207}
        rx={1.125}
        transform="rotate(14.593 73.018 26.207)"
      />
      <Rect
        width={3.752}
        height={3.752}
        x={77.509}
        y={8.958}
        rx={0.618}
        transform="rotate(14.593 77.509 8.958)"
      />
    </G>
    <Circle
      cx={93.325}
      cy={7.245}
      r={0.758}
      fill="#9C590C"
      transform="rotate(14.593 93.325 7.245)"
    />
    <Circle
      cx={95.991}
      cy={7.94}
      r={0.758}
      fill="#9C590C"
      transform="rotate(14.593 95.991 7.94)"
    />
    <Circle
      cx={98.657}
      cy={8.634}
      r={0.758}
      fill="#9C590C"
      transform="rotate(14.593 98.657 8.634)"
    />
    <Rect
      width={26.905}
      height={36.198}
      x={90.795}
      y={4.508}
      fill="#ECE2FC"
      rx={1.958}
      transform="rotate(27.157 90.795 4.508)"
    />
    <Path
      fill="#955EEF"
      d="M89.882 6.288a2 2 0 0 1 2.692-.867l20.38 10.454a2 2 0 0 1 .867 2.692l-1.45 2.829-23.94-12.28 1.451-2.829z"
    />
    <G fill="#D8C3F9" opacity={0.5}>
      <Rect
        width={20.384}
        height={2.251}
        x={87.065}
        y={18.874}
        rx={0.618}
        transform="rotate(27.157 87.065 18.874)"
      />
      <Rect
        width={15.63}
        height={2.251}
        x={85.459}
        y={22.006}
        rx={0.618}
        transform="rotate(27.157 85.459 22.006)"
      />
      <Rect
        width={8.985}
        height={2.251}
        x={86.592}
        y={27.055}
        rx={0.618}
        transform="rotate(27.157 86.592 27.055)"
      />
      <Rect
        width={8.985}
        height={2.251}
        x={94.192}
        y={16.429}
        rx={0.618}
        transform="rotate(27.157 94.192 16.429)"
      />
      <Rect
        width={8.985}
        height={2.251}
        x={84.76}
        y={30.627}
        rx={0.618}
        transform="rotate(27.157 84.76 30.627)"
      />
      <Rect
        width={2.251}
        height={2.251}
        x={83.644}
        y={25.542}
        rx={1.125}
        transform="rotate(27.157 83.644 25.542)"
      />
      <Rect
        width={2.251}
        height={2.251}
        x={81.812}
        y={29.114}
        rx={1.125}
        transform="rotate(27.157 81.812 29.114)"
      />
      <Rect
        width={3.752}
        height={3.752}
        x={89.948}
        y={13.255}
        rx={0.618}
        transform="rotate(27.157 89.948 13.255)"
      />
    </G>
    <Circle
      cx={105.76}
      cy={15.024}
      r={0.758}
      fill="#521ECA"
      transform="rotate(27.157 105.76 15.024)"
    />
    <Circle
      cx={108.21}
      cy={16.281}
      r={0.758}
      fill="#521ECA"
      transform="rotate(27.157 108.21 16.281)"
    />
    <Circle
      cx={110.66}
      cy={17.539}
      r={0.758}
      fill="#521ECA"
      transform="rotate(27.157 110.66 17.539)"
    />
    <Path
      fill="#B9DEF8"
      d="m36.851 19.906-18.702 6.118a.407.407 0 0 1-.504-.255l-3.248-9.929a.407.407 0 0 1 .256-.503l18.702-6.119a.407.407 0 0 1 .504.256l3.247 9.928a.407.407 0 0 1-.255.504z"
    />
    <Path
      fill="#4BA2EE"
      d="m25.678 18.114-10.972-2.02c-.432-.084-.434-.633-.01-.772l18.615-6.09c.423-.138.75.32.448.63l-7.643 8.109a.49.49 0 0 1-.438.143z"
    />
    <Mask
      id="b"
      width={23}
      height={23}
      x={1}
      y={37}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha'
      }}
    >
      <Circle
        cx={12.5}
        cy={48.704}
        r={10.931}
        fill="#D9D9D9"
        transform="rotate(-7.476 12.5 48.704)"
      />
    </Mask>
    <G mask="url(#b)">
      <Circle
        cx={12.557}
        cy={49.134}
        r={10.162}
        fill="url(#c)"
        transform="rotate(-7.476 12.557 49.134)"
      />
      <Path
        stroke="#FFCD6A"
        strokeWidth={2.14}
        d="M22.101 47.405c.718 5.47-3.135 10.486-8.604 11.204-5.47.718-10.487-3.135-11.205-8.605-.717-5.47 3.135-10.486 8.605-11.204 5.47-.717 10.486 3.135 11.204 8.605z"
      />
      <Circle
        cx={12.833}
        cy={48.966}
        r={9.912}
        stroke="#D89317"
        strokeWidth={2.123}
        transform="rotate(-7.476 12.833 48.966)"
      />
      <Path
        fill="#D89317"
        d="m14.96 42.526-1.172.154A1.075 1.075 0 0 0 13 43.1c-.298.38-.385.583-.323 1.055.04.315.374 2.852.999 7.613-.001.843-.422 1.32-1.263 1.43-.841.11-1.348-.244-1.52-1.065l-.196-1.49-2.299.301.227 1.73c.096.73.414 2.94 4.031 2.38 3.617-.561 3.603-2.625 3.505-3.369l-1.202-9.16z"
      />
      <Circle
        cx={12.461}
        cy={48.756}
        r={10.083}
        stroke="url(#d)"
        strokeWidth={2.123}
        transform="rotate(-7.476 12.461 48.756)"
      />
      <Path
        fill="#FFEAB6"
        d="m14.416 42.542-1.118.147c-.31.026-.56.162-.748.408-.284.37-.367.567-.306 1.027l.973 7.42c.002.822-.398 1.286-1.2 1.391-.802.105-1.286-.242-1.453-1.042l-.19-1.453-2.192.287.221 1.687c.093.71.403 2.866 3.851 2.33 3.449-.537 3.429-2.548 3.334-3.273l-1.172-8.929z"
      />
    </G>
    <Mask
      id="e"
      width={14}
      height={14}
      x={101}
      y={32}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha'
      }}
    >
      <Circle
        cx={107.65}
        cy={38.957}
        r={6.589}
        fill="#D9D9D9"
        transform="rotate(-15 107.65 38.957)"
      />
    </Mask>
    <G mask="url(#e)">
      <Circle
        cx={107.72}
        cy={39.209}
        r={6.125}
        fill="url(#f)"
        transform="rotate(-15 107.72 39.209)"
      />
      <Path
        stroke="#FFCD6A"
        strokeWidth={1.29}
        d="M113.28 37.423a6.02 6.02 0 1 1-11.632 3.117 6.022 6.022 0 0 1 11.632-3.117z"
      />
      <Circle
        cx={107.87}
        cy={39.087}
        r={5.974}
        stroke="#D89317"
        strokeWidth={1.28}
        transform="rotate(-15 107.87 39.087)"
      />
      <Path
        fill="#D89317"
        d="m108.63 35.071-.688.184a.647.647 0 0 0-.437.314c-.148.25-.184.38-.11.656l1.198 4.47c.066.504-.148.823-.642.955s-.825-.04-.993-.517l-.234-.875-1.35.362.272 1.016c.115.428.48 1.724 2.597 1.103 2.117-.62 1.945-1.852 1.828-2.289l-1.441-5.379z"
      />
      <Circle
        cx={107.63}
        cy={38.991}
        r={6.077}
        stroke="url(#g)"
        strokeWidth={1.28}
        transform="rotate(-15 107.63 38.991)"
      />
      <Path
        fill="#FFEAB6"
        d="m108.31 35.123-.656.176a.611.611 0 0 0-.416.303c-.14.243-.174.368-.101.638l1.167 4.357c.066.491-.137.8-.607.926-.471.126-.788-.043-.951-.508l-.228-.854-1.287.345.265.99c.112.418.467 1.681 2.485 1.089 2.018-.593 1.848-1.793 1.734-2.219l-1.405-5.243z"
      />
    </G>
    <Mask
      id="h"
      width={14}
      height={14}
      x={33}
      y={13}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha'
      }}
    >
      <Circle
        cx={40.269}
        cy={19.706}
        r={6.589}
        fill="#D9D9D9"
        transform="rotate(-4.952 40.269 19.706)"
      />
    </Mask>
    <G mask="url(#h)">
      <Circle
        cx={40.292}
        cy={19.967}
        r={6.125}
        fill="url(#i)"
        transform="rotate(-4.952 40.292 19.967)"
      />
      <Path
        stroke="#FFCD6A"
        strokeWidth={1.29}
        d="M46.084 19.179a6.021 6.021 0 1 1-11.997 1.04 6.021 6.021 0 0 1 11.997-1.04z"
      />
      <Circle
        cx={40.462}
        cy={19.873}
        r={5.974}
        stroke="#D89317"
        strokeWidth={1.28}
        transform="rotate(-4.952 40.462 19.873)"
      />
      <Path
        fill="#D89317"
        d="m41.914 16.052-.71.061a.648.648 0 0 0-.485.233c-.19.221-.247.341-.223.627l.4 4.61c-.023.508-.29.784-.799.828-.509.045-.805-.183-.887-.681l-.078-.903-1.392.12.09 1.048c.039.442.172 1.782 2.365 1.54 2.193-.241 2.239-1.485 2.2-1.935l-.481-5.548z"
      />
      <Circle
        cx={40.244}
        cy={19.737}
        r={6.077}
        stroke="url(#j)"
        strokeWidth={1.28}
        transform="rotate(-4.952 40.244 19.737)"
      />
      <Path
        fill="#FFEAB6"
        d="m41.586 16.047-.677.059a.61.61 0 0 0-.462.226c-.18.214-.235.331-.211.61l.39 4.494c-.022.495-.275.763-.76.806-.486.042-.769-.18-.848-.667l-.076-.88-1.327.115.088 1.022c.037.43.167 1.736 2.257 1.505s2.133-1.443 2.095-1.882l-.469-5.408z"
      />
    </G>
    <Defs>
      <LinearGradient
        id="c"
        x1={5.768}
        x2={20.457}
        y1={43.242}
        y2={54.301}
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#E39D21" />
        <Stop offset={0.17} stopColor="#fb3" />
        <Stop offset={0.485} stopColor="#FFD877" />
        <Stop offset={0.745} stopColor="#fb3" />
        <Stop offset={1} stopColor="#D89317" />
      </LinearGradient>
      <LinearGradient
        id="d"
        x1={2.896}
        x2={20.445}
        y1={39.278}
        y2={55.546}
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#E39D21" />
        <Stop offset={0.246} stopColor="#fb3" />
        <Stop offset={0.531} stopColor="#FFEEC2" />
        <Stop offset={0.795} stopColor="#fb3" />
        <Stop offset={1} stopColor="#E39D21" />
      </LinearGradient>
      <LinearGradient
        id="f"
        x1={103.63}
        x2={112.48}
        y1={35.658}
        y2={42.323}
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#E39D21" />
        <Stop offset={0.17} stopColor="#fb3" />
        <Stop offset={0.485} stopColor="#FFD877" />
        <Stop offset={0.745} stopColor="#fb3" />
        <Stop offset={1} stopColor="#D89317" />
      </LinearGradient>
      <LinearGradient
        id="g"
        x1={101.87}
        x2={112.44}
        y1={33.278}
        y2={43.083}
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#E39D21" />
        <Stop offset={0.246} stopColor="#fb3" />
        <Stop offset={0.531} stopColor="#FFEEC2" />
        <Stop offset={0.795} stopColor="#fb3" />
        <Stop offset={1} stopColor="#E39D21" />
      </LinearGradient>
      <LinearGradient
        id="i"
        x1={36.2}
        x2={45.053}
        y1={16.415}
        y2={23.081}
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#E39D21" />
        <Stop offset={0.17} stopColor="#fb3" />
        <Stop offset={0.485} stopColor="#FFD877" />
        <Stop offset={0.745} stopColor="#fb3" />
        <Stop offset={1} stopColor="#D89317" />
      </LinearGradient>
      <LinearGradient
        id="j"
        x1={34.479}
        x2={45.056}
        y1={14.024}
        y2={23.829}
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#E39D21" />
        <Stop offset={0.246} stopColor="#fb3" />
        <Stop offset={0.531} stopColor="#FFEEC2" />
        <Stop offset={0.795} stopColor="#fb3" />
        <Stop offset={1} stopColor="#E39D21" />
      </LinearGradient>
    </Defs>
  </>
);

export const UpgradeAccountOptionsIllustration = createIllustration({
  viewBox: '0 0 116 109',
  vectorComponent
});
