import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { NPS_REASON_MAX_LENGTH } from '@common/constants/validation';
import { Field, TextArea } from '@components/FormElements';

type ReasonProps = {
  value: string;
  onChange: () => void;
  error?: string;
};

export const Reason = ({ error, onChange, value }: ReasonProps) => {
  const { t } = useTranslation();

  return (
    <Field
      label={t('nps.tellUsWhy')}
      message={error && <ErrorMessage>{error}</ErrorMessage>}
    >
      <TextArea
        testID="nps-reason-field"
        value={value}
        tone={error ? 'danger' : 'neutral'}
        onChange={onChange}
        charCountMax={NPS_REASON_MAX_LENGTH}
      />
    </Field>
  );
};
