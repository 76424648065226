import React, { useContext } from 'react';
import { BusinessWebsite as InnerField } from '@common/components/BusinessForm/components/BusinessWebsite';
import { FieldWrapper } from '../FieldWrapper';
import { FormContext } from '../FormContext';
import { useBusinessWebsiteRules } from '../rules/businessWebsiteRules';

type Props = { helperText: string };

export default function BusinessWebsite(props: Props) {
  const { t } = useContext(FormContext);
  return (
    <FieldWrapper<string>
      name="website"
      rules={useBusinessWebsiteRules(t)}
      render={(renderProps) => {
        const propsToPass = {
          ...renderProps,
          ...props
        };
        return <InnerField {...propsToPass} />;
      }}
    />
  );
}
