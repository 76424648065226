import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { Box } from '@components/Box';
import { Field, Input } from '@components/FormElements';
import { Stack } from '@components/Stack';

type EmailInputProps = {
  value: string;
  onChange: () => void;
  onBlur: () => void;
  error?: string;
};

export const Email = (props: EmailInputProps) => {
  const { t } = useTranslation();
  const { error, onChange, onBlur, value } = props;

  return (
    <Stack spacing="small">
      <Field
        label={t('common.fieldLabel.email')}
        message={
          error && (
            <Box marginTop="xxsmall">
              <ErrorMessage>{error}</ErrorMessage>
            </Box>
          )
        }
      >
        <Input
          tone={error ? 'danger' : 'neutral'}
          id="login-form-email"
          testID="login-form-email"
          onChange={onChange}
          onBlur={onBlur}
          value={value}
        />
      </Field>
    </Stack>
  );
};
