import { trpcBff } from '@trpcBff';

export const useUpgradeJob = () => {
  const upgradeMutation = trpcBff.jobs.actions.upgradeWithCoins.useMutation();

  const onClickMutate = (value: any) => {
    upgradeMutation.mutate(value);
  };

  return { onClickMutate };
};
