import { useTranslation } from 'react-i18next';
import {
  SHIFT_AVAILABILITY_TYPE,
  ShiftAvailability
} from '@seek/je-shared-data/lib/types/jobs';

export const useShiftAvailabilityValidation = () => {
  const { t } = useTranslation();

  return {
    validate: (value?: ShiftAvailability) => {
      const hasAnytimeAvailability =
        value?.type === SHIFT_AVAILABILITY_TYPE.ANY_TIME;
      const hasValidSpecificAvailability =
        value && hasValidSpecificShiftAvailability(value);

      if (hasAnytimeAvailability || hasValidSpecificAvailability) {
        return true;
      }

      return t('validations.job.shiftAvailability.required');
    }
  };
};

const hasValidSpecificShiftAvailability = (
  shiftAvailability: ShiftAvailability
) => {
  const { type, specificShiftAvailability } = shiftAvailability;

  if (type !== SHIFT_AVAILABILITY_TYPE.SPECIFIC) {
    return false;
  }

  if (!specificShiftAvailability) {
    return false;
  }

  return Object.values(specificShiftAvailability).some(
    (availability) => availability.length > 0
  );
};
