import { Component } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import styles from './TakeOverModal.scss';

import Card from '../Card/Card';
import CloseCross from '../CloseCross/CloseCross';
import ReduxModal from '../ReduxModal/ReduxModal';

@ReduxModal
export default class TakeOverModal extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    modalOpen: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    'data-test-key': PropTypes.string
  };

  render() {
    const {
      children,
      title,
      modalOpen,
      close,
      'data-test-key': dataTestKey,
      ...propsToSpread
    } = this.props;

    return (
      <ReactModal
        isOpen={modalOpen}
        contentLabel={title}
        className={styles.dialogContainer}
        overlayClassName={styles.underlay}
        ariaHideApp={false}
        onRequestClose={close}
        closeTimeoutMS={300}
        {...propsToSpread} >
        <div
          className={styles.header}
          data-test-key={dataTestKey || 'take-over-modal'} >
          {title}
          <CloseCross className={styles.cross} close={close} />
        </div>
        <Card className={styles.modal}>
          {children}
        </Card>
      </ReactModal>
    );
  }
}
