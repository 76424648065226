import { isJobActive } from '@common/helpers/jobs';
import { getJobsList } from '@store/entities/jobs';
import { useAppSelector } from '@store/hooks';

export const useActiveJobs = (): string[] => {
  const jobs = useAppSelector(getJobsList());
  const activeJobs = jobs.filter((job) => isJobActive(job.status));
  const uniqueActiveJobs = Array.from(new Set(activeJobs.map((job) => job.id)));

  return uniqueActiveJobs;
};
