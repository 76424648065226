import { Business } from '@common/types';

export const extractBusinessLocation = (
  locations: Business['locations']
): string => {
  if (!locations || !locations[0]) return '';

  const { suburb, state } = locations[0];
  const address = [suburb, state].filter((element) => !!element);

  return address.join(', ');
};
