import { ClientError } from '@common/helpers/errors';
import { getUuid } from '@common/helpers/uuid';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { PUSH_PERMISSION_MODEL_DATA } from './constants';

async function read() {
  try {
    const pushPermissionData = await AsyncStorage.getItem(
      PUSH_PERMISSION_MODEL_DATA
    );

    return pushPermissionData && JSON.parse(pushPermissionData);
  } catch (err) {
    throw new ClientError('errors.defaultWithGuid', {
      guid: `ui-${getUuid()}`,
      responseBody: err
    });
  }
}

async function save(data: { timestamp: string; isEnabled: boolean }) {
  try {
    await AsyncStorage.setItem(
      PUSH_PERMISSION_MODEL_DATA,
      JSON.stringify(data)
    );
  } catch (err) {
    throw new ClientError('errors.defaultWithGuid', {
      guid: `ui-${getUuid()}`,
      responseBody: err
    });
  }
}

async function clear() {
  try {
    await AsyncStorage.removeItem(PUSH_PERMISSION_MODEL_DATA);
  } catch (err) {
    throw new ClientError('errors.defaultWithGuid', {
      guid: `ui-${getUuid()}`,
      responseBody: err
    });
  }
}

export function PushPermissionModalStore() {
  return {
    read,
    save,
    clear
  };
}
