import { userHasBadAccountStatus } from '@common/helpers/auth';
import { useQueryMyBalance } from '@common/hooks/coins';
import { useCoinsFeature } from '@common/hooks/featureToggles';
import { useSiteCountryCode } from '@common/hooks/localisation';
import { useCurrentUser, useHasAuthentication } from '@common/hooks/user';

export const useCoinBalance = () => {
  const shouldShowCoinsBalance = useShouldShowCoinsBalance();
  const { myBalance } = useQueryMyBalance({ enabled: shouldShowCoinsBalance });
  const hasBalance = myBalance !== undefined;

  return {
    coinsBalance: myBalance || 0,
    showCoinsBalance: shouldShowCoinsBalance && hasBalance
  };
};

const useShouldShowCoinsBalance = () => {
  const isAuthenticated = useHasAuthentication();

  const currentUser = useCurrentUser();
  const badAccountStatus = userHasBadAccountStatus(currentUser);

  const countryCode = useSiteCountryCode();
  const isCoinsDisabled = !useCoinsFeature(countryCode);

  if (!isAuthenticated) return false;
  if (badAccountStatus) return false;
  if (isCoinsDisabled) return false;

  return true;
};
