import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import {
  convertDDMMYYYToIso,
  localisedFormatDate
} from '@common/helpers/dateTime';
import { isFutureDate, isValidDate } from '@common/helpers/validation';
import { Box } from '@components/Box';
import { CalloutText } from '@components/CalloutText';
import { DateInput, Field } from '@components/FormElements';
import { Stack } from '@components/Stack';

type JobStartDateProps = {
  value: string;
  onChange: () => void;
  onBlur: () => void;
  error?: string;
};

export const JobStartDate = ({
  error,
  onChange,
  onBlur,
  value
}: JobStartDateProps) => {
  const { t } = useTranslation();

  const isAcceptedStartDate =
    isValidDate(value || '') && isFutureDate(value || '');

  return (
    <Stack spacing="medium">
      <Field
        label={t('postJobForm.fieldLabel.startDate')}
        message={
          error && (
            <Box marginTop="xxsmall">
              <ErrorMessage>{error}</ErrorMessage>
            </Box>
          )
        }
      >
        <Box marginTop="none">
          <DateInput
            testID="job-form-jobStartDate"
            onChange={onChange}
            onBlur={onBlur}
            value={value}
          />
        </Box>
      </Field>

      {isAcceptedStartDate ? (
        <CalloutText tone="info">
          {t('postJobForm.explanationText.startDate', {
            date: value,
            longDate: localisedFormatDate(
              t,
              'dd MMMM yyyy',
              convertDDMMYYYToIso(value)
            )
          })}
        </CalloutText>
      ) : null}
    </Stack>
  );
};
