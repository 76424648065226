import { useTranslation } from 'react-i18next';
import { NO_STATUS } from '@common/constants/data';
import { getDateDifference } from '@common/helpers/dateTime';
import { Application } from '@common/types/Application';
import {
  trackInteraction,
  updateApplicantStatus
} from '@store/entities/applications/actions';
import { useAppDispatch } from '@store/hooks';
import { useModal } from '@components/Modal';

function getCurrentPositionDuration({ t, currentPositionStartDate }) {
  if (!currentPositionStartDate) {
    return undefined;
  }

  const { years, months } = getDateDifference({
    pastDate: currentPositionStartDate
  });

  const yearCopy =
    years > 0 ? `${t('common.year.abbreviated.count', { count: years })} ` : '';
  const monthCopy = t('common.months.abbreviated.count', { count: months });
  const durationCopy = `${yearCopy}${monthCopy}`;

  return durationCopy;
}

export function useApplicationCard(application: Application) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const chatWithApplicantModal = useModal();

  const currentPositionDurationText = getCurrentPositionDuration({
    t,
    currentPositionStartDate: application?.currentPositionStartDate
  });

  return {
    currentPositionDurationText,
    chatWithApplicantModal,
    handleStatusChange: (status) => {
      const { id: applicationId, jobId } = application;

      dispatch(updateApplicantStatus({ applicationId, jobId, status }));
    },
    trackInteraction: (interactionType) => {
      dispatch(trackInteraction(application.id, interactionType));
    },
    applicationDetails: {
      ...application,
      applicantPhoneNumber:
        application?.phoneNumberE164 || application?.phoneNumber,
      applicantStatus: application?.status || NO_STATUS
    }
  };
}
