import { push } from 'redux-first-history';
import { EventTypes } from 'redux-segment';
import REQUEST_KEY from '@common/constants/requestKeys';
import { convertDDMMYYYToIso } from '@common/helpers/dateTime';
import { ClientError, ErrorPreventLogging } from '@common/helpers/errors';
import { FetchActionResultSuccess } from '@common/store/middleware/clientMiddlewareTypes';
import { GENERAL_EXCEPTION } from '@seek/je-error-helper';
import * as constants from '../../constants';
import { toFormattedJob } from './toFormattedJob';
import { UpdatedJobItem } from './types';

export type UpdateActionSuccess = FetchActionResultSuccess<
  typeof constants.UPDATE_JOB_SUCCESS,
  UpdatedJobItem
>;

export function update(data) {
  return {
    types: [
      constants.UPDATE_JOB,
      constants.UPDATE_JOB_SUCCESS,
      constants.UPDATE_JOB_FAIL
    ],
    promise: async (dispatch, getState, fetch): Promise<UpdatedJobItem> => {
      const {
        config: {
          endpoints: { jobService }
        },
        entities: {
          jobs: { items }
        },
        localisation
      } = getState();

      const {
        jobDescription,
        jobType,
        jobTitle,
        jobTitleAuto,
        jobId,
        userId,
        shiftAvailability,
        rightToWork,
        startDate,
        workExperience,
        salary
      } = data;

      const { company, countryCode, location, adType } = items[jobId];

      const postData = {
        data: {
          type: 'jobs',
          attributes: {
            company,
            countryCode,
            location,
            consentGranted: true,
            description: jobDescription,
            jobType,
            title: jobTitleAuto?.jobTitle || jobTitle,
            role: jobTitleAuto?.role,
            userId,
            jobId,
            rightToWork,
            shiftAvailability,
            workExperience,
            ...(startDate
              ? { startDate: convertDDMMYYYToIso(startDate) }
              : { startDate: null }),
            ...(salary?.amount ? { salary } : { salary: null })
          }
        }
      };

      const body = await fetch(
        `${jobService}/${jobId}`,
        {
          headers: {
            'Content-Type': 'application/vnd.api+json',
            'x-jora-site': localisation.countryCode
          },
          method: 'PUT',
          credentials: 'include',
          body: JSON.stringify(postData)
        },
        {
          requestKey: REQUEST_KEY.JOB.EDIT,
          showGlobalSpinner: true,
          onResponseError: handleUpdateJobError
        }
      );

      const jobItem = toFormattedJob({
        id: body.id,
        ...postData.data.attributes
      });

      dispatch(
        trackUpdateJobSuccess({
          jobCountry: countryCode,
          adType
        })
      );
      dispatch(
        push(`/${localisation.countryCode}/jobs/${jobId}/edit-job-success`)
      );

      return jobItem;
    }
  };
}

function trackUpdateJobSuccess({ jobCountry, adType }) {
  return {
    type: constants.TRACK_UPDATE_JOB_SUCCESS,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'job_edit__successful',
          parameters: {
            jobCountry,
            adType
          }
        }
      }
    }
  };
}

function handleUpdateJobError(body) {
  const { code, guid } = body;

  switch (code) {
    case GENERAL_EXCEPTION.VALIDATION:
      throw new ClientError('errors.validation', {
        guid,
        responseBody: body
      });
    case GENERAL_EXCEPTION.NOT_FOUND:
      throw new ErrorPreventLogging(code);
    default:
      throw new ClientError('errors.defaultWithGuid', {
        guid,
        responseBody: body
      });
  }
}
