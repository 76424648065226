import { useQueryMyBalance } from '@common/hooks/coins';
import { AD_TYPE } from '@seek/je-shared-data';
import { UpgradeableAdType } from '@src/job/adType';

export type CoinPriceMap = Record<UpgradeableAdType, number | undefined>;

export const useHasSufficientCoins = (coinPriceMap: CoinPriceMap) => {
  const { myBalance = 0 } = useQueryMyBalance();

  const plusPrice = coinPriceMap[AD_TYPE.PLUS];
  const maxPrice = coinPriceMap[AD_TYPE.MAX];

  return {
    [AD_TYPE.MAX]: Boolean(
      maxPrice && hasSufficientBalance(myBalance, maxPrice)
    ),
    [AD_TYPE.PLUS]: Boolean(
      plusPrice && hasSufficientBalance(myBalance, plusPrice)
    )
  };
};

const hasSufficientBalance = (balance: number, price: number) =>
  balance >= price;
