export type TotalApplication = {
  totalApplication: number;
};

/**
 * Checks if the given application information has any total applications.
 * @param applicationInformation - The application information to check.
 * @returns A boolean indicating whether the application information has any total applications.
 */
export const anyApplications = (
  applicationInformation: TotalApplication | undefined
) => Boolean(applicationInformation?.totalApplication);

/**
 * Checks if there are no applications in the given application information.
 * @param applicationInformation - The application information to check.
 * @returns True if there are no applications, false otherwise.
 */
export const noApplications = (
  applicationInformation: TotalApplication | undefined
) => !anyApplications(applicationInformation);
