/**
 * Creates a debounced function that delays invoking the provided callback until after `wait` milliseconds have elapsed since the last time it was invoked.
 *
 * @param callback - The callback function to be debounced.
 * @param wait - The number of milliseconds to delay before invoking the callback.
 * @returns A debounced function that can be invoked with the same arguments as the original callback.
 */
export function debounce(
  callback: (...args: any[]) => void,
  wait: number
): (...args: any[]) => void {
  let timeoutId: number | undefined;
  return (...args: any[]) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback.apply(null, args);
    }, wait);
  };
}
