import React from 'react';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <>
    <path d="M6 4h8a4 4 0 0 1 4 4 4 4 0 0 1-4 4H6z"></path>
    <path d="M6 12h9a4 4 0 0 1 4 4 4 4 0 0 1-4 4H6z"></path>
  </>
);

export const BoldIcon = createIcon({
  viewBox: '0 0 24 24',
  vectorComponent
});
