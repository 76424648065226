import React from 'react';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { Box } from '@components/Box';
import { Field, Input } from '@components/FormElements';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { T } from '../../types';

type EmailProps = {
  value: string;
  onChange: () => void;
  onBlur: () => void;
  error?: string;
  t: T;
  showExplanationText?: boolean;
};

export const Email = ({
  error,
  onChange,
  onBlur,
  value,
  t,
  showExplanationText = true
}: EmailProps) => {
  return (
    <Stack spacing="xxsmall">
      <Field
        label={t('common.fieldLabel.email')}
        message={
          error && (
            <Box marginTop="xxsmall">
              <ErrorMessage>{error}</ErrorMessage>
            </Box>
          )
        }
      >
        <Input
          tone={error ? 'danger' : 'neutral'}
          id="account-details-form-email"
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          keyboardType="email-address"
        />
      </Field>
      {showExplanationText && (
        <Text size="small" tone="neutralLight">
          {t('updateAccountDetailsPageForm.explanationText')}
        </Text>
      )}
    </Stack>
  );
};
