import React, { useContext } from 'react';
import { RecruitmentCompanyName as InnerField } from '@common/components/BusinessForm/components/RecruitmentCompanyName';
import { FieldWrapper } from '../FieldWrapper';
import { FormContext } from '../FormContext';
import { recruitmentCompanyNameRules } from '../rules';

export default function RecruitmentCompanyName() {
  const { t } = useContext(FormContext);
  return (
    <FieldWrapper<string>
      name="recruitmentCompanyName"
      rules={recruitmentCompanyNameRules(t)}
      render={(props) => <InnerField {...props} />}
    />
  );
}
