import React from 'react';
import {
  AdCardContainer,
  AdUpgradeCard
} from '@common/components/AdUpgradeCard';
import { useGetAdDuration } from '@common/hooks/job';
import { useBrandName } from '@common/hooks/localisation';
import { AD_TYPE } from '@seek/je-shared-data';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { Stack } from '@components/Stack';
import { CurrencyAmount } from '../components';
import { BasicAdLink } from '../components/BasicAdLink';
import { DisclaimerText } from '../components/DisclaimerText';
import { useCurrencyPaymentPressed, useGetCurrencyPrice } from '../hooks';

type Props = {
  job: {
    id: string;
    countryCode: SupportedCountryCode;
  };
  basicAdLink?: string;
  campaign?: string;
};

/**
 * Component to display currency payment options for job ads
 */
export const CurrencyAdPanel = ({ job, basicAdLink, campaign }: Props) => {
  const { countryCode: jobCountryCode, id: jobId } = job;
  const brandName = useBrandName();

  const pricingText = useGetCurrencyPrice({ jobCountryCode });

  const { adDuration } = useGetAdDuration(jobCountryCode);

  const { onPlusAdPressed, onMaxAdPressed } = useCurrencyPaymentPressed({
    campaign,
    jobId
  });

  return (
    <Stack spacing="medium" testID="currency-ad-panel">
      <AdCardContainer>
        <AdUpgradeCard
          adDuration={adDuration}
          brand={brandName}
          country={jobCountryCode}
          price={<CurrencyAmount price={pricingText[AD_TYPE.PLUS]} />}
          type={AD_TYPE.PLUS}
          onButtonPress={onPlusAdPressed}
        />
        <AdUpgradeCard
          adDuration={adDuration}
          brand={brandName}
          country={jobCountryCode}
          price={<CurrencyAmount price={pricingText[AD_TYPE.MAX]} />}
          type={AD_TYPE.MAX}
          onButtonPress={onMaxAdPressed}
        />
      </AdCardContainer>

      {basicAdLink ? (
        <BasicAdLink
          adDuration={adDuration[AD_TYPE.BASIC]}
          link={basicAdLink}
        />
      ) : null}

      <DisclaimerText jobCountryCode={jobCountryCode} />
    </Stack>
  );
};
