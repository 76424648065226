import React, { ReactElement, ReactNode, forwardRef } from 'react';
import { Text, View, ViewProps } from 'react-native';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { ListStyleProps, useListStyle } from './useListStyle';

export type ListProps = {
  children?: ReactNode;
  testID?: ViewProps['testID'];
} & ListStyleProps;

export const List = forwardRef<View, ListProps>(
  ({ children, testID, variant = 'bullet', ...restProps }: ListProps, ref) => {
    const childrenArray = React.Children.toArray(children);
    const { listTypeStyle } = useListStyle({
      variant,
      ...restProps
    });

    return (
      <Stack testID={testID} ref={ref} tone="transparent">
        {childrenArray.map((child, index) => {
          if (child) {
            return (
              <Row
                spacing="xxsmall"
                key={(child as ReactElement).key || index}
                tone="transparent"
              >
                <Text style={listTypeStyle}>
                  {variant === 'bullet' ? '\u2022' : `${index + 1}.`}
                </Text>
                {child}
              </Row>
            );
          }
        })}
      </Stack>
    );
  }
);
