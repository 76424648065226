import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <>
    <Path
      d="M12 20.5a8.5 8.5 0 100-17 8.5 8.5 0 000 17z"
      strokeMiterlimit={10}
    />
    <Path
      d="M12 16.5V10M12 8v-.5"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </>
);

export const InfoIcon = createIcon({ viewBox: '3 3 18 18', vectorComponent });
