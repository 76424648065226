import { Component } from 'react';
import Div from '../../../../../../common/components/Div/Div';
import CardActions from '../../../../../../common/components/CardActions/CardActions';
import Card from '../../../../../../common/components/Card/Card';
import Skeleton from '../../../../../../common/components/Skeleton/Skeleton';
import ActionLink from '../../../../../../common/components/ActionLink/ActionLink';

import * as colours from '../../../../../../common/theme/palette/colours';

export default class ListBusinessSkeleton extends Component {
  render() {
    const gridRowHeight = 8;

    return (
      <Div marginTop="medium" marginBottom="medium">
        <Div flexDirection="row" justifyContent="flexEnd" marginBottom="medium">
          <Skeleton highlightColor={colours.grey200} baseColor={colours.grey300} width={200} height={gridRowHeight * 6} marginRight={gridRowHeight * 2} />
        </Div>
        <Card>
          <Div flexDirection="row" alignItems="center">
            <Skeleton width={48} height={gridRowHeight * 6} marginRight={gridRowHeight} />
            <Div flexDirection="column">
              <Div>
                <Skeleton width={200} height={gridRowHeight * 2} />
              </Div>
              <Div marginTop="small">
                <Skeleton width={100} height={gridRowHeight * 2} />
              </Div>
            </Div>
          </Div>
          <CardActions>
            <ActionLink onClick={() => { }}>
              <Skeleton width={64} height={gridRowHeight * 2} />
            </ActionLink>
            <ActionLink onClick={() => { }}>
              <Skeleton width={64} height={gridRowHeight * 2} />
            </ActionLink>
          </CardActions>
        </Card>
      </Div>
    );
  }
}
