import React from 'react';
import { formatStringToTitleCase } from '@common/helpers/textFormatting';
import { Heading } from '@components/Heading';
import { Row } from '@components/Row';

type ApplicantNameProps = {
  name: string;
  testID: string;
};

export const ApplicantName = (props: ApplicantNameProps) => {
  const { testID, name } = props;

  return (
    <Row flexShrink={1}>
      <Heading level="h1" testID={testID}>
        {formatStringToTitleCase(name)}
      </Heading>
    </Row>
  );
};
