import { useResolveToken } from '@theme/tokens/utils/useResolveToken';
import { ResponsivePlatformStyle, ResponsiveStyle } from '@theme/types';
import { Direction } from './types';

export type StackStyleProps = {
  direction:
    | Direction
    | ResponsiveStyle<Direction>
    | ResponsivePlatformStyle<Direction>;
};

export function useStackStyle({ direction }: StackStyleProps) {
  const { resolveToken } = useResolveToken();

  const flexDirection = resolveToken(null, direction) || 'column';

  const dividerDirection = (
    {
      row: 'vertical',
      column: 'horizontal'
    } as const
  )[flexDirection];

  return {
    dividerDirection
  };
}
