import React from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from '@components/Column';
import { NoCoinsTransactionsIllustration } from '@components/Illustration';
import { Text } from '@components/Text';

export const EmptyCoinTransactions = () => {
  const { t } = useTranslation();

  return (
    <Column alignItems="center" spacing="xsmall">
      <NoCoinsTransactionsIllustration
        size="standard"
        testID="no-coins-transactions-illustration"
      />
      <Text weight="regular" size="small" align="center">
        {t('myCoinsPage.transactions.noTransactions.title')}
      </Text>
      <Text size="small" align="center">
        {t('myCoinsPage.transactions.noTransactions.description')}
      </Text>
    </Column>
  );
};
