import { useEffect } from 'react';
import { ClientError } from '@common/helpers/errors';
import { updateError } from '@store/app';
import { useAppDispatch } from '@store/hooks';

/**
 * This hook is used to handle the error from the API request and update the error state in the store
 * @param {boolean} isError - boolean value to check if the request has an error
 * @param {string} requestKey - request key to update the error state in the store
 * @param {ClientError} error - error object to be updated in the store
 */
export const useHandleAppError = (
  isError: boolean,
  requestKey: string,
  error?: ClientError
) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isError) {
      updateError(dispatch, requestKey, error);
    }
  }, [dispatch, error, isError, requestKey]);
};
