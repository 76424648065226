import { ViewStyle } from 'react-native';
import { useTheme } from '@theme/DesignSystemProvider/ThemeContext';
import { Breakpoint, Sizes, Spacing } from '@theme/tokens';
import { BorderWidth } from '@theme/tokens/borders';
import { Radius } from '@theme/tokens/radius';
import { Shadow } from '@theme/tokens/shadow';
import { useResolveToken } from '@theme/tokens/utils/useResolveToken';
import { Tone } from '@theme/types';

type Direction = 'row' | 'column' | 'column-reverse';
type BoxTone = Tone | 'transparent' | 'neutralDark' | 'dark' | 'black';

export type BoxStyleProps = {
  flex?: ViewStyle['flex'];
  flexBasis?:
    | ViewStyle['flexBasis']
    | Record<Breakpoint, ViewStyle['flexBasis']>;
  flexGrow?: ViewStyle['flexGrow'] | Record<Breakpoint, ViewStyle['flexGrow']>;
  flexShrink?: ViewStyle['flexShrink'];
  flexWrap?: ViewStyle['flexWrap'];
  alignSelf?:
    | ViewStyle['alignSelf']
    | Record<Breakpoint, ViewStyle['alignSelf']>;
  alignItems?:
    | ViewStyle['alignItems']
    | Record<Breakpoint, ViewStyle['alignItems']>;
  justifyContent?:
    | ViewStyle['justifyContent']
    | Record<Breakpoint, ViewStyle['justifyContent']>;
  padding?: Spacing;
  paddingBottom?: Spacing | Record<Breakpoint, Spacing>;
  paddingHorizontal?: Spacing | Record<Breakpoint, Spacing>;
  paddingLeft?: Spacing | Record<Breakpoint, Spacing>;
  paddingRight?: Spacing | Record<Breakpoint, Spacing>;
  paddingTop?: Spacing | Record<Breakpoint, Spacing>;
  paddingVertical?: Spacing | Record<Breakpoint, Spacing>;
  margin?: Spacing | Record<Breakpoint, Spacing>;
  marginBottom?: Spacing | Record<Breakpoint, Spacing>;
  marginHorizontal?: Spacing | Record<Breakpoint, Spacing>;
  marginLeft?: Spacing | Record<Breakpoint, Spacing>;
  marginRight?: Spacing | Record<Breakpoint, Spacing>;
  marginTop?: Spacing | Record<Breakpoint, Spacing>;
  marginVertical?: Spacing | Record<Breakpoint, Spacing>;
  direction?: Direction | Record<Breakpoint, Direction>;
  tone?: BoxTone;
  shadow?: Shadow;
  borderWidth?: BorderWidth;
  borderLeftWidth?: BorderWidth;
  borderRadius?: Radius;
  visible?: boolean | Record<Breakpoint, boolean>;
  width?: Sizes | Record<Breakpoint, Sizes>;
  minWidth?: Sizes | Record<Breakpoint, Sizes>;
  maxWidth?: Sizes | Record<Breakpoint, Sizes>;
  height?: Sizes | Record<Breakpoint, Sizes>;
  zIndex?: ViewStyle['zIndex'];
};

export function useBoxStyle({
  flex,
  flexBasis,
  flexGrow,
  flexShrink,
  flexWrap,
  alignSelf,
  alignItems,
  justifyContent,
  padding,
  paddingBottom,
  paddingHorizontal,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingVertical,
  margin,
  marginBottom,
  marginHorizontal,
  marginLeft,
  marginRight,
  marginTop,
  marginVertical,
  direction,
  borderWidth,
  borderLeftWidth,
  borderRadius,
  tone = 'neutral',
  shadow,
  visible = true,
  width,
  minWidth,
  maxWidth,
  height,
  zIndex
}: BoxStyleProps) {
  const { spacing, sizes, colors, border, shadow: shadowToken } = useTheme();
  const { resolveToken } = useResolveToken();

  return {
    borderWidth: resolveToken(border.width, borderWidth),
    borderColor:
      borderWidth && borderWidth !== 'none'
        ? resolveToken(border.color, tone)
        : undefined,
    borderLeftWidth: resolveToken(border.width, borderLeftWidth),
    borderRadius: resolveToken(border.radius, borderRadius),
    backgroundColor: resolveToken(colors.background, tone),
    flex: resolveToken(null, flex),
    flexBasis: resolveToken(null, flexBasis as any),
    flexDirection: resolveToken(null, direction),
    flexGrow: resolveToken(null, flexGrow),
    flexShrink: resolveToken(null, flexShrink),
    flexWrap: resolveToken(null, flexWrap),
    alignSelf: resolveToken(null, alignSelf),
    alignItems: resolveToken(null, alignItems),
    justifyContent: resolveToken(null, justifyContent),
    margin: resolveToken(spacing, margin),
    marginBottom: resolveToken(spacing, marginBottom),
    marginHorizontal: resolveToken(spacing, marginHorizontal),
    marginLeft: resolveToken(spacing, marginLeft),
    marginRight: resolveToken(spacing, marginRight),
    marginTop: resolveToken(spacing, marginTop),
    marginVertical: resolveToken(spacing, marginVertical),
    padding: resolveToken(spacing, padding),
    paddingBottom: resolveToken(spacing, paddingBottom),
    paddingHorizontal: resolveToken(spacing, paddingHorizontal),
    paddingLeft: resolveToken(spacing, paddingLeft),
    paddingRight: resolveToken(spacing, paddingRight),
    paddingTop: resolveToken(spacing, paddingTop),
    paddingVertical: resolveToken(spacing, paddingVertical),
    visible: resolveToken(null, visible),
    width: resolveToken(sizes, width),
    minWidth: resolveToken(sizes, minWidth),
    maxWidth: resolveToken(sizes, maxWidth),
    height: resolveToken(sizes, height),
    zIndex: resolveToken(null, zIndex),
    ...(resolveToken(shadowToken, shadow) || {})
  };
}
