import React from 'react';
import { PriceText } from './PriceText';

type Props = {
  price?: string;
};

export const CurrencyAmount = ({ price }: Props) => {
  if (!price) return null;

  return <PriceText<string> price={price} />;
};
