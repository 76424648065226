import React, { useContext } from 'react';
import { Password as InnerField } from '@common/components/Account/components/Password';
import { usePasswordValidation } from '@common/components/Account/components/Password/usePasswordValidation';
import type { T } from '@src/caja/Form/types';
import { FieldWrapper } from '../FieldWrapper';
import { FormContext } from '../FormContext';

export const Password = () => {
  const { t } = useContext(FormContext);
  const passwordRules = usePasswordValidation();
  const rulesFunction = (_: T) => passwordRules;
  return (
    <FieldWrapper<string>
      name="password"
      rules={rulesFunction(t)}
      render={(props) => {
        return (
          <InnerField
            showExplanationText={true}
            {...props}
            labelKey="registerForm.fieldLabel.password"
          />
        );
      }}
    />
  );
};
