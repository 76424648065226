import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash.omit';

import styles from './ScreenHeading.scss';

export default class ScreenHeading extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    subHeading: PropTypes.string,
    removeTopSpacing: PropTypes.bool,
    removeBottomSpacing: PropTypes.bool
  };

  subHeading = () => {
    const { subHeading } = this.props;

    if (subHeading) {
      return (
        <h2
          data-test-key="screenHeadingSubHeading"
          className={styles.subHeading}>
          {subHeading}
        </h2>
      );
    }
  };

  render() {
    const { children, removeTopSpacing, removeBottomSpacing } = this.props;

    return (
      <div className={classNames(styles.root, {
        [styles.removeTopSpacing]: removeTopSpacing,
        [styles.removeBottomSpacing]: removeBottomSpacing
      })}>
        <h1 {...omit(this.props, 'subHeading', 'removeTopSpacing', 'removeBottomSpacing')}>
          {children}
        </h1>
        {this.subHeading()}
      </div>
    );
  }
}