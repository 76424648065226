import React from 'react';
import { TextWithIcon } from '@common/components/TextWithIcon';
import { UserIcon } from '@components/Icon';
import { Text } from '@components/Text';
import { AgeInfoProps, useGenderAgeInfo } from './useAgeInfo';

export const AgeInfo = ({ age, gender }: AgeInfoProps) => {
  const ageGenderInfo = useGenderAgeInfo({ age, gender });

  const userIcon = (
    <UserIcon
      size="small"
      tone="success"
      variant="outline"
      testID="user-icon"
    />
  );

  return (
    <>
      {ageGenderInfo.length > 0 && (
        <TextWithIcon icon={userIcon}>
          <Text>{ageGenderInfo}</Text>
        </TextWithIcon>
      )}
    </>
  );
};
