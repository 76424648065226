import { useEffect } from 'react';
import { endLoading, startLoading } from '@store/app';
import { useAppDispatch } from '@store/hooks';

export const useHandleAppLoading = (isLoading: boolean, requestKey: string) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isLoading) {
      startLoading(dispatch, requestKey, true);
    }
  }, [dispatch, isLoading, requestKey]);

  useEffect(() => {
    if (!isLoading) {
      endLoading(dispatch, requestKey, true);
    }
  }, [dispatch, isLoading, requestKey]);
};
