import { useTheme } from '@theme/DesignSystemProvider/ThemeContext';
import { useResolveToken } from '@theme/tokens/utils/useResolveToken';
import { Tone } from '@theme/types';

type Variant = 'bullet' | 'number';
export type ListStyleProps = {
  tone?: Tone;
  variant?: Variant;
};

export function useListStyle({
  tone = 'neutral',
  variant = 'bullet'
}: ListStyleProps) {
  const {
    colors: { foreground },
    typography: {
      fontFamily,
      text,
      fontSizes,
      fontWeights,
      lineHeights,
      letterSpacing
    }
  } = useTheme();
  const { resolveToken } = useResolveToken();
  const listTypeSize = variant === 'bullet' ? 'xxlarge' : 'medium';

  const { lineHeight: listLineHeight, letterSpacing: listLetterSpacing } =
    resolveToken(text, 'standard' as const) || {};

  return {
    listTypeStyle: {
      fontFamily,
      fontSize: resolveToken(fontSizes, listTypeSize),
      lineHeight: resolveToken(lineHeights, listLineHeight),
      letterSpacing: resolveToken(letterSpacing, listLetterSpacing),
      fontWeight: resolveToken(fontWeights, 'light' as const),
      color: resolveToken(foreground, tone)
    }
  };
}
