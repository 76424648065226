import { ProfileApplication } from 'je-bff/src/applications/list/transformers';
import { ApplicationSortStrategy } from 'je-bff/src/applications/list/transformers/sortProfileApplications/applicationSortRules';
import { ApplicationFilterValues, FILTERS, SORT } from '../../../types';
import {
  applySortReducer,
  sortByApplicationDate,
  sortByDistance,
  sortByRightToWork
} from './';

const applicationSortStrategies: Record<string, ApplicationSortStrategy> = {
  [SORT.APPLICATION_DATE]: sortByApplicationDate,
  [SORT.DISTANCE]: sortByDistance,
  [SORT.RIGHT_TO_WORK]: sortByRightToWork
};

export const sortFilteredApplications = (
  filterValues: ApplicationFilterValues,
  applications: ProfileApplication[]
) => {
  const sortKey = filterValues[FILTERS.SORT_APPLICATIONS];
  const selectedSortFn = sortKey && applicationSortStrategies[sortKey];
  const sortingFunctions = [...(selectedSortFn ? [selectedSortFn] : [])];
  return applySortReducer(applications, sortingFunctions);
};
