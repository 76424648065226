import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <Path
    d="M12 3.5v12M12 15.5l4.5-4M7.5 11.5l4.5 4M21.5 11.5v7c0 1.5-.5 2-1.5 2H4c-1 0-1.5-.5-1.5-2v-7"
    strokeMiterlimit={10}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
);

export const DownloadIcon = createIcon({
  viewBox: '2 2 20 20',
  vectorComponent
});
