import { Step, StepMap } from './types';

/**
 * Converts a path to a step by looking it up in the step map.
 * @param path - The path to convert to a step.
 * @param stepMap - The step map to search for the path.
 * @returns The corresponding step for the given path.
 * @throws Error if the path is not found in the step map.
 */
export function pathToStep(path: string, stepMap: StepMap): Step {
  const stepMapItem = stepMap.find((smi) => smi.path === path);
  if (!stepMapItem) throw new Error(`Path ${path} not found in step map`);
  return stepMapItem.step;
}
