import { useHistory } from 'react-router-native';
import { userHasBadAccountStatus } from '@common/helpers/auth';
import { useSiteCountryCode } from '@common/hooks/localisation';
import { useCurrentUser, useHasAuthentication } from '@common/hooks/user';
import { logout } from '@store/auth';
import { getEnvironment, getSiteId } from '@store/config';
import { useAppDispatch, useAppSelector } from '@store/hooks';

export const useJoraEmployerHeader = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isAuthenticated = useHasAuthentication();
  const currentUser = useCurrentUser();
  const countryCode = useSiteCountryCode();
  const environment = useAppSelector(getEnvironment());
  const siteId = useAppSelector(getSiteId());
  const badAccountStatus = userHasBadAccountStatus(currentUser);

  return {
    isAuthenticated,
    badAccountStatus,
    isNotHomeRoute: history.location?.pathname !== `/${countryCode}/`,
    siteId,
    isDevelopment: environment === 'development',
    goBack: () => history.goBack(),
    goToLogin: () => history.push(`/${countryCode}/login`),
    logOut: () => dispatch(logout({ initiatedByUser: true })),
    goToHome: () => history.push(`/${countryCode}/`)
  };
};
