import { Platform, TextLayoutEventData } from 'react-native';
import { useTheme } from '@theme/DesignSystemProvider/ThemeContext';
import { useResolveToken } from '@theme/tokens/utils/useResolveToken';

export function usePillStyle(textLayoutData?: TextLayoutEventData) {
  const {
    border,
    colors: { foreground },
    pill: {
      color: { backgroundColor, borderColor }
    },
    typography
  } = useTheme();
  const { resolveToken } = useResolveToken();
  const {
    size: textSize,
    lineHeight: textLineHeight,
    letterSpacing: textLetterSpacing
  } = resolveToken(typography.text, 'standard') || {};

  return {
    containerStyle: {
      alignItems: Platform.OS === 'web' ? 'baseline' : 'center'
    },
    pressableStyle: {
      alignSelf: 'flex-start' as const,
      backgroundColor,
      borderColor,
      borderRadius: resolveToken(border.radius, 'full'),
      borderWidth: resolveToken(border.width, 'xsmall'),
      outlineWidth: 0
    },
    imageContainerStyle: {
      marginTop:
        Platform.OS === 'ios'
          ? (textLayoutData?.lines[0]?.descender || 0) / 2
          : undefined
    },
    textStyle: {
      fontFamily: typography.fontFamily,
      color: resolveToken(foreground, 'neutral'),
      fontSize: resolveToken(typography.fontSizes, textSize),
      fontWeight: resolveToken(typography.fontWeights, 'light'),
      lineHeight: resolveToken(typography.lineHeights, textLineHeight),
      letterSpacing: resolveToken(typography.letterSpacing, textLetterSpacing)
    }
  } as const;
}
