import { useCallback } from 'react';
import { useSiteCountryCode } from '@common/hooks/localisation';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { UpgradeableAdType } from '@src/job/adType';

export type Props = {
  jobId: string;
  jobCountryCode: SupportedCountryCode;
  fromPostJob?: boolean;
};

export function useGetPaymentSuccessLink({
  jobId,
  jobCountryCode,
  fromPostJob
}: Props) {
  const countryCode = useSiteCountryCode();

  const getPaymentSuccessLink = useCallback(
    (adType: UpgradeableAdType) => {
      return `/${countryCode}/jobs/${jobId}/payment-success?adType=${adType}&jobCountryCode=${jobCountryCode}${
        fromPostJob ? '&fromPostJob=true' : ''
      }`;
    },
    [jobCountryCode, jobId, countryCode, fromPostJob]
  );

  return getPaymentSuccessLink;
}
