import React, { useContext } from 'react';
import { WorkExperience as InnerField } from '@common/components/PostJobForm/components/WorkExperience/WorkExperience';
import { WorkExperience as WorkExperienceEnum } from '@seek/je-shared-data/lib/types/jobs/jobs';
import { FieldWrapper } from '../FieldWrapper';
import { FormContext } from '../FormContext';
import { workExperienceRules } from '../rules';

export default function WorkExperience() {
  const { t } = useContext(FormContext);
  return (
    <FieldWrapper<WorkExperienceEnum | undefined>
      rules={workExperienceRules(t)}
      name="workExperience"
      render={(props) => <InnerField {...props} />}
    />
  );
}
