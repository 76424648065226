export function applySortReducer<T>(
  items: T[],
  compareFunctions: ((itemA: T, itemB: T) => number)[]
) {
  return items.sort((itemA, itemB) =>
    compareFunctions.reduce((result, compareFn) => {
      if (result !== 0) return result;

      return compareFn(itemA, itemB);
    }, 0)
  );
}
