import React from 'react';
import { Trans as I18nextTrans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-native';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { Box } from '@components/Box';
import { Card, CardContent } from '@components/Card';
import { Heading } from '@components/Heading';
import { List } from '@components/List';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { TextLink } from '@components/TextLink';

export const NotFound = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Stack tone="transparent">
      <JoraEmployerHelmet title={t('notFoundPage.title')} />

      <Stack tone="transparent" spacing="medium">
        <Box tone="transparent" paddingHorizontal="medium" marginTop="medium">
          <Heading level="h1">{t('notFoundPage.heading')}</Heading>
        </Box>

        <Card>
          <CardContent>
            <Stack spacing="xsmall">
              <Text>{t('notFoundPage.description.tryTheseTips')}</Text>

              <List variant="number">
                <Text>{t('notFoundPage.description.checkTheUrl')}</Text>
                <I18nextTrans
                  i18nKey="notFoundPage.description.tryGoingBack"
                  parent={Text}
                  components={[
                    <TextLink variant="primary" onPress={history.goBack} />
                  ]}
                />
              </List>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </Stack>
  );
};
