import React, { PropsWithChildren, ReactElement } from 'react';
import { Box } from '@components/Box';
import { Row, RowProps } from '@components/Row';

export type TextWithIconProps = {
  icon: ReactElement;
  spacing?: RowProps['spacing'];
  alignItems?: RowProps['alignItems'];
  marginTop?: RowProps['marginTop'];
};

export const TextWithIcon = ({
  children,
  icon,
  spacing = 'xsmall',
  alignItems = 'center',
  marginTop = 'none'
}: PropsWithChildren<TextWithIconProps>) => (
  <Row spacing={spacing} alignItems={alignItems} tone="transparent">
    <Box tone="transparent" marginTop={marginTop}>
      {icon}
    </Box>
    <Box flexShrink={1} tone="transparent">
      {children}
    </Box>
  </Row>
);
