import * as sessionTagging from '../../helpers/tagSession';
import { EventTypes } from 'redux-segment';

const interrogateActionResult = (selector, callback) => store => next => action => {
  const returnValue = next(action);

  if (typeof selector !== 'function') {
    return returnValue;
  }

  const selected = selector(action);

  if (!selected) {
    return returnValue;
  }

  callback(selected, action, store);

  return returnValue;
};

function tagTrack(eventPayload) {
  const typeProperty = (eventPayload.properties || {}).type;

  if (typeProperty) {
    sessionTagging.tagSession(`${eventPayload.event}: ${typeProperty}`);
  } else {
    sessionTagging.tagSession(eventPayload.event);
  }
}

function sendTags({ eventType, eventPayload, brand }) {
  switch (eventType) {
    case EventTypes.identify:
      sessionTagging.tagSessionsWithId(eventPayload.userId);
      break;
    case EventTypes.track:
      tagTrack(eventPayload);
      break;
    case EventTypes.page:
      sessionTagging.tagHotjarRecording(brand);
      break;
  }
}

export default function sessionTaggingMiddleWare() {
  const selectSessionTagging = ({ meta }) => meta && meta.analytics;

  return interrogateActionResult(selectSessionTagging, (analytics) => {
    const analyticsArray = Array.isArray(analytics) ? analytics : [analytics];

    analyticsArray.forEach(event => sendTags(event));
  });
}