import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { getFeatures } from '@store/config';
import { useAppSelector } from '@store/hooks';
import { useBrandName } from '../../localisation/useBrandName';

export const useMonetisationGstFeature = (
  countryCode: SupportedCountryCode
) => {
  const features = useAppSelector(getFeatures());
  const brandName = useBrandName();

  return features?.monetisationGst[brandName][countryCode];
};
