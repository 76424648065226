/**
 * Updates the last part of the current URL path.
 * @param {string} slug - The new value for the last part of the URL path.
 */
export function updateSlug(newPart: string, w = window, updateHistory = true) {
  const path = w.location.pathname;
  const queryParams = w.location.search || '';
  const parts = path.split('/');
  parts[parts.length - 1] = newPart;

  const newPath = parts.join('/');

  updateHistory
    ? w.history.pushState({}, '', newPath + queryParams)
    : w.history.replaceState({}, '', newPath + queryParams);
}
