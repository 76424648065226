import { TFunction, useTranslation } from 'react-i18next';

export type AgeInfoProps = {
  age?: number;
  gender?: string;
};

function getGenderText(t: TFunction, gender: string): string {
  return t(
    `applications.applicationCard.aboutMe.gender.${gender?.toLowerCase()}`
  );
}

function getAgeText(t: TFunction, age: number): string {
  return t('applications.applicationCard.aboutMe.age', {
    age
  });
}

export const useGenderAgeInfo = ({ gender, age }: AgeInfoProps): string => {
  const { t } = useTranslation();
  let ageGenderInfo = '';

  if (gender) {
    ageGenderInfo = getGenderText(t, gender);
  }

  if (gender && age) {
    ageGenderInfo += ', ';
  }

  if (age) {
    ageGenderInfo += getAgeText(t, age);
  }

  return ageGenderInfo;
};
