import PropTypes from 'prop-types';
import { white, red600, grey600 } from '../../theme/palette/colours';
import Svg, { Path } from 'svgs';

function getFill(theme) {
  switch (theme) {
    case 'danger':
      return 'none';
    case 'dangerFilled':
      return red600;
    default:
      return white;
  }
}

function getOutline(theme) {
  switch (theme) {
    case 'danger':
      return red600;
    case 'dangerFilled':
      return white;
    default:
      return grey600;
  }
}

function ErrorIcon(props) {
  return (
    <Svg viewBox="3 3 18 18" fill={getFill(props.theme)} {...props}>
      <Path
        d="M12 20.5a8.5 8.5 0 100-17 8.5 8.5 0 000 17z"
        stroke={getOutline(props.theme)}
        strokeMiterlimit={10}
      />
      <Path
        d="M12 7.5v6M12 15.5v.5"
        stroke={getOutline(props.theme)}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
    </Svg>
  );
}

ErrorIcon.propTypes = {
  theme: PropTypes.string
};

export default ErrorIcon;