import { getUuid } from './uuid';

export class ErrorPreventLogging extends Error {
  code?: string | null;
  context?: any;
  preventLogging: boolean;

  constructor(message: string, code: string | null = null, context?: any) {
    super(message);
    Object.setPrototypeOf(this, ErrorPreventLogging.prototype);

    this.name = this.constructor.name;
    this.message = message;
    this.preventLogging = true;
    this.code = code;
    this.context = context;

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }
}

export type ClientErrorProperties = {
  guid?: string;
  responseBody?: any;
  adType?: string;
};

export class ClientError extends Error {
  properties: ClientErrorProperties;

  constructor(
    message: string,
    {
      guid = `ui-${getUuid()}`,
      responseBody,
      adType
    }: ClientErrorProperties = {}
  ) {
    super(message);
    Object.setPrototypeOf(this, ClientError.prototype);

    this.properties = { guid, responseBody, adType };
  }
}
