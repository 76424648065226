import { Component } from 'react';
import PropTypes from 'prop-types';

import { GENERAL_VALIDATION_MESSAGES } from '../../constants/validation';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

export default class ErrorSummary extends Component {
  static propTypes = {
    show: PropTypes.bool,
    insideCard: PropTypes.bool,
    message: PropTypes.string
  };

  render() {
    const { show, insideCard, message } = this.props;

    return (
      <ErrorMessage outsideCard={!insideCard} extraTopMargin>
        {show && (message || GENERAL_VALIDATION_MESSAGES.SUMMARY)}
      </ErrorMessage>
    );
  }
}
