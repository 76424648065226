import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetPostJobLink } from '@common/hooks/appPageLinks/useGetPostJobLink';
import { useRedirect } from '@common/hooks/useRedirect';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';
import { Box } from '@components/Box';
import { Button } from '@components/Button';
import { Card, CardContent } from '@components/Card';
import { Column } from '@components/Column';
import { Heading } from '@components/Heading';
import { Stack } from '@components/Stack';
import { TileText } from '../TileText';
import { ShowLoginIllustration } from './components/ShowLoginIllustration';

export const LoginTile = () => {
  const { t } = useTranslation();
  const { countryCode, brandNameText } = useAppSelector(getLocalisation);
  const { redirect } = useRedirect();
  const { postJobLink } = useGetPostJobLink();

  return (
    <Card>
      <CardContent>
        <Stack
          direction={{ mobile: 'column', tablet: 'row', desktop: 'row' }}
          spacing="large"
          paddingTop="medium"
        >
          <Column
            flexShrink={1}
            maxWidth={{ mobile: 'xxxlarge', tablet: '2/3', desktop: '2/3' }}
          >
            <Heading level="h2" weight="medium">
              {t('employerLandingPage.loginTile.heading', { brandNameText })}
            </Heading>
            <TileText>
              {t('employerLandingPage.loginTile.description', {
                brandNameText
              })}
            </TileText>
            <Stack
              marginTop="large"
              spacing="medium"
              direction={{
                mobile: 'column',
                tablet: 'row',
                desktop: 'row'
              }}
            >
              <Button onPress={redirect(postJobLink)}>
                {t('employerLandingPage.loginTile.postJob')}
              </Button>
              <Button
                testID="top-registration-card-login-action"
                onPress={redirect(`/${countryCode}/login`)}
                variant="secondary"
              >
                {t('common.action.login')}
              </Button>
            </Stack>
          </Column>
          <Box
            marginLeft="medium"
            marginTop={{ mobile: 'large', tablet: 'none', desktop: 'none' }}
          >
            <ShowLoginIllustration />
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};
