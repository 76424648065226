import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { JOB_TYPES } from '@seek/je-shared-data';
import { BrandName } from '@seek/je-shared-data/lib/types/brand';
import { useAppSelector } from '@store/hooks/useAppSelector';
import { getLocalisation } from '@store/localisation';
import { Item } from '@components/FormElements/Dropdown/Dropdown';
import i18next from '../../../../../../joraEmployer/translations/i18n/i18next-server';

const employmentTypeOptions = (
  t: TFunction,
  brandName?: BrandName
): Item<string>[] => {
  const translate =
    brandName === BrandName.JOBSTREETEXPRESS ? i18next.getFixedT('en') : t;

  return [
    {
      label: translate(`postJobForm.jobType.${JOB_TYPES.FULL_TIME}`),
      value: JOB_TYPES.FULL_TIME,
      testID: `jobType-${JOB_TYPES.FULL_TIME}`
    },
    {
      label: translate(`postJobForm.jobType.${JOB_TYPES.PART_TIME}`),
      value: JOB_TYPES.PART_TIME,
      testID: `jobType-${JOB_TYPES.PART_TIME}`
    },
    {
      label: translate(`postJobForm.jobType.${JOB_TYPES.CASUAL}`),
      value: JOB_TYPES.CASUAL,
      testID: `jobType-${JOB_TYPES.CASUAL}`
    }
  ];
};

export const useJobType = () => {
  const { t } = useTranslation();
  const { brandConfig } = useAppSelector(getLocalisation);

  const jobTypeOptions = employmentTypeOptions(t, brandConfig?.name);

  return { jobTypeOptions };
};
