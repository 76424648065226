import { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import Div from '../../../../../common/components/Div/Div';
import CardFieldInput from '../../../../../common/components/FormElements/CardFieldInput/CardFieldInput';

@withTranslation()
export default class PaymentInputFields extends Component {
  static propTypes = {
    onReady: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    'data-test-key': PropTypes.string,
    t: PropTypes.func.isRequired
  };

  render() {
    const { onChange, onReady, 'data-test-key': dataTestKey, t } = this.props;

    return (
      <Div>
        <Field
          component={CardFieldInput}
          name="cardDetails"
          fieldId="cardDetails"
          validationMessages={t('validations.payment.cardDetails', {
            returnObjects: true
          })}
          data-test-key={dataTestKey}
          onChange={onChange}
          onReady={onReady}
        />
      </Div>
    );
  }
}
