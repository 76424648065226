import { useTheme } from '@theme/DesignSystemProvider/ThemeContext';
import { LogoSize } from '@theme/tokens';
import { useResolveToken } from '@theme/tokens/utils/useResolveToken';

export type LogoStyleProps = {
  /**
   * (Opt) The width size that will be logo
   */
  width?: LogoSize | Number;
  /**
   * (Opt) The height size that will be logo
   */
  height?: LogoSize | Number;
  /**
   * (Opt) The theme of the logo
   */
  theme?: 'light' | 'dark';
};

export function useLogoStyle({
  width = 'standard',
  height = 'small',
  theme = 'light'
}: LogoStyleProps) {
  const {
    logo: { sizes, color: logoColor }
  } = useTheme();
  const { resolveToken } = useResolveToken();

  const backgroundColor = resolveToken(logoColor, theme)?.background;

  const dimension = {
    height:
      typeof height === 'number'
        ? height
        : resolveToken(sizes, height as LogoSize),
    width:
      typeof width === 'number' ? width : resolveToken(sizes, width as LogoSize)
  };

  return {
    ...dimension,
    backgroundColor
  };
}
