import { Component } from 'react';
import styles from './Label.scss';
import PropTypes from 'prop-types';

export default class Label extends Component {
  static propTypes = {
    labelFor: PropTypes.string,
    children: PropTypes.node
  };

  render() {
    const { labelFor, children } = this.props;

    return (
      <label className={styles.root} htmlFor={labelFor}>{children}</label>
    );
  }
}