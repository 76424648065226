import React, { useContext } from 'react';
import { forwardRef } from 'react';
import { PressableProps, View } from 'react-native';
import '@components/Button/useButtonStyle';
import { Tab as PrimitiveTab } from '@components/Tabs';
import { TabListContext } from './TabListContext';
import { TabsContext } from './TabsContext';

export type TabProps = {
  children?: string;
  testID?: PressableProps['testID'];
};

export const Tab = forwardRef<View, TabProps>((props, ref) => {
  const { tabIndex, onTabPress } = useContext(TabListContext);
  const { selectedTab, setSelectedTab } = useContext(TabsContext);

  const isSelected = selectedTab === tabIndex;

  const handleOnPress = () => {
    if (onTabPress) {
      onTabPress(tabIndex);
    }

    setSelectedTab(tabIndex);
  };

  return (
    <PrimitiveTab
      {...props}
      isSelected={isSelected}
      handleOnPress={handleOnPress}
      ref={ref}
    />
  );
});
