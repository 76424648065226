import { usePaymentContext } from '@common/components/FormElements/PaymentProvider/PaymentContext';
import { openExternalURL } from '@common/helpers/externalConnection';
import { useAppDispatch } from '@store/hooks';
import { confirmIdBankTransferPayment as confirmIdBankTransferPaymentAction } from '@store/payment/stripeApi';

export type ConfirmIdBankTransferPayment = ({
  data,
  successfulRedirectUrl
}: {
  data: PaymentData;
  successfulRedirectUrl: string;
}) => Promise<any>;

type PaymentData = {
  email: string;
  fullName: string;
  country: string;
  bankName?: string;
  brand: string;
};

const getHostedInstructionsFromResponse = (response) => {
  return response?.result?.next_action?.display_bank_transfer_instructions
    ?.hosted_instructions_url;
};

export function useConfirmIdBankTransferPayment() {
  const { paymentIntentId, clientId } = usePaymentContext();
  const dispatch = useAppDispatch();

  const confirmIdBankTransferPayment = async ({
    data,
    successfulRedirectUrl
  }) => {
    const response = await dispatch(
      confirmIdBankTransferPaymentAction({
        stripeConfig: {
          native: { paymentIntentId, clientId }
        },
        data,
        successfulRedirectUrl
      })
    );

    openExternalURL(getHostedInstructionsFromResponse(response));
  };

  return {
    confirmIdBankTransferPayment
  };
}
