import React from 'react';
import { useTranslation } from 'react-i18next';
import { RevealText } from '@common/components/RevealText';
import { TextWithIcon } from '@common/components/TextWithIcon';
import { truncateStringWithEllipsis } from '@common/helpers/textFormatting';
import { PhoneIcon } from '@components/Icon';
import { Link } from '@components/Link';

type RevealPhoneNumberProps = {
  phoneNumber: string;
  onReveal: () => void;
  handlePhoneLinkClicked: () => void;
};

export const RevealPhoneNumber = ({
  phoneNumber,
  handlePhoneLinkClicked,
  onReveal
}: RevealPhoneNumberProps) => {
  const { t } = useTranslation();
  const maxPhoneNumberLength = phoneNumber.length > 8 ? 8 : 4;

  const truncatedText = truncateStringWithEllipsis(
    phoneNumber,
    maxPhoneNumberLength
  );

  return (
    <TextWithIcon
      icon={
        <PhoneIcon
          size="small"
          tone="success"
          variant="outline"
          testID="phone-icon"
        />
      }
    >
      <RevealText
        truncatedText={truncatedText}
        revealActionText={t('applications.applicationCard.showNumber')}
        onRevealPressed={async () => await onReveal()}
      >
        <Link testID="phone-number-link" onPress={handlePhoneLinkClicked}>
          {phoneNumber}
        </Link>
      </RevealText>
    </TextWithIcon>
  );
};
