import { BrandName } from '@seek/je-shared-data/lib/types/brand';

export const SOCIAL_MEDIA_PLATFORM = {
  FACEBOOK: 'facebook',
  LINKEDIN: 'linkedin',
  WHATSAPP: 'whatsapp'
};

export async function handleSocialLinkClick(event, platform) {
  const { job: { id, liveJobLink }, handleSocialIconClicked, brandName, t } = this.props;
  const campaignBrand = brandName !== BrandName.JOBSTREETEXPRESS ? BrandName.JORA : 'jse';
  const socialPostText = `${t('dashboardPage.shareAd.sharePostContent')}`;
  const jobPostUrl = `${liveJobLink}?utm_campaign=${campaignBrand}-employer-share-link&utm_medium=referral&utm_source=${platform}`;

  if (event && event.preventDefault) {
    event.preventDefault();
    let socialUrl = '';

    await handleSocialIconClicked({
      id,
      platform
    });

    switch (platform) {
      case SOCIAL_MEDIA_PLATFORM.FACEBOOK:
        socialUrl = `https://facebook.com/sharer/sharer.php?u=${encodeURIComponent(jobPostUrl)}&quote=${encodeURIComponent(socialPostText)}`;
        window.open(`${socialUrl}`, '_blank');
        break;
      case SOCIAL_MEDIA_PLATFORM.LINKEDIN:
        socialUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(jobPostUrl)}`;
        window.open(`${socialUrl}`, '_system');
        break;
      case SOCIAL_MEDIA_PLATFORM.WHATSAPP:
        socialUrl = `https://wa.me/?text=${encodeURIComponent(socialPostText + '\n' + jobPostUrl)}`;
        window.open(`${socialUrl}`, '_system');
        break;
      default:
        return this.copyJobUrlToClipBoard(jobPostUrl);
    }
  }
}
