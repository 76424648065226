import { TFunction } from 'i18next';
import { localisedFormatDate } from '@common/helpers/dateTime';
import { ApplicationInformation } from '@common/hooks/useAppQuery/queries/useGetApplications';
import { JOB_STATUS } from '@seek/je-shared-data';

/**
 * Generates an expiry warning message based on the application information and status.
 * @param {Object} options - The options for generating the expiry warning.
 * @param {TFunction} options.t - The translation function.
 * @param {ApplicationInformation} options.applicationInformation - The application information.
 * @param {JOB_STATUS} options.status - The job status.
 * @returns {string} The expiry warning message.
 */
export const expiryWarning = ({
  t,
  applicationInformation,
  status
}: {
  t: TFunction;
  applicationInformation: ApplicationInformation | undefined;
  status: JOB_STATUS | undefined;
}): string => {
  if (!applicationInformation?.applicationsAvailability) return '';

  const {
    applicationsAvailability: { available, unavailableAt }
  } = applicationInformation;

  if (available && status === JOB_STATUS.EXPIRED) {
    return whenAvailableAndExpired(t, unavailableAt);
  }

  if (!available) return whenUnavailable(t, unavailableAt);

  return '';
};

const whenAvailableAndExpired = (t: TFunction, unavailableAt: string) =>
  t('applications.postExpiryWarning', {
    date: localisedFormatDate(t, 'dd MMM yyyy', unavailableAt)
  });

const whenUnavailable = (t: TFunction, unavailableAt: string) =>
  t('applications.preExpiryWarning', {
    date: localisedFormatDate(t, 'dd MMM yyyy', unavailableAt)
  });
