import React from 'react';
import { FlatList } from 'react-native';
import type { ProfileApplication } from 'je-bff/src/applications/list/transformers';
import { ApplicationListItem } from './ApplicationListItem';
import { useApplicationList } from './useApplicationList';

interface ApplicationListWrapperProps {
  jobId: string;
  applications: ProfileApplication[];
}

export const ApplicationListWrapper = ({
  jobId,
  applications
}: ApplicationListWrapperProps) => {
  const { jobCountryCode } = useApplicationList(jobId);
  return (
    <FlatList
      data={applications}
      renderItem={({ item: application, index }) => {
        return (
          <ApplicationListItem
            application={application}
            index={index}
            jobCountryCode={jobCountryCode}
          />
        );
      }}
    />
  );
};
