import jsCookies from 'js-cookie';

export function save({ key, serializedData, allowSecureCookies }) {
  jsCookies.set(key, serializedData, {
    expires: 1460,
    sameSite: 'strict',
    secure: allowSecureCookies
  });
}

export function get({
  key,
  cookies
}: {
  key: string;
  cookies?: Record<string, unknown>;
}) {
  return cookies?.[key];
}
