import React from 'react';
import { ApplicationInformation } from '@common/hooks/useAppQuery/queries/useGetApplications';
import { ListJobItem } from '@store/entities/jobs';
import { CardFooter } from '@components/Card';
import { ApplicationsAction } from './components/ApplicationsAction';
import { JobActions } from './components/JobActions/JobActions';

type JobCardFooterProps = {
  applicationInformation: ApplicationInformation | undefined;
  job: ListJobItem;
};

export const JobCardFooter = ({
  job,
  applicationInformation
}: JobCardFooterProps) => (
  <CardFooter>
    <ApplicationsAction
      job={job}
      applicationInformation={applicationInformation}
    />
    <JobActions job={job} />
  </CardFooter>
);
