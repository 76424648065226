import pick from 'lodash.pick';
import ReactGA from 'react-ga4';
import { formatEventName } from './format';

export const trackEvent = (action, state) => {
  const metaAnalytics = action.meta && action.meta.analytics;
  if (metaAnalytics) {
    const {
      eventPayload: { event, properties = {}, parameters = {} }
    } = metaAnalytics;
    const {
      id: userId,
      impersonatorToken,
      registeredCountry
    } = state.auth.currentUser || {};

    ReactGA.event(formatEventName(event), {
      ...parameters,
      user_properties: {
        ...properties,
        ...pick(impersonatorToken, ['impersonatorId', 'sessionId']),
        user_id: userId,
        siteId: registeredCountry
      }
    });
  }
};

export const trackPageView = (action, state) => {
  const { payload } = action;
  const { language, countryCode, brandConfig } = state.localisation || {};
  const { id: userId, registeredCountry } = state.auth?.currentUser || {};

  ReactGA.send({
    hitType: 'pageview',
    path: payload.location.pathname,
    user_properties: {
      user_id: userId,
      siteId: registeredCountry,
      siteBrand: brandConfig.name,
      language,
      siteCountryCode: countryCode
    }
  });
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const setUser = (trackingId: string, userId: string, state: any) => {
  ReactGA._gtag('config', trackingId, { user_id: userId });
};
