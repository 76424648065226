import { BrandName } from '@seek/je-shared-data/lib/types/brand';

/**
 * Determines whether to show priority placement over basic and plus benefits.
 *
 * @param {string} params.brand - The brand name.
 * @returns {boolean} - Returns true if priority placement should be shown, false otherwise.
 */
export const showPriorityPlacementOverBasicAndPlus = ({
  brand
}: {
  brand: BrandName;
}): boolean => {
  if (brand === BrandName.JOBSTREETEXPRESS) return true;
  if (brand === BrandName.JOBSDB) return true;
  if (brand === BrandName.JORA) return true;

  return false;
};
