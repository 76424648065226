import { ExactSalary } from '@seek/je-shared-data/lib/types/jobs';

export type useExactAmountProps = {
  value?: ExactSalary['amount'];
  onChange: (string) => void;
};

export const useExactAmount = ({ onChange }: useExactAmountProps) => {
  const onAmountChange = (amount: string) => {
    onChange(amount ? Number(amount) : undefined);
  };

  return {
    onAmountChange
  };
};
