import { useHistory } from 'react-router-native';
import { getUser } from '@store/auth';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';

type UseRedirectEmailVerifiedUserProps = {
  to: string;
};

export function useRedirectEmailVerifiedUser(
  props?: UseRedirectEmailVerifiedUserProps
) {
  const { to } = props || {};
  const { isAuthenticated, currentUser } = useAppSelector(getUser);
  const { emailVerified } = currentUser;
  const { countryCode } = useAppSelector(getLocalisation);
  const history = useHistory();

  if (isAuthenticated && emailVerified) {
    history.push(to || `/${countryCode}/home`);
  }
}
