import { useTranslation } from 'react-i18next';
import { COUNTRY_OPTIONS } from '@common/constants/data';
import { useLimitBusinessCountryCode } from '@common/hooks/featureToggles';
import { useBrandName } from '@common/hooks/localisation';
import { validateCountryCodeForBrand } from '@seek/je-shared-data';

export const useCountrySelector = () => {
  const { t } = useTranslation();
  const brandName = useBrandName();
  const hasLimitedBusinessCountryCode = useLimitBusinessCountryCode(brandName);

  const filteredCountryOptions = hasLimitedBusinessCountryCode
    ? COUNTRY_OPTIONS(t).filter((country) =>
        validateCountryCodeForBrand(brandName, country.value)
      )
    : COUNTRY_OPTIONS(t);

  return { filteredCountryOptions };
};
