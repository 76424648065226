import { Children, Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash.omit';
import ErrorIcon from '../Icons/ErrorIcon';

import styles from './ErrorMessage.scss';

/**
 * @deprecated Use the @common/components/ErrorMessage instead
 **/
export default class ErrorMessage extends Component {
  static propTypes = {
    children: PropTypes.node,
    outsideCard: PropTypes.bool,
    extraTopMargin: PropTypes.bool,
    'data-test-key': PropTypes.string,
    className: PropTypes.string
  };

  containerClass() {
    const { outsideCard } = this.props;

    return (outsideCard) ? styles.outsideCard : styles.root;
  }

  render() {
    const { children, extraTopMargin, 'data-test-key': dataTestKey, className } = this.props;
    const spanClassName = classNames({
      [styles.errorText]: styles.errorText,
      [className]: className
    });

    if (!children) {
      return false;
    }

    return (
      <div {...omit(this.props, 'extraTopMargin', 'outsideCard', 'data-test-key')}
        className={classNames(this.containerClass(), { [styles.extraTopMargin]: extraTopMargin })}>
        {
          Children.map(children, (element) => (
            <div data-test-key={dataTestKey || 'error-message'} className={styles.errorItem}>
              <ErrorIcon theme="dangerFilled" className={styles.icon} />
              <span className={spanClassName}>{element || 'Error'}</span>
            </div>
          ))
        }
      </div>
    );
  }
}