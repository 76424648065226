import { UpdateAccountDetailsFormFields } from '@common/components/Account/forms/updateAccountDetails/types';
import { mapCountryCodeToDiallingCode } from '@common/constants/mapping';
import { User } from '@common/types';
import { parsePhoneNumber } from '@lib/mobileNumberValidation';
import { clearError } from '@store/app';
import { updateAccountDetails } from '@store/auth';
import { useAppDispatch } from '@store/hooks';
import { mapFormData } from '../helpers/mapFormData';
import { mapResponseError } from '../helpers/mapResponseError';
import { useAccountDetailsRedirect } from './useAccountDetailsRedirect';

export const useUpdateAccountDetails = (requestKey: string) => {
  const dispatch = useAppDispatch();
  const { onCancelRedirect, onSubmitRedirect } = useAccountDetailsRedirect();

  const fetchDefaultValues = (currentUser: Partial<User>) => {
    const { givenName, surname, email, mobile } = currentUser as User;
    const parsedMobileNumber = parsePhoneNumber(mobile);

    return {
      givenName,
      surname,
      email,
      confirmEmail: email,
      mobileNumber: {
        diallingCode: mapCountryCodeToDiallingCode(
          parsedMobileNumber?.country?.toLowerCase()
        ),
        phoneNumber: parsedMobileNumber?.nationalNumber.toString() || ''
      }
    };
  };

  const onSubmit = async (
    data: UpdateAccountDetailsFormFields,
    formState: { isDirty: boolean; isValid: boolean }
  ) => {
    if (!formState.isValid) return;
    if (!formState.isDirty) {
      onCancelRedirect();
      return;
    }

    dispatch(clearError(requestKey));

    const { result, error = {} } = await dispatch(
      updateAccountDetails(mapFormData(data))
    );

    if (Object.keys(error).length > 0) {
      return mapResponseError(error);
    }

    onSubmitRedirect(result.emailVerified);
  };

  return {
    fetchDefaultValues,
    onSubmit
  };
};
