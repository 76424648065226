import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RequestErrorMessage } from '@common/components/RequestErrorMessage';
import REQUEST_KEY from '@common/constants/requestKeys';
import { Button } from '@components/Button';
import { Modal } from '@components/Modal';
import { AdditionalComments } from './components/AdditionalComments';
import { Reason } from './components/Reason';
import { useCloseJobModal } from './hooks';
import { CloseJobReason } from './hooks/useReasonOptions';

type CloseJobModalProps = {
  jobTitle: string;
  jobId: string;
  visible: boolean;
  onRequestClose: () => void;
};

export type FormInputs = {
  reason: CloseJobReason;
  additionalComments?: string;
};

export const CloseJobModal = ({
  jobTitle,
  jobId,
  visible,
  onRequestClose
}: CloseJobModalProps) => {
  const { t } = useTranslation();

  const { requestCloseJob, requestCloseModal } = useCloseJobModal({
    jobId,
    onRequestClose
  });

  const { control, handleSubmit, watch } = useForm<FormInputs>({
    mode: 'onSubmit'
  });

  const reason = watch('reason');

  const shouldRenderAdditionalComments =
    reason === CloseJobReason.FilledElsewhere ||
    reason === CloseJobReason.HaventFilled;

  const additionalCommentsLabel =
    reason === CloseJobReason.FilledElsewhere
      ? t('dashboardPage.jobCard.closeJob.where')
      : t('dashboardPage.jobCard.closeJob.why');

  return (
    <Modal
      title={t('dashboardPage.jobCard.closeJob.title', {
        jobTitle
      })}
      visible={visible}
      testID="close-job-modal"
      onRequestClose={requestCloseModal}
    >
      <Reason control={control} />

      {shouldRenderAdditionalComments ? (
        <AdditionalComments control={control} label={additionalCommentsLabel} />
      ) : null}

      <Button onPress={handleSubmit(requestCloseJob)} testID="close-job-button">
        {t('dashboardPage.jobCard.closeJob.action')}
      </Button>

      <RequestErrorMessage
        requestKey={REQUEST_KEY.JOB.DELETE}
        testID="close-job-error"
      />
    </Modal>
  );
};
