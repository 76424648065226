import React from 'react';
import { t } from 'i18next';
import { useRedirect } from '@common/hooks/useRedirect';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';
import { CardActionLink, CardActions } from '@components/Card';
import { EditIcon, TrashIcon } from '@components/Icon';
import { useModal } from '@components/Modal';
import { CloseJobModal } from '../CloseJobModal';

type ActiveJobActionsProps = {
  jobId: string;
  title: string;
};

export const ActiveJobActions = ({ jobId, title }: ActiveJobActionsProps) => {
  const { redirect } = useRedirect();
  const { countryCode } = useAppSelector(getLocalisation);
  const { isModalOpen, openModal, closeModal } = useModal();

  return (
    <CardActions>
      <CardActionLink
        icon={<EditIcon variant="outline" testID="edit-icon" />}
        text={t('dashboardPage.action.edit')}
        onPress={redirect(`/${countryCode}/jobs/${jobId}/edit-job`)}
      />

      <>
        <CloseJobModal
          jobTitle={title}
          jobId={jobId}
          visible={isModalOpen}
          onRequestClose={closeModal}
        />
        <CardActionLink
          icon={<TrashIcon variant="outline" testID="trash-icon" />}
          text={t('dashboardPage.action.close')}
          onPress={openModal}
        />
      </>
    </CardActions>
  );
};
