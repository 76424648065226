import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextWithIcon } from '@common/components/TextWithIcon';
import { When } from '@common/components/When';
import { MortarboardIcon } from '@components/Icon';
import { Text } from '@components/Text';

type HighestEducationInfoProps = {
  highestEducation?: string;
};

export const HighestEducationInfo = ({
  highestEducation
}: HighestEducationInfoProps) => {
  const { t } = useTranslation();

  const earthIcon = (
    <MortarboardIcon
      size="small"
      tone="success"
      variant="outline"
      testID="mortarboard-icon"
    />
  );

  return (
    <When condition={!!highestEducation}>
      <TextWithIcon
        icon={earthIcon}
        alignItems="flex-start"
        marginTop="xxsmall"
      >
        <Text>
          {t(
            `applications.applicationCard.aboutMe.highestEducation.${highestEducation}`
          )}
        </Text>
      </TextWithIcon>
    </When>
  );
};
