import { formatIsoToDate } from '@common/helpers/dateTime';
import { CreateJobItem } from './types';

export function toFormattedJob(job): CreateJobItem {
  return {
    id: job.id,
    company: job.company,
    countryCode: job.countryCode,
    description: job.description,
    jobType: job.jobType,
    location: job.location,
    title: job.title,
    rightToWork: job.rightToWork,
    workExperience: job.workExperience,
    createdAt: job.createdAt,
    email: job.email,
    status: job.status,
    ...(job.startDate
      ? { startDate: formatIsoToDate(job.startDate, 'DD/MM/YYYY') }
      : {})
  };
}
