import React from 'react';
import { useTranslation } from 'react-i18next';
import { localisedFormatDate } from '@common/helpers/dateTime';
import { Badge, BadgeTone } from '@components/Badge';
import { Box } from '@components/Box';
import { Row } from '@components/Row';
import { Text } from '@components/Text';

type UserPackageProps = {
  packageType?: string;
  expiryDate?: Date | null;
};

export const UserPackage = ({ packageType, expiryDate }: UserPackageProps) => {
  const { t } = useTranslation();

  if (!packageType) return null;

  const packageColour: Record<string, BadgeTone> = {
    free: 'neutral',
    paid: 'success'
  };

  return (
    <Row alignItems="center" spacing="xsmall" tone="transparent">
      <Badge tone={packageColour[packageType]}>
        {t(`account.userPackage.packageType.${packageType}`)}
      </Badge>

      {expiryDate && (
        <Box tone="transparent">
          <Text tone="neutralLight" size="small">
            {t('account.userPackage.expiry', {
              expiryDate: localisedFormatDate(t, 'dd MMM yyyy', expiryDate)
            })}
          </Text>
        </Box>
      )}
    </Row>
  );
};
