import dayjs from 'dayjs';
import { localisedFormatDate } from '@common/helpers/dateTime';
import { Job } from '@common/types';
import { AD_TYPE, JOB_STATUS } from '@seek/je-shared-data';
import { getExpiryText } from '@src/job/expiry';
import { isSameCaseInsensitive } from './textHelper';

export const isPermittedToShowApplicationInformation = (
  status: JOB_STATUS | undefined
) =>
  status !== undefined &&
  [JOB_STATUS.LIVE, JOB_STATUS.CLOSED, JOB_STATUS.EXPIRED].includes(status);

export const isJobAdBasic = (adType: AD_TYPE | undefined) =>
  adType === undefined || ![AD_TYPE.PLUS, AD_TYPE.MAX].includes(adType);

export const isJobInactive = (status: JOB_STATUS) =>
  [
    JOB_STATUS.CLOSED,
    JOB_STATUS.EXPIRED,
    JOB_STATUS.EXPIRED_BUT_NEVER_LIVE
  ].includes(status);

export const isJobActive = (status: JOB_STATUS) => !isJobInactive(status);

export const isJobClosedOrExpired = (status) =>
  [JOB_STATUS.CLOSED, JOB_STATUS.EXPIRED].includes(status);

export function formatRecruitmentCompanyName({
  t,
  name,
  recruitmentCompanyName
}: {
  t: (key: string, options) => string;
  name: string;
  recruitmentCompanyName: string;
}) {
  if (!t) {
    throw new Error('t was not provided!');
  }

  if (isSameCaseInsensitive(name, recruitmentCompanyName)) {
    return recruitmentCompanyName;
  }

  return t('jobs.onBehalfOfRecruitment', { recruitmentCompanyName, name });
}

export function shouldShowApplicationCount(job: Job) {
  const { status } = job;

  return (
    status !== undefined &&
    ![
      JOB_STATUS.IN_REVIEW,
      JOB_STATUS.ACTION_REQUIRED,
      JOB_STATUS.INVALID_AD,
      JOB_STATUS.EXPIRED_BUT_NEVER_LIVE,
      JOB_STATUS.USER_BANNED,
      JOB_STATUS.CRAWLER_REJECTED
    ].includes(status)
  );
}

export function getJobStatusDateText(
  t: (key: string, options?) => string,
  job: Job
) {
  const { expiredAt, updatedAt, status } = job;

  switch (status) {
    case JOB_STATUS.EXPIRED:
    case JOB_STATUS.EXPIRED_BUT_NEVER_LIVE: {
      const localisedExpiredDate = localisedFormatDate(
        t,
        'dd MMM yyyy',
        expiredAt
      );
      return t(
        'jobDetailsAndApplicationsListPage.explanationText.jobExpireDate.expiredOn',
        { date: localisedExpiredDate }
      );
    }
    case JOB_STATUS.LIVE: {
      return getExpiryText({ t, expiredAt });
    }
    case JOB_STATUS.CLOSED: {
      const localisedClosedDate = localisedFormatDate(
        t,
        'dd MMM yyyy',
        updatedAt
      );
      return t('jobDetailsAndApplicationsListPage.label.closedOn', {
        date: localisedClosedDate
      });
    }
    default:
      return;
  }
}

export const getJobAge = (jobCreatedAt: Job['createdAt']) =>
  dayjs().diff(dayjs(jobCreatedAt), 'day');

export function getStatusTheme(status: JOB_STATUS) {
  switch (status) {
    case JOB_STATUS.LIVE:
      return 'success';
    case JOB_STATUS.IN_REVIEW:
      return 'warning';
    case JOB_STATUS.INVALID_AD:
    case JOB_STATUS.ACTION_REQUIRED:
    case JOB_STATUS.USER_BANNED:
    case JOB_STATUS.CRAWLER_REJECTED:
      return 'danger';
    default:
      return 'default';
  }
}

export function getStatusTone(status: JOB_STATUS) {
  switch (status) {
    case JOB_STATUS.LIVE:
      return 'success';
    case JOB_STATUS.IN_REVIEW:
      return 'warning';
    case JOB_STATUS.INVALID_AD:
    case JOB_STATUS.ACTION_REQUIRED:
    case JOB_STATUS.USER_BANNED:
    case JOB_STATUS.CRAWLER_REJECTED:
      return 'danger';
    default:
      return 'neutralDark';
  }
}
