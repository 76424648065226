import queryString from 'query-string';
import { push } from 'redux-first-history';
import reduxActions from 'redux-form/lib/actions';
import { EventTypes } from 'redux-segment';
import { VerifyAccountDetailsFormValues } from '@common/components/VerifyAccountDetailsForm';
import { isNative } from '@common/helpers/detection';
import * as draftFormStore from '@common/helpers/draftFromStore';
import { ClientError, ErrorPreventLogging } from '@common/helpers/errors';
import { FetchActionResultSuccess } from '@common/store/middleware/clientMiddlewareTypes';
import { User } from '@common/types';
import { parsePhoneNumber } from '@lib/mobileNumberValidation';
import { AUTH_EXCEPTION, GENERAL_EXCEPTION } from '@seek/je-error-helper';
import { AppealStatus } from '@seek/je-shared-data/lib/types/authentication';
import REQUEST_KEY from '../../constants/requestKeys';
import { updateError } from '../app/actions';
import { clear as clearUserData } from '../userData/actions';
import * as constants from './constants';
import {
  ErrorResponse,
  UpdateAccountDetailsAction,
  UpdateAccountDetailsActionProps
} from './types';

const { updateSyncErrors, setSubmitFailed } = reduxActions;

export type AuthAction = {
  type: string;
  types: string[];
  result: User;
};

export function authenticated(userdata: User) {
  return {
    type: constants.AUTHENTICATED,
    result: userdata
  };
}

function getLoginRedirectionUrl({ pathQueries, body, countryCode, jobDraft }) {
  const hasPostJobDraft = Object.keys(jobDraft).length > 0;
  let redirectTo = hasPostJobDraft
    ? `/${countryCode}/post-job`
    : `/${countryCode}/home`;

  const { isVerified, appealStatus, emailVerified } = body;
  const {
    LOGIN_REDIRECT_QUERY_PARAMS: {
      EDIT_JOB,
      REPOST_JOB,
      UPGRADE_JOB,
      VERIFY_ACCOUNT
    }
  } = constants;
  const {
    [EDIT_JOB]: editJobId,
    [REPOST_JOB]: originalJobId,
    [UPGRADE_JOB]: upgradeJobId,
    [VERIFY_ACCOUNT]: shouldVerifyAccount
  } = queryString.parse(pathQueries);

  if (!emailVerified) {
    redirectTo = `/${countryCode}/account/verify-email`;
  } else if (originalJobId) {
    redirectTo = `/${countryCode}/jobs/${originalJobId}/repost-job`;
  } else if (editJobId) {
    redirectTo = `/${countryCode}/jobs/${editJobId}/edit-job`;
  } else if (upgradeJobId) {
    redirectTo = `/${countryCode}/jobs/${upgradeJobId}/upgrade-job`;
  } else if (
    shouldVerifyAccount ||
    (isVerified === false && appealStatus === AppealStatus.REQUESTED)
  ) {
    redirectTo = `/${countryCode}/account/verify-account-details`;
  } else if (isVerified === false && appealStatus === AppealStatus.SUBMITTED) {
    redirectTo = `/${countryCode}/account/verify-account-details-success`;
  }

  return redirectTo;
}

export function login({ email, password }, pathQueries) {
  return {
    types: [constants.LOGIN, constants.AUTHENTICATED, constants.LOGIN_FAIL],
    promise: async (dispatch, getState, fetch) => {
      const { config, draft, localisation } = getState();
      const authenticationServiceBaseUrl =
        config.endpoints.authenticationService;

      const body = await fetch(
        `${authenticationServiceBaseUrl}/login`,
        {
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify({
            username: email,
            password
          })
        },
        {
          requestKey: REQUEST_KEY.AUTH.LOGIN,
          showGlobalSpinner: true,
          defaultError: 'errors.authentication.login.signInError',
          mapErrorContext: {
            username: 'email'
          },
          mapFormError: {
            [AUTH_EXCEPTION.ATTEMPTS_EXCEEDED]: 'common.errors.attemptLimit'
          }
        }
      );

      const redirectTo = getLoginRedirectionUrl({
        pathQueries,
        body,
        countryCode: localisation.countryCode,
        jobDraft: draft.job
      });

      await dispatch(authenticated(body));
      dispatch(push(redirectTo));

      return body;
    }
  };
}

function trackForgotPasswordSuccess() {
  return {
    type: constants.FORGOT_PASSWORD_SUCCESS_TRACK,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'Reset password email sent'
        }
      }
    }
  };
}

export function forgotPassword(form) {
  return {
    types: [
      constants.FORGOT_PASSWORD,
      constants.FORGOT_PASSWORD_SUCCESS,
      constants.FORGOT_PASSWORD_FAIL
    ],
    promise: async (dispatch, getState, fetch) => {
      const authenticationServiceBaseUrl =
        getState().config.endpoints.authenticationService;
      const { countryCode } = getState().localisation;

      await fetch(
        `${authenticationServiceBaseUrl}/forgot-password`,
        {
          headers: {
            'country-code': countryCode
          },
          method: 'POST',
          body: JSON.stringify(form),
          credentials: 'include'
        },
        {
          requestKey: REQUEST_KEY.AUTH.FORGOT_PASSWORD,
          showGlobalSpinner: true,
          mapFormError: {
            [AUTH_EXCEPTION.ATTEMPTS_EXCEEDED]:
              'errors.authentication.forgotPassword.limitExceededException'
          }
        }
      );

      dispatch(trackForgotPasswordSuccess());
      dispatch(push(`/${countryCode}/forgot-password-success`));

      return {
        email: form.email
      };
    }
  };
}

export function register(data, formName) {
  return {
    types: [
      constants.REGISTER,
      constants.AUTHENTICATED,
      constants.REGISTER_FAIL
    ],
    promise: async (
      dispatch,
      getState,
      fetch
    ): Promise<{
      error: ErrorResponse;
    }> => {
      const {
        mobile,
        password,
        email,
        givenName,
        surname,
        impersonatorId,
        expressVerificationToken,
        UTM_Source,
        UTM_Medium,
        UTM_Campaign,
        recaptchaResponse
      } = data;
      const authenticationServiceBaseUrl =
        getState().config.endpoints.authenticationService;
      const { countryCode, language } = getState().localisation;

      return await fetch(
        `${authenticationServiceBaseUrl}/register`,
        {
          headers: {
            'x-google-recaptcha': recaptchaResponse,
            'country-code': countryCode,
            language: language
          },
          method: 'POST',
          body: JSON.stringify({
            mobile,
            password,
            email,
            givenName,
            surname,
            impersonatorId,
            expressVerificationToken,
            UTM_Source,
            UTM_Medium,
            UTM_Campaign
          }),
          credentials: 'include'
        },
        {
          requestKey: REQUEST_KEY.AUTH.REGISTER,
          showGlobalSpinner: true,
          mapErrorContext: {
            mobile: 'mobileNumber'
          },
          onResponseError: (body) => {
            if (!isNative()) {
              // todo: wrap this
              (window as any).grecaptcha.reset();
            }

            if (body.context) {
              dispatch(updateSyncErrors(formName, body.context));
              dispatch(setSubmitFailed(formName));
            }
          }
        }
      );
    }
  };
}

function trackResetPasswordSuccess({ userId }) {
  return {
    type: constants.RESET_PASSWORD_SUCCESS_TRACK,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'Reset password success',
          properties: {
            userId
          }
        }
      }
    }
  };
}

export function resetPassword(resetPasswordDetails) {
  return {
    types: [
      constants.RESET_PASSWORD,
      constants.RESET_PASSWORD_SUCCESS,
      constants.RESET_PASSWORD_FAIL
    ],
    promise: async (dispatch, getState, fetch) => {
      const authenticationServiceBaseUrl =
        getState().config.endpoints.authenticationService;
      const { countryCode } = getState().localisation;

      await fetch(
        `${authenticationServiceBaseUrl}/reset-password`,
        {
          method: 'POST',
          body: JSON.stringify(resetPasswordDetails),
          credentials: 'include'
        },
        {
          requestKey: REQUEST_KEY.AUTH.RESET_PASSWORD,
          showGlobalSpinner: true,
          mapErrorContext: {
            password: 'newPassword'
          },
          mapFormError: {
            [AUTH_EXCEPTION.CODE_EXPIRED]:
              'errors.authentication.resetPassword.expiredCodeException',
            [AUTH_EXCEPTION.CODE_MISMATCH]:
              'errors.authentication.resetPassword.expiredCodeException',
            [AUTH_EXCEPTION.ATTEMPTS_EXCEEDED]:
              'errors.authentication.resetPassword.limitExceededException'
          }
        }
      );

      dispatch(
        trackResetPasswordSuccess({ userId: resetPasswordDetails.userId })
      );
      dispatch(push(`/${countryCode}/reset-password-success`));
    }
  };
}

export function updatePassword({ currentPassword, newPassword }) {
  return {
    types: [
      constants.UPDATE_PASSWORD,
      constants.UPDATE_PASSWORD_SUCCESS,
      constants.UPDATE_PASSWORD_FAIL
    ],
    promise: async (dispatch, getState, fetch) => {
      const authenticationServiceBaseUrl =
        getState().config.endpoints.authenticationService;
      const { countryCode } = getState().localisation;

      await fetch(
        `${authenticationServiceBaseUrl}/password`,
        {
          method: 'PUT',
          body: JSON.stringify({ currentPassword, newPassword }),
          credentials: 'include'
        },
        {
          requestKey: REQUEST_KEY.AUTH.UPDATE_PASSWORD,
          showGlobalSpinner: true,
          mapFormError: {
            [AUTH_EXCEPTION.ATTEMPTS_EXCEEDED]: 'common.errors.attemptLimit',
            [AUTH_EXCEPTION.INCORRECT_CURRENT_PASSWORD]:
              'errors.updatePassword.incorrectCurrentPassword'
          }
        }
      );

      dispatch(push(`/${countryCode}/account`));
    }
  };
}

export type UpdateAccountDetailsActionSuccess = FetchActionResultSuccess<
  typeof constants.UPDATE_ACCOUNT_DETAILS_SUCCESS,
  UpdateAccountDetailsAction
>;

export function updateAccountDetails({
  email,
  mobileNumber,
  diallingCode,
  givenName,
  surname
}: UpdateAccountDetailsActionProps) {
  return {
    types: [
      constants.UPDATE_ACCOUNT_DETAILS,
      constants.UPDATE_ACCOUNT_DETAILS_SUCCESS,
      constants.UPDATE_ACCOUNT_DETAILS_FAIL
    ],
    promise: async (
      dispatch,
      getState,
      fetch
    ): Promise<UpdateAccountDetailsAction> => {
      const {
        auth: { currentUser }
      } = getState();
      const authenticationServiceBaseUrl =
        getState().config.endpoints.authenticationService;

      const parsedMobileNumber = parsePhoneNumber(
        `${diallingCode}${mobileNumber}`
      );
      const mobile = parsedMobileNumber
        ? parsedMobileNumber.number.toString()
        : '';

      await fetch(
        `${authenticationServiceBaseUrl}/me`,
        {
          method: 'PUT',
          body: JSON.stringify({
            email,
            mobile,
            givenName,
            surname
          }),
          credentials: 'include'
        },
        {
          requestKey: REQUEST_KEY.AUTH.UPDATE_ACCOUNT,
          showGlobalSpinner: true,
          mapErrorContext: {
            mobile: 'mobileNumber'
          },
          mapFormError: {
            [AUTH_EXCEPTION.ATTEMPTS_EXCEEDED]: 'common.errors.attemptLimit',
            [AUTH_EXCEPTION.EMAIL_EXIST]: 'common.errors.emailAlreadyExists',
            [AUTH_EXCEPTION.MOBILE_EXIST]: 'common.errors.mobileAlreadyExists'
          }
        }
      );

      return {
        givenName,
        surname,
        email,
        mobile,
        emailVerified: email === currentUser.email
      };
    }
  };
}

type LogoutResult = {
  meta: {
    analytics: {
      eventType: 'track';
      eventPayload: {
        event: string;
      };
    };
  };
};

export type LogoutActionSuccess = FetchActionResultSuccess<
  typeof constants.LOGOUT_SUCCESS,
  LogoutResult,
  LogoutResult['meta']
>;

export function logout({ initiatedByUser, to = '' }) {
  return {
    types: [constants.LOGOUT, constants.LOGOUT_SUCCESS, constants.LOGOUT_FAIL],
    promise: async (dispatch, getState, fetch) => {
      const authenticationServiceBaseUrl =
        getState().config.endpoints.authenticationService;
      const id = getState().auth.currentUser.id;
      const { localisation } = getState();
      const redirectUrl = to || `/${localisation.countryCode}/`;

      await fetch(
        `${authenticationServiceBaseUrl}/logout`,
        {
          method: 'POST',
          body: JSON.stringify({ id }),
          credentials: 'include'
        },
        {
          requestKey: REQUEST_KEY.AUTH.LOGOUT,
          showGlobalSpinner: true,
          defaultError: 'errors.authentication.logout.error'
        }
      );

      draftFormStore.clearAll();
      dispatch(clearUserData('source.query'));
      dispatch({ type: constants.LOGOUT_SUCCESS });
      dispatch(push(redirectUrl));

      if (initiatedByUser) {
        return {
          meta: {
            analytics: {
              eventType: EventTypes.track,
              eventPayload: {
                event: 'User signed out'
              }
            }
          }
        };
      }
    }
  };
}

export function submitAccountAppeal({
  businessRegistrationNumber,
  website,
  additionalInformation
}: VerifyAccountDetailsFormValues) {
  return {
    types: [
      constants.SUBMIT_ACCOUNT_APPEAL,
      constants.SUBMIT_ACCOUNT_APPEAL_SUCCESS,
      constants.SUBMIT_ACCOUNT_APPEAL_FAIL
    ],
    promise: async (dispatch, getState, fetch) => {
      const authenticationServiceBaseUrl =
        getState().config.endpoints.authenticationService;

      return await fetch(
        `${authenticationServiceBaseUrl}/submit-user-appeal`,
        {
          method: 'POST',
          body: JSON.stringify({
            businessRegistrationNumber,
            website,
            ...(additionalInformation
              ? { appealAnswer: additionalInformation }
              : {})
          }),
          credentials: 'include'
        },
        {
          requestKey: REQUEST_KEY.AUTH.SUBMIT_ACCOUNT_APPEAL,
          showGlobalSpinner: true
        }
      );
    }
  };
}

function trackVerifyEmailSuccess() {
  return {
    type: constants.VERIFY_EMAIL_SUCCESS_TRACK,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'user__sign_up_successful'
        }
      }
    }
  };
}

export function verifyEmail({ verificationCode }) {
  return {
    types: [
      constants.VERIFY_EMAIL,
      constants.VERIFY_EMAIL_SUCCESS,
      constants.VERIFY_EMAIL_FAIL
    ],
    promise: async (dispatch, getState, fetch) => {
      const authenticationServiceBaseUrl =
        getState().config.endpoints.authenticationService;

      const result = await fetch(
        `${authenticationServiceBaseUrl}/verify-email`,
        {
          method: 'POST',
          body: JSON.stringify({
            verificationCode: verificationCode?.trim()
          }),
          credentials: 'include'
        },
        {
          requestKey: REQUEST_KEY.AUTH.VERIFY_EMAIL,
          showGlobalSpinner: true,
          mapFormError: {
            [AUTH_EXCEPTION.CODE_EXPIRED]:
              'validations.user.verifyEmail.expired',
            [AUTH_EXCEPTION.CODE_MISMATCH]:
              'validations.user.verifyEmail.mismatch',
            [AUTH_EXCEPTION.ATTEMPTS_EXCEEDED]: 'common.errors.attemptLimit'
          }
        }
      );
      dispatch(trackVerifyEmailSuccess());

      return result;
    }
  };
}

function handleGetUserDetailsError(body) {
  const { code, guid } = body;

  switch (code) {
    case GENERAL_EXCEPTION.UNAUTHORISED:
      throw new ErrorPreventLogging(code);
    default:
      throw new ClientError('errors.defaultWithGuid', {
        guid,
        responseBody: body
      });
  }
}

export function getUserDetails() {
  return {
    types: [
      constants.GET_ACCOUNT_DETAILS,
      constants.GET_ACCOUNT_DETAILS_SUCCESS,
      constants.GET_ACCOUNT_DETAILS_FAIL
    ],
    promise: async (dispatch, getState, fetch) => {
      const authenticationServiceBaseUrl =
        getState().config.endpoints.authenticationService;

      return await fetch(
        `${authenticationServiceBaseUrl}/get-account-details`,
        {
          method: 'GET',
          credentials: 'include'
        },
        {
          requestKey: REQUEST_KEY.AUTH.GET_ACCOUNT_DETAILS,
          showGlobalSpinner: true,
          onResponseError: handleGetUserDetailsError
        }
      );
    }
  };
}

export function sendVerifyEmail(isResend) {
  return {
    types: [
      constants.RESEND_VERIFY_EMAIL,
      constants.RESEND_VERIFY_EMAIL_SUCCESS,
      constants.RESEND_VERIFY_EMAIL_FAIL
    ],
    promise: async (dispatch, getState, fetch) => {
      const authenticationServiceBaseUrl =
        getState().config.endpoints.authenticationService;
      const queryStrings = isResend ? '?isResend=true' : '';

      return await fetch(
        `${authenticationServiceBaseUrl}/send-verification-code${queryStrings}`,
        {
          method: 'POST',
          credentials: 'include'
        },
        {
          requestKey: REQUEST_KEY.AUTH.RESEND_VERIFY_EMAIL,
          showGlobalSpinner: true,
          mapFormError: {
            [AUTH_EXCEPTION.ATTEMPTS_EXCEEDED]: 'common.errors.attemptLimit'
          }
        }
      );
    }
  };
}

export async function handleRefreshUserDetails({ dispatch, getState }) {
  await dispatch(getUserDetails());

  const getUserDetailsError =
    getState().app.error[REQUEST_KEY.AUTH.GET_ACCOUNT_DETAILS];
  const { isAuthenticated, currentUser } = getState().auth;
  const isUserAccessSuspended =
    currentUser?.isVerified === false &&
    currentUser?.appealStatus === AppealStatus.DECLINED;

  if (getUserDetailsError || isUserAccessSuspended) {
    const shouldLogUserOut =
      (isAuthenticated && getUserDetailsError) || isUserAccessSuspended;

    if (shouldLogUserOut) {
      await dispatch(logout({ initiatedByUser: false }));
    }
  }

  return;
}

export function updateRecaptchaError(err) {
  return (dispatch) => {
    updateError(
      dispatch,
      REQUEST_KEY.RECAPTCHA,
      new Error('Error with recaptcha', err)
    );
  };
}

export function trackInvalidFormatVerifyEmailCodeValidationError() {
  return (dispatch) => {
    dispatch({
      type: 'track',
      meta: {
        analytics: {
          eventType: EventTypes.track,
          eventPayload: {
            event: 'Verify Email Invalid Code Format Validation Error Displayed'
          }
        }
      }
    });
  };
}

export function trackEmptyVerifyEmailCodeValidationError() {
  return (dispatch) => {
    dispatch({
      type: 'track',
      meta: {
        analytics: {
          eventType: EventTypes.track,
          eventPayload: {
            event: 'Verify Email Empty Code Validation Error Displayed'
          }
        }
      }
    });
  };
}

export function deleteAccountRequest() {
  return {
    types: [
      constants.DELETE_ACCOUNT_REQUEST,
      constants.DELETE_ACCOUNT_REQUEST_SUCCESS,
      constants.DELETE_ACCOUNT_REQUEST_FAIL
    ],
    promise: async (dispatch, getState, fetch) => {
      const authenticationServiceBaseUrl =
        getState().config.endpoints.authenticationService;

      const body = await fetch(
        `${authenticationServiceBaseUrl}/delete-user-account`,
        {
          method: 'POST',
          credentials: 'include'
        },
        {
          requestKey: REQUEST_KEY.AUTH.DELETE_ACCOUNT_REQUEST,
          showGlobalSpinner: true,
          defaultError: 'errors.default'
        }
      );

      return body;
    }
  };
}
