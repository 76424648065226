import { useTheme } from '@theme/DesignSystemProvider/ThemeContext';
import { FontWeight } from '@theme/tokens/typography';
import { useResolveToken } from '@theme/tokens/utils/useResolveToken';

export type LinkState = 'default' | 'hover';

export type TextLinkStyleProps = {
  variant?: 'primary' | 'secondary';
  state?: LinkState;
  size?: 'xsmall' | 'small' | 'standard';
  weight?: FontWeight;
};

export function useTextLinkStyle({
  variant = 'primary',
  state = 'default',
  size,
  weight
}: TextLinkStyleProps) {
  const { typography, link } = useTheme();
  const { resolveToken } = useResolveToken();

  const typographyStyle = {
    primary: 'standard',
    secondary: 'small'
  } as const;
  const textStyle = resolveToken(
    typography.text,
    size || typographyStyle[variant]
  );

  const fontColor = link.variant[variant][state].fontColor;

  const textDecorationLine = (
    {
      default: 'none',
      hover: 'underline'
    } as const
  )[state];

  return {
    fontFamily: typography.fontFamily,
    color: fontColor,
    fontSize: resolveToken(typography.fontSizes, textStyle?.size),
    fontWeight: resolveToken(typography.fontWeights, weight || 'light'),
    lineHeight: resolveToken(typography.lineHeights, textStyle?.lineHeight),
    letterSpacing: resolveToken(
      typography.letterSpacing,
      textStyle?.letterSpacing
    ),
    textDecorationLine
  } as const;
}
