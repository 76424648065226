import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAvailabilityDisplay } from '@common/hooks/useAvailibilityDisplay';
import { ShiftAvailability } from '@seek/je-shared-data/lib/types/jobs';
import { Box } from '@components/Box';
import { Column } from '@components/Column';
import { Heading } from '@components/Heading';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { useAvailableShiftDetails } from './hooks';

type Props = {
  testID: string;
};

export type AvailableShiftDetailsProps = Props & {
  value: ShiftAvailability;
};

export const AvailableShiftDetails = ({
  value,
  testID
}: AvailableShiftDetailsProps) => {
  const { t } = useTranslation();

  return (
    <Stack spacing="small" testID={testID}>
      <Row testID={`${testID}-label`}>
        <Heading level="h2">
          {t('applications.applicationCard.shiftAvailability.title')}
        </Heading>
      </Row>

      <Row width="full" alignItems="center" marginBottom="medium">
        <Column width="1/4" />

        <Row width="3/4">
          <WeekDayLabels value={value} testID={testID} />
        </Row>
      </Row>

      <Row width="full" alignItems="center">
        <TimeAvailabilityLabels testID={testID} />

        <Row width="3/4">
          <ShiftAvailabilityGrid value={value} testID={testID} />
        </Row>
      </Row>
    </Stack>
  );
};

const WeekDayLabels = ({ value, testID }: AvailableShiftDetailsProps) => {
  const { availableShifts } = useAvailableShiftDetails(value);

  return (
    <>
      {Object.keys(availableShifts).map((shift) => (
        <Column alignItems="center" width="1/7" key={shift}>
          <Text testID={`${testID}-availability-${shift}-label`} size="small">
            {shift[0].toUpperCase()}
          </Text>
        </Column>
      ))}
    </>
  );
};

const TimeAvailabilityLabels = ({ testID }: Props) => {
  const { availabilityLabel } = useAvailabilityDisplay();

  return (
    <Column width="1/4" spacing="medium">
      {availabilityLabel.map((availability) => (
        <Row key={availability.value}>
          <Text testID={`${testID}-availability-label`} size="small">
            {availability.label}
          </Text>
        </Row>
      ))}
    </Column>
  );
};

const ShiftAvailabilityGrid = ({
  value,
  testID
}: AvailableShiftDetailsProps) => {
  const { availableShifts } = useAvailableShiftDetails(value);
  const { availabilityLabel } = useAvailabilityDisplay();

  return (
    <>
      {Object.keys(availableShifts).map((shift, index) => (
        <Column alignItems="center" width="1/7" spacing="large" key={index}>
          {availabilityLabel.map((availability) => {
            const hasShiftAvailability = availableShifts[shift].includes(
              availability.value
            );
            const dotTestID = `${testID}-availability-dot-${shift}-${
              availability.value
            }-${hasShiftAvailability ? 'available' : 'unavailable'}`;

            return (
              <Box
                key={`${shift}-${availability.value}`}
                testID={dotTestID}
                tone={hasShiftAvailability ? 'brand' : 'neutralDark'}
                borderRadius="full"
                width="xxxxsmall"
                height="xxxxsmall"
              />
            );
          })}
        </Column>
      ))}
    </>
  );
};
