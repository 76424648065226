import { LocalisedBrandDetails } from '@seek/je-shared-data';
import { LocalisationAction } from './actions';
import * as constants from './constants';

const initialState: Partial<LocalisedBrandDetails> = {};

export default function reducer(
  state = initialState,
  action: Partial<LocalisationAction> = {}
) {
  const { result, type } = action;

  switch (type) {
    case constants.LOCALISATION_UPDATE_SUCCESS:
      return {
        ...state,
        ...result
      };
    default:
      return state;
  }
}
