import React from 'react';
import { useTranslation } from 'react-i18next';
import { Trans as I18nextTrans } from 'react-i18next';
import { LINK, useCandidateLink } from '@common/hooks/useCandidateLink';
import { useRedirect } from '@common/hooks/useRedirect';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';
import { Box } from '@components/Box';
import { Card, CardContent } from '@components/Card';
import { Heading } from '@components/Heading';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { TextLink } from '@components/TextLink';
import { LOCALISED_COUNTRIES } from '../../../joraEmployer/constants/data';

export const JobPostingNotAvailable = () => {
  const { t } = useTranslation();
  const { countryCode, brandNameText } = useAppSelector(getLocalisation);
  const { redirect } = useRedirect();
  const { getCandidateLink } = useCandidateLink();
  const candidateLink = getCandidateLink(LINK.LOGIN);
  const fullCountryName =
    LOCALISED_COUNTRIES(t)[countryCode as SupportedCountryCode];

  return (
    <Stack
      tone="transparent"
      testID="job-posting-not-available"
      data-test-key="job-posting-not-available"
    >
      <Box
        paddingTop="medium"
        paddingBottom="small"
        paddingHorizontal="medium"
        tone="transparent"
      >
        <Heading level="h1" weight="regular">
          {t('jobPostingNotAvailable.title', { country: fullCountryName })}
        </Heading>
      </Box>
      <Card>
        <CardContent>
          <I18nextTrans
            i18nKey={'jobPostingNotAvailable.description'}
            values={{
              brandNameText
            }}
            parent={Text}
            components={[<TextLink onPress={redirect(candidateLink)} />]}
          />
        </CardContent>
      </Card>
    </Stack>
  );
};
