import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { Else, If, Then } from '@common/components/If';
import { APPLICATION_NOTES_MAX_LENGTH } from '@common/constants/validation';
import { ClientError } from '@common/helpers/errors';
import { Box } from '@components/Box';
import { EditIcon } from '@components/Icon';
import { Link } from '@components/Link';
import { Modal, useModal } from '@components/Modal';
import { Row } from '@components/Row';
import { Text } from '@components/Text';
import { useApplicationContext } from '../../../ApplicationList/ApplicationContext';
import { AddNotesButton, NotesHeading } from './components';
import { NotesForm } from './forms/NotesForm';
import { NotesFormFields } from './forms/types';

type NotesProps = {
  applicantName: string;
  applicationId: string;
  notes?: string;
  notesUpdatedAt?: string;
};

export const Notes = ({
  notes,
  notesUpdatedAt,
  applicantName,
  applicationId
}: NotesProps) => {
  const { t } = useTranslation();
  const { isModalOpen, openModal, closeModal } = useModal();
  const [error, setError] = useState<ClientError>();
  const { updateNote } = useApplicationContext();

  const onSubmit = async (data: NotesFormFields) => {
    try {
      await updateNote({
        applicationId,
        notes: data.notes as string
      });
      onCloseModal();
    } catch (e) {
      setError(e as ClientError);
    }
  };

  const onCloseModal = () => {
    setError(undefined);
    closeModal();
  };

  return (
    <Box marginTop="small" testID={`${applicationId}-applicant-notes`}>
      <If condition={!notes}>
        <Then>
          <AddNotesButton onPress={openModal}>
            {t('applications.notes.action.addNote')}
          </AddNotesButton>
        </Then>
        <Else>
          <>
            <Row justifyContent="space-between">
              <NotesHeading
                headingText={t('applications.notes.heading')}
                notesUpdatedAt={notesUpdatedAt as string}
              />

              <Link
                onPress={openModal}
                icon={<EditIcon variant="outline" tone="info" size="small" />}
              >
                {t('common.action.edit')}
              </Link>
            </Row>

            <Text>{notes}</Text>
          </>
        </Else>
      </If>
      <Modal
        title={t('applications.notes.modal.title', { applicantName })}
        visible={isModalOpen}
        onRequestClose={onCloseModal}
      >
        <NotesForm
          onCancel={onCloseModal}
          onSubmit={onSubmit}
          defaultValues={{ notes }}
          maxChars={APPLICATION_NOTES_MAX_LENGTH}
        />

        {/* Unfortunately using <When> doesn't provide the type narrowing for 'error' */}
        {error && (
          <Box marginTop="medium">
            <ErrorMessage>
              {t(error.message, { guid: error.properties.guid })}
            </ErrorMessage>
          </Box>
        )}
      </Modal>
    </Box>
  );
};
