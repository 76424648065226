import React from 'react';
import { Notes as InnerField } from '@common/components/Applications/components/Notes';
import { FieldWrapper } from '../FieldWrapper';
import { notes } from '../rules';

export const Notes = ({ maxChars }) => {
  return (
    <FieldWrapper<string>
      name="notes"
      rules={notes}
      render={(props) => {
        return <InnerField {...props} maxChars={maxChars} />;
      }}
    />
  );
};
