import { isValidPasswordFormat } from '../../../../../../helpers/validation';
import * as validation from '../../../../../../constants/validation';

export function validateUpdatePasswordForm(data) {
  const errors = {};
  const fields = ['currentPassword', 'newPassword', 'confirmPassword'];

  if (data.newPassword !== data.confirmPassword) {
    errors.confirmPassword = validation.MISMATCH_VALIDATION_ERROR;
  }

  fields.forEach(key => {
    if (!data[key]) {
      errors[key] = validation.REQUIRED_VALIDATION_ERROR;
    }

    if (data[key] && !isValidPasswordFormat(data[key])) {
      errors[key] = validation.FORMAT_VALIDATION_ERROR;
    }
  });

  return errors;
}