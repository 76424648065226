import React from 'react';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { JobStartDate } from './JobStartDate';
import { useJobStartDateValidation } from './useJobStartDateValidation';

export const JobStartDateController = <T extends FieldValues>({
  name,
  control
}: UseControllerProps<T>) => {
  const jobStartDateRules = useJobStartDateValidation();

  return (
    <Controller
      name={name}
      control={control}
      rules={jobStartDateRules}
      render={({ field: { onChange, onBlur, value }, formState }) => {
        return (
          <JobStartDate
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            error={formState?.errors[name]?.message as string}
          />
        );
      }}
    />
  );
};
