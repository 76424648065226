import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@components/Box';
import { Button } from '@components/Button';
import { Column } from '@components/Column';
import { Link } from '@components/Link';
import { Modal } from '@components/Modal';
import { StackProps } from '@components/Stack';
import { Text } from '@components/Text';
import { usePushPermissionModal } from './hooks';

type PushPermissionModalProps = {
  testID?: StackProps['testID'];
};

export const PushPermissionModal = (props: PushPermissionModalProps) => {
  const { t } = useTranslation();
  const { testID } = props;
  const { enablePushNotification, cancelPushNotification, isModalOpen } =
    usePushPermissionModal();

  return (
    <Modal
      title={t('pushNotifications.title')}
      visible={isModalOpen}
      onRequestClose={cancelPushNotification}
      testID="push-permission-modal"
    >
      <Column justifyContent="center" alignItems="center" spacing="medium">
        <Text
          weight="regular"
          tone="neutralLight"
          size="small"
          testID="push-permission-modal-description"
        >
          {t('pushNotifications.description')}
        </Text>
        <Box width="full" alignItems="center" marginVertical="medium">
          <Button
            onPress={enablePushNotification}
            testID="push-permission-modal-enable-button"
          >
            {t('pushNotifications.enable')}
          </Button>
        </Box>
        <Link
          onPress={cancelPushNotification}
          variant="secondary"
          testID={`${testID}-cancel-link`}
        >
          {t('pushNotifications.cancel')}
        </Link>
      </Column>
    </Modal>
  );
};
