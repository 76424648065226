import React from 'react';
import { Box } from '@components/Box';
import { Column } from '@components/Column';
import { SelectUpgradeButton } from '../SelectUpgradeButton';

interface UpgradeWrapperProps {
  children: React.ReactNode;
  onChoose: () => void;
  buttonText: string;
}
export const UpgradeWrapper = ({
  children,
  onChoose,
  buttonText
}: UpgradeWrapperProps) => {
  return (
    <Column
      justifyContent="space-between"
      borderWidth="xsmall"
      padding="medium"
      flexShrink={1}
      width="full"
    >
      <Box>{children}</Box>
      <SelectUpgradeButton onButtonPress={onChoose}>
        {buttonText}
      </SelectUpgradeButton>
    </Column>
  );
};
