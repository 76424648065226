import {
  BUSINESS_REGISTRATION_NUMBER_MAX_LENGTH,
  MAX_LENGTH
} from '@common/constants/validation';
import { T } from '../../FormContext';
import { useGetBusinessRegistrationFieldLabel } from '../../hooks';

type BusinessRegistrationNumberErrors = {
  required: string;
  maxLength: string;
};

export const useBusinessRegistrationNumberRules = (t: T) => {
  const { label } = useGetBusinessRegistrationFieldLabel();
  const errors = t('validations.business.businessRegistrationNumber', {
    returnObjects: true,
    max: BUSINESS_REGISTRATION_NUMBER_MAX_LENGTH,
    businessRegistrationNumberFieldName: label
  }) as BusinessRegistrationNumberErrors;

  return {
    required: errors.required,
    [MAX_LENGTH]: {
      value: BUSINESS_REGISTRATION_NUMBER_MAX_LENGTH,
      message: errors.maxLength
    }
  };
};
