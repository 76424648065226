import React from 'react';
import { useTranslation } from 'react-i18next';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { RequestErrorMessages } from '@common/components/RequestErrorMessage';
import { VerifyAccountDetailsForm } from '@common/components/VerifyAccountDetailsForm';
import { useRedirectUnauthenticatedUser } from '@common/hooks/useRedirectUnauthenticatedUser';
import REQUEST_KEY from '@src/joraEmployer/constants/requestKeys';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';
import { Box } from '@components/Box';
import { CalloutText } from '@components/CalloutText';
import { Card, CardContent } from '@components/Card';
import { Heading } from '@components/Heading';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import {
  useLoadVerifyAccountDetailsData,
  useRedirectUserWithoutRequestedAppealStatus,
  useVerifyAccountDetails
} from './hooks';

export const VerifyAccountDetails = () => {
  const { t } = useTranslation();
  const { countryCode } = useAppSelector(getLocalisation);

  useRedirectUnauthenticatedUser({ to: `/${countryCode}/login` });
  useRedirectUserWithoutRequestedAppealStatus();

  const { firstBusinessName } = useLoadVerifyAccountDetailsData();
  const { submitAccountAppeal, defaultValues, isLoading } =
    useVerifyAccountDetails();

  return (
    <Stack tone="transparent">
      <JoraEmployerHelmet title={t('verifyAccountDetailsPage.title')} />

      <Stack tone="transparent" spacing="medium">
        <Box paddingTop="medium" paddingLeft="medium" tone="transparent">
          <Heading level="h1" weight="regular">
            {t('verifyAccountDetailsPage.heading')}
          </Heading>
        </Box>

        <Card marginBottom="medium">
          <CardContent>
            <CalloutText tone="danger">
              {t('verifyAccountDetailsForm.calloutText.pleaseProvideDetails')}
            </CalloutText>

            {firstBusinessName ? (
              <Box marginVertical="medium">
                <Heading level="h2" weight="regular">
                  {firstBusinessName}
                </Heading>
              </Box>
            ) : null}

            <Text>
              {t(
                'verifyAccountDetailsForm.description.pleaseProvideRecruitmentDetails'
              )}
            </Text>

            <VerifyAccountDetailsForm
              submitAccountAppeal={submitAccountAppeal}
              defaultValues={defaultValues}
              isLoading={isLoading}
            />

            <RequestErrorMessages
              requestKeys={[
                REQUEST_KEY.BUSINESS.LIST,
                REQUEST_KEY.AUTH.SUBMIT_ACCOUNT_APPEAL
              ]}
            />
          </CardContent>
        </Card>
      </Stack>
    </Stack>
  );
};
