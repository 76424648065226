import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <>
    <Path
      d="M10.5.5.5 9l10 8.5"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const ChevronLeftIcon = createIcon({
  viewBox: '0 0 11 18',
  vectorComponent
});
