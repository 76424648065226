import { FilterForm } from '../../../hooks';
import { useTrackFiltersApplied } from '../../../hooks';

export const useHandleApplyButton = ({
  filterForm,
  applySelectedFilters,
  closeModal
}: {
  filterForm: FilterForm;
  applySelectedFilters: () => void;
  closeModal: () => void;
}) => {
  const trackFiltersApplied = useTrackFiltersApplied({ filters: filterForm });

  const onApplyButtonClicked = () => {
    applySelectedFilters();
    trackFiltersApplied();
    closeModal();
  };

  return onApplyButtonClicked;
};
