import { useActiveBusinesses } from '@common/hooks/business';
import { useBusinessLimit } from '../useBusinessLimit';

export const overBusinessLimit = (
  businessCount: number,
  businessLimit: number
): boolean => businessCount > businessLimit;

export const useOverBusinessLimit = () => {
  const businessCount = useActiveBusinesses().length;
  const businessLimit = useBusinessLimit();
  if (!businessLimit || !businessCount) return false;

  return overBusinessLimit(businessCount, businessLimit);
};
