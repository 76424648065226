import React from 'react';
import { PaymentElement } from '@stripe/react-stripe-js';
import type { StripePaymentElementChangeEvent } from '@stripe/stripe-js';

export type CardDetailsInputProps = {
  onChange: (cardDetails: StripePaymentElementChangeEvent) => void;
  onReady: () => void;
};

export const CardDetailsInput = ({
  onChange,
  onReady
}: CardDetailsInputProps) => {
  return <PaymentElement onChange={onChange} onReady={onReady} />;
};
