import React, { useRef, useState } from 'react';
import { ProfileApplication } from 'je-bff/src/applications/list/transformers';
import { useUpdateNote } from '@common/hooks/application/notes/useUpdateNote';
import { useSearchQueryParam } from '@common/hooks/useSearchQueryParam';
import type { Application } from '@common/types';
import { resolveItemsPerPage } from '@src/joraEmployer/paging';
import { PagingMenu } from '@components/Paging';
import { Stack } from '@components/Stack';
import { ApplicationContext } from './ApplicationContext';
import { ApplicationListWrapper } from './ApplicationListWrapper';

type ApplicationListProps = {
  jobId: string;
  applications: ProfileApplication[];
  testID?: string;
};

export const ApplicationList = (props: ApplicationListProps) => {
  const { jobId, applications, testID } = props;
  const updateNote = useUpdateNote({ jobId });
  const { getQueryParam } = useSearchQueryParam();
  const perPage = resolveItemsPerPage(getQueryParam('perPage'));
  const topOfListRef = useRef<HTMLDivElement>(null);
  const [paginatedApplications, setPaginatedApplications] = useState<
    Application[]
  >([]);

  return (
    <ApplicationContext.Provider value={{ updateNote }}>
      <Stack tone="transparent" testID={testID}>
        <div ref={topOfListRef} />
        <ApplicationListWrapper
          applications={paginatedApplications}
          jobId={jobId}
        />
        <PagingMenu
          items={applications}
          handlePaginatedItems={setPaginatedApplications}
          perPage={perPage}
          scrollToOnPageChange={topOfListRef.current}
        />
      </Stack>
    </ApplicationContext.Provider>
  );
};
