import { useDispatch } from 'react-redux';
import { EventTypes } from 'redux-segment';

export const useTrackContactMeButtonClicked = () => {
  const dispatch = useDispatch();
  return () => dispatch(trackContactMeButtonClicked());
};

const TRACK_CONTACT_ME_BUTTON_CLICKED =
  'jora-employer/account/TRACK_CONATACT_ME_BUTTON_CLICKED';

function trackContactMeButtonClicked(): { type: string } & Record<
  string,
  unknown
> {
  return {
    type: TRACK_CONTACT_ME_BUTTON_CLICKED,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'Contact me button clicked',
          properties: {}
        }
      }
    }
  };
}
