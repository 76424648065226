import React from 'react';
import { TextWithIcon } from '@common/components/TextWithIcon';
import { JoraCoinIcon } from '@components/Icon';
import { PriceText } from './PriceText';

type Props = {
  price?: number;
};

export const CoinAmount = ({ price }: Props) => {
  if (!price) return null;

  return (
    <TextWithIcon
      icon={<JoraCoinIcon size="xlarge" testID="jora-coin-icon" />}
      spacing="xsmall"
    >
      <PriceText<number> price={price} />
    </TextWithIcon>
  );
};
