import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@components/Text';

type CharacterCounterProps = {
  maxCharCount?: number;
  currentCharCount: number;
};

export const CharacterCounter = ({
  maxCharCount,
  currentCharCount
}: CharacterCounterProps) => {
  const { t } = useTranslation();

  if (!maxCharCount) {
    return null;
  }

  const remainingCount = maxCharCount - currentCharCount;

  if (remainingCount >= 0) {
    return (
      <Text tone="neutralLight" size="small" align="right">
        {t('validations.general.inputField.message', {
          count: remainingCount
        })}
      </Text>
    );
  }

  return (
    <Text tone="danger" size="small" align="right">
      {t('validations.general.inputField.maxLength', {
        count: remainingCount * -1
      })}
    </Text>
  );
};
