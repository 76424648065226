interface Verified {
  isVerified?: boolean;
  emailVerified?: boolean;
}
export function userHasBadAccountStatus({
  isVerified,
  emailVerified
}: Verified) {
  if (userIsUnverified({ isVerified })) return true;
  if (userHasUnverifiedEmail({ emailVerified })) return true;

  return false;
}

function userIsUnverified({ isVerified }) {
  return isVerified === false;
}

function userHasUnverifiedEmail({ emailVerified }) {
  return emailVerified === false;
}
