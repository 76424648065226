import { SUPPORTED_LANGUAGE_BY_COUNTRY } from '@seek/je-shared-data';

export function getAlternateHrefLangList({
  validCountryCodes,
  pathname,
  siteDomain
}) {
  return validCountryCodes.map((countryCode) => {
    const uppercaseCountryCode = countryCode.toUpperCase();
    const path = pathname.replace(/\/([a-z]{2})\//g, '');
    const hrefLang = `${SUPPORTED_LANGUAGE_BY_COUNTRY[countryCode][0]}-${uppercaseCountryCode}`;

    return { href: `${siteDomain}/${countryCode}/${path}`, hrefLang };
  });
}
