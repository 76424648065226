import React from 'react';
import { Trans as I18nextTrans, useTranslation } from 'react-i18next';
import { useMonetisationGstFeature } from '@common/hooks/featureToggles';
import { useBrandNameText } from '@common/hooks/localisation';
import { LINK, useCandidateLink } from '@common/hooks/useCandidateLink';
import { useRedirect } from '@common/hooks/useRedirect';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { Box } from '@components/Box';
import { Text } from '@components/Text';
import { TextLink } from '@components/TextLink';

type DisclaimerTextProps = {
  jobCountryCode: SupportedCountryCode;
};

export const DisclaimerText = ({ jobCountryCode }: DisclaimerTextProps) => {
  const { t } = useTranslation();
  const { getCandidateLink } = useCandidateLink();
  const brandNameText = useBrandNameText();
  const { redirect } = useRedirect();

  const hasGst = useMonetisationGstFeature(jobCountryCode);

  return (
    <Box tone="transparent">
      <Text size="xsmall" tone="neutralLight">
        {hasGst ? (
          <Text size="xsmall" tone="neutralLight">
            {t('upgradeJobForm.gstDisclaimer') + ' '}
          </Text>
        ) : null}

        <I18nextTrans
          fontSize="tiny"
          lineHeight="small"
          i18nKey="upgradeJobForm.disclaimer"
          values={{ brandNameText }}
          components={[
            <TextLink
              size="xsmall"
              onPress={redirect(
                getCandidateLink(LINK.ADVERTISING_TERMS_OF_USE_PAGE)
              )}
            />,
            <TextLink
              size="xsmall"
              onPress={redirect(getCandidateLink(LINK.PRIVACY_PAGE))}
            />
          ]}
        />
      </Text>
    </Box>
  );
};
