import type {
  FormProps,
  IncompleteRegisterFormData,
  OnChangeCallback,
  OnSubmitCallback
} from '../types';

export interface HydratedRegisterFormProps {
  defaultValues: IncompleteRegisterFormData;
  onChange: OnChangeCallback;
  onSubmit: OnSubmitCallback;
  showCookieConsent: boolean;
  showAgeVerification: boolean;
  brandNameText: string;
}

export function hydrateRegisterFormProps(
  props: FormProps
): HydratedRegisterFormProps {
  return {
    defaultValues: props.defaultValues ?? {},
    onChange: props.onChange ?? (() => {}),
    onSubmit: props.onSubmit ?? (() => {}),
    showCookieConsent: props.showCookieConsent ?? false,
    showAgeVerification: props.showAgeVerification ?? false,
    brandNameText: props.brandNameText
  };
}
