import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextWithIcon } from '@common/components/TextWithIcon';
import { Column } from '@components/Column';
import { SuccessIcon } from '@components/Icon';
import { Text } from '@components/Text';

export const Benefits = () => {
  const { t } = useTranslation();

  return (
    <Column spacing="xsmall">
      <TextWithIcon icon={<SuccessIcon variant="outline" tone="success" />}>
        <Text>
          {t('account.upgradeAccountModal.requestUpgrade.benefits.0')}
        </Text>
      </TextWithIcon>

      <TextWithIcon icon={<SuccessIcon variant="outline" tone="success" />}>
        <Text>
          {t('account.upgradeAccountModal.requestUpgrade.benefits.1')}
        </Text>
      </TextWithIcon>

      <TextWithIcon icon={<SuccessIcon variant="outline" tone="success" />}>
        <Text>
          {t('account.upgradeAccountModal.requestUpgrade.benefits.2')}
        </Text>
      </TextWithIcon>

      <TextWithIcon icon={<SuccessIcon variant="outline" tone="success" />}>
        <Text>
          {t('account.upgradeAccountModal.requestUpgrade.benefits.3')}
        </Text>
      </TextWithIcon>

      <TextWithIcon icon={<SuccessIcon variant="outline" tone="success" />}>
        <Text>
          {t('account.upgradeAccountModal.requestUpgrade.benefits.4')}
        </Text>
      </TextWithIcon>
    </Column>
  );
};
