import { REGEX } from '@common/constants/data';
import {
  ADDITIONAL_APPEAL_INFORMATION_MAX_LENGTH,
  MAX_LENGTH
} from '@common/constants/validation';
import { T } from '../../FormContext';

type BusinessAdditionalInformationErrors = {
  maxLength: string;
  noAngleBracket: string;
};

export const useBusinessAdditionalInformationRules = (t: T) => {
  const errors = t('validations.user.verifyAccount.additionalInformation', {
    returnObjects: true,
    max: ADDITIONAL_APPEAL_INFORMATION_MAX_LENGTH
  }) as BusinessAdditionalInformationErrors;

  return {
    [MAX_LENGTH]: {
      value: ADDITIONAL_APPEAL_INFORMATION_MAX_LENGTH,
      message: errors.maxLength
    },
    validate: (value) => {
      if (REGEX.ANGLE_BRACKET.test(value)) {
        return errors.noAngleBracket;
      }

      return true;
    }
  };
};
