import React from 'react';
import { Else, If, Then } from '@common/components/If';
import { DetailedLanding } from './DetailedLanding';
import { SimpleLanding } from './SimpleLanding';
import { useLandingPage } from './hooks';

export const Landing = () => {
  const renderDetailedLanding = useLandingPage();

  return (
    <If condition={renderDetailedLanding}>
      <Then>
        <DetailedLanding />
      </Then>
      <Else>
        <SimpleLanding />
      </Else>
    </If>
  );
};
