import { useEffect } from 'react';
import { debounce } from '@lib/func';

interface ListenParams<T> {
  watch: any;
  onChange: T extends any ? (value: T) => void : never;
  updateDebounce: number;
}
export function useListenForChanges<T>({
  watch,
  updateDebounce,
  onChange
}: ListenParams<T>) {
  useEffect(() => {
    const debouncedCb = debounce((value) => onChange(value), updateDebounce);
    const subscription = watch(debouncedCb);
    return () => subscription.unsubscribe();
  }, [watch, updateDebounce, onChange]);
}
