import { TextStyle } from 'react-native';
import { useTheme } from '@theme/DesignSystemProvider/ThemeContext';
import { BaseTheme } from '@theme/DesignSystemProvider/ThemeContext/makeBaseTheme';
import { FontWeight, LineHeight } from '@theme/tokens/typography';
import { useResolveToken } from '@theme/tokens/utils/useResolveToken';
import { Tone } from '@theme/types';

export type TextStyleProps = {
  align?: TextStyle['textAlign'];
  size?: keyof BaseTheme['typography']['text'];
  tone?: Tone | 'neutralLight' | 'white';
  weight?: FontWeight;
  height?: LineHeight;
  decoration?: TextStyle['textDecorationLine'];
};

export function useTextStyle({
  align,
  size = 'standard',
  tone = 'neutral',
  weight = 'light',
  decoration,
  height
}: TextStyleProps) {
  const {
    colors: { foreground },
    typography: {
      fontFamily,
      text,
      fontSizes,
      fontWeights,
      lineHeights,
      letterSpacing
    }
  } = useTheme();
  const { resolveToken } = useResolveToken();

  const {
    size: textSize,
    lineHeight: textLineHeight,
    letterSpacing: textLetterSpacing
  } = resolveToken(text, size) || {};

  return {
    containerStyle: {
      flexDirection: 'row' as const,
      alignItems: 'center' as const
    },
    textStyle: {
      fontFamily,
      fontSize: resolveToken(fontSizes, textSize),
      lineHeight: resolveToken(lineHeights, height || textLineHeight),
      fontWeight: resolveToken(fontWeights, weight),
      ...(decoration && { textDecorationLine: decoration }),
      color: resolveToken(foreground, tone),
      letterSpacing: resolveToken(letterSpacing, textLetterSpacing),
      textAlign: align
    }
  };
}
