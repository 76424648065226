export const AUTHENTICATED = 'jora-employer/auth/AUTHENTICATED';

export const LOGIN = 'jora-employer/auth/LOGIN';
export const LOGIN_SUCCESS = 'jora-employer/auth/LOGIN_SUCCESS';
export const LOGIN_FAIL = 'jora-employer/auth/LOGIN_FAIL';

export const LOGIN_SUBMISSION_FAIL = 'jora-employer/auth/LOGIN_SUBMISSION_FAIL';

export const REGISTER = 'jora-employer/auth/REGISTER';
export const REGISTER_SUCCESS = 'jora-employer/auth/REGISTER_SUCCESS';
export const REGISTER_FAIL = 'jora-employer/auth/REGISTER_FAIL';
export const REGISTER_SUBMISSION_FAIL =
  'jora-employer/auth/REGISTER_SUBMISSION_FAIL';
export const REGISTER_FORM_NAME = 'jora-employer-register-form';

export const GET_ACCOUNT_DETAILS = 'jora-employer/auth/GET_ACCOUNT_DETAILS';
export const GET_ACCOUNT_DETAILS_SUCCESS =
  'jora-employer/auth/GET_ACCOUNT_DETAILS_SUCCESS';
export const GET_ACCOUNT_DETAILS_FAIL =
  'jora-employer/auth/GET_ACCOUNT_DETAILS_FAIL';

export const FORGOT_PASSWORD = 'jora-employer/auth/FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS =
  'jora-employer/auth/FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'jora-employer/auth/FORGOT_PASSWORD_FAIL';
export const FORGOT_PASSWORD_SUBMISSION_FAIL =
  'jora-employer/auth/FORGOT_PASSWORD_SUBMISSION_FAIL';

export const FORGOT_PASSWORD_SUCCESS_TRACK =
  'jora-employer/auth/FORGOT_PASSWORD_SUCCESS_TRACK';

export const RESET_PASSWORD = 'jora-employer/auth/RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS =
  'jora-employer/auth/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'jora-employer/auth/RESET_PASSWORD_FAIL';
export const RESET_PASSWORD_SUBMISSION_FAIL =
  'jora-employer/auth/RESET_PASSWORD_SUBMISSION_FAIL';

export const RESET_PASSWORD_SUCCESS_TRACK =
  'jora-employer/auth/RESET_PASSWORD_SUCCESS_TRACK';

export const UPDATE_PASSWORD = 'jora-employer/auth/UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS =
  'jora-employer/auth/UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAIL = 'jora-employer/auth/UPDATE_PASSWORD_FAIL';
export const UPDATE_PASSWORD_SUBMISSION_FAIL =
  'jora-employer/auth/UPDATE_PASSWORD_SUBMISSION_FAIL';

export const UPDATE_ACCOUNT_DETAILS =
  'jora-employer/auth/UPDATE_ACCOUNT_DETAILS';
export const UPDATE_ACCOUNT_DETAILS_SUCCESS =
  'jora-employer/auth/UPDATE_ACCOUNT_DETAILS_SUCCESS';
export const UPDATE_ACCOUNT_DETAILS_FAIL =
  'jora-employer/auth/UPDATE_ACCOUNT_DETAILS_FAIL';
export const UPDATE_ACCOUNT_DETAILS_SUBMISSION_FAIL =
  'jora-employer/auth/UPDATE_ACCOUNT_DETAILS_SUBMISSION_FAIL';

export const SUBMIT_ACCOUNT_APPEAL = 'jora-employer/auth/SUBMIT_ACCOUNT_APPEAL';
export const SUBMIT_ACCOUNT_APPEAL_SUCCESS =
  'jora-employer/auth/SUBMIT_ACCOUNT_APPEAL_SUCCESS';
export const SUBMIT_ACCOUNT_APPEAL_FAIL =
  'jora-employer/auth/SUBMIT_ACCOUNT_APPEAL_FAIL';

export const VERIFY_ACCOUNT_DETAILS_FORM_NAME =
  'jora-employer-verify-account-details-form';

export const LOGOUT = 'jora-employer/auth/LOGOUT';
export const LOGOUT_SUCCESS = 'jora-employer/auth/LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'jora-employer/auth/LOGOUT_FAIL';

export const VERIFY_EMAIL = 'jora-employer/auth/VERIFY_EMAIL';
export const VERIFY_EMAIL_SUCCESS = 'jora-employer/auth/VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAIL = 'jora-employer/auth/VERIFY_EMAIL_FAIL';

export const VERIFY_EMAIL_SUCCESS_TRACK =
  'jora-employer/auth/VERIFY_EMAIL_SUCCESS_TRACK';

export const RESEND_VERIFY_EMAIL = 'jora-employer/auth/RESEND_VERIFY_EMAIL';
export const RESEND_VERIFY_EMAIL_SUCCESS =
  'jora-employer/auth/RESEND_VERIFY_EMAIL_SUCCESS';
export const RESEND_VERIFY_EMAIL_FAIL =
  'jora-employer/auth/RESEND_VERIFY_EMAIL_FAIL';

export const DELETE_ACCOUNT_REQUEST =
  'jora-employer/auth/DELETE_ACCOUNT_REQUEST';
export const DELETE_ACCOUNT_REQUEST_SUCCESS =
  'jora-employer/auth/DELETE_ACCOUNT_REQUEST_SUCCESS';
export const DELETE_ACCOUNT_REQUEST_FAIL =
  'jora-employer/auth/DELETE_ACCOUNT_REQUEST_FAIL';

export const CHANGE_EMAIL_CTA_TRACK =
  'jora-employer/auth/CHANGE_EMAIL_CTA_TRACK';

export const LOGIN_REDIRECT_QUERY_PARAMS = {
  EDIT_JOB: 'editJob',
  REPOST_JOB: 'repostJob',
  UPGRADE_JOB: 'upgradeJob',
  VERIFY_ACCOUNT: 'verifyAccount'
};
