import { IncompleteData } from '../types';
import { getLastPathPart } from './getLastPathPart';
import { pathToStep } from './pathToStep';
import {
  resolveBusinessForm,
  resolveDefaultStep,
  resolveRegisterForm
} from './stepResolvers';
import { Step, StepMap } from './types';

export interface StepMapping {
  stepMap: StepMap;
  path?: string;
  data: IncompleteData;
  isAuthenticated?: boolean;
  emailVerified?: boolean;
  options?: { infoForm?: unknown };
}

/**
 * Maps the current step based on the provided step map and completeness of data.
 * Where required form data is missing, the step will be mapped to the appropriate form.
 * @param stepMap - The step map object.
 * @param path - The current path.
 * @param data - The form data object.
 * @param options - Additional options for form validation. ie. min/max string lengths
 * @param isAuthenticated - Indicates whether the user is authenticated.
 * @param emailVerified - Indicates whether the user's email is verified.
 * @returns The mapped step.
 */
export function mapToStep({
  stepMap,
  path: currentPath,
  data,
  options,
  isAuthenticated,
  emailVerified
}: StepMapping): Step {
  if (isAuthenticated && !emailVerified) return 'verify-form';

  try {
    const lastPathPart = getLastPathPart(currentPath || '');
    const step = pathToStep(lastPathPart, stepMap);

    if (step === 'verify-success-form' && emailVerified) {
      return 'verify-success-form';
    }

    if (step === 'verify-form') {
      return 'verify-form';
    }

    if (step === 'register-form') {
      return resolveRegisterForm({ data, options });
    }

    if (step === 'business-form') {
      return resolveBusinessForm({ data, options });
    }
  } catch (error) {
    return resolveDefaultStep();
  }

  return resolveDefaultStep();
}
