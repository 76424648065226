import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';

/**
 * Retrieves the brand name text from the localisation configuration.
 * @returns {brandNameText} The brand name text (e.g: "Jobstreet Express", "Jora", etc).
 */
export function useBrandNameText(): string {
  const { brandNameText } = useAppSelector(getLocalisation);
  return brandNameText as string;
}
