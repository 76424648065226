import { LOGOUT_SUCCESS } from '../../../auth/constants';
import * as constants from '../constants';
import { Actions, JobsState } from './types';

const initialState = {
  items: {},
  dashboard: {
    list: [],
    nextKey: null
  }
};

export function reducer(
  state: JobsState = initialState,
  action: Actions
): JobsState {
  switch (action.type) {
    case constants.POST_JOB_SUCCESS:
    case constants.UPDATE_JOB_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          [action?.result?.id]: {
            ...state.items[action?.result?.id],
            ...action.result
          }
        }
      };

    case LOGOUT_SUCCESS:
      return initialState;

    case constants.LOAD_JOB_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          [action?.result?.id]: action.result
        }
      };

    case constants.LOAD_DASHBOARD_JOBS_SUCCESS:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          list: state.dashboard.list.concat(action?.result?.jobs),
          nextKey: action?.result?.nextKey
        }
      };

    case constants.CLEAR_DASHBOARD_JOBS:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          list: [],
          nextKey: null
        }
      };

    default:
      return state;
  }
}
