import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <Path
    d="M5.5 5.5l13 13M18.5 5.5l-13 13"
    strokeMiterlimit={10}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
);

export const CrossIcon = createIcon({ viewBox: '5 5 14 14', vectorComponent });
