import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => {
  return (
    <>
      <Path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m17.067 9.641.126-.104c2.116-1.746 3.174-2.619 4.024-2.197.85.421.85 1.82.85 4.613v1.564c0 2.794 0 4.192-.85 4.613s-1.908-.45-4.024-2.197l-.126-.104m-15-4.094c0-3.3 0-4.95 1.025-5.975s2.675-1.025 5.975-1.025h1c3.3 0 4.95 0 5.975 1.025s1.025 2.675 1.025 5.975v2c0 3.3 0 4.95-1.025 5.975s-2.675 1.025-5.975 1.025h-1c-3.3 0-4.95 0-5.975-1.025s-1.025-2.675-1.025-5.975v-2z"
      />
      <Path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12.817 10.485a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5z"
      />
    </>
  );
};

export const VideoIcon = createIcon({
  viewBox: '0 0 25 25',
  vectorComponent
});
