import { ProfileApplication } from 'je-bff/src/applications/list/transformers';

export const sortByApplicationDate = (
  applicationA: ProfileApplication,
  applicationB: ProfileApplication
) => {
  const appliedAtDateA = new Date(applicationA.candiAppliedAt).getTime();
  const appliedAtDateB = new Date(applicationB.candiAppliedAt).getTime();

  return appliedAtDateB - appliedAtDateA;
};
