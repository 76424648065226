import React from 'react';
import { View, ViewProps, useWindowDimensions } from 'react-native';
import RenderHtml from 'react-native-render-html';
import sanitize from 'sanitize-html';
import { blockRenderer } from './renderer';

export type HtmlTextProps = {
  children?: string;
  testID?: ViewProps['testID'];
};

export const HtmlText = (props: HtmlTextProps) => {
  const { children, testID } = props;
  const { width } = useWindowDimensions();

  const source = {
    html: `<body>${sanitize(children, {
      allowedTags: ['div', 'p', 'ul', 'ol', 'li', 'strong'] // tags whitelisted by the candi Job Service API
    })}</body>`
  };

  const renderers = {
    body: blockRenderer,
    div: blockRenderer,
    p: blockRenderer,
    ul: blockRenderer,
    ol: blockRenderer,
    li: blockRenderer
  };

  return (
    <View testID={testID}>
      <RenderHtml contentWidth={width} source={source} renderers={renderers} />
    </View>
  );
};
