import React from 'react';
import { Stack } from '@components/Stack';
import { RequestErrorMessage } from '.';

type Props = {
  requestKeys: string[];
};

/**
 * Renders a list of request error messages based on the provided request keys.
 *
 * @component
 * @param {string[]} props.requestKeys - The array of request keys.
 * @returns {JSX.Element} The rendered component.
 */
export const RequestErrorMessages = (props: Props) => {
  const { requestKeys } = props;

  return (
    <Stack spacing="medium">
      {requestKeys.map((key) => (
        <RequestErrorMessage key={key} requestKey={key} />
      ))}
    </Stack>
  );
};
