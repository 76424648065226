import { SupportLevel } from '@seek/je-shared-data/lib/types/brand';
import { useSupportTier } from '../../localisation/useSupportTier';

/**
 * Checks if the current support level is Tier 3.
 * @returns {boolean} True if current support tier is Tier 3. False otherwhise.
 */
export function useIsTier3Support(): boolean {
  const supportTier = useSupportTier();
  return supportTier === SupportLevel.TIER_3;
}
