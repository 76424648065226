import React from 'react';
import { useTranslation } from 'react-i18next';
import { TotalCoinsText } from '@common/components/TotalCoinsText';
import { Box } from '@components/Box';
import { ChevronLeftIcon } from '@components/Icon';
import { Link } from '@components/Link';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { Logo } from '../Logo';
import { useCoinBalance } from './useCoinBalance';
import { useJoraEmployerHeader } from './useJoraEmployerHeader';

export const JoraEmployerHeader = () => {
  const { t } = useTranslation();
  const {
    isAuthenticated,
    badAccountStatus,
    isNotHomeRoute,
    siteId,
    isDevelopment,
    goBack,
    goToLogin,
    logOut,
    goToHome
  } = useJoraEmployerHeader();

  const { showCoinsBalance, coinsBalance } = useCoinBalance();

  return (
    <Stack tone="black" alignItems="center" testID="employer-header">
      <Row
        width={{
          desktop: 'xxlarge',
          tablet: 'full',
          mobile: 'full'
        }}
        alignItems="center"
        justifyContent="space-between"
        paddingVertical="xxsmall"
        paddingHorizontal="medium"
        tone="transparent"
      >
        <Box testID="header-back-action-section" tone="transparent" width="1/3">
          {!badAccountStatus && isNotHomeRoute && (
            <Link
              icon={
                <ChevronLeftIcon size="small" tone="white" variant="outline" />
              }
              onPress={goBack}
              testID="back-button"
            />
          )}
        </Box>

        <Box
          testID="header-logo-section"
          tone="transparent"
          width="1/3"
          alignItems="center"
        >
          <Logo theme="dark" handlePress={goToHome} />
        </Box>

        <Box
          testID="header-action-section"
          tone="transparent"
          width="1/3"
          alignItems="flex-end"
        >
          <Row spacing="medium" alignItems="center" tone="transparent">
            {isDevelopment && siteId && (
              <Box tone="promo" paddingHorizontal="small">
                <Text
                  testID="header-site-id-section"
                  weight="bold"
                  tone="promo"
                >
                  {`SiteID: 😎🤪 ${siteId}`}
                </Text>
              </Box>
            )}
            {!isAuthenticated && (
              <Link
                variant="neutral"
                onPress={goToLogin}
                testID="header-login-link"
              >
                {t('common.action.login')}
              </Link>
            )}
            {badAccountStatus && (
              <Link
                variant="neutral"
                onPress={logOut}
                testID="header-logout-link"
              >
                {t('common.action.logout')}
              </Link>
            )}

            {showCoinsBalance && (
              <TotalCoinsText
                size="small"
                textTone="white"
                balance={coinsBalance}
              />
            )}
          </Row>
        </Box>
      </Row>
    </Stack>
  );
};
