import React from 'react';
import { Text } from '@components/Text';

type Props<T> = {
  price: T;
};

export const PriceText = <T extends number | string>({ price }: Props<T>) => (
  <Text size="xlarge" weight="regular">
    {price}
  </Text>
);
