import React from 'react';
import { useTranslation } from 'react-i18next';
import { When } from '@common/components/When';
import { useRedirect } from '@common/hooks/useRedirect';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';
import { Link } from '@components/Link';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';

type ApplicationsRecipientProps = {
  email: string;
  isEditable: boolean;
};

export const ApplicationsRecipient = ({
  email,
  isEditable
}: ApplicationsRecipientProps) => {
  const { t } = useTranslation();
  const { countryCode } = useAppSelector(getLocalisation);
  const { redirect } = useRedirect();
  const updateAccountUrl = `/${countryCode}/account/update-details?fromPostJob=true`;

  return (
    <Stack>
      <Text>{t('postJobForm.applicationsRecipient.applicationsSentTo')}</Text>

      <Row spacing="small">
        <Text weight="medium">{email}</Text>

        <When condition={isEditable}>
          <Link
            variant="primary"
            onPress={redirect(updateAccountUrl)}
            testID="post-job-form-change-email-link"
          >
            {t('common.action.change')}
          </Link>
        </When>
      </Row>
    </Stack>
  );
};
