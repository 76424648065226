import { useTranslation } from 'react-i18next';
import {
  JOB_DESCRIPTION_MAX_LENGTH,
  JOB_DESCRIPTION_MIN_LENGTH,
  JOB_DESCRIPTION_MIN_LENGTH_FOR_EXPRESS_VERIFICATION
} from '@common/constants/validation';
import { normaliseExtractedText } from '@common/helpers/textHelper';
import { useSearchQueryParam } from '@common/hooks/useSearchQueryParam';
import { useAppDispatch } from '@store/hooks';
import {
  trackMaxLengthValidationError,
  trackMinLengthValidationError
} from './jobDescriptionActions';

type JobDescriptionErrors = {
  required: string;
  minLength: string;
  maxLength: string;
};

export const useJobDescriptionValidation = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { getQueryParam } = useSearchQueryParam();
  const expressVerificationToken = getQueryParam('ev') as string;

  const jobDescriptionMinLength = (expressVerificationToken as string)?.length
    ? JOB_DESCRIPTION_MIN_LENGTH_FOR_EXPRESS_VERIFICATION
    : JOB_DESCRIPTION_MIN_LENGTH;

  const errors = t('validations.job.description', {
    returnObjects: true,
    min: jobDescriptionMinLength,
    max: JOB_DESCRIPTION_MAX_LENGTH
  }) as JobDescriptionErrors;

  return {
    validate: (text: string) => {
      const normalisedExtractedText = normaliseExtractedText(text as string);

      if (normalisedExtractedText.length === 0) {
        return errors.required;
      }

      if (normalisedExtractedText.length < jobDescriptionMinLength) {
        dispatch(trackMinLengthValidationError());
        return errors.minLength;
      }
      if (normalisedExtractedText.length > JOB_DESCRIPTION_MAX_LENGTH) {
        dispatch(trackMaxLengthValidationError());
        return errors.maxLength;
      }
    }
  };
};
