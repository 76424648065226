import React from 'react';
import { useTranslation } from 'react-i18next';
import { RegistrationProgressIndicator } from '@common/components/RegistrationProgressIndicator';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';
import { Box } from '@components/Box';
import { Button } from '@components/Button';
import { Card, CardContent } from '@components/Card';
import { Heading } from '@components/Heading';
import { TwoPeopleHighFiving } from '@components/Illustration';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { useVerifyEmailSuccess } from '../useVerifyEmailSuccess';
import { useCreateAccount } from './useCreateAccount';

export const CreateAccount = () => {
  const { t } = useTranslation();
  const { handleSubmit } = useCreateAccount();
  const { fromPostJobFlow } = useVerifyEmailSuccess();
  const { countryCode } = useAppSelector(getLocalisation);

  if (!fromPostJobFlow) return null;

  return (
    <Stack spacing="medium" tone="transparent">
      <Box paddingTop="medium" paddingLeft="medium" tone="transparent">
        <Heading level="h1">{t('verifyEmailPage.heading.fromPostJob')}</Heading>
      </Box>

      <Card>
        <CardContent>
          <Stack spacing="xlarge">
            <Box alignSelf="center">
              <RegistrationProgressIndicator
                currentPathOverride={`/${countryCode}/register`}
              />
            </Box>

            <Stack spacing="large">
              <Stack alignItems="center" spacing="medium">
                <TwoPeopleHighFiving
                  size="large"
                  testID="two-people-high-fiving"
                />
                <Heading level="h1" weight="regular">
                  {t('verifyEmailSuccessPage.description.emailHasBeenVerified')}
                </Heading>

                <Text>
                  {t('verifyEmailSuccessPage.description.mayNowProceed')}
                </Text>
              </Stack>

              <Button onPress={handleSubmit}>
                {t('common.action.continue')}
              </Button>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
};
