import React from 'react';
import { Box, BoxProps } from '@components/Box';
import { useBreakpoint } from '@theme/DesignSystemProvider/BreakpointContext';
import { usePlatform } from '@theme/DesignSystemProvider/PlatformContext';
import { Spacing } from '@theme/tokens';
import { useSpace } from '../hooks';
import { DeviceSpacing } from '../types';

type SpaceProps = Pick<BoxProps, 'direction'> & {
  size?: Spacing | DeviceSpacing;
};

const directionToMargin = {
  row: 'marginLeft',
  column: 'marginTop'
};

export const Space = ({ direction = 'column', size = 'none' }: SpaceProps) => {
  const breakpoint = useBreakpoint();
  const platform = usePlatform();
  const spaceSize = useSpace({ breakpoint, platform, size });

  if (size === 'none') {
    return null;
  }

  const directionalSize = {
    [directionToMargin[direction[breakpoint] || direction]]: spaceSize
  };

  return <Box direction={direction} {...directionalSize} />;
};
