import { useSearchQueryParam } from '@common/hooks/useSearchQueryParam';
import { getUser, sendVerifyEmail, verifyEmail } from '@store/auth';
import { useAppDispatch, useAppSelector } from '@store/hooks';

type SubmitVerificationCodeProps = {
  emailVerificationCode: string;
};
const useSubmitVerificationCode = () => {
  const dispatch = useAppDispatch();

  return async (props: SubmitVerificationCodeProps) => {
    await dispatch(
      verifyEmail({ verificationCode: props.emailVerificationCode })
    );
  };
};

const useResendVerificationEmail = () => {
  const dispatch = useAppDispatch();

  return async () => {
    await dispatch(sendVerifyEmail(true));
  };
};

const useGetUser = () => {
  const { currentUser } = useAppSelector(getUser);

  return currentUser;
};

const useIsFromUpdateAccount = (emailLastVerifiedAt?: string) => {
  const { checkQueryParam } = useSearchQueryParam();

  return !!emailLastVerifiedAt || checkQueryParam('fromUpdateAccount');
};

export const useVerifyEmail = () => {
  const submitVerificationCode = useSubmitVerificationCode();
  const resendVerificationEmail = useResendVerificationEmail();
  const { email, emailLastVerifiedAt } = useGetUser();
  const isFromUpdateAccount = useIsFromUpdateAccount(emailLastVerifiedAt);

  return {
    submitVerificationCode,
    resendVerificationEmail,
    userEmail: email,
    isFromUpdateAccount
  };
};
