import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { createReduxHistoryContext } from 'redux-first-history';
import promise from 'redux-promise';
import ReduxThunk from 'redux-thunk';
import clientMiddleware from '../../common/store/middleware/clientMiddleware';
import segmentLocalisationMiddleware from './middleware/segmentLocalisationMiddleware';
import sessionTaggingMiddleware from './middleware/sessionTaggingMiddleware';
import rootReducer from './reducers';

export default function configureStore(
  initialState,
  initialHistory,
  fetch,
  additionalMiddleware: any[] = []
) {
  const { createReduxHistory, routerMiddleware, routerReducer } =
    createReduxHistoryContext({ history: initialHistory });

  const middleware = [
    segmentLocalisationMiddleware,
    clientMiddleware(fetch),
    promise,
    sessionTaggingMiddleware(),
    ReduxThunk
  ];

  const composeEnhancers = composeWithDevTools({});

  if (initialHistory) {
    middleware.push(routerMiddleware);
  }

  middleware.push(...additionalMiddleware);

  const store = createStore(
    rootReducer(routerReducer),
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      const nextRootReducer = require('./reducers').default;
      store.replaceReducer(nextRootReducer(routerReducer));
    });
  }

  const history = createReduxHistory(store);

  return { store, history };
}
