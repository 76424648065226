import React from 'react';
import { Trans } from 'react-i18next';
import { openExternalURL } from '@common/helpers/externalConnection';
import { CalloutText } from '@components/CalloutText';
import { Text } from '@components/Text';
import { TextLink } from '@components/TextLink';

type MoreBusinessesCalloutProps = {
  brandNameText?: string;
  supportSalesEmail?: string;
  supportSalesWhatsApp?: string;
};

export const MoreBusinessesCallout = ({
  brandNameText,
  supportSalesEmail,
  supportSalesWhatsApp
}: MoreBusinessesCalloutProps) => {
  const openEmail = () => {
    openExternalURL(`mailto:${supportSalesEmail}`);
  };

  const openWhatsapp = () => {
    const parsedWhatsappNumber = supportSalesWhatsApp
      ?.replace(/[+\s]/g, '')
      .trim();
    openExternalURL(`https://wa.me/${parsedWhatsappNumber}`);
  };

  return (
    <CalloutText tone="info">
      <Text>
        <Trans
          i18nKey={'postJobForm.businessInformation.moreBusinesses.description'}
          values={{
            brandNameText,
            supportSalesEmail,
            supportSalesWhatsApp
          }}
          components={[
            <TextLink onPress={openWhatsapp}>1</TextLink>,
            <TextLink onPress={openEmail}>2</TextLink>
          ]}
        />
      </Text>
    </CalloutText>
  );
};
