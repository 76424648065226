import { readAll } from '@common/helpers/draftFromStore';
import { objectHasAllKeys } from '@src/lib/func/objectHasAllKeys';

/**
 * Check if draft data exists for all the given keys
 * @param keys - keys to check for
 * @param getDraftData - function to get draft data
 * @returns true if draft data exists for all the given keys, false otherwise
 */
export function hasDraftData(keys: string[], getDraftData = readAll) {
  const draftData = getDraftData() || {};
  return objectHasAllKeys(draftData, keys);
}
