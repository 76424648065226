import React from 'react';
import { TextWithIcon } from '@common/components/TextWithIcon';
import { LocationIcon } from '@components/Icon';
import { Text } from '@components/Text';

type LocationInfoProps = {
  location?: string;
};

export const LocationInfo = ({ location }: LocationInfoProps) => {
  if (!location) return null;

  return (
    <TextWithIcon
      icon={
        <LocationIcon
          size="small"
          variant="outline"
          tone="success"
          testID="location-icon"
        />
      }
    >
      <Text>{location}</Text>
    </TextWithIcon>
  );
};
