import React from 'react';
import { Application } from '@common/types';
import { Column } from '@components/Column';
import { Divider } from '@components/Divider';
import { AboutMe } from '../AboutMe';
import { AvailableShiftDetails } from '../AvailableShiftDetails';
import { Summary } from '../Summary';
import { WorkExperienceDetails } from '../WorkExperienceDetails';

type Props = {
  applicationId: string;
  shiftAvailability?: Application['shiftAvailability'];
  workExperience?: Application['workExperience'];
  summary?: Application['aboutMe'];
  candiRightToWork?: Application['candiRightToWork'];
  age?: Application['age'];
  gender?: Application['gender'];
  citizenship?: Application['citizenship'];
  jobCountryCode?: string;
  highestEducation?: Application['highestEducation'];
};

export const ProfileDetails = ({
  shiftAvailability,
  workExperience,
  applicationId,
  summary,
  candiRightToWork,
  age,
  gender,
  citizenship,
  jobCountryCode,
  highestEducation
}: Props) => (
  <Column marginTop="xlarge" spacing="large">
    <Divider />

    <AboutMe
      age={age}
      gender={gender}
      citizenship={citizenship}
      candiRightToWork={candiRightToWork}
      jobCountryCode={jobCountryCode}
      highestEducation={highestEducation}
    />

    <Divider />

    {summary ? (
      <Summary summary={summary} testID={`${applicationId}-summary`} />
    ) : null}

    {summary && workExperience ? <Divider /> : null}

    {workExperience ? (
      <WorkExperienceDetails
        value={workExperience}
        testID={`${applicationId}-work-experience-details`}
      />
    ) : null}

    {workExperience && shiftAvailability ? <Divider /> : null}

    {shiftAvailability ? (
      <AvailableShiftDetails
        value={shiftAvailability}
        testID={`${applicationId}-available-shift-details`}
      />
    ) : null}
  </Column>
);
