import { LINK, useCandidateLink } from '@common/hooks/useCandidateLink';

/**
 * Custom hook to retrieve the FAQ link for the "What are coins?" section.
 * This hook internally uses the `useCandidateLink` hook to get the candidate link.
 * @returns The FAQ link for the "What are Coins?" section.
 */
export const useFaqLink = () => {
  const { getCandidateLink } = useCandidateLink();
  const faqLink = getCandidateLink(LINK.FAQ_WHAT_ARE_COINS);

  return faqLink;
};
