import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <>
    <Path
      d="M13 20.5l-8.25-.01c-.142.01-.25-.106-.25-.247V2.748c0-.14.108-.248.25-.248h14.5c.142 0 .25.107.25.248V14.89"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M13 17.5a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM15.5 16.5l3 3"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const ViewIcon = createIcon({
  viewBox: '2 2 19 19',
  vectorComponent
});
