import React from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from '@components/Column';
import { Heading } from '@components/Heading';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { AgeInfo } from '../AgeInfo';
import { CitizenshipInfo } from '../CitizenshipInfo';
import { HighestEducationInfo } from '../HighestEducationInfo';
import { RightToWorkInfo } from '../RightToWorkInfo';

type AboutMeProps = {
  age?: number;
  gender?: string;
  citizenship?: string;
  candiRightToWork?: boolean;
  jobCountryCode?: string;
  highestEducation?: string;
};

export const AboutMe = (props: AboutMeProps) => {
  const {
    age,
    gender,
    citizenship,
    candiRightToWork,
    jobCountryCode,
    highestEducation
  } = props;
  const { t } = useTranslation();

  return (
    <Stack spacing="medium">
      <Row>
        <Heading level="h2">
          {t('applications.applicationCard.aboutMe.title')}
        </Heading>
      </Row>
      <Stack
        direction={{ mobile: 'column', tablet: 'row', desktop: 'row' }}
        justifyContent="space-between"
      >
        <Column
          spacing="xsmall"
          width={{
            mobile: 'full',
            tablet: '3/7',
            desktop: '3/7'
          }}
          marginBottom={{
            mobile: 'small',
            tablet: 'none',
            desktop: 'none'
          }}
        >
          {(age || gender) && <AgeInfo age={age} gender={gender} />}
          <HighestEducationInfo highestEducation={highestEducation} />
        </Column>
        <Column
          spacing="xsmall"
          width={{
            mobile: 'full',
            tablet: '1/2',
            desktop: '1/2'
          }}
        >
          {citizenship && <CitizenshipInfo citizenship={citizenship} />}
          <RightToWorkInfo
            candiRightToWork={candiRightToWork}
            jobCountryCode={jobCountryCode}
          />
        </Column>
      </Stack>
    </Stack>
  );
};
