import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <>
    <Path
      d="M18.554 13.341v6.264c0 .757-.305.895-1.054.895h-13c-.748 0-1-.244-1-1v-13c0-.756.252-1 1-1h6"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M11.554 14.683l-2.685.448.448-2.685 8.053-8.054s1.469-1.664 2.685-.447c1.217 1.216-.447 2.684-.447 2.684l-8.054 8.054z"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const EditIcon = createIcon({ viewBox: '3 3 18 18', vectorComponent });
