import { useTranslation } from 'react-i18next';
import { Application } from '@common/types/Application';
import { VirusScanStatus } from '@seek/je-shared-data/lib/types/virusScanStatus';

export function useCoverLetterAndResumeStatusMessages(
  application: Application
) {
  const { resume } = application;

  const { isResumeAttached, virusScanStatus } = resume || {};

  const { t } = useTranslation();

  const hasResume = isResumeAttached;
  const hasVirusInResume =
    virusScanStatus === VirusScanStatus.SCANNED_HAS_VIRUS;

  const { resumeMessageKey, isResumeStatusCritical } =
    (() => {
      switch (true) {
        case !hasResume:
          return {
            resumeMessageKey: 'applications.applicationCard.resumeMissing',
            isResumeStatusCritical: false
          };
        case hasVirusInResume:
          return {
            resumeMessageKey:
              'applications.applicationCard.resumeVirusDetected',
            isResumeStatusCritical: true
          };
      }
    })() || {};

  return {
    resumeStatusMessage: resumeMessageKey && t(resumeMessageKey),
    isResumeStatusCritical
  };
}
