import { useTranslation } from 'react-i18next';
import { useGetSourceParams } from '@common/hooks/useSearchQueryParam';
import { getUser } from '@store/auth';
import { postJob } from '@store/entities/jobs';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { PostJobFormFields } from '../../types';

type UseSubmitPostJobParams = {
  originalJobId?: string;
};

export const useSubmitPostJob = ({
  originalJobId
}: UseSubmitPostJobParams = {}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const sourceParams = useGetSourceParams();
  const { currentUser } = useAppSelector(getUser);

  const submitPostJob = (data: PostJobFormFields) => {
    const { business: businessData, ...jobData } = data;

    dispatch(
      postJob({
        ...jobData,
        ...(originalJobId ? { originalJobId } : {}),
        userData: currentUser,
        sourceParams,
        businessData,
        t
      })
    );
  };

  return { submitPostJob };
};
