import React from 'react';
import { useTranslation } from 'react-i18next';
import { localisedFormatDate } from '@common/helpers/dateTime';
import { ExpiredIcon } from '@components/Icon';
import { Row } from '@components/Row';
import { Text } from '@components/Text';

type Props = {
  expiredAt: string;
};

export const ExpiredExpireInformation = ({ expiredAt }: Props) => {
  const { t } = useTranslation();

  return (
    <Row
      alignItems="center"
      spacing="xxsmall"
      testID="expiry-info-for-expired-job"
    >
      <ExpiredIcon
        variant="outline"
        tone="neutralLight"
        testID="expired-icon"
      />

      <Text size="small">
        {t(
          'jobDetailsAndApplicationsListPage.explanationText.jobExpireDate.expiredOn',
          { date: localisedFormatDate(t, 'dd MMM yyyy', expiredAt) }
        )}
      </Text>
    </Row>
  );
};
