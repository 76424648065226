import REQUEST_KEY from '@common/constants/requestKeys';
import { FetchActionResultSuccess } from '@common/store/middleware/clientMiddlewareTypes';
import { Maybe } from '@common/types/Maybe';
import * as constants from '../../constants';
import { toJobServiceEndpoint } from '../stateUtils/toJobServiceEndpoint';
import { toFormattedJob } from './toFormattedJob';
import { ListJobItem } from './types';

type ListResult = {
  jobs: ListJobItem[];
  nextKey: Maybe<string>;
};

export type ListActionSuccess = FetchActionResultSuccess<
  typeof constants.LOAD_DASHBOARD_JOBS_SUCCESS,
  ListResult
>;

export function list(nextKey = null) {
  return {
    types: [
      constants.LOAD_DASHBOARD_JOBS,
      constants.LOAD_DASHBOARD_JOBS_SUCCESS,
      constants.LOAD_DASHBOARD_JOBS_FAIL
    ],
    promise: async (dispatch, getState, fetch): Promise<ListResult> => {
      const jobServiceEndpoint = toJobServiceEndpoint(getState);

      const nextKeyValue = nextKey
        ? `?nextKey=${encodeURIComponent(nextKey)}`
        : '';

      const body = await fetch(
        `${jobServiceEndpoint}/list${nextKeyValue}`,
        {
          headers: {
            'Content-Type': 'application/vnd.api+json'
          },
          method: 'GET',
          credentials: 'include'
        },
        {
          requestKey: REQUEST_KEY.JOB.LIST,
          showGlobalSpinner: false
        }
      );

      return {
        ...body,
        jobs: body.jobs.map(toFormattedJob)
      };
    }
  };
}
