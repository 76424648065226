import chunk from 'lodash.chunk';
import queryString from 'query-string';
import { EventTypes } from 'redux-segment';
import REQUEST_KEY from '@common/constants/requestKeys';
import { getStatusCount } from '@common/helpers/applications';
import { ClientError, ErrorPreventLogging } from '@common/helpers/errors';
import { Application, ApplicationStatus } from '@common/types/Application';
import { GENERAL_EXCEPTION } from '@seek/je-error-helper';
import { APPLICANT_STATUS } from '@seek/je-shared-data';
import { AppThunkAction, GroupedActions } from '../../store';
import * as constants from './constants';
import { getApplications } from './selectors';
import { Applications, ApplicationsStatuses, ResumeLinks } from './types';

export function loadApplicationSuccess(jobId, data, statusCounts) {
  return {
    type: constants.LOAD_APPLICATIONS_SUCCESS,
    result: {
      jobId,
      applications: data,
      statusCounts
    }
  };
}

function mapUpdatedApplicantStatus(
  data: Partial<Application>[],
  applicationId: string,
  status: ApplicationStatus
) {
  return data.map((application) => {
    if (application.id === applicationId) {
      application.status = status;
      return { ...application };
    }

    return application;
  });
}

export function loadCount(
  jobIds: string[] = []
): GroupedActions<ApplicationsStatuses> {
  return {
    types: [
      constants.LOAD_COUNT,
      constants.LOAD_COUNT_SUCCESS,
      constants.LOAD_COUNT_FAIL
    ],
    promise: async (dispatch, getState, fetch) => {
      const {
        config: { endpoints: { applicationService } = {} }
      } = getState();
      const jobIdChunks = chunk(jobIds, 20);

      let applicationsStatuses: ApplicationsStatuses = {};

      for (const ids of jobIdChunks) {
        const queryParam = queryString.stringify(
          { ids },
          { arrayFormat: 'none' }
        );
        const body = await fetch(
          `${applicationService}/count?${queryParam}`,
          {
            headers: {
              'Content-Type': 'application/vnd.api+json'
            },
            method: 'GET',
            credentials: 'include'
          },
          {
            requestKey: REQUEST_KEY.APPLICATION.LOAD_COUNT,
            shouldLoading: false
          }
        );

        applicationsStatuses = { ...applicationsStatuses, ...body };

        dispatch({
          type: constants.LOAD_COUNT_SUCCESS,
          result: { applicationsStatuses }
        });
        await new Promise((resolve) => setTimeout(resolve, 500));
      }

      return applicationsStatuses;
    }
  };
}

function handleResumeUrlError(body) {
  const { code, guid } = body;

  switch (code) {
    case GENERAL_EXCEPTION.VIRUS_DETECTED:
      throw new ErrorPreventLogging(
        'applications.applicationCard.resumeVirusDetected'
      );
    default:
      throw new ClientError('errors.defaultWithGuid', {
        guid
      });
  }
}

export function getResumeUrl(
  applicationId: string
): GroupedActions<ResumeLinks> {
  return {
    payload: {
      applicationId
    },
    types: [
      constants.LOAD_TEMPORARY_RESUME_LINK,
      constants.LOAD_TEMPORARY_RESUME_LINK_SUCCESS,
      constants.LOAD_TEMPORARY_RESUME_LINK_FAIL
    ],
    promise: async (dispatch, getState, fetch) => {
      const {
        config: { endpoints: { applicationService } = {} }
      } = getState();

      const body = await fetch(
        `${applicationService}/${applicationId}/resume`,
        {
          method: 'GET',
          credentials: 'include'
        },
        {
          requestKey: `${REQUEST_KEY.APPLICATION.GET_RESUME_URL_BY_ID(
            applicationId
          )}`,
          showGlobalSpinner: false,
          onResponseError: handleResumeUrlError
        }
      );

      return { applicationId, url: body.url };
    }
  };
}

function trackApplicantStatus({ jobId, applicationId, status }) {
  return {
    type: constants.APPLICANT_STATUS_TRACK,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'Update Applicant Status',
          properties: {
            jobId,
            applicationId,
            status
          }
        }
      }
    }
  };
}

export function updateApplicantStatus({
  applicationId,
  jobId,
  status
}: {
  applicationId: string;
  jobId: string;
  status: ApplicationStatus;
}): GroupedActions<Applications> {
  return {
    payload: {
      applicationId
    },
    types: [
      constants.UPDATE_APPLICANT_STATUS,
      constants.UPDATE_APPLICANT_STATUS_SUCCESS,
      constants.UPDATE_APPLICANT_STATUS_FAIL
    ],
    promise: async (dispatch, getState, fetch) => {
      const state = getState();
      const applications = getApplications(jobId)(state);
      const {
        config: { endpoints: { applicationService } = {} }
      } = state;

      await fetch(
        `${applicationService}/${applicationId}/status`,
        {
          method: 'PUT',
          credentials: 'include',
          body: JSON.stringify({ status, jobId })
        },
        {
          requestKey:
            REQUEST_KEY.APPLICATION.UPDATE_APPLICANT_STATUS_BY_ID(
              applicationId
            ),
          showGlobalSpinner: true,
          defaultError: 'errors.pleaseTryAgain'
        }
      );

      dispatch(trackApplicantStatus({ jobId, applicationId, status }));

      const data = mapUpdatedApplicantStatus(
        applications.data,
        applicationId,
        status
      );
      const statusCounts = getStatusCount(data);

      return {
        jobId,
        data,
        statusCounts
      };
    }
  };
}

type UpdateApplicantNotesParams = {
  notes: string;
  notesUpdatedAt: string;
  applicationId: string;
  jobId: string;
};
export function updateApplicantNotes({
  notes,
  notesUpdatedAt,
  applicationId,
  jobId
}: UpdateApplicantNotesParams) {
  return (dispatch, getState) => {
    const state = getState();
    const applications = getApplications(jobId)(state);

    const data = applications.data.map((application) => {
      if (application.id === applicationId) {
        return {
          ...application,
          notes,
          notesUpdatedAt
        };
      }
      return application;
    });

    dispatch({
      type: constants.UPDATE_APPLICANT_NOTES,
      payload: { applicationId },
      result: { data, jobId }
    });
  };
}

export function trackExpandedCoverLetter({
  jobId,
  candiApplicationId,
  applicationId,
  candiUserId,
  candiAnonymousId
}: {
  jobId: string;
  candiApplicationId?: string;
  applicationId: string;
  candiUserId: string | null;
  candiAnonymousId: string | null;
}) {
  return {
    type: constants.EXPANDED_COVER_LETTER_TRACK,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'Expanded cover letter',
          properties: {
            employerJobId: jobId,
            candidateApplicationId: candiApplicationId,
            employerApplicationId: applicationId,
            candiUserId,
            candiAnonymousId
          }
        }
      }
    }
  };
}

export function trackInteraction(
  applicationId: string,
  connectionType: string
): GroupedActions {
  return {
    types: [
      constants.INTERACTION_TRACK,
      constants.INTERACTION_TRACK_SUCCESS,
      constants.INTERACTION_TRACK_FAIL
    ],
    promise: async (dispatch, getState, fetch) => {
      const { config } = getState();
      const { endpoints: { applicationService } = {} } = config;

      await fetch(
        `${applicationService}/${applicationId}/interaction`,
        {
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify({ connectionType })
        },
        {
          requestKey: REQUEST_KEY.APPLICATION.TRACK_INTERACTION,
          showGlobalSpinner: true
        }
      );
    }
  };
}

export function trackApplicationListTabClicked({
  jobId,
  tab
}: {
  jobId: string;
  tab: 'matched' | 'other';
}) {
  return {
    type: constants.LIST_TAB_CLICKED_TRACK,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'Applicant list tab clicked',
          properties: { employerJobId: jobId, tab }
        }
      }
    }
  };
}

export function clearFilters({ jobId }: { jobId: string }): AppThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const applications = getApplications(jobId)(state);
    const filteredApplications = applications.data.filter(
      (application) => application.status !== APPLICANT_STATUS.NOT_SUITABLE
    );

    dispatch({
      type: constants.CLEAR_FILTER,
      result: {
        filteredApplications
      },
      meta: {
        analytics: {
          eventType: EventTypes.track,
          eventPayload: {
            event: 'Applicant list filters cleared',
            properties: {
              jobId
            }
          }
        }
      }
    });
  };
}

/** @deprecated */
export function setFixedPanelHeight(height: number): AppThunkAction {
  return (dispatch) => {
    dispatch({
      type: constants.UPDATE_FIXED_PANEL_HEIGHT,
      result: {
        height
      }
    });
  };
}
