import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { Else, If, Then } from '@common/components/If';
import { JobPostingNotAvailable } from '@common/components/JobPostingNotAvailable';
import { PostJobFormWithAuto } from '@common/components/PostJobFormWithAuto/PostJobFormWithAuto';
import { RegistrationProgressIndicator } from '@common/components/RegistrationProgressIndicator';
import { PostJobForm } from '@common/componentsV2/PostJobForm';
import { PostJobFormFields } from '@common/componentsV2/PostJobForm/types';
import { PostJobFormFields as PostJobFormFieldsWithAuto } from '@common/componentsV2/PostJobFormWithAuto/types';
import { useJobTitleAutoSuggest } from '@common/hooks/featureToggles';
import { useUpdateSourceQuery } from '@common/hooks/useUpdateSourceQuery';
import {
  useShowJobLimitReached,
  useShowOverBusinessLimitModal
} from '@common/hooks/userPackages';
import { SupportLevel } from '@seek/je-shared-data/lib/types/brand';
import { getUser } from '@store/auth';
import { loadBusinesses } from '@store/entities/businesses/actions';
import { loadDashboardJobs } from '@store/entities/jobs';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';
import { Box } from '@components/Box';
import { Card, CardContent } from '@components/Card';
import { Heading } from '@components/Heading';
import { Stack } from '@components/Stack';
import REQUEST_KEY from '../../../constants/requestKeys';
import { JobLimitReached } from '../job-limit-reached';
import { UpdateYourBusinessesModal } from './components';
import { usePostJob } from './usePostJob';

export const PostJob = () => {
  useUpdateSourceQuery();
  const { supportTier } = useAppSelector(getLocalisation);
  const { isAuthenticated } = useAppSelector(getUser);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const {
    submitPostJob,
    submitPostJobWithAuto,
    userHasBusiness,
    redirectToCreateBusinessPage,
    updateDepricatedUrls
  } = usePostJob();
  const showOverBusinessLimitBlockingModal = useShowOverBusinessLimitModal();
  const showJobLimitReached = useShowJobLimitReached();
  const pageTitle = t('postJobPage.title');
  const jobTitleAutoSuggestEnabled = useJobTitleAutoSuggest();

  updateDepricatedUrls();

  useEffect(() => {
    dispatch(loadDashboardJobs());
  }, [dispatch]);

  useEffect(() => {
    if (!userHasBusiness) {
      dispatch(loadBusinesses());
    }
  }, [dispatch, userHasBusiness]);

  const submitAction = (data: PostJobFormFields) => {
    if (isAuthenticated && userHasBusiness) {
      submitPostJob(data);
    } else {
      redirectToCreateBusinessPage();
    }
  };

  const submitActionWithAuto = (data: PostJobFormFieldsWithAuto) => {
    if (isAuthenticated && userHasBusiness) {
      submitPostJobWithAuto(data);
    } else {
      redirectToCreateBusinessPage();
    }
  };

  if (showJobLimitReached) {
    return <JobLimitReached pageTitle={pageTitle} />;
  }

  return (
    <Stack tone="transparent">
      <JoraEmployerHelmet title={pageTitle} />

      {showOverBusinessLimitBlockingModal && <UpdateYourBusinessesModal />}

      <If condition={supportTier === SupportLevel.TIER_3}>
        <Then>
          <JobPostingNotAvailable />
        </Then>
        <Else>
          <Stack spacing="medium" tone="transparent">
            <Box paddingTop="medium" paddingLeft="medium" tone="transparent">
              <Heading level="h1">{t('postJobPage.heading')}</Heading>
            </Box>

            <Card>
              <CardContent>
                <Stack spacing="large">
                  {!isAuthenticated ? (
                    <Box alignSelf="center">
                      <RegistrationProgressIndicator />
                    </Box>
                  ) : null}

                  {jobTitleAutoSuggestEnabled ? (
                    <PostJobFormWithAuto
                      mode="POST"
                      submitPostJob={submitActionWithAuto}
                      requestKey={REQUEST_KEY.JOB.CREATE}
                    />
                  ) : (
                    <PostJobForm
                      mode="POST"
                      submitPostJob={submitAction}
                      requestKey={REQUEST_KEY.JOB.CREATE}
                    />
                  )}
                </Stack>
              </CardContent>
            </Card>
          </Stack>
        </Else>
      </If>
    </Stack>
  );
};
