import omit from 'lodash.omit';
import { LOGOUT_SUCCESS } from '../../auth/constants';
import * as constants from './constants';
import { BusinessAction, BusinessWithUsers } from './types';

export type BusinessState = {
  items: { [id: string]: BusinessWithUsers };
  list: {
    businesses: BusinessWithUsers[];
  };
};

const initialState: BusinessState = {
  items: {},
  list: {
    businesses: []
  }
};

export default function reducer(
  state = initialState,
  action: Partial<BusinessAction> = {}
): BusinessState {
  switch (action.type) {
    case constants.LOAD_BUSINESS_SUCCESS:
      if (!isBusiness(action?.result)) {
        return state;
      }
      return {
        ...state,
        items: {
          ...state.items,
          [action.result.id]: action.result
        }
      };

    case constants.UPDATE_BUSINESS_SUCCESS:
      if (!isBusiness(action?.result)) {
        return state;
      }

      return {
        ...state,
        items: omit(state.items, action.result.id)
      };

    case constants.LOAD_BUSINESSES:
      return {
        ...state,
        list: {
          ...state.list,
          businesses: []
        }
      };

    case constants.LOAD_BUSINESSES_SUCCESS:
      if (!action.result) {
        return state;
      }
      return {
        ...state,
        list: {
          ...state.list,
          businesses: isBusiness(action.result)
            ? [action.result]
            : action.result
        }
      };

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
}

function isBusiness(
  result?: BusinessAction['result']
): result is BusinessWithUsers {
  return (result as BusinessWithUsers).id !== undefined;
}
