import { AD_TYPE } from '@seek/je-shared-data';

export function useAdTypeStyle(adType: AD_TYPE) {
  const badgeToneVariant = {
    [AD_TYPE.BASIC]: {
      variant: 'secondary',
      tone: 'neutral'
    },
    [AD_TYPE.PLUS]: {
      variant: 'secondary',
      tone: 'success'
    },
    [AD_TYPE.MAX]: {
      variant: 'secondary',
      tone: 'success'
    }
  } as const;

  return badgeToneVariant[adType];
}
