import { getLimitRolesEnabledStatus } from '@store/config';
import { useAppSelector } from '@store/hooks';

/**
 * Returns the full feature status of the limit job roles feature.
 * @returns The full feature status of the limit job roles feature.
 */
export function useLimitJobRolesStatus() {
  return useAppSelector(getLimitRolesEnabledStatus());
}
