import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import queryString from 'query-string';
import { withTranslation } from 'react-i18next';

import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import ErrorMessage from '../../../../common/components/ErrorMessage/ErrorMessage';
import ScreenHeading from '../../../../common/components/ScreenHeading/ScreenHeading';
import ResetPasswordForm from '../../../components/ResetPasswordForm/ResetPasswordForm';

import * as authActions from '../../../store/auth/actions';

@connect(
  ({ auth }) => ({
    isAuthenticated: auth.isAuthenticated
  }),
  {
    logout: authActions.logout,
    resetPassword: authActions.resetPassword
  }
)
@withTranslation()
export default class ResetPassword extends Component {
  static propTypes = {
    localisation: PropTypes.shape({
      countryCode: PropTypes.string.isRequired
    }),
    resetPassword: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    logout: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
  };

  async componentWillMount() {
    const { isAuthenticated, logout } = this.props;
    if (isAuthenticated) {
      await logout({ initiatedByUser: false });
    }
  }

  async submitResetPassword(data) {
    const {
      resetPassword,
      location: { search }
    } = this.props;
    const query = queryString.parse(search);
    const params = {
      code: query.code,
      userId: query.id,
      password: data.newPassword
    };

    await resetPassword(params);
  }

  render() {
    const { location: { search }, t } = this.props;
    const query = queryString.parse(search);

    if (!(query.code && query.id)) {
      return (
        <Stack tone="transparent">
          <Helmet title={t('resetPasswordPage.title')} />
          <ErrorMessage outsideCard extraTopMargin>
            <Text>
              {t('errors.default')}
            </Text>
          </ErrorMessage>
        </Stack>
      );
    }

    return (
      <Stack tone="transparent">
        <Helmet title={t('resetPasswordPage.title')} />
        <ScreenHeading>{t('resetPasswordPage.heading')}</ScreenHeading>
        <ResetPasswordForm
          submitResetPassword={this.submitResetPassword.bind(this)}
        />
      </Stack>
    );
  }
}