import React from 'react';
import { useTranslation } from 'react-i18next';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { useGetPostJobLink } from '@common/hooks/appPageLinks/useGetPostJobLink';
import { useRedirect } from '@common/hooks/useRedirect';
import { useRedirectUnauthenticatedUser } from '@common/hooks/useRedirectUnauthenticatedUser';
import { Box } from '@components/Box';
import { Button } from '@components/Button';
import { Card, CardContent } from '@components/Card';
import { Heading } from '@components/Heading';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';

export const JobNotFound = () => {
  useRedirectUnauthenticatedUser();

  const { t } = useTranslation();
  const { redirect } = useRedirect();
  const { postJobLink } = useGetPostJobLink();

  return (
    <Stack tone="transparent">
      <JoraEmployerHelmet title={t('errors.jobNotFound.title')} />
      <Stack tone="transparent" spacing="medium">
        <Box tone="transparent" paddingHorizontal="medium" marginTop="medium">
          <Heading level="h1">{t('errors.jobNotFound.title')}</Heading>
        </Box>
        <Card>
          <CardContent>
            <Stack spacing="medium">
              <Text>{t('errors.jobNotFound.description')}</Text>

              <Button onPress={redirect(postJobLink)}>
                {t('common.action.postNewJob')}
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </Stack>
  );
};
