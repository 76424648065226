import React from 'react';
import { Path } from 'svgs';
import { createIllustration } from '../createIllustration';

const vectorComponent = () => (
  <>
    <Path
      fill="#DDF8EB"
      d="M67.491 1.823C30.775-9.213 20.19 34.412 19.486 57.603 15.588 82.52 52.72 89.392 91.757 94.19c39.037 4.798 56.414-35.969 54.335-50.382-4.254-29.486-32.706-28.19-78.601-41.985z"
    />
    <Path
      fill="#5CC78B"
      d="M21.655 67.412c2.917 1.746 4.72 4.21 6.883 6.677 2.317 2.642 4.016 5.707 4.164 9.283-7.096.417-16.665.327-22.303-4.836-2.101-1.924-3.54-4.452-4.84-6.986a104.718 104.718 0 0 1-3.183-6.8c6.49-1.426 13.58-.753 19.279 2.662z"
    />
    <Path
      fill="#2FAC66"
      d="M32.886 82.629c-.376-.166-2.259-.998-2.817-1.253-4.212-1.919-8.407-4.113-11.83-7.094-.078-.058-.15-.124-.221-.185-.211-.186-.42-.382-.624-.578-2.04-1.927-3.764-4.181-4.991-6.881-.03-.061-.052-.12-.078-.18-.146-.34-.3-.68-.44-1.022a38.53 38.53 0 0 1-2.768-10.99 37.759 37.759 0 0 1 .186-9.31c.025-.223.056-.446.093-.669.106.015.212.033.317.051 3.453.614 6.518 2.268 9.106 4.587.215.19.43.392.64.6a29.973 29.973 0 0 1 6.262 8.72c.03.064.062.136.098.202.136.273.269.552.395.83a73.97 73.97 0 0 1 4.238 11.155 72.502 72.502 0 0 1 1.496 6.105c.14.68.53 5.734.938 5.912z"
    />
    <Path
      fill="#5CC78B"
      d="M31.751 82.13c-.56-.248-1.124-.5-1.683-.754-1.59-2.233-2.832-4.726-4.02-7.244a.61.61 0 0 1-.196-.008c-2.044-.44-4.045-.54-6.114-.207a11.25 11.25 0 0 0-1.5.365c-.077-.058-.149-.124-.22-.185-.211-.186-.42-.382-.624-.578.83-.292 1.713-.477 2.557-.614a16.117 16.117 0 0 1 2.692-.22c.58.005 2.136.433 2.624.247.06-.019.12-.054.18-.088-.87-1.853-1.736-3.705-2.724-5.462-3.566-1.58-6.81-1.56-10.396-.923-.155-.338-.3-.68-.44-1.023 3.154-1.11 6.803-.753 9.946.39-1.629-2.809-3.332-5.574-5.048-8.335a.374.374 0 0 1-.264-.055c-2.343-1.538-4.745-1.92-7.31-2.17a23.957 23.957 0 0 1-.092-.821c2.366.042 4.74.517 6.795 1.656-2.258-3.622-4.515-7.25-6.61-10.965.027-.222.057-.445.094-.668.106.015.213.031.317.051 2.342 3.552 4.567 7.194 6.758 10.855.315-2.22 1.169-4.352 2.348-6.267.215.191.43.393.64.6-1.116 2.17-2.163 4.287-2.297 6.825 1.798 3.03 5.524 9.36 5.603 9.502.595-2.779 1.48-5.267 2.957-7.605.03.066.063.136.098.203.137.272.27.551.395.828-1.383 2.29-2.325 4.834-2.532 7.524a.617.617 0 0 1-.182.37c.89 1.652 1.694 3.341 2.498 5.03.915-1.014 1.598-3 2.383-3.871.342-.38.704-.726 1.086-1.023.132.378.254.758.375 1.141-1.446 1.322-2.377 3.003-3.26 4.989.37.78.74 1.559 1.127 2.328 1.127 2.273 2.527 4.263 4.04 6.182z"
    />
    <Path
      fill="#FFF7E1"
      d="M124.77 84.806H31.193c-2.348 0-4.25-2.48-4.25-5.543v-65.85c0-3.064 1.902-5.544 4.25-5.544h93.577c2.349 0 4.25 2.48 4.25 5.544v65.85c0 3.063-1.901 5.543-4.25 5.543z"
    />
    <Path
      fill="#FFBB32"
      d="M129.15 16.942H26.95v-3.53c0-3.064 1.903-5.573 4.277-5.573h93.69c2.351 0 4.254 2.48 4.254 5.543v3.56h-.022z"
    />
    <Path
      fill="#C07D15"
      d="M107.95 13.908a1.574 1.574 0 1 0 0-3.153 1.574 1.574 0 0 0 0 3.153zM113.66 13.908a1.574 1.574 0 1 0 0-3.153 1.574 1.574 0 0 0 0 3.153zM119.38 13.908a1.575 1.575 0 1 0-.002-3.15 1.575 1.575 0 0 0 .002 3.15z"
    />
    <Path
      fill="#FFBEBE"
      d="M117.72 38.468H38.255c-1.276 0-2.287-1.254-2.287-2.77V24.73c0-1.546 1.035-2.77 2.287-2.77h79.465c1.276 0 2.287 1.254 2.287 2.77v10.967c.024 1.517-1.011 2.771-2.287 2.771z"
    />
    <Path
      fill="#FFDFDF"
      d="M117.72 57.745H38.255c-1.276 0-2.287-1.254-2.287-2.77V44.006c0-1.546 1.035-2.77 2.287-2.77h79.465c1.276 0 2.287 1.253 2.287 2.77v10.967c.024 1.517-1.011 2.771-2.287 2.771z"
    />
    <Path
      fill="#FFF2F1"
      d="M117.72 76.674H38.255c-1.276 0-2.287-1.254-2.287-2.77V62.935c0-1.546 1.035-2.77 2.287-2.77h79.465c1.276 0 2.287 1.254 2.287 2.77v10.967c.024 1.517-1.011 2.771-2.287 2.771z"
    />
    <Path
      fill="#FFDFDF"
      d="M50.307 34.997h-7.281a1.17 1.17 0 0 1-1.165-1.167V26.54a1.17 1.17 0 0 1 1.165-1.167h7.281c.64 0 1.165.525 1.165 1.167v7.291c-.03.671-.524 1.167-1.165 1.167z"
    />
    <Path
      fill="#FFF2F1"
      d="M50.307 54.274h-7.281a1.17 1.17 0 0 1-1.165-1.167v-7.291a1.17 1.17 0 0 1 1.165-1.167h7.281c.64 0 1.165.525 1.165 1.167v7.291c-.03.642-.524 1.167-1.165 1.167z"
    />
    <Path
      fill="#fff"
      d="M50.307 73.204h-7.281a1.17 1.17 0 0 1-1.165-1.167v-7.291a1.17 1.17 0 0 1 1.165-1.167h7.281c.64 0 1.165.525 1.165 1.167v7.291c-.03.642-.524 1.167-1.165 1.167z"
    />
    <Path d="M146.06 86.732c0-.03.029-.03.029-.058.233-.438.641-.788.991-1.109.466-.438.699-.117 1.108.204.291.234.583.467.874.73.117.087 1.399 1.254 1.224 1.43-1.195 1.137-2.361 2.275-3.556 3.413-.321.292-.641.612-.933.904-.291.292-.554.671-.903.905-.554.32-.758-.234-.7-.7.088-.73.583-1.226.933-1.839.495-.875.904-1.838.904-2.859-.03-.35-.117-.7.029-1.021zM115.54 89.007h.058c.495-.03.991.146 1.457.263.612.175.467.525.408 1.05l-.174 1.138c-.03.146-.35 1.867-.613 1.78-1.574-.438-3.177-.847-4.751-1.284a16.51 16.51 0 0 0-1.282-.321c-.408-.088-.875-.117-1.225-.321-.583-.321-.175-.788.234-.963.67-.292 1.34-.117 2.04-.146.991-.029 2.04-.175 2.915-.7.321-.175.583-.438.933-.496z" />
    <Path
      fill="#1C1C1C"
      d="m135.57 54.521 2.011 14.967c.029.204.117.438.233.613l10.377 14.559c.408.583.321 1.371-.233 1.809l-1.195.992a1.37 1.37 0 0 1-1.895-.146l-12.971-15.142a1.35 1.35 0 0 1-.262-.496l-5.043-16.601c-.262-.875.379-1.75 1.283-1.75h6.354c.671.028 1.253.524 1.341 1.195z"
    />
    <Path
      fill="#fff"
      d="M127.79 39.583c2.64 0 4.78-.418 4.78-.934s-2.14-.933-4.78-.933-4.781.418-4.781.933 2.141.934 4.781.934zM111.29 26.191c-.175.496-.408.671-.408.671.146.438-.117.904-.583 1.021l-.321.088c-.408.116-.845-.146-.962-.555v-.029s-.67-1.021-.903-2.48c-.321-1.867.408-2.888 1.02-2.947.554-.058 1.02 2.13 1.224 2.947l.292-.846c.029-.117.145-.175.262-.175s.233.088.262.204l.204.963c.03.38.03.759-.087 1.138z"
    />
    <Path fill="#fff" d="M126.96 38.848h1.545v-2.042h-1.545v2.042z" />
    <Path
      fill="#1C1C1C"
      d="m128.08 53.996-.379 4.61c0 .146-.029.262-.087.408l-5.917 15.26a.423.423 0 0 0-.058.204l-3.44 14.792a1.448 1.448 0 0 1-1.516 1.108l-.787-.058a1.442 1.442 0 0 1-1.311-1.634l4.751-35.478c.087-.758.787-1.313 1.545-1.254l5.888.466c.787.088 1.399.788 1.311 1.576z"
    />
    <Path
      fill="#fff"
      d="M138.98 61.757c-.088-.525-.321-.73-.321-.73.204-.408 0-.904-.437-1.079l-.292-.117a.808.808 0 0 0-1.02.409v.03s-.787.933-1.224 2.333c-.554 1.81.058 2.918.612 3.064.554.116 1.312-1.984 1.574-2.772l.175.875c.029.117.117.204.233.204a.322.322 0 0 0 .292-.175l.32-.933c.117-.35.175-.73.088-1.109z"
    />
    <Path
      fill="#187FE6"
      d="M134.66 38.386c-.757-.292-1.632-.408-2.39-.496-.699-.087-1.574-.263-2.215.117-.729.437-1.574.7-2.419.67a3.51 3.51 0 0 1-1.603-.466c-.409-.233-.7-.642-1.079-.904-.525-.35-1.195-.409-1.836-.438-.7-.03-1.399-.058-2.099-.146-.408-.058-.845-.088-1.253-.146-.379-.058-.816-.175-1.195-.175a17.056 17.056 0 0 1-2.682-.642c-.641-.204-1.312-.437-1.807-.904-1.691-1.576-2.186-6.39-2.303-7.615 0-.146-.146-.263-.321-.263l-2.739.321c-.146.03-.234.146-.234.263-.029 1.604-.087 10.678 3.032 12.575 3.002 1.809 7.345 3.238 8.365 3.56.117.028.204.174.204.29l-1.282 9.483c-.029.175.087.321.262.321l16.79.759c.174 0 .32-.175.291-.35l-1.807-7.995c-.058-.292.291-.467.495-.233 1.079 1.284 2.915 3.734 3.003 5.164.116 1.867-1.37 7.936-1.574 8.753-.03.058 0 .146.029.204.204.38 1.02 1.605 3.148.963.058-.03.116-.058.145-.117.554-.992 5.131-9.336 2.711-14.442-1.137-2.422-2.856-4.522-4.897-6.273a19.734 19.734 0 0 0-1.982-1.517c-.291-.117-.524-.233-.758-.32z"
    />
    <Path
      fill="#fff"
      d="M135.48 57.906a2.987 2.987 0 0 1-2.973-2.976c0-.058.029-.088.087-.088.059 0 .088.03.088.088a2.8 2.8 0 0 0 2.798 2.801c.058 0 .087.03.087.087-.029.03-.058.088-.087.088zM119.48 57.906c-.058 0-.087-.03-.087-.087 0-.059.029-.088.087-.088a2.8 2.8 0 0 0 2.799-2.8c0-.06.029-.088.087-.088s.088.029.088.087a2.987 2.987 0 0 1-2.974 2.976z"
    />
    <Path
      fill="#1C1C1C"
      d="M135.48 51.282c-.029 0-.059-.03-.088-.058l-2.215-9.395c0-.058.029-.088.058-.088.059 0 .088.03.088.059l2.215 9.394c.029.03 0 .059-.058.088zM119.48 48.307c-.029 0-.029 0 0 0-.058 0-.087-.058-.087-.087l.933-6.857c0-.058.058-.087.087-.058.058 0 .088.058.058.087l-.932 6.857c0 .029-.03.058-.059.058zM127.79 39.816a4.299 4.299 0 0 1-3.061-1.254c-.029-.03-.029-.088 0-.117.029-.03.088-.03.117 0a4.15 4.15 0 0 0 5.888 0 .088.088 0 0 1 .116 0c.03.029.03.087 0 .117a4.295 4.295 0 0 1-3.06 1.254z"
    />
    <Path
      fill="#fff"
      d="M146.18 90.204c-.029 0-.029 0-.058-.03l-1.137-1.137c-.029-.03-.029-.088 0-.117.029-.029.087-.029.117 0l1.136 1.138c.03.03.03.088 0 .117-.029 0-.058.029-.058.029zM145.71 90.641c-.029 0-.029 0-.058-.03l-1.137-1.137c-.029-.03-.029-.088 0-.117a.088.088 0 0 1 .116 0l1.137 1.138a.09.09 0 0 1 0 .117c0 .029-.029.029-.058.029zM112.13 91.05c-.058 0-.087-.058-.087-.087l.116-1.634c0-.059.059-.088.088-.088.058 0 .087.058.087.088l-.116 1.634c0 .058-.059.087-.088.087zM111.49 91.021c-.059 0-.088-.058-.088-.087l.117-1.634c0-.059.058-.088.087-.088.059 0 .088.058.088.088l-.117 1.634c0 .029-.029.087-.087.087z"
    />
    <Path
      fill="#1C1C1C"
      d="M111.2 28.816c-.029 0-.087-.03-.087-.087l-.087-.787c0-.059.029-.088.087-.088s.087.03.087.088l.088.787c0 .058-.029.087-.088.087zM109.16 29.02c-.029 0-.087-.03-.087-.087l-.088-.788c0-.058.029-.087.088-.087.058 0 .087.03.087.087l.087.788c0 .029-.029.087-.087.087zM109.83 28.962c-.03 0-.088-.03-.088-.087l-.087-.787c0-.059.029-.088.087-.088s.088.03.088.088l.087.787c0 .029-.029.087-.087.087zM110.53 28.874c-.029 0-.087-.03-.087-.087l-.088-.788c0-.058.029-.087.088-.087.058 0 .087.03.087.087l.087.788c0 .058-.058.087-.087.087zM138.92 60.618h-.029c-.029-.03-.058-.058-.058-.117l.291-.728c.029-.03.058-.059.117-.059.029.03.058.059.058.117l-.291.729c-.03.029-.059.058-.088.058zM137.02 59.86h-.029c-.029-.03-.058-.058-.058-.117l.291-.728c.029-.03.058-.059.117-.059.029.03.058.059.058.117l-.291.729c-.029.029-.059.058-.088.058zM137.67 60.093h-.029c-.029-.03-.058-.058-.058-.117l.291-.728c.029-.03.059-.059.117-.059.029.03.058.059.058.117l-.291.729c-.029.058-.058.058-.088.058zM138.28 60.356h-.029c-.029-.03-.059-.058-.059-.117l.292-.728c.029-.03.058-.059.116-.059.029.03.058.059.058.117l-.291.729c-.029.029-.058.058-.087.058zM122.57 29.809v-.38c.088-1.02.904-1.575 1.895-1.37.67.145 1.341.408 2.04.466.962.088 1.807-.437 2.74-.437.729 0 1.429.291 1.982.73 1.108.903 1.778 2.42 1.72 3.85a4.812 4.812 0 0 1-1.078 2.772c-.467.584-1.05 1.021-1.778 1.167-1.545.321-3.178-.759-4.315-1.663a8.848 8.848 0 0 1-2.477-3.005c-.35-.642-.671-1.371-.729-2.13z"
    />
    <Path
      fill="#fff"
      d="M127.61 37.045c2.012 0 3.644-1.633 3.644-3.647s-1.632-3.647-3.644-3.647-3.644 1.633-3.644 3.647 1.632 3.647 3.644 3.647z"
    />
    <Path
      fill="#fff"
      d="M131.25 35.178a.991.991 0 1 0 0-1.982.991.991 0 0 0 0 1.982z"
    />
    <Path
      fill="#1C1C1C"
      d="M124.06 32.143a.99.99 0 0 0 .583 0c.699-.204 1.136-.875 1.836-1.05.758-.204 1.108.408 1.428.963.408.67.991 1.283 1.691 1.663.874.495 1.778.175 1.895-.905.116-.992-.35-1.896-1.05-2.538-.845-.759-1.982-1.255-3.09-1.517-1.078-.263-2.098-.438-3.089.204-.758.467-1.05 1.196-1.05 2.071 0 .525.35.992.846 1.109z"
    />
    <Path
      fill="#B9DEF8"
      d="m14.734 56.803-.169.098a.158.158 0 0 0 .085.295h6.49c.084 0 .154-.07.154-.154v-2.613a.156.156 0 0 0-.154-.155h-6.504c-.155 0-.211.211-.085.295l.169.099 1.615.927c.099.056.099.21 0 .267l-1.601.941z"
    />
    <Path
      fill="#FFBB32"
      d="M21.728 54.274h-.941V67.1h.941V54.274zM32.941 70.177h-1.84v15.06h1.84v-15.06z"
    />
    <Path
      fill="#FFD260"
      d="M28.656 58.306h8.738v11.871H20.34v-3.54c0-4.594 3.723-8.33 8.316-8.33z"
    />
    <Path
      fill="#FFBB32"
      d="M41.86 70.177h-8.92v-7.404a4.455 4.455 0 0 1 4.453-4.453 4.455 4.455 0 0 1 4.453 4.454v7.403h.014z"
    />
    <Path
      fill="#B9DEF8"
      d="m31.63 25.924-19.226 6.29a.418.418 0 0 1-.518-.263L8.547 21.745a.418.418 0 0 1 .263-.518l19.226-6.29c.21-.068.45.053.518.263l3.339 10.206a.418.418 0 0 1-.263.518z"
    />
    <Path
      fill="#4BA2EE"
      d="M20.145 24.081 8.866 22.005c-.444-.087-.446-.651-.01-.794l19.135-6.26c.436-.142.773.33.461.647l-7.856 8.336a.503.503 0 0 1-.45.147z"
    />
  </>
);

export const JobLimitReachedIllustration = createIllustration({
  viewBox: '0 0 151 95',
  vectorComponent
});
