import { useAppDispatch } from '@store/hooks';
import { set } from '@store/userData/actions';

export function useSetAppPreference() {
  const dispatch = useAppDispatch();

  return {
    setAppPreference: (countryCode: string) =>
      dispatch(set('appPreferences', { countryCode }))
  };
}
