import React from 'react';
import { Row } from '@components/Row';
import { Text } from '@components/Text';

type NotesHeadingProps = {
  notesUpdatedAt: string;
  headingText: string;
};

export const NotesHeading = ({
  notesUpdatedAt,
  headingText
}: NotesHeadingProps) => {
  return (
    <Row spacing="small" alignItems="center" marginBottom="xxsmall">
      <Text weight="regular" size="small">
        {headingText}
      </Text>
      <Text>&bull;</Text>
      <Text size="xsmall" tone="neutralLight">
        {notesUpdatedAt}
      </Text>
    </Row>
  );
};
