import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-native';
import { openExternalURL } from '@common/helpers/externalConnection';
import {
  useBrandNameText,
  useSiteCountryCode,
  useSupportSalesEmail
} from '@common/hooks/localisation';
import { useSupportSalesWhatsApp } from '@common/hooks/localisation/useSupportSalesWhatsApp';
import { useBusinessLimit } from '@common/hooks/userPackages';
import { Box } from '@components/Box';
import { Button } from '@components/Button';
import { Column } from '@components/Column';
import { UpdateYourBusinessesIllustration } from '@components/Illustration';
import { Modal } from '@components/Modal';
import { Text } from '@components/Text';
import { TextLink } from '@components/TextLink';

export const UpdateYourBusinessesModal = () => {
  const { t } = useTranslation();
  const siteCountryCode = useSiteCountryCode();
  const brandNameText = useBrandNameText();
  const supportSalesEmail = useSupportSalesEmail();
  const supportSalesWhatsApp = useSupportSalesWhatsApp();
  const businessLimit = useBusinessLimit();
  const history = useHistory();

  const redirectToListBusinesses = () => {
    history.push(`/${siteCountryCode}/businesses`);
  };

  const openEmail = () => {
    openExternalURL(`mailto:${supportSalesEmail}`);
  };

  const openWhatsapp = () => {
    const parsedWhatsappNumber = supportSalesWhatsApp
      ?.replace(/[+\s]/g, '')
      .trim();
    openExternalURL(`https://wa.me/${parsedWhatsappNumber}`);
  };

  return (
    <Modal
      visible
      title={t('postJobPage.updateYourBusinessesModal.title')}
      onRequestClose={() => {}}
      hideCloseButton
    >
      <Column tone="transparent" spacing="small" alignItems="center">
        <UpdateYourBusinessesIllustration />
        <Text weight="regular">
          {t('postJobPage.updateYourBusinessesModal.heading1')}
        </Text>
        <Text align="center">
          {t('postJobPage.updateYourBusinessesModal.description1', {
            count: businessLimit
          })}
        </Text>
        <Box marginBottom="xlarge">
          <Button onPress={redirectToListBusinesses}>
            {t('postJobPage.updateYourBusinessesModal.cta')}
          </Button>
        </Box>
        <Text weight="regular">
          {t('postJobPage.updateYourBusinessesModal.heading2')}
        </Text>
        <Text align="center">
          <Trans
            i18nKey="postJobPage.updateYourBusinessesModal.description2"
            values={{
              brandNameText,
              supportSalesEmail,
              supportSalesWhatsApp,
              count: businessLimit
            }}
            components={[
              <TextLink onPress={openWhatsapp}>0</TextLink>,
              <TextLink onPress={openEmail}>1</TextLink>
            ]}
          />
        </Text>
      </Column>
    </Modal>
  );
};
