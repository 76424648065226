import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

@connect(
  ({ app }) => ({
    loadStack: app.loadStack
  })
)
export default class ListWithLoadingState extends Component {
  static propTypes = {
    requestKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
    loadStack: PropTypes.array.isRequired,
    zeroState: PropTypes.node.isRequired,
    isZeroState: PropTypes.bool.isRequired,
    loadingState: PropTypes.node.isRequired,
    isRetainList: PropTypes.bool,
    children: PropTypes.node
  };

  checkLoadStatus() {
    const { loadStack, requestKeys = [] } = this.props;

    return requestKeys.some(key =>
      loadStack.includes(key)
    );
  }

  render() {
    const { loadingState, zeroState, isZeroState, children, isRetainList } = this.props;
    const isLoading = this.checkLoadStatus();

    return (
      <Fragment>
        {
          isLoading && isRetainList && children
        }
        {
          isLoading ? loadingState :
            <Fragment>
              {isZeroState ? zeroState : children}
            </Fragment>
        }
      </Fragment>
    );
  }
}
