import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => {
  return (
    <>
      <Path
        d="M12 20.5a8.5 8.5 0 100-17 8.5 8.5 0 000 17z"
        strokeMiterlimit={10}
      />
      <Path
        d="M12 14.805a2.929 2.929 0 100-5.857 2.929 2.929 0 000 5.857z"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M7.433 18.004c.85-1.9 2.573-3.2 4.567-3.2 1.994 0 3.717 1.3 4.567 3.2"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
};

export const AccountIcon = createIcon({
  viewBox: '3 3 18 18',
  vectorComponent
});
