import { useBreakpoint } from '@theme/DesignSystemProvider/BreakpointContext';
import { Sizes } from '@theme/tokens/size';

export const useProgressIndicatorStyles = (): { width: Sizes } => {
  const breakpoint = useBreakpoint();

  return {
    width: breakpoint === 'mobile' ? '3/4' : 'full'
  };
};
