// Send to react native
import { inNativeApp } from '@src/joraEmployer/whereAmIRunning';

/**
 * Send a message to the native app
 * @param action The action to send to the native app
 * @param payload The payload to send to the native app
 * @returns A promise that resolves with the response from the native app
 */
export function sendToNative<T, R>(
  action: string,
  payload: T
): Promise<R | void> {
  return new Promise((resolve) => {
    if (!inNativeApp() || cantPost()) return resolve();

    // Track the message id to match the response
    const msgId = newMsgId();

    // Set up a listener for the response
    waitForResponse(msgId).then((response) => {
      resolve(response);
    });

    // Send the message to the native app
    sendMsg({ action, msgId, payload });
  });
}

function sendMsg(requestObject: any) {
  (window as any).ReactNativeWebView.postMessage(JSON.stringify(requestObject));
}

function cantPost() {
  return (
    !(window as any).ReactNativeWebView ||
    !(window as any).ReactNativeWebView.postMessage
  );
}

function waitForResponse(msgId: string): Promise<any> {
  return new Promise((resolve) => {
    const listener = (event: any) => {
      const data = JSON.parse(event.data);
      if (data.msgId === msgId) {
        document.removeEventListener('message', listener);
        window.removeEventListener('message', listener);
        resolve(data);
      }
    };

    document.addEventListener('message', listener);
    window.addEventListener('message', listener);
  });
}

function newMsgId() {
  return Math.random().toString(36).substring(7);
}
