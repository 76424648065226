import React from 'react';
import { t } from 'i18next';
import { Trans as I18nextTrans } from 'react-i18next';
import { useRedirect } from '@common/hooks/useRedirect';
import { Heading } from '@components/Heading';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { TextLink } from '@components/TextLink';

type CandidateLoginProps = {
  candidateLogin: string;
  candidateRegister: string;
};

export const CandidateLogin = (props: CandidateLoginProps) => {
  const { candidateLogin, candidateRegister } = props;
  const { redirect } = useRedirect();

  return (
    <Stack spacing="medium">
      <Heading level="h2" weight="regular">
        {t('loginForm.explanationText.areYouJobseeker')}
      </Heading>

      <Row>
        <I18nextTrans
          i18nKey="loginForm.explanationText.loginOrRegister"
          parent={Text}
          components={[
            <TextLink onPress={redirect(candidateLogin)}>0</TextLink>,
            <TextLink onPress={redirect(candidateRegister)}>2</TextLink>
          ]}
        />
      </Row>
    </Stack>
  );
};
