import React from 'react';
import { PostIcon } from '@components/Icon';
import { Link } from '@components/Link';

type AddNoteButtonProps = {
  children: string;
  onPress: () => void;
};

export const AddNotesButton = ({ onPress, children }: AddNoteButtonProps) => {
  return (
    <Link
      onPress={onPress}
      icon={<PostIcon variant="outline" tone="info" size="small" />}
    >
      {children}
    </Link>
  );
};
