const mappings = [{ pattern: /<(\/?)b>/g, value: '<$1strong>' }];

/**
 * Replaces rich text tags in the given text based on predefined mappings. Currently, only swaps bold tags to strong tags.
 *
 * @param text - The input string containing rich text tags to be swapped.
 * @returns The modified string with rich text tags swapped.
 */
export function swapRichTags(text: string): string {
  return mappings.reduce(
    (acc, { pattern, value }) => acc.replace(pattern, value),
    text
  );
}
