import { EventTypes } from 'redux-segment';
import * as consts from './constants';

type DefaultNpsParams = {
  page: string;
  userType: string;
  applicantsReceived: boolean;
  jobId: string;
};

export type TrackScoreParams = {
  score: number;
} & DefaultNpsParams;

export type TrackReasonParams = {
  reason: string;
} & DefaultNpsParams;

export function track({
  score,
  page,
  userType,
  applicantsReceived,
  jobId
}: TrackScoreParams) {
  return {
    type: consts.NPS_TRACK_SUBMITTED,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'NPS Submitted',
          properties: {
            score,
            page,
            userType,
            applicantsReceived,
            jobId
          }
        }
      }
    }
  };
}

export function trackReason({
  reason,
  page,
  userType,
  applicantsReceived,
  jobId
}: TrackReasonParams) {
  return {
    type: consts.NPS_TRACK_REASON,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'NPS Reason Submitted',
          properties: {
            reason,
            page,
            userType,
            applicantsReceived,
            jobId
          }
        }
      }
    }
  };
}
