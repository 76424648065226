import React from 'react';
import { useLoadJobFromParamsIntoDraft } from '@common/components/PostJobForm/hooks/useLoadJobIntoDraft/useLoadJobFromParamsIntoDraft';
import { useSubmitEditJob } from '@common/components/PostJobForm/hooks/useSubmitEditJob/useSubmitEditJob';
import { PostJobForm } from '@common/componentsV2/PostJobForm';
import REQUEST_KEY from '@common/constants/requestKeys';

/**
 * Renders a edit form, fetching the original job from the server
 * and loading it into the draft store.
 * @returns An edit form
 */
export const EditFormWithTextTitle = () => {
  useLoadJobFromParamsIntoDraft();
  const { submitEditJob } = useSubmitEditJob();

  return (
    <PostJobForm
      mode="EDIT"
      submitPostJob={submitEditJob}
      requestKey={REQUEST_KEY.JOB.EDIT}
    />
  );
};
