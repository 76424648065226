import React from 'react';
import {
  TabList as PrimitiveTabList,
  TabListProps as PrimitiveTabListProps
} from '@components/Tabs';
import { TabListContext } from './TabListContext';

type Props = {
  onTabPress?: (index: number) => void;
} & PrimitiveTabListProps;

export const TabListProvider = (props: Props) => {
  const { children, onTabPress, ...restProps } = props;
  const validChildren = children.filter((child) => child);

  return (
    <PrimitiveTabList {...restProps}>
      {validChildren.map((child, index) => (
        <TabListContext.Provider
          value={{ tabIndex: index, onTabPress }}
          key={index}
        >
          {child}
        </TabListContext.Provider>
      ))}
    </PrimitiveTabList>
  );
};
