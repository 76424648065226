import React from 'react';
import { EmailIcon, PhoneIcon } from '@components/Icon';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';

type PhoneAndEmailProps = {
  mobile?: string;
  email?: string;
};

export const PhoneAndEmail = ({ mobile, email }: PhoneAndEmailProps) => (
  <Stack
    spacing={{ desktop: 'xlarge', tablet: 'xlarge', mobile: 'small' }}
    direction={{ desktop: 'row', tablet: 'row', mobile: 'column' }}
  >
    <Row spacing="xxsmall" alignItems="center">
      <PhoneIcon variant="outline" tone="success" size="large" />
      <Text>{mobile}</Text>
    </Row>

    <Row spacing="xxsmall" alignItems="center">
      <EmailIcon variant="outline" tone="success" size="large" />
      <Text>{email}</Text>
    </Row>
  </Stack>
);
