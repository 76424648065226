import React from 'react';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { Reason } from './Reason';
import { useReasonValidation } from './useReasonValidation';

export const ReasonController = <T extends FieldValues>({
  name,
  control
}: UseControllerProps<T>) => {
  const reasonRules = useReasonValidation();

  return (
    <Controller
      name={name}
      control={control}
      rules={reasonRules}
      render={({ field: { onChange, value }, formState }) => (
        <Reason
          onChange={onChange}
          value={value}
          error={formState?.errors[name]?.message as string}
        />
      )}
    />
  );
};
