import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <>
    <Path
      d="M16.5 20.5h-9c-.775 0-1-.82-1-1.5V7.5h11V19c0 .68-.225 1.5-1 1.5zM4.5 7.5h15M8.435 7.142C8.435 5.407 10.03 3.5 12 3.5c1.97 0 3.565 1.907 3.565 3.642M9.5 17.5v-7M14.5 10.5v7"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const TrashIcon = createIcon({ viewBox: '3 3 18 18', vectorComponent });
