import { Platform } from 'react-native';
import { hasDraftData } from '@common/helpers/draftFromStore/hasDraftData';
import { getPromises } from '@common/helpers/fetchdata';
import { pathUserHasLimitedAccessTo } from '@common/helpers/userAccessPathMapper';
import { refreshClient as refreshWebClient } from '@common/helpers/webClient';
import { BUSINESS_DRAFT_FORM, JOB_DRAFT_FORM } from '@store/draft/constants';
import * as authActions from '../../auth/actions';
import { AppDispatch, GetState } from '../../store';

type RefreshClientParams = {
  url: string;
  dispatch: AppDispatch;
  getState: GetState;
};

export async function refreshClient({
  url,
  dispatch,
  getState
}: RefreshClientParams) {
  if (Platform.OS === 'web') {
    return refreshWebClient();
  }

  await authActions.handleRefreshUserDetails({ dispatch, getState });

  const { emailVerified, appealStatus, emailLastVerifiedAt } =
    getState().auth?.currentUser || {};
  const { countryCode } = getState().localisation;
  const path = pathUserHasLimitedAccessTo({
    countryCode,
    userDetails: { emailVerified, appealStatus, emailLastVerifiedAt },
    hasDraftData: hasDraftData([JOB_DRAFT_FORM, BUSINESS_DRAFT_FORM])
  });

  if (!path || path === url) {
    // To avoid compile time dependency where all pages are loaded for each unit test.
    const { default: routes } = require('../../../routes');

    return await Promise.all(
      getPromises({
        url,
        store: { dispatch, getState },
        routes
      })
    );
  }

  return;
}
