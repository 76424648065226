import React from 'react';
import { UpgradeAccountModal } from '@common/components/Account/components';
import { useGetHomeLink } from '@common/hooks/appPageLinks';
import { useBrandNameText } from '@common/hooks/localisation';
import { useRequestUpgradeAccountCallback } from '@common/hooks/orgs/useRequestUpgradeAccountCallback';
import { useRedirect } from '@common/hooks/useRedirect';
import { useTrackUpgradeAccountButtonClicked } from '@src/joraEmployer/pages/account/hooks';
import { useTrackContactMeButtonClicked } from '@src/joraEmployer/pages/account/hooks/useTrackContactMeClicked/useTrackContactMeClicked';
import { getUser } from '@store/auth';
import { useAppSelector } from '@store/hooks';
import { Button } from '@components/Button';
import { useModal } from '@components/Modal';

type UpgradeAccountModalWithButtonProps = {
  pageTitle: string;
  buttonText: string;
};

export const UpgradeAccountModalWithButton = ({
  pageTitle,
  buttonText
}: UpgradeAccountModalWithButtonProps) => {
  const { redirect } = useRedirect();
  const { isModalOpen, openModal, closeModal } = useModal();
  const { currentUser } = useAppSelector(getUser);
  const trackUpgradeAccountButtonClicked =
    useTrackUpgradeAccountButtonClicked();
  const trackContactMeClicked = useTrackContactMeButtonClicked();
  const { requestUpgradeAccountCallback } = useRequestUpgradeAccountCallback();
  const brandNameText = useBrandNameText();
  const homePath = useGetHomeLink();

  const onUpgradeClicked = () => {
    openModal();
    trackUpgradeAccountButtonClicked();
  };

  const handleRequstUpgradeSubmit = () => {
    requestUpgradeAccountCallback({ page: `${pageTitle} | ${brandNameText}` });
    trackContactMeClicked();
  };

  const handleCloseModalWhenComplete = () => {
    redirect(homePath)();
  };

  return (
    <>
      <Button onPress={onUpgradeClicked}>{buttonText}</Button>
      <UpgradeAccountModal
        mobile={currentUser.mobile}
        email={currentUser.email}
        visible={isModalOpen}
        onRequestClose={closeModal}
        onRequestUpgradeSubmit={handleRequstUpgradeSubmit}
        onCloseModalWhenComplete={handleCloseModalWhenComplete}
      />
    </>
  );
};
