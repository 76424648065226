import { useCallback } from 'react';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { useAppDispatch } from '@store/hooks';
import * as paymentActions from '@store/payment/actions';

export function useFetchAdPrices() {
  const dispatch = useAppDispatch();

  const getAdPrices = useCallback(
    (countryCode: SupportedCountryCode) => {
      dispatch(paymentActions.getAdPrices(countryCode));
    },
    [dispatch]
  );

  return {
    getAdPrices
  };
}
