import React, { useContext } from 'react';
import { BusinessAddress as InnerField } from '@common/components/BusinessForm/components/BusinessAddress/BusinessAddress';
import { FieldWrapper } from '../FieldWrapper';
import { FormContext } from '../FormContext';
import { businessAddressRules } from '../rules';

export default function BusinessAddress({ initialValue, countryCode }) {
  const { t } = useContext(FormContext);

  return (
    <FieldWrapper<any>
      name="businessAddress"
      rules={businessAddressRules(t)}
      render={(props: any) => {
        return (
          <InnerField
            {...props}
            initialValue={initialValue}
            country={countryCode}
          />
        );
      }}
    />
  );
}
