import { FILTERS } from '../../../types';

type Filter = {
  [FILTERS.DISTANCE]?: number;
};

type Item = {
  distanceFromJobKm?: number;
};

export const filterDistance = <T extends Item>(
  filter: Filter,
  items: T[]
): T[] => {
  const filterValue = filter[FILTERS.DISTANCE];

  if (!filterValue) {
    return items;
  }

  return items.filter((item) => {
    return item.distanceFromJobKm && item.distanceFromJobKm <= filterValue;
  });
};
