import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AlreadyHaveAccountLink } from '@caja/Form/forms/components';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { useBrandDomain } from '@common/hooks/localisation/useBrandDomain';
import { useBrandName } from '@common/hooks/localisation/useBrandName';
import { BUSINESS_ROLE, INDUSTRY } from '@seek/je-shared-data';
import { CajaContext } from '@src/caja/context/CajaContext';
import { Box } from '@components/Box';
import { Stack } from '@components/Stack';
import { useListenForChanges } from '../hooks';
import { FormContext } from './FormContext';
import { useShowCountrySelector } from './hooks';
import { hydrateBusinessFormProps } from './props/hydrateDefaultProps';
import {
  BusinessAddress,
  BusinessName,
  BusinessRegistration,
  BusinessSize,
  BusinessWebsite,
  CountrySelector,
  IndustryDescription,
  IndustrySelector,
  RecruitmentCompanyName,
  Role,
  Submit
} from './topLevelFields';
import {
  CompleteBusinessFormData,
  FormProps,
  IncompleteBusinessFormData
} from './types';

export default function BusinessForm(opts: FormProps) {
  const { updateDebounce, t } = useContext(CajaContext);
  const props = hydrateBusinessFormProps(opts);
  const { onChange, onSubmit, defaultValues } = props;
  const brandDomain = useBrandDomain();
  const brandName = useBrandName();

  const [countryCode, setCountryCode] = useState<string | undefined>(
    defaultValues?.country
  );
  const [showIndustryDescription, setShowIndustryDescription] =
    useState<boolean>(false);
  const [showRecruitmentCompanyName, setShowRecruitmentCompanyName] =
    useState<boolean>(false);

  const showCountrySelector = useShowCountrySelector(brandName);

  const {
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors, isSubmitted, isValid }
  } = useForm<IncompleteBusinessFormData>({
    defaultValues,
    mode: 'onBlur'
  });

  function verifySubmit(data: IncompleteBusinessFormData) {
    onSubmit(data as CompleteBusinessFormData);
  }

  function resetIndustryDescription(data: IncompleteBusinessFormData) {
    if (data.industryId !== INDUSTRY.OTHER && data.industryOtherDescription) {
      setValue('industryOtherDescription', undefined);
    }
  }

  function resetRecruitementCompanyName(data: IncompleteBusinessFormData) {
    if (data.role !== BUSINESS_ROLE.RECRUITER && data.recruitmentCompanyName) {
      setValue('recruitmentCompanyName', undefined);
    }
  }

  function interceptOnChange(data: IncompleteBusinessFormData) {
    setCountryCode(data.country);
    setShowIndustryDescription(data.industryId === INDUSTRY.OTHER);
    setShowRecruitmentCompanyName(data.role === BUSINESS_ROLE.RECRUITER);
    resetIndustryDescription(data);
    resetRecruitementCompanyName(data);

    onChange(data);
  }

  useListenForChanges({ watch, updateDebounce, onChange: interceptOnChange });

  return (
    <FormContext.Provider value={{ control, errors, t }}>
      <Stack spacing="large">
        <BusinessName />
        {showCountrySelector && (
          <Box zIndex={10}>
            <CountrySelector />
          </Box>
        )}
        <Box zIndex={9}>
          <BusinessAddress
            initialValue={defaultValues.businessAddress || ''}
            countryCode={countryCode}
          />
        </Box>
        <Box zIndex={8}>
          <IndustrySelector />
        </Box>
        {showIndustryDescription && <IndustryDescription />}
        <Box zIndex={7}>
          <BusinessSize />
        </Box>
        <Box zIndex={6}>
          <Role />
        </Box>
        {showRecruitmentCompanyName && <RecruitmentCompanyName />}
        <BusinessWebsite
          helperText={t('verifyAccountDetailsForm.fieldHelpText.website', {
            returnObjects: true,
            brandDomain,
            brand: brandName.toLowerCase()
          })}
        />
        <BusinessRegistration />
        <Submit onPress={handleSubmit(verifySubmit)}>
          {t('common.action.next')}
        </Submit>

        {isSubmitted && !isValid ? (
          <ErrorMessage>{t('validations.general.summary')}</ErrorMessage>
        ) : null}

        <AlreadyHaveAccountLink />
      </Stack>
    </FormContext.Provider>
  );
}
