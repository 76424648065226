import { Reducer, combineReducers } from 'redux';
import { RouterState } from 'redux-first-history/build/es6/reducer';
import { reducer as formReducer } from 'redux-form';
import appReducers from './app/reducers';
import authReducers from './auth/reducers';
import configReducers from './config/reducers';
import draftFormReducers from './draft/reducers';
import applicationReducers from './entities/applications/reducers';
import businessesReducers from './entities/businesses/reducers';
import { jobsReducers } from './entities/jobs/reducers';
import googleLocationAutoSuggestReducers from './googleLocationAutoSuggest/reducers';
import localizationReducers from './localisation/reducers';
import navigationReducers from './navigation/reducers';
import paymentReducer from './payment/reducers';
import platformReducers from './platform/reducers';
import userDataReducers from './userData/reducers';

const rootReducer = (routerReducer: Reducer<RouterState>) =>
  combineReducers({
    app: appReducers,
    auth: authReducers,
    platform: platformReducers,
    userData: userDataReducers,
    router: routerReducer,
    form: formReducer,
    googleLocationAutoSuggest: googleLocationAutoSuggestReducers,
    localisation: localizationReducers,
    config: configReducers,
    payment: paymentReducer,
    entities: combineReducers({
      jobs: jobsReducers,
      applications: applicationReducers,
      businesses: businessesReducers
    }),
    draft: draftFormReducers,
    navigation: navigationReducers
  });

export default rootReducer;
