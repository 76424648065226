import React from 'react';
import { useTranslation } from 'react-i18next';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { Else, If, Then } from '@common/components/If';
import { JobPostingNotAvailable } from '@common/components/JobPostingNotAvailable';
import { useRegistrationEnabled } from '@common/hooks/featureToggles';
import { useRedirectAuthenticatedUser } from '@common/hooks/useRedirectAuthenticatedUser';
import { useUpdateSourceQuery } from '@common/hooks/useUpdateSourceQuery';
import { SupportLevel } from '@seek/je-shared-data/lib/types/brand';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';
import { Box } from '@components/Box';
import { Heading } from '@components/Heading';
import { Stack } from '@components/Stack';
import {
  BottomRegistrationCard,
  InformationCard,
  TopRegistrationCard
} from './components';

export const SimpleLanding = () => {
  useRedirectAuthenticatedUser();
  useUpdateSourceQuery();

  const isRegistrationEnabled = useRegistrationEnabled();
  const { supportTier } = useAppSelector(getLocalisation);
  const { t } = useTranslation();

  return (
    <Stack tone="transparent">
      <JoraEmployerHelmet title={t('employerLandingPage.title')} />
      <If condition={supportTier === SupportLevel.TIER_3}>
        <Then>
          <JobPostingNotAvailable />
        </Then>
        <Else>
          <Stack spacing="medium" tone="transparent">
            <Box
              paddingHorizontal="medium"
              marginTop="medium"
              tone="transparent"
            >
              <Heading level="h1">{t('employerLandingPage.title')}</Heading>
            </Box>

            {isRegistrationEnabled ? <TopRegistrationCard /> : null}

            <InformationCard
              heading={t('employerLandingPage.easyJobPostingCard.heading')}
              text={t('employerLandingPage.easyJobPostingCard.description')}
            />

            <InformationCard
              heading={t('employerLandingPage.postMultipleJobsCard.heading')}
              text={t('employerLandingPage.postMultipleJobsCard.description')}
            />

            <InformationCard
              heading={t('employerLandingPage.reachMillionsCard.heading')}
              text={t('employerLandingPage.reachMillionsCard.description')}
            />

            {isRegistrationEnabled ? <BottomRegistrationCard /> : null}
          </Stack>
        </Else>
      </If>
    </Stack>
  );
};
