import React from 'react';
import { PropsWithChildren } from 'react';
import { Stack } from '@components/Stack';

export const AdCardContainer = ({ children }: PropsWithChildren) => {
  return (
    <Stack
      direction={{
        mobile: 'column',
        tablet: 'row',
        desktop: 'row'
      }}
      spacing="medium"
      paddingTop="xsmall"
    >
      {children}
    </Stack>
  );
};
