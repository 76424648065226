import React from 'react';
import { Trans } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { useCandidateLink } from '@common/hooks/useCandidateLink';
import { LINK } from '@common/hooks/useCandidateLink';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';
import { Box } from '@components/Box';
import { ExternalLink } from '@components/ExternalLink';
import { Checkbox, Field } from '@components/FormElements';
import { Stack } from '@components/Stack';

type CookieConsentProps = {
  value: boolean;
  onChange: () => void;
  error?: string;
};

export const CookieConsent = (props: CookieConsentProps) => {
  const { value, onChange, error } = props;
  const { getCandidateLink } = useCandidateLink();
  const { brandNameText } = useAppSelector(getLocalisation);

  return (
    <Stack spacing="small">
      <Field
        label=""
        message={
          error && (
            <Box marginTop="xxsmall">
              <ErrorMessage>{error}</ErrorMessage>
            </Box>
          )
        }
      >
        <Box marginTop="xsmall">
          <Checkbox
            testID="cookie-consent-checkbox"
            label={
              <Trans
                i18nKey="common.message.termsAndPrivacy.cookiesConsent"
                values={{
                  brandNameText
                }}
                components={[
                  <ExternalLink
                    size="small"
                    testID="cookie-policy-link"
                    href={getCandidateLink(LINK.PRIVACY_PAGE)!}
                  >
                    0
                  </ExternalLink>
                ]}
              />
            }
            value={value}
            onChange={onChange}
          />
        </Box>
      </Field>
    </Stack>
  );
};
