import i18n from 'i18next';
import 'intl-pluralrules';
import resources from '../index';
import { isNative } from '../../../common/helpers/detection';
import {initReactI18next} from 'react-i18next';

i18n.use(initReactI18next)
  .init({
    ...(isNative() && { compatibilityJSON: 'v4' }),
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
    ns: 'translation',
    debug: false
  });

export default i18n;