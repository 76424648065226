import { useTheme } from '@theme/DesignSystemProvider/ThemeContext';
import { BaseTheme } from '@theme/DesignSystemProvider/ThemeContext/makeBaseTheme';
import { useResolveToken } from '@theme/tokens/utils/useResolveToken';

type Direction = 'horizontal' | 'vertical';

export type DividerStyleProps = {
  /** The direction of the divider
   *  @default horizontal
   */
  direction?: Direction;
  /** The tone of the divider
   *  @default neutral
   */
  tone?: keyof BaseTheme['divider']['color'];
  /** The width of the divider
   *  @default xsmall
   */
  width?: keyof BaseTheme['divider']['width'];
};

const directionalSizes = {
  horizontal: 'height',
  vertical: 'width'
};

export function useDividerStyle({
  direction = 'horizontal',
  tone = 'neutral',
  width = 'xsmall'
}: DividerStyleProps) {
  const { divider } = useTheme();
  const { resolveToken } = useResolveToken();

  const lineDirection = resolveToken(null, direction) || 'horizontal';

  const directionalSize = {
    [directionalSizes[lineDirection]]: divider.width[width]
  };

  const flexDirection = (
    {
      horizontal: 'row',
      vertical: 'column'
    } as const
  )[lineDirection];

  return {
    ...directionalSize,
    alignSelf: 'stretch' as const,
    backgroundColor: divider.color[tone],
    flexDirection
  };
}
