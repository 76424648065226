import { useBreakpoint } from '@theme/DesignSystemProvider/BreakpointContext';
import { useTheme } from '@theme/DesignSystemProvider/ThemeContext';
import { useResolveToken } from '@theme/tokens/utils/useResolveToken';

export const useMilestoneStepStyle = () => {
  const {
    icon: { sizes: iconSizes },
    sizes,
    colors,
    border,
    spacing,
    typography: { fontSizes }
  } = useTheme();
  const breakpoint = useBreakpoint();
  const { resolveToken } = useResolveToken();

  const dot = {
    width: resolveToken(spacing, 'xxsmall'),
    height: resolveToken(spacing, 'xxsmall'),
    borderRadius: resolveToken(border.radius, 'xlarge'),
    backgroundColor: resolveToken(colors.foreground, 'brand')
  };

  const defaultCircleContainer = {
    width: resolveToken(iconSizes, 'xxlarge'),
    height: resolveToken(iconSizes, 'xxlarge'),
    borderWidth: resolveToken(border.width, 'small'),
    borderRadius: resolveToken(border.radius, 'full'),
    borderColor: resolveToken(colors.foreground, 'neutralLight')
  };

  const activeCircleContainer = {
    ...defaultCircleContainer,
    borderColor: resolveToken(colors.foreground, 'brand')
  };

  const lineContainer = {
    top: resolveToken(iconSizes, 'small'),
    width:
      breakpoint === 'mobile'
        ? iconSizes.xxlarge * 2
        : resolveToken(sizes, 'small')
  };

  const inProgressLineContainer = {
    ...lineContainer,
    width: breakpoint === 'mobile' ? sizes.xxxsmall : spacing.xlarge * 4
  };

  const dottedLineContainer = {
    ...lineContainer,
    width: breakpoint === 'mobile' ? spacing.large : spacing.xlarge * 1.6
  };

  const stepContainer = {
    width:
      breakpoint === 'mobile'
        ? resolveToken(sizes, 'xxxsmall')
        : iconSizes.xxlarge * 2
  };

  const textContainer = {
    width: sizes.xsmall + spacing.xxsmall,
    height: resolveToken(fontSizes, 'large')
  };

  const defaultLine = {
    height: resolveToken(spacing, 'xxsmall'),
    borderRadius: resolveToken(border.radius, 'small'),
    backgroundColor: resolveToken(colors.foreground, 'neutralLight')
  };

  const activeLine = {
    ...defaultLine,
    backgroundColor: resolveToken(colors.foreground, 'brand')
  };

  return {
    dot,
    lineContainer,
    stepContainer,
    textContainer,
    dottedLineContainer,
    activeCircleContainer,
    defaultCircleContainer,
    inProgressLineContainer,
    line: { defaultLine, activeLine }
  };
};
