import React from 'react';
import { CalloutText } from '@components/CalloutText';
import { Card, CardContent } from '@components/Card';
import { Text } from '@components/Text';
import { useApplicationsExpiryCalloutDetails } from './useApplicationsExpiryCalloutDetails';

type Props = {
  jobId: string;
};

export const ApplicationsExpiryCalloutDetails = ({ jobId }: Props) => {
  const { shouldRenderWarning, expiryCalloutText } =
    useApplicationsExpiryCalloutDetails(jobId);

  if (!shouldRenderWarning) {
    return null;
  }

  return (
    <Card marginBottom="medium">
      <CardContent>
        <CalloutText tone="neutral">
          <Text size="small" height="medium">
            {expiryCalloutText}
          </Text>
        </CalloutText>
      </CardContent>
    </Card>
  );
};
