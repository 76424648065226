import { Children, PureComponent } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './CardActions.scss';

/**
 * @deprecated Use the @components/CardFooter instead.
 **/
export default class CardActions extends PureComponent {
  static propTypes = {
    children: PropTypes.any.isRequired,
    onCardWithBorder: PropTypes.bool
  };

  render() {
    const { children, onCardWithBorder } = this.props;

    return (
      <div className={classNames(styles.container, { [styles.onCardWithBorder]: onCardWithBorder })}>
        {
          Children.map(children, (element) =>
            element &&
              <div className={styles.item}>
                {element}
              </div>
          )
        }
      </div>
    );
  }
}