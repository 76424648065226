import React from 'react';
import { useTranslation } from 'react-i18next';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { Else, If, Then } from '@common/components/If';
import { useGetPostJobLink } from '@common/hooks/appPageLinks/useGetPostJobLink';
import { useRedirect } from '@common/hooks/useRedirect';
import { useRedirectUnauthenticatedUser } from '@common/hooks/useRedirectUnauthenticatedUser';
import { SupportLevel } from '@seek/je-shared-data/lib/types/brand';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';
import { Box } from '@components/Box';
import { Button } from '@components/Button';
import { Card, CardContent } from '@components/Card';
import { Heading } from '@components/Heading';
import { SuccessIcon } from '@components/Icon';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';

export const DeleteJobSuccess = () => {
  useRedirectUnauthenticatedUser();

  const { countryCode, supportTier } = useAppSelector(getLocalisation);
  const { postJobLink } = useGetPostJobLink();
  const { t } = useTranslation();
  const { redirect } = useRedirect();

  return (
    <Stack tone="transparent">
      <JoraEmployerHelmet title={t('deleteJobSuccessPage.title')} />

      <Stack spacing="medium" tone="transparent">
        <Card>
          <CardContent>
            <Stack alignItems="center" spacing="medium">
              <SuccessIcon size="xxxlarge" tone="success" variant="outline" />
              <Heading level="h1" weight="regular">
                {t('deleteJobSuccessPage.heading')}
              </Heading>
            </Stack>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Heading level="h2">
              {t('deleteJobSuccessPage.description.whatHappensNow')}
            </Heading>
            <Box marginTop="medium">
              <Text>
                {t('deleteJobSuccessPage.description.yourJobWillBeRemoved')}
              </Text>
            </Box>
            <If condition={supportTier === SupportLevel.TIER_3}>
              <Then>
                <Stack
                  spacing="medium"
                  direction={{
                    mobile: 'column',
                    tablet: 'row',
                    desktop: 'row'
                  }}
                  marginTop="medium"
                >
                  <Button
                    data-testid="back-to-home"
                    onPress={redirect(`/${countryCode}/`)}
                    variant="secondary"
                  >
                    {t('common.action.backToHome')}
                  </Button>
                </Stack>
              </Then>
              <Else>
                <Stack
                  spacing="medium"
                  direction={{
                    mobile: 'column',
                    tablet: 'row',
                    desktop: 'row'
                  }}
                  marginTop="medium"
                >
                  <Button
                    data-testid="post-job"
                    onPress={redirect(postJobLink)}
                  >
                    {t('common.action.postNewJob')}
                  </Button>
                  <Button
                    data-testid="back-to-home"
                    onPress={redirect(`/${countryCode}/`)}
                    variant="secondary"
                  >
                    {t('common.action.backToHome')}
                  </Button>
                </Stack>
              </Else>
            </If>
          </CardContent>
        </Card>
      </Stack>
    </Stack>
  );
};
