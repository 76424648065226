import { trpcBff } from '@trpcBff';

type Response = ReturnType<typeof trpcBff.coins.pricing.get.useQuery>;

export type ErrorResponse = Response['error'];

export type QueryOptions = {
  enabled?: boolean;
};

export const useFetchMyBalance = (queryOptions?: QueryOptions) => {
  const { data, isError, error, isInitialLoading } =
    trpcBff.coins.balance.get.useQuery(undefined, {
      ...queryOptions,
      staleTime: 5000,
      refetchOnWindowFocus: false,
      retry: false
    });

  return {
    data,
    isError,
    error,
    isLoading: isInitialLoading
  };
};
