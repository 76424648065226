import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { TabsContext } from './TabsContext';

type Props = PropsWithChildren<{
  initialSelectedTab?: number;
}>;

export const TabsProvider = (props: Props) => {
  const { children, initialSelectedTab = 0 } = props;
  const [selectedTab, setSelectedTab] = useState(initialSelectedTab);

  useEffect(() => {
    setSelectedTab(initialSelectedTab);
  }, [initialSelectedTab]);

  const contextValue = useMemo(
    () => ({
      selectedTab,
      setSelectedTab
    }),
    [selectedTab]
  );

  return (
    <TabsContext.Provider value={contextValue}>{children}</TabsContext.Provider>
  );
};
