import React from 'react';
import { ClipPath, Defs, G, Path } from 'svgs';
import { createIllustration } from '../createIllustration';

const vectorComponent = () => (
  <>
    <Path
      fill="#DDF8EB"
      fillRule="evenodd"
      d="M22.789 89.868c-6.053-14.79 11.528-26.125 18.296-39.936 5.875-11.99 5.728-28.437 17.746-33.612 13.651-5.88 31.281-3.205 42.584 7.629 10.558 10.12 9.208 26.546 8.148 40.992-.86 11.724-5.07 22.319-13.359 30.019-8.761 8.133-19.754 12.759-31.81 11.924-15.444-1.07-35.48-2.046-41.606-17.014z"
      clipRule="evenodd"
    />
    <G clipPath="url(#a)" filter="url(#b)">
      <Path
        fill="#1B1C1C"
        d="M77.31 47.325c4.577-.9 10.292-4.475 9.075-9.983-.608-2.78-3.254-4.58-5.9-5.137-2.064-.423-4.974-.053-6.535-1.747-1.323-1.43-1.985-3.23-3.493-4.555-1.402-1.218-3.095-2.092-4.92-2.436-3.07-.556-6.457.583-8.335 3.072-1.879 2.489-2.117 5.905-1.509 8.95 1.323 6.7 7.409 10.089 13.494 11.624 2.408.635 5.345.741 8.123.212zM76.914 67.477l3.201 23.752c.053.344.186.662.37.953l16.485 23.116a2.17 2.17 0 0 1-.37 2.886l-1.906 1.59c-.9.741-2.222.635-2.99-.239L71.12 95.492a1.98 1.98 0 0 1-.424-.768l-8.043-26.347a2.128 2.128 0 0 1 2.037-2.754h10.107a2.12 2.12 0 0 1 2.117 1.854z"
      />
      <Path
        fill="#fff"
        d="M65.404 40.282s4.075-.08 5.053-3.336h.212a1.954 1.954 0 0 0 1.958-1.96c0-1.085-.873-1.96-1.958-1.96-.106 0-.238 0-.344.027a13.784 13.784 0 0 0-.873-2.356s-4.921 1.43-10.61 1.403c0 .026-1.164 8.34 6.562 8.182zM64.531 43.751c-4.194 0-7.594-.665-7.594-1.483s3.4-1.483 7.594-1.483c4.194 0 7.593.665 7.593 1.483s-3.4 1.483-7.593 1.483z"
      />
      <Path fill="#fff" d="M63.261 39.382h2.434v3.23h-2.434v-3.23z" />
      <Path
        fill="#1B1C1C"
        d="m65.033 66.656-.582 7.335c-.026.212-.08.424-.159.635l-9.366 24.202c-.026.106-.08.212-.106.318l-5.45 23.514c-.265 1.112-1.27 1.856-2.408 1.75l-1.27-.106a2.26 2.26 0 0 1-2.064-2.571l7.514-56.322c.16-1.192 1.244-2.065 2.435-1.96l9.366.768a2.228 2.228 0 0 1 2.09 2.437z"
      />
      <Path
        fill="#fff"
        d="M82.337 78.943c-.159-.82-.503-1.139-.503-1.139.291-.662-.026-1.456-.688-1.72l-.476-.186c-.635-.238-1.35.053-1.614.662l-.026.026s-1.244 1.483-1.932 3.734c-.873 2.886.08 4.634 1.006 4.846.873.185 2.063-3.178 2.513-4.396l.265 1.404c.026.185.185.317.37.344s.37-.106.45-.292l.503-1.482a3.774 3.774 0 0 0 .132-1.801z"
      />
      <Path
        fill="#14A34A"
        d="M75.485 41.845c-1.19-.477-2.567-.635-3.81-.768-1.138-.132-2.487-.397-3.519.212-1.164.688-2.487 1.138-3.863 1.086a5.091 5.091 0 0 1-2.54-.768c-.635-.397-1.111-1.006-1.72-1.43-.846-.556-1.905-.662-2.91-.689-1.111-.053-11.216 2.78-15.684 5.802 0 0-7.872 4.986-2.924 7.978 4.763 2.886 13.295.763 13.272.975l-1.569 11.595c-.026.264.159.503.424.503l26.644 1.191c.29 0 .529-.264.45-.53L74.85 54.347c-.105-.45.477-.742.768-.371 1.693 2.039 4.63 5.905 4.789 8.182.185 2.966-2.17 12.578-2.514 13.902a.443.443 0 0 0 .027.318c.317.582 1.64 2.568 5 1.536.106-.027.185-.106.265-.212.873-1.562 8.149-14.828 4.286-22.958-1.826-3.813-4.55-7.176-7.752-9.93-1.006-.847-2.038-1.668-3.149-2.383a4.721 4.721 0 0 0-1.085-.583v-.002z"
      />
      <Path
        fill="#fff"
        d="M76.754 72.825c.08 0 .132-.053.132-.132s-.053-.133-.132-.133a4.44 4.44 0 0 1-4.445-4.448c0-.08-.053-.133-.132-.133s-.133.053-.133.133a4.722 4.722 0 0 0 4.71 4.713zM51.381 72.825a4.722 4.722 0 0 0 4.71-4.713c0-.08-.053-.133-.133-.133s-.132.053-.132.133a4.44 4.44 0 0 1-4.445 4.448c-.08 0-.132.053-.132.133s.053.132.132.132z"
      />
      <Path
        fill="#1B1C1C"
        d="M76.755 62.312h.026c.08-.026.106-.08.106-.159l-3.519-14.908c-.026-.08-.08-.106-.158-.106-.08.027-.106.08-.106.159l3.519 14.908c.026.08.08.106.132.106zM50.259 65.364c.053 0 .132-.053.132-.106L51.9 54.375a.179.179 0 0 0-.106-.159.179.179 0 0 0-.16.106l-1.507 10.883c0 .08.053.159.132.159zM64.531 44.148a6.869 6.869 0 0 0 4.868-2.013.128.128 0 0 0 0-.185.128.128 0 0 0-.185 0c-2.566 2.569-6.773 2.569-9.34 0a.128.128 0 0 0-.185 0 .128.128 0 0 0 0 .185 6.809 6.809 0 0 0 4.842 2.013zM82.232 77.142a.145.145 0 0 0 .132-.08l.476-1.165c.027-.079 0-.132-.079-.158-.08-.027-.132 0-.159.08l-.476 1.164c-.026.08 0 .133.08.159h.026zM79.242 75.95a.145.145 0 0 0 .132-.08l.477-1.165c.026-.079 0-.132-.08-.158-.08-.027-.132 0-.159.079l-.476 1.165c-.026.08 0 .133.08.159-.027-.026 0 0 .026 0zM80.221 76.347a.145.145 0 0 0 .132-.08l.477-1.165c.026-.079 0-.132-.08-.158s-.132 0-.159.08l-.476 1.164c-.026.08 0 .132.08.159 0-.026.026 0 .026 0zM81.226 76.743a.146.146 0 0 0 .132-.08l.476-1.165c.027-.079 0-.132-.079-.158s-.132 0-.159.079l-.476 1.165c-.026.08 0 .132.08.159h.026z"
      />
      <Path
        fill="#C0E2CF"
        d="m58.156 53.932 2.95-1.138c.633-.244.948-.955.704-1.588l-3.338-8.668a1.227 1.227 0 0 0-1.587-.704l-2.95 1.138a1.229 1.229 0 0 0-.704 1.588l3.338 8.667c.244.634.954.95 1.587.705z"
      />
      <Path
        fill="#91CFAB"
        d="m57.865 53.808 2.95-1.138c.633-.244.948-.955.704-1.588l-3.338-8.668a1.227 1.227 0 0 0-1.587-.704l-2.95 1.138a1.229 1.229 0 0 0-.704 1.588l3.338 8.667c.244.634.954.95 1.587.705z"
      />
      <Path
        fill="#fff"
        d="M53.67 47.14c-.476.638-.286 2.786-.286 2.786-.67.13-1.01.895-.756 1.644l.187.545c.26.713.942 1.166 1.59 1.05l.022-.014s1.92.22 3.966-.543c2.67-.97 2.625-3.146 2.13-4.099-1.747-2.343-4.2.678-5.393.99 0 0-1.108-2.82-1.463-2.359h.003z"
      />
      <Path
        fill="#1B1C1C"
        d="M49.876 52.942c0 .08.074.135.127.138l1.273-.093c.079 0 .135-.074.11-.13 0-.08-.073-.135-.129-.11l-1.273.092c-.052-.03-.105.024-.108.103zM49.661 49.704c0 .08.074.135.127.138l1.273-.093c.08 0 .135-.074.11-.13 0-.08-.073-.135-.129-.111l-1.273.093c-.052-.03-.105.024-.108.103zM49.732 50.766c0 .08.074.135.127.138l1.273-.093c.079 0 .135-.074.11-.13 0-.08-.073-.135-.129-.111l-1.272.093c-.053 0-.109.05-.109.103zM49.804 51.853c0 .08.074.135.127.138l1.273-.093c.079 0 .135-.074.11-.13 0-.08-.073-.135-.129-.111l-1.273.093c-.052-.03-.105.024-.108.103z"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M36.882 23.331h60.476v101.09H36.882z" />
      </ClipPath>
    </Defs>
  </>
);

export const NoCoinsTransactionsIllustration = createIllustration({
  viewBox: '0 0 132 127',
  vectorComponent
});
