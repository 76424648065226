import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextWithIcon } from '@common/components/TextWithIcon';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';
import { Box } from '@components/Box';
import { Card, CardContent } from '@components/Card';
import { Heading } from '@components/Heading';
import {
  LightbulbIcon,
  SearchDocumentIcon,
  SuccessIcon
} from '@components/Icon';
import { Text } from '@components/Text';

export const PurposeTile = () => {
  const { t } = useTranslation();
  const { brandNameText } = useAppSelector(getLocalisation);

  return (
    <Card>
      <CardContent>
        <Heading level="h2" weight="medium">
          {t('employerLandingPage.purposeTile.heading', { brandNameText })}
        </Heading>

        <Box marginTop="medium" flexShrink={1}>
          <TextWithIcon
            icon={<SuccessIcon variant="outline" size="medium" tone="brand" />}
            alignItems="flex-start"
            marginTop="xxsmall"
          >
            <Heading level="h2">
              {t('employerLandingPage.purposeTile.easyToStart.heading')}
            </Heading>
            <Box marginTop="xxsmall">
              <Text>
                {t('employerLandingPage.purposeTile.easyToStart.description', {
                  brandNameText
                })}
              </Text>
            </Box>
          </TextWithIcon>
        </Box>

        <Box marginTop="medium" flexShrink={1}>
          <TextWithIcon
            icon={
              <LightbulbIcon variant="outline" size="medium" tone="brand" />
            }
            alignItems="flex-start"
            marginTop="xxsmall"
          >
            <Heading level="h2">
              {t('employerLandingPage.purposeTile.greatCandidates.heading')}
            </Heading>
            <Box marginTop="xxsmall">
              <Text>
                {t(
                  'employerLandingPage.purposeTile.greatCandidates.description',
                  { brandNameText }
                )}
              </Text>
            </Box>
          </TextWithIcon>
        </Box>

        <Box marginTop="medium" flexShrink={1}>
          <TextWithIcon
            icon={
              <SearchDocumentIcon
                variant="outline"
                size="medium"
                tone="brand"
              />
            }
            alignItems="flex-start"
            marginTop="xxsmall"
          >
            <Heading level="h2">
              {t('employerLandingPage.purposeTile.numberOfCandidates.heading')}
            </Heading>
            <Box marginTop="xxsmall">
              <Text>
                {t(
                  'employerLandingPage.purposeTile.numberOfCandidates.description',
                  { brandNameText }
                )}
              </Text>
            </Box>
          </TextWithIcon>
        </Box>
      </CardContent>
    </Card>
  );
};
