import { useSearchQueryParam } from '@common/hooks/useSearchQueryParam';

export const useSourceParams = () => {
  const { getQueryParam } = useSearchQueryParam();

  const utmCampaign = getQueryParam('utm_campaign');
  const utmMedium = getQueryParam('utm_medium');
  const utmSource = getQueryParam('utm_source');
  const utmContent = getQueryParam('utm_content');

  const hasSourceParams = utmCampaign || utmMedium || utmSource || utmContent;

  return hasSourceParams
    ? `utm_campaign=${utmCampaign}&utm_medium=${utmMedium}&utm_source=${utmSource}&utm_content=${utmContent}`
    : '';
};
