import { TrpcRouter } from 'je-bff/src/handlers/bff';
import type { inferRouterInputs } from '@trpc/server';
import { trpcBff } from '@trpcBff';

export type QueryOptions = {
  enabled?: boolean;
};

type Params = inferRouterInputs<TrpcRouter>['coins']['pricing']['get'];
type Response = ReturnType<typeof trpcBff.coins.pricing.get.useQuery>;

export type ErrorResponse = Response['error'];

export const useFetchCoinPricing = (
  params: Params,
  queryOptions?: QueryOptions
) => {
  const { data, isError, error, isInitialLoading } =
    trpcBff.coins.pricing.get.useQuery(params, {
      ...queryOptions,
      staleTime: 5000,
      refetchOnWindowFocus: false,
      retry: false
    });

  return {
    data,
    isError,
    error,
    isLoading: isInitialLoading
  };
};
