import React from 'react';
import { ImmediateStart as InnerField } from '@common/components/PostJobForm/components/ImmediateStart';
import { FieldWrapper } from '../FieldWrapper';

export default function ImmediateStart() {
  return (
    <FieldWrapper<boolean>
      name="immediateStart"
      render={(props) => <InnerField {...props} />}
    />
  );
}
