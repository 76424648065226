import { TrpcRouter } from 'je-bff/src/handlers/bff';
import type { inferRouterInputs, inferRouterOutputs } from '@trpc/server';
import { trpcBff } from '@trpcBff';

export type QueryOptions = {
  enabled?: boolean;
};
export type Params =
  inferRouterInputs<TrpcRouter>['coins']['transactions']['get'];

type Response = ReturnType<typeof trpcBff.coins.pricing.get.useQuery>;

export type SuccessResponse = NonNullable<
  inferRouterOutputs<TrpcRouter>['coins']['transactions']['get']
>;
export type ErrorResponse = Response['error'];

export const useFetchTransactions = (
  params: Params,
  queryOptions?: QueryOptions
) => {
  const { data, isError, error, isInitialLoading } =
    trpcBff.coins.transactions.get.useQuery(params, {
      ...queryOptions,
      staleTime: 5000,
      refetchOnWindowFocus: false,
      retry: false
    });

  return {
    data,
    isError,
    error,
    isLoading: isInitialLoading
  };
};
