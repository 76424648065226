import React from 'react';
import { View } from 'react-native';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { DIALLING_CODE_OPTIONS } from '@common/constants/data';
import { Box } from '@components/Box';
import { Dropdown, Field, Input } from '@components/FormElements';
import { Row } from '@components/Row';
import { Text } from '@components/Text';
import { useTheme } from '@theme/DesignSystemProvider/ThemeContext';
import { T } from '../../types';

export type ValueProps = {
  diallingCode: string;
  phoneNumber: string;
};

type MobileNumberProps = {
  value: ValueProps;
  onChange: (val: ValueProps) => void;
  onBlur: () => void;
  error?: string;
  t: T;
  showExplanationText?: boolean;
};

export const MobileNumber = ({
  error,
  onChange,
  onBlur,
  value,
  showExplanationText = false,
  t
}: MobileNumberProps) => {
  const {
    formElement: {
      container: { maxWidth }
    }
  } = useTheme();

  return (
    <Box zIndex={10}>
      <Field
        label={t('common.fieldLabel.phone')}
        message={
          error && (
            <Box zIndex={-5} marginTop="xxsmall">
              <ErrorMessage>{error}</ErrorMessage>
            </Box>
          )
        }
      >
        <View style={{ maxWidth }}>
          <Row spacing="xsmall" marginBottom="xsmall" flexGrow={1} zIndex={1}>
            <Dropdown
              width="1/3"
              items={DIALLING_CODE_OPTIONS}
              testID="account-details-form-diallingCode"
              onChange={(val) =>
                onChange({ ...value, diallingCode: val.toString() })
              }
              selectedItem={value.diallingCode}
            />
            <Box flexGrow={2}>
              <Input
                tone={error ? 'danger' : 'neutral'}
                id="mobileNumber"
                onChange={(val) => onChange({ ...value, phoneNumber: val })}
                onBlur={onBlur}
                value={value.phoneNumber}
                keyboardType="phone-pad"
              />
            </Box>
          </Row>
          {showExplanationText && (
            <Text size="small" tone="neutralLight">
              {t('registerForm.explanationText.mobile')}
            </Text>
          )}
        </View>
      </Field>
    </Box>
  );
};
