import { useAppDispatch } from '@store/hooks';
import { trackSocialShareActionClicked } from '../actions/trackSocialShareActionClicked';

export const useOnSharePressed = () => {
  const dispatch = useAppDispatch();

  return ({ id }: { id: string }) => {
    dispatch(trackSocialShareActionClicked({ id }));
  };
};
