import REQUEST_KEY from '@common/constants/requestKeys';
import { ClientError } from '@common/helpers/errors';
import { useHandleAppError } from '@common/hooks/appError';
import { useHandleAppLoading } from '@common/hooks/appLoading';
import { trpcBff } from '@trpcBff';

export const useShareJobMutation = () => {
  const shareJobMutation = trpcBff.jobs.actions.share.useMutation();

  useHandleAppLoading(shareJobMutation.isLoading, REQUEST_KEY.JOB.ACTION.SHARE);

  useHandleAppError(
    shareJobMutation.isError,
    REQUEST_KEY.JOB.ACTION.SHARE,
    getClientError(shareJobMutation.error)
  );

  return shareJobMutation.mutate;
};

const getClientError = (error) => {
  if (!error) return;

  const guid = error?.data?.httpError.guid;
  const responseBody = error?.data;

  return new ClientError('errors.defaultWithGuid', {
    guid,
    responseBody
  });
};
