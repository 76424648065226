import { Dispatch } from 'react';
import * as draftFormStore from '@common/helpers/draftFromStore';
import { CLEAR_DRAFT_FORM, SAVE_DRAFT_FORM } from './constants';
import { FormAction, FormType } from './types';

export function saveDraftForm<t>(
  key: FormType,
  data: t
): (dispatch: Dispatch<FormAction<t>>) => void {
  return (dispatch) => {
    draftFormStore.save({
      [key]: data
    });

    dispatch({
      type: SAVE_DRAFT_FORM,
      payload: { key, data }
    });
  };
}

export function clearDraftForm<t>(
  key: FormType
): (dispatch: Dispatch<FormAction<t>>) => void {
  return (dispatch) => {
    draftFormStore.clear(key);
    dispatch({
      type: CLEAR_DRAFT_FORM,
      payload: { key }
    });
  };
}

export function loadFromLocalStorage<t>(): (
  dispatch: Dispatch<FormAction<t>>
) => void {
  return async (dispatch) => {
    const draftFormValues = await draftFormStore.readAll();

    if (draftFormValues) {
      Object.keys(draftFormValues).forEach((propKey: string) => {
        const key = propKey as FormType;
        dispatch({
          type: SAVE_DRAFT_FORM,
          payload: { key, data: draftFormValues[key] }
        });
      });
    }
  };
}
