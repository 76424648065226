import React from 'react';
import { PostJobFormWithAuto } from '@common/components/PostJobFormWithAuto';
import { useLoadJobFromParamsIntoDraft } from '@common/components/PostJobFormWithAuto/hooks/useLoadJobIntoDraft/useLoadJobFromParamsIntoDraft';
import { useSubmitEditJobWithAuto } from '@common/components/PostJobFormWithAuto/hooks/useSubmitEditJob/useSubmitEditJob';
import REQUEST_KEY from '@common/constants/requestKeys';

/**
 * Renders a edit form, fetching the original job from the server
 * and loading it into the draft store.
 * @returns An edit form
 */
export const EditFormWithAutoTitle = () => {
  useLoadJobFromParamsIntoDraft();
  const { submitEditJobWithAuto } = useSubmitEditJobWithAuto();

  return (
    <PostJobFormWithAuto
      mode="EDIT"
      submitPostJob={submitEditJobWithAuto}
      requestKey={REQUEST_KEY.JOB.EDIT}
    />
  );
};
