import { BusinessWithUsers } from '@store/entities/businesses/types';
import { PostJobFormBusiness } from '../types';

export const mapBusinessToFormValues = (
  business: BusinessWithUsers
): PostJobFormBusiness => ({
  id: business.id,
  name: business.name,
  recruitmentCompanyName: business.recruitmentCompanyName,
  geolocation: business.locations?.[0],
  businessSize: business.businessSize
});
