import React from 'react';
import { useTranslation } from 'react-i18next';
import { Application } from '@common/types/Application';
import { Box } from '@components/Box';
import { CardAction } from '@components/Card';
import { SearchIcon } from '@components/Icon';
import { Link } from '@components/Link';
import { useRefreshCardAction } from './useRefreshCardAction';

type RefreshActionProps = {
  application: Application;
  responsive: boolean;
};

export const RefreshCardAction = ({
  application: { id },
  responsive
}: RefreshActionProps) => {
  const { t } = useTranslation();
  const { refresh } = useRefreshCardAction();

  return (
    <CardAction>
      <Box
        paddingHorizontal={{
          mobile: 'xlarge',
          tablet: 'none',
          desktop: 'none'
        }}
      >
        <Link
          onPress={refresh}
          align={{
            mobile: responsive ? 'vertical' : 'horizontal',
            tablet: 'horizontal',
            desktop: 'horizontal'
          }}
          variant="secondary"
          icon={<SearchIcon variant="outline" />}
          testID={`${id}-resume-virus-scan-in-progress`}
        >
          {t('applications.applicationCard.resumeVirusScanningInProgress')}
        </Link>
      </Box>
    </CardAction>
  );
};
