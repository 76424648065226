import { useTranslation } from 'react-i18next';
import {
  MAX_LENGTH,
  NPS_REASON_MAX_LENGTH
} from '@common/constants/validation';

type ReasonErrors = {
  maxLength: string;
};

export const useReasonValidation = () => {
  const { t } = useTranslation();

  const errors = t('validations.nps.reason', {
    returnObjects: true,
    max: NPS_REASON_MAX_LENGTH
  }) as ReasonErrors;

  return {
    [MAX_LENGTH]: {
      value: NPS_REASON_MAX_LENGTH,
      message: errors.maxLength
    }
  };
};
