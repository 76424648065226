import { LOGOUT_SUCCESS } from '../auth/constants';
import * as constants from './constants';
import { FormAction } from './types';

const initialState = {
  [constants.JOB_DRAFT_FORM]: {},
  [constants.BUSINESS_DRAFT_FORM]: {}
};

export default function reducer<t>(
  state = initialState,
  action: FormAction<t>
) {
  switch (action.type) {
    case constants.SAVE_DRAFT_FORM: {
      const { key, data } = action.payload;

      return {
        ...state,
        [key]: data
      };
    }

    case constants.CLEAR_DRAFT_FORM: {
      const { key } = action.payload;

      return {
        ...state,
        [key]: {}
      };
    }

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
}
