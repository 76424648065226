import { useParams } from 'react-router-native';
import { getUser } from '@store/auth';
import { updateJob } from '@store/entities/jobs';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { PostJobFormFields } from '../../types';

export const useSubmitEditJobWithAuto = () => {
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector(getUser);
  const { jobId } = useParams<{ jobId: string }>();

  const submitEditJobWithAuto = (data: PostJobFormFields) => {
    dispatch(updateJob({ ...data, jobId, userId: currentUser.id }));
  };

  return { submitEditJobWithAuto };
};
