import React, { forwardRef, useState } from 'react';
import {
  Pressable,
  PressableProps,
  Text as ReactNativeText,
  TextProps,
  View
} from 'react-native';
import {
  LinkState,
  TextLinkStyleProps,
  useTextLinkStyle
} from './useTextLinkStyle';

export type TextLinkProps = {
  children?: string;
  testID?: PressableProps['testID'];
  onPress?: TextProps['onPress'];
} & Omit<TextLinkStyleProps, 'state'>;

/**
 * TextLink component is used with i18Next Trans to prevent the alignment issue on mobile
 */
export const TextLink = forwardRef<View, TextLinkProps>((props, ref) => {
  const { children, testID, onPress, ...restProps } = props;

  const [TextLinkState, setTextLinkState] = useState<LinkState>('default');
  const textLinkStyleProps = { state: TextLinkState, ...restProps };
  const textStyle = useTextLinkStyle(textLinkStyleProps);

  return (
    <Pressable
      onPress={onPress}
      testID={testID}
      ref={ref}
      onHoverIn={() => setTextLinkState('hover')}
      onHoverOut={() => setTextLinkState('default')}
      onPressIn={() => setTextLinkState('hover')}
      onPressOut={() => setTextLinkState('default')}
    >
      <ReactNativeText style={textStyle} ref={ref}>
        {children}
      </ReactNativeText>
    </Pressable>
  );
});
