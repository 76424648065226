import React, { PropsWithChildren } from 'react';
import { BusinessSize } from '@common/types';
import { Box } from '@components/Box';
import { Row } from '@components/Row';
import { BusinessImage } from '../BusinessImage';

export type BusinessImageAndDetailsProps = {
  /**
   * The business size that will determine the icon to be rendered
   */
  businessSize: BusinessSize;
};

export const BusinessImageAndDetails = ({
  children,
  businessSize
}: PropsWithChildren<BusinessImageAndDetailsProps>) => (
  <Row spacing="medium" alignItems="center">
    <BusinessImage businessSize={businessSize} />
    <Box flexShrink={1}>{children}</Box>
  </Row>
);
