import React, { useState } from 'react';
import { TextInputProps } from 'react-native';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { Box } from '@components/Box';
import { Field, Input } from '@components/FormElements';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { PasswordRevealButton } from './PasswordRevealButton';

type PasswordInputProps = {
  value: string;
  onChange: () => void;
  onBlur: () => void;
  labelKey?: string;
  error?: string;
  onEnter?: TextInputProps['onSubmitEditing'];
  showExplanationText?: boolean;
};

export const Password = (props: PasswordInputProps) => {
  const { t } = useTranslation();
  const {
    error,
    labelKey,
    onChange,
    onBlur,
    value,
    onEnter,
    showExplanationText
  } = props;

  const [isHidePassword, setHidePassword] = useState(true);
  const togglePasswordReveal = () => {
    setHidePassword(!isHidePassword);
  };

  return (
    <Stack spacing="small">
      <Field
        label={t(labelKey ?? 'common.fieldLabel.password')}
        message={
          error && (
            <Box marginTop="xxsmall">
              <ErrorMessage>{error}</ErrorMessage>
            </Box>
          )
        }
      >
        <Input
          tone={error ? 'danger' : 'neutral'}
          id="login-form-password"
          testID="login-form-password"
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          secureTextEntry={isHidePassword}
          onSubmitEditing={onEnter}
          icon={
            <PasswordRevealButton
              isHidePassword={isHidePassword}
              onPress={togglePasswordReveal}
            />
          }
        />
      </Field>
      {showExplanationText && (
        <Text size="small" tone="neutralLight">
          {t('validations.general.password.format')}
        </Text>
      )}
    </Stack>
  );
};
