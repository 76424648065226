import { BrandName } from '@seek/je-shared-data/lib/types/brand';

/**
 * Determines whether to show the benefit for three times more search result
 * views.
 *
 * @param {BrandName} params.brand - The brand name.
 * @returns {boolean} Returns true if the benefit should be shown, false
 * otherwise.
 */
export const showThreeTimesMoreSearchResultViews = ({
  brand
}: {
  brand: BrandName;
}): boolean => {
  if (brand === BrandName.JOBSTREETEXPRESS) return false;

  return true;
};
