import React from 'react';
import { useTranslation } from 'react-i18next';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { useAppAvailable } from '@common/hooks/useAppAvailable';
import { useRedirectAuthenticatedUser } from '@common/hooks/useRedirectAuthenticatedUser';
import { useUpdateSourceQuery } from '@common/hooks/useUpdateSourceQuery';
import { Box } from '@components/Box';
import { Heading } from '@components/Heading';
import { Stack } from '@components/Stack';
import { useDetailedLanding } from '../hooks/useDetailedLanding';
import {
  AboutTile,
  AppInfoTile,
  FunctionalityTile,
  LoginTile,
  MonetisationTile,
  PostJobTile,
  PurposeTile
} from './components';

export const DetailedLanding = () => {
  useRedirectAuthenticatedUser();
  useUpdateSourceQuery();

  const { t } = useTranslation();
  const { isMonetisationEnabled } = useDetailedLanding();
  const { isAppAvailable } = useAppAvailable();

  return (
    <Stack tone="transparent">
      <JoraEmployerHelmet title={t('employerLandingPage.title')} />

      <Stack spacing="medium" tone="transparent" marginBottom="medium">
        <Box paddingHorizontal="medium" marginTop="medium" tone="transparent">
          <Heading level="h1">{t('employerLandingPage.title')}</Heading>
        </Box>
        <LoginTile />
        <AboutTile />
        <PurposeTile />
        <FunctionalityTile />
        {isMonetisationEnabled && <MonetisationTile />}
        <PostJobTile />
        {isAppAvailable && <AppInfoTile />}
      </Stack>
    </Stack>
  );
};
