import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRedirect } from '@common/hooks/useRedirect';
import { Job } from '@common/types';
import { JOB_STATUS } from '@seek/je-shared-data';
import { CardAction, CardFooter } from '@components/Card';
import { HtmlText } from '@components/HtmlText';
import { ExternalLinkIcon, ViewIcon } from '@components/Icon';
import { Link } from '@components/Link';
import { Modal, useModal } from '@components/Modal';
import { Stack } from '@components/Stack';

type CardFooterActionsProps = {
  job: Job;
};

export const CardFooterActions = ({ job }: CardFooterActionsProps) => {
  const { t } = useTranslation();
  const { redirect } = useRedirect();
  const { isModalOpen, openModal, closeModal } = useModal();
  const isJobLiveWithLink = job.liveJobLink && job.status === JOB_STATUS.LIVE;

  return (
    <Stack>
      <Modal
        testID="html-text-view"
        title={t('jobDetailsAndApplicationsListPage.label.jobDescription')}
        visible={isModalOpen}
        onRequestClose={closeModal}
      >
        <HtmlText testID="job-description-html-text">
          {job.description}
        </HtmlText>
      </Modal>

      <CardFooter>
        {isJobLiveWithLink ? (
          <CardAction>
            <Link
              onPress={redirect(job.liveJobLink)}
              align={{
                mobile: 'vertical',
                tablet: 'horizontal',
                desktop: 'horizontal'
              }}
              variant="secondary"
              icon={<ExternalLinkIcon variant="outline" />}
              testID="view-live-job"
            >
              {t('jobDetailsAndApplicationsListPage.action.viewLiveJob')}
            </Link>
          </CardAction>
        ) : null}
        <CardAction>
          <Link
            onPress={openModal}
            align={{
              mobile: isJobLiveWithLink ? 'vertical' : 'horizontal',
              tablet: 'horizontal',
              desktop: 'horizontal'
            }}
            variant="secondary"
            icon={<ViewIcon variant="outline" />}
            testID="view-job-description"
          >
            {t('jobDetailsAndApplicationsListPage.action.viewJobDescription')}
          </Link>
        </CardAction>
      </CardFooter>
    </Stack>
  );
};
