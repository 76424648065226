import React from 'react';
import { t } from 'i18next';
import { useGetRepostJobLink } from '@common/hooks/appPageLinks/useGetRepostJobLink';
import { useRedirect } from '@common/hooks/useRedirect';
import { CardAction, CardActionLink } from '@components/Card';
import { RepostIcon } from '@components/Icon';

type InactiveJobActionProps = {
  jobId: string;
};

export const InactiveJobAction = ({ jobId }: InactiveJobActionProps) => {
  const { redirect } = useRedirect();
  const { repostJobLink } = useGetRepostJobLink(jobId);

  return (
    <CardAction>
      <CardActionLink
        icon={<RepostIcon variant="outline" testID="repost-icon" />}
        text={t('dashboardPage.action.repostAd')}
        onPress={redirect(repostJobLink)}
      />
    </CardAction>
  );
};
