import React from 'react';
import { useTranslation } from 'react-i18next';
import { getExpiryText } from '@src/job/expiry';
import { ExpiredIcon } from '@components/Icon';
import { Row } from '@components/Row';
import { Text } from '@components/Text';

type Props = {
  expiredAt: string;
};

export const LiveExpireInformation = ({ expiredAt }: Props) => {
  const { t } = useTranslation();
  const expiryText = getExpiryText({ t, expiredAt });

  return (
    <Row
      alignItems="center"
      spacing="xxsmall"
      testID="expiry-info-for-live-job"
    >
      <ExpiredIcon
        variant="outline"
        tone="success"
        testID="success-expired-icon"
      />

      <Text size="small">{expiryText}</Text>
    </Row>
  );
};
