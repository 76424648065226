import { Platform } from 'react-native';
import { useAppSelector } from '@store/hooks';
import { getPlatform } from '@store/platform/selectors';

export type DeviceType = 'mobile' | 'web';

export const DEVICE_TYPE: Record<string, DeviceType> = {
  MOBILE: 'mobile',
  WEB: 'web'
};

type DeviceTypeReturn = {
  type: DeviceType;
  isMobileDevice: boolean;
  isNativeApp: boolean;
};

export function useDeviceType(): DeviceTypeReturn {
  const {
    device: { type }
  } = useAppSelector(getPlatform);

  const isMobileDevice =
    Platform.OS === 'ios' ||
    Platform.OS === 'android' ||
    type === DEVICE_TYPE.MOBILE;

  const isNativeApp = ['android', 'ios'].includes(Platform.OS);

  return {
    type: isMobileDevice ? DEVICE_TYPE.MOBILE : DEVICE_TYPE.WEB,
    isMobileDevice,
    isNativeApp
  };
}
