import { useLimitJobRolesEnabled } from '@common/hooks/featureToggles';
import { useSiteCountryCode } from '@common/hooks/localisation';

export function useGetRepostJobLink(jobId: string) {
  const countryCode = useSiteCountryCode();
  const isRoleLimitEnabled = useLimitJobRolesEnabled();

  const repostJobPage = isRoleLimitEnabled ? 'repost-job-role' : 'repost-job';

  return {
    repostJobLink: `/${countryCode}/jobs/${jobId}/${repostJobPage}`
  };
}
