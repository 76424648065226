import React from 'react';
import { useTranslation } from 'react-i18next';
import { localisedFormatDate } from '@common/helpers/dateTime';
import { TransactionsResult } from '@common/hooks/coins';
import { useRedirect } from '@common/hooks/useRedirect';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';
import { Column } from '@components/Column';
import { Link } from '@components/Link';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { useFormatTransactionInfo } from './useFormatTransactionInfo';

type Props = {
  transaction: TransactionsResult['transactions'][0];
};

export const TransactionListItem = ({ transaction }: Props) => {
  const { t } = useTranslation();
  const { redirect } = useRedirect();
  const { countryCode } = useAppSelector(getLocalisation);
  const { isDebit, jobInfo, amount } = useFormatTransactionInfo(transaction);

  return (
    <Stack key={`${transaction.id}-card`} spacing="xxsmall">
      <Text size="xxsmall" tone="neutralLight">
        {localisedFormatDate(t, 'dd MMM yyyy', transaction.createdAt)}
      </Text>

      <Row alignItems="flex-start" justifyContent="space-between">
        <Column spacing="xxsmall">
          <Text weight="regular" size="small">
            {t(`myCoinsPage.transactions.transactionsCode.${transaction.code}`)}
          </Text>

          {transaction.jobTitle ? (
            <Link
              size="xxsmall"
              onPress={redirect(
                `/${countryCode}/jobs/${transaction.jobId}/details`
              )}
            >
              {jobInfo}
            </Link>
          ) : null}
        </Column>

        <Column alignItems="flex-end" spacing="xxsmall">
          <Text
            weight="regular"
            tone={isDebit ? 'neutral' : 'success'}
            size="small"
          >
            {amount}
          </Text>

          {transaction.expiryDate ? (
            <Text tone="neutralLight" size="xxsmall">
              {t('myCoinsPage.transactions.expiry', {
                date: localisedFormatDate(
                  t,
                  'dd MMM yyyy',
                  transaction.expiryDate
                )
              })}
            </Text>
          ) : null}
        </Column>
      </Row>
    </Stack>
  );
};
