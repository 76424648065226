import { openExternalURL } from '@common/helpers/externalConnection';
import { DeviceType, useDeviceType } from '@common/hooks/useDeviceType';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { useAppDispatch } from '@store/hooks';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';
import { BannerTone } from '@components/Banner/useBannerStyle';
import { trackDisplayBannerClicked } from './actions';

type PartnerPromoConfig = {
  tone: BannerTone;
  bannerType: string;
  linkText: string;
  text: string;
  brandName: string;
  linkTo: string;
};

function getPartnerPromoConfig({
  countryCode,
  deviceType
}: {
  countryCode?: SupportedCountryCode;
  deviceType: DeviceType;
}): PartnerPromoConfig | undefined {
  if (!countryCode) {
    return undefined;
  }

  const partnerPromoBanners: {
    [countryCode: string]:
      | PartnerPromoConfig
      | { [countryCode: string]: PartnerPromoConfig };
  } = {
    my: {
      web: {
        tone: 'info',
        bannerType: 'JobStreet MY talent search Desktop',
        linkText: 'Contact JobStreet',
        text: "Maximise your hiring with our partner, Jobstreet's Talent Search",
        brandName: 'jobStreet',
        linkTo: 'https://www.jobstreet.com.my/en/cms/employer/#inquiryModal'
      },
      mobile: {
        tone: 'info',
        bannerType: 'JobStreet MY talent search Mobile',
        linkText: 'Chat with JobStreet',
        text: "Maximise your hiring with our partner, Jobstreet's Talent Search",
        brandName: 'jobStreet',
        linkTo:
          'https://api.whatsapp.com/send/?phone=601546000127&text=Hi%21+Tell+me+more+about+your+Talent+Search'
      }
    }
  };

  const bannerCountryConfig = partnerPromoBanners[countryCode];

  if (!bannerCountryConfig) {
    return undefined;
  }

  return bannerCountryConfig[deviceType] || bannerCountryConfig;
}

export function usePartnerPromo() {
  const { countryCode } = useAppSelector(getLocalisation);
  const { type: deviceType } = useDeviceType();
  const dispatch = useAppDispatch();

  const track = (type) => {
    dispatch(trackDisplayBannerClicked({ type }));
  };

  return {
    onBannerCardClicked: ({ linkTo, type }) => {
      track(type);
      openExternalURL(linkTo);
    },
    partnerPromoBannerConfig: getPartnerPromoConfig({
      countryCode,
      deviceType
    })
  };
}
