import React, { forwardRef, useEffect, useState } from 'react';
import {
  Pressable,
  PressableProps,
  Text as ReactNativeText,
  View
} from 'react-native';
import { Box } from '@components/Box';
import { Stack } from '@components/Stack';
import { sizes } from '@theme/tokens/size';
import { useResolveToken } from '@theme/tokens/utils/useResolveToken';
import {
  ButtonState,
  ButtonStyleProps,
  useButtonStyle,
  useTextStyle
} from './useButtonStyle';

export type ButtonProps = {
  children?: string;
  testID?: PressableProps['testID'];
  onPress?: PressableProps['onPress'];
  disabled?: boolean;
  fullWidth?: boolean;
} & ButtonStyleProps;

export const Button = forwardRef<View, ButtonProps>((props, ref) => {
  const { resolveToken } = useResolveToken();
  const { children, testID, onPress, disabled, fullWidth, ...restProps } =
    props;

  const defaultButtonState = disabled ? 'disabled' : 'default';
  const [buttonState, setButtonState] =
    useState<ButtonState>(defaultButtonState);
  const buttonStyleProps = { state: buttonState, ...restProps };
  const resolvedPressableStyle = useButtonStyle(buttonStyleProps);
  const resolvedTextStyle = useTextStyle(buttonStyleProps);

  useEffect(() => {
    setButtonState(disabled ? 'disabled' : 'default');
  }, [disabled]);

  const pressableStyle = {
    ...resolvedPressableStyle,
    ...(fullWidth && { width: resolveToken(sizes, 'full') })
  };

  return (
    <Stack
      direction={{ mobile: 'column', tablet: 'row', desktop: 'row' }}
      tone="transparent"
    >
      <Pressable
        role="button"
        aria-disabled={disabled}
        disabled={disabled}
        style={pressableStyle}
        onPress={onPress}
        testID={testID}
        ref={ref}
        onHoverIn={() => setButtonState('hover')}
        onHoverOut={() => setButtonState('default')}
        onPressIn={() => setButtonState('active')}
        onPressOut={() => setButtonState('default')}
      >
        <Box
          tone="transparent"
          paddingVertical="small"
          paddingHorizontal={{
            mobile: 'medium',
            tablet: 'large',
            desktop: 'large'
          }}
        >
          <ReactNativeText style={resolvedTextStyle}>
            {children}
          </ReactNativeText>
        </Box>
      </Pressable>
    </Stack>
  );
});
