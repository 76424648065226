import { useHistory, useParams } from 'react-router-native';
import { JOB_STATUS } from '@seek/je-shared-data';
import { getJobById } from '@store/entities/jobs';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';

export const useRedirectToJobNotFoundIfJobNotEditable = () => {
  const { jobId } = useParams<{ jobId: string }>();
  const job = useAppSelector(getJobById(jobId));

  const { countryCode } = useAppSelector(getLocalisation);

  const history = useHistory();

  if (
    [
      JOB_STATUS.CLOSED,
      JOB_STATUS.EXPIRED,
      JOB_STATUS.EXPIRED_BUT_NEVER_LIVE
    ].some((status) => status === job?.status)
  ) {
    history.push(`/${countryCode}/jobs/job-not-found`);
  }
};
