import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';
import { Box } from '@components/Box';
import { Card, CardContent } from '@components/Card';
import { Column } from '@components/Column';
import { Heading } from '@components/Heading';
import { JobsTypeIllustration } from '@components/Illustration';
import { Stack } from '@components/Stack';
import { TileText } from '../TileText';

export const AboutTile = () => {
  const { t } = useTranslation();
  const { brandNameText } = useAppSelector(getLocalisation);

  return (
    <Card>
      <CardContent>
        <Heading level="h2" weight="medium">
          {t('employerLandingPage.aboutTile.heading', { brandNameText })}
        </Heading>
        <Stack
          direction={{ mobile: 'column', tablet: 'row', desktop: 'row' }}
          spacing="large"
        >
          <Column
            flexShrink={1}
            maxWidth={{ mobile: 'xxxlarge', tablet: '2/3', desktop: '2/3' }}
          >
            <TileText>
              {t('employerLandingPage.aboutTile.description', {
                brandNameText
              })}
            </TileText>
            <TileText>
              {t('employerLandingPage.aboutTile.goal', { brandNameText })}
            </TileText>
            <TileText>
              {t('employerLandingPage.aboutTile.operation', {
                brandNameText
              })}
            </TileText>
          </Column>
          <Box
            marginLeft="medium"
            marginTop={{ mobile: 'large', tablet: 'none', desktop: 'none' }}
          >
            <JobsTypeIllustration size="large" />
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};
