import { useTranslation } from 'react-i18next';
import { LOCALISED_COUNTRIES } from '@common/constants/data';
import { getLocalisedBrandDetails } from '@seek/je-shared-data';
import { useRegionSelectorFeatureToggle } from './useRegionSelectorFeatureToggle';

export const useSelectRegionForm = (brandName, initialCountryCode) => {
  const { i18n } = useTranslation();
  const regionSelectorFeatureToggle = useRegionSelectorFeatureToggle();
  const t = i18n.getFixedT('en');

  let validCountryCodes = getLocalisedBrandDetails(
    brandName,
    initialCountryCode
  ).brandConfig.validCountryCodes;

  const shouldLimitDisplayedCountries =
    typeof regionSelectorFeatureToggle?.[brandName] === 'object';
  if (shouldLimitDisplayedCountries) {
    validCountryCodes = validCountryCodes.filter(
      (country) => regionSelectorFeatureToggle[brandName]?.[country]
    );
  }

  const dropdownOptions = validCountryCodes.map((countryCode) => {
    return {
      key: countryCode,
      value: countryCode,
      label: LOCALISED_COUNTRIES(t)[countryCode]
    };
  });

  return {
    dropdownOptions
  };
};
