import React from 'react';
import { useTranslation } from 'react-i18next';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { useRedirectAuthenticatedUser } from '@common/hooks/useRedirectAuthenticatedUser';
import { getUser } from '@store/auth';
import { useAppSelector } from '@store/hooks';
import { Card, CardContent } from '@components/Card';
import { Heading } from '@components/Heading';
import { SuccessIcon } from '@components/Icon';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { ForgotPasswordHelpLink } from './components/ForgotPasswordHelpLink';

export const ForgotPasswordSuccess = () => {
  const { t } = useTranslation();

  const {
    forgotPassword: { email }
  } = useAppSelector(getUser);

  useRedirectAuthenticatedUser();

  return (
    <Stack tone="transparent">
      <JoraEmployerHelmet title={t('forgotPasswordSuccessPage.title')} />

      <Stack spacing="medium" tone="transparent">
        <Card>
          <CardContent>
            <Stack alignItems="center" spacing="medium">
              <SuccessIcon size="xxxlarge" tone="success" variant="outline" />
              <Heading level="h1" weight="regular">
                {t('forgotPasswordSuccessPage.heading')}
              </Heading>
            </Stack>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Stack spacing="medium">
              <Text>
                {t('forgotPasswordSuccessPage.description.emailSent', {
                  email
                })}
              </Text>

              <Heading level="h2">{t('common.message.stillNeedHelp')}</Heading>
            </Stack>
            <ForgotPasswordHelpLink />
          </CardContent>
        </Card>
      </Stack>
    </Stack>
  );
};
