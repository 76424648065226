import React, { useContext } from 'react';
import { JobDescription as InnerField } from '@common/components/PostJobForm/components/JobDescription/JobDescription';
import { FieldWrapper } from '../FieldWrapper';
import { FormContext } from '../FormContext';
import { jobDescriptionRules } from '../rules';
import { OnInvalidDescriptionCallback } from '../types';

interface JobDescriptionLength {
  min: number;
  max: number;
}

interface Props {
  jobDescriptionLength: JobDescriptionLength;
  onInvalidDescription: OnInvalidDescriptionCallback;
}

export default function JobDescription({
  jobDescriptionLength,
  onInvalidDescription
}: Props) {
  const { t } = useContext(FormContext);
  return (
    <FieldWrapper<string>
      name="jobDescription"
      rules={jobDescriptionRules(t, jobDescriptionLength, onInvalidDescription)}
      render={(props) => <InnerField {...props} />}
    />
  );
}
