import { TotalApplication } from './anyApplications';

/**
 * Returns the count of applicants from the application information.
 * @param applicationInformation - The application information object.
 * @returns The count of applicants.
 */
export const applicantCount = (
  applicationInformation: TotalApplication | undefined
) => applicationInformation?.totalApplication || 0;
