import googleAnalyticsGTagMiddleware from '@store/middleware/googleAnalyticsGTag';

export const createGoogleGTagMiddleware = (state) => {
  const brandName = state.localisation?.brandConfig?.name;
  const gtagId = state.config.analytics.gtagId?.[brandName];
  const enableGA4Direct = state.config.featureToggles.enableGA4Direct;
  if (enableGA4Direct && gtagId) {
    return googleAnalyticsGTagMiddleware;
  }

  return undefined;
};
