import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CajaContext } from '@caja/context';
import {
  CompleteBusinessFormData,
  IncompleteBusinessFormData
} from '@src/caja/Form/forms/businessForm/types';
import {
  CompleteInfoFormData,
  IncompleteInfoFormData
} from '@src/caja/Form/forms/infoForm/types';
import RegisterForm from '@src/caja/Form/forms/registerForm/RegisterForm';
import { CompleteRegisterFormData } from '@src/caja/Form/forms/registerForm/types';
import { CompleteVerifyFormData } from '@src/caja/Form/forms/verifyEmailForm/types';
import { Stack } from '@components/Stack';
import {
  BusinessForm,
  InfoForm,
  VerifyEmailForm,
  VerifySuccessForm
} from '../../Form/forms';
import { subsetOfData as infoFormSubset } from '../../Form/forms/infoForm';
import { hydrateDefaultProps } from '../../props/hydrateDefaultProps';
import { mapToStep } from '../../steps';
import { type CajaProps, CompleteData } from '../../types';
import CajaWrapper from './CajaWrapper';
import { toCajaContext } from './context/toCajaContext';
import useFlowNavigation from './hooks/useFlowNavigation';
import { stepMap } from './stepMap';

export default function CajaUnauthorisedFlow(incomingProps: CajaProps) {
  const loading = useState(false);
  const props = hydrateDefaultProps(incomingProps);
  const { nextStep, redirectOnStepChange } = useFlowNavigation(
    stepMap,
    props.currentPath
  );

  const [infoFormData, setInfoFormData] = useState<IncompleteInfoFormData>(
    infoFormSubset(props.defaultJobValues)
  );
  const [businessFormData, setBusinessFormData] =
    useState<IncompleteBusinessFormData>(props.defaultBusinessValues);

  const { t } = useTranslation();
  const cajaContext = toCajaContext({ ...props, loading, t });
  const step = mapToStep({
    stepMap: stepMap,
    path: props.currentPath,
    data: { ...props.defaultJobValues, ...props.defaultBusinessValues },
    options: { infoForm: props.infoFormOptions },
    isAuthenticated: !!props.userEmail,
    emailVerified: props.userEmailVerified
  });

  redirectOnStepChange(step);

  function infoFormSubmit(_data: CompleteInfoFormData) {
    nextStep('business-form');
  }

  function infoFormChange(data: IncompleteInfoFormData) {
    setInfoFormData(data);
    props.onJobChange(data);
  }

  function businessFormSubmit(_data: CompleteBusinessFormData) {
    nextStep('register-form');
  }

  function businessFormChange(data: IncompleteBusinessFormData) {
    setBusinessFormData(data);
    props.onBusinessChange(data);
  }

  async function registerFormSubmit(data: CompleteRegisterFormData) {
    const response = await props.onRegisterSubmit(data);

    if (response?.isSuccessful) {
      nextStep('verify-form');
    }

    return response;
  }

  async function verifyFormSubmit(data: CompleteVerifyFormData) {
    const successful = await props.onVerifySubmit(data);

    if (successful) {
      nextStep('verify-success-form');
    }
  }

  function resendVerificationEmail() {
    props.onResendVerificationEmailSubmit();
  }

  function verifySuccessSubmit() {
    props.onVerifySuccessSubmit({
      job: infoFormData,
      business: businessFormData
    } as CompleteData);
  }

  return (
    <CajaWrapper
      {...props}
      stepMap={stepMap}
      disablePosting={incomingProps.disablePosting}
      step={step}
      showProgress={props.showProgress}
    >
      <CajaContext.Provider value={cajaContext}>
        <Fragment>
          <Stack spacing="large">
            {step === 'info-form' && (
              <InfoForm
                {...props.infoFormOptions}
                onChange={infoFormChange}
                onSubmit={infoFormSubmit}
                defaultValues={infoFormSubset(props.defaultJobValues)}
              />
            )}
            {step === 'business-form' && (
              <BusinessForm
                onChange={businessFormChange}
                onSubmit={businessFormSubmit}
                defaultValues={props.defaultBusinessValues}
              />
            )}
            {step === 'register-form' && (
              <RegisterForm
                onChange={() => {}}
                onSubmit={registerFormSubmit}
                defaultValues={props.defaultRegisterValues}
                brandNameText={props.brandNameText}
              />
            )}
            {step === 'verify-form' && (
              <VerifyEmailForm
                onSubmit={verifyFormSubmit}
                onResend={resendVerificationEmail}
              />
            )}
            {step === 'verify-success-form' && (
              <VerifySuccessForm onSubmit={verifySuccessSubmit} />
            )}
          </Stack>
        </Fragment>
      </CajaContext.Provider>
    </CajaWrapper>
  );
}
