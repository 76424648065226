import { RecaptchaHandle } from '../topLevelFields';

export const useStartRecaptcha = (
  recaptchaRef: React.RefObject<RecaptchaHandle>
) => {
  return {
    startRecaptcha: () => {
      recaptchaRef.current?.execute();
    }
  };
};
