import { Application } from '@common/types/Application';
import { VIDEO_SCREENING_STATUS } from '@seek/je-shared-data/lib/types/applications';
import { VirusScanStatus } from '@seek/je-shared-data/lib/types/virusScanStatus';

export function useApplicationCardActions(application: Application) {
  const {
    message,
    resume,
    isProfileApply = false,
    textScreeningAnswer,
    videoScreeningStatus,
    videoUid
  } = application;
  const { isResumeAttached, virusScanStatus } = resume || {};

  const shouldShowViewProfileAction = Boolean(
    application?.shiftAvailability ||
      application?.workExperience ||
      application?.aboutMe
  );

  const shouldShowCoverLetterAction = Boolean(message);

  const shouldShowRefreshAction =
    !isProfileApply && virusScanStatus === VirusScanStatus.SCANNING;

  const isProfileApplyAndHasResume = Boolean(
    isProfileApply && isResumeAttached
  ); // Profile apply resumes do not have virus scan status - it will block downloads from the backend if there is a virus
  const isLegacyApplyAndHasResume = Boolean(
    !isProfileApply &&
      isResumeAttached &&
      virusScanStatus === VirusScanStatus.SCANNED_OK
  );
  const shouldShowDownloadResumeAction =
    isProfileApplyAndHasResume || isLegacyApplyAndHasResume;

  const shouldShowViewScreeningAnswersAction = !!textScreeningAnswer;
  const shouldShowViewVideoScreeningAction =
    !!videoUid && videoScreeningStatus === VIDEO_SCREENING_STATUS.VIDEO_READY;

  const shouldShowProfileOrCoverLetterAction =
    shouldShowViewProfileAction || shouldShowCoverLetterAction;
  const shouldShowRefreshOrDownloadAction =
    shouldShowRefreshAction || shouldShowDownloadResumeAction;
  const shouldShowAnyScreeningAction =
    shouldShowViewScreeningAnswersAction || shouldShowViewVideoScreeningAction;

  return {
    shouldShowCoverLetterAction,
    shouldShowViewProfileAction,
    shouldShowRefreshAction,
    shouldShowDownloadResumeAction,
    shouldShowViewScreeningAnswersAction,
    shouldShowViewVideoScreeningAction,
    shouldBeResponsive:
      (shouldShowProfileOrCoverLetterAction &&
        shouldShowRefreshOrDownloadAction) ||
      shouldShowAnyScreeningAction
  };
}
