import { useHistory } from 'react-router-native';
import { useGetHomeLink } from '@common/hooks/appPageLinks';
import { useMonetisationEnabled } from '@common/hooks/featureToggles';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { upgradeable } from '@src/job';
import { JobItemState } from '@store/entities/jobs';
import { useMismatchedStripeAccount } from '../featureToggles/useMonetisationEnabled/useMonetisationEnabled';

export const useRedirectUnupgradeableAd = (job?: JobItemState): void => {
  const history = useHistory();
  const homeLink = useGetHomeLink();
  const isMismatchedStripeAccount = useMismatchedStripeAccount(
    job?.countryCode as SupportedCountryCode
  );

  const jobCanBeUpgraded =
    useMonetisationEnabled(job?.countryCode || '') &&
    !isMismatchedStripeAccount &&
    upgradeable(job?.adType, job?.status);

  const shouldRedirect = job && !jobCanBeUpgraded;

  if (shouldRedirect) history.push(homeLink);
};
