import {
  FullScreenMessage,
  InAppMessage,
  ModalMessage,
  changeUser,
  initialize,
  isPushBlocked,
  isPushPermissionGranted,
  isPushSupported,
  openSession,
  requestPushPermission,
  showInAppMessage,
  subscribeToInAppMessage
} from '@braze/web-sdk';

export const setupBraze = ({ apiKey, baseUrl, userId }) => {
  initialize(apiKey, {
    baseUrl,
    enableLogging: process.env.NODE_ENV !== 'production',
    // Sets the minimum time interval between triggered in-app messages to
    // 0 seconds in dev instead of the default 30
    minimumIntervalBetweenTriggerActionsInSeconds:
      process.env.NODE_ENV !== 'production' ? 0 : 30
  });

  if (userId) {
    changeUser(userId);
  }

  subscribeToInAppMessage(function (inAppMessage) {
    // Check if message is not a control variant
    if (inAppMessage instanceof InAppMessage) {
      // Access the key-value pairs, defined as `extras`
      const keyValuePairs = inAppMessage.extras || {};
      // Check the value of our key `msg-id` defined in the Braze dashboard
      if (keyValuePairs['msg-id'] === 'push-primer') {
        // We don't want to display the soft push prompt to users on browsers
        // that don't support push, or if the user has already granted/blocked permission
        if (
          isPushSupported() === false ||
          isPushPermissionGranted() ||
          isPushBlocked()
        ) {
          // Do not call `showInAppMessage`
          return;
        }

        // User is eligible to receive the native prompt
        // Register a click handler on one of the two buttons
        if (
          inAppMessage instanceof (ModalMessage || FullScreenMessage) &&
          inAppMessage.buttons[0]
        ) {
          // Prompt the user when the first button is clicked
          inAppMessage.buttons[0].subscribeToClickedEvent(function () {
            requestPushPermission();
          });
        }
      }
    }

    // Show the in-app message now
    showInAppMessage(inAppMessage);
  });

  // `openSession` should be called last - after `changeUser` and `automaticallyShowInAppMessages`
  openSession();
};
