import React from 'react';
import { G, Path } from 'svgs';
import { colors } from '@theme/tokens/colors';
import { VectorComponentProps, createLogo } from '../createLogo';

const { green600 } = colors;

const vectorComponent = ({ backgroundColor }: VectorComponentProps) => (
  <>
    <G>
      <Path
        fill={green600}
        d="M223.142171,108 L8.56608526,108 C3.04023812,108 0,110.021795 0,114 C0,117.978205 3.04023808,120 8.56608526,120 L223.142171,120 C228.858605,120 232,117.989044 232,114 C232,110.010956 228.858605,108 223.142171,108 Z"
      />
      <Path
        fill={backgroundColor}
        d="M177.645454,43.2350002 C178.072427,31.2067845 186.75888,24.8171735 203.704813,24.0661671 C229.123713,22.9396576 230.227529,36.478793 231.409176,43.2350002 C232.196941,47.7391381 232.196941,54.8701516 231.409176,64.62804 C230.869903,73.3998454 229.913278,79.1621658 228.539301,81.915001 C226.478335,86.0442542 222.141803,89.8201015 219.575156,91.4901765 C217.008509,93.1602517 211.200373,96 199.700624,96 C192.034125,96 186.210543,94.2052358 182.229878,90.6157076 C178.605829,87.1471539 176.552211,81.7759563 176.069025,74.5021147 C175.585838,67.228273 177.639456,61.8655883 182.229878,58.4140605 C185.611551,56.3667602 189.183562,54.7776764 192.945911,53.6468093 C196.708259,52.5159422 202.062939,51.1406432 209.00995,49.520912 C211.740733,49.0160956 213.514929,48.2368303 214.332537,47.1831165 C215.150145,46.1294027 215.401186,44.8133639 215.08566,43.2350002 C213.643599,40.0107997 210.161867,38.3986994 204.640464,38.3986994 C199.119061,38.3986994 195.688766,40.0107997 194.349581,43.2350002 L177.645454,43.2350002 Z M215.355147,60.2446639 C208.688913,62.5467306 205.355796,63.6977641 205.355796,63.6977641 C205.355796,63.6977641 202.879577,64.1900962 199.340709,65.3398172 C195.80184,66.4895381 194.426625,67.6941203 193.232493,69.2146276 C192.038361,70.7351349 190.721183,74.2996956 193.232493,78.5396171 C194.9067,81.3662313 198.947801,82.2687209 205.355796,81.2470861 C208.688913,80.3202015 211.356036,78.2676755 213.357166,75.089508 C215.358296,71.9113405 216.02429,66.9630591 215.355147,60.2446639 Z"
      />
      <Path
        fill={backgroundColor}
        d="M172,24 L172,43.2918172 L163.96566,43.2918172 C160.191313,43.2918172 157.110742,44.4857459 154.723949,46.8736038 C152.337156,49.2614615 151.059075,52.4951824 150.889707,56.5747663 L150.889707,96 L132,96 L132,57.2541852 C132.924703,46.0038655 136.755618,37.6489215 143.492744,32.1893528 C150.22987,26.7297842 159.732289,24 172,24 Z"
      />
      <Path
        fill={backgroundColor}
        d="M72.8225677,86.9973466 C67.5028324,80.0754183 64.5817866,71.1652417 64.0594302,60.2668165 C63.5370738,49.3683915 66.4581197,40.2683249 72.8225677,32.9666171 C78.7081341,26.9888724 86.4328166,24 95.9966149,24 C105.560413,24 113.285096,26.9888724 119.170662,32.9666171 C125.056887,39.4824378 128,48.5825044 128,60.2668165 C128,71.9511289 125.056887,80.8613054 119.170662,86.9973466 C113.793192,92.9991155 106.069638,96 96,96 C85.9303617,96 78.2045509,92.9991155 72.8225677,86.9973466 Z M96,39.1900338 C86.4820306,39.1900338 81.7230459,46.1266892 81.7230459,60 C81.7230459,73.8733107 86.4820306,80.9511493 96,81.2335159 C105.595465,80.3883909 110.393198,73.3811439 110.393198,60.2117748 C110.393198,47.0424057 105.595465,40.0351587 96,39.1900338 Z"
      />
      <Path
        fill={backgroundColor}
        d="M60,0.0109500395 L50.6663429,0.0109500395 C48.09184,-0.106814649 45.8958996,0.725315989 44.0785218,2.50734194 C41.3524551,5.1803809 40.4602405,6.68796371 40.4602405,10.454521 C40.4602405,12.9655592 40.4602405,33.226976 40.4602405,71.238771 C39.5877951,77.85723 35.8033834,81.1664596 29.1070051,81.1664596 C22.4106268,81.1664596 18.8093401,77.85723 18.3031449,71.238771 L18.3031449,59.3360356 L0,59.3360356 C0,64.664919 0,69.2702396 0,73.1519969 C0,78.9746328 0.219371403,96.657281 29.1070051,95.98113 C57.9946386,95.304979 60,79.090216 60,73.1519969 C60,69.193184 60,44.8128352 60,0.0109500395 Z"
      />
    </G>
  </>
);

export const JoraLogo = createLogo({
  viewBox: '0 0 235 120',
  vectorComponent
});
