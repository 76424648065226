import { useTranslation } from 'react-i18next';
import { getLoadAndErrorState } from '@store/app';
import { useAppSelector } from '@store/hooks';

export function useRequestError() {
  const { error } = useAppSelector(getLoadAndErrorState);
  const { t } = useTranslation();

  const getRequestError = (requestKey: string) => {
    const requestError = error[requestKey];

    const errorMessage =
      (requestError && t(requestError.message, requestError.properties)) ||
      undefined;

    return errorMessage;
  };

  return { getRequestError };
}
