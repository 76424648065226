import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { Spinner } from '@common/components/Spinner';
import { useQueryVideoSignedUrl } from '@common/hooks/application/screeningVideos';
import { Badge } from '@components/Badge';
import { Box } from '@components/Box';
import { Modal } from '@components/Modal';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { VideoPlayer } from '../VideoPlayer';

type VideoScreeningModalProps = {
  isModalOpen: boolean;
  closeModal: () => void;
  company: string;
  videoUid: string;
  applicationId: string;
  testID?: string;
  applicantName: string;
};

export const VideoScreeningModal = ({
  isModalOpen,
  closeModal,
  company,
  videoUid,
  applicationId,
  testID,
  applicantName
}: VideoScreeningModalProps) => {
  const { t } = useTranslation();
  const { signedUrl, errorMessage, isSuccess, isLoading } =
    useQueryVideoSignedUrl({
      videoUid,
      applicationId
    });

  const titleWithBetaBadge = (
    <Row tone="transparent" spacing="small" flexWrap="wrap">
      <Box tone="transparent" marginBottom="xxsmall">
        <Text weight="bold">
          {t('applications.applicationCard.screeningVideo.title', {
            name: applicantName
          })}
        </Text>
      </Box>

      <Box tone="transparent" marginBottom="xxsmall">
        <Badge tone="promo">{t('badge.beta')}</Badge>
      </Box>
    </Row>
  );

  return (
    <Modal
      testID={testID}
      title={titleWithBetaBadge}
      visible={isModalOpen}
      onRequestClose={closeModal}
    >
      <Stack spacing="medium" width="full" height="full">
        <Text size="small">
          {t('applications.applicationCard.screeningAnswer.label', {
            businessName: company
          })}
        </Text>

        {isLoading && (
          <Stack alignItems="center" testID="video-modal-loader">
            <Spinner size="50" />
          </Stack>
        )}

        {isSuccess && signedUrl && (
          <Stack alignItems="center">
            <VideoPlayer signedUrl={signedUrl} />
          </Stack>
        )}

        {errorMessage && (
          <ErrorMessage>
            {t(errorMessage.message, errorMessage.properties)}
          </ErrorMessage>
        )}
      </Stack>
    </Modal>
  );
};
