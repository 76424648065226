import { Application } from '@common/types/Application';
import { trackExpandedCoverLetter } from '@store/entities/applications/actions';
import { useAppDispatch } from '@store/hooks';

export function useViewCoverLetterCardAction(application: Application) {
  const {
    id: applicationId,
    jobId,
    candiUserId,
    candiApplicationId,
    isAnonymous
  } = application;

  const dispatch = useAppDispatch();
  const trackViewCoverLetter = () => {
    dispatch(
      trackExpandedCoverLetter({
        jobId,
        candiApplicationId,
        applicationId,
        candiUserId: isAnonymous ? null : candiUserId,
        candiAnonymousId: isAnonymous ? candiUserId : null
      })
    );
  };

  return {
    trackViewCoverLetter
  };
}
