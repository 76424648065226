import { useEffect } from 'react';
import { useGetBusinessListData } from '@common/hooks/business';

export const useLoadVerifyAccountDetailsData = () => {
  const { getBusinessListData, businesses } = useGetBusinessListData();

  useEffect(() => {
    getBusinessListData();
  }, [getBusinessListData]);

  return {
    firstBusinessName: businesses[0]?.name
  };
};
