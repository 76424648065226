import React from 'react';
import { useTranslation } from 'react-i18next';
import { Application } from '@common/types';
import { ProgressBar } from '@components/ProgressBar';
import { ApplicationsStatusProgressHelperText } from './ApplicationsStatusProgressHelperText';
import { useProgressBarScore } from './useProgressBarScore';

type ApplicationsCounterProps = {
  applications: Array<Application>;
};

export const ApplicationsStatusProgressBar = ({
  applications
}: ApplicationsCounterProps) => {
  const { t } = useTranslation();
  const { applicationStatusProgress } = useProgressBarScore(applications);

  return (
    <ProgressBar
      value={applicationStatusProgress}
      label={t(
        'jobDetailsAndApplicationsListPage.applicationStatusProgressText.heading'
      )}
      helperText={
        <ApplicationsStatusProgressHelperText
          applicationStatusProgress={applicationStatusProgress}
        />
      }
    />
  );
};
