import { StyleSheet } from 'react-native';

const base = StyleSheet.create({
  alignCenter: {
    alignItems: 'center'
  }
});

export default {
  base
};
