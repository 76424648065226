export function isPhone() {
  return (
    /Mobi/i.test(navigator.userAgent) && !/iPad/i.test(navigator.userAgent)
  );
}

export function getDeviceInformation() {
  return null;
}

export function isIOS() {
  return false;
}

export function isAndroid() {
  return false;
}

export function getOS() {
  return DEVICE_TYPE.web;
}

export function getDeviceUserAgent(defaultUserAgent) {
  return Promise.resolve(defaultUserAgent);
}

export function requiresSafeAreaInset() {
  return false;
}

export const DEVICE_TYPE = {
  ios: 'ios',
  android: 'android',
  web: 'web'
} as const;

export type DeviceType = (typeof DEVICE_TYPE)[keyof typeof DEVICE_TYPE];
