import { useTheme } from '@theme/DesignSystemProvider/ThemeContext';
import { useResolveToken } from '@theme/tokens/utils/useResolveToken';

export type TabStyleProps = {
  isSelected: boolean;
};

export function useTabStyle({ isSelected }: TabStyleProps) {
  const { border, tab } = useTheme();

  const { resolveToken } = useResolveToken();
  const { backgroundColor, borderColor } =
    tab[isSelected ? 'active' : 'default'];

  const defaultTabStyle = {
    borderRadius: 'xsmall',
    borderWidth: 'xsmall',
    borderStyle: 'solid',
    justifyContent: 'center'
  } as const;

  return {
    backgroundColor,
    borderColor,
    borderRadius: resolveToken(border.radius, defaultTabStyle.borderRadius),
    borderStyle: defaultTabStyle.borderStyle,
    borderWidth: resolveToken(border.width, defaultTabStyle.borderWidth),
    justifyContent: defaultTabStyle.justifyContent,
    flex: 1
  };
}

export function useTextStyle({ isSelected }: TabStyleProps) {
  const { tab, typography } = useTheme();
  const { resolveToken } = useResolveToken();
  const { fontColor } = tab[isSelected ? 'active' : 'default'];
  const {
    size: textSize,
    lineHeight: textLineHeight,
    letterSpacing: textLetterSpacing
  } = resolveToken(typography.text, {
    mobile: 'small',
    desktop: 'standard',
    tablet: 'standard'
  }) || {};

  const defaultTabTypography = {
    weight: 'regular',
    textAlign: 'center'
  } as const;

  return {
    fontFamily: typography.fontFamily,
    color: fontColor,
    fontSize: resolveToken(typography.fontSizes, textSize),
    fontWeight: resolveToken(
      typography.fontWeights,
      defaultTabTypography.weight
    ),
    lineHeight: resolveToken(typography.lineHeights, textLineHeight),
    letterSpacing: resolveToken(typography.letterSpacing, textLetterSpacing),
    textAlign: defaultTabTypography.textAlign
  };
}
