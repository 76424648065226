import REQUEST_KEY from '@common/constants/requestKeys';
import { ClientError } from '@common/helpers/errors';
import { useHandleAppLoading } from '@common/hooks/appLoading';
import { extractTrpcErrorProperties } from '@src/trpc/lib';
import {
  ErrorResponse,
  Params,
  QueryOptions,
  SuccessResponse,
  useFetchTransactions
} from './useFetchTransactions';

export type QueryTransactionsResult = {
  transactions: SuccessResponse;
  isLoading: boolean;
  errorMessage: {
    message: string | undefined;
    properties:
      | {
          guid: string;
          responseBody: any;
        }
      | undefined;
  };
};

export const useQueryTransactions = (
  params: Params,
  queryOptions?: QueryOptions
) => {
  const { data, error, isLoading } = useFetchTransactions(params, queryOptions);

  useHandleAppLoading(isLoading, REQUEST_KEY.COINS.TRANSACTIONS.GET);

  const clientError = getClientError(
    extractTrpcErrorProperties<ErrorResponse>(error)
  );

  return {
    transactions: data,
    isLoading,
    errorMessage: {
      message: clientError?.message,
      properties: clientError?.properties
    }
  };
};

const getClientError = (errorProperties) => {
  if (!errorProperties) return;

  return new ClientError('errors.defaultWithGuid', {
    guid: errorProperties.guid,
    responseBody: errorProperties.responseBody
  });
};
