import { ApplicationStatus } from '@common/types/Application';
import { APPLICANT_STATUS } from '@seek/je-shared-data';

const { ACCEPTED_OFFER, NO_STATUS } = APPLICANT_STATUS;

export type ApplicationStatusStyleProps = {
  status: ApplicationStatus;
  count: number;
};

export function useApplicationStatus({
  status,
  count
}: ApplicationStatusStyleProps) {
  let tone: 'danger' | 'success' | 'neutral';

  if (status === NO_STATUS && count) {
    tone = 'danger';
  } else if (status === ACCEPTED_OFFER && count) {
    tone = 'success';
  } else {
    tone = 'neutral';
  }

  return {
    displayCount: count || '-',
    tone
  };
}
