import React, { useContext } from 'react';
import { Controller } from 'react-hook-form';
import { FormContext } from './FormContext';
import type { IncompleteBusinessFormData } from './types';

interface FieldWrapperProps<V> {
  name: keyof IncompleteBusinessFormData;
  render: (props: RenderField<V>) => React.ReactElement;
  rules?: Record<string, unknown>;
}

interface RenderField<T> {
  onChange: () => void;
  onBlur: () => void;
  value: T;
  error: string | undefined;
}
export function FieldWrapper<V>({ name, render, rules }: FieldWrapperProps<V>) {
  const { control, errors } = useContext(FormContext);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) =>
        render({
          onChange: field.onChange,
          onBlur: field.onBlur,
          value: field.value as V,
          error: errors[name]?.message as string | undefined
        })
      }
    />
  );
}
