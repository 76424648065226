// Primary colour
export const green50 = '#F2FDF7'; // Used as shadows to add depth to brand coloured icons/text
export const green100 = '#DFF8EB';
export const green200 = '#BDF0D5';
export const green500 = '#22C365';
export const green600 = '#15A449';
export const green700 = '#0E8136'; //Brand
export const green800 = '#085C22';

// Secondary colours
export const red50 = '#FFF1F1';
export const red100 = '#FFDFDF';
export const red200 = '#FFBEBE';
export const red500 = '#FF3333';
export const red600 = '#E32121'; // Used for errors

export const yellow50 = '#FFFDF2';
export const yellow100 = '#FFF7E1';
export const yellow200 = '#FFEEC3';
export const yellow500 = '#FFBB33';
export const yellow600 = '#E28D21';

export const blue50 = '#F2FAFE';
export const blue100 = '#DEF0FC';
export const blue200 = '#B9DEF8';
export const blue500 = '#197FE6';
export const blue600 = '#0F62C7'; // Used for links

export const purple50 = '#F8F3FE';
export const purple100 = '#ECE2FC';
export const purple200 = '#D8C3F9';
export const purple500 = '#6E30E8';
export const purple600 = '#521ECA';

// Neutral
export const grey50 = '#FAFAFA';
export const grey100 = '#F0F0F0';
export const grey200 = '#DEDEDE';
export const grey300 = '#C1C1C1';
export const grey400 = '#A4A4A4';
export const grey500 = '#808080';
export const grey600 = '#626262'; // Lightest text colour allowed on white bg
export const grey700 = '#494949';
export const grey900 = '#1C1C1C';
export const blackRGB = '23,26,23';

export const white = '#FFFFFF';
export const black = '#000000';

// Transparencies
export const transparentOverlay = 'rgba(23,26,23,0.5)';
export const transparent = 'rgba(0,0,0,0)';
