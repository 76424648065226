import { useCallback, useEffect } from 'react';
import { clearDraftForm, saveDraftForm } from '@store/draft';
import { JOB_DRAFT_FORM } from '@store/draft/constants';
import { BusinessWithUsers } from '@store/entities/businesses/types';
import { useAppDispatch } from '@store/hooks';
import { mapBusinessToFormValues } from '../../helpers/mapBusinessToFormValues';
import { mapJobToFormValues } from '../../helpers/mapJobToFormValues';
import { useGetBusinessData } from '../useGetBusinessData';
import { useGetJobData } from '../useGetJobData';

export function useLoadJobIntoDraft({ jobId }: { jobId: string }) {
  const dispatch = useAppDispatch();

  const { getJobData, job, hasLoadJobError } = useGetJobData(jobId);
  const { getBusinessData, business, hasLoadBusinessError } =
    useGetBusinessData(job.businessId);

  const { saveJobIntoDraft, clearJobDraft } = useSaveJobIntoDraftForm();

  useEffect(() => {
    getJobData();
    getBusinessData();
  }, [getJobData, getBusinessData]);

  useEffect(() => {
    saveJobIntoDraft({
      ...mapJobToFormValues(job),
      business:
        business && mapBusinessToFormValues(business as BusinessWithUsers)
    });

    return clearJobDraft;
  }, [job, business, saveJobIntoDraft, clearJobDraft, dispatch]);

  return {
    business,
    hasLoadBusinessError,
    job,
    hasLoadJobError
  };
}

function useSaveJobIntoDraftForm() {
  const dispatch = useAppDispatch();

  const saveJobIntoDraft = useCallback(
    (data: any) => {
      dispatch(saveDraftForm(JOB_DRAFT_FORM, data));
    },
    [dispatch]
  );

  const clearJobDraft = useCallback(() => {
    dispatch(clearDraftForm(JOB_DRAFT_FORM));
  }, [dispatch]);

  return { saveJobIntoDraft, clearJobDraft };
}
