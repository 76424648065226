import { JOB_STATUS } from '@seek/je-shared-data';
import { BadgeTone } from '@components/Badge';

/**
 * Returns the corresponding BadgeTone based on the provided job status.
 * @param status The job status.
 * @returns The BadgeTone corresponding to the job status, or undefined if the status is not recognized.
 */
export const getJobStatusBadgeTone = (
  status: JOB_STATUS | undefined
): BadgeTone | undefined => {
  switch (status) {
    case JOB_STATUS.LIVE:
      return 'success';
    case JOB_STATUS.IN_REVIEW:
      return 'warning';
    case JOB_STATUS.INVALID_AD:
    case JOB_STATUS.ACTION_REQUIRED:
    case JOB_STATUS.USER_BANNED:
    case JOB_STATUS.CRAWLER_REJECTED:
      return 'danger';
    default:
      return;
  }
};
