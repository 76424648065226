/* eslint css-modules/no-unused-class: [2, { markAsUsed: [display-block] }] */
import { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash.omit';
import styles from './Link.scss';

export default class WebLink extends Component {
  static propTypes = {
    children: PropTypes.node,
    extraTopMargin: PropTypes.bool,
    onDarkBackground: PropTypes.bool,
    display: PropTypes.oneOf(['block']),
    textStyle: PropTypes.any
  };

  render() {
    const { children, textStyle, onDarkBackground, extraTopMargin, display } = this.props;

    return (
      <Link {...omit(this.props, 'onPress', 'onDarkBackground', 'extraTopMargin', 'textStyle')}
        className={classNames({
          [styles.onDarkBackground]: onDarkBackground,
          [styles.extraTopMargin]: extraTopMargin,
          [styles[`display-${display}`]]: display,
          [textStyle]: textStyle
        })}>
        {children}
      </Link>
    );
  }
}
