import { useTranslation } from 'react-i18next';
import { useAppAvailable } from '@common/hooks/useAppAvailable';
import { useOpenAppStoreLink } from '@common/hooks/useOpenAppStoreLink';
import { inNativeApp } from '@src/joraEmployer/whereAmIRunning';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';

export function useAppDownloadReminderBanner() {
  const { t } = useTranslation();
  const { brandNameText } = useAppSelector(getLocalisation);
  const { isAppAvailable } = useAppAvailable();
  const { onPressAppStore } = useOpenAppStoreLink();

  return {
    shouldRenderBanner: isAppAvailable && !inNativeApp(),
    bannerCopy: {
      title: t('common.banner.appsDownload.title'),
      description: t('common.banner.appsDownload.description', {
        brand: brandNameText
      })
    },
    onPressAppStore
  };
}
