import { useRef } from 'react';
import { MultiSelectProps } from './MultiSelect';

type UseMultiSelectProps<T> = Pick<MultiSelectProps<T>, 'value' | 'onChange'>;

export function useMultiSelect<T>({
  value = [],
  onChange
}: UseMultiSelectProps<T>) {
  const selectedKeysRef = useRef<T[]>([...value]);

  const updateSelectedKeys = (key: T) => (selected: boolean) => {
    if (selected) {
      selectedKeysRef.current = [...selectedKeysRef.current, key];
    } else {
      selectedKeysRef.current = selectedKeysRef.current.filter(
        (selectedKey) => selectedKey !== key
      );
    }

    onChange(selectedKeysRef.current);
  };

  return {
    updateSelectedKeys
  };
}
