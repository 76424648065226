import React, { useEffect, useState } from 'react';
import { When } from '@common/components/When';
import { getNativeVersionNumber } from '@src/nativeBridge/actions';

export const AppDetails = () => {
  const [versionNumber, setVersionNumber] = useState<string | null>(null);
  useEffect(() => {
    getNativeVersionNumber().then((version) => {
      setVersionNumber(version);
    });
  }, []);

  return (
    <When condition={Boolean(versionNumber)}>
      <div className="mt-8 text-sm text-foreground-neutral">
        Version: {versionNumber}
      </div>
    </When>
  );
};
