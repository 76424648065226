import { ProfileApplication } from 'je-bff/src/applications/list/transformers';
import { VIDEO_SCREENING_STATUS } from '@seek/je-shared-data/lib/types/applications';
import { FILTERS } from '../../../types';

type Filter = {
  [FILTERS.VIDEO_SCREENING]?: boolean;
};

type Item = {
  videoScreeningStatus?: ProfileApplication['videoScreeningStatus'];
};

export const filterVideoScreening = <T extends Item>(
  filter: Filter,
  items: T[]
): T[] => {
  const filterValue = filter[FILTERS.VIDEO_SCREENING];

  if (!filterValue) {
    return items;
  }

  return items.filter(
    (item) =>
      item.videoScreeningStatus &&
      item.videoScreeningStatus === VIDEO_SCREENING_STATUS.VIDEO_READY
  );
};
