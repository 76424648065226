import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <>
    <Path
      d="M17.417 13.164v7.753H1.083V1.083h16.444v6.708"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M4.467 5.61h8.458M4.467 9.11H9.46M4.467 12.61h7.292M4.467 16.11h7.292"
      strokeWidth={0.75}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M12.633 10.3l2.45 2.567 5.95-6.067"
      strokeWidth={0.8}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const ResumeWithTickIcon = createIcon({
  viewBox: '0 0 22 22',
  vectorComponent
});
