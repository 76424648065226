import React from 'react';
import { JobCardStatus } from '@common/components/JobCardStatus';
import { ApplicationInformation } from '@common/hooks/useAppQuery/queries/useGetApplications';
import { BusinessSize } from '@common/types';
import { ListJobItem } from '@store/entities/jobs/types';
import { Card } from '@components/Card';
import { JobCardContent } from './components/JobCardContent';
import { JobCardFooter } from './components/JobCardFooter';

export type JobCardProps = {
  applicationInformation: ApplicationInformation;
  businessSize: BusinessSize;
  job: ListJobItem;
};

export const JobCard = ({
  applicationInformation,
  businessSize,
  job
}: JobCardProps) => (
  <Card
    key={job.id}
    leftAdornment={<JobCardStatus status={job.status} />}
    borderWidth="xsmall"
    testID={`job-card-${job.id}`}
  >
    <JobCardContent
      applicationInformation={applicationInformation}
      businessSize={businessSize}
      job={job}
    />
    <JobCardFooter applicationInformation={applicationInformation} job={job} />
  </Card>
);
