import { useTranslation } from 'react-i18next';
import { VIDEO_SCREENING_FILTER_OPTIONS } from '@common/constants/data';

export const useVideoScreeningOptions = () => {
  const { t } = useTranslation();

  const options = VIDEO_SCREENING_FILTER_OPTIONS(t);

  return [...options];
};
