import { useEffect } from 'react';
import REQUEST_KEY from '@common/constants/requestKeys';
import { Job } from '@common/types';
import { getRequestState } from '@store/app';
import { getJobById } from '@store/entities/jobs';
import * as jobActions from '@store/entities/jobs/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';

const defaultEmptyJob = {};

export function useLoadJobData(jobId: string) {
  const dispatch = useAppDispatch();
  const loadJobError = useAppSelector(
    getRequestState(REQUEST_KEY.JOB.LOAD)
  ).error;
  const job = (useAppSelector(getJobById(jobId)) as Job) || defaultEmptyJob;

  useEffect(() => {
    (async () => {
      await dispatch(jobActions.load(jobId));
    })();
  }, [dispatch, jobId]);

  return {
    hasLoadJobError: Boolean(loadJobError),
    job
  };
}
