import { useTheme } from '@theme/DesignSystemProvider/ThemeContext';
import { useResolveToken } from '@theme/tokens/utils/useResolveToken';

export function useAvatarStyle() {
  const { avatar, colors, border, shadow: shadowToken } = useTheme();
  const { resolveToken } = useResolveToken();

  return {
    backgroundColor: resolveToken(colors.background, 'neutral'),
    borderRadius: resolveToken(border.radius, 'full'),
    height: resolveToken(avatar.sizes, 'medium'),
    width: resolveToken(avatar.sizes, 'medium'),
    justifyContent: 'center',
    alignItems: 'center',
    ...(resolveToken(shadowToken, 'medium') || {})
  } as const;
}
