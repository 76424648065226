import React from 'react';
import { useTranslation } from 'react-i18next';
import { getStatusTone } from '@common/helpers/applications';
import { ApplicationStatus } from '@common/types';
import { Badge } from '@components/Badge';
import { Box } from '@components/Box';

type Props = {
  status: ApplicationStatus;
  testID: string;
};

export const ApplicantStatusBadge = (props: Props) => {
  const { testID, status } = props;
  const { t } = useTranslation();

  const statusTone = getStatusTone(status);

  return (
    <Box borderWidth="xsmall" tone="neutral" borderLeftWidth="none">
      <Badge tone={statusTone} testID={testID} borderRadius="none">
        {t(`applications.status.${status}`)}
      </Badge>
    </Box>
  );
};
