import React, { useContext } from 'react';
import { ConfirmEmail as InnerField } from '@common/components/Account/components/ConfirmEmail';
import { confirmEmail } from '@common/components/Account/rules';
import { FieldWrapper } from '../FieldWrapper';
import { FormContext } from '../FormContext';

export const ConfirmEmail = () => {
  const { t } = useContext(FormContext);

  return (
    <FieldWrapper<string>
      name="confirmEmail"
      rules={confirmEmail(t)}
      render={(props) => {
        const mergedProps = { ...props, t };
        return <InnerField {...mergedProps} />;
      }}
    />
  );
};
