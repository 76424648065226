import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Application } from '@common/types';
import { useAppDispatch } from '@store/hooks';
import { trackViewProfileClicked } from './actions';

export function useViewProfileAction(application: Application) {
  const dispatch = useAppDispatch();
  const [isProfileExpanded, setIsProfileExpanded] = useState(false);

  const trackProfileExpanded = (type) => {
    dispatch(
      trackViewProfileClicked({
        type,
        employerJobId: application.jobId,
        applicationId: application.id,
        candiApplicationId: application?.candiApplicationId,
        candiUserId: application.candiUserId
      })
    );
  };

  return {
    isProfileExpanded,
    onViewProfilePressed: () => {
      const updatedIsProfileExpanded = !isProfileExpanded;

      setIsProfileExpanded(updatedIsProfileExpanded);
      trackProfileExpanded(updatedIsProfileExpanded ? 'open' : 'close');
    }
  };
}

export function useViewProfileDisplay(isProfileExpanded) {
  const { t } = useTranslation();

  return isProfileExpanded
    ? t('applications.applicationCard.closeProfile')
    : t('applications.applicationCard.viewProfile');
}
