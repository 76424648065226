import { createContext } from 'react';

type TabListContextType = {
  tabIndex: number;
  onTabPress?: (index: number) => void;
};

export const TabListContext = createContext<TabListContextType>({
  tabIndex: -1
});
