import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { Button } from '@components/Button';
import { Stack } from '@components/Stack';
import { FormContext } from './context/FormContext';
import { useResponseErrorHandler } from './hooks';
import {
  ConfirmEmail,
  Email,
  GivenName,
  MobileNumber,
  Surname
} from './topLevelFields';
import type { UpdateAccountDetailsFormFields } from './types';

type FormProps = {
  defaultValues: UpdateAccountDetailsFormFields;
  onCancel: () => void;
  onSubmit: (
    data: UpdateAccountDetailsFormFields,
    formState: { isDirty: boolean; isValid: boolean }
  ) => Promise<void | { field: string; errorMessage: string }>;
};

export const UpdateAccountDetailsForm = ({
  onCancel,
  defaultValues,
  onSubmit
}: FormProps) => {
  const { t } = useTranslation();
  const { handleResponseErrors } = useResponseErrorHandler();

  const {
    control,
    handleSubmit,
    trigger: triggerValidation,
    setError,
    formState: { isValid, isSubmitted, isDirty, errors }
  } = useForm<UpdateAccountDetailsFormFields>({
    defaultValues,
    mode: 'onBlur'
  });

  const submit = async (data: UpdateAccountDetailsFormFields) => {
    await triggerValidation();
    const errorResponse = await onSubmit(data, { isDirty, isValid });

    handleResponseErrors(errorResponse, setError);
  };

  return (
    <FormContext.Provider value={{ control, errors, t }}>
      <Stack spacing="xlarge">
        <Stack spacing="large">
          <GivenName />
          <Surname />
          <Email />
          <ConfirmEmail />
          <MobileNumber />
        </Stack>
        <Stack
          zIndex={-1}
          spacing="medium"
          direction={{ mobile: 'column', tablet: 'row', desktop: 'row' }}
        >
          <Button
            testID="update-account-details-submit"
            onPress={handleSubmit(submit)}
          >
            {t('updateAccountDetailsPageForm.action')}
          </Button>
          <Button variant="secondary" onPress={() => onCancel()}>
            {t('common.action.cancel')}
          </Button>
        </Stack>

        <Stack>
          {isSubmitted && !isValid && (
            <ErrorMessage>{t('validations.general.summary')}</ErrorMessage>
          )}
        </Stack>
      </Stack>
    </FormContext.Provider>
  );
};
