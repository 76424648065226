import { createElement, PureComponent } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './ImageWithTitle.scss';

export default class ImageWithTitle extends PureComponent {
  static propTypes = {
    svgComponent: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    mediumIcon: PropTypes.bool,
    smallIcon: PropTypes.bool,
    smallTitle: PropTypes.bool,
    children: PropTypes.node
  };

  render() {
    const { svgComponent, title, smallIcon, mediumIcon, smallTitle, children } = this.props;
    const conditionalIconClasses = {
      [styles.smallIcon]: smallIcon,
      [styles.mediumIcon]: mediumIcon
    };
    const conditionalTextClasses = {
      [styles.smallTitle]: smallTitle
    };

    return (
      <div className={styles.root}>
        {createElement(svgComponent, { className: classNames(styles.image, conditionalIconClasses) })}
        <label className={classNames(styles.text, conditionalTextClasses)}>{title}</label>
        { children }
      </div>
    );
  }
}