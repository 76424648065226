import React from 'react';
import { useTranslation } from 'react-i18next';
import { Trans as I18nextTrans } from 'react-i18next';
import { Box } from '@components/Box';
import { CalloutText } from '@components/CalloutText';
import { Link } from '@components/Link';
import { List } from '@components/List';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { useJobDescriptionCalloutDetails } from './useJobDescriptionCalloutDetails';

const FindOutMoreLink = () => {
  const { shouldRenderFindOutMoreLink, onPressFindOutMore } =
    useJobDescriptionCalloutDetails();

  if (!shouldRenderFindOutMoreLink) {
    return null;
  }

  return (
    <Box marginTop="small" tone="transparent">
      <Link onPress={() => onPressFindOutMore()}>Find out more</Link>
    </Box>
  );
};

export const JobDescriptionCalloutDetails = () => {
  const { t } = useTranslation();

  return (
    <Stack spacing="small">
      <CalloutText tone="warning" testID="bad-ad-tips-callout">
        {t('postJobForm.explanationText.jobDescription.badJobDescription')}
      </CalloutText>

      <CalloutText tone="info" testID="good-ad-tips-callout">
        <Stack spacing="xxsmall" tone="transparent" flexShrink={1}>
          <Text>
            {t(
              'postJobForm.explanationText.jobDescription.goodJobDescription.title'
            )}
          </Text>
          <I18nextTrans
            i18nKey={
              'postJobForm.explanationText.jobDescription.goodJobDescription.list'
            }
            components={{
              List: <List />,
              Text: <Text />
            }}
          />
          <Text>
            {t(
              'postJobForm.explanationText.jobDescription.jobShouldNotIncludeNumberAndEmail'
            )}
          </Text>

          <FindOutMoreLink />
        </Stack>
      </CalloutText>
    </Stack>
  );
};
