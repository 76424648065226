import React, { ReactElement, forwardRef } from 'react';
import { Text as ReactNativeText, View, ViewProps } from 'react-native';
import { Row } from '@components/Row';
import { BadgeStyleProps, useBadgeStyle } from './useBadgeStyle';

export type BadgeProps = {
  children: string;
  testID?: ViewProps['testID'];
  icon?: ReactElement;
} & BadgeStyleProps;

export const Badge = forwardRef<View, BadgeProps>((props, ref) => {
  const { children, testID, icon, ...restProps } = props;
  const { containerStyle, textStyle } = useBadgeStyle(restProps);

  return (
    <View style={{ ...containerStyle }} testID={testID} ref={ref}>
      <Row spacing="xsmall" tone="transparent" alignItems="center">
        {icon}
        <ReactNativeText style={{ ...textStyle }}>{children}</ReactNativeText>
      </Row>
    </View>
  );
});
