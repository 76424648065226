import { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorSummary from '../ErrorSummary/ErrorSummary';

export const FormProps = {
  valid: PropTypes.bool,
  submitFailed: PropTypes.bool,
  insideCard: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
  withRef: PropTypes.any,
  errorSummary: PropTypes.string
};

export default class Form extends Component {
  static propTypes = FormProps;

  handleKeyDown(e, handleSubmit) {
    if (e.target.nodeName !== 'TEXTAREA' && e.key === 'Enter' && e.shiftKey === false) {
      e.preventDefault();
      handleSubmit();
    }
  }

  render() {
    const { children, withRef, valid, submitFailed, onSubmit, insideCard, errorSummary, ...rest } = this.props;

    return (
      <form
        {...rest}
        ref={withRef}
        onSubmit={onSubmit}
        onKeyDown={(e) => this.handleKeyDown(e, onSubmit)}>
        {children}
        <ErrorSummary insideCard={insideCard} show={!valid && submitFailed} message={errorSummary} />
      </form>
    );
  }
}
