import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { GENERAL_EXCEPTION } from '@seek/je-error-helper';
import { BUSINESS_ROLE } from '@seek/je-shared-data';
import { CalloutText } from '@components/CalloutText';
import Div from '../../../../common/components/Div/Div';
import ScreenHeading from '../../../../common/components/ScreenHeading/ScreenHeading';
import CreateBusinessForm from '../../../components/CreateBusinessForm/CreateBusinessForm';
import { RedirectUnauthenticatedUser } from '../../../components/RedirectUnauthenticatedUser';
import { BUSINESS_ROLE_OPTIONS } from '../../../constants/data';
import { mapBusinessToBusinessFormValues } from '../../../constants/mapping';
import REQUEST_KEY from '../../../constants/requestKeys';
import * as businessActions from '../../../store/entities/businesses/actions';

@connect(
  ({ entities, localisation, auth, app }, ownProps) => {
    const businessId = ownProps.match.params.businessId;
    const business = entities.businesses.items[businessId];
    const initialValues = mapBusinessToBusinessFormValues(
      business,
      auth.currentUser.id
    );

    return {
      loadBusinessError: app.error[REQUEST_KEY.BUSINESS.LOAD],
      businessId,
      initialValues,
      countryCode: localisation.countryCode,
      currentUser: auth.currentUser
    };
  },
  {
    updateBusiness: businessActions.updateBusiness
  }
)
@withTranslation()
export default class EditBusiness extends PureComponent {
  static propTypes = {
    businessId: PropTypes.string,
    currentUser: PropTypes.object.isRequired,
    countryCode: PropTypes.string.isRequired,
    initialValues: PropTypes.object,
    updateBusiness: PropTypes.func.isRequired,
    loadBusinessError: PropTypes.object,
    t: PropTypes.func.isRequired
  };

  static async fetchData({ params, store }) {
    if (store.getState().auth.isAuthenticated) {
      await store.dispatch(businessActions.load(params.businessId));
    }
  }

  async submit(data) {
    const { t, businessId, updateBusiness } = this.props;
    await updateBusiness({
      data: {
        id: businessId,
        ...data
      },
      t
    });
  }

  bottomCalloutText() {
    const { t } = this.props;

    return (
      <Div marginTop="large">
        <CalloutText tone="warning">
          {t(
            'editBusinessPage.calloutText.changesToYourBusinessNameAndLocation'
          )}
        </CalloutText>
      </Div>
    );
  }

  getRoleOptions() {
    const { t, initialValues } = this.props;
    const whitelist = {
      [BUSINESS_ROLE.RECRUITER]: null,
      [BUSINESS_ROLE.OWNER]: [BUSINESS_ROLE.OWNER, BUSINESS_ROLE.EMPLOYEE],
      [BUSINESS_ROLE.EMPLOYEE]: [BUSINESS_ROLE.OWNER, BUSINESS_ROLE.EMPLOYEE]
    };

    const availableOptions = whitelist[initialValues.role];

    return (
      availableOptions &&
      BUSINESS_ROLE_OPTIONS(t).filter(({ key }) =>
        availableOptions.includes(key)
      )
    );
  }

  render() {
    const { t, businessId, countryCode, initialValues, loadBusinessError } =
      this.props;

    if (
      loadBusinessError &&
      loadBusinessError.message === GENERAL_EXCEPTION.NOT_FOUND
    ) {
      return <Redirect to={`/${countryCode}/businesses`} />;
    }

    return (
      <Div>
        <RedirectUnauthenticatedUser to={`/${countryCode}/login`} />
        <Helmet title={t('editBusinessPage.heading')} />
        <ScreenHeading data-test-key="edit-business-screen-heading">
          {t('editBusinessPage.title')}
        </ScreenHeading>

        {initialValues && (
          <CreateBusinessForm
            roleOptions={this.getRoleOptions()}
            initialValues={initialValues}
            businessId={businessId}
            requestKey={REQUEST_KEY.BUSINESS.EDIT}
            isSaveDraft={false}
            showCancelButton={true}
            submitAction={this.submit.bind(this)}
            submitButtonLabel={t('createBusinessForm.action.updateBusiness')}
            bottomCalloutText={this.bottomCalloutText()}
          />
        )}
      </Div>
    );
  }
}
