import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';

export const CoinTransactionsNote = () => {
  const { t } = useTranslation();

  return (
    <Stack
      tone="transparent"
      paddingHorizontal={{
        desktop: 'none',
        tablet: 'none',
        mobile: 'small'
      }}
    >
      <Text size="xsmall" tone="neutralLight">
        {t('myCoinsPage.transactions.note')}
      </Text>
    </Stack>
  );
};
