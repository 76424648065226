import { REGEX } from '@common/constants/data';
import { isFutureDate } from '@common/helpers/validation';
import type { T } from '@src/caja/Form/types';

export function jobStartDateRules(t: T) {
  return {
    validate: (value: string) => {
      if (!value) {
        return;
      }

      // putting this here instead of assigning it to the pattern property
      // because it's an inverse check of the regex
      if (!REGEX.VALID_DATE.test(value)) {
        return t('validations.job.startDate.valid');
      }

      if (value && value !== '' && !isFutureDate(value)) {
        return t('validations.job.startDate.future');
      }

      return;
    }
  };
}
