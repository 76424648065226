import { grey900 } from '../../theme/palette/colours';
import Svg, { Path } from 'svgs';

function SearchIcon(props) {
  return (
    <Svg
      fill="none"
      viewBox="0 0 18 18"
      {...props}
    >
      <Path
        d="M7.5 14.5a7 7 0 1 0 0-14 7 7 0 0 0 0 14zM12 13l5 4.5"
        stroke={grey900}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
      />
    </Svg>
  );
}

export default SearchIcon;
