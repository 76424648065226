import React from 'react';
import { InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';
import { Box } from '@components/Box';
import { Column } from '@components/Column';
import { Row } from '@components/Row';
import { Slider } from '@components/Slider';
import { Text } from '@components/Text';
import { PremiumFeaturesBadge } from '../../../../../PremiumFeaturesBadge';

const min = 1;
const max = 101;

export const DisabledDistanceFilter = () => {
  const { t } = useTranslation();

  return (
    <Column spacing="medium">
      <Row alignItems="center" spacing="medium">
        <Text weight="medium">
          {t('applications.applicationsFilter.distance.title')}
        </Text>
        <PremiumFeaturesBadge />
      </Row>

      <Box paddingLeft="small" paddingRight="large">
        <Row spacing="xxsmall" alignItems="center">
          <span className="text-sm font-light text-grey400">{t('Within')}</span>
          <div className="inputNumber">
            <InputNumber
              disabled={true}
              className="border-grey400 rounded-none text-sm focus:border-green700 focus:outline-none focus:ring-0 hover:border-grey400"
              style={{ width: '68px' }}
              min={min}
              max={max}
              size="large"
              controls={false}
              value={max}
              formatter={(val) => {
                if (`${val}` === `${max}`) return '100+';
                return `${val}`;
              }}
              onChange={() => {}}
            />
          </div>
          <span className="text-sm font-light text-grey400">{t('km')}</span>
        </Row>

        <Slider
          disabled={true}
          value={max}
          onChange={() => {}}
          min={min}
          max={max}
          marks={{
            1: {
              label: (
                <span className="text-sm font-light text-grey400">1km</span>
              )
            },
            101: {
              label: (
                <span className="text-sm font-light text-grey400">100km+</span>
              )
            }
          }}
        />
      </Box>
    </Column>
  );
};
