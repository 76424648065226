import React from 'react';
import { useCoinsFeature } from '@common/hooks/featureToggles';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { UpgradeableAdType } from '@src/job/adType';
import { CoinAdPanel, CurrencyAdPanel } from './variantComponents';

type AdUpgradePanelProps = {
  job: {
    id: string;
    countryCode: SupportedCountryCode;
  };
  getPaymentSuccessLink: (adType: UpgradeableAdType) => string;
  basicAdLink?: string;
  campaign?: string;
};

export const AdUpgradePanel = ({
  job,
  basicAdLink,
  campaign,
  getPaymentSuccessLink
}: AdUpgradePanelProps) => {
  const coinsEnabled = useCoinsFeature(job.countryCode);

  if (coinsEnabled) {
    return (
      <CoinAdPanel
        job={job}
        basicAdLink={basicAdLink}
        campaign={campaign}
        getPaymentSuccessLink={getPaymentSuccessLink}
      />
    );
  }

  return (
    <CurrencyAdPanel job={job} basicAdLink={basicAdLink} campaign={campaign} />
  );
};
