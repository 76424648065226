import { useAppDispatch, useAppSelector } from '@store/hooks';
import { refresh } from '@store/navigation/actions';
import { getIsRefreshing } from '@store/navigation/selectors';

export function useAppRefresh() {
  const isRefreshing = useAppSelector(getIsRefreshing);
  const dispatch = useAppDispatch();

  return {
    isRefreshing,
    refreshApp: () => dispatch(refresh())
  };
}
