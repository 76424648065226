/**
 * Creates a function that return modified url path by replacing last segment with new part.
 *
 * @param newPart - Part of the URL path that will replace last segment of the modified Url.
 * @param path - Fully qualified path contains country code.
 * @returns Modified Url path.
 */
export function modifyUrlPath(newPart: string, path: string): string {
  const parts = path.split('/');
  parts[parts.length - 1] = newPart;
  return parts.join('/');
}
