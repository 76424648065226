import React from 'react';
import { useTranslation } from 'react-i18next';
import { FilterIcon } from '@components/Icon';
import { Link } from '@components/Link';

export const FiltersLink = ({ onPressLink }: { onPressLink: () => void }) => {
  const { t } = useTranslation();

  return (
    <Link
      icon={<FilterIcon variant="outline" size="large" />}
      variant="secondary"
      size="standard"
      onPress={() => onPressLink()}
      testID="applications-filter-filter-link"
    >
      {t('applications.applicationsFilter.action.filter')}
    </Link>
  );
};
