import React, { useContext } from 'react';
import { AgreeToTerms as InnerField } from '@common/components/Account/components/AgreeToTerms';
import { termsAndPrivacyConsentRules } from '@common/components/Account/rules/AgreeToTerms';
import { FieldWrapper } from '../FieldWrapper';
import { FormContext } from '../FormContext';

type AgreeToTermsProps = {
  brandNameText: string;
};

export function AgreeToTerms({ brandNameText }: AgreeToTermsProps) {
  const { t } = useContext(FormContext);

  return (
    <FieldWrapper<boolean>
      name="termsAndPrivacyConsent"
      rules={termsAndPrivacyConsentRules(t, brandNameText)}
      render={(props) => <InnerField {...props} />}
    />
  );
}
