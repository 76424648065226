import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from '@components/Heading';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';

type SummaryProps = {
  summary: string;
  testID: string;
};

export const Summary = (props: SummaryProps) => {
  const { summary, testID } = props;
  const { t } = useTranslation();

  return (
    <Stack spacing="small" testID={testID}>
      <Row>
        <Heading level="h2">
          {t('applications.applicationCard.summary.title')}
        </Heading>
      </Row>
      <Row>
        <Text>{summary}</Text>
      </Row>
    </Stack>
  );
};
