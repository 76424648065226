import { createContext } from 'react';
import type { Control, FieldErrorsImpl } from 'react-hook-form';
import type { T } from '../../types';
import { IncompleteRegisterFormData } from './types';

interface FormContextProps {
  t: T;
  control: Control<IncompleteRegisterFormData>;
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
}

export const FormContext = createContext<FormContextProps>({
  control: {} as Control<IncompleteRegisterFormData>,
  errors: {},
  t: () => ''
});
