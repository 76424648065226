import React from 'react';
import { Trans as I18nextTrans, useTranslation } from 'react-i18next';
import { useGetPostJobLink } from '@common/hooks/appPageLinks/useGetPostJobLink';
import { useRedirect } from '@common/hooks/useRedirect';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation/selectors';
import { Box } from '@components/Box';
import { Button } from '@components/Button';
import { Card, CardContent } from '@components/Card';
import { Column } from '@components/Column';
import { Heading } from '@components/Heading';
import { JobSuccessIllustration } from '@components/Illustration';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';

export const TopRegistrationCard = () => {
  const { countryCode, brandNameText } = useAppSelector(getLocalisation);
  const { postJobLink } = useGetPostJobLink();
  const { t } = useTranslation();
  const { redirect } = useRedirect();

  return (
    <Card testID="top-registration-card">
      <CardContent>
        <Box marginTop="medium">
          <Heading level="h2">
            {t('employerLandingPage.getStartedCard.heading')}
          </Heading>
        </Box>
        <Stack
          marginTop="xsmall"
          direction={{ mobile: 'column', tablet: 'row', desktop: 'row' }}
        >
          <Column spacing="xsmall" flexShrink={1}>
            <Text>
              {t('employerLandingPage.getStartedCard.description', {
                brandNameText
              })}
            </Text>
            <I18nextTrans
              i18nKey="employerLandingPage.text.signUpForFreeToday"
              parent={Text}
              components={[<Text weight="medium" />]}
            />
            <Stack
              marginTop="xsmall"
              spacing="medium"
              direction={{
                mobile: 'column',
                tablet: 'row',
                desktop: 'row'
              }}
            >
              <Button
                testID="top-registration-card-sign-up-action"
                onPress={redirect(postJobLink)}
              >
                {t('employerLandingPage.action.signupNow')}
              </Button>
              <Button
                testID="top-registration-card-login-action"
                onPress={redirect(`/${countryCode}/login`)}
                variant="secondary"
              >
                {t('common.action.login')}
              </Button>
            </Stack>
          </Column>
          <Box
            marginLeft="medium"
            visible={{ mobile: false, tablet: true, desktop: true }}
          >
            <JobSuccessIllustration />
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};
