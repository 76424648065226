import React from 'react';
import { NPS } from '@common/components/NPS';
import { When } from '@common/components/When';
import type { Application } from '@common/types';
import { Stack } from '@components/Stack';
import { ApplicationCard } from '../ApplicationCard';
import { useNps } from './useApplicationList';

interface ApplicationListItemProps {
  jobCountryCode: string;
  application: Application;
  index: number;
}
export const ApplicationListItem = ({
  jobCountryCode,
  application,
  index
}: ApplicationListItemProps) => {
  const { shouldRenderNPS, npsTrackingData } = useNps(application.jobId);
  return (
    <Stack key={`${application.id}-card`} tone="transparent">
      <ApplicationCard
        jobId={application.jobId}
        jobCountryCode={jobCountryCode}
        application={application}
      />
      <When condition={Boolean(shouldRenderNPS(index))}>
        <NPS trackingData={npsTrackingData} />
      </When>
    </Stack>
  );
};
