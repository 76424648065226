import { useTheme } from '@theme/DesignSystemProvider/ThemeContext';
import { useResolveToken } from '@theme/tokens/utils/useResolveToken';

type Variant = 'primary' | 'secondary';
export type ButtonState = 'default' | 'active' | 'hover' | 'disabled';
export type ButtonStyleProps = {
  variant?: Variant;
  state?: ButtonState;
};

export function useButtonStyle({
  variant = 'primary',
  state = 'default'
}: ButtonStyleProps) {
  const { border, button } = useTheme();
  const { resolveToken } = useResolveToken();
  const { backgroundColor, borderColor } = button.variant[variant][state];

  const defaultButtonStyle = {
    borderRadius: 'xsmall',
    borderWidth: 'xsmall',
    borderStyle: 'solid',
    justifyContent: 'center'
  } as const;

  return {
    backgroundColor,
    borderColor,
    borderRadius: resolveToken(border.radius, defaultButtonStyle.borderRadius),
    borderStyle: defaultButtonStyle.borderStyle,
    borderWidth: resolveToken(border.width, defaultButtonStyle.borderWidth),
    justifyContent: defaultButtonStyle.justifyContent
  };
}

export function useTextStyle({
  variant = 'primary',
  state = 'default'
}: ButtonStyleProps) {
  const { button, typography } = useTheme();
  const { resolveToken } = useResolveToken();
  const { fontColor } = button.variant[variant][state];
  const {
    size: textSize,
    lineHeight: textLineHeight,
    letterSpacing: textLetterSpacing
  } = resolveToken(typography.text, 'standard') || {};

  const defaultButtonTypography = {
    weight: 'regular',
    textAlign: 'center'
  } as const;

  return {
    fontFamily: typography.fontFamily,
    color: fontColor,
    fontSize: resolveToken(typography.fontSizes, textSize),
    fontWeight: resolveToken(
      typography.fontWeights,
      defaultButtonTypography.weight
    ),
    lineHeight: resolveToken(typography.lineHeights, textLineHeight),
    letterSpacing: resolveToken(typography.letterSpacing, textLetterSpacing),
    textAlign: defaultButtonTypography.textAlign
  };
}
