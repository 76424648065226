import {
  AVAILABILITY,
  DAY,
  SHIFT_AVAILABILITY_TYPE
} from '@seek/je-shared-data/lib/types/jobs';
import { ShiftAvailability } from '@seek/je-shared-data/lib/types/jobs';
import {
  OnAllCheckboxPressedType,
  OnCheckboxPressedType,
  SpecificShiftAvailabilityType
} from '../types';

const { MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY } = DAY;
const { MORNING, AFTERNOON, EVENING } = AVAILABILITY;

const defaultSpecificShiftAvailability = {
  [MONDAY]: [],
  [TUESDAY]: [],
  [WEDNESDAY]: [],
  [THURSDAY]: [],
  [FRIDAY]: [],
  [SATURDAY]: [],
  [SUNDAY]: []
};

export const useSpecificShiftAvailability = (
  value: ShiftAvailability,
  onChange: (value: ShiftAvailability) => void
) => {
  const selectedAvailability: SpecificShiftAvailabilityType =
    value?.specificShiftAvailability || defaultSpecificShiftAvailability;

  const onCheckboxPressed = ({
    day,
    availability,
    isSelected
  }: OnCheckboxPressedType) => {
    let updatedDayAvailability;

    if (isSelected) {
      updatedDayAvailability = addAvailability({
        originalAvailability: selectedAvailability[day],
        availabilityToAdd: availability
      });
    } else {
      updatedDayAvailability = removeAvailability({
        originalAvailability: selectedAvailability[day],
        availabilityToRemove: availability
      });
    }

    const updatedSpecificShiftAvailability = {
      ...selectedAvailability,
      [day]: updatedDayAvailability
    };

    onChange({
      ...value,
      type: SHIFT_AVAILABILITY_TYPE.SPECIFIC,
      specificShiftAvailability: updatedSpecificShiftAvailability
    });
  };

  const onAllCheckboxPressed = ({
    availability,
    isSelected
  }: OnAllCheckboxPressedType) => {
    const updatedSpecificShiftAvailability = selectedAvailability;

    Object.keys(selectedAvailability).forEach((day) => {
      if (isSelected) {
        updatedSpecificShiftAvailability[day] = addAvailability({
          originalAvailability: selectedAvailability[day],
          availabilityToAdd: availability
        });
      } else {
        updatedSpecificShiftAvailability[day] = removeAvailability({
          originalAvailability: selectedAvailability[day],
          availabilityToRemove: availability
        });
      }
    });

    onChange({
      ...value,
      type: SHIFT_AVAILABILITY_TYPE.SPECIFIC,
      specificShiftAvailability: updatedSpecificShiftAvailability
    });
  };

  return {
    onCheckboxPressed,
    onAllCheckboxPressed,
    selectedAvailability,
    selectedAllAvailability: getAllAvailabilityValue(selectedAvailability)
  };
};

const getAllAvailabilityValue = (
  specificShiftAvailability: SpecificShiftAvailabilityType
) => {
  const allOptionValue = {
    [MORNING]: false,
    [AFTERNOON]: false,
    [EVENING]: false
  };

  const checkContainsAllAvailability = (availability) =>
    Object.keys(specificShiftAvailability).every((day) =>
      specificShiftAvailability[day].includes(availability)
    );

  Object.keys(allOptionValue).forEach((availability) => {
    allOptionValue[availability] = checkContainsAllAvailability(availability);
  });

  return allOptionValue;
};

const addAvailability = ({ originalAvailability, availabilityToAdd }) => {
  return [...new Set([...originalAvailability, availabilityToAdd])];
};

const removeAvailability = ({ originalAvailability, availabilityToRemove }) => {
  return originalAvailability.filter(
    (currentAvailability) => currentAvailability !== availabilityToRemove
  );
};
