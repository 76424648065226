export type AddressPart = google.maps.GeocoderAddressComponent;

function formattedKeyName(keyName: string): string {
  return keyName.replace(/_([a-z,0-9])/g, (g) => g[1].toUpperCase());
}

function getKeyName(
  addressPart: AddressPart,
  searchString: string
): string | undefined {
  return addressPart.types.find((type) => type.match(searchString));
}

export function getAdministrativeAreaLevels(
  addressParts: google.maps.GeocoderAddressComponent[]
): Record<string, string> {
  const keyNamePartial = 'administrative_area_level';

  return addressParts.reduce((agg, addressPart) => {
    const keyName = getKeyName(addressPart, keyNamePartial);
    if (!keyName) return agg;

    return { ...agg, [formattedKeyName(keyName)]: addressPart.long_name };
  }, {});
}
