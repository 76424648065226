import { grey900 } from '../../theme/palette/colours';
import Svg, { Path } from 'svgs';

function EyeSlashIcon(props) {
  return (
    <Svg viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M6.5 9.5C4.05698 11.1854 2.5 13.2869 2.5 13.2869C2.5 13.2869 6.47272 17.5 12 17.5C13.0633 17.5 14.569 17.2518 15.5 17M9.5 7.88961C10.2879 7.6452 11.1245 7.5 12 7.5C17.5273 7.5 21.5 13.2869 21.5 13.2869C21.5 13.2869 20.3792 14.3855 18.5 15.5"
        stroke={grey900}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"/>
      <Path
        d="M8.64494 11.5C8.55064 11.8169 8.5 12.1525 8.5 12.5C8.5 14.433 10.067 16 12 16C12.5368 16 13.0454 15.8792 13.5 15.6632M11 9.14494C11.3169 9.05064 11.6525 9 12 9C13.933 9 15.5 10.567 15.5 12.5C15.5 12.7797 15.4672 13.0518 15.4052 13.3126"
        stroke={grey900}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"/>
      <Path
        d="M5.5 6.5L19.5 18.5"
        stroke={grey900}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"/>
    </Svg>
  );
}

export default EyeSlashIcon;
