interface DeletableItem {
  deletedAt?: string;
}

/**
 * Removes deleted items from the given array of obejcts provided it has a `deletedAt` property.
 * @param items - The array of deletable items.
 * @returns The array of items without deleted items.
 */
export function removeDeleted(items: DeletableItem[]) {
  return items.filter((item) => !item.deletedAt);
}
