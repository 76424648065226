import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Trans as I18nextTrans } from 'react-i18next';
import { CajaContext } from '@src/caja/context';
import { Button } from '@components/Button';
import { Heading } from '@components/Heading';
import { VerifyEmailIllustration } from '@components/Illustration';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { TextLink } from '@components/TextLink';
import { FormContext } from './FormContext';
import { EmailVerificationCode } from './topLevelFields';
import type { CompleteVerifyFormData } from './types';

type FormProps = {
  onResend: () => void;
  onSubmit: (data: CompleteVerifyFormData) => void;
};

export default function VerifyEmailForm({ onResend, onSubmit }: FormProps) {
  const { userEmail, t } = useContext(CajaContext);

  const {
    control,
    handleSubmit,
    trigger: triggerValidation,
    formState: { errors }
  } = useForm<CompleteVerifyFormData>({
    mode: 'onBlur'
  });

  const submit = async (data: CompleteVerifyFormData) => {
    await triggerValidation();
    onSubmit(data);
  };

  return (
    <FormContext.Provider value={{ control, errors, t }}>
      <Stack alignItems="center" spacing="medium" marginBottom={'small'}>
        <VerifyEmailIllustration size="large" />
        <Heading level="h1" weight="regular" align="center">
          {t('verifyEmailPage.description.confirmEmail')}
        </Heading>
        <Text align="center">
          <I18nextTrans
            extraTopMargin="small"
            parent={Text}
            i18nKey="verifyEmailPage.description.emailSent"
            values={{ email: userEmail }}
            components={[<Text weight="regular" />]}
          />
        </Text>
      </Stack>
      <Stack spacing="large">
        <EmailVerificationCode />
      </Stack>
      <Stack
        paddingTop="large"
        spacing="medium"
        direction={{ mobile: 'column', tablet: 'row', desktop: 'row' }}
        alignItems={{ mobile: 'stretch', tablet: 'center', desktop: 'center' }}
      >
        <Button onPress={handleSubmit(submit)} testID="verify-email-action">
          {t('verifyEmailForm.action.submit')}
        </Button>
        <Stack alignItems={'center'}>
          <TextLink
            variant="primary"
            onPress={onResend}
            testID="resend-email-action"
          >
            {t('verifyEmailForm.action.emailResend')}
          </TextLink>
        </Stack>
      </Stack>
    </FormContext.Provider>
  );
}
