import { useBrandName } from '@common/hooks/localisation';
import { BrandName } from '@seek/je-shared-data/lib/types/brand';

export const useLandingPage = () => {
  const brand = useBrandName();

  if (brand === BrandName.JOBSTREETEXPRESS) return false;

  return true;
};
