import React from 'react';
import { Trans as I18nextTrans } from 'react-i18next';
import { CalloutText } from '@components/CalloutText';
import { Text } from '@components/Text';
import { TextLink } from '@components/TextLink';
import { useUpgradeJobCalloutText } from './useUpgradeJobCalloutText';

export const UpgradeJobCalloutText = () => {
  const { redirectToCandiFaqPage } = useUpgradeJobCalloutText();

  return (
    <CalloutText tone="info">
      <I18nextTrans
        i18nKey={
          'jobDetailsAndApplicationsListPage.calloutText.attractMoreApplicantsJobUpgrade'
        }
        parent={Text}
        components={[<TextLink onPress={redirectToCandiFaqPage} />]}
      />
    </CalloutText>
  );
};
