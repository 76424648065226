interface ShowFooterParams {
  inNativeApp: boolean;
  pathname: string;
}

export function showFooter({ pathname, inNativeApp }: ShowFooterParams) {
  if (!inNativeApp) return true;
  return lastPartOfPath(pathname) === 'account';
}

function lastPartOfPath(pathname: string): string {
  const parts = pathname.split('/').pop()?.split('?');
  return parts ? parts[0] : '';
}
