import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <>
    <Path
      d="M13.267 4.712h2.441v2.432H8.29V4.712h2.464"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M16 6h2v14.5H6V6h2M10.765 4.712c0-.672.556-1.212 1.236-1.212.68 0 1.237.547 1.237 1.212"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M8.382 9.38h7.238M8.382 12.38h7.238M8.38 15.38h7.238M8.38 18.32h7.238"
      strokeWidth={0.75}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const JobsIcon = createIcon({ viewBox: '3 3 18 18', vectorComponent });
