import React from 'react';
import { JobPostingNotAvailable } from '@common/components/JobPostingNotAvailable';
import { RegistrationProgressIndicator } from '@common/components/RegistrationProgressIndicator';
import { useSiteCountryCode } from '@common/hooks/localisation';
import { Box } from '@components/Box';
import { Card, CardContent } from '@components/Card';
import { Heading } from '@components/Heading';
import { Stack } from '@components/Stack';
import type { T } from './Form/types';
import { stepToProgressPath, stepToTitleKey } from './steps';
import { Step, StepMap } from './steps/types';

// CAJA Wrapper - generic for all flows??

export interface CajaWrapperProps {
  children: React.ReactNode;
  disablePosting?: boolean;
  showProgress?: boolean;
  step: Step;
  stepMap: StepMap;
  t: T;
}

export default function CajaWrapper(props: CajaWrapperProps) {
  const countryCode = useSiteCountryCode();
  if (props.disablePosting) return <JobPostingNotAvailable />;

  return (
    <Stack spacing="medium" tone="transparent">
      <Box paddingTop="medium" paddingLeft="medium" tone="transparent">
        <Heading level="h1">
          {props.t(stepToTitleKey(props.step, props.stepMap))}
        </Heading>
      </Box>
      <Card>
        <CardContent>
          <Stack spacing="large">
            {props.showProgress ? (
              <Box alignSelf="center">
                <RegistrationProgressIndicator
                  currentPathOverride={stepToProgressPath(
                    countryCode,
                    props.step
                  )}
                />
              </Box>
            ) : null}
            {props.children}
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
}
