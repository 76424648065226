import { TextStyle } from 'react-native';
import { useTheme } from '@theme/DesignSystemProvider/ThemeContext';
import { FontWeight } from '@theme/tokens/typography';
import { useResolveToken } from '@theme/tokens/utils/useResolveToken';
import { Tone } from '@theme/types';

export type HeadingStyleProps = {
  level: 'h1' | 'h2';
  tone?: Tone;
  weight?: FontWeight;
  align?: TextStyle['textAlign'];
};

export function useHeadingStyle({
  align,
  level,
  tone = 'neutral',
  weight = 'regular'
}: HeadingStyleProps) {
  const {
    colors: { foreground },
    typography: {
      fontFamily,
      heading,
      fontSizes,
      fontWeights,
      lineHeights,
      letterSpacing
    }
  } = useTheme();
  const { resolveToken } = useResolveToken();

  const {
    size: textSize,
    lineHeight: textLineHeight,
    letterSpacing: textLetterSpacing
  } = resolveToken(heading, level) || {};

  return {
    fontFamily,
    fontSize: resolveToken(fontSizes, textSize),
    lineHeight: resolveToken(lineHeights, textLineHeight),
    fontWeight: resolveToken(fontWeights, weight),
    letterSpacing: resolveToken(letterSpacing, textLetterSpacing),
    color: resolveToken(foreground, tone),
    textAlign: align
  };
}
