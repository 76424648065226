import { CompleteRegisterFormData } from '@src/caja/Form/forms/registerForm/types';
import { register as registerAction } from '@store/auth';
import { ErrorResponse } from '@store/auth/types';
import { useAppDispatch } from '@store/hooks';

type RegisterProps = {
  registerData: CompleteRegisterFormData;
  sourceParams: Record<string, string>;
};

type RegisterResponse = {
  isSuccessful: boolean;
  error?: ErrorResponse;
};

function getMobileNumber({
  diallingCode,
  phoneNumber
}: CompleteRegisterFormData['mobileNumber']) {
  return `${diallingCode}${phoneNumber}`;
}

const toRegisterRequest = ({ registerData: data, sourceParams }) => ({
  mobile: getMobileNumber(data.mobileNumber),
  password: data.password,
  email: data.email,
  givenName: data.givenName,
  surname: data.surname,
  recaptchaResponse: data.recaptchaResponse,
  expressVerificationToken: sourceParams.ev,
  impersonatorId: sourceParams.impersonatorId,
  UTM_Source: sourceParams.utm_source,
  UTM_Medium: sourceParams.utm_medium,
  UTM_Campaign: sourceParams.utm_campaign
});

export const useRegister = () => {
  const dispatch = useAppDispatch();

  return async ({
    registerData,
    sourceParams
  }: RegisterProps): Promise<RegisterResponse> => {
    const registerRequestData = toRegisterRequest({
      registerData,
      sourceParams
    });

    const response = await dispatch(
      registerAction(registerRequestData, 'jora-employer-register-form')
    );

    return { isSuccessful: !response?.error, error: response?.error };
  };
};
