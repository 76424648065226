import PropTypes from 'prop-types';
import { green700, white } from '../../theme/palette/colours';
import Svg, { Path } from 'svgs';

function getFill(theme) {
  switch (theme) {
    case 'green':
      return green700;
    default:
      return 'none';
  }
}

function getOutline(theme) {
  switch (theme) {
    case 'green':
      return white;
    default:
      return green700;
  }
}

function SuccessIcon(props) {
  return (
    <Svg viewBox="3 3 18 18" fill={getFill(props.theme)} {...props}>
      <Path
        d="M12 20.5a8.5 8.5 0 100-17 8.5 8.5 0 000 17z"
        stroke={getOutline(props.theme)}
        strokeMiterlimit={10}
      />
      <Path
        d="M7.5 13.5l3 2 6-7"
        stroke={getOutline(props.theme)}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

SuccessIcon.propTypes = {
  theme: PropTypes.string
};

export default SuccessIcon;
