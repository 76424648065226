import { useTranslation } from 'react-i18next';
import { localisedFormatDate } from '@common/helpers/dateTime';
import { JOB_STATUS } from '@seek/je-shared-data';
import {
  getApplicationAvailability,
  getTotalApplicationCount
} from '@store/entities/applications/selectors';
import { getJobById } from '@store/entities/jobs/selectors';
import { useAppSelector } from '@store/hooks';

export function useApplicationsExpiryCalloutDetails(jobId: string) {
  const { t } = useTranslation();
  const { status } = useAppSelector(getJobById(jobId)) || {};
  const { available, unavailableAt } =
    useAppSelector(getApplicationAvailability(jobId)) || {};
  const totalApplicationCount =
    useAppSelector(getTotalApplicationCount(jobId)) || 0;

  const shouldRenderWarning =
    status === JOB_STATUS.EXPIRED && available && totalApplicationCount > 0;

  const expiryCalloutText = t('applications.preExpiryWarning', {
    date: localisedFormatDate(t, 'dd MMM yyyy', unavailableAt)
  });

  return {
    shouldRenderWarning,
    expiryCalloutText
  };
}
