import { useCallback, useEffect, useState } from 'react';
import { formatStringToTitleCase } from '@common/helpers/textFormatting';
import { useBrandName, useSiteCountryCode } from '@common/hooks/localisation';
import { Suggestion } from '@components/FormElements/AutoSuggest';
import fetchJobTitles from './fetchJobTitles';

const filterJobTitle = (jobTitle: string, searchTerm: string) =>
  jobTitle.toLowerCase().includes(searchTerm.toLowerCase());

const toAutoSuggestValue = (
  { jobTitle, role }: { jobTitle: string; role: string },
  index: number
): Suggestion => ({
  key: `${index}`,
  displayText: formatStringToTitleCase(jobTitle),
  data: {
    jobTitle: formatStringToTitleCase(jobTitle),
    role
  }
});

export default function useTitleSuggestions() {
  const brandName = useBrandName();
  const countryCode = useSiteCountryCode();
  const [jobTitles, setJobTitles] = useState<
    { jobTitle: string; role: string }[]
  >([]);

  useEffect(() => {
    async function loadJobTitles() {
      try {
        const jobTitleData = await fetchJobTitles({ brandName, countryCode });
        setJobTitles(jobTitleData);
      } catch (error) {
        console.error(
          `Failed to load job titles for: ${brandName}-${countryCode}`,
          error
        );
      }
    }

    loadJobTitles();
  }, [brandName, countryCode]);

  const getSuggestions = useCallback(
    (searchTerm: string): Suggestion[] => {
      return jobTitles
        .filter(({ jobTitle }) => filterJobTitle(jobTitle, searchTerm))
        .map(toAutoSuggestValue);
    },
    [jobTitles]
  );

  const isJobTitleInvalid = (jobTitle: string) => {
    if (!jobTitle) return false;
    const formattedJobTitle = jobTitle.toLowerCase();
    return !jobTitles.some(
      ({ jobTitle: title }) => title.toLowerCase() === formattedJobTitle
    );
  };

  return { getSuggestions, isJobTitleInvalid };
}
