import { Braze } from '@src/nativeBridge/actions';
import { AUTHENTICATED, LOGOUT_SUCCESS } from '../auth/constants';

export const nativeBrazeMiddleware = () => (next) => (action) => {
  switch (action.type) {
    case AUTHENTICATED:
      Braze.enableSDK();
      Braze.changeUser(action?.result?.id);
      break;
    case LOGOUT_SUCCESS:
      Braze.disableSDK();
      break;
  }

  return next(action);
};
