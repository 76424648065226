import React from 'react';
import { useTranslation } from 'react-i18next';
import { Else, If, Then } from '@common/components/If';
import { LINK, useCandidateLink } from '@common/hooks/useCandidateLink';
import { useRedirect } from '@common/hooks/useRedirect';
import { SupportLevel } from '@seek/je-shared-data/lib/types/brand';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';
import { Link } from '@components/Link';

export const ForgotPasswordHelpLink = () => {
  const { t } = useTranslation();
  const { supportTier } = useAppSelector(getLocalisation);

  const { redirect } = useRedirect();

  const helpPage =
    supportTier === SupportLevel.TIER_1
      ? LINK.CONTACT_PAGE
      : LINK.FAQ_EMPLOYER_PAGE;

  const { getCandidateLink } = useCandidateLink();
  const candidateLink = getCandidateLink(helpPage);

  return (
    <If condition={supportTier === SupportLevel.TIER_1}>
      <Then>
        <Link
          onPress={redirect(candidateLink)}
          testID="forgot-password-success-contact-us"
        >
          {t('common.linkText.contactUs')}
        </Link>
      </Then>
      <Else>
        <Link
          onPress={redirect(candidateLink)}
          testID="forgot-password-success-faqs"
        >
          {t('common.linkText.faqs')}
        </Link>
      </Else>
    </If>
  );
};
