import React from 'react';
import { getStatusTone } from '@common/helpers/jobs';
import { JOB_STATUS } from '@seek/je-shared-data';
import { Divider } from '@components/Divider';

type JobCardStatusProps = {
  status: JOB_STATUS;
};

export const JobCardStatus = ({ status }: JobCardStatusProps) => (
  <Divider tone={getStatusTone(status)} direction="vertical" width="large" />
);
