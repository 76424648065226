import Svg, { Path } from 'svgs';

function LinkedinIcon(props) {
  return (
    <Svg
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M17.5 34.7143C26.9678 34.7143 34.6429 27.0392 34.6429 17.5714C34.6429 8.10371 26.9678 0.428589 17.5 0.428589C8.03228 0.428589 0.357162 8.10371 0.357162 17.5714C0.357162 27.0392 8.03228 34.7143 17.5 34.7143ZM13.8483 25.5892H10.7182V14.1123H13.8483V25.5892ZM12.2833 12.7894C11.2754 12.7894 10.4574 11.9651 10.4574 10.9489C10.4574 9.93266 11.2754 9.10841 12.2833 9.10841C13.2911 9.10841 14.1091 9.93266 14.1091 10.9489C14.1091 11.9651 13.2922 12.7894 12.2833 12.7894ZM26.3685 25.5892H23.2385V19.7423C23.2385 16.2282 19.065 16.4943 19.065 19.7423V25.5892H15.935V14.1123H19.065V15.9538C20.5216 13.2557 26.3685 13.0565 26.3685 18.5372V25.5892Z"
        fill="#2867B2"
      />
    </Svg>
  );
}

export default LinkedinIcon;
