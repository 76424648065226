import React from 'react';
import { useTranslation } from 'react-i18next';
import { localisedFormatDate } from '@common/helpers/dateTime';
import { ApplicationStatus } from '@common/types';
import { Box } from '@components/Box';
import { Row } from '@components/Row';
import { Text } from '@components/Text';
import { ApplicantStatusBadge } from '../ApplicantStatusBadge';

type Props = {
  date: string;
  applicantId: string;
  applicantStatus: ApplicationStatus;
};

export const CardDateAndApplicantStatusBadge = ({
  date,
  applicantId,
  applicantStatus
}: Props) => {
  const { t } = useTranslation();

  return (
    <Row justifyContent="center" alignItems="center">
      <Box
        borderWidth="xsmall"
        tone="neutral"
        paddingHorizontal="medium"
        paddingVertical="xxsmall"
      >
        <Text tone="neutralLight" size="small">
          {t('applications.applicationCard.appliedOn', {
            date: localisedFormatDate(t, 'dd MMM yyyy', date)
          })}
        </Text>
      </Box>
      <ApplicantStatusBadge
        testID={`${applicantId}-applicant-status-badge`}
        status={applicantStatus}
      />
    </Row>
  );
};
