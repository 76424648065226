import React from 'react';
import { ConfirmEmail as InnerField } from '@common/components/Account/components/ConfirmEmail';
import { confirmEmail } from '@common/components/Account/rules';
import { FieldWrapper } from '../FieldWrapper';

export const ConfirmEmail = () => {
  return (
    <FieldWrapper<string>
      name="confirmEmail"
      rules={confirmEmail}
      render={(props) => {
        return <InnerField {...props} />;
      }}
    />
  );
};
