import { getErrorMessage } from '@common/helpers/responseData';
import * as constants from './constants';
import { NavigationAction } from './types';

const initialState = {
  isRefreshing: false
};

export default function reducer(
  state = initialState,
  action: Partial<NavigationAction> = {}
) {
  switch (action.type) {
    case constants.REFRESH:
      return {
        ...state,
        error: null,
        isRefreshing: true
      };
    case constants.REFRESH_SUCCESS:
      return {
        ...state,
        error: null,
        isRefreshing: false
      };
    case constants.REFRESH_FAIL:
      return {
        ...state,
        errors: getErrorMessage(action.error),
        isRefreshing: false
      };
    default:
      return state;
  }
}
