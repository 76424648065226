import { openExternalURL } from '@common/helpers/externalConnection';
import { LINK, useCandidateLink } from '@common/hooks/useCandidateLink';

// TODO: This should be a common hook when multiple components are using this
export function useRedirectCandidateLinks() {
  const { getCandidateLink } = useCandidateLink();
  const candidateLink = getCandidateLink(LINK.FAQ_EMPLOYER_PAGE);

  return {
    redirectToEmployerFaq: () => openExternalURL(candidateLink)
  };
}
