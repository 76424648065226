import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import querystring from 'query-string';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import { RegistrationProgressIndicator } from '@common/components/RegistrationProgressIndicator';
import { BUSINESS_ROLE_OPTIONS } from '@common/constants/data';
import REQUEST_KEY from '@common/constants/requestKeys';
import { REDIRECT_TO } from '@common/search';
import * as businessActions from '@store/entities/businesses/actions';
import * as jobActions from '@store/entities/jobs/actions';
import { Box } from '@components/Box';
import { Stack } from '@components/Stack';
import Div from '../../../../common/components/Div/Div';
import ScreenHeading from '../../../../common/components/ScreenHeading/ScreenHeading';
import CreateBusinessForm from '../../../components/CreateBusinessForm/CreateBusinessForm';

@withTranslation()
@connect(
  ({ app, draft, localisation, auth, router }) => ({
    initialValues: {
      country: localisation.countryCode,
      ...(draft.business || {})
    },
    countryCode: localisation.countryCode,
    currentUser: auth.currentUser,
    job: draft.job,
    isJobPostingError: !!app.error[REQUEST_KEY.JOB.CREATE],
    isAuthenticated: auth.isAuthenticated,
    shouldRedirectTo: querystring.parse(router?.location?.search, {
      parseBooleans: true
    }).shouldRedirectTo,
    jobId: querystring.parse(router?.location?.search, { parseBooleans: true })
      .jobId,
    shouldCreateJob: !!querystring.parse(router?.location?.search, {
      parseBooleans: true
    }).shouldCreateJob,
    expressVerificationToken: querystring.parse(router.location?.search).ev
  }),
  {
    createBusiness: businessActions.createBusiness,
    postJob: jobActions.postJob,
    pushRoute: push
  }
)
export default class CreateBusiness extends PureComponent {
  static propTypes = {
    createBusiness: PropTypes.func.isRequired,
    postJob: PropTypes.func.isRequired,
    countryCode: PropTypes.string.isRequired,
    pushRoute: PropTypes.func.isRequired,
    currentUser: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    shouldCreateJob: PropTypes.bool,
    shouldRedirectTo: PropTypes.string,
    jobId: PropTypes.string,
    job: PropTypes.object,
    isJobPostingError: PropTypes.bool,
    initialValues: PropTypes.object,
    expressVerificationToken: PropTypes.string,
    t: PropTypes.func.isRequired
  };

  async submit(data) {
    const {
      createBusiness,
      countryCode,
      pushRoute,
      shouldRedirectTo,
      jobId,
      shouldCreateJob,
      postJob,
      currentUser,
      job: jobData
    } = this.props;
    const { t } = this.props;
    const business = (await createBusiness(data)).result;

    if (business) {
      const businessIdSelectedQueryString = `selectedBusinessId=${business.businessId}`;

      if (shouldCreateJob) {
        const { name, recruitmentCompanyName, businessAddress } = data;
        const businessData = {
          id: business.businessId,
          name,
          geolocation: businessAddress,
          recruitmentCompanyName,
          token: business.token
        };

        await postJob({ ...jobData, businessData, t, userData: currentUser });

        if (this.props.isJobPostingError) {
          await pushRoute(
            `/${countryCode}/post-job?${businessIdSelectedQueryString}`
          );
        }
      } else if (shouldRedirectTo === REDIRECT_TO.postJob) {
        await pushRoute(
          `/${countryCode}/post-job?${businessIdSelectedQueryString}`
        );
      } else if (shouldRedirectTo === REDIRECT_TO.repostJob) {
        await pushRoute(
          `/${countryCode}/jobs/${jobId}/repost-job?${businessIdSelectedQueryString}`
        );
      } else {
        pushRoute({ pathname: `/${countryCode}/businesses` });
      }
    }
  }

  async next() {
    const { pushRoute, countryCode, expressVerificationToken } = this.props;
    const search = expressVerificationToken?.length
      ? `?ev=${expressVerificationToken}`
      : '';

    await pushRoute({
      pathname: `/${countryCode}/register`,
      search
    });
  }

  render() {
    const { t, isAuthenticated, shouldCreateJob, initialValues } = this.props;
    const submitAction = isAuthenticated ? this.submit : this.next;

    const authenticatedSubmitLabel = shouldCreateJob
      ? t('postJobForm.action.postJob')
      : t('createBusinessForm.action.addNewBusiness');
    const submitButtonLabel = isAuthenticated
      ? authenticatedSubmitLabel
      : t('common.action.next');

    const title = shouldCreateJob
      ? t('createBusinessPage.title.addNewBusinessToJobAd')
      : t('createBusinessPage.title.addNewBusiness');
    const heading = shouldCreateJob
      ? t('createBusinessPage.heading.addNewBusinessToJobAd')
      : t('createBusinessPage.heading.addNewBusiness');

    return (
      <Div>
        <Helmet title={title} />
        <ScreenHeading data-test-key="create-business-screen-heading">
          {heading}
        </ScreenHeading>

        <Stack space="medium" paddingTop={!isAuthenticated ? 'large' : 'none'}>
          {!isAuthenticated && (
            <Box alignSelf="center">
              <RegistrationProgressIndicator />
            </Box>
          )}

          <CreateBusinessForm
            requestKey={REQUEST_KEY.BUSINESS.CREATE}
            roleOptions={BUSINESS_ROLE_OPTIONS(t)}
            initialValues={initialValues}
            isSaveDraft={true}
            submitAction={submitAction.bind(this)}
            submitButtonLabel={submitButtonLabel}
          />
        </Stack>
      </Div>
    );
  }
}
