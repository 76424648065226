import React, { forwardRef } from 'react';
import { ModalProps as RNModalProps, ScrollView, View } from 'react-native';
import { ModalStyleProps, useModalStyle } from './useModalStyle';

export type ScrollableModalContentProps = Pick<
  ModalStyleProps,
  'height' | 'hasFooter'
> & {
  children?: React.ReactNode;
  testID?: RNModalProps['testID'];
};

export const ScrollableModalContent = forwardRef<
  ScrollView,
  ScrollableModalContentProps
>((props, ref) => {
  const { children, ...restProps } = props;
  const { scrollableContentStyle } = useModalStyle(restProps);

  return (
    <ScrollView style={scrollableContentStyle} bounces={false} ref={ref}>
      <View onStartShouldSetResponder={() => true}>{children}</View>
    </ScrollView>
  );
});
