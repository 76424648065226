import React from 'react';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { Box } from '@components/Box';
import { Field, Input } from '@components/FormElements';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';

type BusinessWebsiteProps = {
  value: string;
  onChange: () => void;
  onBlur: () => void;
  label: string;
  helperText: string;
  error?: string;
};

export const BusinessWebsite = (props: BusinessWebsiteProps) => {
  const { error, onChange, onBlur, value, label, helperText } = props;

  return (
    <Stack spacing="xxsmall">
      <Field
        label={label}
        message={
          error && (
            <Box marginTop="xxsmall">
              <ErrorMessage>{error}</ErrorMessage>
            </Box>
          )
        }
      >
        <Input
          id="verify-account-form-businessWebsite"
          testID="website-input-field"
          tone={error ? 'danger' : 'neutral'}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          autoCapitalize="none"
        />
      </Field>

      <Text
        size="xsmall"
        tone="neutralLight"
        testID="business-website-helper-text"
      >
        {helperText}
      </Text>
    </Stack>
  );
};
