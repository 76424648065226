import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { Box } from '@components/Box';
import { Checkbox, Field } from '@components/FormElements';
import { Stack } from '@components/Stack';

type AgeVerificationProps = {
  value: boolean;
  onChange: () => void;
  error?: string;
};

export const AgeVerification = (props: AgeVerificationProps) => {
  const { value, onChange, error } = props;
  const { t } = useTranslation();

  return (
    <Stack spacing="small">
      <Field
        label=""
        message={
          error && (
            <Box marginTop="xxsmall">
              <ErrorMessage>{error}</ErrorMessage>
            </Box>
          )
        }
      >
        <Box marginTop="xsmall">
          <Checkbox
            testID="agree-to-terms-checkbox"
            label={t('common.message.termsAndPrivacy.ageConsent')}
            value={value}
            onChange={onChange}
          />
        </Box>
      </Field>
    </Stack>
  );
};
