import React from 'react';
import { useTranslation } from 'react-i18next';
import { localisedFormatDate } from '@common/helpers/dateTime';
import { Text } from '@components/Text';

type JobPostedOnTextProps = {
  createdAt: string | undefined;
};

export const JobPostedOnText = ({ createdAt }: JobPostedOnTextProps) => {
  const { t } = useTranslation();

  if (!createdAt) {
    return null;
  }

  return (
    <Text size="small">
      {t('dashboardPage.jobCard.postedOn', {
        date: localisedFormatDate(t, 'dd MMM yyyy', createdAt)
      })}
    </Text>
  );
};
