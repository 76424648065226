import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Button';
import { Card, CardContent } from '@components/Card';
import { CrossIcon } from '@components/Icon';
import { IconButton } from '@components/IconButton';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { ReasonController } from './components/Reason';

type NPSFormProps = {
  handleClose: () => void;
  onSubmit: ({ reason }: { reason: string }) => void;
};

export const NPSForm = ({ handleClose, onSubmit }: NPSFormProps) => {
  const { t } = useTranslation();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      reason: ''
    }
  });

  return (
    <Card testID="nps-reason-form">
      <CardContent>
        <Row justifyContent="flex-end">
          <IconButton
            testID="nps-reason-form-close-button"
            icon={<CrossIcon size="small" variant="outline" tone="neutral" />}
            onPress={handleClose}
          />
        </Row>
        <ReasonController control={control} name="reason" />

        <Stack marginTop="medium">
          <Button
            onPress={handleSubmit(onSubmit)}
            testID="nps-reason-submit-button"
          >
            {t('common.action.done')}
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};
