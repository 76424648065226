import { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  Field,
  change,
  initialize,
  reduxForm,
  propTypes as reduxFormPropTypes
} from 'redux-form';
import {
  BUSINESS_ROLE,
  INDUSTRY,
  validateCountryCodeForBrand
} from '@seek/je-shared-data';
import { BrandName } from '@seek/je-shared-data/lib/types/brand';
import { CalloutText } from '@components/CalloutText';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import ButtonWithSecondaryLink from '../../../common/components/ButtonWithSecondaryLink/ButtonWithSecondaryLink';
import Card from '../../../common/components/Card/Card';
import Div from '../../../common/components/Div/Div';
import Form from '../../../common/components/Form/Form';
import Dropdown from '../../../common/components/FormElements/Dropdown/Dropdown';
import GoogleLocationAutoSuggest from '../../../common/components/FormElements/GoogleLocationAutoSuggestOrig/GoogleLocationAutoSuggest';
import InputField from '../../../common/components/FormElements/InputField/InputField';
import Label from '../../../common/components/Label/Label';
import Link from '../../../common/components/Link/Link';
import {
  BUSINESS_REGISTRATION_NUMBER_FIELD_NAME,
  BUSINESS_ROLE_OPTIONS,
  BUSINESS_SIZE_OPTIONS,
  COUNTRY_OPTIONS,
  INDUSTRY_OPTIONS
} from '../../constants/data';
import {
  BUSINESS_NAME_MAX_LENGTH,
  BUSINESS_REGISTRATION_NUMBER_MAX_LENGTH,
  RECRUITMENT_COMPANY_NAME_MAX_LENGTH,
  WEBSITE_URL_MAX_LENGTH
} from '../../constants/validation';
import * as draftActions from '../../store/draft/actions';
import { BUSINESS_DRAFT_FORM } from '../../store/draft/constants';
import { CREATE_BUSINESS_FORM_NAME } from '../../store/entities/businesses/constants';
import ConnectedErrorMessage from '../ConnectedErrorMessage/ConnectedErrorMessage';
import Submit from '../Submit/Submit';
import { validateCreateBusinessForm } from './helpers/validation';

@withTranslation()
@connect(
  (
    { localisation, form, auth, googleLocationAutoSuggest, config },
    { initialValues }
  ) => {
    const role =
      form[CREATE_BUSINESS_FORM_NAME] &&
      (form[CREATE_BUSINESS_FORM_NAME].values || {}).role;
    const industryId =
      form[CREATE_BUSINESS_FORM_NAME] &&
      (form[CREATE_BUSINESS_FORM_NAME].values || {}).industryId;

    const selectedCountry =
      (form[CREATE_BUSINESS_FORM_NAME] &&
        (form[CREATE_BUSINESS_FORM_NAME].values || {}).country) ||
      localisation.countryCode;

    const brand = localisation.brandConfig.name;

    return {
      localisation,
      selectedCountry,
      isSelectedRoleRecruiter: role === BUSINESS_ROLE.RECRUITER,
      isSelectedIndustryIdOther: industryId === INDUSTRY.OTHER,
      isAuthenticated: auth.isAuthenticated,
      googleLocationAutoSuggest,
      businessRegistrationNumberFieldName:
        BUSINESS_REGISTRATION_NUMBER_FIELD_NAME[selectedCountry],
      initialValues,
      limitBusinessCountryCodeForBrand:
        config.featureToggles.limitBusinessCountryCodeForBrand[brand]
    };
  },
  {
    initializeForm: initialize,
    saveDraftForm: draftActions.saveDraftForm,
    changeField: change
  }
)
@reduxForm({
  form: CREATE_BUSINESS_FORM_NAME,
  validate: validateCreateBusinessForm,
  onChange: (values, dispatch, props) => {
    const {
      saveDraftForm,
      changeField,
      isSelectedIndustryIdOther,
      isSelectedRoleRecruiter,
      isSaveDraft
    } = props;

    if (!isSelectedIndustryIdOther) {
      changeField(CREATE_BUSINESS_FORM_NAME, 'industryOtherDescription', '');
    }

    if (!isSelectedRoleRecruiter) {
      changeField(CREATE_BUSINESS_FORM_NAME, 'recruitmentCompanyName', '');
    }

    if (isSaveDraft) {
      saveDraftForm(BUSINESS_DRAFT_FORM, {
        ...values,
        website: values.website,
        industryOtherDescription: values.industryOtherDescription,
        recruitmentCompanyName: values.recruitmentCompanyName
      });
    }
  }
})
export default class CreateBusinessForm extends Component {
  static propTypes = {
    ...reduxFormPropTypes,
    submitAction: PropTypes.func.isRequired,
    submitButtonLabel: PropTypes.string.isRequired,
    saveDraftForm: PropTypes.func.isRequired,
    requestKey: PropTypes.string.isRequired,
    localisation: PropTypes.shape({
      countryCode: PropTypes.string.isRequired,
      brandConfig: PropTypes.shape({
        name: PropTypes.string.isRequired,
        domain: PropTypes.string.isRequired
      })
    }),
    isAuthenticated: PropTypes.bool.isRequired,
    changeField: PropTypes.func.isRequired,
    isSelectedRoleRecruiter: PropTypes.bool.isRequired,
    isSelectedIndustryIdOther: PropTypes.bool.isRequired,
    userId: PropTypes.string,
    businessId: PropTypes.string,
    roleOptions: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.object).isRequired,
      (props, key) =>
        props[key] === null ? null : 'Not null and not an array of roleOptions'
    ]),
    bottomCalloutText: PropTypes.object,
    isSaveDraft: PropTypes.bool,
    showCancelButton: PropTypes.bool,
    t: PropTypes.func.isRequired,
    businessRegistrationNumberFieldName: PropTypes.string,
    limitBusinessCountryCodeForBrand: PropTypes.bool.isRequired
  };

  componentDidMount() {
    const { initialValues, initializeForm } = this.props;

    initializeForm(
      CREATE_BUSINESS_FORM_NAME,
      {
        ...initialValues
      },
      true
    );
  }

  validateBusinessAddress() {
    const { t, googleLocationAutoSuggest } = this.props;
    const validationMessages = t('validations.business.businessAddress', {
      returnObjects: true
    });

    if (
      googleLocationAutoSuggest.loading ||
      googleLocationAutoSuggest.locationSuggestError ||
      googleLocationAutoSuggest.locationDetailsError
    ) {
      return;
    }

    return validationMessages;
  }

  renderCountryDropdown() {
    const {
      t,
      localisation: {
        brandConfig: { name: brandName }
      },
      initialValues,
      limitBusinessCountryCodeForBrand
    } = this.props;

    if (brandName === BrandName.JOBSTREETEXPRESS) {
      return null; // APEX-618: Hide the country dropdown input for JSE
    }

    const isCreateBusiness = !initialValues.id;

    const filteredCountryOptions = limitBusinessCountryCodeForBrand
      ? COUNTRY_OPTIONS(t).filter((country) =>
          validateCountryCodeForBrand(brandName, country.value)
        )
      : COUNTRY_OPTIONS(t);

    return (
      isCreateBusiness && (
        <Field
          component={Dropdown}
          disabled={limitBusinessCountryCodeForBrand}
          name="country"
          fieldId="country"
          label={t('createBusinessForm.fieldLabel.country')}
          options={filteredCountryOptions}
        />
      )
    );
  }

  renderActions() {
    const {
      t,
      submitButtonLabel,
      showCancelButton,
      localisation: { countryCode }
    } = this.props;

    if (showCancelButton) {
      return (
        <ButtonWithSecondaryLink
          linkText={t('common.action.cancel')}
          linkLocation={`/${countryCode}/businesses`}
          button
          insideCard
          extraTopMargin
        >
          <Submit
            extraTopMargin
            insideCard
            data-test-key="create-business-action"
          >
            {submitButtonLabel}
          </Submit>
        </ButtonWithSecondaryLink>
      );
    }

    return (
      <Submit extraTopMargin insideCard data-test-key="create-business-action">
        {submitButtonLabel}
      </Submit>
    );
  }

  render() {
    const {
      handleSubmit,
      submitFailed,
      valid,
      localisation: {
        countryCode,
        brandConfig: { name: brandName, domain: brandDomain }
      },
      isAuthenticated,
      requestKey,
      t,
      submitAction,
      isSelectedRoleRecruiter,
      isSelectedIndustryIdOther,
      selectedCountry,
      roleOptions,
      initialValues,
      bottomCalloutText,
      businessRegistrationNumberFieldName
    } = this.props;
    const locationValueFromStore =
      typeof initialValues.businessAddress === 'string'
        ? { streetAddress: initialValues.businessAddress }
        : initialValues.businessAddress;
    const initialValueForGoogleLocationAutoSuggest = locationValueFromStore || {
      streetAddress: '',
      lat: null,
      lon: null
    };

    return (
      <Div>
        <Card>
          <Form
            onSubmit={handleSubmit(submitAction.bind(this))}
            valid={valid}
            insideCard
            submitFailed={submitFailed}
            errorSummary={t('validations.general.summary')}
          >
            <Field
              component={InputField}
              name="name"
              fieldId="name"
              label={t('createBusinessForm.fieldLabel.name')}
              autoCorrect="off"
              autoCapitalize="words"
              validationMessages={t('validations.business.name', {
                returnObjects: true,
                max: BUSINESS_NAME_MAX_LENGTH
              })}
              firstField
            />

            {this.renderCountryDropdown()}

            <Field
              component={GoogleLocationAutoSuggest}
              name="businessAddress"
              fieldId="businessAddress"
              label={t('createBusinessForm.fieldLabel.businessAddress')}
              autoCorrect="off"
              autoCapitalize="words"
              country={selectedCountry}
              extraTopMargin
              validationMessages={this.validateBusinessAddress()}
              initialValue={initialValueForGoogleLocationAutoSuggest}
            />
            <Stack marginTop="xxsmall">
              <Text size="small" tone="neutralLight">
                {t('createBusinessForm.fieldHelpText.location')}
              </Text>
            </Stack>
            <Stack marginTop="medium">
              <CalloutText tone="info">
                <Stack tone="transparent" flexShrink={1}>
                  <Text>
                    {t('createBusinessForm.calloutText.location.title')}
                  </Text>
                  <Text>
                    {t('createBusinessForm.calloutText.location.example')}
                  </Text>
                </Stack>
              </CalloutText>
            </Stack>

            <Field
              component={Dropdown}
              name="industryId"
              fieldId="industryId"
              label={t('createBusinessForm.fieldLabel.industry')}
              options={INDUSTRY_OPTIONS(t)}
              validationMessages={t('validations.business.industry', {
                returnObjects: true
              })}
            />
            {isSelectedIndustryIdOther && (
              <Field
                component={InputField}
                name="industryOtherDescription"
                fieldId="industryOtherDescription"
                label={t('createBusinessForm.fieldLabel.otherIndustry')}
                autoCapitalize="words"
                validationMessages={t('validations.business.otherIndustry', {
                  returnObjects: true
                })}
              />
            )}

            <Field
              component={Dropdown}
              name="businessSize"
              fieldId="businessSize"
              label={t('createBusinessForm.fieldLabel.businessSize')}
              options={BUSINESS_SIZE_OPTIONS(t)}
              validationMessages={t('validations.business.businessSize', {
                returnObjects: true
              })}
            />

            {roleOptions ? (
              <Field
                component={Dropdown}
                name="role"
                fieldId="role"
                label={t('createBusinessForm.fieldLabel.role')}
                options={roleOptions}
                validationMessages={t('validations.business.role', {
                  returnObjects: true
                })}
              />
            ) : (
              <Div>
                <Label>{t('createBusinessForm.fieldLabel.role')}</Label>
                  <Text weight="medium">
                  {
                    BUSINESS_ROLE_OPTIONS(t).find(
                      ({ key }) => key === initialValues.role
                    ).label
                  }
                  </Text>
              </Div>
            )}

            {isSelectedRoleRecruiter && (
              <Field
                component={InputField}
                name="recruitmentCompanyName"
                fieldId="recruitmentCompanyName"
                label={t('createBusinessForm.fieldLabel.recruitmentName')}
                autoCorrect="off"
                autoCapitalize="words"
                validationMessages={t(
                  'validations.business.recruitmentCompanyName',
                  {
                    returnObjects: true,
                    max: RECRUITMENT_COMPANY_NAME_MAX_LENGTH
                  }
                )}
              />
            )}

            <Field
              component={InputField}
              name="website"
              fieldId="website"
              label={t('createBusinessForm.fieldLabel.website')}
              autoCorrect="off"
              autoCapitalize="none"
              validationMessages={t('validations.business.website', {
                returnObjects: true,
                max: WEBSITE_URL_MAX_LENGTH
              })}
            />
            <Stack marginTop="xxsmall">
              <Text size="small" tone="neutralLight">
                {t('createBusinessForm.fieldHelpText.website', {
                  returnObjects: true,
                  brandDomain,
                  brand: brandName.toLowerCase()
                })}
              </Text>
            </Stack>

            <Field
              component={InputField}
              name="businessRegistrationNumber"
              fieldId="businessRegistrationNumber"
              label={businessRegistrationNumberFieldName}
              autoCorrect="off"
              autoCapitalize="none"
              validationMessages={t(
                'validations.business.businessRegistrationNumber',
                {
                  returnObjects: true,
                  max: BUSINESS_REGISTRATION_NUMBER_MAX_LENGTH,
                  businessRegistrationNumberFieldName
                }
              )}
            />
            {bottomCalloutText ? <Div>{bottomCalloutText}</Div> : null}

            {this.renderActions()}

            {!isAuthenticated && (
              <Row spacing="xxsmall" paddingTop="medium" alignItems="center">
                <Text>
                  {t('registerForm.description.alreadyHaveAnAccount') + ' '}
                </Text>

                <Link
                  data-test-key="already-have-an-account-login-link"
                  to={`/${countryCode}/login`}
                >
                  {t('common.action.login')}
                </Link>
              </Row>
            )}
          </Form>
          <ConnectedErrorMessage requestKey={requestKey} extraTopMargin />
        </Card>
      </Div>
    );
  }
}
