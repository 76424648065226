import { rulesAreValid } from '@lib/form';
import { BUSINESS_ROLE, INDUSTRY } from '@seek/je-shared-data';
import {
  businessAddressRules,
  businessNameRules,
  businessSizeRules,
  industryDescriptionRules,
  industryRules,
  recruitmentCompanyNameRules,
  roleRules
} from './rules';
import type { IncompleteBusinessFormData } from './types';

/**
 * Checks if the provided data is complete based on the specified rules.
 * @param {Incomplete} data - The data to validate.
 * @returns A boolean value indicating whether the data is complete or not.
 */
export function isComplete(data: IncompleteBusinessFormData): boolean {
  const t = () => 'A';
  return [
    rulesAreValid(data, 'name', businessNameRules(t)),
    rulesAreValid(data, 'businessAddress', businessAddressRules(t)),
    rulesAreValid(data, 'businessSize', businessSizeRules(t)),
    data.industryId === INDUSTRY.OTHER
      ? rulesAreValid(
          data,
          'industryOtherDescription',
          industryDescriptionRules(t)
        )
      : true,
    rulesAreValid(data, 'industryId', industryRules(t)),
    rulesAreValid(data, 'role', roleRules(t)),
    data.role === BUSINESS_ROLE.RECRUITER
      ? rulesAreValid(
          data,
          'recruitmentCompanyName',
          recruitmentCompanyNameRules(t)
        )
      : true
  ].every(Boolean);
}
