import React from 'react';
import { ErrorIcon } from '@components/Icon';
import { Text } from '@components/Text';
import { TextWithIcon } from '../TextWithIcon';

export type NeutralWarningTextProps = {
  children?: string;
};

export const NeutralWarningText = ({ children }: NeutralWarningTextProps) => {
  if (!children) return null;

  return (
    <TextWithIcon
      icon={<ErrorIcon tone="neutralLight" variant="outline" size="small" />}
    >
      <Text tone="neutralLight" size="small">
        {children}
      </Text>
    </TextWithIcon>
  );
};
