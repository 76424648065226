import React from 'react';
import { Path, Rect } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <>
    <Rect
      x="3.05261"
      y="3.05273"
      width="18.0231"
      height="18.0231"
      rx="8.67073"
      stroke-width="0.878049"
    />
    <Path
      d="M17.2631 4.67017V6.9975C17.2631 7.21019 17.1859 7.41564 17.0458 7.5757L15.0558 9.8501C14.9147 10.0113 14.7197 10.1153 14.5073 10.1427L11.5763 10.5209C11.3692 10.5477 11.1593 10.4997 10.9843 10.3856L9.18085 9.20937C8.83946 8.98672 8.38974 9.02827 8.09492 9.30969L6.34584 10.9793C6.16199 11.1548 6.0632 11.4013 6.07501 11.6552L6.26095 15.6528C6.27708 15.9997 6.08718 16.3236 5.77652 16.4789L4.43896 17.1477"
      stroke-width="0.878049"
    />
    <Path
      d="M17.3787 19.1119L16.7112 18.349C16.6184 18.243 16.5015 18.1608 16.3703 18.1095L14.4708 17.3662C14.1342 17.2345 13.9127 16.91 13.9127 16.5485V15.3195C13.9127 14.9588 14.1333 14.6347 14.4689 14.5025L17.2334 13.4135C17.536 13.2943 17.8797 13.3521 18.1267 13.5638L20.1515 15.2993"
      stroke-width="0.878049"
    />
  </>
);

export const EarthIcon = createIcon({
  viewBox: '2 2 20 20',
  vectorComponent
});
