import { useBreakpoint } from '@theme/DesignSystemProvider/BreakpointContext';
import { usePlatform } from '@theme/DesignSystemProvider/PlatformContext';
import { Breakpoint } from '../breakpoints';
import { Platform } from '../platform';

type AtomicToken<Token> = Exclude<Token, Record<Breakpoint, any>>;

type ResponsiveToken<Theme, Token> = Theme extends null | undefined
  ?
      | AtomicToken<Token>
      | Record<Breakpoint, AtomicToken<Token>>
      | Record<Breakpoint, Record<Platform, AtomicToken<Token>>>
  :
      | keyof Theme
      | Record<Breakpoint, keyof Theme>
      | Record<Breakpoint, Record<Platform, keyof Theme>>;

type Result<Theme, Token> = Theme extends null | undefined
  ? AtomicToken<Token>
  : Token extends keyof Theme
  ? Theme[Token]
  : AtomicToken<Token>;

function isResponsive(
  token: Record<Breakpoint, any> | any
): token is Record<Breakpoint, any> {
  return !!(token.mobile || token.tablet || token.desktop);
}

function isPlatformResponsive(
  token: Record<Breakpoint, Record<Platform, any>> | any,
  breakpoint: Breakpoint
): token is Record<Breakpoint, Record<Platform, any>> {
  if (!token[breakpoint]) return false;

  return !!(
    token[breakpoint].ios ||
    token[breakpoint].android ||
    token[breakpoint].web
  );
}

function getTokenNameValue<Theme, Token>({
  tokenName,
  breakpoint,
  platform
}: {
  tokenName: ResponsiveToken<Theme, Token>;
  breakpoint: Breakpoint;
  platform: Platform;
}) {
  if (isPlatformResponsive(tokenName, breakpoint)) {
    return tokenName[breakpoint][platform];
  }

  if (isResponsive(tokenName)) return tokenName[breakpoint];

  return tokenName;
}

export function useResolveToken() {
  const platform = usePlatform();
  const breakpoint = useBreakpoint();

  const resolveToken = <Theme, Token>(
    theme?: Theme,
    tokenName?: ResponsiveToken<Theme, Token>
  ): Result<Theme, Token> | undefined => {
    if (tokenName) {
      const tokenNameValue = getTokenNameValue<Theme, Token>({
        tokenName,
        breakpoint,
        platform
      });

      if (!theme) {
        return tokenNameValue;
      }

      return theme[tokenNameValue];
    }
  };

  return { resolveToken };
}
