import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { ShiftAvailability } from '@seek/je-shared-data/lib/types/jobs';
import { Box } from '@components/Box';
import { Field } from '@components/FormElements';
import { Stack } from '@components/Stack';
import {
  Tab,
  TabList,
  TabPanels,
  TabsProvider
} from '@components/TabsProvider';
import { SpecificShiftAvailability } from './components';
import { useShiftAvailabilityTab, useSpecificShiftAvailability } from './hooks';

export type Props = {
  value: ShiftAvailability;
  onChange: (value: ShiftAvailability) => void;
  error?: string;
};

export const ShiftAvailabilityField = ({ value, onChange, error }: Props) => {
  const { t } = useTranslation();

  const { selectedTabValue, handleOnTabPress } = useShiftAvailabilityTab(
    value,
    onChange
  );

  const {
    onCheckboxPressed,
    selectedAvailability,
    onAllCheckboxPressed,
    selectedAllAvailability
  } = useSpecificShiftAvailability(value, onChange);

  return (
    <Stack spacing="small" testID="shift-availability">
      <Field
        label={t('postJobForm.shiftAvailability.title')}
        message={
          error && (
            <Box marginTop="small">
              <ErrorMessage>{error}</ErrorMessage>
            </Box>
          )
        }
      >
        <Box>
          <TabsProvider initialSelectedTab={selectedTabValue}>
            <TabList onTabPress={handleOnTabPress} paddingHorizontal={'none'}>
              <Tab>{t('postJobForm.shiftAvailability.tab.specificTimes')}</Tab>
              <Tab testID="shiftAvailability-tab-anyTime">
                {t('postJobForm.shiftAvailability.tab.anyTime')}
              </Tab>
            </TabList>

            <TabPanels>
              <SpecificShiftAvailability
                value={selectedAvailability}
                selectedAllAvailability={selectedAllAvailability}
                onAvailabilityCheckboxPress={onCheckboxPressed}
                onAllAvailabilityCheckboxPress={onAllCheckboxPressed}
              />
              <Box />
            </TabPanels>
          </TabsProvider>
        </Box>
      </Field>
    </Stack>
  );
};
