import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { I18nextProvider } from 'react-i18next';

@connect(
  state => ({
    language: state.localisation.language
  })
)
export default class I18nProvider extends PureComponent {
  static propTypes = {
    language: PropTypes.string,
    children: PropTypes.any,
    i18n: PropTypes.any
  };

  render() {
    const { language, children, i18n } = this.props;

    if (language !== i18n.language) {
      i18n.changeLanguage(language);
    }

    return (
      <I18nextProvider i18n={i18n}>
        {children}
      </I18nextProvider>
    );
  }
}
