import { formatIsoToDate } from '@common/helpers/dateTime';
import { UpdatedJobItem } from './types';

export function toFormattedJob(job): UpdatedJobItem {
  return {
    id: job.id,
    company: job.company,
    countryCode: job.countryCode,
    description: job.description,
    jobType: job.jobType,
    location: job.location,
    title: job.title,
    rightToWork: job.rightToWork,
    workExperience: job.workExperience,
    ...(job.startDate
      ? { startDate: formatIsoToDate(job.startDate, 'DD/MM/YYYY') }
      : {})
  };
}
