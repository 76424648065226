import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardAction } from '@components/Card';
import { ViewIcon } from '@components/Icon';
import { Link } from '@components/Link';

type Props = {
  onPress: () => void;
};

export const ViewMoreAction = ({ onPress }: Props) => {
  const { t } = useTranslation();

  return (
    <CardAction>
      <Link
        onPress={onPress}
        variant="secondary"
        icon={<ViewIcon variant="outline" />}
      >
        {t('myCoinsPage.transactions.viewMore')}
      </Link>
    </CardAction>
  );
};
