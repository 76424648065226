import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { inIOSApp } from '@src/joraEmployer/whereAmIRunning';
import { VideoError } from '../VideoError';

type VideoPlayerProps = {
  signedUrl: string;
};

export const VideoPlayer = ({ signedUrl }: VideoPlayerProps) => {
  const url = `${signedUrl}/manifest/video.m3u8`;
  const [isMediaError, setMediaError] = useState(false);

  if (isMediaError) return <VideoError />;

  return (
    <ReactPlayer
      data-test-key="web-video-player"
      url={url}
      width="100%"
      height="auto"
      playsinline={true}
      playing={true}
      controls={true}
      config={{
        file: {
          forceHLS: !inIOSApp()
        }
      }}
      onError={(_error) => setMediaError(true)}
    />
  );
};
