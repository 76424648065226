import { grey600 } from '../../theme/palette/colours';
import Svg, { Path } from 'svgs';

function DropdownIcon(props) {
  return (
    <Svg viewBox="7 4 18 18" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.931 10h8.138a.8.8 0 01.565 1.366l-4.068 4.068a.8.8 0 01-1.132 0l-4.068-4.068A.8.8 0 017.93 10z"
        fill={grey600}
      />
    </Svg>
  );
}

export default DropdownIcon;
