import { useLocation } from 'react-router-native';
import { useRedirect } from '@common/hooks/useRedirect';
import { getJobs } from '@store/entities/jobs/selectors';
import { useAppSelector } from '@store/hooks';

type UseMilestoneStepProps = {
  pathname: string;
};

const useGetUserHasJob = () => {
  const jobs = useAppSelector(getJobs());

  return Object.keys(jobs).length;
};

const useRedirectToPathname = (pathname: string) => {
  const { search } = useLocation();
  const { redirect } = useRedirect();

  return redirect(`${pathname}${search}`);
};

export const useMilestoneStep = ({ pathname }: UseMilestoneStepProps) => {
  const userHasJob = useGetUserHasJob();
  const redirectToPathname = useRedirectToPathname(pathname);

  return {
    userHasJob,
    redirectToPathname
  };
};
