import React from 'react';
import { TFunction } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { Box } from '@components/Box';
import { Field, TextArea } from '@components/FormElements';
import { Stack } from '@components/Stack';

type NotesProps = {
  value: string;
  onChange: () => void;
  onBlur: () => void;
  error?: string;
  t: TFunction<'translation', undefined>;
  maxChars: number;
};

export const Notes = ({ error, onChange, value, maxChars, t }: NotesProps) => {
  return (
    <Stack spacing="small">
      <Field
        testID="notes-field"
        label=""
        message={
          error && (
            <Box marginTop="xxsmall">
              <ErrorMessage>{error}</ErrorMessage>
            </Box>
          )
        }
      >
        <TextArea
          placeholder={t('applications.notes.placeholder.notes')}
          onChange={onChange}
          value={value}
          charCountMax={maxChars}
        />
      </Field>
    </Stack>
  );
};
