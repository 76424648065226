import React, { forwardRef, useState } from 'react';
import { TextInput, TextInputProps, View } from 'react-native';
import { CharacterCounter } from './CharacterCounter';
import {
  TextAreaTone,
  useTextAreaStyle,
  useTextStyle
} from './useTextAreaStyle';

export type TextAreaProps = {
  value: TextInputProps['value'];
  onChange: TextInputProps['onChangeText'];
  /** The number of lines for text area
   *  @default 5
   */
  lines?: number;
  testID?: TextInputProps['testID'];
  disabled?: boolean;
  charCountMax?: number;
  tone?: 'neutral' | 'danger';
  placeholder?: string;
};

const getTextAreaTone = ({
  defaultTone,
  disabled
}: {
  defaultTone: TextAreaTone;
  disabled?: boolean;
}) => (disabled ? 'successMuted' : defaultTone);

export const TextArea = forwardRef<TextInput, TextAreaProps>((props, ref) => {
  const {
    lines = 5,
    testID,
    disabled,
    charCountMax,
    onChange,
    tone = 'neutral',
    placeholder,
    ...restProps
  } = props;

  const defaultTone = getTextAreaTone({
    defaultTone: tone || 'neutral',
    disabled
  });
  const activeTone = getTextAreaTone({
    defaultTone: 'success',
    disabled
  });
  const [textAreaTone, setTone] = useState<TextAreaTone>(defaultTone);

  const {
    container: resolvedContainerStyle,
    textInputField: resolvedTextInputFieldStyle,
    placeholder: resolvedPlaceholderStyle
  } = useTextAreaStyle({ tone: textAreaTone, lines });
  const resolvedTextStyle = useTextStyle({ tone: textAreaTone });

  return (
    <View style={resolvedContainerStyle}>
      <TextInput
        placeholder={placeholder}
        placeholderTextColor={resolvedPlaceholderStyle.color}
        onChangeText={onChange}
        style={[resolvedTextInputFieldStyle, resolvedTextStyle]}
        multiline
        numberOfLines={lines}
        ref={ref}
        testID={testID}
        onFocus={() => setTone(activeTone)}
        onBlur={() => setTone(defaultTone)}
        onPressIn={() => setTone(activeTone)}
        onPressOut={() => setTone(defaultTone)}
        editable={!disabled}
        selectTextOnFocus={!disabled}
        underlineColorAndroid="rgba(0,0,0,0)"
        autoCorrect
        {...restProps}
      />
      <CharacterCounter
        maxCharCount={charCountMax}
        currentCharCount={props.value ? props.value.length : 0}
      />
    </View>
  );
});
