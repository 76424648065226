import React from 'react';
import { Controller, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { SingleSelect } from '@common/components/FormElements/SingleSelect';
import { Box } from '@components/Box';
import { Field } from '@components/FormElements/Field';
import { FormInputs } from '../CloseJobModal';
import { useFormValidation } from '../hooks';
import { useReasonOptions } from '../hooks/useReasonOptions';

type ReasonProps = {
  control: UseControllerProps<FormInputs>['control'];
};

export const Reason = ({ control }: ReasonProps) => {
  const { t } = useTranslation();
  const { reasonFieldValidation } = useFormValidation();
  const { options } = useReasonOptions();

  return (
    <Controller
      name="reason"
      control={control}
      rules={reasonFieldValidation.validate()}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Field
          label={t('dashboardPage.jobCard.closeJob.description')}
          message={
            error && (
              <Box paddingBottom="small">
                <ErrorMessage>{error.message}</ErrorMessage>
              </Box>
            )
          }
        >
          <Box paddingTop="large" paddingBottom={error ? 'small' : 'large'}>
            <SingleSelect
              options={options}
              value={value}
              testID="close-job-single-select"
              onChange={(data) => {
                onChange(data);
              }}
            />
          </Box>
        </Field>
      )}
    />
  );
};
