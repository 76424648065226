import React, { useContext } from 'react';
import { FormContext } from '../../FormContext';
import { FieldWrapper } from '../FieldWrapper';
import { BusinessRegistrationNumber as InnerField } from './BusinessRegistrationNumber';
import { useBusinessRegistrationNumberRules } from './useBusinessRegistrationNumberRules';

export const BusinessRegistrationNumber = ({ label }: { label: string }) => {
  const { t } = useContext(FormContext);

  return (
    <FieldWrapper<string>
      name="businessRegistrationNumber"
      rules={useBusinessRegistrationNumberRules(t)}
      render={(props) => {
        const propsToPass = {
          ...props,
          label
        };

        return <InnerField {...propsToPass} />;
      }}
    />
  );
};
