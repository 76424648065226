import React from 'react';
import { TextInputProps } from 'react-native';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { ExactSalary, RangeSalary } from '@seek/je-shared-data/lib/types/jobs';
import { Box } from '@components/Box';
import { CalloutText } from '@components/CalloutText';
import { Field } from '@components/FormElements';
import { Link } from '@components/Link';
import { useModal } from '@components/Modal';
import { Stack } from '@components/Stack';
import { ExactAmount, RangeAmount } from './components/Amount';
import { ChangeCurrencyModal } from './components/ChangeCurrencyModal';
import { RateSelector } from './components/RateSelector';
import { SalaryTypeSelector } from './components/SalaryTypeSelector';
import {
  useExpectedSalary,
  useExpectedSalaryProps
} from './hooks/useExpectedSalary';

export type ExpectedSalaryProps = useExpectedSalaryProps & {
  error?: string;
  onBlur?: TextInputProps['onBlur'];
};

export const ExpectedSalary = ({
  value,
  onChange,
  onBlur,
  error
}: ExpectedSalaryProps) => {
  const { t } = useTranslation();
  const {
    onSalaryByChange,
    onExactAmountChange,
    onRangeAmountChange,
    onRateChange,
    onCurrencyChange,
    calloutText,
    currencySymbol
  } = useExpectedSalary({ value, onChange });

  const { isModalOpen, openModal, closeModal } = useModal();

  return (
    <Stack spacing="medium" maxWidth="full" zIndex={10}>
      <Field
        label={t('postJobForm.fieldLabel.expectedSalary')}
        message={
          error && (
            <Box marginTop="xxsmall" zIndex={-1}>
              <ErrorMessage>{error}</ErrorMessage>
            </Box>
          )
        }
      >
        <Box>
          <Link
            variant="primary"
            size="small"
            onPress={openModal}
            testID="change-currency-link"
          >
            {`${t(
              'postJobForm.expectedSalary.changeCurrencyModal.title'
            )}: ${currencySymbol} ${value.currency}`}
          </Link>

          <ChangeCurrencyModal
            visible={isModalOpen}
            onClose={closeModal}
            onCurrencyChange={onCurrencyChange}
            value={value.currency}
          />
        </Box>

        <Stack
          width="full"
          direction={{ mobile: 'column', tablet: 'column', desktop: 'row' }}
          marginTop="small"
        >
          <Box
            zIndex={11}
            width={{ mobile: 'full', tablet: 'full', desktop: '1/4' }}
            marginBottom={{
              mobile: 'medium',
              tablet: 'medium',
              desktop: 'none'
            }}
          >
            <SalaryTypeSelector
              value={value.type}
              onChange={onSalaryByChange}
            />
          </Box>

          <Box
            width={{ mobile: 'full', tablet: 'full', desktop: '1/2' }}
            marginBottom={{
              mobile: 'medium',
              tablet: 'medium',
              desktop: 'none'
            }}
            paddingHorizontal={{
              mobile: 'none',
              tablet: 'none',
              desktop: 'small'
            }}
          >
            {value.type === 'range' ? (
              <RangeAmount
                currencySymbol={currencySymbol}
                value={value.amount as Partial<RangeSalary['amount']>}
                onChange={onRangeAmountChange}
                onBlur={onBlur}
              />
            ) : (
              <ExactAmount
                currencySymbol={currencySymbol}
                value={value.amount as ExactSalary['amount']}
                onChange={onExactAmountChange}
                onBlur={onBlur}
              />
            )}
          </Box>

          <Box
            zIndex={10}
            width={{ mobile: 'full', tablet: 'full', desktop: '1/4' }}
          >
            <RateSelector value={value.rate} onChange={onRateChange} />
          </Box>
        </Stack>
      </Field>

      <Box zIndex={-1}>
        <CalloutText tone="info" testID="expected-salary-callout-text">
          {calloutText}
        </CalloutText>
      </Box>
    </Stack>
  );
};
