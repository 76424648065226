import React, { ReactNode, forwardRef } from 'react';
import { Text as ReactNativeText, View } from 'react-native';
import { TextStyleProps, useTextStyle } from './useTextStyle';

export type TextProps = {
  children?: string | ReactNode;
  testID?: string;
} & TextStyleProps;

export const Text = forwardRef<View, TextProps>((props, ref) => {
  const { children, testID, ...restProps } = props;
  const { textStyle } = useTextStyle(restProps);

  return (
    <ReactNativeText style={textStyle} testID={testID} ref={ref}>
      {children}
    </ReactNativeText>
  );
});
