import React from 'react';
import { Stack } from '@components/Stack';

type PageHeadingLayoutProps = {
  children: React.ReactNode;
};

export const PageHeadingLayout = ({ children }: PageHeadingLayoutProps) => (
  <Stack
    direction={{ desktop: 'row', tablet: 'row', mobile: 'column' }}
    tone="transparent"
    justifyContent="space-between"
    alignItems={{
      desktop: 'flex-start',
      tablet: 'flex-start',
      mobile: 'stretch'
    }}
    marginHorizontal="medium"
    marginTop={{ desktop: 'medium', tablet: 'medium', mobile: 'small' }}
    marginBottom="medium"
    spacing={{ desktop: 'none', tablet: 'none', mobile: 'small' }}
  >
    {children}
  </Stack>
);
