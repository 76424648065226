export type Props = {
  size?: 'small' | 'large';
  textTone?: 'white' | 'neutral';
};

export const useTotalCoinsTextStyle = ({
  size = 'large',
  textTone = 'neutral'
}: Props) => {
  return {
    size: textSizeConfig[size],
    tone: textTone,
    weight: textWeightConfig[size]
  };
};

export const useTotalCoinsIconStyle = ({
  size = 'large'
}: {
  size: Props['size'];
}) => {
  return {
    size: iconSizeConfig[size]
  };
};

const textSizeConfig = {
  small: 'standard',
  large: 'xxlarge'
} as const;

const textWeightConfig = {
  small: 'light',
  large: 'regular'
} as const;

const iconSizeConfig = {
  small: 'large',
  large: 'xlarge'
} as const;
