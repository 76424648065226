import { VerifyAccountDetailsFormValues } from '@common/components/VerifyAccountDetailsForm';
import REQUEST_KEY from '@src/joraEmployer/constants/requestKeys';
import { getRequestState } from '@store/app';
import { submitAccountAppeal } from '@store/auth';
import { useAppDispatch, useAppSelector } from '@store/hooks';

export const useVerifyAccountDetails = () => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(
    getRequestState(REQUEST_KEY.AUTH.SUBMIT_ACCOUNT_APPEAL)
  );

  return {
    submitAccountAppeal: (data: VerifyAccountDetailsFormValues) =>
      dispatch(submitAccountAppeal(data)),
    defaultValues: {
      businessRegistrationNumber: '',
      website: '',
      additionalInformation: ''
    },
    isLoading
  };
};
