import React from 'react';
import { NO_STATUS } from '@common/constants/data';
import { getStatusCount } from '@common/helpers/applications';
import { Application } from '@common/types/Application';
import { APPLICANT_STATUS } from '@seek/je-shared-data';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { ApplicationStatus } from './components';

type ApplicationsCounterProps = {
  applications: Array<Application>;
};

const {
  NOT_SUITABLE,
  SHORTLISTED,
  SCHEDULED_INTERVIEW,
  OFFERED_JOB,
  ACCEPTED_OFFER
} = APPLICANT_STATUS;

export const ApplicationsCounter = ({
  applications
}: ApplicationsCounterProps) => {
  const applicationsByStatusCount = getStatusCount(applications);

  return (
    <Stack
      direction={{ desktop: 'row', tablet: 'column', mobile: 'column' }}
      spacing="small"
      alignItems="stretch"
      testID="applications-counter"
    >
      <Row spacing="small" flex={1}>
        <ApplicationStatus
          testID="applications-status-noStatus"
          status={NO_STATUS}
          count={applicationsByStatusCount[NO_STATUS]}
        />

        <ApplicationStatus
          testID={`applications-status-${NOT_SUITABLE}`}
          status={NOT_SUITABLE}
          count={applicationsByStatusCount[NOT_SUITABLE]}
        />

        <ApplicationStatus
          testID={`applications-status-${SHORTLISTED}`}
          status={SHORTLISTED}
          count={applicationsByStatusCount[SHORTLISTED]}
        />
      </Row>

      <Row spacing="small" flex={1}>
        <ApplicationStatus
          testID={`applications-status-${SCHEDULED_INTERVIEW}`}
          status={SCHEDULED_INTERVIEW}
          count={applicationsByStatusCount[SCHEDULED_INTERVIEW]}
        />
        <ApplicationStatus
          testID={`applications-status-${OFFERED_JOB}`}
          status={OFFERED_JOB}
          count={applicationsByStatusCount[OFFERED_JOB]}
        />
        <ApplicationStatus
          testID={`applications-status-${ACCEPTED_OFFER}`}
          status={ACCEPTED_OFFER}
          count={applicationsByStatusCount[ACCEPTED_OFFER]}
        />
      </Row>
    </Stack>
  );
};
