import { ProfileApplication } from 'je-bff/src/applications/list/transformers';
import { ApplicationFilterValues } from '../../../types';

export const applyFilterReducer = (
  filters: Array<
    (
      filterValues: ApplicationFilterValues,
      applications: ProfileApplication[]
    ) => ProfileApplication[]
  >,
  filterValues: ApplicationFilterValues,
  applications: ProfileApplication[]
): ProfileApplication[] => {
  return filters.reduce(
    (filteredApplications, filter) =>
      filter(filterValues, filteredApplications),
    applications
  );
};
