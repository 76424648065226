import React, { useEffect, useState } from 'react';
import { DevSettings } from 'react-native';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-native';

export const DevMenu = () => {
  const [isRedirected, setRedirected] = useState(false);
  const countryCode = useSelector(getCountryCode);
  const history = useHistory();

  useEffect(() => {
    if (__DEV__) {
      DevSettings.addMenuItem('Show Playground', () => {
        setRedirected(true);
      });

      history.listen(() => {
        setRedirected(false);
      });
    }
  }, [history]);

  return isRedirected ? <Redirect to={`/${countryCode}/playground`} /> : null;
};

function getCountryCode(state) {
  return state.localisation?.countryCode;
}
