import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { modalOpened, modalClosed } from '../../actions/modal';

export default function (WrappedComponent) {
  class ReduxModal extends Component {
    static propTypes = {
      modalOpen: PropTypes.bool.isRequired,
      modalOpened: PropTypes.func.isRequired,
      modalClosed: PropTypes.func.isRequired,
      close: PropTypes.func.isRequired,
      openTrackEventPayload: PropTypes.object,
      locationPathname: PropTypes.string
    };

    componentDidMount() {
      const { modalOpen } = this.props;

      if (modalOpen) {
        this.triggerModalOpenedAction();
      }
    }

    componentWillUnmount() {
      const { modalClosed } = this.props;

      modalClosed();
    }

    componentDidUpdate(prevProps) {
      const { modalOpen, modalClosed, locationPathname, close } = this.props;

      if (prevProps.modalOpen !== modalOpen) {
        if (modalOpen) {
          this.triggerModalOpenedAction();
        } else {
          modalClosed();
        }
      }

      if (prevProps.locationPathname !== locationPathname) {
        close();
      }
    }

    triggerModalOpenedAction() {
      const { modalOpened, openTrackEventPayload } = this.props;

      modalOpened({openTrackEventPayload});
    }

    render() {
      return (
        <WrappedComponent {...this.props} />
      );
    }
  }

  return connect(state => ({
    locationPathname: ((state.router || {}).location || {}).pathname
  }), {
    modalOpened,
    modalClosed
  })(ReduxModal);
}