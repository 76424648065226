import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './ActionLink.scss';
import SuccessIcon from '../Icons/SuccessIcon';

/**
 * @deprecated Use the @components/Link instead.
 **/
export default class ActionLink extends Component {
  static propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    extraTopMargin: PropTypes.bool,
    extraLeftMargin: PropTypes.bool,
    centered: PropTypes.bool,
    confirmationMessage: PropTypes.string,
    href: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      actionCompleted: false
    };
  }

  startAction(e) {
    const { onClick } = this.props;

    if (onClick) {
      onClick(e, this.completeAction.bind(this));
    }
  }

  completeAction() {
    this.setState({
      actionCompleted: true
    });
  }

  render() {
    const {
      href,
      className,
      extraTopMargin,
      extraLeftMargin,
      children,
      centered,
      confirmationMessage,
      ...restProps
    } = this.props;
    const { actionCompleted } = this.state;

    if (actionCompleted && confirmationMessage) {
      return (
        <span data-test-key="action-link-confirmation">
          <SuccessIcon className={styles.icon} />
          {confirmationMessage}
        </span>
      );
    }

    return (
      <a
        href={href}
        onClick={this.startAction.bind(this)}
        className={classNames(
          className,
          {
            [styles.extraTopMargin]: extraTopMargin,
            [styles.extraLeftMargin]: extraLeftMargin,
            [styles.centered]: centered
          }
        )}
        {...restProps}
      >
        {children}
      </a>
    );
  }
}
