import React from 'react';
import { RightToWork as InnerField } from '@common/components/PostJobForm/components/RightToWork/RightToWork';
import { FieldWrapper } from '../FieldWrapper';

export default function RightToWork() {
  return (
    <FieldWrapper<boolean>
      name="rightToWork"
      render={(props) => <InnerField {...props} />}
    />
  );
}
