import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useBrandNameText } from '@common/hooks/localisation';

export function useReasonOptions() {
  const brandNameText = useBrandNameText();
  const { t } = useTranslation();

  const options = useMemo(
    () => [
      {
        key: CloseJobReason.FilledHere,
        label: t('dashboardPage.jobCard.closeJob.filledHere', { brandNameText })
      },
      {
        key: CloseJobReason.FilledElsewhere,
        label: t('dashboardPage.jobCard.closeJob.filledElsewhere')
      },
      {
        key: CloseJobReason.HaventFilled,
        label: t('dashboardPage.jobCard.closeJob.haventFilled')
      }
    ],
    [brandNameText, t]
  );

  return {
    options
  };
}

export enum CloseJobReason {
  FilledHere = 'filledHere',
  FilledElsewhere = 'filledElsewhere',
  HaventFilled = 'haventFilled'
}
