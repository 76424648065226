import { useTheme } from '@theme/DesignSystemProvider/ThemeContext';
import { Breakpoint } from '@theme/tokens';
import { FontWeight } from '@theme/tokens/typography';
import { useResolveToken } from '@theme/tokens/utils/useResolveToken';

export type LinkState = 'default' | 'hover';

type Alignment = 'horizontal' | 'vertical';
type Size = 'xxsmall' | 'xsmall' | 'small' | 'standard';

export type LinkStyleProps = {
  align?: Alignment | Record<Breakpoint, Alignment>;
  variant?: 'primary' | 'secondary' | 'success' | 'neutral';
  state?: LinkState;
  size?: Size | Record<Breakpoint, Size>;
  weight?: FontWeight;
};

export function useLinkStyle({
  align,
  variant = 'primary',
  state = 'default',
  size,
  weight
}: LinkStyleProps) {
  const {
    spacing,
    typography,
    link,
    icon: { sizes: iconSizes }
  } = useTheme();
  const { resolveToken } = useResolveToken();

  const typographyStyle = {
    primary: 'standard',
    secondary: 'small'
  } as const;
  const textStyle = resolveToken(
    typography.text,
    size || typographyStyle[variant]
  );

  const { fontColor, activityIndicatorColor } = link.variant[variant][state];

  const textDecorationLine = (
    {
      default: 'none',
      hover: 'underline'
    } as const
  )[state];

  const atomicAlign = resolveToken(null, align) || 'horizontal';
  const { flexDirection, marginBottom, marginRight, textAlign } = (
    {
      horizontal: {
        flexDirection: 'row',
        marginBottom: 'none',
        marginRight: 'xsmall',
        textAlign: 'left'
      },
      vertical: {
        flexDirection: 'column',
        marginBottom: 'xsmall',
        marginRight: 'none',
        textAlign: 'center'
      }
    } as const
  )[atomicAlign];

  return {
    containerStyle: {
      flexDirection,
      marginBottom: resolveToken(spacing, marginBottom),
      marginRight: resolveToken(spacing, marginRight)
    },
    pressableStyle: {
      borderWidth: 0,
      outlineWidth: 0
    },
    activityIndicatorStyle: {
      color: activityIndicatorColor,
      size: resolveToken(iconSizes, 'medium')
    },
    textStyle: {
      fontFamily: typography.fontFamily,
      color: fontColor,
      fontSize: resolveToken(typography.fontSizes, textStyle?.size),
      fontWeight: resolveToken(typography.fontWeights, weight || 'light'),
      lineHeight: resolveToken(typography.lineHeights, textStyle?.lineHeight),
      letterSpacing: resolveToken(
        typography.letterSpacing,
        textStyle?.letterSpacing
      ),
      textAlign,
      textDecorationLine
    }
  } as const;
}
