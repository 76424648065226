import React from 'react';
import { Circle, Path, Rect } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => {
  return (
    <>
      <Circle cx={12} cy={14.236} r={1.482} stroke="#000" strokeWidth={0} />
      <Path
        fill="#000"
        strokeWidth={0}
        fillRule="evenodd"
        d="M6.6 9.418a.76.76 0 0 0-.76.76v9.892c0 .42.34.76.76.76h10.8c.42 0 .76-.34.76-.76v-9.892a.76.76 0 0 0-.76-.76H6.6Zm10.55.935H6.85v9.542h10.3v-9.542Z"
        clipRule="evenodd"
      />
      <Rect
        width={1.3}
        height={3.407}
        x={11.35}
        y={14.428}
        fill="#000"
        rx={0.65}
        strokeWidth={0}
      />
      <Path
        stroke="#000"
        strokeWidth={0}
        fillRule="evenodd"
        d="M15.565 9.418V7.735a3.565 3.565 0 0 0-7.13 0v1.683h-1V7.735a4.565 4.565 0 1 1 9.13 0v1.683h-1Z"
        clipRule="evenodd"
      />
    </>
  );
};

export const PrivacyIcon = createIcon({
  viewBox: '0 0 24 24',
  vectorComponent
});
