import React from 'react';
import { useTranslation } from 'react-i18next';
import { type RightToWork } from '@common/constants/data';
import { Column } from '@components/Column';
import { Checkbox } from '@components/FormElements';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { TextLink } from '@components/TextLink';
import { PremiumFeaturesBadge } from '../../../../../PremiumFeaturesBadge';
import { useRightToWorkOptions } from './useRightToWorkOptions';

type RightToWorkFilterProps = {
  value?: RightToWork[];
  onClear: () => void;
  onChange: (selectedValues: RightToWork[]) => void;
};

export const RightToWorkFilter = ({
  onChange,
  onClear,
  value = []
}: RightToWorkFilterProps) => {
  const { t } = useTranslation();
  const options = useRightToWorkOptions();

  return (
    <Column spacing="large">
      <Row justifyContent="space-between" alignItems="center">
        <Row alignItems="center" spacing="medium">
          <Text weight="medium">
            {t('applications.applicationsFilter.rightToWork.title')}
          </Text>

          <PremiumFeaturesBadge />
        </Row>

        <TextLink
          variant={value.length ? 'primary' : 'secondary'}
          onPress={onClear}
          testID="clear-filter-button"
        >
          {t('applications.applicationsFilter.clear')}
        </TextLink>
      </Row>

      <OptionsColumn
        options={options}
        value={value}
        onChange={onChange}
        onClear={onClear}
      />
    </Column>
  );
};

type OptionsColumnProps = RightToWorkFilterProps & {
  options: ReturnType<typeof useRightToWorkOptions>;
};

const OptionsColumn = ({
  options,
  value = [],
  onChange,
  onClear
}: OptionsColumnProps) => {
  const handleCheckbox = (checkboxValue: RightToWork) => {
    const isCurrentlySelected = value.includes(checkboxValue);
    const newValue = isCurrentlySelected
      ? value.filter((item) => item !== checkboxValue)
      : value.concat(checkboxValue);

    if (newValue.length === 0) {
      return onClear();
    }

    return onChange(newValue);
  };

  return (
    <Stack
      spacing="medium"
      direction={{ desktop: 'row', tablet: 'column', mobile: 'column' }}
      width="full"
    >
      {options.map(({ key, label, value: checkboxValue }) => (
        <Row
          width={{
            desktop: '1/2',
            tablet: 'full',
            mobile: 'full'
          }}
        >
          <Checkbox
            key={key}
            value={value.includes(checkboxValue)}
            label={label}
            testID={`right-to-work-filter-option-${key}`}
            onChange={() => handleCheckbox(checkboxValue)}
          />
        </Row>
      ))}
    </Stack>
  );
};
