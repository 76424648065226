import type { T } from '../types';

interface GivenNameErrors {
  required: string;
}

export const givenName = (t: T) => {
  const errors = t('validations.user.givenName', {
    returnObjects: true
  }) as GivenNameErrors;

  return {
    required: errors.required
  };
};
