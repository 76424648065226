import { ProfileApplication } from 'je-bff/src/applications/list/transformers';

export const sortByRightToWork = (
  applicationA: ProfileApplication,
  applicationB: ProfileApplication
) => {
  const currentApplication = Boolean(applicationA.candiRightToWork);
  const nextApplication = Boolean(applicationB.candiRightToWork);

  if (currentApplication && !nextApplication) {
    return -1;
  }
  if (!currentApplication && nextApplication) {
    return 1;
  }

  return 0;
};
