import { useGetPaymentLink } from '@common/hooks/appPageLinks';
import { useRedirect } from '@common/hooks/useRedirect';
import { AD_TYPE } from '@seek/je-shared-data';

type Params = {
  jobId: string;
  campaign?: string;
};

export const useCurrencyPaymentPressed = ({ campaign, jobId }: Params) => {
  const { redirect } = useRedirect();
  const maxPaymentPageLink = useGetPaymentLink({
    adType: AD_TYPE.MAX,
    campaign,
    jobId
  });

  const plusPaymentPageLink = useGetPaymentLink({
    adType: AD_TYPE.PLUS,
    campaign,
    jobId
  });

  return {
    onPlusAdPressed: redirect(plusPaymentPageLink),
    onMaxAdPressed: redirect(maxPaymentPageLink)
  };
};
