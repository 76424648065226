import { useCallback, useState } from 'react';
import { UpgradeableAdType } from '@src/job/adType';
import { useModal } from '@components/Modal';

export function useCoinPaymentModal() {
  const { openModal, closeModal, isModalOpen } = useModal();
  const [modalAdType, setModalAdType] = useState<UpgradeableAdType>();

  const openCoinPaymentModal = useCallback(
    (adType: UpgradeableAdType) => {
      setModalAdType(adType);
      openModal();
    },
    [openModal]
  );

  const closeCoinPaymentModal = useCallback(() => {
    setModalAdType(undefined);
    closeModal();
  }, [closeModal]);

  return {
    openModal: openCoinPaymentModal,
    closeModal: closeCoinPaymentModal,
    isModalOpen,
    modalAdType
  };
}
