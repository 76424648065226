import invertBy from 'lodash.invertby';
import {
  BrandName,
  CountryToCurrencyMap,
  CurrencySymbols,
  SupportedCountryCode,
  SupportedCurrencies
} from '@seek/je-shared-data/lib/types/brand';
import { getLimitedBusinessCountryCodes } from '@store/config';
import { useAppSelector } from '@store/hooks';
import { getBrandName } from '@store/localisation';

export const useChangeCurrencyModal = () => {
  const brand = useAppSelector(getBrandName) || BrandName.JORA;
  const limitedCountryCodes = useAppSelector(
    getLimitedBusinessCountryCodes(brand)
  );

  const brandSupportedCurrencies = Object.keys(SupportedCurrencies).filter(
    (key) =>
      invertBy(CountryToCurrencyMap)[key].some(
        (country: SupportedCountryCode) => limitedCountryCodes.includes(country)
      )
  );

  const sortedCurrencyOptions = brandSupportedCurrencies.sort().map((key) => {
    return {
      key,
      label: `${CurrencySymbols[key]} ${key}`
    };
  });

  const length = sortedCurrencyOptions.length;
  const isLengthOdd = length % 2 !== 0;

  const currencyOptions = {
    leftColumn: sortedCurrencyOptions.slice(0, Math.ceil(length / 2)),
    rightColumn: sortedCurrencyOptions.slice(
      Math.floor(length / 2) + (isLengthOdd ? 1 : 0),
      length
    )
  };
  return {
    currencyOptions
  };
};
