import queryString from 'query-string';
import { push } from 'redux-first-history';
import { LOCALISED_COUNTRIES } from '@common/constants/data';
import REQUEST_KEY from '@common/constants/requestKeys';
import { convertDDMMYYYToIso } from '@common/helpers/dateTime';
import { ClientError, ErrorPreventLogging } from '@common/helpers/errors';
import { formatRecruitmentCompanyName } from '@common/helpers/jobs';
import { mismatchedStripeAccount } from '@common/hooks/featureToggles/useMonetisationEnabled';
import { GENERAL_EXCEPTION, JOB_EXCEPTION } from '@seek/je-error-helper';
import { AD_TYPE, JOB_STATUS } from '@seek/je-shared-data';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { PAYMENT_CAMPAIGN } from '@store/payment/constants';
import { CreateJobItem, PostData, trackPostJobSuccess } from '.';
import * as draftActions from '../../../../draft/actions';
import { JOB_DRAFT_FORM } from '../../../../draft/constants';
import { set as setUserData } from '../../../../userData/actions';
import * as constants from '../../constants';
import { toJobServiceEndpoint } from '../stateUtils/toJobServiceEndpoint';
import { toFormattedJob } from './toFormattedJob';

export function create({ userData, businessData, t, ...data }) {
  return {
    types: [
      constants.POST_JOB,
      constants.POST_JOB_SUCCESS,
      constants.POST_JOB_FAIL
    ],
    promise: async (dispatch, getState, fetch): Promise<CreateJobItem> => {
      const jobServiceEndpoint = toJobServiceEndpoint(getState);
      const state = getState();
      const { localisation } = state;
      const {
        config: {
          featureToggles: { monetisation: monetisationSupportedCountries }
        }
      } = state;
      const {
        auth: { currentUser }
      } = state;

      const {
        jobDescription,
        jobType,
        jobTitle,
        sourceParams,
        shiftAvailability,
        rightToWork,
        startDate,
        workExperience,
        originalJobId,
        jobRole,
        salary
      } = data;
      const { id: userId, mobile: mobilePhone } = userData;
      const impersonatorId = (state.userData['source.query'] || {})
        .impersonatorId;

      if (!t) {
        throw Error('Translation object undefined');
      }

      const {
        id: businessId,
        geolocation,
        name: businessName,
        recruitmentCompanyName,
        token: businessToken
      } = businessData;
      const { state: businessState, suburb: businessSuburb } = geolocation;

      const companyWithBusinessName = recruitmentCompanyName
        ? formatRecruitmentCompanyName({ t, ...businessData })
        : businessName;
      const businessCountryCode = geolocation.countryCode.toLowerCase();
      const countryName = LOCALISED_COUNTRIES(t)[businessCountryCode];

      const sourceParamsString = queryString.stringify(sourceParams);

      const postData: PostData = {
        data: {
          type: 'jobs',
          attributes: {
            repostFrom: originalJobId,
            userId,
            consentGranted: true,
            countryCode: businessCountryCode,
            description: jobDescription,
            jobType,
            shiftAvailability,
            workExperience,
            mobilePhone,
            rightToWork,
            title: jobTitle,
            sourceParams: sourceParamsString ? sourceParamsString : undefined,
            company: companyWithBusinessName,
            businessId,
            location: `${businessSuburb}, ${businessState}`,
            geolocation,
            businessName,
            recruitmentCompanyName,
            businessToken,
            expressVerificationToken: sourceParams?.ev,
            ...(startDate ? { startDate: convertDDMMYYYToIso(startDate) } : {}),
            ...(salary?.amount ? { salary } : {}),
            ...(jobRole ? { jobRole } : {})
          }
        }
      };

      const body = await fetch(
        `${jobServiceEndpoint}`,
        {
          headers: {
            'Content-Type': 'application/vnd.api+json',
            'x-jora-site': localisation.countryCode,
            'x-client-impersonator-id': impersonatorId
          },
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify(postData)
        },
        {
          requestKey: REQUEST_KEY.JOB.CREATE,
          showGlobalSpinner: true,
          onResponseError: (responseBodyError) => {
            handlePostJobError(responseBodyError, {
              t,
              country: countryName
            });
          }
        }
      );

      dispatch(
        trackPostJobSuccess({
          acquisitionChannel: sourceParams?.utm_medium,
          adType: AD_TYPE.BASIC,
          businessName,
          jobCountry: businessCountryCode,
          jobRole,
          state: businessState,
          title: jobTitle
        })
      );
      dispatch(draftActions.clearDraftForm(JOB_DRAFT_FORM));

      const jobItem = toFormattedJob({
        id: body.id,
        status: JOB_STATUS.IN_REVIEW,
        createdAt: new Date().toISOString(),
        email: currentUser.email,
        ...postData.data.attributes
      });

      if (businessData) {
        dispatch(setUserData('recentlyUsedBusiness', { id: businessId }));
      }

      const isMismatchedStripeAccount = mismatchedStripeAccount({
        brand: localisation.brandConfig.name,
        jobCountryCode: jobItem.countryCode as SupportedCountryCode,
        siteCountryCode: localisation.countryCode
      });
      if (
        monetisationSupportedCountries[localisation.brandConfig.name][
          jobItem.countryCode
        ] &&
        !isMismatchedStripeAccount
      ) {
        dispatch(
          push(
            `/${localisation.countryCode}/jobs/${body.id}/upgrade-job?fromPostJob=true&campaign=${PAYMENT_CAMPAIGN.DEFAULT}`
          )
        );
      } else {
        dispatch(
          push(`/${localisation.countryCode}/jobs/${body.id}/post-job-success`)
        );
      }

      return jobItem;
    }
  };
}

function handlePostJobError(
  body: { code: string; guid?: string },
  { t, country }
) {
  const { code, guid } = body;
  const unsupportedTierMessage = t('jobPostingNotAvailable.title', { country });

  switch (code) {
    case GENERAL_EXCEPTION.VALIDATION:
      throw new ClientError('errors.validation', {
        guid,
        responseBody: body
      });
    case JOB_EXCEPTION.LIMIT_EXCEEDED:
      throw new ErrorPreventLogging('errors.job.limitExceeded');
    case GENERAL_EXCEPTION.UNSUPPORTED_TIER:
      throw new ClientError(unsupportedTierMessage);
    default:
      throw new ClientError('errors.defaultWithGuid', {
        guid,
        responseBody: body
      });
  }
}
