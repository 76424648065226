export const KEYCODE_ENTER = 13;
export const KEYCODE_SPACEBAR = 32;

export const ENCODED_NEW_LINE_CHAR = '%0A';

export const CHARCODE_ELLIPSIS = 8230;

export const NUMBER_OF_LINES_BEFORE_ELLIPSIS = 3;

export const REDUX_FORM_PROPS = [
  'initialValue',
  'autofill',
  'onUpdate',
  'valid',
  'invalid',
  'dirty',
  'pristine',
  'error',
  'active',
  'touched',
  'visited',
  'autofilled',
  'asyncValidating',
  'dispatch',
  'initial',
  'warning',
  'submitting',
  'submitFailed'
];
