import React from 'react';
import { Controller, UseControllerProps, useForm } from 'react-hook-form';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { CLOSE_JOB_REASON_MAX_LENGTH } from '@common/constants/validation';
import { Box } from '@components/Box';
import { TextArea } from '@components/FormElements';
import { Field } from '@components/FormElements/Field';
import { FormInputs } from '../CloseJobModal';
import { useFormValidation } from '../hooks';

type AdditionalCommentsProps = {
  control: UseControllerProps<FormInputs>['control'];
  label: string;
};

export const AdditionalComments = ({
  control,
  label
}: AdditionalCommentsProps) => {
  const { additionalCommentsValidation } = useFormValidation();
  const { trigger } = useForm<FormInputs>();

  return (
    <Controller
      name="additionalComments"
      control={control}
      rules={additionalCommentsValidation.validate()}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Field
          label={label}
          message={
            error && (
              <Box paddingBottom="small">
                <ErrorMessage>{error.message}</ErrorMessage>
              </Box>
            )
          }
        >
          <Box paddingBottom="small">
            <TextArea
              testID="close-job-additional-comment-field"
              lines={2}
              value={value}
              tone={error ? 'danger' : 'neutral'}
              onChange={(data) => {
                onChange(data);
                trigger('additionalComments');
              }}
              charCountMax={CLOSE_JOB_REASON_MAX_LENGTH}
            />
          </Box>
        </Field>
      )}
    />
  );
};
