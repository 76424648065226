import React from 'react';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { Card, CardContent, CardFooter } from '@components/Card';
import { Stack } from '@components/Stack';
import { useCoinTransactions } from '../../hooks';
import { CoinTransactionsHeading } from './CoinTransactionsHeading';
import { CoinTransactionsNote } from './CoinTransactionsNote';
import { EmptyCoinTransactions } from './EmptyCoinTransactions';
import { TransactionListItem } from './TransactionListItem';
import { ViewLessAction } from './ViewLessAction';
import { ViewMoreAction } from './ViewMoreAction';

export const CoinTransactions = () => {
  const {
    transactions,
    fetchTransactionsError,
    viewLessOnPress,
    viewMoreOnPress,
    hasLessTransactions,
    hasMoreTransactions,
    isFetchTransactionsLoading
  } = useCoinTransactions();

  const shouldShowFooter = hasMoreTransactions || hasLessTransactions;

  if (isFetchTransactionsLoading && transactions.length === 0) return null;

  if (fetchTransactionsError.message) {
    return (
      <Card>
        <CardContent>
          <CoinTransactionsHeading />
          <Stack marginTop="small">
            <ErrorMessage>{fetchTransactionsError?.message}</ErrorMessage>
          </Stack>
        </CardContent>
      </Card>
    );
  }

  if (transactions.length === 0) {
    return (
      <Stack spacing="small" tone="transparent" marginBottom="medium">
        <Card>
          <CardContent>
            <CoinTransactionsHeading />
            <EmptyCoinTransactions />
          </CardContent>
        </Card>
        <CoinTransactionsNote />
      </Stack>
    );
  }

  return (
    <Stack spacing="small" tone="transparent" marginBottom="medium">
      <Card>
        <CardContent>
          <CoinTransactionsHeading />
          <Stack marginTop="small" dividers spacing="medium">
            {transactions.map((transaction, index) => (
              <TransactionListItem key={index} transaction={transaction} />
            ))}
          </Stack>
        </CardContent>

        {shouldShowFooter ? (
          <CardFooter>
            {hasMoreTransactions ? (
              <ViewMoreAction onPress={viewMoreOnPress} />
            ) : null}
            {hasLessTransactions ? (
              <ViewLessAction onPress={viewLessOnPress} />
            ) : null}
          </CardFooter>
        ) : null}
      </Card>

      <CoinTransactionsNote />
    </Stack>
  );
};
