import React from 'react';
import { ProfileApplication } from 'je-bff/src/applications/list/transformers';
import { Box } from '@components/Box';
import { Card, CardContent } from '@components/Card';
import { SearchIllustration } from '@components/Illustration';
import { Text } from '@components/Text';
import { useEmptyApplicationsInformationCard } from './hooks/useEmptyApplicationsInformationCard';
import { CurrentSelectedTab } from './hooks/useEmptyApplicationsInformationType';

type Props = {
  jobId: string;
  currentSelectedTab: CurrentSelectedTab;
  filteredApplications: ProfileApplication[];
};

export const EmptyApplicationsInformationCard = ({
  jobId,
  currentSelectedTab,
  filteredApplications
}: Props) => {
  const { emptyApplicationsInformationCopy } =
    useEmptyApplicationsInformationCard(
      jobId,
      currentSelectedTab,
      filteredApplications
    );
  const { description, subtitle } = emptyApplicationsInformationCopy || {};

  return (
    <Card testID="no-applications-information-card">
      <CardContent>
        <Box marginBottom="medium">
          <SearchIllustration size="standard" testID="search-illustration" />
        </Box>
        <Text weight="regular" align="center">
          {description}
        </Text>
        {subtitle ? <Text align="center">{subtitle}</Text> : null}
      </CardContent>
    </Card>
  );
};
