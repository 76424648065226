import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './ButtonWithSecondaryLink.scss';
import { Link } from 'react-router-dom';

import Button from '../Button/Button';
import ActionLink from '../ActionLink/ActionLink';

/**
 * @deprecated Use the @components/Button instead.
 **/
export default class ButtonWithSecondaryLink extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    linkText: PropTypes.string.isRequired,
    linkLocation: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ]),
    linkAction: PropTypes.func,
    linkProps: PropTypes.object,
    'data-test-key': PropTypes.string,
    button: PropTypes.bool,
    buttonTheme: PropTypes.string,
    centered: PropTypes.bool,
    insideCard: PropTypes.bool,
    extraTopMargin: PropTypes.bool,
    isExternal: PropTypes.bool
  };

  static defaultProps = {
    linkProps: {}
  };

  showButton() {
    const { linkText, linkLocation, linkAction, 'data-test-key': dataTestKey, buttonTheme, insideCard, isExternal } = this.props;

    return (
      <Button
        insideCard={insideCard}
        type="button"
        href={linkLocation}
        theme={buttonTheme || 'secondary'}
        className={styles.secondaryButton}
        data-test-key={dataTestKey || 'button-secondary-button-action'}
        onClick={linkAction}
        {...(isExternal ? { isExternal } : {})}>
        {linkText}
      </Button>
    );
  }

  showLink() {
    const { 'data-test-key': dataTestKey, linkText, linkLocation, linkAction, linkProps } = this.props;
    const props = {
      ...linkProps,
      'data-test-key': dataTestKey || 'button-secondary-link-action',
      className: styles.sideLink,
      onClick: linkAction
    };

    if (linkAction && !linkLocation) {
      return (
        <ActionLink onClick={linkAction} {...props}>
          {linkText}
        </ActionLink>
      );
    }

    return (
      <Link {...props} to={linkLocation || ''}>
        {linkText}
      </Link>
    );
  }

  render() {
    const { children, button, centered, extraTopMargin } = this.props;

    return (
      <div className={classNames(styles.container, { [styles.centered]: centered })}>
        <div>{children}</div>
        <div className={button && extraTopMargin ? styles.secondaryActionContainer : null}>
          {button ? this.showButton() : this.showLink()}
        </div>
      </div>
    );
  }
}