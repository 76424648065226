import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { GENERAL_EXCEPTION } from '@seek/je-error-helper';
import { Card, CardContent } from '@components/Card';
import { Column } from '@components/Column';
import { LocationIcon } from '@components/Icon';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import BusinessImageAndDetails from '../../../../common/components/BusinessImageAndDetails/BusinessImageAndDetails';
import ScreenHeading from '../../../../common/components/ScreenHeading/ScreenHeading';
import ConnectedErrorMessage from '../../../components/ConnectedErrorMessage/ConnectedErrorMessage';
import { RedirectUnauthenticatedUser } from '../../../components/RedirectUnauthenticatedUser';
import {
  BUSINESS_REGISTRATION_NUMBER_FIELD_NAME,
  INDUSTRY_ID_TRANSLATE_MAPPER
} from '../../../constants/data';
import REQUEST_KEY from '../../../constants/requestKeys';
import * as businessActions from '../../../store/entities/businesses/actions';

@connect((state, ownProps) => {
  const businesses = state.entities.businesses;
  const businessId = ownProps.match.params.businessId;
  const businessCountryCode =
    businesses.items[businessId] &&
    businesses.items[businessId].locations[0].countryCode.toLowerCase();

  return {
    loadBusinessError: state.app.error[REQUEST_KEY.BUSINESS.LOAD],
    countryCode: state.localisation.countryCode,
    businesses,
    businessId,
    currentUser: state.auth.currentUser,
    businessRegistrationNumberFieldName:
      BUSINESS_REGISTRATION_NUMBER_FIELD_NAME[businessCountryCode]
  };
})
@withTranslation()
export default class BusinessDetails extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    businesses: PropTypes.object.isRequired,
    businessId: PropTypes.string.isRequired,
    currentUser: PropTypes.object.isRequired,
    businessRegistrationNumberFieldName: PropTypes.string,
    countryCode: PropTypes.string.isRequired,
    loadBusinessError: PropTypes.object
  };

  static async fetchData({ params, store }) {
    const { businessId } = params;

    if (store.getState().auth.isAuthenticated) {
      await store.dispatch(businessActions.load(businessId));
    }
  }

  render() {
    const {
      t,
      businessId,
      businesses,
      currentUser,
      businessRegistrationNumberFieldName,
      loadBusinessError,
      countryCode
    } = this.props;
    const { items } = businesses;
    let name,
      locations,
      businessSizeKey,
      industryId,
      website,
      users,
      businessUser,
      businessRegistrationNumber;

    if (items && items[businessId]) {
      ({
        name,
        locations,
        businessSize: businessSizeKey,
        industryId,
        website,
        users,
        businessRegistrationNumber
      } = items[businessId]);
      businessUser = users.find((user) => user.userId === currentUser.id);
    }

    if (
      loadBusinessError &&
      loadBusinessError.message === GENERAL_EXCEPTION.NOT_FOUND
    ) {
      return <Redirect to={`/${countryCode}/businesses`} />;
    }

    return (
      <Stack tone="transparent">
        <RedirectUnauthenticatedUser />

        {items && items[businessId] && (
          <Stack tone="transparent">
            <Helmet title={t('businessDetailsPage.title')} />
            <ScreenHeading>{t('businessDetailsPage.heading')}</ScreenHeading>

            <Card>
              <CardContent>
                <BusinessImageAndDetails businessSize={businessSizeKey}>
                  <Text size="xlarge" weight="medium">{name}</Text>
                </BusinessImageAndDetails>

                <Column spacing="xxsmall" marginTop="medium">
                  <Text weight="medium">
                    {t('createBusinessForm.fieldLabel.businessAddress')}
                  </Text>
                  <Row alignItems="center" spacing="xxsmall">
                    <LocationIcon
                      size="large"
                      variant="outline"
                      tone="success"
                    />
                    <Text>{`${locations[0].suburb} ${locations[0].state} `}</Text>
                  </Row>
                </Column>

                <Column spacing="xxsmall" marginTop="medium">
                  <Text weight="medium">
                    {t('createBusinessForm.fieldLabel.industry')}
                  </Text>
                  <Text>{t(INDUSTRY_ID_TRANSLATE_MAPPER[industryId])}</Text>
                </Column>

                <Column spacing="xxsmall" marginTop="medium">
                  <Text weight="medium">
                    {t('createBusinessForm.fieldLabel.businessSize')}
                  </Text>
                  <Text>
                    {' '}
                    {t(`businessDetailsPage.businessSize.${businessSizeKey}`)}
                  </Text>
                </Column>

                <Column spacing="xxsmall" marginTop="medium">
                  <Text weight="medium">
                    {t('createBusinessForm.fieldLabel.role')}
                  </Text>
                  <Text>{t(`business.businessRole.${businessUser.role}`)}</Text>
                </Column>

                {businessUser.recruitmentCompanyName && (
                  <Column spacing="xxsmall" marginTop="medium">
                    <Text weight="medium">
                      {t('createBusinessForm.fieldLabel.recruitmentName')}
                    </Text>
                    <Text>{businessUser.recruitmentCompanyName}</Text>
                  </Column>
                )}

                {website && (
                  <Column spacing="xxsmall" marginTop="medium">
                    <Text weight="medium">
                      {t('createBusinessForm.fieldLabel.website')}
                    </Text>
                    <Text>{website}</Text>
                  </Column>
                )}

                {businessRegistrationNumber && (
                  <Column spacing="xxsmall" marginTop="medium">
                    <Text weight="medium">
                      {businessRegistrationNumberFieldName}
                    </Text>
                    <Text>{businessRegistrationNumber}</Text>
                  </Column>
                )}
              </CardContent>
            </Card>
          </Stack>
        )}

        <ConnectedErrorMessage
          requestKey={REQUEST_KEY.BUSINESS.LOAD}
          extraTopMargin
        />
      </Stack>
    );
  }
}
