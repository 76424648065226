import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';
import omit from 'lodash.omit';
import Form, { FormProps } from '../../../common/components/Form/Form';

@connect(
  ({ app }) => ({
    submissionError: app.submissionError
  })
)
export default class ConnectedForm extends Component {
  static propTypes = {
    requestKey: PropTypes.string.isRequired,
    submissionError: PropTypes.object,
    ...FormProps
  };

  async handleSubmit(data) {
    const { onSubmit, requestKey } = this.props;

    await onSubmit(data);

    const { submissionError } = this.props;

    if (submissionError[requestKey]) {
      throw new SubmissionError(submissionError[requestKey]);
    }
  }

  render() {
    const { reduxFormSubmit, children, ...formProps } = this.props;

    return (
      <Form
        onSubmit={reduxFormSubmit(this.handleSubmit.bind(this))}
        {...omit(formProps, 'onSubmit', 'dispatch', 'requestKey', 'submissionError')}>
        {children}
      </Form>
    );
  }
}