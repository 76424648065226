import React, { forwardRef } from 'react';
import { Text as ReactNativeText, View } from 'react-native';
import { HeadingStyleProps, useHeadingStyle } from './useHeadingStyle';

export type HeadingProps = {
  children?: string;
  testID?: string;
} & HeadingStyleProps;

export const Heading = forwardRef<View, HeadingProps>((props, ref) => {
  const { children, testID, ...restProps } = props;
  const resolvedStyles = useHeadingStyle(restProps);

  return (
    <ReactNativeText style={resolvedStyles} testID={testID} ref={ref}>
      {children}
    </ReactNativeText>
  );
});
