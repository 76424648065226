import React from 'react';
import { formatStringToTitleCase } from '@common/helpers/textFormatting';
import { Avatar } from '@components/Avatar';
import { Column } from '@components/Column';

type ApplicantAvatarProps = {
  name: string;
  testID: string;
};

export const ApplicantAvatar = (props: ApplicantAvatarProps) => {
  const { testID, name } = props;

  return (
    <Column justifyContent="center">
      <Avatar testID={testID}>{formatStringToTitleCase(name)[0]}</Avatar>
    </Column>
  );
};
