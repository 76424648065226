import { useTranslation } from 'react-i18next';
import { useGetCoinPricing } from '@common/hooks/payment';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';

type Params = {
  jobCountryCode: SupportedCountryCode;
};

export const useGetCoinPrice = ({ jobCountryCode }: Params) => {
  const { t } = useTranslation();
  const { errorMessage: coinErrorMessage, coinPrice } =
    useGetCoinPricing(jobCountryCode);

  const coinErrorText = coinErrorMessage?.message
    ? t(coinErrorMessage.message, coinErrorMessage.properties)
    : '';

  return {
    pricingText: coinPrice,
    fetchErrorMessage: coinErrorText
  };
};
