import type { BrandConfig } from '@seek/je-shared-data/lib/types/brand';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';

type BrandDomain = BrandConfig['domain'];

/**
 * Retrieves the brand domain from the brand configuration.
 * @returns {BrandDomain} The brand domain.
 */
export function useBrandDomain(): BrandDomain {
  const { brandConfig } = useAppSelector(getLocalisation);
  return brandConfig?.domain as BrandDomain;
}
