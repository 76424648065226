import React from 'react';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { ExpectedSalary } from './ExpectedSalary';
import { useExpectedSalaryValidation } from './hooks/useExpectedSalaryValidation';

export const ExpectedSalaryController = <T extends FieldValues>({
  name,
  control
}: UseControllerProps<T>) => {
  const expectedSalaryRules = useExpectedSalaryValidation();

  return (
    <Controller
      control={control}
      name={name}
      rules={expectedSalaryRules}
      render={({ field: { onChange, value, onBlur }, formState }) => {
        return (
          <ExpectedSalary
            onChange={onChange}
            value={value}
            onBlur={onBlur}
            error={formState?.errors[name]?.message as string}
          />
        );
      }}
    />
  );
};
