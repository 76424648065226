import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { Box } from '@components/Box';
import { Field, Input } from '@components/FormElements';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';

type BusinessWebsiteProps = {
  value: string | undefined;
  onChange: () => void;
  onBlur: () => void;
  error?: string;
  helperText: string;
};

export const BusinessWebsite = (props: BusinessWebsiteProps) => {
  const { t } = useTranslation();
  const { error, onChange, onBlur, value, helperText } = props;

  return (
    <Stack spacing="small">
      <Field
        label={t('createBusinessForm.fieldLabel.website')}
        message={
          error && (
            <Box marginTop="xxsmall">
              <ErrorMessage>{error}</ErrorMessage>
            </Box>
          )
        }
      >
        <Input
          id="business-form-businessWebsite"
          testID="business-form-businessWebsite"
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          autoCapitalize="words"
        />
        <Text
          size="xsmall"
          tone="neutralLight"
          testID="business-website-helper-text"
        >
          {helperText}
        </Text>
      </Field>
    </Stack>
  );
};
