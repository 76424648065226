import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <>
    <Path d="M 16.48 2.519 L 8.375 10.519 L 5.48 7.662 M 13.813 0.519 C 15.286 0.519 16.48 1.713 16.48 3.186 L 16.48 13.852 C 16.48 15.325 15.286 16.519 13.813 16.519 L 3.147 16.519 C 1.674 16.519 0.48 15.325 0.48 13.852 L 0.48 3.186 C 0.48 1.713 1.674 0.519 3.147 0.519 L 13.813 0.519 Z" />
  </>
);

export const TickIcon = createIcon({ viewBox: '0 0 17 17', vectorComponent });
