import { useTranslation } from 'react-i18next';
import { SORT_APPLICATIONS_OPTIONS } from '@common/constants/data';

export const useSortApplicationsOptions = () => {
  const { t } = useTranslation();

  const options = SORT_APPLICATIONS_OPTIONS(t);

  return [...options];
};
