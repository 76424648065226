import { useTranslation } from 'react-i18next';
import { APPLICATION_STATUS_FILTER_OPTIONS } from '@common/constants/data';

export const useApplicantStatusOptions = () => {
  const { t } = useTranslation();

  const options = APPLICATION_STATUS_FILTER_OPTIONS(t);

  return [...options];
};
