import { useRegister } from './useRegister';
import { useSendVerificationEmail } from './useSendVerificationEmail';
import { useVerifyEmail } from './useVerifyEmail';

export function useRegistration() {
  const register = useRegister();
  const sendVerificationEmail = useSendVerificationEmail();
  const verifyEmail = useVerifyEmail();

  return {
    register,
    sendVerificationEmail,
    verifyEmail
  };
}
