import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextWithIcon } from '@common/components/TextWithIcon';
import { AD_TYPE } from '@seek/je-shared-data';
import { UpgradeableAdType } from '@src/job/adType';
import { MaxAdIcon, PlusAdIcon } from '@components/Icon';
import { Text } from '@components/Text';

type AdTypeTextWithIconProps = {
  adType: UpgradeableAdType;
};

export const AdTypeTextWithIcon = ({ adType }: AdTypeTextWithIconProps) => {
  const { t } = useTranslation();

  if (adType === AD_TYPE.MAX) {
    return (
      <TextWithIcon
        icon={
          <MaxAdIcon
            size="xxlarge"
            variant="outline"
            tone="success"
            testID="max-ad-icon"
          />
        }
        spacing="medium"
      >
        <Text size="xlarge" weight="regular">
          {t('upgradeJobForm.max.name')}
        </Text>
      </TextWithIcon>
    );
  } else if (adType === AD_TYPE.PLUS) {
    return (
      <TextWithIcon
        icon={
          <PlusAdIcon
            size="xxlarge"
            variant="outline"
            tone="success"
            testID="plus-ad-icon"
          />
        }
        spacing="medium"
      >
        <Text size="xlarge" weight="regular">
          {t('upgradeJobForm.plus.name')}
        </Text>
      </TextWithIcon>
    );
  } else {
    throw new Error(`Invalid ad upgrade type: ${adType}`);
  }
};
