import React, { Fragment } from 'react';

type ThenProps = {
  children: React.ReactNode;
  condition?: boolean;
};

export const Then = ({ children, condition }: ThenProps) => {
  return <Fragment>{condition && children}</Fragment>;
};
