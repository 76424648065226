import React from 'react';
import { Slider as BaseSlider, SliderSingleProps } from 'antd';
import { colors } from '@theme/tokens/colors';
import { spacing } from '@theme/tokens/spacing';

export type SliderProps = {
  /**
   * Current value of the slider
   */
  value: number;

  /**
   * Callback function that is triggered when the slider value changes
   */
  onChange: (value: number) => void;

  /**
   * Marks on the slider
   */
  marks: SliderSingleProps['marks'];

  /**
   * Minimum value of the slider
   */
  min: number;

  /**
   * Maximum value of the slider
   */
  max: number;

  /**
   * Disables the slider
   */
  disabled?: boolean;
};

export const Slider = ({
  marks,
  min,
  max,
  onChange,
  value,
  disabled = false
}: SliderProps) => {
  return (
    <div className="slider">
      <BaseSlider
        marks={marks}
        min={min}
        max={max}
        onChange={onChange}
        disabled={disabled}
        styles={{
          rail: {
            backgroundColor: colors.grey200,
            height: spacing.xsmall,
            marginTop: '-2px' // Adjust to center the rail
          },
          track: {
            backgroundColor: colors.green700,
            height: spacing.xsmall,
            marginTop: '-2px' // Adjust to center the track
          }
        }}
        tooltip={{ open: false }}
        value={value}
      />
    </div>
  );
};
