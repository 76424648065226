import React from 'react';
import { Breakpoint } from '@theme/tokens';
import { BreakpointContext } from './BreakpointContext';
import { useResponsiveValue } from './BreakpointContext/useResponsiveValue';
import { PlatformContext, usePlatform } from './PlatformContext';
import { ThemeContext } from './ThemeContext';
import type { BaseTheme } from './ThemeContext/makeBaseTheme';

type Props = {
  children: React.ReactNode;
  defaultBreakpoint?: Breakpoint;
  theme: BaseTheme;
};

export const DesignSystemProvider = ({
  theme,
  defaultBreakpoint = 'mobile',
  children
}: Props) => {
  const breakpoint = useResponsiveValue(defaultBreakpoint);
  const platform = usePlatform();

  return (
    <ThemeContext.Provider value={theme}>
      <BreakpointContext.Provider value={breakpoint}>
        <PlatformContext.Provider value={platform}>
          {children}
        </PlatformContext.Provider>
      </BreakpointContext.Provider>
    </ThemeContext.Provider>
  );
};
