import type { BrandName } from '@seek/je-shared-data/lib/types/brand';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';

/**
 * Retrieves the brand name from the brand configuration.
 * @returns {BrandName} The brand name.
 */
export function useBrandName(): BrandName {
  const { brandConfig } = useAppSelector(getLocalisation);
  return brandConfig?.name as BrandName;
}
