import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { AlreadyHaveAccountLink } from '@caja/Form/forms/components';
import { CajaContext } from '@caja/context';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { ApplicationsRecipient } from '@common/components/PostJobForm/components/ApplicationsRecipient';
import { useJobTitleAutoSuggest } from '@common/hooks/featureToggles';
import { REDIRECT_TO } from '@common/search';
import { Button } from '@components/Button';
import { Stack } from '@components/Stack';
import { useListenForChanges } from '../hooks';
import { FormContext } from './FormContext';
import { hydrateInfoFormProps } from './props/hydrateDefaultProps';
import {
  ExpectedSalary,
  JobDescription,
  JobStartDate,
  JobTitle,
  JobType,
  RightToWork,
  ShiftAvailability,
  Submit,
  WorkExperience
} from './topLevelFields';
import Business from './topLevelFields/Business';
import JobTitleAutoSuggest from './topLevelFields/jobTitleAutoSuggest';
import type {
  CompleteInfoFormData,
  FormProps,
  IncompleteInfoFormData
} from './types';

export default function InfoForm(opts: FormProps) {
  const { updateDebounce, userEmail, allowBusinessAndEmailChange, t } =
    useContext(CajaContext);
  const props = hydrateInfoFormProps(opts, t);
  const { onChange, onSubmit, onCancel, defaultValues } = props;

  const jobTitleAutoSuggestEnabled = useJobTitleAutoSuggest();

  const {
    handleSubmit,
    watch,
    control,
    getValues,
    formState: { errors, isValid, isSubmitted }
  } = useForm<IncompleteInfoFormData>({
    defaultValues,
    mode: 'onBlur'
  });

  function verifySubmit(data: IncompleteInfoFormData) {
    onSubmit(data as CompleteInfoFormData);
  }

  useListenForChanges({ watch, updateDebounce, onChange });

  return (
    <FormContext.Provider value={{ control, errors, t }}>
      <Stack spacing="large">
        {userEmail && (
          <ApplicationsRecipient
            email={userEmail!}
            isEditable={Boolean(allowBusinessAndEmailChange)}
          />
        )}
        {jobTitleAutoSuggestEnabled ? <JobTitleAutoSuggest /> : <JobTitle />}
        <JobType />
        {getValues('jobType') && <ShiftAvailability />}
        <RightToWork />
        <WorkExperience />
        <JobStartDate />
        <ExpectedSalary />
        <JobDescription
          jobDescriptionLength={props.jobDescriptionLength}
          onInvalidDescription={props.onInvalidDescription}
        />
        {userEmail && (
          <Business
            isBusinessEditable={allowBusinessAndEmailChange}
            shouldRedirectBusinessCreatedTo={REDIRECT_TO.postJob}
          />
        )}
        <Stack
          spacing="medium"
          direction={{ mobile: 'column', tablet: 'row', desktop: 'row' }}
        >
          <Submit onPress={handleSubmit(verifySubmit)}>
            {props.submitButtonLabel}
          </Submit>
          {onCancel && (
            <Button variant="secondary" onPress={() => onCancel()}>
              {t('common.action.cancel')}
            </Button>
          )}
        </Stack>

        {isSubmitted && !isValid ? (
          <ErrorMessage>{t('validations.general.summary')}</ErrorMessage>
        ) : null}

        <AlreadyHaveAccountLink />
      </Stack>
    </FormContext.Provider>
  );
}
