import { AD_TYPE } from '@seek/je-shared-data';
import { UpgradeableAdType } from '@src/job/adType';

export type OnPressed = (adType: UpgradeableAdType) => void;

export const useCoinPaymentPressed = (onPressed: OnPressed) => {
  const onPlusAdPressed = () => onPressed(AD_TYPE.PLUS);
  const onMaxAdPressed = () => onPressed(AD_TYPE.MAX);

  return {
    onPlusAdPressed,
    onMaxAdPressed
  };
};
