import { ClientError } from '@common/helpers/errors';
import { extractTrpcErrorProperties } from '@src/trpc/lib';
import {
  ErrorResponse,
  FetchSignedVideoUrlParams,
  QueryOptions,
  useFetchVideoSignedUrl
} from './useFetchVideoSignedUrl';

export const useQueryVideoSignedUrl = (
  params: FetchSignedVideoUrlParams,
  queryOptions?: QueryOptions
) => {
  const { data, error, isLoading, isSuccess } = useFetchVideoSignedUrl(
    params,
    queryOptions
  );

  const clientError = getClientError(
    extractTrpcErrorProperties<ErrorResponse>(error)
  );

  return {
    signedUrl: data,
    isLoading,
    isSuccess,
    errorMessage: clientError
  };
};

const getClientError = (errorProperties) => {
  if (!errorProperties) return;

  return new ClientError('errors.defaultWithGuid', {
    guid: errorProperties.guid,
    responseBody: errorProperties.responseBody
  });
};
