import React from 'react';
import { useTranslation } from 'react-i18next';
import { SalaryRate } from '@seek/je-shared-data/lib/types/jobs';
import { Box } from '@components/Box';
import { Dropdown, Field } from '@components/FormElements';
import { Text } from '@components/Text';
import { useRateSelector } from './hooks/useRateSelector';

type RateProps = {
  value: SalaryRate;
  onChange: (string) => void;
};

export const RateSelector = (props: RateProps) => {
  const { t } = useTranslation();
  const { rateOptions } = useRateSelector();
  const { onChange, value } = props;

  return (
    <Field
      label={
        <Box paddingBottom="xsmall">
          <Text size="small">
            {t('postJobForm.expectedSalary.label.salaryRate')}
          </Text>
        </Box>
      }
    >
      <Box>
        <Dropdown
          items={rateOptions}
          selectedItem={value}
          onChange={onChange}
          testID="job-form-salary-rate"
        />
      </Box>
    </Field>
  );
};
