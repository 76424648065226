import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <>
    <Path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="m3.15 6.746 7.437 7 7.438-7"
    />
  </>
);

export const UpArrowIcon = createIcon({
  viewBox: '0 0 21 22',
  vectorComponent
});
