import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import omit from 'lodash.omit';
import Button, { ButtonProps } from '../../../common/components/Button/Button';

@connect(
  ({ app }) => ({
    isLoading: app.loadStack.length > 0 || app.loadStackWithSpinner.length > 0
  })
)
export default class Submit extends Component {
  static propTypes = {
    ...ButtonProps,
    isLoading: PropTypes.bool.isRequired
  };

  render() {
    const { children, isLoading, ...restProps } = this.props;

    return (
      <Button {...omit(restProps, 'dispatch')} type="submit" disabled={isLoading}>{children}</Button>
    );
  }
}