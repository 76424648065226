/* eslint css-modules/no-unused-class: [2, { markAsUsed: [ 'input', 'suggestionsContainerOpen', 'suggestion', 'suggestionHighlighted' ] }] */
import { Component } from 'react';
import classNames from 'classnames';
import debounce from 'lodash.debounce';
import omit from 'lodash.omit';
import PropTypes from 'prop-types';
import AutoSuggest from 'react-autosuggest';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { LOCALISED_COUNTRIES } from '../../../../joraEmployer/constants/data';
import * as actions from '../../../../joraEmployer/store/googleLocationAutoSuggest/actions';
import { REDUX_FORM_PROPS } from '../../../constants/general';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import SearchIcon from '../../Icons/SearchIcon';
import Spinner from '../../Spinner/Spinner';
import ReduxFormField from '../ReduxFormField/ReduxFormField';
import styles from './GoogleLocationAutoSuggest.scss';

const DEBOUNCE_DURATION = 200;

@withTranslation()
@ReduxFormField
@connect((state) => state.googleLocationAutoSuggest, {
  fetchSuggestions: actions.fetchSuggestions,
  fetchLocationData: actions.fetchLocationData,
  clearLocationData: actions.clearLocationData
})
export default class GoogleLocationAutoSuggest extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    fieldId: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    error: PropTypes.string,
    touched: PropTypes.bool,
    firstField: PropTypes.bool,
    initialValue: PropTypes.object.isRequired,
    fetchSuggestions: PropTypes.func.isRequired,
    fetchLocationData: PropTypes.func.isRequired,
    clearLocationData: PropTypes.func.isRequired,
    locationsSuggested: PropTypes.array.isRequired,
    locationSuggestError: PropTypes.string,
    locationDetailsError: PropTypes.string,
    selectedLocationData: PropTypes.object.isRequired,
    extraTopMargin: PropTypes.bool,
    suburbSearch: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    country: PropTypes.string.isRequired,
    value: PropTypes.any,
    t: PropTypes.func.isRequired
  };

  constructor(props) {
    const { initialValue } = props;

    super(props);
    this.state = {
      displayValue: initialValue.streetAddress || '',
      locationSelected: initialValue
    };

    this.debouncedLocationAutoSuggest = debounce(
      this.locationAutoSuggest,
      DEBOUNCE_DURATION
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.locationSuggestError || nextProps.locationDetailsError) {
      this.clearFormValue();
    } else {
      this.updateFormValue(nextProps, this.props.value);
    }
  }

  clearFormValue() {
    const { onChange, clearLocationData } = this.props;
    const { locationSelected } = this.state;
    const clearedValues = {
      streetAddress: '',
      lat: null,
      lon: null,
      street: null,
      suburb: null,
      state: null,
      postcode: null,
      formattedAddress: null,
      administrativeAreaLevels: null
    };

    if (JSON.stringify(locationSelected) !== JSON.stringify(clearedValues)) {
      clearLocationData();

      this.setState({
        locationSelected: clearedValues
      });

      onChange(clearedValues);
    }
  }

  updateFormValue(props, prevPropsValue) {
    const { selectedLocationData, onChange } = props;
    const { displayValue } = this.state;

    if (selectedLocationData.lat && selectedLocationData.lon) {
      const locationSelected = {
        ...selectedLocationData
      };

      locationSelected.streetAddress = displayValue;

      this.setState({ locationSelected });

      onChange(locationSelected);
    }

    if (typeof prevPropsValue === 'object' && typeof props.value === 'string') {
      if (prevPropsValue.streetAddress === props.value) {
        onChange(prevPropsValue);
      }
    }
  }

  locationAutoSuggest(searchTerm) {
    const { country, fetchSuggestions, suburbSearch } = this.props;

    if (searchTerm.trim() === '') {
      return;
    }

    fetchSuggestions(searchTerm, !!suburbSearch, country);
  }

  shouldRenderSuggestions = (value) => value.trim().length > 2;

  highlightSearchTerm(suggestionText) {
    const { displayValue } = this.state;
    const startIndex = suggestionText
      .toLowerCase()
      .indexOf(displayValue.toLowerCase());
    const endIndex = startIndex + displayValue.length;

    if (startIndex === -1) {
      return this.renderHighlightSearchTerm({ before: suggestionText });
    }

    return this.renderHighlightSearchTerm({
      before: suggestionText.slice(0, startIndex),
      highlighted: suggestionText.slice(startIndex, endIndex),
      after: suggestionText.slice(endIndex, suggestionText.length)
    });
  }

  removeCountryText(suggestion) {
    const { t, country } = this.props;
    const descriptionString = suggestion.description;
    const fullCountryName = LOCALISED_COUNTRIES(t)[country];

    if (
      descriptionString &&
      descriptionString.indexOf(`, ${fullCountryName}`) !== -1
    ) {
      const suggestionTermsLength = suggestion.terms.length;
      let description = '';

      suggestion.terms.forEach((term, index) => {
        if (index !== suggestionTermsLength - 1) {
          description = description + term.value;

          if (index !== suggestionTermsLength - 2) {
            description = isNaN(term.value)
              ? description + ', '
              : description + ' ';
          }
        }
      });

      return description;
    }

    return descriptionString;
  }

  renderSuggestion(suggestion) {
    const suggestionDisplayText = this.removeCountryText(suggestion);

    return <span>{this.highlightSearchTerm(suggestionDisplayText)}</span>;
  }

  renderSearchBarVisual(loading) {
    if (loading) {
      return (
        <div className={styles.spinner}>
          <Spinner size="30" delay={0} />
        </div>
      );
    }

    return <SearchIcon className={styles.searchBarIcon} />;
  }

  renderHighlightSearchTerm({ before, highlighted, after }) {
    if (!highlighted && !after) {
      return before;
    }

    return (
      <span>
        <span>{before}</span>
        <span className={styles.highlightSuggestion}>{highlighted}</span>
        <span>{after}</span>
      </span>
    );
  }

  getSuggestionValue(suggestion) {
    return this.removeCountryText(suggestion);
  }

  onSuggestionSelected(e, { suggestion }) {
    const { fetchLocationData, suburbSearch } = this.props;

    fetchLocationData(suggestion, !!suburbSearch);
  }

  onChange(e, { newValue }) {
    this.clearFormValue();

    this.setState({
      displayValue: newValue
    });
  }

  onSuggestionsFetchRequested({ value }) {
    this.debouncedLocationAutoSuggest(value);
  }

  onSuggestionsClearRequested() {
    //fn required by 3rd party component but not used
  }

  searchBarVisual() {
    const { loading } = this.props;

    return this.renderSearchBarVisual(loading);
  }

  apiErrorMessages() {
    const { t, locationSuggestError, locationDetailsError } = this.props;

    if (locationSuggestError) {
      return <ErrorMessage>{t(locationSuggestError)}</ErrorMessage>;
    }
    if (locationDetailsError) {
      return <ErrorMessage>{t(locationDetailsError)}</ErrorMessage>;
    }
  }

  render() {
    const { firstField, ...restProps } = this.props;
    const { displayValue } = this.state;
    const {
      label,
      fieldId,
      locationsSuggested,
      error,
      touched,
      extraTopMargin
    } = this.props;
    const inputProps = {
      value: displayValue,
      onChange: this.onChange.bind(this),
      id: fieldId,
      'data-test-key': 'google-location-autosuggest'
    };

    const autoSuggestProps = {
      onSuggestionSelected: this.onSuggestionSelected.bind(this),
      onSuggestionsFetchRequested: this.onSuggestionsFetchRequested.bind(this),
      onSuggestionsClearRequested: this.onSuggestionsClearRequested.bind(this),
      shouldRenderSuggestions: this.shouldRenderSuggestions.bind(this),
      getSuggestionValue: this.getSuggestionValue.bind(this),
      renderSuggestion: this.renderSuggestion.bind(this),
      suggestions: locationsSuggested,
      firstField,
      inputProps
    };

    const propsToSpread = omit(
      { ...restProps },
      'extraTopMargin',
      't',
      'tReady',
      'clearLocationData',
      'mainDisplayValue',
      'locationSuggestError',
      'renderHighlightSearchTerm',
      'renderSearchBarVisual',
      'fetchLocationData',
      'fetchSuggestions',
      'selectedLocationData',
      'locationsSuggested',
      'locationDetailsError',
      'renderSuggestion',
      'loading',
      'suburbSearch',
      'fieldId',
      REDUX_FORM_PROPS
    );

    return (
      <div
        className={classNames({ [styles.extraTopMargin]: extraTopMargin })}
        {...propsToSpread}
      >
        <div className={error && touched ? styles.invalid : styles.root}>
          <label className={styles.label} htmlFor={fieldId}>
            {label}
          </label>
          <AutoSuggest theme={styles} {...autoSuggestProps} />
          {this.searchBarVisual()}
          {this.apiErrorMessages()}
        </div>
      </div>
    );
  }
}
