import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSiteCountryCode } from '@common/hooks/localisation';
import { useRedirect } from '@common/hooks/useRedirect';
import { Row } from '@components/Row';
import { Text } from '@components/Text';
import { TextLink } from '@components/TextLink';

export function AlreadyHaveAccountLink() {
  const { t } = useTranslation();
  const countryCode = useSiteCountryCode();
  const { redirect } = useRedirect();
  return (
    <Row spacing="xxsmall" alignItems="center">
      <Text>{t('registerForm.description.alreadyHaveAnAccount') + ' '}</Text>
      <TextLink
        data-test-key="already-have-an-account-login-link"
        onPress={redirect(`/${countryCode}/login`)}
      >
        {t('common.action.login')}
      </TextLink>
    </Row>
  );
}
