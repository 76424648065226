import React from 'react';
import { useTranslation } from 'react-i18next';
import { Trans as I18nextTrans } from 'react-i18next';
import { openExternalURL } from '@common/helpers/externalConnection';
import { useSupportSalesEmail } from '@common/hooks/localisation';
import { useBrandNameText } from '@common/hooks/useBrandNameText';
import { useRedirect } from '@common/hooks/useRedirect';
import { Card, CardContent } from '@components/Card';
import { Column } from '@components/Column';
import { Heading } from '@components/Heading';
import { Text } from '@components/Text';
import { TextLink } from '@components/TextLink';
import { useFaqLink } from './useFaqLink';

type Props = {
  showExpiryInformation?: boolean;
};

export const CoinFaqSection = (props: Props) => {
  const { showExpiryInformation } = props;
  const brandNameText = useBrandNameText();
  const salesEmail = useSupportSalesEmail();
  const faqLink = useFaqLink();

  const { t } = useTranslation();
  const { redirect } = useRedirect();

  return (
    <Card>
      <CardContent>
        <Heading level="h2">{t('myCoinsPage.about.heading')}</Heading>
        <Column spacing="medium" marginTop="small">
          <Text>
            {t('myCoinsPage.about.generalDescription', { brandNameText })}
          </Text>

          {showExpiryInformation ? (
            <Text>{t('myCoinsPage.about.expiryDescription')}</Text>
          ) : null}

          {salesEmail ? (
            <I18nextTrans
              i18nKey={'myCoinsPage.about.contactUs'}
              values={{ brandNameText }}
              parent={Text}
              components={[
                <TextLink
                  onPress={openExternalURL.bind(null, `mailto:${salesEmail}`)}
                />
              ]}
            />
          ) : null}

          <I18nextTrans
            i18nKey={'myCoinsPage.about.faq'}
            parent={Text}
            components={[<TextLink onPress={redirect(faqLink)} />]}
          />
        </Column>
      </CardContent>
    </Card>
  );
};
