import { TrpcRouter } from 'je-bff/src/handlers/bff';
import { useHandleAppLoading } from '@common/hooks/appLoading';
import { GENERAL_EXCEPTION } from '@seek/je-error-helper';
import { useAppDispatch } from '@store/hooks';
import { refresh } from '@store/navigation/actions';
import { inferRouterInputs } from '@trpc/server';
import { useGetApplications } from './queries/useGetApplications';

type TrpcRouterInput = inferRouterInputs<TrpcRouter>;
type Query = keyof TrpcRouterInput;
type QueryParam = TrpcRouterInput[Query];

export const useAppQuery = (
  query: Query,
  params: QueryParam,
  requestKey: string
) => {
  const dispatch = useAppDispatch();

  const result = trpcQueries[query](params);

  useHandleAppLoading(result.isLoading || result.isRefetching, requestKey);

  if (result.error?.message === GENERAL_EXCEPTION.UNVERIFIED_USER) {
    dispatch(refresh());
  }

  return result;
};

const trpcQueries = {
  getApplicationProfiles: useGetApplications
};
