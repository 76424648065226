import React, { forwardRef } from 'react';
import { StyleProp, View, ViewProps, ViewStyle } from 'react-native';
import { BoxStyleProps, useBoxStyle } from './useBoxStyle';

export type BoxProps = {
  children?: React.ReactNode;
  testID?: ViewProps['testID'];
} & BoxStyleProps;

export const Box = forwardRef<View, BoxProps>((props, ref) => {
  const { children, testID, ...restProps } = props;
  const { visible, ...resolvedStyles } = useBoxStyle(restProps);

  if (!visible) {
    return null;
  }

  return (
    <View
      style={resolvedStyles as StyleProp<ViewStyle>}
      testID={testID}
      ref={ref}
    >
      {children}
    </View>
  );
});
