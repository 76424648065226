import { useAppDispatch } from '@store/hooks';
import {
  TrackReasonParams,
  TrackScoreParams,
  track,
  trackReason
} from '@store/nps';

export function useTrackNps() {
  const dispatch = useAppDispatch();

  return {
    trackNpsScore: (data: TrackScoreParams) => dispatch(track(data)),
    trackNpsReason: (data: TrackReasonParams) => dispatch(trackReason(data))
  };
}
