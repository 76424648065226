import React, { forwardRef, useEffect, useState } from 'react';
import { TextInput, TextInputProps, View } from 'react-native';
import MaskInput, { Mask, UseMaskedInputProps } from 'react-native-mask-input';
import { InputTone, useInputStyle, useTextStyle } from './useInputStyle';

export type InputProps = {
  placeholder?: TextInputProps['placeholder'];
  keyboardType?: TextInputProps['keyboardType'];
  value: TextInputProps['value'];
  onChange:
    | TextInputProps['onChangeText']
    | UseMaskedInputProps['onChangeText'];
  onBlur?: TextInputProps['onBlur'];
  testID?: TextInputProps['testID'];
  disabled?: boolean;
  tone?: 'neutral' | 'danger';
  mask?: Mask;
  maskAutoComplete?: boolean;
  autoCapitalize?: TextInputProps['autoCapitalize'];
  autoFocus?: TextInputProps['autoFocus'];
  id: TextInputProps['id'];
  inputMode?: TextInputProps['inputMode'];
  secureTextEntry?: TextInputProps['secureTextEntry'];
  onSubmitEditing?: TextInputProps['onSubmitEditing'];
  prefixIcon?: React.ReactNode;
  icon?: React.ReactNode;
  expanded?: boolean;
};

const getTextInputTone = ({
  defaultTone,
  disabled
}: {
  defaultTone: InputTone;
  disabled?: boolean;
}) => (disabled ? 'successMuted' : defaultTone);

export const Input = forwardRef<TextInput, InputProps>((props, ref) => {
  const {
    id,
    inputMode,
    testID,
    disabled,
    onChange,
    onBlur,
    tone = 'neutral',
    prefixIcon,
    icon,
    ...restProps
  } = props;

  const defaultTone = getTextInputTone({
    defaultTone: tone || 'neutral',
    disabled
  });
  const activeTone = getTextInputTone({
    defaultTone: 'success',
    disabled
  });

  const [textAreaTone, setTone] = useState<InputTone>(defaultTone);
  useEffect(() => setTone(tone), [tone]);

  const {
    container: resolvedContainerStyle,
    prefixIconContainer: resolvedPrefixIconContainerStyle,
    inputField: resolvedInputFieldStyle,
    placeholder: resolvedPlaceholderStyle
  } = useInputStyle({
    tone: textAreaTone,
    expanded: props.expanded,
    hasPrefixIcon: !!prefixIcon
  });
  const resolvedTextStyle = useTextStyle({ tone: textAreaTone });

  return (
    <View style={resolvedContainerStyle}>
      {!!prefixIcon && (
        <View style={resolvedPrefixIconContainerStyle}>{prefixIcon}</View>
      )}
      <MaskInput
        id={id}
        inputMode={inputMode}
        placeholderTextColor={resolvedPlaceholderStyle.color}
        onChangeText={onChange}
        style={[resolvedInputFieldStyle, resolvedTextStyle]}
        ref={ref}
        testID={testID}
        onFocus={() => setTone(activeTone)}
        onBlur={(e) => {
          onBlur?.(e);
          setTone(defaultTone);
        }}
        onKeyPress={() => setTone(activeTone)}
        onPressIn={() => setTone(activeTone)}
        onPressOut={() => {
          setTone(defaultTone);
        }}
        editable={!disabled}
        selectTextOnFocus={!disabled}
        underlineColorAndroid="rgba(0,0,0,0)"
        autoCorrect
        {...restProps}
      />
      {icon}
    </View>
  );
});
