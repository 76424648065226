export const REDIRECT_TO = {
  postJob: 'PostJob',
  repostJob: 'RepostJob'
} as const;

export type RedirectTo = (typeof REDIRECT_TO)[keyof typeof REDIRECT_TO];

export type SearchParamProps = {
  shouldRedirectTo: RedirectTo;
  jobId?: string;
};

/**
 * Filters out invalid search parameters from the given object.
 * Invalid parameters are those that are undefined or empty strings.
 *
 * @param searchParams - The search parameters object to filter.
 * @returns The filtered search parameters object.
 */
export const filterInvalidSearchParams = (searchParams: SearchParamProps) =>
  Object.fromEntries(
    Object.entries(searchParams).filter(
      ([, value]) => value !== undefined && value !== ''
    )
  );
