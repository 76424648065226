import React, { ReactNode } from 'react';
import { Text, TextProps } from '@components/Text';
import { TextLink } from '@components/TextLink';
import { useRevealText } from './useRevealText';

export type RevealTextProps = {
  children: string | ReactNode;
  revealActionText: string;
  truncatedText?: string | ReactNode;
  onRevealPressed?: () => {};
  testID?: TextProps['testID'];
};

export const RevealText = (props: RevealTextProps) => {
  const { children, truncatedText, revealActionText, onRevealPressed, testID } =
    props;
  const { shouldDisplayExpanded, setTextExpanded } = useRevealText({
    truncatedText,
    fullText: children
  });

  const handleShowMoreClicked = () => {
    if (onRevealPressed) {
      onRevealPressed();
    }

    setTextExpanded();
  };

  if (shouldDisplayExpanded) {
    return <Text testID={`${testID}-expanded`}>{children}</Text>;
  }

  return (
    <Text testID={`${testID}-truncated`}>
      {truncatedText}{' '}
      <TextLink
        testID={`${testID}-show-more-link`}
        onPress={handleShowMoreClicked}
      >
        {revealActionText}
      </TextLink>
    </Text>
  );
};
