import { Component } from 'react';
import PropTypes from 'prop-types';
import querystring from 'query-string';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Card, CardContent } from '@components/Card';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { Column } from '@components/Column';
import Spinner from '../../../common/components/Spinner/Spinner';
import { ALLOWED_REDIRECT_PATHS } from '../../constants/data';

const buildRedirectPathWithParams = (search) => {
  const parsed = querystring.parse(search);
  const { redirectPath, ...rest } = parsed;
  if (Object.keys(rest).length) {
    return `${redirectPath}?${querystring.stringify(rest)}`;
  }
  return redirectPath;
};

@withTranslation()
@connect(
  ({ router, localisation }) => ({
    siteCountryCode: localisation.countryCode,
    redirectPath: buildRedirectPathWithParams(router?.location?.search)
  }),
  null
)
export default class RedirectFromThirdParty extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    redirectPath: PropTypes.string,
    siteCountryCode: PropTypes.string
  };

  componentDidMount() {
    const { redirectPath, siteCountryCode } = this.props;
    const origin = window.location.origin;

    const canRedirect =
      redirectPath &&
      ALLOWED_REDIRECT_PATHS.find((regex) => regex.test(redirectPath));

    if (canRedirect) {
      window.location.replace(`${origin}/${siteCountryCode}/${redirectPath}`);
    } else {
      window.location.replace(`/${siteCountryCode}/home`);
    }
  }

  render() {
    const { t } = this.props;

    return (
      <Stack tone="transparent">
        <Helmet title={t('redirectPage.heading')} />
        <Spinner size="80" fullPageOverlay />

        <Card>
          <CardContent>
            <Column spacing="xxsmall">
              <Text weight="medium">{t('redirectPage.heading')}</Text>
              <Text>{t('redirectPage.weAreRedirectingYou')}</Text>
            </Column>
          </CardContent>
        </Card>
      </Stack>
    );
  }
}
