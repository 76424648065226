import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { UpdateInteractionParams } from '@common/hooks/application/interaction';
import { Application } from '@common/types/Application';
import { getJobById } from '@store/entities/jobs/selectors';
import { useAppSelector } from '@store/hooks';
import { Badge } from '@components/Badge';
import { Box } from '@components/Box';
import { CardAction } from '@components/Card';
import { CommentIcon } from '@components/Icon';
import { Link } from '@components/Link';
import { useModal } from '@components/Modal';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { TextScreeningAnswerModal } from './TextScreeningAnswerModal';
import { useOnSuccessfulInteraction } from './useOnSuccessfulInteraction';
import { useViewTextScreeningAnswer } from './useViewTextScreeningAnswer';

type ViewScreeningAnswerActionProps = {
  application: Application;
  jobId: string;
};

export const ViewTextScreeningAnswerAction = ({
  application,
  jobId
}: ViewScreeningAnswerActionProps) => {
  const { id, isScreeningAnswerViewed } = application;
  const { t } = useTranslation();
  const { isModalOpen, openModal, closeModal } = useModal();
  const { company } = useAppSelector(getJobById(jobId));
  const onSuccessfulInteraction = useOnSuccessfulInteraction(openModal);
  const { onViewTextScreeningAnswerPress, errorMessage, isAnswerViewed } =
    useViewTextScreeningAnswer({
      applicationId: id,
      onSuccess: onSuccessfulInteraction
    });

  return (
    <>
      <CardAction>
        <Stack spacing="small" width="full" alignItems="center">
          <Box
            alignItems="center"
            direction={{
              mobile: 'column',
              tablet: 'row',
              desktop: 'row'
            }}
          >
            <Link
              onPress={() =>
                onViewTextScreeningAnswerPress(
                  'viewedScreeningAnswer' as UpdateInteractionParams['interaction']
                )
              }
              align={{
                mobile: 'vertical',
                tablet: 'horizontal',
                desktop: 'horizontal'
              }}
              variant="secondary"
              icon={<CommentIcon variant="outline" />}
            >
              {t('applications.applicationCard.viewScreeningAnswer')}
            </Link>

            {!isScreeningAnswerViewed && !isAnswerViewed && (
              <Row
                marginTop={{
                  mobile: 'xsmall',
                  tablet: 'none',
                  desktop: 'none'
                }}
                marginLeft={{
                  mobile: 'none',
                  tablet: 'xsmall',
                  desktop: 'xsmall'
                }}
              >
                <Badge tone="promo">{t('badge.newBadge')}</Badge>
              </Row>
            )}
          </Box>

          {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
        </Stack>
      </CardAction>

      <TextScreeningAnswerModal
        application={application}
        jobCompany={company}
        testID={`view-screening-answer-modal-${id}`}
        onClose={closeModal}
        visible={isModalOpen}
      />
    </>
  );
};
