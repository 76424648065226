import { JOB_STATUS } from '@seek/je-shared-data';
import { LiveOrExpired } from './types';

export const liveOrInReview = (jobStatus: JOB_STATUS | undefined) =>
  !!jobStatus && [JOB_STATUS.IN_REVIEW, JOB_STATUS.LIVE].includes(jobStatus);

export const isLive = (jobStatus: JOB_STATUS | undefined) =>
  jobStatus === JOB_STATUS.LIVE;

export const isExpired = (jobStatus: JOB_STATUS | undefined) =>
  jobStatus === JOB_STATUS.EXPIRED;

/**
 * Check if the job is live or expired to display expired data
 * @param {JOB_STATUS} status - The status of the job
 * @returns {boolean} - True if the job is live or expired
 */
export const isLiveOrExpired = (status: JOB_STATUS): status is LiveOrExpired =>
  [JOB_STATUS.LIVE, JOB_STATUS.EXPIRED].includes(status);
