import React from 'react';
import { EmailVerificationCode as InnerField } from '../../../components/EmailVerificationCode';
import { emailVerificationCode } from '../../../rules';
import { FieldWrapper } from '../FieldWrapper';

export const EmailVerificationCode = () => {
  return (
    <FieldWrapper<string>
      name="emailVerificationCode"
      rules={emailVerificationCode}
      render={(props) => {
        return <InnerField {...props} />;
      }}
    />
  );
};
