import { REGEX } from '@common/constants/data';
import {
  MAX_LENGTH,
  MIN_LENGTH,
  RECRUITMENT_COMPANY_NAME_MAX_LENGTH,
  RECRUITMENT_COMPANY_NAME_MIN_LENGTH
} from '@common/constants/validation';
import type { T } from '@src/caja/Form/types';

/**
 * Generates validation rules for the recruitment company name field.
 * @param t - The translation function.
 * @returns The validation rules object.
 */
export function recruitmentCompanyNameRules(t: T) {
  return {
    required: t('validations.business.recruitmentCompanyName.required'),
    [MAX_LENGTH]: {
      value: RECRUITMENT_COMPANY_NAME_MAX_LENGTH,
      message: t('validations.business.recruitmentCompanyName.maxLength')
    },
    [MIN_LENGTH]: {
      value: RECRUITMENT_COMPANY_NAME_MIN_LENGTH,
      message: t('validations.business.recruitmentCompanyName.minLength')
    },
    pattern: {
      value: REGEX.CONTAINS_ANGLE_BRACKET,
      message: t('validations.business.recruitmentCompanyName.noAngleBracket')
    }
  };
}
