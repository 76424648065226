import { UpdateAccountDetailsFormFields } from '@common/components/Account/forms/updateAccountDetails/types';
import { UpdateAccountDetailsActionProps } from '@store/auth/types';

export function mapFormData(
  data: UpdateAccountDetailsFormFields
): UpdateAccountDetailsActionProps {
  const {
    confirmEmail,
    mobileNumber: { diallingCode, phoneNumber },
    givenName,
    surname
  } = data;

  return {
    email: confirmEmail,
    diallingCode,
    mobileNumber: phoneNumber,
    givenName,
    surname
  };
}
