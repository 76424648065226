import React from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from '@components/Column';
import { Text } from '@components/Text';
import {
  ApplicationStatusStyleProps,
  useApplicationStatus
} from './useApplicationStatus';

type ApplicationStatusProps = ApplicationStatusStyleProps & {
  testID?: string;
};

export const ApplicationStatus = ({
  status,
  count,
  testID
}: ApplicationStatusProps) => {
  const { t } = useTranslation();
  const { displayCount, tone } = useApplicationStatus({ status, count });

  return (
    <Column
      flex={1}
      flexShrink={1}
      shadow="medium"
      padding="medium"
      tone={tone}
      testID={testID}
    >
      <Text size="xlarge" weight="bold" align="center" tone={tone}>
        {displayCount}
      </Text>
      <Text size="xsmall" align="center" tone={tone}>
        {t(`applications.status.${status}`)}
      </Text>
    </Column>
  );
};
