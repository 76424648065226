import React, { ReactElement } from 'react';
import { AD_TYPE } from '@seek/je-shared-data';
import type { BrandName } from '@seek/je-shared-data/lib/types/brand';
import type { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { maxBenefits, plusBenefits } from '@src/job';
import { UpgradeableAdType } from '@src/job/adType';
import { MaxAdUpgradeCard } from './components/MaxAdUpgradeCard';
import { PlusAdUpgradeCard } from './components/PlusAdUpgradeCard';

export type AdUpgradeCardProps = {
  adDuration?: {
    [AD_TYPE.BASIC]: number | undefined;
    [AD_TYPE.PLUS]: number | undefined;
    [AD_TYPE.MAX]: number | undefined;
  };
  brand: BrandName;
  country: SupportedCountryCode;
  price: ReactElement;
  type: UpgradeableAdType;
  onButtonPress: () => void;
};

export const AdUpgradeCard = ({
  adDuration,
  brand,
  country,
  price,
  type,
  onButtonPress
}: AdUpgradeCardProps) => {
  if (type === AD_TYPE.PLUS) {
    return (
      <PlusAdUpgradeCard
        adDuration={adDuration}
        price={price}
        onButtonPress={onButtonPress}
        hireCandidatesFaster={plusBenefits.showHireCandidatesFaster({ brand })}
        jobFeaturedForXDays={plusBenefits.showJobFeaturedForXDays({ brand })}
        jobFeaturedForXDaysPromo={plusBenefits.showJobFeaturedForXDaysPromo({
          brand
        })}
        priorityPlacementOverBasic={plusBenefits.showPriorityPlacementOverBasic(
          {
            brand
          }
        )}
        threeTimesMoreViews={plusBenefits.showThreeTimesMoreViews({ brand })}
        xTimesMoreCandidateViews={plusBenefits.showXTimesMoreCandidateViews({
          brand
        })}
      />
    );
  } else if (type === AD_TYPE.MAX) {
    return (
      <MaxAdUpgradeCard
        adDuration={adDuration}
        price={price}
        onButtonPress={onButtonPress}
        featuredOnSocialMedia={maxBenefits.showFeaturedOnSocialMedia({
          brand,
          country
        })}
        fourTimesMoreSearchResultViews={maxBenefits.showFourTimesMoreSearchResultViews(
          {
            brand,
            country
          }
        )}
        hireCandidatesFaster={maxBenefits.showHireCandidatesFaster({
          brand,
          country
        })}
        jobFeaturedForXDays={maxBenefits.showJobFeaturedForXDays({
          brand,
          country
        })}
        jobFeaturedForXDaysPromo={maxBenefits.showJobFeaturedForXDaysPromo({
          brand,
          country
        })}
        priorityPlacementOverBasicAndPlus={maxBenefits.showPriorityPlacementOverBasicAndPlus(
          {
            brand
          }
        )}
        sixTimesMoreViews={maxBenefits.showSixTimesMoreViews({
          brand,
          country
        })}
        xTimesMoreCandidateViews={maxBenefits.showXTimesMoreCandidateViews({
          brand
        })}
      />
    );
  } else {
    throw new Error(`Invalid ad upgrade type: ${type}`);
  }
};
