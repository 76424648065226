import React from 'react';
import { useBrandName } from '@common/hooks/localisation';
import { BrandName } from '@seek/je-shared-data/lib/types/brand';
import {
  JobsDBPostJobIllustration,
  JobstreetPostJobIllustration,
  JoraPostJobIllustration
} from '@components/Illustration';

export const ShowPostJobIllustration = () => {
  const brand = useBrandName();
  if (brand === BrandName.JOBSDB) {
    return (
      <JobsDBPostJobIllustration
        size="large"
        testID="jobsdb-postjob-illustration"
      />
    );
  }

  if (brand === BrandName.JOBSTREETVN) {
    return (
      <JobstreetPostJobIllustration
        size="large"
        testID="jobstreet-postjob-illustration"
      />
    );
  }

  if (brand === BrandName.JOBSTREETEXPRESS) {
    return null;
  }

  return (
    <JoraPostJobIllustration size="large" testID="jora-postjob-illustration" />
  );
};
