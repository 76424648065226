import { AD_TYPE } from '@seek/je-shared-data';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { useFormatCurrencyPricing } from './useFormatCurrencyPricing';

/**
 * Custom hook to get currency pricing based on the job country code.
 * @param jobCountryCode The country code of the job.
 * @returns An object containing currency pricing for different ad types.
 */
export const useGetCurrencyPricing = (jobCountryCode: SupportedCountryCode) => {
  return {
    currencyPrice: {
      plus: useFormatCurrencyPricing(jobCountryCode, AD_TYPE.PLUS),
      max: useFormatCurrencyPricing(jobCountryCode, AD_TYPE.MAX)
    }
  };
};
