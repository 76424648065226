import { useCallback, useEffect, useRef } from 'react';
import { Alert, Platform } from 'react-native';
import { TFunction, useTranslation } from 'react-i18next';
import { getResumeUrl } from '@store/entities/applications/actions';
import { getTemporaryResumeLink } from '@store/entities/applications/selectors';
import { useAppDispatch, useAppSelector } from '@store/hooks';

export function useDownloadResumeCardAction(applicationId: string) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isDownloadRequestedRef = useRef(false);
  const temporaryResumeLink = useAppSelector(
    getTemporaryResumeLink(applicationId)
  );

  useEffect(() => {
    if (isDownloadRequestedRef.current && temporaryResumeLink) {
      isDownloadRequestedRef.current = false;

      downloadAndOpenResume(temporaryResumeLink.url, {
        onGeneralError: () => showGeneralError(t),
        onDownloadError: () => showDownloadError(t),
        onOpenError: (fileExtension) => showOpenError(t, fileExtension)
      });
    }
  }, [t, temporaryResumeLink]);

  const downloadResume = useCallback(async () => {
    isDownloadRequestedRef.current = true;
    await dispatch(getResumeUrl(applicationId));
  }, [applicationId, dispatch]);

  return {
    downloadResume
  };
}

type ErrorHandlers = {
  onGeneralError: () => void;
  onDownloadError: () => void;
  onOpenError: (fileExtension: string) => void;
};

async function downloadAndOpenResume(
  url: string,
  errorHandlers: ErrorHandlers
) {
  if (Platform.OS === 'web') {
    window.location.href = url;
  } else {
    const fileInfo = await downloadFile(url, errorHandlers);
    if (fileInfo) {
      const { downloadFilePath, fileExtension } = fileInfo;
      await openFile(downloadFilePath, fileExtension, errorHandlers);
    }
  }
}

async function downloadFile(
  url: string,
  { onGeneralError, onDownloadError }: ErrorHandlers
) {
  const decodedUrl = decodeURIComponent(url);
  const filenameAndExtension = decodedUrl.match(/"(.*)"/)?.[1];
  if (!filenameAndExtension) {
    return onGeneralError();
  }

  const fs = (await import('react-native-fs')).default;
  const downloadFolder = fs.DocumentDirectoryPath;
  const downloadFilePath = `${downloadFolder}/${filenameAndExtension}`;

  try {
    const result = await fs.downloadFile({
      fromUrl: url,
      toFile: downloadFilePath
    }).promise;

    return result.statusCode === 200
      ? {
          downloadFilePath,
          fileExtension: filenameAndExtension?.split('.')[1]
        }
      : onDownloadError();
  } catch {
    onDownloadError();
  }
}

async function openFile(
  downloadFilePath: string,
  fileExtension: string,
  { onOpenError }: ErrorHandlers
) {
  const viewer = (await import('react-native-file-viewer')).default;

  try {
    await viewer.open(downloadFilePath, {
      showOpenWithDialog: true,
      showAppsSuggestions: true
    });
  } catch {
    onOpenError(fileExtension);
  }
}

function showGeneralError(t: TFunction) {
  Alert.alert(
    t('errors.application.resume.download.title'),
    t('errors.default')
  );
}

function showDownloadError(t: TFunction) {
  Alert.alert(
    t('errors.application.resume.download.title'),
    t('errors.application.resume.download.description')
  );
}

function showOpenError(t: TFunction, fileExtension: string) {
  Alert.alert(
    t('errors.application.resume.fileOpen.title'),
    t('errors.application.resume.fileOpen.description', { fileExtension })
  );
}
