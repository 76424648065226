import { Platform } from 'react-native';
import { useTheme } from '@theme/DesignSystemProvider/ThemeContext';
import { Sizes } from '@theme/tokens';
import { useResolveToken } from '@theme/tokens/utils/useResolveToken';

export type DropdownTone = 'neutral' | 'success' | 'danger' | 'successMuted';

export type DropdownStyleProps = {
  tone?: DropdownTone;
  width?: Sizes;
};

export function useDropdownStyle({
  tone = 'neutral',
  width
}: DropdownStyleProps) {
  const { formElement, spacing, border, sizes } = useTheme();
  const { resolveToken } = useResolveToken();
  const { container, color: formElementColors } = formElement;
  const { borderColor } = formElementColors[tone];

  return {
    container: {
      maxWidth: container.maxWidth,
      width: resolveToken(sizes, width)
    },
    dropdownContainerField: {
      paddingLeft: resolveToken(spacing, container.paddingHorizontal),
      paddingTop: resolveToken(spacing, container.paddingTop),
      paddingBottom: resolveToken(spacing, container.paddingBottom),
      borderWidth: resolveToken(border.width, container.borderWidth),
      borderStyle: container.borderStyle,
      borderRadius: resolveToken(spacing, container.borderRadius),
      borderColor,
      ...(Platform.OS === 'web' ? { outlineStyle: 'none' } : {})
    }
  } as const;
}

export function useTextStyle({ tone = 'neutral' }: DropdownStyleProps) {
  const textStyleValues = {
    size: 'standard',
    tone,
    weight: 'light'
  } as const;

  const {
    typography: {
      fontFamily,
      text,
      fontSizes,
      fontWeights,
      lineHeights,
      letterSpacing
    },
    formElement
  } = useTheme();
  const { resolveToken } = useResolveToken();
  const { color: formElementColors } = formElement;
  const { fontColor } = formElementColors[tone];

  const {
    size: textSize,
    lineHeight: textLineHeight,
    letterSpacing: textLetterSpacing
  } = resolveToken(text, textStyleValues.size) || {};

  return {
    fontFamily,
    fontSize: resolveToken(fontSizes, textSize),
    lineHeight: resolveToken(lineHeights, textLineHeight),
    fontWeight: resolveToken(fontWeights, textStyleValues.weight),
    color: fontColor,
    letterSpacing: resolveToken(letterSpacing, textLetterSpacing),
    ...(Platform.OS === 'android'
      ? ({ textAlignVertical: 'top' } as const)
      : {})
  };
}
