import React, { forwardRef } from 'react';
import { View, ViewProps } from 'react-native';
import { Text } from '@components/Text';
import { useAvatarStyle } from './useAvatarStyle';

export type AvatarProps = {
  children?: string;
  testID?: ViewProps['testID'];
};

export const Avatar = forwardRef<View, AvatarProps>((props, ref) => {
  const { children, testID } = props;
  const resolvedStyles = useAvatarStyle();

  return (
    <View style={resolvedStyles} ref={ref} testID={testID}>
      <Text tone="success" weight="medium">
        {children}
      </Text>
    </View>
  );
});
