import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <>
    <Path
      d="M 3.485 1 C 2.758 0.961 2.295 1.216 1.811 1.701 C 1.326 2.185 1.071 2.653 1.11 3.38 L 1.11 21.305 L 1.11 20.305 L 1.222 20.305 L 1.122 21.321 C 1.073 21.822 1.24 22.123 1.588 22.493 C 1.937 22.863 2.21 23.051 2.737 23 L 24.52 23 C 25.068 23.024 25.437 22.878 25.872 22.576 C 26.307 22.275 26.574 21.98 26.744 21.459 L 30.436 11.63 L 30.436 11.581 L 30.502 11.454 C 30.798 10.88 30.655 10.476 30.303 9.966 C 29.951 9.455 29.626 9.178 28.984 9.25 L 27.61 9.25 L 27.61 7.125 C 27.649 6.398 27.394 5.9350000000000005 26.909 5.451 C 26.425 4.966 25.957 4.711 25.23 4.75 L 15.143 4.75 L 14.781 4.75 L 11.428 1.957 L 11.405 1.957 L 11.148 1.725 C 10.557 1.193 9.943 0.971 9.149000000000001 1 L 3.485 1 Z"
      strokeWidth="2"
    />
    <Path
      d="M 28.984 9.5 C 29.358 9.553 29.935 9.792 30.136 10.083 C 30.336 10.374 30.353 10.995 30.269 11.363 C 30.269 11.364 30.267 11.367 30.267 11.368 L 26.51 21.372 C 26.35 21.728 26.033 22.163 25.731 22.372 C 25.429 22.581 24.911 22.725 24.52 22.749 L 2.737 22.749 C 2.365 22.697 1.79 22.461 1.589 22.172 C 1.387 21.883 1.367 21.259 1.449 20.891 L 1.485 20.729 L 1.5270000000000001 20.688 L 5.211 10.876 C 5.371 10.52 5.687 10.086 5.989 9.876 C 6.29 9.667 6.809 9.523 7.2 9.499 L 8.2 9.436 L 8.2 9.5 L 28.984 9.5 Z"
      strokeWidth="2"
    />
    <Path
      d="M 9.15 1.25 C 9.76 1.28 10.504 1.55 10.99 1.917 L 14.657 4.9719999999999995 C 14.708 5.054 14.642 5.03 14.735 5 L 25.235 5 C 25.752 5.042 26.411 5.286 26.743 5.617 C 27.074 5.949 27.318 6.608 27.36 7.125 L 27.36 9.25 L 26.36 9.25 L 7.201 9.25 C 6.6530000000000005 9.226 6.283 9.372 5.848 9.674 C 5.413 9.975 5.146 10.268 4.976 10.79 L 1.3599999999999999 20.42 L 1.3599999999999999 3.375 C 1.4020000000000001 2.858 1.646 2.199 1.9769999999999999 1.867 C 2.309 1.536 2.968 1.292 3.485 1.25 L 9.15 1.25 Z"
      strokeWidth="2"
    />
  </>
);

export const FolderIcon = createIcon({
  viewBox: '0 0 33 24',
  vectorComponent
});
