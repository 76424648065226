import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Card from '../../../common/components/Card/Card';
import CardCopy from '../../../common/components/Card/components/CardCopy/CardCopy';
import Div from '../../../common/components/Div/Div';
import SadManIllustration from '../../../common/components/Illustrations/SadManIllustration';
import ImageWithTitle from '../../../common/components/ImageWithTitle/ImageWithTitle';
import ScreenHeading from '../../../common/components/ScreenHeading/ScreenHeading';
import { unsubscribeFromEmail } from '../../store/unsubscribe/actions';

@connect(
  (state) => ({
    localisation: state.localisation
  }),
  {
    unsubscribeFromEmail
  }
)
@withTranslation()
export default class Unsubscribe extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    unsubscribeFromEmail: PropTypes.func.isRequired,
    localisation: PropTypes.shape({
      supportEmail: PropTypes.string.isRequired,
      brandNameText: PropTypes.string.isRequired
    })
  };

  componentDidMount() {
    const {
      location: { search },
      unsubscribeFromEmail
    } = this.props;
    const queryParams = queryString.parse(search);

    if (queryParams.userId) {
      unsubscribeFromEmail({
        userId: queryParams.userId
      });
    }
  }

  render() {
    const {
      t,
      localisation: { brandNameText }
    } = this.props;

    return (
      <Div>
        <Helmet title={t('unsubscribePage.title')} />
        <ScreenHeading>{t('unsubscribePage.heading')}</ScreenHeading>
        <Card>
          <ImageWithTitle
            smallTitle
            mediumIcon
            svgComponent={SadManIllustration}
            title={t('unsubscribePage.description.youAreUnsubscribed', {
              brandNameText
            })}
          >
            <CardCopy extraTopMargin>
              {t('unsubscribePage.description.stillReceiveEmails')}
            </CardCopy>
          </ImageWithTitle>
        </Card>
      </Div>
    );
  }
}
