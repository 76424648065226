import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <>
    <Path
      d="M21.5 13.2869C21.5 13.2869 17.5273 17.5 12 17.5C6.47272 17.5 2.5 13.2869 2.5 13.2869C2.5 13.2869 6.47272 7.5 12 7.5C17.5273 7.5 21.5 13.2869 21.5 13.2869Z"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M12 16C13.933 16 15.5 14.433 15.5 12.5C15.5 10.567 13.933 9 12 9C10.067 9 8.5 10.567 8.5 12.5C8.5 14.433 10.067 16 12 16Z"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path d="M12 14.5C13.1046 14.5 14 13.5431 14 12.5C14 11.4569 13.1046 10.5 12 10.5C10.8954 10.5 10 11.4569 10 12.5C10 13.5431 10.8954 14.5 12 14.5Z" />
  </>
);

export const EyeIcon = createIcon({ viewBox: '0 0 24 24', vectorComponent });
