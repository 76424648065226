import { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './IconWithActionText.scss';
import Link from '../Link/Link';
import ExternalLink from '../ExternalLink/ExternalLink';
import ActionLink from '../ActionLink/ActionLink';
import Spinner from '../Spinner/Spinner';
import classNames from 'classnames';

export default class IconWithActionText extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    icon: PropTypes.any.isRequired,
    isStacked: PropTypes.bool,
    stackForSmallScreen: PropTypes.bool,
    iconTheme: PropTypes.string,
    to: PropTypes.string,
    onClick: PropTypes.func,
    isLoading: PropTypes.bool,
    isExternalLink: PropTypes.bool,
    'data-test-key': PropTypes.string,
    removeRightSpacing: PropTypes.bool
  };

  getClassNames() {
    const { isStacked, stackForSmallScreen, removeRightSpacing } = this.props;

    if (isStacked) {
      return {
        containerStyle: classNames(styles.rootStacked, {
          [styles.removeRightSpacing]: removeRightSpacing
        }),
        iconContainerStyle: styles.iconContainerStacked
      };
    }

    if (stackForSmallScreen) {
      return {
        containerStyle: classNames(styles.rootStackForSmallScreen, {
          [styles.removeRightSpacing]: removeRightSpacing
        }),
        iconContainerStyle: styles.iconContainerStackForSmallScreen
      };
    }

    return {
      containerStyle: classNames(styles.root, {
        [styles.removeRightSpacing]: removeRightSpacing
      }),
      iconContainerStyle: styles.iconContainer
    };
  }

  render() {
    const { text, icon: Icon, to, onClick, iconTheme, isLoading, isExternalLink, 'data-test-key': dataTestKey } = this.props;
    const { containerStyle, iconContainerStyle } = this.getClassNames();

    if (isLoading) {
      const mediumIconSize = '20';

      return (
        <ActionLink onClick={(e) => {
          e.preventDefault();
        }} data-test-key={dataTestKey || 'icon-with-action-link-text'}>
          <div className={containerStyle}>
            <div className={iconContainerStyle}>
              <Spinner size={mediumIconSize} delay={0} />
            </div>
            <div className={styles.text}>{text}</div>
          </div>
        </ActionLink>
      );
    }

    const icon = (
      <div className={iconContainerStyle}>
        <Icon className={styles.icon} theme={iconTheme} />
      </div>
    );

    if (to) {
      if (isExternalLink) {
        return (
          <ExternalLink data-test-key={dataTestKey || 'icon-with-action-link-text'} href={to} target="_blank">
            <div className={containerStyle}>
              {icon}
              <div className={styles.text}>{text}</div>
            </div>
          </ExternalLink>
        );
      }
      return (
        <Link to={to} data-test-key={dataTestKey || 'icon-with-action-link-text'}>
          <div className={containerStyle}>
            {icon}
            <div className={styles.text}>{text}</div>
          </div>
        </Link>
      );
    }

    if (onClick) {
      return (
        <ActionLink onClick={onClick} data-test-key={dataTestKey || 'icon-with-action-link-text'}>
          <div className={containerStyle}>
            {icon}
            <div className={styles.text}>{text}</div>
          </div>
        </ActionLink>
      );
    }
  }
}