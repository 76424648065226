import React from 'react';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { Box } from '@components/Box';
import { Field, Input } from '@components/FormElements';
import { Stack } from '@components/Stack';
import { useBusinessRegistrationFieldName } from './hooks/useBusinessRegistration';

type BusinessRegistrationProps = {
  value: string | undefined;
  onChange: () => void;
  onBlur: () => void;
  error?: string;
};

export const BusinessRegistration = (props: BusinessRegistrationProps) => {
  const { error, onChange, onBlur, value } = props;
  const { businessRegistrationFieldName } = useBusinessRegistrationFieldName();

  return (
    <Stack spacing="small">
      <Field
        label={businessRegistrationFieldName} // use hooks?
        message={
          error && (
            <Box marginTop="xxsmall">
              <ErrorMessage>{error}</ErrorMessage>
            </Box>
          )
        }
      >
        <Input
          id="business-form-businessRegistration"
          testID="business-form-businessRegistration"
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          autoCapitalize="words"
        />
      </Field>
    </Stack>
  );
};
