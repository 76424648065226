import { Component } from 'react';
import PropTypes from 'prop-types';

import Link from '../Link/Link';
import ExternalLink from '../ExternalLink/ExternalLink';
import ActionLink from '../ActionLink/ActionLink';
import Div from '../Div/Div';
import styles from './IconShowCase.styles';
import { isNative } from '../../helpers/detection';

export default class IconShowCase extends Component {
  static propTypes = {
    dataSet: PropTypes.arrayOf(PropTypes.shape({
      icon: PropTypes.node.isRequired,
      title: PropTypes.node.isRequired,
      to: PropTypes.string,
      onClick: PropTypes.func,
      isExternalLink: PropTypes.bool
    })),
    marginTop: PropTypes.string,
    'data-test-key': PropTypes.string,
    maxRowItems: PropTypes.number
  };

  renderIconAndText = ({ index, icon, title, dataTestKey }) => (
    <Div
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      key={index}
      data-test-key={dataTestKey}
    >
      {icon}
      <Div marginTop="small">
        {title}
      </Div>
    </Div>
  );

  renderExternalLink = ({ key, to, onClick, children }) => (
    <ExternalLink
      href={to}
      target="_blank"
      key={key}
      onClick={onClick}
    >
      {children}
    </ExternalLink>
  );

  renderLink = ({ key, to, children }) => (
    <Link to={to} key={key}>
      {children}
    </Link>
  );

  renderActionLink = ({ key, onClick, children }) => (
    <ActionLink onClick={(e) => onClick(e, key)} key={key}>
      {children}
    </ActionLink>
  );

  getActionComponentToRender = (cardItem) => {
    if (cardItem.to) {
      if (cardItem.isExternalLink) {
        return this.renderExternalLink;
      }
      return this.renderLink;
    }

    if (cardItem.onClick) {
      return this.renderActionLink;
    }
  };

  renderItemContainer = ({ container: { key, width, marginTop }, children }) => {
    const baseStyles = styles.base;

    return(
      <Div
        width={width}
        key={key}
        marginTop={marginTop}
        { ...(isNative() ? { style: [baseStyles.alignCenter] } : {})}
      >
        {children}
      </Div>
    );
  };

  fractionToPercent = (numerator, denominator) => {
    return `${(numerator * 100) / denominator}%`;
  };

  render() {
    const { dataSet, marginTop, 'data-test-key': dataTestKey, maxRowItems } = this.props;
    const maxRowItemLength = maxRowItems && maxRowItems <= dataSet.length ? maxRowItems : dataSet.length || dataSet.length;
    const width = this.fractionToPercent(1, maxRowItemLength);

    return (
      <Div
        flexDirection="row"
        marginBottom="small"
        flexWrap="wrap"
        marginTop={marginTop}
      >
        {
          dataSet.map((cardItem, index) => {
            const itemExtraTopMargin = index >= maxRowItemLength ? 'large' : null;
            const iconAndTextComponent = this.renderIconAndText({
              ...cardItem,
              dataTestKey: `${dataTestKey}-${cardItem.key}`
            });

            const actionComponent = this.getActionComponentToRender(cardItem);
            const children = !actionComponent ?
              iconAndTextComponent :
              actionComponent({ ...cardItem, children: iconAndTextComponent });

            return this.renderItemContainer({
              container: {
                key: `item-container-${index}`,
                width,
                marginTop: itemExtraTopMargin
              },
              children
            });
          })
        }
      </Div >
    );
  }
}