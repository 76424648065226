/* eslint css-modules/no-unused-class: [2, { markAsUsed: ['secondary', 'ghost', 'none'] }] */
import * as React from 'react';
import classNames from 'classnames';
import omit from 'lodash.omit';
import * as queryString from 'query-string';
import { HashLink as Link } from 'react-router-hash-link';
import { Spinner } from '../Spinner';
import styles from './Button.scss';

type ButtonProps = {
  theme?: string;
  href?: string;
  query?: Record<string, unknown>;
  hash?: string;
  state?: Record<string, unknown>;
  children: React.ReactNode;
  insideCard?: boolean;
  isExternal?: boolean;
  className?: string;
  centered?: boolean;
  loading?: boolean;
  extraTopMargin?: boolean;
  disabled?: boolean;
};

/**
 * @deprecated Use the @components/Button instead.
 **/
export default class Button extends React.Component<ButtonProps> {
  render() {
    const {
      isExternal,
      href,
      query,
      hash,
      state,
      children,
      insideCard,
      className,
      loading,
      centered,
      extraTopMargin
    } = this.props;
    const propsToSpread = omit(
      this.props,
      'extraTopMargin',
      'centered',
      'isExternal',
      'href',
      'query',
      'hash',
      'children',
      'insideCard',
      'className',
      'theme',
      'loading'
    );
    const { theme } = this.props;

    const themeClass = theme ? styles[theme] : styles.primary;

    const conditionalClasses = {
      [styles.insideCard]: insideCard,
      [styles.centered]: centered,
      [styles.extraTopMargin]: extraTopMargin
    };

    if (loading) {
      return (
        <div
          className={styles.loadingContainer}
          data-test-key="button-loading-spinner"
        >
          <Spinner size="45" delay={0} centered extraTopMargin />
        </div>
      );
    }

    if (isExternal) {
      return (
        <a
          href={href}
          className={classNames(themeClass, conditionalClasses, className)}
          {...propsToSpread}
        >
          {children}
        </a>
      );
    }

    if (href) {
      return (
        <Link
          smooth
          to={{
            pathname: href,
            search: queryString.stringify(query!),
            hash,
            state
          }}
          className={classNames(themeClass, conditionalClasses, className)}
          {...propsToSpread}
        >
          {children}
        </Link>
      );
    }

    return (
      <button
        {...propsToSpread}
        className={classNames(themeClass, conditionalClasses, className)}
      >
        {children}
      </button>
    );
  }
}
