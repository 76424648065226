type UseRadioButtonA11yPropsProps = {
  disabled?: boolean;
  checked?: boolean;
};

export function useRadioButtonA11yProps({
  disabled = false,
  checked = false
}: UseRadioButtonA11yPropsProps) {
  return {
    accessibilityChecked: checked,
    accessibilityDisabled: disabled,
    accessibilityRole: 'radio',
    accessibilityState: {
      disabled,
      checked
    }
  } as const;
}
