import { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Div from '../../../../common/components/Div/Div';
import ScreenHeading from '../../../../common/components/ScreenHeading/ScreenHeading';
import ForgotPasswordForm from '../../../components/ForgotPasswordForm/ForgotPasswordForm';
import { RedirectAuthenticatedUser } from '../../../components/RedirectAuthenticatedUser';

@connect(
  state => ({
    localisation: state.localisation
  })
)
@withTranslation()
export default class ForgotPassword extends Component {
  static propTypes = {
    localisation: PropTypes.shape({
      countryCode: PropTypes.string.isRequired
    }),
    t: PropTypes.func.isRequired
  };

  render() {
    const { t, localisation: { countryCode } } = this.props;

    return (
      <Div>
        <RedirectAuthenticatedUser to={`/${countryCode}/account/update-password`} />
        <Helmet title={t('forgotPasswordPage.title')} />
        <ScreenHeading>{t('forgotPasswordPage.heading')}</ScreenHeading>
        <ForgotPasswordForm />
      </Div>
    );
  }
}
