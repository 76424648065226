import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-native';
import REQUEST_KEY from '@common/constants/requestKeys';
import { APPLICANT_STATUS } from '@seek/je-shared-data';
import { clearError as clearErrorAction, getRequestState } from '@store/app';
import { updateApplicantStatus } from '@store/entities/applications';
import { deleteJob } from '@store/entities/jobs';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';
import type { FormInputs } from '../CloseJobModal';
import { CloseJobReason } from './useReasonOptions';

type UseCloseJobModalProps = {
  jobId: string;
  onRequestClose: () => void;
};

export function useCloseJobModal({
  jobId,
  onRequestClose
}: UseCloseJobModalProps) {
  const dispatch = useAppDispatch();
  const { countryCode } = useAppSelector(getLocalisation);
  const { error: deleteJobError, isLoading } = useAppSelector(
    getRequestState(REQUEST_KEY.JOB.DELETE)
  );
  const history = useHistory();
  const [isDeleteJobRequested, setDeleteJobRequested] = useState(false);
  const reasonRef = useRef<string>();

  useEffect(() => {
    if (isDeleteJobRequested && !isLoading) {
      setDeleteJobRequested(false);

      if (!deleteJobError) {
        if (reasonRef.current === CloseJobReason.FilledHere) {
          dispatch(
            updateApplicantStatus({
              applicationId: CLOSE_JOB_APPLICATION_ID,
              jobId,
              status: APPLICANT_STATUS.ACCEPTED_OFFER
            })
          );
        }

        onRequestClose();
        history.push(`/${countryCode}/jobs/${jobId}/close-job-success`);
      }
    }
  }, [
    countryCode,
    deleteJobError,
    dispatch,
    history,
    isDeleteJobRequested,
    isLoading,
    jobId,
    onRequestClose
  ]);

  const requestCloseJob = ({ reason, additionalComments }: FormInputs) => {
    setDeleteJobRequested(true);
    reasonRef.current = reason;

    dispatch(deleteJob({ jobId, reason, additionalComments }));
  };

  const requestCloseModal = useCallback(() => {
    dispatch(clearErrorAction(REQUEST_KEY.JOB.DELETE));
    onRequestClose();
  }, [dispatch, onRequestClose]);

  return {
    requestCloseJob,
    requestCloseModal
  };
}

// A special ID as there is no candidate. It will be used in the Application Service
// to fill the employer_application_id and the candidate_application_id fields to
// differentiate it from other regular updates.
const CLOSE_JOB_APPLICATION_ID = 'placement_modal';
