import React from 'react';
import { Email as InnerField } from '../../../components/Email';
import { email } from '../../../rules';
import { FieldWrapper } from '../FieldWrapper';

export const Email = () => {
  return (
    <FieldWrapper<string>
      name="email"
      rules={email}
      render={(props) => {
        return <InnerField {...props} />;
      }}
    />
  );
};
