import requestKeys from '@common/constants/requestKeys';
import { getRequestState } from '@store/app';
import { useAppSelector } from '@store/hooks';

export const useGetRequestErrors = () => {
  const { error: loadJobError } = useAppSelector(
    getRequestState(requestKeys.JOB.LOAD)
  );
  const { error: getAdPricesError } = useAppSelector(
    getRequestState(requestKeys.PAYMENT.GET_AD_PRICES)
  );

  return {
    hasError: Boolean(getAdPricesError || loadJobError),
    requestKeys: [requestKeys.PAYMENT.GET_AD_PRICES, requestKeys.JOB.LOAD]
  };
};
