import PropTypes from 'prop-types';
import { green700, grey900 } from '../../theme/palette/colours';
import Svg, { Path } from 'svgs';

function getOutline(theme) {
  switch (theme) {
    case 'green':
      return green700;
    default:
      return grey900;
  }
}

function ShareIcon(props) {
  return (
    <Svg viewBox="0 0 14 18" fill="none" {...props}>
      <Path
        d="M9.5 4.5H13.5V17.5H0.5V4.5H4.5"
        stroke={getOutline(props.theme)}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M7 9.5V1"
        stroke={getOutline(props.theme)}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M5 2L6.80685 0.592588C6.93014 0.467868 7.13562 0.467868 7.25069 0.600383L9 2"
        stroke={getOutline(props.theme)}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

ShareIcon.propTypes = {
  theme: PropTypes.string
};

export default ShareIcon;
