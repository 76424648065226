import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <>
    <Path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M9 12.5a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM1.5 20.5c0-4.417 3.36-8 7.5-8 4.14 0 7.5 3.583 7.5 8M24 12.5a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM16.5 20.5c0-4.417 3.36-8 7.5-8 4.14 0 7.5 3.583 7.5 8"
    />
  </>
);

export const PlusAdIcon = createIcon({
  viewBox: '0 0 36 24',
  vectorComponent
});
