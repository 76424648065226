import React from 'react';
import { Else, If, Then } from '@common/components/If';
import { isJobActive } from '@common/helpers/jobs';
import { ListJobItem } from '@store/entities/jobs/types';
import { ActiveJobActions } from './components/ActiveJobActions';
import { InactiveJobAction } from './components/InactiveJobAction/InactiveJobAction';
import { useJobActions } from './hooks/useJobActions';

type JobActionsProps = {
  job: ListJobItem;
};

export const JobActions = ({ job }: JobActionsProps) => {
  const { status, id: jobId, title } = job;
  const { shouldDisplayCardActions } = useJobActions({ status });

  if (!shouldDisplayCardActions) {
    return null;
  }

  return (
    <If condition={isJobActive(status)}>
      <Then>
        <ActiveJobActions jobId={jobId} title={title} />
      </Then>
      <Else>
        <InactiveJobAction jobId={jobId} />
      </Else>
    </If>
  );
};
