import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@components/Modal';
import { RequestUpgrade, RequestUpgradeConfirmation } from './components';

type UpgradeModalProps = {
  mobile?: string;
  email?: string;
  visible: boolean;
  onRequestClose: () => void;
  onRequestUpgradeSubmit: () => void;
  onCloseModalWhenComplete: () => void;
};

export const UpgradeAccountModal = ({
  mobile,
  email,
  visible,
  onRequestClose,
  onRequestUpgradeSubmit,
  onCloseModalWhenComplete
}: UpgradeModalProps) => {
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState<boolean>(false);

  const handleOnSubmit = () => {
    onRequestUpgradeSubmit();
    setSubmitted(true);
  };

  const handleOnComplete = () => {
    onCloseModalWhenComplete();
  };

  return (
    <Modal
      title={t('account.upgradeAccountModal.title')}
      visible={visible}
      onRequestClose={onRequestClose}
      height="fit-content"
    >
      {submitted ? (
        <RequestUpgradeConfirmation
          mobile={mobile}
          email={email}
          onComplete={handleOnComplete}
        />
      ) : (
        <RequestUpgrade
          mobile={mobile}
          email={email}
          onSubmit={handleOnSubmit}
        />
      )}
    </Modal>
  );
};
