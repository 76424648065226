import {
  BrandName,
  SupportedCountryCode
} from '@seek/je-shared-data/lib/types/brand';

interface ShowFeatured {
  brand: BrandName;
  country: SupportedCountryCode;
}
/**
 * Determines whether to show the benefit for job featured on social media.
 *
 * @param {BrandName} params.brand - The brand name.
 * @param {SupportedCountryCode} params.country - The country code.
 * @returns {boolean} Returns true if the benefit should be shown, false
 * otherwise.
 */
export const showFeaturedOnSocialMedia = ({
  brand,
  country
}: ShowFeatured): boolean => {
  if (brand !== BrandName.JOBSTREETEXPRESS) return false;
  if (country === SupportedCountryCode.Indonesia) return true;

  return false;
};
