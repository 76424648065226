export const POST_JOB = 'jora-employer/entities/jobs/POST_JOB';
export const POST_JOB_SUCCESS = 'jora-employer/entities/jobs/POST_JOB_SUCCESS';
export const POST_JOB_FAIL = 'jora-employer/entities/jobs/POST_JOB_FAIL';

export const LOAD_JOB = 'jora-employer/entities/jobs/LOAD_JOB';
export const LOAD_JOB_SUCCESS = 'jora-employer/entities/jobs/LOAD_JOB_SUCCESS';
export const LOAD_JOB_FAIL = 'jora-employer/entities/jobs/LOAD_JOB_FAIL';

export const LOAD_DASHBOARD_JOBS =
  'jora-employer/entities/jobs/LOAD_DASHBOARD_JOBS';
export const LOAD_DASHBOARD_JOBS_SUCCESS =
  'jora-employer/entities/jobs/LOAD_DASHBOARD_JOBS_SUCCESS';
export const LOAD_DASHBOARD_JOBS_FAIL =
  'jora-employer/entities/jobs/LOAD_DASHBOARD_JOBS_FAIL';

export const CLEAR_DASHBOARD_JOBS =
  'jora-employer/entities/jobs/CLEAR_DASHBOARD_JOBS';

export const UPDATE_JOB = 'jora-employer/entities/jobs/UPDATE_JOB';
export const UPDATE_JOB_SUCCESS =
  'jora-employer/entities/jobs/UPDATE_JOB_SUCCESS';
export const UPDATE_JOB_FAIL = 'jora-employer/entities/jobs/UPDATE_JOB_FAIL';

export const DELETE_JOB = 'jora-employer/entities/jobs/DELETE_JOB';
export const DELETE_JOB_SUCCESS =
  'jora-employer/entities/jobs/DELETE_JOB_SUCCESS';
export const DELETE_JOB_FAIL = 'jora-employer/entities/jobs/DELETE_JOB_FAIL';

export const TRACK_POST_JOB_SUCCESS =
  'jora-employer/entities/jobs/TRACK_POST_JOB_SUCCESS';
export const TRACK_UPDATE_JOB_SUCCESS =
  'jora-employer/entities/jobs/TRACK_UPDATE_JOB_SUCCESS';
