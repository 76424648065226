import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getDateDifference,
  localisedFormatDate
} from '@common/helpers/dateTime';
import { WorkExperience } from '@common/types';

export const WORK_EXPERIENCE_LIMIT = 3;

export function useWorkExperienceDetails(value: WorkExperience) {
  const { t } = useTranslation();
  const [showLimitedWorkExperience, setShowLimitedWorkExperience] =
    useState(true);

  const textLink = getTextLink(t, value.length, showLimitedWorkExperience);
  const workExperience = getWorkExperienceWithDurationText(
    t,
    value,
    showLimitedWorkExperience
  );

  const toggleShowLimitedWorkExperience = () => {
    setShowLimitedWorkExperience((prev) => !prev);
  };

  return {
    textLink,
    workExperience,
    showLimitedWorkExperience,
    toggleShowLimitedWorkExperience
  };
}

function getDurationCopy({ t, startDate, endDate }) {
  const { years, months } = getDateDifference({
    currentDate: endDate,
    pastDate: startDate
  });

  const yearCopy =
    years > 0 ? `${t('common.year.abbreviated.count', { count: years })} ` : '';
  const monthCopy = t('common.months.abbreviated.count', { count: months });
  const durationCopy = `${yearCopy}${monthCopy}`;

  return durationCopy;
}

function getDuration({ t, startDate, endDate }) {
  return endDate
    ? `${localisedFormatDate(
        t,
        'dd MMMM yyyy',
        startDate
      )} - ${localisedFormatDate(t, 'dd MMMM yyyy', endDate)}`
    : `${localisedFormatDate(t, 'dd MMMM yyyy', startDate)} - ${t(
        'applications.applicationCard.workExperience.present'
      )}`;
}

const getWorkExperienceWithDurationText = (
  t: (key: string) => string,
  workExperience: WorkExperience,
  showLimitedWorkExperience: boolean
) => {
  const workExperienceWithDuration = workExperience.map((experience) => {
    const { startDate, endDate } = experience;

    const durationText = getDurationCopy({
      t,
      startDate,
      endDate
    });
    const duration = getDuration({
      t,
      startDate,
      endDate
    });

    return {
      ...experience,
      durationText,
      duration
    };
  });

  if (showLimitedWorkExperience) {
    return workExperienceWithDuration.slice(0, WORK_EXPERIENCE_LIMIT);
  }

  return workExperienceWithDuration;
};

const getTextLink = (
  t: (key: string, config?: object) => string,
  count: number,
  showLimitedWorkExperience: boolean
) => {
  if (showLimitedWorkExperience) {
    return `${t('applications.applicationCard.workExperience.showAll', {
      count
    })}`;
  }

  return t('applications.applicationCard.workExperience.showLess');
};
