import { EventTypes } from 'redux-segment';
import { ClientError } from '../../../common/helpers/errors';
import REQUEST_KEY from '../../constants/requestKeys';
import { clearError, endLoading, startLoading } from '../app/actions';
import * as constants from './constants';

export { confirmPayment, loadStripeInstance } from './stripeApi';

type GetAdPricesResult = {
  [countryCode: string]: {
    pricing: number;
    currency: string;
  };
};

type CreatePaymentIntentResult = {
  paymentIntentId: string;
  clientId: string;
  pricingText: string;
  adType: string;
  jobCountry: string;
};

export type PaymentAction = {
  type: string;
  types: string[];
  result: GetAdPricesResult | CreatePaymentIntentResult;
};

export function getAdPrices(countryCode) {
  return {
    types: [
      constants.GET_AD_PRICES,
      constants.GET_AD_PRICES_SUCCESS,
      constants.GET_AD_PRICES_FAIL
    ],
    promise: async (dispatch, getState, fetch) => {
      const {
        config: {
          endpoints: { paymentService }
        },
        payment: {
          prices: { items }
        }
      } = getState();

      if (items[countryCode]) {
        return items[countryCode];
      }

      const body = await fetch(
        `${paymentService}/prices/${countryCode}/ad`,
        {
          method: 'GET',
          credentials: 'include'
        },
        {
          requestKey: REQUEST_KEY.PAYMENT.GET_AD_PRICES,
          showGlobalSpinner: true,
          defaultError: 'errors.payment.getAdPrices.error'
        }
      );

      return {
        [countryCode]: body
      };
    }
  };
}

function trackAdTypeButtonClick({ jobCountry, adType, sourceParams }) {
  return {
    type: constants.TRACK_AD_TYPE_BUTTON_CLICK,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'job_upgrade__initiate',
          parameters: {
            jobCountry,
            adType,
            sourceParams
          }
        }
      }
    }
  };
}

export function createPaymentIntent({
  countryCode,
  jobId,
  adType,
  brand,
  campaign,
  pricingText,
  sourceParams
}) {
  return {
    types: [
      constants.CREATE_PAYMENT_INTENT,
      constants.CREATE_PAYMENT_INTENT_SUCCESS,
      constants.CREATE_PAYMENT_INTENT_FAIL
    ],
    promise: async (dispatch, getState, fetch) => {
      const {
        config: {
          endpoints: { paymentService }
        }
      } = getState();
      const paymentCampaign = campaign
        ? campaign
        : constants.PAYMENT_CAMPAIGN.DEFAULT;

      const body = await fetch(
        `${paymentService}/intent/create`,
        {
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify({
            countryCode,
            jobId,
            selectedAdType: adType,
            brand,
            campaign: paymentCampaign
          })
        },
        {
          requestKey: REQUEST_KEY.PAYMENT.CREATE_PAYMENT_INTENT,
          showGlobalSpinner: true,
          onFetchError: (error) => {
            throw new ClientError('errors.pleaseTryAgain', {
              responseBody: error,
              adType
            });
          },
          onResponseError: (responseBody) => {
            throw new ClientError('errors.pleaseTryAgain', {
              responseBody: responseBody,
              adType
            });
          }
        }
      );

      dispatch(clearError(REQUEST_KEY.PAYMENT.CONFIRM_PAYMENT));
      dispatch(
        trackAdTypeButtonClick({
          jobCountry: countryCode,
          adType,
          sourceParams
        })
      );
      return {
        ...body,
        pricingText,
        adType,
        jobCountry: countryCode
      };
    }
  };
}

export function trackConfirmPaymentSuccess({
  jobCountry,
  adType,
  sourceParams
}) {
  return {
    type: constants.TRACK_CONFIRM_PAYMENT_SUCCESS,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'job_upgrade__successful',
          parameters: {
            jobCountry,
            adType,
            sourceParams
          }
        }
      }
    }
  };
}

export function clearCheckoutDetails() {
  return (dispatch) => {
    dispatch({
      type: constants.CREATE_PAYMENT_INTENT
    });
  };
}

export function onLoadPaymentElement() {
  return (dispatch) => {
    startLoading(dispatch, REQUEST_KEY.PAYMENT.LOAD_PAYMENT_ELEMENT, true);
  };
}

export function onReadyPaymentElement() {
  return (dispatch) => {
    endLoading(dispatch, REQUEST_KEY.PAYMENT.LOAD_PAYMENT_ELEMENT, true);
  };
}
