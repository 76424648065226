import REQUEST_KEY from '@common/constants/requestKeys';
import { ClientError, ErrorPreventLogging } from '@common/helpers/errors';
import { FetchActionResultSuccess } from '@common/store/middleware/clientMiddlewareTypes';
import { GENERAL_EXCEPTION } from '@seek/je-error-helper';
import * as constants from '../../constants';
import { toJobServiceEndpoint } from '../stateUtils/toJobServiceEndpoint';
import { toFormattedJob } from './toFormattedJob';
import { GetJobItem } from './types';

export type GetActionSuccess = FetchActionResultSuccess<
  typeof constants.LOAD_JOB_SUCCESS,
  GetJobItem
>;

export function get(jobId) {
  return {
    types: [
      constants.LOAD_JOB,
      constants.LOAD_JOB_SUCCESS,
      constants.LOAD_JOB_FAIL
    ],
    promise: async (dispatch, getState, fetch): Promise<GetJobItem> => {
      const jobServiceEndpoint = toJobServiceEndpoint(getState);

      const body = await fetch(
        `${jobServiceEndpoint}/${jobId}`,
        {
          method: 'GET',
          credentials: 'include'
        },
        {
          requestKey: REQUEST_KEY.JOB.LOAD,
          showGlobalSpinner: true,
          onResponseError: handleLoadError
        }
      );

      return toFormattedJob(body);
    }
  };
}

function handleLoadError(body) {
  const { code, guid } = body;

  switch (code) {
    case GENERAL_EXCEPTION.NOT_FOUND:
      throw new ErrorPreventLogging(code);
    default:
      throw new ClientError('errors.defaultWithGuid', {
        guid,
        responseBody: body
      });
  }
}
