import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Button';
import { Row } from '@components/Row';
import { FormContext } from './FormContext';
import { Notes } from './topLevelFields';
import { NotesFormFields } from './types';

type NotesFormProps = {
  defaultValues: NotesFormFields;
  onSubmit: (data: NotesFormFields) => void;
  onCancel: () => void;
  maxChars: number;
};

export const NotesForm = ({
  onCancel,
  onSubmit,
  defaultValues,
  maxChars
}: NotesFormProps) => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    trigger: triggerValidation,
    formState: { errors }
  } = useForm<NotesFormFields>({
    defaultValues,
    mode: 'onChange'
  });

  const submit = async (data: NotesFormFields) => {
    await triggerValidation();
    onSubmit(data);
  };

  return (
    <FormContext.Provider value={{ control, errors, t }}>
      <Notes maxChars={maxChars} />

      <Row spacing="small" marginTop="small">
        <Button onPress={handleSubmit(submit)}>
          {t('applications.notes.action.submit')}
        </Button>
        <Button variant="secondary" onPress={onCancel}>
          {t('common.action.cancel')}
        </Button>
      </Row>
    </FormContext.Provider>
  );
};
