import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@components/Box';
import { CalloutText } from '@components/CalloutText';
import { Checkbox, Field } from '@components/FormElements';
import { Stack } from '@components/Stack';

type RightToWorkProps = {
  value: boolean;
  onChange: () => void;
};

export const RightToWork = (props: RightToWorkProps) => {
  const { value, onChange } = props;
  const { t } = useTranslation();

  const calloutTextKey = value ? 'visaCanBeProvided' : 'workingRightsRequired';

  return (
    <Stack spacing="small">
      <Field label={t('postJobForm.fieldLabel.rightToWork')}>
        <Box marginTop="xsmall">
          <Checkbox
            testID="job-form-rightToWork"
            label={t('postJobForm.rightToWork.visaCanBeProvided')}
            value={value}
            onChange={onChange}
          />
        </Box>
      </Field>

      <CalloutText tone="info" testID="right-to-work-callout-text">
        {t(`postJobForm.explanationText.rightToWork.${calloutTextKey}`)}
      </CalloutText>
    </Stack>
  );
};
