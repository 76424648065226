import React from 'react';
import { useTranslation } from 'react-i18next';
import { localisedFormatDate } from '@common/helpers/dateTime';
import { Text } from '@components/Text';

type Props = {
  expiryDate?: Date | null;
  amountExpiring?: number;
};

export const CoinExpiryText = (props: Props) => {
  const { t } = useTranslation();
  const { amountExpiring, expiryDate } = props;

  return (
    <Text tone="neutralLight" size="small">
      {expiryDate
        ? t('myCoinsPage.balance.expiringCoins', {
            remainingAmount: amountExpiring,
            expiryDate: localisedFormatDate(t, 'dd MMM yyyy', expiryDate)
          })
        : t('myCoinsPage.balance.noExpiringCoins')}
    </Text>
  );
};
