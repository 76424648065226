import React from 'react';
import { Trans as I18nextTrans } from 'react-i18next';
import { Job } from '@common/types';
import { CalloutText } from '@components/CalloutText';
import { Card, CardContent } from '@components/Card';
import { Text } from '@components/Text';
import { TextLink } from '@components/TextLink';
import { useCalloutDetails } from './useCalloutDetails';

type CalloutDetailsProps = {
  job: Job;
};

export const CalloutDetails = ({ job }: CalloutDetailsProps) => {
  const { i18nKey, redirectToFaq, tone } = useCalloutDetails(job) || {};

  if (!i18nKey) {
    return null;
  }

  return (
    <Card marginTop="medium" testID={`job-details-${job.status}-callout-text`}>
      <CardContent>
        <CalloutText tone={tone}>
          <I18nextTrans
            i18nKey={i18nKey}
            parent={Text}
            components={[
              <TextLink
                testID="why-isnt-my-job-ad-live-callout-text"
                onPress={redirectToFaq}
              />
            ]}
          />
        </CalloutText>
      </CardContent>
    </Card>
  );
};
