import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Card from '../../../common/components/Card/Card';
import ErrorMessage from '../../../common/components/ErrorMessage/ErrorMessage';
import ScreenHeading from '../../../common/components/ScreenHeading/ScreenHeading';
import Div from '../../../common/components/Div/Div';

import * as appActions from '../../store/app/actions';

/**
 * @deprecated Use the RequestErrorMessage component instead.
 */
@connect(
  ({ app }) => ({
    error: app.error,
    submissionError: app.submissionError
  }),
  {
    clearError: appActions.clearError
  }
)
@withTranslation()
export default class ConnectedErrorMessage extends Component {
  static propTypes = {
    requestKey: PropTypes.string.isRequired,
    error: PropTypes.object,
    submissionError: PropTypes.object,
    withCard: PropTypes.bool,
    hideWithSubmissionError: PropTypes.bool,
    clearError: PropTypes.func.isRequired,
    extraTopMargin: PropTypes.bool,
    children: PropTypes.node,
    t: PropTypes.func.isRequired,
    'data-test-key': PropTypes.string,
    screenHeading: PropTypes.node,
    className: PropTypes.string,
    additionCondition: PropTypes.func
  };

  componentWillUnmount() {
    const { requestKey, clearError } = this.props;

    clearError(requestKey);
  }

  renderError(children) {
    const { withCard, screenHeading, 'data-test-key': dataTestKey } = this.props;

    return (
      <Div data-test-key={dataTestKey || 'connected-error-message'}>
        {
          screenHeading &&
          <ScreenHeading>{screenHeading}</ScreenHeading>
        }
        {
          withCard ?
            <Card>
              {children}
            </Card> :
            children
        }
      </Div>
    );
  }

  render() {
    const { error, hideWithSubmissionError, submissionError, requestKey, extraTopMargin, children, t, className, additionCondition } = this.props;

    if (hideWithSubmissionError && submissionError[requestKey]) {
      return <Fragment />;
    }

    return (
      error[requestKey] && (!additionCondition || additionCondition(error[requestKey].properties)) ?
        this.renderError(
          <ErrorMessage extraTopMargin={extraTopMargin} className={className}>
            {error[requestKey] && t(error[requestKey].message, error[requestKey].properties)}
          </ErrorMessage>
        ) :
        <Fragment>{children}</Fragment>
    );
  }
}
