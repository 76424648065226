import { BrandName } from '@seek/je-shared-data/lib/types/brand';
import { useBrandName } from '../localisation';

// TODO: cleanup after jobsDB shutdown
export function useRedirectToJobsDbShutdown() {
  const brand = useBrandName();
  if (brand !== BrandName.JOBSDB) {
    return;
  }

  const hasSeenAnnouncement = sessionStorage.getItem(
    'jobsdb_retirement_announcement_seen'
  );
  if (!hasSeenAnnouncement) {
    sessionStorage.setItem('jobsdb_retirement_announcement_seen', 'true');
    window.location.href = '/announcement';
  }
}
