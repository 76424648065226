export default store => next => action => {
  const { meta } = action;
  if (meta && meta.analytics) {
    const { eventPayload = {}} = meta.analytics;
    const state = store.getState();

    const actionWithLocalisation = {
      ...action,
      meta: {
        ...meta,
        analytics: {
          ...meta.analytics,
          eventPayload: {
            ...eventPayload,
            properties: {
              siteCountryCode: state.localisation.countryCode,
              siteBrand: state.localisation.brandConfig.name,
              user_id: state.auth.currentUser.id, // eslint-disable-line camelcase
              siteId: state.auth.currentUser.registeredCountry,
              ...(eventPayload.properties || {}),
              ...(eventPayload.parameters || {})
            }
          }
        }
      }
    };

    const result = next(actionWithLocalisation);
    return result;
  }
  const result = next(action);
  return result;
};
