import { useTranslation } from 'react-i18next';
import { REGEX } from '@common/constants/data';

export const usePasswordValidation = () => {
  const { t } = useTranslation();

  const errors = {
    required: t('validations.general.password.required'),
    pattern: t('validations.general.password.format')
  };

  return {
    required: errors.required,
    pattern: {
      value: REGEX.PASSWORD,
      message: errors.pattern
    }
  };
};
