import React, { useCallback } from 'react';
import { View, ViewProps } from 'react-native';
import { useTranslation } from 'react-i18next';
import { getUser } from '@store/auth';
import { useAppSelector } from '@store/hooks';
import { SuccessIcon } from '@components/Icon';
import { IconButton } from '@components/IconButton';
import { Link } from '@components/Link';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { useMilestoneStep } from './hooks/useMilestoneStep';
import { useMilestoneStepStyle } from './hooks/useMilestoneStepStyle';

export type MilestoneStepProps = {
  label: string;
  pathname: string;
  number?: number;
  isDone?: boolean;
  isActive?: boolean;
  isLastStep?: boolean;
  testID?: ViewProps['testID'];
};

export const MilestoneStep = ({
  label,
  number,
  isActive,
  isDone,
  isLastStep,
  pathname,
  testID
}: MilestoneStepProps) => {
  const {
    dot,
    lineContainer,
    stepContainer,
    textContainer,
    dottedLineContainer,
    activeCircleContainer,
    defaultCircleContainer,
    inProgressLineContainer,
    line: { defaultLine, activeLine }
  } = useMilestoneStepStyle();
  const { t } = useTranslation();
  const { userHasJob, redirectToPathname } = useMilestoneStep({ pathname });
  const { isAuthenticated } = useAppSelector(getUser);

  const Line = useCallback(() => {
    if (isLastStep) {
      return null;
    }

    if (isActive) {
      const numberOfDots = 7;

      return (
        <Row spacing="xxsmall" testID="milestone-step-dotted-line">
          <View style={inProgressLineContainer}>
            <View style={activeLine} />
          </View>

          <View style={dottedLineContainer}>
            <Row spacing="xxsmall">
              {[...Array(numberOfDots)].map((_, index) => (
                <View key={index} style={dot} />
              ))}
            </Row>
          </View>
        </Row>
      );
    }

    return (
      <View style={lineContainer}>
        <View
          style={isDone ? activeLine : defaultLine}
          testID={`milestone-step-${isDone ? 'active-line' : 'default-line'}`}
        />
      </View>
    );
  }, [
    activeLine,
    defaultLine,
    dot,
    dottedLineContainer,
    inProgressLineContainer,
    isActive,
    isDone,
    isLastStep,
    lineContainer
  ]);

  const Step = useCallback(() => {
    if (isDone) {
      const isLinkDisabled = isAuthenticated && !userHasJob;

      return (
        <View style={stepContainer}>
          <Stack spacing="xsmall" alignItems="center">
            <IconButton
              icon={
                <SuccessIcon variant="outline" size="xxlarge" tone="brand" />
              }
              onPress={redirectToPathname}
            />

            <View style={textContainer}>
              <Row justifyContent="center">
                {isLinkDisabled ? (
                  <Text
                    size="small"
                    tone="brand"
                    align="center"
                    weight="regular"
                  >
                    {t(`${label}`)}
                  </Text>
                ) : (
                  <Link
                    weight="regular"
                    variant="secondary"
                    onPress={redirectToPathname}
                    testID="milestone-step-link"
                  >
                    {t(`${label}`)}
                  </Link>
                )}
              </Row>
            </View>
          </Stack>
        </View>
      );
    }

    return (
      <View style={stepContainer}>
        <Stack spacing="xsmall" alignItems="center">
          <View
            style={isActive ? activeCircleContainer : defaultCircleContainer}
          >
            <Stack height="full" justifyContent="center" tone="transparent">
              <Text
                weight="regular"
                tone={isActive ? 'brand' : 'neutralLight'}
                align="center"
              >
                {number}
              </Text>
            </Stack>
          </View>

          <View style={textContainer}>
            <Text
              size="small"
              align="center"
              weight="regular"
              tone={isActive ? 'brand' : 'neutralLight'}
            >
              {t(`${label}`)}
            </Text>
          </View>
        </Stack>
      </View>
    );
  }, [
    activeCircleContainer,
    defaultCircleContainer,
    isActive,
    isAuthenticated,
    isDone,
    label,
    number,
    redirectToPathname,
    stepContainer,
    t,
    textContainer,
    userHasJob
  ]);

  return (
    <Row alignItems="flex-start" testID={testID}>
      <Step />
      <Line />
    </Row>
  );
};
