import { useParams } from 'react-router-native';
import { useSearchQueryParam } from '@common/hooks/useSearchQueryParam';
import {
  CountryToCurrencyMap,
  SupportedCountryCode,
  SupportedCurrencies
} from '@seek/je-shared-data/lib/types/brand';
import { SalaryRate } from '@seek/je-shared-data/lib/types/jobs';
import { getDraftJob } from '@store/draft';
import {
  getBusinessById,
  getBusinesses
} from '@store/entities/businesses/selectors';
import { BusinessWithUsers } from '@store/entities/businesses/types';
import { getJobById } from '@store/entities/jobs';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';
import { getRecentlyUsedBusinessId } from '@store/userData';
import { mapBusinessToFormValues } from './helpers/mapBusinessToFormValues';
import { PostJobFormBusiness } from './types';
import { PostJobFormFields } from './types';

type UsePostJobForm = {
  draftJob: any;
  mapBusinessToFormValues: (value: BusinessWithUsers) => PostJobFormBusiness;
  business?: PostJobFormBusiness;
  defaultValues: Partial<PostJobFormFields>;
};

const useGetCurrentJobBusiness = () => {
  const { originalJobId } = useParams<{ originalJobId: string }>();
  const job = useAppSelector(getJobById(originalJobId));

  const selectedBusiness = useAppSelector(
    getBusinessById(job?.businessId as string)
  );

  return selectedBusiness;
};

const useGetSelectedBusiness = () => {
  const { getQueryParam } = useSearchQueryParam();
  const selectedBusinessId = getQueryParam('selectedBusinessId');
  const selectedBusiness = useAppSelector(
    getBusinessById(selectedBusinessId as string)
  );

  return selectedBusiness;
};

const useGetRecentlyUsedBusiness = () => {
  const recentlyUsedBusinessId = useAppSelector(getRecentlyUsedBusinessId);
  const recentlyUsedBusiness = useAppSelector(
    getBusinessById(recentlyUsedBusinessId)
  );

  return recentlyUsedBusiness;
};

const useGetRecentlyCreatedBusiness = () => {
  const businesses = useAppSelector(getBusinesses());
  const recentlyCreatedBusiness = businesses[0];

  return recentlyCreatedBusiness;
};

const useGetDefaultBusiness = () => {
  const currentJobBusiness = useGetCurrentJobBusiness();
  const selectedBusiness = useGetSelectedBusiness();
  const recentlyUsedBusiness = useGetRecentlyUsedBusiness();
  const recentlyCreatedBusiness = useGetRecentlyCreatedBusiness();

  return (
    currentJobBusiness ||
    selectedBusiness ||
    recentlyUsedBusiness ||
    recentlyCreatedBusiness
  );
};

const useDefaultValues = (business?: PostJobFormBusiness) => {
  const { countryCode: siteCountryCode } = useAppSelector(getLocalisation);

  const countryCode = business?.geolocation.countryCode || siteCountryCode;
  const currency =
    CountryToCurrencyMap[countryCode?.toLowerCase() as SupportedCountryCode];

  const defaultValues: Partial<PostJobFormFields> = {
    jobTitle: '',
    shiftAvailability: {
      type: 'SPECIFIC'
    },
    rightToWork: false,
    startDate: '',
    jobDescription: '',
    salary: {
      type: 'exact',
      amount: undefined,
      currency: currency || SupportedCurrencies.AUD,
      rate: SalaryRate.MONTHLY
    }
  };

  return defaultValues;
};

export const usePostJobForm = (): UsePostJobForm => {
  const draftJob = useAppSelector(getDraftJob) as PostJobFormFields;

  const defaultBusiness = useGetDefaultBusiness();
  const business =
    draftJob?.business ??
    (defaultBusiness && mapBusinessToFormValues(defaultBusiness));

  const defaultValues = useDefaultValues(business);

  return {
    draftJob,
    mapBusinessToFormValues,
    business,
    defaultValues
  };
};
