import { useTheme } from '@theme/DesignSystemProvider/ThemeContext';
import { useResolveToken } from '@theme/tokens/utils/useResolveToken';
import { Tone } from '@theme/types';

export type BannerTone = keyof Pick<
  { [key in Tone] },
  'success' | 'info' | 'warning' | 'danger'
>;

export type BannerStyleProps = {
  /**
   * The theme that will be applied to the banner
   */
  tone?: BannerTone;
};

export function useBannerStyle({ tone = 'success' }: BannerStyleProps) {
  const {
    banner: { backgroundColor }
  } = useTheme();
  const { resolveToken } = useResolveToken();

  return {
    gradientStyle: {
      backgroundColor: 'transparent',
      borderRightWidth: 100,
      borderTopWidth: 210,
      borderTopColor: 'transparent',
      borderRightColor: resolveToken(backgroundColor, tone)
    }
  };
}
