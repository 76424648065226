import { Job } from '@common/types';
import { JOB_STATUS } from '@seek/je-shared-data';
import { SupportLevel } from '@seek/je-shared-data/lib/types/brand';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';

export const useJobActions = ({ status }: { status: Job['status'] }) => {
  const { supportTier } = useAppSelector(getLocalisation);

  return {
    shouldDisplayCardActions:
      supportTier !== SupportLevel.TIER_3 &&
      status !== JOB_STATUS.EXPIRED_BUT_NEVER_LIVE
  };
};
