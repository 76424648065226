import { useCoinsFeature } from '@common/hooks/featureToggles';
import { useBrandName } from '@common/hooks/localisation';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { useQueryCoinPricing } from '../useQueryCoinPricing';

/**
 * Custom hook to get coin pricing based on the job country code.
 * @param jobCountryCode - The country code of the job.
 * @returns An object containing coin pricing and error message.
 */
export const useGetCoinPricing = (jobCountryCode: SupportedCountryCode) => {
  const supportCoins = useCoinsFeature(jobCountryCode);
  const brandName = useBrandName();

  const { data, error } = useQueryCoinPricing(jobCountryCode, brandName, {
    enabled: supportCoins
  });

  return {
    coinPrice: {
      plus: data?.plus,
      max: data?.max
    },
    errorMessage: { message: error?.message, properties: error?.properties }
  };
};
