import React from 'react';
import { Circle, Path } from 'svgs';
import { VectorComponentProps, createIcon } from '../createIcon';

const vectorComponent = (props: VectorComponentProps) => {
  const { foregroundColor } = props;

  return (
    <>
      <Circle cx="57.6974" cy="58.17" r="54.1327" strokeWidth="6.33435" />
      <Path
        d="M71.826 28.3662H66.0292C64.4302 28.293 63.0664 28.8098 61.9377 29.9166C60.2446 31.5767 59.6905 32.513 59.6905 34.8523C59.6905 36.4118 59.6905 48.9955 59.6905 72.6034C59.1487 76.7139 56.7983 78.7691 52.6394 78.7691C48.4805 78.7691 46.2439 76.7139 45.9295 72.6034V65.211H34.562C34.562 68.5206 34.562 71.3808 34.562 73.7916C34.562 77.4079 34.6983 88.39 52.6394 87.97C70.5805 87.5501 71.826 77.4797 71.826 73.7916C71.826 71.3329 71.826 56.1911 71.826 28.3662Z"
        fill={foregroundColor}
      />
    </>
  );
};

export const CoinMenuIcon = createIcon({
  viewBox: '0 0 116 116',
  vectorComponent
});
