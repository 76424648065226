import { getUuid } from '../../helpers/uuid/uuid';
import { ClientError } from '../../helpers/errors';
import { sendError } from '../../helpers/raygun/raygun';
import fetchInterceptor from './fetchInterceptor';

function getRaygunErrorMessage(error) {
  if (error instanceof ClientError) {
    if (error.properties.responseBody && error.properties.responseBody.message) {
      const originalErrorMessage = error.properties.responseBody.message;
      const raygunError = new ClientError(`${originalErrorMessage}: ${error.properties.type}`);
      raygunError.properties = { ...error.properties, displayMessage: error.message };

      return raygunError;
    }
  }

  if (error instanceof TypeError) {
    const raygunError = new TypeError(`${error.message}: ${error.properties.type}`);
    raygunError.properties = { ...error.properties, displayMessage: error.message };

    return raygunError;
  }

  return error;
}

export default fetch =>
  ({ dispatch, getState }) => {
    return next => action => {

      function handleError(action) {
        if (!action.error.preventLogging) {
          if (typeof action.error === 'string') {
            action.error = { message: action.error, properties: { guid: `ui-${getUuid()}` } };
          }

          if (typeof action.error === 'object' && !action.error.properties) {
            action.error.properties = { guid: `ui-${getUuid()}` };
          }

          action.error.properties.type = action.type;
          const userId = getState().auth?.currentUser?.id;
          action.error.properties.userId = userId;

          try {
            const raygunError = getRaygunErrorMessage(action.error);
            sendError(raygunError);
          } catch (_) { /* never mind */ }
        }

        return next(action);
      }

      if (typeof action === 'function') {
        return action(dispatch, getState);
      }

      const { promise, types, ...rest } = action; // eslint-disable-line no-redeclare

      if (!promise) {
        return next(action);
      }

      const [REQUEST, SUCCESS, FAILURE] = types;
      next({ ...rest, type: REQUEST });

      return promise(dispatch, getState, fetchInterceptor(fetch, dispatch, getState)).then(
        (result) => next({
          ...rest,
          result,
          type: SUCCESS,
          meta: (result && result.meta) ? result.meta : undefined
        }),
        async (error) => await handleError({ ...rest, error, type: FAILURE })
      ).catch(async (error) => {
        console.error('MIDDLEWARE ERROR:', error); //eslint-disable-line no-console
        await handleError({ ...rest, error, type: FAILURE });
      });
    };
  };