import { trpcBff } from '@trpcBff';

type Response = ReturnType<
  typeof trpcBff.applications.videoSignedUrl.get.useQuery
>;

export type ErrorResponse = Response['error'];

export type FetchSignedVideoUrlParams = {
  applicationId: string;
  videoUid: string;
};

export type QueryOptions = {
  enabled?: boolean;
};

export const useFetchVideoSignedUrl = (
  params: FetchSignedVideoUrlParams,
  queryOptions?: QueryOptions
) => {
  const { data, isError, error, isInitialLoading, isSuccess } =
    trpcBff.applications.videoSignedUrl.get.useQuery(params, {
      ...queryOptions,
      staleTime: 5000,
      refetchOnWindowFocus: false,
      retry: false
    });

  return {
    data,
    isError,
    error,
    isSuccess,
    isLoading: isInitialLoading
  };
};
