export const iconSizes = {
  xxxsmall: 10,
  xxsmall: 12,
  xsmall: 14,
  small: 16,
  medium: 18,
  large: 20,
  xlarge: 24,
  xxlarge: 36,
  xxxlarge: 80
} as const;

export type IconSize = keyof typeof iconSizes;
