import React, { forwardRef, useState } from 'react';
import {
  Pressable,
  PressableProps,
  Text as ReactNativeText,
  TextLayoutEventData,
  View,
  ViewProps
} from 'react-native';
import { CrossIcon } from '../Icon';
import { Row } from '../Row';
import { usePillStyle } from './usePillStyle';

export type PillProps = {
  label: string;
  testID?: ViewProps['testID'];
  onPress?: PressableProps['onPress'];
};

export const Pill = forwardRef<View, PillProps>((props, ref) => {
  const { label, testID, onPress } = props;
  const [textLayoutData, setTextLayoutData] = useState<TextLayoutEventData>();
  const { pressableStyle, containerStyle, imageContainerStyle, textStyle } =
    usePillStyle(textLayoutData);

  return (
    <Pressable
      style={pressableStyle}
      onPress={onPress}
      testID={testID}
      ref={ref}
    >
      <Row
        {...containerStyle}
        paddingHorizontal="small"
        paddingVertical="xxsmall"
        spacing="small"
        tone="transparent"
      >
        <ReactNativeText
          style={textStyle}
          onTextLayout={(e) => setTextLayoutData(e.nativeEvent)}
        >
          {label}
        </ReactNativeText>
        <View style={imageContainerStyle}>
          <CrossIcon size="xxxsmall" variant="outline" />
        </View>
      </Row>
    </Pressable>
  );
});
