import React from 'react';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { Email } from './Email';
import { useEmailValidation } from './useEmailValidation';

export const EmailController = <T extends FieldValues>({
  name,
  control
}: UseControllerProps<T>) => {
  const emailRules = useEmailValidation();

  return (
    <Controller
      name={name}
      control={control}
      rules={emailRules}
      render={({ field: { onChange, onBlur, value }, formState }) => {
        return (
          <Email
            onChange={onChange}
            onBlur={onBlur}
            value={value.trim()}
            error={formState?.errors[name]?.message as string}
          />
        );
      }}
    />
  );
};
