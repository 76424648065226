import React from 'react';
import { MobileNumber as InnerField } from '@common/components/Account/components/MobileNumber';
import { mobileNumber } from '@common/components/Account/rules';
import { FieldWrapper } from '../FieldWrapper';

export const MobileNumber = () => {
  return (
    <FieldWrapper<{ diallingCode: string; phoneNumber: string }>
      name="mobileNumber"
      rules={mobileNumber}
      render={(props) => {
        return <InnerField {...props} />;
      }}
    />
  );
};
