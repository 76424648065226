import { useEffect } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-native';
import { useAppDispatch } from '@store/hooks';
import { set } from '@store/userData/actions';

export function useUpdateSourceQuery() {
  const dispatch = useAppDispatch();
  const { search } = useLocation();

  useEffect(() => {
    if (!search) {
      return;
    }

    dispatch(set('source.query', queryString.parse(search)));
  }, [dispatch, search]);
}
