import { Breakpoint, Platform, Spacing } from '@theme/tokens';
import { spacing } from '@theme/tokens/spacing';
import { DeviceSpacing } from '../types';

type UseSpaceProps = {
  breakpoint: Breakpoint;
  platform: Platform;
  size: Spacing | DeviceSpacing;
};

const isSpacing = (size: unknown): size is Spacing =>
  Object.keys(spacing).includes(size as string);

export const useSpace = ({
  breakpoint,
  platform,
  size
}: UseSpaceProps): Spacing => {
  if (isSpacing(size)) return size;
  if (isSpacing(size[breakpoint])) return size[breakpoint] as Spacing;

  return size[breakpoint][platform];
};
