import { useHistory } from 'react-router-native';
import REQUEST_KEY from '@common/constants/requestKeys';
import { GENERAL_EXCEPTION } from '@seek/je-error-helper';
import { getRequestState } from '@store/app';
import { useAppSelector } from '@store/hooks';
import { useGetJobNotFoundLink } from '../appPageLinks';

export function useRedirectToJobNotFoundIfError() {
  const loadJobError = useAppSelector(
    getRequestState(REQUEST_KEY.JOB.LOAD)
  ).error;
  const history = useHistory();
  const jobNotFoundLink = useGetJobNotFoundLink();

  if (loadJobError?.message === GENERAL_EXCEPTION.NOT_FOUND) {
    history.push(jobNotFoundLink);
  }
}
