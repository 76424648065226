import {
  BrandName,
  SupportedCountryCode
} from '@seek/je-shared-data/lib/types/brand';

interface TwentyEightDays {
  brand: BrandName;
  country: SupportedCountryCode;
}
/**
 * Determines whether to show the benefit for job featured for x days.
 *
 * @param {BrandName} params.brand - The brand name.
 * @param {SupportedCountryCode} params.country - The country code.
 * @returns {boolean} Returns true if the benefit should be shown, otherwise
 * false.
 */
export const showJobFeaturedForXDays = ({
  brand
}: TwentyEightDays): boolean => {
  if (brand === BrandName.JOBSTREETEXPRESS) return true;

  return true;
};
