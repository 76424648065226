import { useLocation } from 'react-router-native';
import { updateSlug } from '@src/lib/window';

export const useUpdateDepricatedUrls = () => {
  const { pathname } = useLocation();
  return () => {
    if (pathname.includes('repost-job-role')) {
      updateSlug('repost-job', window, false);
    }
  };
};
