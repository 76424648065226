import React, { ReactNode } from 'react';
import { Box } from '@components/Box';
import { Text } from '@components/Text';

export const TileText = ({ children }: { children: ReactNode | string }) => {
  return (
    <Box marginTop="medium">
      <Text>{children}</Text>
    </Box>
  );
};
