import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeadingWithUserPackage } from '@common/components/PageHeadingWithUserPackage';
import { Box } from '@components/Box';
import { Card, CardContent } from '@components/Card';
import { ZeroJobsIllustration } from '@components/Illustration';
import { Text } from '@components/Text';
import { AddBusinessAction } from '../AddBusinessAction';
import { PageHeadingLayout } from '../PageHeadingLayout';

export const ZeroBusinessesState = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageHeadingLayout>
        <Box marginTop="xsmall" tone="transparent">
          <PageHeadingWithUserPackage
            heading={t('businessListingPage.title')}
          />
        </Box>
        <AddBusinessAction atBusinessLimit={false} />
      </PageHeadingLayout>

      <Card marginTop="medium">
        <CardContent>
          <ZeroJobsIllustration size="standard" />
          <Text size="xlarge" weight="regular" align="center">
            {t('businessListingPage.state.zero')}
          </Text>
        </CardContent>
      </Card>
    </>
  );
};
