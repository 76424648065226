import REQUEST_KEY from '@common/constants/requestKeys';
import { getLoadAndErrorState } from '@store/app';
import { useAppSelector } from '@store/hooks';

export function useResumeUrlStatus(applicationId: string) {
  const { loadStack } = useAppSelector(getLoadAndErrorState);

  const isGettingtResumeUrl = loadStack.includes(
    `${REQUEST_KEY.APPLICATION.GET_RESUME_URL_BY_ID(applicationId)}`
  );

  return {
    isGettingtResumeUrl
  };
}
