import { BrandName } from '@seek/je-shared-data/lib/types/brand';

/**
 * Determines whether to show three times more views for a job ad upgrade.
 *
 * @param {BrandName} params.brand - The brand name.
 * @returns A boolean value indicating whether to show three times more views.
 */
export const showThreeTimesMoreViews = ({
  brand
}: {
  brand: BrandName;
}): boolean => {
  if (brand === BrandName.JOBSTREETEXPRESS) return false;

  return false;
};
