import React, { forwardRef } from 'react';
import { View } from 'react-native';
import { Column, ColumnProps } from '@components/Column';
import { Row } from '../Row';
import { CardStyleProps, useCardStyle } from './useCardStyle';

type CardPropsToPick =
  | 'children'
  | 'testID'
  | 'margin'
  | 'marginTop'
  | 'marginBottom'
  | 'marginLeft'
  | 'marginRight'
  | 'marginHorizontal'
  | 'marginVertical'
  | 'shadow'
  | 'borderWidth';

type CardProps = Pick<ColumnProps, CardPropsToPick> & CardStyleProps;

export const Card = forwardRef<View, CardProps>(
  ({ children, leftAdornment, testID, ...restProps }, ref) => {
    const { borderLeftWidth } = useCardStyle({ leftAdornment });

    return (
      <Row
        justifyContent="center"
        alignItems="stretch"
        tone="transparent"
        testID={testID}
      >
        {leftAdornment}
        <Column
          borderLeftWidth={borderLeftWidth}
          flex={1}
          tone="neutral"
          ref={ref}
          {...restProps}
        >
          {children}
        </Column>
      </Row>
    );
  }
);
