import {
  BrandName,
  SupportedCountryCode
} from '@seek/je-shared-data/lib/types/brand';

interface SixTimesMore {
  brand: BrandName;
  country: SupportedCountryCode;
}
/**
 * Determines whether to show six times more views for a job ad upgrade.
 *
 * @param {BrandName} params.brand - The brand name.
 * @param {SupportedCountryCode} params.country - The country code.
 * @returns A boolean value indicating whether to show six times more views.
 */
export const showSixTimesMoreViews = ({
  brand,
  country
}: SixTimesMore): boolean => {
  if (brand !== BrandName.JOBSTREETEXPRESS) return false;
  if (
    country === SupportedCountryCode.Singapore ||
    country === SupportedCountryCode.Indonesia
  ) {
    return false;
  }

  return false;
};
