import { EventTypes } from 'redux-segment';
import { AD_TYPE } from '@seek/je-shared-data';
import * as constants from '../../constants';

type TrackPostJobSuccess = {
  acquisitionChannel: string;
  adType: AD_TYPE.BASIC;
  businessName: string;
  jobCountry: string;
  state: string;
  title: string;
  jobRole?: string;
};

/**
 * Tracks the successful posting of a job.
 *
 * @param {TrackPostJobSuccess} params - The parameters for tracking the job post success.
 * @returns {Object} - The action object for tracking the job post success.
 */
export function trackPostJobSuccess(
  params: TrackPostJobSuccess
): Record<string, unknown> {
  return {
    type: constants.TRACK_POST_JOB_SUCCESS,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'job_post__successful',
          parameters: {
            ...params
          }
        }
      }
    }
  };
}
