import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { Box } from '@components/Box';
import { Button } from '@components/Button';
import { Modal } from '@components/Modal';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { useDeleteBusinessModal } from './hooks';
import { useOnDeleteBusinessSuccess } from './hooks/useOnDeleteBusinessSuccess';

type DeleteBusinessModalProps = {
  businessName: string;
  businessId: string;
  visible: boolean;
  onRequestClose: () => void;
};

export const DeleteBusinessModal = ({
  businessName,
  businessId,
  visible,
  onRequestClose
}: DeleteBusinessModalProps) => {
  const { t } = useTranslation();
  const onDeleteBusinessSuccess = useOnDeleteBusinessSuccess(onRequestClose);

  const { onDeleteBusinessPress, errorText } = useDeleteBusinessModal({
    businessId,
    onDeleteBusinessSuccess
  });

  return (
    <Modal
      title={t('deleteBusiness.title')}
      visible={visible}
      onRequestClose={onRequestClose}
    >
      <Stack spacing="medium">
        <Box>
          <Text>{t('deleteBusiness.description', { businessName })}</Text>
        </Box>
        <Button
          onPress={() => onDeleteBusinessPress()}
          testID="delete-business-button"
        >
          {t('common.action.delete')}
        </Button>

        {errorText ? <ErrorMessage>{errorText}</ErrorMessage> : null}
      </Stack>
    </Modal>
  );
};
