import React from 'react';
import { Stack } from '@components/Stack';
import { BusinessCard } from '../BusinessCard';

type BusinessListProps = {
  activeBusinesses: any;
};

export const BusinessList = ({ activeBusinesses }: BusinessListProps) => (
  <Stack tone="transparent" spacing="medium">
    {activeBusinesses.map((business) => (
      <BusinessCard key={business.id} business={business} />
    ))}
  </Stack>
);
