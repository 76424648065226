import omit from 'lodash.omit';
import { DRAFT_FORM } from './constants';

export function hasLocalStorageSupport() {
  const test = 'test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}

export function readAll() {
  return (
    hasLocalStorageSupport() &&
    localStorage.getItem(DRAFT_FORM) &&
    JSON.parse(localStorage.getItem(DRAFT_FORM) as string)
  );
}

export function save(data: object) {
  if (hasLocalStorageSupport()) {
    const draftFormValues = readAll();
    localStorage.setItem(
      DRAFT_FORM,
      JSON.stringify({ ...draftFormValues, ...data })
    );
  }
}

export function clear(key: string) {
  if (hasLocalStorageSupport()) {
    const draftFormValues = readAll();
    const updatedDraftFormValues = omit(draftFormValues, key);

    if (Object.keys(updatedDraftFormValues).length === 0) {
      localStorage.removeItem(DRAFT_FORM);
      return;
    }

    localStorage.setItem(DRAFT_FORM, JSON.stringify(updatedDraftFormValues));
  }
}

export function clearAll() {
  if (hasLocalStorageSupport()) {
    localStorage.removeItem(DRAFT_FORM);
  }
}
