export function getConfirmPaymentError(error) {
  if (error?.type === 'card_error' && error?.message) {
    return {
      message: error?.message
    };
  }

  if (error?.param === 'payment_method_data[id_bank_transfer][bank]') {
    return {
      message: 'errors.payment.confirmPayment.otherBank'
    };
  }

  const errorMessage = error?.message || JSON.stringify(error);
  throw new Error(errorMessage);
}
