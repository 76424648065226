import { EventTypes } from 'redux-segment';

const VIEW_PROFILE_BUTTON_CLICKED = 'jora-employer/VIEW_PROFILE_BUTTON_CLICKED';

type TrackViewProfileClickedArgs = {
  type: 'close' | 'open';
  employerJobId: string;
  applicationId: string;
  candiUserId: string;
  candiApplicationId?: string;
};

export function trackViewProfileClicked({
  type,
  employerJobId,
  applicationId,
  candiApplicationId,
  candiUserId
}: TrackViewProfileClickedArgs) {
  return {
    type: VIEW_PROFILE_BUTTON_CLICKED,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'Application view profile button clicked',
          properties: {
            type,
            employerJobId,
            applicationId,
            candiApplicationId,
            candiUserId
          }
        }
      }
    }
  };
}
