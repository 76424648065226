import { logout } from '@store/auth/actions';
import { useAppDispatch } from '@store/hooks';

export function useLogout() {
  const dispatch = useAppDispatch();

  return {
    logout: () => dispatch(logout({ initiatedByUser: true }))
  };
}
