import { UseFormSetError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UpdateAccountDetailsFormFields } from '../types';

export function useResponseErrorHandler() {
  const { t } = useTranslation();
  const handleResponseErrors = (
    response: {
      field: string;
      errorMessage: string;
    } | void,
    setError: UseFormSetError<UpdateAccountDetailsFormFields>
  ) => {
    if (!response) return;
    const { field, errorMessage } = response;
    setError(field as keyof UpdateAccountDetailsFormFields, {
      type: 'server',
      message: t(errorMessage)
    });
  };

  return {
    handleResponseErrors
  };
}
