import React from 'react';
import { ErrorIcon } from '@components/Icon';
import { Row } from '@components/Row';
import { Text } from '@components/Text';
import { UseWarningStyleProps, useWarningStyle } from './useWarningStyle';

type WarningProps = UseWarningStyleProps & {
  message: string;
};

export const Warning = ({ message, critical }: WarningProps) => {
  const { iconStyle, textStyle } = useWarningStyle({ critical });
  return (
    <Row marginTop="medium" spacing="xxsmall">
      <ErrorIcon {...iconStyle} />
      <Text {...textStyle}>{message}</Text>
    </Row>
  );
};
