import React, { useContext } from 'react';
import { StripeElements } from '@stripe/stripe-js';

export type PaymentContextData = {
  paymentIntentId?: string;
  clientId?: string;
  elements?: StripeElements | null;
  stripe?: any;
  setPaymentIntentId: React.Dispatch<React.SetStateAction<string | undefined>>;
  setClientId: React.Dispatch<React.SetStateAction<string | undefined>>;
  setElements: React.Dispatch<
    React.SetStateAction<StripeElements | undefined | null>
  >;
  setStripe: React.Dispatch<React.SetStateAction<any>>;
};

export const PaymentContext = React.createContext<PaymentContextData>({
  setPaymentIntentId: throwContextError,
  setClientId: throwContextError,
  setElements: throwContextError,
  setStripe: throwContextError
});

export const usePaymentContext = () => useContext(PaymentContext);

function throwContextError() {
  throw new Error('Called outside PaymentProvider not allowed');
}
