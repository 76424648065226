import React from 'react';
import { TextInputProps } from 'react-native';
import { UseMaskedInputProps, createNumberMask } from 'react-native-mask-input';
import { Input } from '@components/FormElements/Input';

export type CurrencyInputProps = {
  value: TextInputProps['value'];
  onChange:
    | TextInputProps['onChangeText']
    | UseMaskedInputProps['onChangeText'];
  onBlur?: TextInputProps['onBlur'];
  testID?: TextInputProps['testID'];
  disabled?: boolean | undefined;
  tone?: 'neutral' | 'danger' | undefined;
  placeholder?: TextInputProps['placeholder'];
  currencySymbol?: string;
};

export const CurrencyInput = (props: CurrencyInputProps) => {
  const currencyMask = createNumberMask({
    prefix: [props.currencySymbol || '$', ' '],
    delimiter: ',',
    precision: 3
  });

  return (
    <Input
      id="currency"
      keyboardType="decimal-pad"
      mask={currencyMask}
      maskAutoComplete={true}
      {...props}
    />
  );
};
