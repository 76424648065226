import * as constants from '../constants';

export type ClearDashboardActionSuccess = {
  type: typeof constants.CLEAR_DASHBOARD_JOBS;
};

export function clearDashboardJobs() {
  return (dispatch) => {
    dispatch({
      type: constants.CLEAR_DASHBOARD_JOBS
    });
  };
}
