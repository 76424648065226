import React from 'react';
import { useTranslation } from 'react-i18next';
import * as appTheme from '@common/theme/theme';
import { BrandName } from '@seek/je-shared-data/lib/types/brand';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';
import { Link } from '@components/Link';
import {
  JobStreetExpressLogo,
  JobStreetLogo,
  JobsDbLogo,
  JoraEmployerLogo
} from '@components/Logo';

type LogoProps = {
  theme: 'dark' | 'light';
  handlePress?: () => void;
  isSplashScreen?: boolean;
};

const getLogo = ({ brandName, theme, title, isSplashScreen }) => {
  switch (brandName) {
    case BrandName.JOBSTREETEXPRESS:
      return (
        <JobStreetExpressLogo
          height={48}
          theme={theme}
          width={isSplashScreen ? appTheme.gridRowHeight * 30 : 'standard'}
          title={title}
          testID="logo-jobstreet-express"
        />
      );
    case BrandName.JOBSDB:
      return (
        <JobsDbLogo
          height={48}
          theme={theme}
          width={isSplashScreen ? appTheme.gridRowHeight * 20 : 'standard'}
          title={title}
          testID="logo-jobsdb"
        />
      );
    case BrandName.JOBSTREETVN:
      return (
        <JobStreetLogo
          height={48}
          theme={theme}
          width="standard"
          title={title}
          testID="logo-jobstreet"
        />
      );
    case BrandName.JORA:
    default:
      return (
        <JoraEmployerLogo
          height={48}
          theme={theme}
          width={isSplashScreen ? appTheme.gridRowHeight * 20 : 'standard'}
          title={title}
          testID="logo-jora-employer"
        />
      );
  }
};

export const Logo = ({ theme, handlePress, isSplashScreen }: LogoProps) => {
  const { t } = useTranslation();
  const { brandNameText, brandConfig } = useAppSelector(getLocalisation);
  const title = `${brandNameText} ${t('common.linkText.employer')}`;

  return (
    <>
      <Link
        onPress={handlePress}
        icon={getLogo({
          brandName: brandConfig?.name,
          theme,
          title,
          isSplashScreen
        })}
      />
    </>
  );
};
