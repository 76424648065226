import React from 'react';
import { useTranslation } from 'react-i18next';
import { RequestErrorMessage } from '@common/components/RequestErrorMessage';
import REQUEST_KEY from '@common/constants/requestKeys';
import { Box } from '@components/Box';
import { Card, CardContent } from '@components/Card';
import { Heading } from '@components/Heading';
import { Stack } from '@components/Stack';

export const ApplicationsLoadError = () => {
  const { t } = useTranslation();

  return (
    <Stack tone="transparent">
      <Box
        paddingHorizontal="medium"
        marginTop="medium"
        marginBottom="medium"
        tone="transparent"
      >
        <Heading level="h1">{t('applications.title.error')}</Heading>
      </Box>

      <Card>
        <CardContent>
          <RequestErrorMessage
            testID="applications-error"
            requestKey={REQUEST_KEY.APPLICATION.LOAD}
          />
        </CardContent>
      </Card>
    </Stack>
  );
};
