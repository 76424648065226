import React from 'react';
import { useAvailabilityDisplay } from '@common/hooks/useAvailibilityDisplay';
import { DAY } from '@seek/je-shared-data/lib/types/jobs';
import { Column } from '@components/Column';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import {
  AllShiftsAvailabilityCheckboxes,
  DailyShiftAvailabilityCheckboxes
} from '.';
import {
  AllSpecificAvailabilityType,
  OnAllCheckboxPressedType,
  OnCheckboxPressedType,
  SpecificShiftAvailabilityType
} from '../types';

type Props = {
  value: SpecificShiftAvailabilityType;
  selectedAllAvailability: AllSpecificAvailabilityType;
  onAvailabilityCheckboxPress: (value: OnCheckboxPressedType) => void;
  onAllAvailabilityCheckboxPress: (value: OnAllCheckboxPressedType) => void;
};

export const SpecificShiftAvailability = (props: Props) => {
  const {
    selectedAllAvailability,
    onAllAvailabilityCheckboxPress,
    onAvailabilityCheckboxPress,
    value
  } = props;

  return (
    <Stack
      marginLeft={{ desktop: 'large', tablet: 'large', mobile: 'none' }}
      marginTop="small"
      spacing="medium"
      borderWidth="xsmall"
      borderRadius="small"
      paddingVertical="medium"
      paddingHorizontal="xlarge"
    >
      <AllShiftsAvailabilityCheckboxes
        currentSelectedOptions={selectedAllAvailability}
        onChange={onAllAvailabilityCheckboxPress}
      />

      <TimeAvailabilityLabels />

      {Object.values(DAY).map((day, index) => {
        return (
          <DailyShiftAvailabilityCheckboxes
            day={day}
            key={`${day}-${index}`}
            onCheckboxPressed={onAvailabilityCheckboxPress}
            availabilityOptions={value[day]}
          />
        );
      })}
    </Stack>
  );
};

const TimeAvailabilityLabels = () => {
  const { availabilityLabel } = useAvailabilityDisplay();

  return (
    <Row width="full">
      <Column width="1/4" />
      {availabilityLabel.map((availability) => (
        <Column width="1/4" alignItems="center" key={availability.value}>
          <Text size="small">{availability.label}</Text>
        </Column>
      ))}
    </Row>
  );
};
